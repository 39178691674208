import React, { useState, useEffect, Component } from 'react';
import axios from 'axios';
import { Modal } from 'react-bootstrap';

import SuperAdminSidebar from './superadmin_sidebar'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import ShowSoacialMedia from '../ShowSocialMediaComp';

const Offers = () => {

   const [currentEditId, setcurrentEditId] = useState(0);
   const [isBlockModelShow, setIsBlockModelShow] = useState(false);
   //const closeBlockModelShow  = () => { setIsBlockModelShow(false) }
   const showUserBlockAction = (id) => { setcurrentEditId(id); setIsBlockModelShow(true) }
   const status_type = ['Status', 'active', 'completed', 'expired'];
   const posted_by_arr = ['Posted By', 'Recruiter', 'Individual', 'Company'];
   const posted_by_arrobj = [
      { key: 1, keyval: '', value: 'Posted By' },
      { key: 2, keyval: 'rec_reprasentative', value: 'Recruiter' },
      { key: 3, keyval: 'rec_indivisual', value: 'Individual' },
      { key: 4, keyval: 'rec_owners', value: 'Company' }
   ];
   const userdetails = JSON.parse(localStorage.getItem("squadGologin"));
   const [moduleAccessibility] = useState(typeof (userdetails.accessibility) != undefined ? userdetails.accessibility.jobs_offers : [{
      view: true,
      edit: true,
      delete: true,
   }]);
   const viewAccessibility = (moduleAccessibility != undefined && typeof (moduleAccessibility.view) != undefined) ? moduleAccessibility.view : true;
   const editAccessibility = (moduleAccessibility != undefined && typeof (moduleAccessibility.edit) != undefined) ? moduleAccessibility.edit : true;
   const deleteAccessibility = (moduleAccessibility != undefined && typeof (moduleAccessibility.delete) != undefined) ? moduleAccessibility.delete : true;
   const [jobOfferDataArr, setOfferDataArr] = useState([]);
   const [currentPageActive, setCurrentPageActive] = useState(1);
   const [dataLength, setDataLength] = useState(0);
   const [totalPageLength, setTotalPageLength] = useState(1);
   const [dataisLoading, setDataisLoading] = useState(true);
   const [dataLoading, setDataLoading] = useState(false);
   const [tableOrderType, settableOrderType] = useState('ASC');
   const [searchText, setSearchText] = useState('');
   const [postedByCheck, setPostedByCheck] = useState('');
   const [jobStatus, setJobStatus] = useState('');
   //const [offerDataArr, setofferDataArr] = useState([]);


   const getOffersData = () => {
      (async () => {
         setDataisLoading(true);
         try {
            const formData = new FormData();
            axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/get_jobs_for_superadmin/${userdetails.login_token}?page=${currentPageActive}&search=${searchText}&postedby=${postedByCheck}&status=${jobStatus}&fromDate=${fromDate}&toDate=${toDate}&ordertype=${tableOrderType}`, formData)
               .then((response) => {
                  setDataisLoading(false);
                  console.log(response.data.data);
                  if (response.status == 200 && response.data.status == 1) {
                     setOfferDataArr(response.data.data.offerdata);
                     setDataLength(response.data.data.length);
                     const calculatedTotalPages = Math.ceil(response.data.data.length / 10);
                     setTotalPageLength(calculatedTotalPages);
                  }
               })
               .catch((error) => { console.error(error); });
         } catch (error) { console.log(error); }

      })();
   }
   useEffect(() => { getOffersData(); }, [searchText, postedByCheck, jobStatus, currentPageActive , tableOrderType ]);


   const CheckStatusHtml = (data) => {

      let className = 'bluebtn';
      switch (data.status) {
         case 'expire':
            className = 'btn-danger';
            break;
         case 'completed':
            className = 'btn-green';
            break;

         case 'draft':
            className = 'btn-info';
            break;
      }
      return (
         <div className={`btnsmall text-capitalize text-white ${className} ${data.status}`}>
            {data.status == 'expire' ? 'expired' : data.status}
         </div>
      )
   }


   /*********Pagination**********/
   const handlePageChange = (newPage) => {
      setCurrentPageActive(newPage);
   };

   const RenderCompletedPaginationNumbers = () => {
      const paginationNumbers = [];
      paginationNumbers.push(<li className="page-item" onClick={(e) => handlePageChange(currentPageActive - 1)} ><a className="page-link" href="#"><i className="fa fa-angle-left" aria-hidden="true"></i></a></li>)
      for (let i = 1; i <= totalPageLength; i++) {
         paginationNumbers.push(<li key={i} onClick={() => handlePageChange(i)} className={i === currentPageActive ? 'page-item active' : 'page-item'} ><a className="page-link" href="#">{i}</a></li>);
      }
      paginationNumbers.push(<li className="page-item" onClick={(e) => handlePageChange(currentPageActive + 1)} ><a className="page-link" href="#"><i className="fa fa-angle-right" aria-hidden="true"></i></a></li>)
      return paginationNumbers;
   };



   /*-------------------jobdetail module--------------------*/
   // const [dataisLoading, setDataisLoading] = useState(true);
   //const [validated, setValidated] = useState(false);

   const Availability = (data) => {
      let obj = (data.abdata) ? data.abdata : [];
      return (
         obj.length ?
            <>
               {
                  obj.map((item, indxe) => (
                     <>
                        <div key={'availibility_' + indxe} className="availability-sec d-flex align-items-center  mb-2">
                           <p>{item.name}</p>
                           <strong>{item.from_value ? item.from_value : '--.--'}</strong>
                           <div className="subheadpera">To</div>
                           <strong>{item.to_value ? item.to_value : '--.--'}</strong>
                        </div>
                     </>
                  ))
               }

            </>
            : <></>
      )
   }
   //const [offerID, setofferID] = useState(0);
   // const [isLoadingAcceptedOffer, setisLoadingAcceptedOffer] = useState(false);
   const [isModalDataLoad, setIsModalDataLoad] = useState(false);
   const [staffViewDetailsDataArr, setstaffViewDetailsDataArr] = useState([]);
   const [isJobDetailsModalShow, setIsJobDetailsModalShow] = useState(false);
   const [currentActiveTab, setcurrentActiveTab] = useState('job-detail');
   const [itemUserTypeRole, setItemUserTypeRole] = useState(0);

   const jobDetailModuleShow = (job_id, role = 0) => {
      //setofferID(offer_id); 
      setItemUserTypeRole(role);
      setIsModalDataLoad(true); setIsJobDetailsModalShow(true); setcurrentActiveTab('job-detail');
      (async () => {
         try {
            const formData = new FormData();
            axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/get_jobdetail_for_jobseeker/${userdetails.login_token}/${job_id}/${0}`, formData)
               .then((response) => {
                  setIsModalDataLoad(false);
                  if (response.status == 200 && response.data.status == 1) { setstaffViewDetailsDataArr(response.data.data); }
               })
               .catch((error) => { console.error(error); });
         } catch (error) { console.log(error); setIsModalDataLoad(false); }
      })();
   }
   const closeJobDetailModal = () => { setIsJobDetailsModalShow(false) }



   const showUserBlockActionExecute = () => {
      (async () => {
         setDataLoading(true)
         try {
            const formData = new FormData();
            axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/jobform_and_offers_delete/${userdetails.login_token}/${currentEditId}`, formData)
               .then((response) => {
                  setDataLoading(false);
                  if (response.status == 200 && response.data.status == 1) {
                     setIsBlockModelShow(false);
                     setcurrentEditId(false);
                     getOffersData();
                     toast.success(response.data.message);
                  }
               })
               .catch((error) => { console.error(error); });
         } catch (error) { console.log(error); }

      })();
   }


   const hideAndShowTab = (tab) => {
      setcurrentActiveTab(tab)
   }



   /*----------------date swaping--------------------*/
   const [fromDate, setFromDate] = useState('');
   const [toDate, setToDate] = useState('');

   const handleFromDateChange = (event) => {
      const newFromDate = event.target.value;
      // Check if newFromDate is less than toDate
      if (!toDate || new Date(newFromDate) < new Date(toDate)) {
         setFromDate(newFromDate);
      } else { setFromDate(toDate); setToDate(newFromDate); }
   };

   const handleToDateChange = (event) => {
      const newToDate = event.target.value;
      if (!fromDate || new Date(newToDate) > new Date(fromDate)) {
         setToDate(newToDate);
      } else { setToDate(fromDate); setFromDate(newToDate); }
   };

   useEffect(() => {
      if (fromDate && toDate) { getOffersData(); }
   }, [fromDate, toDate]);

   return (
      <>
         <div className="dashboarwrap">

            <div className="pagebanner">
               <div className="container">
                  <div className="row">
                     <div className="col-md-12">
                        <h2>Job Offers</h2>
                     </div>
                  </div>
               </div>
            </div>


            {
               (userdetails.email_status == 1 && userdetails.contact_status == 1) ?
                  <>
                     <div className="adminwrap py-4">
                        <div className="container">
                           <div className="row">
                              <div className="col-md-3 adminsidebar">
                                 <SuperAdminSidebar></SuperAdminSidebar>
                              </div>
                              <div className="col-md-9">
                                 <div className="row">
                                    <div className="col-md-12">
                                       <div className="box mb-4">
                                          {/* <div className="box-ttl mb-3 d-flex align-items-center justify-content-between">
                                             <h3>Job offers</h3>
                                          </div> */}
                                          <div className="table_filter mb-2 d-flex align-items-center justify-content-between commonform">
                                             <div className="tblsearch d-flex align-items-center">
                                                {/* <span className="filtericon" data-toggle="tooltip" title="Export in pdf">
                                                   <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
                                                </span> */}
                                                <input onChange={(e) => setSearchText(e.target.value)} value={searchText} type="text" className="form-control" placeholder="Search" />
                                             </div>
                                             <div className="tblrightfilters d-flex align-items-center">
                                                <div className="d-flex align-items-center datefilter">
                                                   <input value={fromDate} onChange={handleFromDateChange} type="date" className="form-control  text-capitalize mr-2"></input>
                                                   <span>To</span>
                                                   <input value={toDate} onChange={handleToDateChange} type="date" className="form-control  text-capitalize ml-2"></input>
                                                </div>
                                                <select onChange={(e) => setPostedByCheck(e.target.value)} className="form-control  text-capitalize ml-3" >
                                                   {
                                                      posted_by_arrobj.map((item, index) => (
                                                         <option selected={postedByCheck == item.keyval} key={item.key} value={item.keyval}>
                                                            <span className='text-capitalize'>{item.value}</span>
                                                         </option>
                                                      ))
                                                   }
                                                </select>
                                                <select onChange={(e) => setJobStatus(e.target.value)} className="form-control ml-3 text-capitalize" >
                                                   {
                                                      status_type.map((item, index) => (
                                                         <option selected={jobStatus == item} key={item} value={item}>
                                                            <span className='text-capitalize'>{item}</span>
                                                         </option>
                                                      ))
                                                   }

                                                </select>

                                                <span onClick={() => { setFromDate(''); setToDate(''); setCurrentPageActive(1); setSearchText(''); setPostedByCheck(''); setJobStatus(''); getOffersData() }} className="reseticon filtericon" data-toggle="tooltip" title="Reset filters">
                                                   <i className="fa fa-refresh"></i>
                                                </span>

                                             </div>

                                          </div>
                                          <div className="table-responsive">
                                             {
                                                !dataisLoading ?
                                                   <>
                                                      <table className="table admintbl text-capitalize1 joboffertbl">
                                                         <thead>
                                                            <tr>
                                                               <th onClick={() => settableOrderType((tableOrderType == 'ASC' ? 'DESC' :'ASC'))}>
                                                               {tableOrderType == 'ASC' ? <i className="fa fa-angle-down ml-5"></i> : <i className="fa fa-angle-up ml-5"></i> }
                                                               </th>
                                                               <th>Job title</th>
                                                               <th>Posted by</th>
                                                               <th>Staff</th>
                                                               <th  >
                                                                  Expired
                                                               </th>
                                                               <th>Status</th>
                                                               <th>Action</th>
                                                            </tr>
                                                         </thead>
                                                         <tbody>
                                                            {
                                                               jobOfferDataArr.length ?
                                                                  jobOfferDataArr.map((item, index) => (
                                                                     <>
                                                                        <tr key={item.id}>
                                                                           <td>

                                                                              {
                                                                                 item.user_type_role == 'rec_owners' ? <img className="usertypeicon" data-toggle="tooltip" data-placement="top" title="Owner" src={'front/images/building.svg'}></img>
                                                                                    : <img className="usertypeicon" data-toggle="tooltip" data-placement="top" title="Individual" src={'front/images/profile.svg'}></img>
                                                                              }
                                                                           </td>
                                                                           <td className='text-capitalize'>
                                                                              <b>{item.job_title_text}</b>
                                                                           </td>

                                                                           <td className='text-capitalize'>
                                                                              <Link target='_balnk' to={`/recruiter-detail?id=${item.unique_id}`}>
                                                                                 {item.first_name} {item.last_name}
                                                                              </Link>
                                                                           </td>
                                                                           <td>
                                                                              <span className='cursor_pointer staff_need_count' data-toggle="tooltip" data-placement="top" title="Total accepted offers">{item.total_accepted_offer}</span>-
                                                                              <span className='cursor_pointer staff_need_count' data-toggle="tooltip" data-placement="top" title="Total offers send">{item.total_send_offer}</span>-
                                                                              <span className='cursor_pointer staff_need_count' data-toggle="tooltip" data-placement="top" title="Total staff needed">{item.staff_number}</span>
                                                                           </td>
                                                                           <td className='text-lowercase'>{item.expired_date_format}</td>
                                                                           <td>
                                                                              <CheckStatusHtml status={item.status} />

                                                                           </td>
                                                                           <td>
                                                                              <div className="d-flex">
                                                                                 {viewAccessibility && <Link onClick={(e) => jobDetailModuleShow(item.job_form_id, item.user_type_role)} className="ml-3" data-toggle="tooltip" title="View Offer's Detail"><img src="/front/images/Show.svg" /></Link>}
                                                                                 {editAccessibility && <Link onClick={(e) => showUserBlockAction(item.job_form_id)} className="ml-3" data-toggle="tooltip" title="Delete Offer "><img src="/front/images/trash.svg" /></Link>}

                                                                                 {/* <Link className="ml-3" onClick={(e) => showUserBlockAction(item.offer_id, item.job_form_id)} >
                                                                                    <i className="faicon fa fa-trash" aria-hidden="true"></i>
                                                                                 </Link> */}
                                                                                 {/* <a href="#" className="ml-3"><img src="/front/images/TickSquare.svg" /></a>
                                                                                 <a href="#" className="ml-3"><img src="/front/images/trash.svg" /></a> */}
                                                                              </div>
                                                                           </td>
                                                                        </tr>
                                                                     </>
                                                                  ))
                                                                  :
                                                                  <>
                                                                     <tr>
                                                                        <td className='text-center' colSpan={7}>No information available to display</td>
                                                                     </tr>
                                                                  </>
                                                            }


                                                         </tbody>
                                                      </table>
                                                      {
                                                         dataLength > 10 ?
                                                            <>
                                                               <div className="card-box">
                                                                  <nav aria-label="Page navigation">
                                                                     <ul className="pagination mb-0 justify-content-center">
                                                                        <RenderCompletedPaginationNumbers />
                                                                     </ul>
                                                                  </nav>
                                                               </div>
                                                            </>
                                                            : <></>
                                                      }
                                                   </>
                                                   :
                                                   <>
                                                      <div className={`tab-pane fade show active mt45px`} >
                                                         <div className='loader_container'>
                                                            <p className='text-center'><img className='width-100 loader_image' src={'/front/images/loader.gif'} /></p>
                                                         </div>
                                                      </div>
                                                   </>
                                             }
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </>
                  :
                  <>
                     Please Activate your account
                  </>
            }
         </div>
         {
            (userdetails.email_status == 1 && userdetails.contact_status == 1) ?
               <>
                  {/* recruiter View details model */}
                  <Modal className="modal fade modallarg jobmodle jobdetailmdl" id="jobsetailmodle" show={isJobDetailsModalShow} onHide={closeJobDetailModal}>
                     {
                        <>
                           <div className="modal-dialog1">
                              <div className="modal-content">
                                 <div className="modal-body">
                                    <button type="button" className="btn-close" onClick={closeJobDetailModal} ></button>
                                    {staffViewDetailsDataArr.job_title_text && <>
                                       <nav className="jobseekertabs mt-0">
                                          <div className="nav nav-tabs " id="nav-tab" role="tablist">
                                             <button className={`${currentActiveTab == 'job-detail' ? 'active' : ''} nav-link`} id="jobdetail" onClick={() => hideAndShowTab('job-detail')} ><i className="fa fa-briefcase mr-1" aria-hidden="true"></i> Job Details</button>
                                             <button className={`${currentActiveTab == 'about-recruiter' ? 'active' : ''} nav-link`} onClick={() => hideAndShowTab('about-recruiter')} id="aboutrecruiter"  ><i className="fa fa-user-o mr-1" aria-hidden="true"></i>
                                                About {
                                                   itemUserTypeRole == 'rec_owners' ?
                                                      <>Owner</>
                                                      : itemUserTypeRole == 'rec_reprasentative' ? <>Reprasentative</>
                                                         : <>Recruiter</>
                                                }
                                             </button>
                                             <button className={`${currentActiveTab == 'accepted-by' ? 'active' : ''} nav-link`} onClick={() => hideAndShowTab('accepted-by')} id="acceptedby" >
                                                <i className="fa fa-hand-pointer-o mr-1" aria-hidden="true"></i>Accepted Offers
                                             </button>
                                          </div>
                                       </nav>
                                       <div className="tab-content" id="nav-tabContent">

                                          {currentActiveTab == 'job-detail' && <div className="tab-pane1" id="jobdetailpan" role="tabpanel" aria-labelledby="jobdetail">
                                             <div className="joblistbox">
                                                <div className="d-flex flex-wrap justify-content-between">
                                                   <h3 className='text-capitalize font-bold'>
                                                      <b>{staffViewDetailsDataArr.job_title_text}</b>
                                                   </h3>
                                                   <h3 className="job_price orange_clr">

                                                      {
                                                         staffViewDetailsDataArr.fixed_salary ?
                                                            <>
                                                               <strong>{staffViewDetailsDataArr.fixed_salary ? '$' + staffViewDetailsDataArr.fixed_salary + '' : '--'}</strong>
                                                            </>
                                                            :
                                                            <>
                                                               <strong>{staffViewDetailsDataArr.salary_minimum ? '$' + staffViewDetailsDataArr.salary_minimum + '/Hour' : '--'}</strong>
                                                               <strong className="subheadpera">To</strong>
                                                               <strong>{staffViewDetailsDataArr.salary_maximum ? '$' + staffViewDetailsDataArr.salary_maximum + '/Hour' : '--'}</strong>
                                                            </>
                                                      }

                                                   </h3>
                                                </div>
                                                <div><span className="tags">{staffViewDetailsDataArr.job_type_text}</span></div>
                                                {/* <p>Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Sed hendrerit. Maecenas nec odio et ante condimentum rhoncus, sem quam semper libero condimentum rhoncus, sem quam...</p> */}
                                                <div className="d-flex flex-wrap mt-2">
                                                   <div className="icontxt"><img src="/front/images/calender.png"></img> Offer date: <span>{staffViewDetailsDataArr.created_date_format ?? '.....'}</span></div>
                                                   <div className="icontxt"><img src="/front/images/clock.png"></img> Offer expire date: <span>{staffViewDetailsDataArr.expired_date_format ?? '.....'}</span></div>
                                                   <div className="icontxt"><img src="/front/images/location.png"></img> Location: <span>{staffViewDetailsDataArr.job_location_text ? staffViewDetailsDataArr.job_location_text : '......'}</span></div>
                                                   <div className="icontxt"><img src="/front/images/bag.png"></img> Job type: <span>{staffViewDetailsDataArr.job_type_text}</span></div>
                                                   <div className="icontxt"><img src="/front/images/star.png"></img> Experience: <span>{staffViewDetailsDataArr.total_experience_month + '.' + staffViewDetailsDataArr.total_experience_year + ' Years'}</span></div>
                                                   <div className="icontxt"><img src="/front/images/fa_route.png"></img> Range from location: <span>{staffViewDetailsDataArr.range_from_location}{staffViewDetailsDataArr.range_from_location > 0 ? 'KM' : ''}</span></div>
                                                   <div className="icontxt"><img src="/front/images/user.svg"></img> Total staff loooking: <span>{staffViewDetailsDataArr.staff_number}</span></div>
                                                   <div className="icontxt"><img src="/front/images/dollar.png"></img> Salary Type: <span>{staffViewDetailsDataArr.fixed_salary ? 'Fixed' : 'Hourly'}</span></div>
                                                </div>
                                                <hr>
                                                </hr>
                                                <h5>Availability to work</h5>
                                                <p>Jobseeker should available on below time.</p>
                                                <Availability abdata={staffViewDetailsDataArr.availibility} ></Availability>
                                                <hr></hr>
                                                <h5>Paying extra for</h5>
                                                <ul>
                                                   <li className='text-capitalize'>Public holidays : {staffViewDetailsDataArr.are_you_paying_extra_in_holiday}  </li>
                                                   <li className='text-capitalize'>Weekend : {staffViewDetailsDataArr.are_you_paying_extra_in_weekend} </li>
                                                   <li className='text-capitalize'>Shift loading : {staffViewDetailsDataArr.are_you_paying_extra_in_overtime} </li>
                                                </ul>
                                                <hr></hr>
                                                <h5>Education qualification</h5>
                                                <p>Jobseeker should have following degree and diplomas:</p>
                                                <ul>
                                                   <li>{staffViewDetailsDataArr.education_qualification_text}</li>
                                                </ul>
                                                <hr></hr>
                                                <h5>Language known</h5>
                                                <p>Jobseeker should be fluent in following languages:</p>
                                                <ul> <li>{staffViewDetailsDataArr.language_text}</li></ul>

                                                <hr></hr>
                                                <h5>Job Descriptions</h5>
                                                <div>
                                                   {staffViewDetailsDataArr.job_descriptions ? staffViewDetailsDataArr.job_descriptions : '.................................'}
                                                </div>


                                                <div className="btnsgroup">

                                                   {/* <Link disabled={isLoadingAcceptedOffer} className="batn batn-green" onClick={(e) => offerAccepted(offerID) } >Accept {isLoadingAcceptedOffer ? <><img   src={'/front/images/loader.gif'} /></> : <></>}</Link> */}
                                                   {/* <Link className="batn batn-red" onClick={(e) => jobDeclineModuleShow(offerID) } >Decline</Link> */}


                                                </div>
                                             </div>
                                          </div>}



                                          {currentActiveTab == 'about-recruiter' && <div className="tab-pane1" id="aboutrecruiterpan" role="tabpanel" aria-labelledby="aboutrecruiter">
                                             {
                                                <>
                                                   <div className="aboutrecuruter mt-2 d-flex">
                                                      <div className="recruterimg">
                                                         <img src={'https://squadgoo.com.au/api.squadgoo.com.au/' + staffViewDetailsDataArr.recruiterdata.profilepic}></img>
                                                      </div>
                                                      <div className="recruterabout">
                                                         <h5 className='text-capitalize'>{staffViewDetailsDataArr.recruiterdata.first_name + ' ' + staffViewDetailsDataArr.recruiterdata.last_name}</h5>
                                                         <div className="d-flex recruiterdetailpop">
                                                            <p>
                                                               <i className="fa fa-envelope-o" aria-hidden="true"></i>
                                                               {
                                                                  staffViewDetailsDataArr.recruiterdata.email ?
                                                                     <><a className="linktxt" href={`mailto:${staffViewDetailsDataArr.recruiterdata.email}`}>{staffViewDetailsDataArr.recruiterdata.email}</a> </>
                                                                     : <>Email will show after accept offer</>
                                                               }
                                                            </p>
                                                            <p>
                                                               <i className="fa fa-phone" aria-hidden="true"></i>
                                                               {
                                                                  staffViewDetailsDataArr.recruiterdata.contact_number ?
                                                                     <>
                                                                        <a className="linktxt" href={`tel:${staffViewDetailsDataArr.recruiterdata.contact_number}`}>
                                                                           {staffViewDetailsDataArr.recruiterdata.contact_country_code ? "+" + staffViewDetailsDataArr.recruiterdata.contact_country_code : ''} {staffViewDetailsDataArr.recruiterdata.contact_number}
                                                                        </a>
                                                                     </>
                                                                     : <>Conatce will show after offer</>
                                                               }
                                                            </p>
                                                            <p><i className="fa fa-industry" aria-hidden="true"></i>
                                                               {staffViewDetailsDataArr.reccomp.industry ? staffViewDetailsDataArr.reccomp.industry_text : '-------------'}
                                                            </p>
                                                         </div>
                                                         <div className="d-flex recruiterdetailpop">
                                                            <p>
                                                               <a href={staffViewDetailsDataArr.reccomp.website} target="_blank" className="linktxt">
                                                                  <i className="fa fa-globe" aria-hidden="true"></i>
                                                                  {staffViewDetailsDataArr.reccomp.website ? staffViewDetailsDataArr.reccomp.website : '------------------'}
                                                               </a>
                                                            </p>
                                                            <p className='text-capitalize'><i className="fa fa-map-marker" aria-hidden="true"></i>
                                                               {`
                                                               ${staffViewDetailsDataArr.reccomp.address_street_name ? staffViewDetailsDataArr.reccomp.address_street_name : '------'} 
                                                               ${staffViewDetailsDataArr.reccomp.address_suburbs ? ` , ${staffViewDetailsDataArr.reccomp.address_suburbs}` : '------'}
                                                               `}
                                                            </p>
                                                         </div>
                                                         <ShowSoacialMedia data={staffViewDetailsDataArr.recruiterdata} />
                                                      </div>
                                                   </div>
                                                   <hr></hr>
                                                   <p>
                                                      {staffViewDetailsDataArr.recruiterdata.profile_summary}
                                                   </p>

                                                   {staffViewDetailsDataArr.recruiterdata.user_type_role != 'rec_owners' && <>
                                                      <hr></hr>
                                                      <h5 className=''>Address details</h5>
                                                      <div className="d-flex flex-wrap mt-2 joblistbox">
                                                         <div className="icontxt"> Building No.: <span>{staffViewDetailsDataArr.recruiterdata.address_building_no}</span></div>
                                                         <div className="icontxt"> Street: <span>{staffViewDetailsDataArr.recruiterdata.address_street_name}</span></div>
                                                         <div className="icontxt"> Suburbs: <span>{staffViewDetailsDataArr.recruiterdata.address_suburbs}</span></div>
                                                         <div className="icontxt"> Unit No.: <span>{staffViewDetailsDataArr.recruiterdata.address_unit_no}</span></div>
                                                         <div className="icontxt"> State: <span>{staffViewDetailsDataArr.recruiterdata.address_user_state}</span></div>
                                                      </div>
                                                   </>}

                                                   {
                                                      staffViewDetailsDataArr.recruiterdata.user_type_role == 'rec_owners' &&
                                                      <>

                                                         <hr></hr>
                                                         <h5 className=''>Comapny details</h5>
                                                         <div className="d-flex flex-wrap mt-2 joblistbox">
                                                            <div className="icontxt"> Comapny Name: <span>{staffViewDetailsDataArr.reccomp.name ? staffViewDetailsDataArr.reccomp.name : '........'}</span></div>
                                                            <div className="icontxt"> Company website: <span>{staffViewDetailsDataArr.reccomp.website ? staffViewDetailsDataArr.reccomp.website : '........'}  </span></div>
                                                            <div className="icontxt"> Industry: <span>{staffViewDetailsDataArr.reccomp.industry_text ? staffViewDetailsDataArr.reccomp.industry_text : '........'} </span></div>
                                                         </div>
                                                         <hr></hr>
                                                         <h5 className=''>Comapny address</h5>
                                                         <div className="d-flex flex-wrap mt-2 joblistbox">
                                                            <div className="icontxt"> Building No.: <span>{staffViewDetailsDataArr.reccomp.address_building_no}</span></div>
                                                            <div className="icontxt"> Street: <span>{staffViewDetailsDataArr.reccomp.address_street_name}</span></div>
                                                            <div className="icontxt"> Suburbs: <span>{staffViewDetailsDataArr.reccomp.address_suburbs}</span></div>
                                                            <div className="icontxt"> Unit No.: <span>{staffViewDetailsDataArr.reccomp.address_unit_no}</span></div>
                                                            <div className="icontxt"> State: <span>{staffViewDetailsDataArr.reccomp.address_state}</span></div>
                                                         </div>
                                                      </>
                                                   }

                                                </>
                                             }
                                          </div>}

                                          {currentActiveTab == 'accepted-by' && <div className="tab-pane1" id="acceptedbypan" role="tabpanel" aria-labelledby="acceptedby">
                                             <div className="table-responsive">

                                                {
                                                   staffViewDetailsDataArr.offer_data.length > 0 ?
                                                      <>
                                                         <table id="acceptedbytabl" className="table admintbl profiletbl">
                                                            <thead>
                                                               <tr>
                                                                  <th>Full name</th>
                                                                  <th>Email</th>
                                                                  <th>Phone Number</th>
                                                                  <th>Accepted Date</th>
                                                               </tr>
                                                            </thead>
                                                            <tbody>
                                                               {
                                                                  staffViewDetailsDataArr.offer_data.map((item, index) => (
                                                                     <>
                                                                        <tr className="active_tr">
                                                                           <td className="text-capitalize">
                                                                              <a href="#">
                                                                                 {item.first_name + ' ' + item.last_name}
                                                                              </a>
                                                                           </td>
                                                                           <td>{item.email}</td>
                                                                           <td>{item.contact_number}</td>
                                                                           <td>{item.offer_created_date2}</td>
                                                                        </tr>
                                                                     </>
                                                                  ))
                                                               }

                                                            </tbody>
                                                         </table>
                                                      </>
                                                      :
                                                      <>
                                                         <p className='text-center'>No offers have been accepted for this job.</p>
                                                      </>
                                                }


                                             </div>
                                          </div>}

                                       </div>
                                    </>}
                                 </div>
                                 <div className={`model_loader ${!isModalDataLoad ? 'display_none' : ''}`}>
                                    <img src={'/front/images/loader.gif'} />
                                 </div>
                              </div>
                           </div>
                        </>
                     }
                  </Modal>
                  <Modal className="modal fade modalsmall jobacceptmodle" id="jobacceptmodle" show={isBlockModelShow} onHide={() => setIsBlockModelShow(false)} >
                     <div className="modal-dialog1">
                        <div className="modal-content">
                           <div className="modal-body">
                              <button type="button" className="btn-close" onClick={() => setIsBlockModelShow(false)}></button>
                              <div className="text-center mb-3">
                                 {/* <img src="/front/images/greencheck.svg" /> */}
                              </div>
                              <h5 className="text-center mb-4">Do you want to delete?</h5>

                              {
                                 dataLoading ?
                                    <>
                                       <p className='text-center'>Please wait.....</p>
                                    </>
                                    :
                                    <div className="btnsgroup text-center">
                                       <Link onClick={() => showUserBlockActionExecute()} className="batn batn-orange">Yes</Link>
                                       <Link onClick={() => setIsBlockModelShow(false)} className="batn batn-orange batn-border">No</Link>
                                    </div>
                              }

                           </div>
                        </div>
                     </div>
                  </Modal>
               </>
               : ''
         }
      </>
   );
}
export default Offers;