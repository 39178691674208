import SuperAdminSidebar from './superadmin_sidebar'
const Reports = () => {
   return (
      <>
         <div className="dashboarwrap">
            <div className="adminwrap py-4">
               <div className="container">
                  <div className="row">
                     <div className="col-md-3 adminsidebar">
                        <SuperAdminSidebar></SuperAdminSidebar>
                     </div>
                     <div className="col-md-9">
                        <div className="box mb-4">
                           <div className="box-ttl mb-3 d-flex align-items-center justify-content-between">
                              <h3>Requests</h3>
                              <div className="switch-field mr-0">
                                 <div className="switchfieldinner"><input type="radio" id="open" name="profileswitch" /><label htmlFor="open">Open</label></div>
                                 <div className="switchfieldinner"><input type="radio" id="resolved" name="profileswitch" /><label htmlFor="resolved">Resolved</label></div>
                              </div>
                           </div>
                           <div className="reportblock">
                              <h4>The offered job role is not same as company shown in offer.</h4>
                              <p className="reportdate d-flex"><img src="/front/images/calender.png" />09-05-2023</p>
                              <p>Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Sed hendrerit. Maecenas nec... <a href="#">Read More</a></p>
                              <div className="d-flex reportbtm">
                                 <img src="/front/images/profile.png" />
                                 <h4>Andrew Enderson</h4>
                                 <div className="ml-auto">
                                    <div className="d-flex"><a href="#" className="ml-3"><img src="/front/images/Show.svg" /></a><a href="#" className="ml-3"><img src="/front/images/phoneorange.svg" /></a><a href="#" className="ml-3"><img src="/front/images/yellowmail.svg" /></a><a href="#" className="ml-3"><img src="/front/images/TickSquare.svg" /></a></div>
                                 </div>
                              </div>
                           </div>
                           <div className="reportblock">
                              <h4>The offered job role is not same as company shown in offer.</h4>
                              <p className="reportdate d-flex"><img src="/front/images/calender.png" />09-05-2023</p>
                              <p>Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Sed hendrerit. Maecenas nec... <a href="#">Read More</a></p>
                              <div className="d-flex reportbtm">
                                 <img src="/front/images/profile.png" />
                                 <h4>Andrew Enderson</h4>
                                 <div className="ml-auto">
                                    <div className="d-flex"><a href="#" className="ml-3"><img src="/front/images/Show.svg" /></a><a href="#" className="ml-3"><img src="/front/images/phoneorange.svg" /></a><a href="#" className="ml-3"><img src="/front/images/yellowmail.svg" /></a><a href="#" className="ml-3"><img src="/front/images/TickSquare.svg" /></a></div>
                                 </div>
                              </div>
                           </div>
                           <div className="reportblock">
                              <h4>The offered job role is not same as company shown in offer.</h4>
                              <p className="reportdate d-flex"><img src="/front/images/calender.png" />09-05-2023</p>
                              <p>Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Sed hendrerit. Maecenas nec... <a href="#">Read More</a></p>
                              <div className="d-flex reportbtm">
                                 <img src="/front/images/profile.png" />
                                 <h4>Andrew Enderson</h4>
                                 <div className="ml-auto">
                                    <div className="d-flex"><a href="#" className="ml-3"><img src="/front/images/Show.svg" /></a><a href="#" className="ml-3"><img src="/front/images/phoneorange.svg" /></a><a href="#" className="ml-3"><img src="/front/images/yellowmail.svg" /></a><a href="#" className="ml-3"><img src="/front/images/TickSquare.svg" /></a></div>
                                 </div>
                              </div>
                           </div>
                           <div className="text-center"><a href="#" className="batn batn-orange mt-2">View All Requests</a></div>
                        </div>
                        <div className="box mb-4">
                           <div className="box-ttl mb-3 d-flex align-items-center justify-content-between">
                              <h3>Complaints</h3>
                              <div className="switch-field mr-0">
                                 <div className="switchfieldinner"><input type="radio" id="open1" name="profileswitch" /><label htmlFor="open1">Open</label></div>
                                 <div className="switchfieldinner"><input type="radio" id="resolved1" name="profileswitch" /><label htmlFor="resolved1">Resolved</label></div>
                              </div>
                           </div>
                           <div className="reportblock">
                              <h4>The offered job role is not same as company shown in offer.</h4>
                              <p className="reportdate d-flex"><img src="/front/images/calender.png" />09-05-2023</p>
                              <p>Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Sed hendrerit. Maecenas nec... <a href="#">Read More</a></p>
                              <div className="d-flex reportbtm">
                                 <img src="/front/images/profile.png" />
                                 <h4>Andrew Enderson</h4>
                                 <div className="ml-auto">
                                    <div className="d-flex"><a href="#" className="ml-3"><img src="/front/images/Show.svg" /></a><a href="#" className="ml-3"><img src="/front/images/phoneorange.svg" /></a><a href="#" className="ml-3"><img src="/front/images/yellowmail.svg" /></a><a href="#" className="ml-3"><img src="/front/images/TickSquare.svg" /></a></div>
                                 </div>
                              </div>
                           </div>
                           <div className="reportblock">
                              <h4>The offered job role is not same as company shown in offer.</h4>
                              <p className="reportdate d-flex"><img src="/front/images/calender.png" />09-05-2023</p>
                              <p>Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Sed hendrerit. Maecenas nec... <a href="#">Read More</a></p>
                              <div className="d-flex reportbtm">
                                 <img src="/front/images/profile.png" />
                                 <h4>Andrew Enderson</h4>
                                 <div className="ml-auto">
                                    <div className="d-flex"><a href="#" className="ml-3"><img src="/front/images/Show.svg" /></a><a href="#" className="ml-3"><img src="/front/images/phoneorange.svg" /></a><a href="#" className="ml-3"><img src="/front/images/yellowmail.svg" /></a><a href="#" className="ml-3"><img src="/front/images/TickSquare.svg" /></a></div>
                                 </div>
                              </div>
                           </div>
                           <div className="reportblock">
                              <h4>The offered job role is not same as company shown in offer.</h4>
                              <p className="reportdate d-flex"><img src="/front/images/calender.png" />09-05-2023</p>
                              <p>Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Sed hendrerit. Maecenas nec... <a href="#">Read More</a></p>
                              <div className="d-flex reportbtm">
                                 <img src="/front/images/profile.png" />
                                 <h4>Andrew Enderson</h4>
                                 <div className="ml-auto">
                                    <div className="d-flex"><a href="#" className="ml-3"><img src="/front/images/Show.svg" /></a><a href="#" className="ml-3"><img src="/front/images/phoneorange.svg" /></a><a href="#" className="ml-3"><img src="/front/images/yellowmail.svg" /></a><a href="#" className="ml-3"><img src="/front/images/TickSquare.svg" /></a></div>
                                 </div>
                              </div>
                           </div>
                           <div className="text-center"><a href="#" className="batn batn-orange mt-2">View All Complaints</a></div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
}
export default Reports;