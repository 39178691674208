import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import ContactFormComp from '../../expocomp/ContactFormComp';
const ContactUs = () => {

    return (
        <>
            <div className="webpagewrap">
                <div className="webpabebanner">
                    <div className="container">
                        <div className="row  align-items-center">
                            <div className="col-md-6">
                                <h1>Contact Us</h1>
                            </div>
                            <div className="col-md-6 text-right">
                                <img src="/front/images/contactimg.png"></img>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contactdtls">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <h2 className="sectionhead">Get in touch with us today !</h2>
                                <div className="contactbox d-flex mb-4">
                                    <div className="contctmg"><img src="/front/images/home-address.png"></img></div>
                                    <div className="contactboxmeta">
                                        <h4>Address</h4>
                                        {/* <p> Head office (communication center) -Melbourne level 8, 276 flinders street, Melbourne vic 3000</p> */}
                                        <p> Head office (communication center) - 
                                            {/* 15 Pinecone court, Werribee VIC 3030 */}
                                            Level 14 275 Alfred St North, North Sydney NSW, 2060 15 Pinecone Court, Werribee VIC, 3030
                                        </p>
                                    </div>
                                </div>

                                <div className="contactbox d-flex mb-4">
                                    <div className="contctmg"><img src="/front/images/email-con.png"></img></div>
                                    <div className="contactboxmeta">
                                        <h4>Email</h4>
                                        <p><a href="mailto:support@mybeamlabour.com">support@mybeamlabour.com</a></p>
                                    </div>
                                </div>

                                <div className="contactbox d-flex">
                                    <div className="contctmg"><img src="/front/images/phone-con.png"></img></div>
                                    <div className="contactboxmeta">
                                        <h4>Phone</h4>
                                        <p><a href="tel:0370369230">0370369230</a></p>
                                    </div>
                                </div>
                            </div>


                            <div className="col-md-6">
                                <h2 className="sectionhead">Contact Us</h2>
                                <div className="commonform contactformmain">
                                    <div className="row">

                                        <ContactFormComp screen="Contact Form" />
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className="Mapsec">
                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d121058.9318709163!2d73.78056541087135!3d18.52476137538877!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bf2e67461101%3A0x828d43bf9d9ee343!2sPune%2C%20Maharashtra!5e0!3m2!1sen!2sin!4v1691548003410!5m2!1sen!2sin" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.814115073353!2d144.96022778267553!3d-37.817822761991636!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642b475affff9%3A0x33c9ee2293708599!2sLevel-8%2F276%20Flinders%20St%2C%20Melbourne%20VIC%203000%2C%20Australia!5e0!3m2!1sen!2sin!4v1713603741944!5m2!1sen!2sin" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3149.1862090069717!2d144.650414375698!3d-37.87932777195927!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad68578ac7d1a1b%3A0xeb0603e5548e0b21!2s15%20Pinecone%20Ct%2C%20Werribee%20VIC%203030%2C%20Australia!5e0!3m2!1sen!2sin!4v1719409382584!5m2!1sen!2sin" width="600" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>            </div>
        </>
    );
}
export default ContactUs;