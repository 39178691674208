import React, { useState, useEffect, Component } from 'react';
import { NavLink, Link } from "react-router-dom";
import ProfileNotCompleted from '../../front/pages/profilenotcompleted';
import { Form, Button, Col, InputGroup, Modal, Row } from 'react-bootstrap';

import axios from 'axios';

import { ToastContainer, toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';

import ChatwidgetList from './chat_widget_list.js';


import { useDispatch } from 'react-redux'
import { recdeshjobactioncheck } from '../../redux_state/reducres/findStaffBtnAction';
const RecruiterDashboard = () => {
   const userdetails = JSON.parse(localStorage.getItem("squadGologin")); 
   const [currentScreen, setcurrentScreen] = useState('active_job');
   const [findStaffHistoryLoading, setFindStaffHistoryLoading] = useState(false);
   const useriddata = JSON.parse(localStorage.getItem("squadGologinSession"));
   const [findStaffActiveJobs, setFindStaffActiveJobs] = useState([]);
   const [findStaffHistory, setFindStaffDraftJobs] = useState([]);
   const [findStaffCompletedJobs, setFindStaffCompletedJobs] = useState([]);
   const [findStaffExpiredJobs, setFindStaffExpiredJobs] = useState([]);
   const [jobDataLength, setJobDataLength] = useState({ 'activedata_length': 0, 'completedData_length': 0, 'expireData_length': 0, 'draftdata_length': 0 });
   const [totalPagesActive, setTotalPagesActive] = useState(1);
   const [totalPageCompleted, setTotalPageCompleted] = useState(1);
   const [totalPageExpired, setTotalPageExpired] = useState(1);
   const [totalPagesDraft, setTotalPagesDraft] = useState(1);
   const [currentPageActive, setCurrentPageActive] = useState(1);
   const [currentPagecompleted, setCurrentPagecompleted] = useState(1);
   const [currentPageExpired, setCurrentPageExpired] = useState(1);
   const [currentPageDraft, setCurrentPageDraft] = useState(1);

   const [currentActivePage, setCurrentActivePage] = useState(1);
   const [callingScreenType, setCallingScreenType] = useState('all');

   const [shortByFilter, setshortByFilter] = useState('latest');
   const [filterActionTye, setfilterActionTye] = useState('weekly');
   const [shortBySearchtype , setShortBySearchtype ] = useState('');


   const ShortByFilter = [
      { key: 1, value: 'latest', name: 'All Posts' },
      { key: 2, value: 'last_week', name: 'Last Week' },
      { key: 3, value: 'last_2_week', name: 'Last 2 Week' }
   ];

   const ShortByQuickSearch = [
      { key: 1, value: '', name: 'Select Searchtype' },
      { key: 2, value: 'manually', name: 'Manually' },
      { key: 3, value: 'quick_search', name: 'Quick search' }
   ];


   
   
   const shortByFilterfun = (type, actiontype = 'weekly') => {
      setshortByFilter(type); setfilterActionTye(actiontype);
   }
   //const shortBySearchTypefun = (type) => {setshortByFilter(type); }


   const getAllJobListing = () => {
      (async () => {
         setFindStaffHistoryLoading(true);
         //alert(filterActionTye);
         try {
            const formData = new FormData();
            axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/findstaffdata_history/${useriddata.login_token}/${useriddata.unique_id}?screentype=${callingScreenType}&page=${currentActivePage}&shortby=${shortByFilter}&searchtype=${shortBySearchtype}&actiontype=${filterActionTye}`, formData)
               .then((response) => {
                  setFindStaffHistoryLoading(false);
                  if (response.status == 200 && response.data.status == 1) {
                     setFindStaffActiveJobs(response.data.data.activedata);
                     setFindStaffDraftJobs(response.data.data.draftdata);
                     setFindStaffCompletedJobs(response.data.data.completeddata);
                     setFindStaffExpiredJobs(response.data.data.expiredata);
                     setJobDataLength(response.data.data.length);
                     //console.log(response.data.data.length.draftdata_length)
                     if (response.data.data.length.draftdata_length) {
                        const calculatedTotalPages = Math.ceil(response.data.data.length.draftdata_length / 10);
                        setTotalPagesDraft(calculatedTotalPages);
                     }
                     if (response.data.data.length.activedata_length) {
                        const calculatedTotalPages = Math.ceil(response.data.data.length.activedata_length / 10);
                        setTotalPagesActive(calculatedTotalPages);
                     }

                     if (response.data.data.length.completedData_length) {
                        const calculatedTotalPages = Math.ceil(response.data.data.length.completedData_length / 10);
                        setTotalPageCompleted(calculatedTotalPages);
                     }
                     if (response.data.data.length.expireData_length) {
                        const calculatedTotalPages = Math.ceil(response.data.data.length.expireData_length / 10);
                        setTotalPageExpired(calculatedTotalPages);
                     }
                  }
               })
               .catch((error) => { console.error(error); });
         } catch (error) { console.log(error); }

      })();
   }


   useEffect(() => {
      const queryParameters = new URLSearchParams(window.location.search);
      const screenTypeAction = queryParameters.get("type");
      if (screenTypeAction) { setcurrentScreen(screenTypeAction); }
      getAllJobListing();
   }, [shortByFilter]);

   useEffect(() => { getAllJobListing(); }, [currentActivePage, callingScreenType,shortBySearchtype]);
   //const dasboardScreenAction = () => { }


   const RenderActivePaginationNumbers = () => {
      const paginationNumbers = [];
      paginationNumbers.push(<li className="page-item" onClick={(e) => handlePageChange('active', currentPageActive - 1)} ><a className="page-link" href="#"><i className="fa fa-angle-left" aria-hidden="true"></i></a></li>)
      for (let i = 1; i <= totalPagesActive; i++) {
         paginationNumbers.push(<li key={i} onClick={() => handlePageChange('active', i)} className={i === currentPageActive ? 'page-item active' : 'page-item'} ><a className="page-link" href="#">{i}</a></li>);
      }
      paginationNumbers.push(<li className="page-item" onClick={(e) => handlePageChange('active', currentPageActive + 1)} ><a className="page-link" href="#"><i className="fa fa-angle-right" aria-hidden="true"></i></a></li>)
      return paginationNumbers;
   };

   const RenderCompletedPaginationNumbers = () => {
      const paginationNumbers = [];
      paginationNumbers.push(<li className="page-item" onClick={(e) => handlePageChange('completed', currentPagecompleted - 1)} ><a className="page-link" href="#"><i className="fa fa-angle-left" aria-hidden="true"></i></a></li>)
      for (let i = 1; i <= totalPageCompleted; i++) {
         paginationNumbers.push(<li key={i} onClick={() => handlePageChange('completed', i)} className={i === currentPagecompleted ? 'page-item active' : 'page-item'} ><a className="page-link" href="#">{i}</a></li>);
      }
      paginationNumbers.push(<li className="page-item" onClick={(e) => handlePageChange('completed', currentPagecompleted + 1)} ><a className="page-link" href="#"><i className="fa fa-angle-right" aria-hidden="true"></i></a></li>)
      return paginationNumbers;
   };

   const RenderExpiredPaginationNumbers = () => {
      const paginationNumbers = [];
      paginationNumbers.push(<li className="page-item" onClick={(e) => handlePageChange('expired', currentPageExpired - 1)} ><a className="page-link" href="#"><i className="fa fa-angle-left" aria-hidden="true"></i></a></li>)
      for (let i = 1; i <= totalPageExpired; i++) {
         paginationNumbers.push(<li key={i} onClick={() => handlePageChange('expired', i)} className={i === currentPageExpired ? 'page-item active' : 'page-item'} ><a className="page-link" href="#">{i}</a></li>);
      }
      paginationNumbers.push(<li className="page-item" onClick={(e) => handlePageChange('expired', currentPageExpired + 1)} ><a className="page-link" href="#"><i className="fa fa-angle-right" aria-hidden="true"></i></a></li>)
      return paginationNumbers;
   };

   const RenderDraftPaginationNumbers = () => {
      const paginationNumbers = [];
      paginationNumbers.push(<li className="page-item" onClick={(e) => handlePageChange('draft', currentPageDraft - 1)} ><a className="page-link" href="#"><i className="fa fa-angle-left" aria-hidden="true"></i></a></li>)
      for (let i = 1; i <= totalPagesDraft; i++) {
         paginationNumbers.push(<li key={i} onClick={() => handlePageChange('draft', i)} className={i === currentPageDraft ? 'page-item active' : 'page-item'} ><a className="page-link" href="#">{i}</a></li>);
      }
      paginationNumbers.push(<li className="page-item" onClick={(e) => handlePageChange('draft', currentPageDraft + 1)} ><a className="page-link" href="#"><i className="fa fa-angle-right" aria-hidden="true"></i></a></li>)
      return paginationNumbers;
   };

   const handlePageChange = (type, newPage) => {
      setCurrentActivePage(newPage);
      setCallingScreenType(type);
      getAllJobListing();
      setCurrentPageActive(1); setCurrentPageDraft(1); setCurrentPagecompleted(1); setCurrentPageExpired(1);
      if (type == 'active' && newPage >= 1 && newPage <= totalPagesActive) { setCurrentPageActive(newPage); }
      if (type == 'draft' && newPage >= 1 && newPage <= totalPagesDraft) { setCurrentPageDraft(newPage); }
      if (type == 'completed' && newPage >= 1 && newPage <= totalPageCompleted) { setCurrentPagecompleted(newPage); }
      if (type == 'expired' && newPage >= 1 && newPage <= totalPageExpired) { setCurrentPageExpired(newPage); }
   };


   /*--------------deletefindStaffJob----------------*/
   const [isResumeModalShow, isSetResumeModalShow] = useState(false);
   const [isDataRemoving, setIsDataRemoving] = useState(false);
   const [deleteItemID, setDeleteItemID] = useState(0);
   const [deleteItemType, setDeleteItemType] = useState(0);

   const closeJobDeleteModal = () => { setIsDataRemoving(false); isSetResumeModalShow(false) };


   const showDeleteeModal = (itemid, type) => { isSetResumeModalShow(true); setDeleteItemID(itemid); setDeleteItemType(type) };


   const deletefindStaffJob = () => {
      //isSetResumeModalShow(true);
      setIsDataRemoving(true)
      if (isResumeModalShow) {
         (async () => {
            try {
               const formData = new FormData();
               axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/findstaffdata_form_remove/${useriddata.login_token}/${deleteItemID}/${deleteItemType}?action_type=${(deleteItemType == 'draft') ? 'delete_job' : 'close_job'}`, formData)
                  .then((response) => {
                     if (response.status == 200 && response.data.status == 1) {
                        //console.log(response.data)
                        toast.success('Removed Successfully!');
                        getAllJobListing();
                        closeJobDeleteModal();
                     }
                  })
                  .catch((error) => { console.error(error); });
            } catch (error) { console.log(error); }

         })();
      }
   }

   const dispatch = useDispatch();

   /*----------------Reschedule --------------------*/
   //{modalstatus:false , foemid:0, formstatus:'active'}
   const [isRescheduleModalShow, setisRescheduleModalShow] = useState(false);



   return (
      <>
         {/* <ToastContainer />*/}
         <div className="dashboarwrap">
            {
               (userdetails.email_status == 1 && userdetails.contact_status == 1) ?
                  <>
                     <div className="jobbanner">
                        <div className="container">
                           <div className="row">
                              <div className="col-md-12">
                                 <h2>Dashboard</h2>
                                 <form className="searchform d-flex">
                                    <input type="text" className="form-control" placeholder="Search for task"></input><input type="submit" value="Search"></input>
                                 </form>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="jobbtmsec py-4">
                        <div className="container">
                           <div className="row">
                              <div className="col-md-12">
                                 <ul className="nav nav-tabs jobtab d-flex" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                       <button onClick={(e) => (setcurrentScreen('active_job'))} className={`nav-link jobtablink ${currentScreen == 'active_job' ? 'active' : ''}`} id="activejob-tab">Active offer</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                       <button onClick={(e) => (setcurrentScreen('completed_job'))} className={`nav-link jobtablink ${currentScreen == 'completed_job' ? 'active' : ''}`} id="completedjob-tab">Completed offer</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                       <button onClick={(e) => (setcurrentScreen('expired_job'))} className={`nav-link jobtablink ${currentScreen == 'expired_job' ? 'active' : ''}`} id="pausedjob-tab" >Expired offer</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                       <button onClick={(e) => (setcurrentScreen('draft_job'))} className={`nav-link jobtablink ${currentScreen == 'draft_job' ? 'active' : ''}`} id="draftedjob-tab">Drafted offer</button>
                                    </li>
                                 </ul>
                                 <div className="tab-content" id="myTabContent">
                                    <div className="row">
                                       <div className="col-md-3 recurutersidbar">
                                          <ChatwidgetList />
                                       </div>
                                       <div className="col-md-9">

                                          {findStaffHistoryLoading ?
                                             <>
                                                <div className={`tab-pane mt45px`} >
                                                   <div className='loader_container'>
                                                      <p className='text-center'><img className='width-100 loader_image' src={'/front/images/loader.gif'} /></p>
                                                   </div>
                                                </div>
                                             </> :
                                             <>
                                                <div className="d-flex flex-wrap justify-content-between mb-2">
                                                   {currentScreen == 'active_job' && <h3>{!findStaffHistoryLoading ? `${jobDataLength.activedata_length} Active job offers` : ''}</h3>}
                                                   {currentScreen == 'completed_job' && <h3>{jobDataLength.completedData_length} Completed job offers</h3>}
                                                   {currentScreen == 'expired_job' && <h3>{jobDataLength.expireData_length} Expired job offers</h3>}
                                                   {currentScreen == 'draft_job' && <h3>{!findStaffHistoryLoading ? `${jobDataLength.draftdata_length} Draft job offers` : ''}</h3>}

                                                  <div className="sort_option d-flex"> 
                                                      <p>Sort by</p>
                                                      {/* shortByTime shortBySearchtype */}
                                                     
                                                      <select onChange={(e) => shortByFilterfun(e.target.value, 'weekly')} className="form-control">
                                                         {
                                                            ShortByFilter.map(
                                                               (item, index) => (
                                                                  <option selected={shortByFilter == item.value ? true :false}  value={item.value}>{item.name}</option>
                                                               )
                                                            )
                                                         }
                                                      </select>
                                                      <select onChange={(e) => setShortBySearchtype(e.target.value)} className="form-control ml-2">
                                                         {
                                                            ShortByQuickSearch.map(
                                                               (item, index) => (
                                                                  <option selected={shortBySearchtype == item.value ? true :false}  value={item.value}>{item.name}</option>
                                                               )
                                                            )
                                                         }
                                                      </select>
                                                   </div>
                                                </div>
                                                {/* tab section 1 active job */}
                                                {currentScreen == 'active_job' && <div className={`tab-pane fade  ${currentScreen == 'active_job' ? 'show active' : ''}`} id="activejob" role="tabpanel" aria-labelledby="activejob-tab">

                                                   <div className="joblistingwrap">
                                                      {
                                                         findStaffHistoryLoading ?
                                                            <>
                                                               <div className='loader_container'>
                                                                  <p className='text-center'><img className='width-100 loader_image' src={'/front/images/loader.gif'} /></p>
                                                               </div>
                                                            </>
                                                            :
                                                            <>
                                                               {
                                                                  (findStaffActiveJobs.length) ?
                                                                     <>
                                                                        {
                                                                           findStaffActiveJobs.map((item, index) => (
                                                                              <>
                                                                                 <div key={item.id} className="card-box joblistbox">
                                                                                    <div className="d-flex flex-wrap justify-content-between">
                                                                                       <h3 className="text-capitalize">{item.job_title_text}</h3>
                                                                                       <h3 className="job_price orange_clr">

                                                                                          ${(item.fixed_salary != '0.00') ? item.fixed_salary : `${item.salary_minimum}/Hour to ${item.salary_maximum}/Hour`}
                                                                                       </h3>
                                                                                    </div>
                                                                                    <div>
                                                                                    
                                                                                      {item.searchtype == 'manually' ? <span className="tags">{item.job_type_text}</span> : <span className="tags">{item.industry_text}</span>}
                                                                                       <span className="tags float-right bgdarkgreen">{item.searchtype == 'quick_search' ? 'Quick Search' : 'Manually search'}</span>
                                                                                    </div>
                                                                                    {item.searchtype == 'manually' ? <div className="d-flex flex-wrap">
                                                                                       <div className="icontxt"><img src="/front/images/calender.png"></img> Offer date: <span>{item.created_date_format}</span></div>
                                                                                       <div className="icontxt"><img src="/front/images/clock.png"></img> Offer expire date: <span>{item.expired_date_format}</span></div>
                                                                                       <div className="icontxt"><img src="/front/images/location.png"></img> Work location: <span>{item.job_location_text}</span></div>
                                                                                       <div className="icontxt"><img src="/front/images/bag.png"></img> Job type: <span>{item.job_type_text}</span></div>
                                                                                       <div className="icontxt"><img src="/front/images/star.png"></img> Experience: <span>{item.total_experience_year + '.' + item.total_experience_month}</span></div>
                                                                                       <div className="icontxt"><i className="fa fa-usd" aria-hidden="true"></i>Salary type: <span>{item.fixed_salary == '0.00' ? 'Hourly' : 'Fixed'}</span></div>
                                                                                    </div> :
                                                                                       (
                                                                                          <>
                                                                                             <div className="d-flex flex-wrap">
                                                                                                <div className="icontxt"><img src="/front/images/location.png"></img> Work location: <span>{item.job_location}</span></div>
                                                                                                <div className="icontxt"><img src="/front/images/bag.png" /> Number of staff: <span>{item.staff_number}</span></div>
                                                                                             </div>
                                                                                          </>
                                                                                       )
                                                                                    }

                                                                                    <div className="d-flex flex-wrap">
                                                                                       <Link onClick={() => dispatch(recdeshjobactioncheck({ id: item.id, actiontype: 'preview', screentype: 'active_job' , searchtype: item.searchtype }))} className="icontxt icontxtbtm icontxtview"><img src="/front/images/eyeicon.png"></img> Preview</Link>
                                                                                       <Link onClick={() => dispatch(recdeshjobactioncheck({ id: item.id, actiontype: 'edit', screentype: 'active_job' , searchtype: item.searchtype }))} className="icontxt icontxtbtm icontxtedit"><img src="/front/images/editicon.png"></img>Update </Link>
                                                                                       <Link to={'/sendoffer-candidate?type=active_job&offerid=' + item.id + '&jobtype=' + (item.searchtype ? item.searchtype : 'manually')} className="icontxt icontxtbtm icontxtedit"><img src="/front/images/user.svg"></img>Candidates</Link>
                                                                                       <Link onClick={() => showDeleteeModal(item.id, 'active')} className="icontxt icontxtbtm icontxtdelete"><img src="/front/images/deletenew.png"></img> Close job</Link>
                                                                                       {item.searchtype == 'quick_search' && <Link onClick={() => setisRescheduleModalShow(true)} className="icontxt icontxtbtm icontxtedit"><img src="/front/images/reshedule.png"></img> Reschedule</Link>}
                                                                                       <div className="icontxt icontxtbtm statics">Statistic:
                                                                                          <span>
                                                                                             <span className='cursor_pointer total_accepted text-green' data-toggle="tooltip" data-placement="top" title="Total offer accepted" >{item.total_static_accepted}</span> |
                                                                                             <span className='cursor_pointer total_pending  text-red' data-toggle="tooltip" data-placement="top" title="Total offer pending" >{item.total_static_count}</span> |
                                                                                             <span className='cursor_pointer total_staff text-orange' data-toggle="tooltip" data-placement="top" title="Total Staff need" >{item.staff_number}</span>
                                                                                          </span>
                                                                                       </div>
                                                                                    </div>
                                                                                 </div>
                                                                              </>
                                                                           ))
                                                                        }

                                                                     </>
                                                                     :
                                                                     <>
                                                                        <div className="card-box joblistbox data_not_found_card">
                                                                           <p className='text-center text-bold'>Data history not found</p>
                                                                        </div>
                                                                     </>
                                                               }
                                                            </>
                                                      }

                                                   </div>
                                                   {
                                                      (findStaffActiveJobs.length && jobDataLength.activedata_length > 10) ?
                                                         <>
                                                            <div className="card-box">
                                                               <nav aria-label="Page navigation">
                                                                  <ul className="pagination mb-0 justify-content-center">
                                                                     <RenderActivePaginationNumbers />
                                                                  </ul>
                                                               </nav>
                                                            </div>
                                                         </>
                                                         :
                                                         <></>
                                                   }

                                                </div>}
                                                {/* tab section 2 complete job */}
                                                {currentScreen == 'completed_job' && <div className={`tab-pane fade  ${currentScreen == 'completed_job' ? 'show active' : ''}`} id="completedjob" role="tabpanel" aria-labelledby="completedjob-tab">
                                                  
                                                   <div className="joblistingwrap">
                                                      {
                                                         findStaffCompletedJobs.length ?
                                                            <>
                                                               {
                                                                  findStaffCompletedJobs.map((item, index) => (
                                                                     <>
                                                                        <div key={item.id} className="card-box joblistbox">
                                                                           <div className="d-flex flex-wrap justify-content-between">
                                                                              {item.job_title_text && <h3 className="text-capitalize">{item.job_title_text}</h3>}

                                                                              <h3 className="job_price orange_clr">
                                                                                 ${(item.fixed_salary != '0.00') ? item.fixed_salary : `${item.salary_minimum}/Hour to ${item.salary_maximum}/Hour`}
                                                                              </h3>
                                                                           </div>
                                                                           <div>
                                                                              {item.job_type_text && <span className="tags">{item.job_type_text}</span>}
                                                                              <span className="tags float-right bgdarkgreen">{item.searchtype == 'quick_search' ? 'Quick Search' : 'Manually search'}</span>
                                                                           </div>
                                                                      

                                                                           {item.searchtype == 'manually' ? <div className="d-flex flex-wrap">
                                                                              <div className="icontxt"><img src="/front/images/calender.png"></img> Offer date: <span>{item.created_date_format}</span></div>
                                                                              <div className="icontxt"><img src="/front/images/clock.png"></img> Offer End Date: <span>{item.expired_date_format}</span></div>
                                                                              <div className="icontxt"><img src="/front/images/location.png"></img> Work location: <span>{item.job_location_text}</span></div>
                                                                              <div className="icontxt"><img src="/front/images/bag.png"></img> Job type: <span>{item.job_type_text}</span></div>
                                                                              <div className="icontxt"><img src="/front/images/star.png"></img> Experience: <span>{item.total_experience_year + '.' + item.total_experience_month}</span></div>
                                                                           </div> :
                                                                              (
                                                                                 <>
                                                                                    <div className="d-flex flex-wrap">
                                                                                       <div className="icontxt"><img src="/front/images/location.png"></img> Work location: <span>{item.job_location}</span></div>
                                                                                       <div className="icontxt"><img src="/front/images/bag.png" /> Number of staff: <span>{item.staff_number}</span></div>
                                                                                    </div>
                                                                                 </>
                                                                              )
                                                                           }
                                                                           <div className="d-flex flex-wrap">
                                                                              <Link onClick={() => dispatch(recdeshjobactioncheck({ id: item.id, actiontype: 'preview', screentype: 'active_job' , searchtype: item.searchtype  }))} className="icontxt icontxtbtm icontxtview"><img src="/front/images/eyeicon.png"></img> Preview</Link>
                                                                              <Link to={'/sendoffer-candidate?type=completed_job&offerid=' + item.id + '&jobtype=' + (item.searchtype ? item.searchtype : 'manually')} className="icontxt icontxtbtm icontxtedit"><img src="/front/images/user.svg"></img>Candidates</Link>
                                                                              <Link onClick={() => dispatch(recdeshjobactioncheck({ id: item.id, actiontype: 'repost', screentype: 'active_job'  , searchtype: item.searchtype  }))} className="icontxt icontxtbtm icontxtview"><img src="/front/images/eyeicon.png"></img> Repost</Link>
                                                                              {item.searchtype == 'quick_search' && <Link onClick={() => setisRescheduleModalShow(true)} className="icontxt icontxtbtm icontxtedit"><img src="/front/images/reshedule.png"></img> Reschedule</Link>}
                                                                             </div>
                                                                        </div>
                                                                     </>
                                                                  ))
                                                               }
                                                            </>
                                                            :
                                                            <><div className="card-box joblistbox data_not_found_card"><p>There are no completed job</p></div></>
                                                      }
                                                   </div>
                                                   {
                                                      (findStaffCompletedJobs.length && jobDataLength.completedData_length > 10) ?
                                                         <>
                                                            <div className="card-box">
                                                               <nav aria-label="Page navigation">
                                                                  <ul className="pagination mb-0 justify-content-center">
                                                                     <RenderCompletedPaginationNumbers />
                                                                  </ul>
                                                               </nav>
                                                            </div>
                                                         </>
                                                         :
                                                         <></>
                                                   }
                                                </div>}
                                                {/* tab section 3 expired job */}
                                                {currentScreen == 'expired_job' && <div className={`tab-pane fade  ${currentScreen == 'expired_job' ? 'show active' : ''}`} id="pausedjob" role="tabpanel" aria-labelledby="pausedjob-tab" >
                                                   <div className="joblistingwrap">
                                                      {
                                                         (findStaffExpiredJobs.length) ?
                                                            <>
                                                               {
                                                                  findStaffExpiredJobs.map((item, index) => (
                                                                     <>
                                                                        <div key={item.id} className="card-box joblistbox">
                                                                           <div className="d-flex flex-wrap justify-content-between">
                                                                              <h3 className="text-capitalize">{item.job_title_text}</h3>
                                                                              {/* <h3 className="job_price orange_clr">${item.salary_minimum}/Hour to ${item.salary_maximum}/Hour</h3> */}
                                                                              <h3 className="job_price orange_clr">
                                                                                 ${(item.fixed_salary != '0.00') ? item.fixed_salary : `${item.salary_minimum}/Hour to ${item.salary_maximum}/Hour`}
                                                                              </h3>
                                                                           </div>
                                                                           <div>
                                                                              <span className="tags">{item.job_type_text}</span>
                                                                              <span className="tags float-right bgdarkgreen">{item.searchtype == 'quick_search' ? 'Quick Search' : 'Manually search'}</span>
                                                                           </div>
                                                                           <div className="d-flex flex-wrap">
                                                                              <div className="icontxt"><img src="/front/images/calender.png"></img> Offer date: <span>{item.created_date_format}</span></div>
                                                                              <div className="icontxt"><img src="/front/images/clock.png"></img> Offer expired on: <span>{item.expired_date_format}</span></div>
                                                                              <div className="icontxt"><img src="/front/images/location.png"></img> Work location: <span>{item.job_location_text}</span></div>
                                                                              <div className="icontxt"><img src="/front/images/bag.png"></img> Job type: <span>{item.job_type_text}</span></div>
                                                                              <div className="icontxt"><img src="/front/images/star.png"></img> Experience: <span>{item.total_experience_year + '.' + item.total_experience_month}</span></div>
                                                                           </div>
                                                                           <div className="d-flex flex-wrap">
                                                                              <Link onClick={() => dispatch(recdeshjobactioncheck({ id: item.id, actiontype: 'preview', screentype: 'expired_job' , searchtype: item.searchtype  }))} className="icontxt icontxtbtm icontxtview"><img src="/front/images/eyeicon.png"></img> Preview</Link>
                                                                              <Link to={'/sendoffer-candidate?type=expired_job&offerid=' + item.id + '&jobtype=' + (item.searchtype ? item.searchtype : 'manually')} className="icontxt icontxtbtm icontxtedit"><img src="/front/images/user.svg"></img>Candidates</Link>
                                                                              {/* <Link onClick={() => showDeleteeModal(item.id, 'draft')} className="icontxt icontxtbtm icontxtdelete"><img src="/front/images/deletenew.png"></img> Delete</Link> */}
                                                                              <Link onClick={() => dispatch(recdeshjobactioncheck({ id: item.id, actiontype: 'repost', screentype: 'expired_job'  , searchtype: item.searchtype  }))} className="icontxt icontxtbtm icontxtview"><img src="/front/images/eyeicon.png"></img> Repost</Link>
                                                                              <div className="icontxt icontxtbtm statics">Statistic:
                                                                                 <span>
                                                                                    <span className='cursor_pointer total_accepted text-green' data-toggle="tooltip" data-placement="top" title="Total offer accepted" >{item.total_static_accepted}</span> |
                                                                                    <span className='cursor_pointer total_pending  text-red' data-toggle="tooltip" data-placement="top" title="Total offer pending" >{item.total_static_count}</span> |
                                                                                    <span className='cursor_pointer total_staff text-orange' data-toggle="tooltip" data-placement="top" title="Total Staff need" >{item.staff_number}</span>
                                                                                 </span>
                                                                              </div>
                                                                           </div>
                                                                        </div>
                                                                     </>
                                                                  ))
                                                               }
                                                            </>
                                                            :
                                                            <><div className="card-box joblistbox data_not_found_card"><p>There are no expired job</p></div></>
                                                      }
                                                   </div>

                                                   {
                                                      (findStaffExpiredJobs.length && jobDataLength.expireData_length > 10) ?
                                                         <>
                                                            <div className="card-box">
                                                               <nav aria-label="Page navigation">
                                                                  <ul className="pagination mb-0 justify-content-center">
                                                                     <RenderExpiredPaginationNumbers />
                                                                  </ul>
                                                               </nav>
                                                            </div>
                                                         </>
                                                         :
                                                         <></>
                                                   }



                                                </div>}
                                                {/* tab section 4 draftedjob job */}
                                                {currentScreen == 'draft_job' && <div className={`tab-pane fade  ${currentScreen == 'draft_job' ? 'show active' : ''}`} id="draftedjob" role="tabpanel" aria-labelledby="draftedjob-tab">

                                                   <div className="joblistingwrap">
                                                      {
                                                         findStaffHistoryLoading ?
                                                            <>
                                                               <div className='loader_container'>
                                                                  <p className='text-center'><img className='width-100 loader_image' src={'/front/images/loader.gif'} /></p>
                                                               </div></>
                                                            :
                                                            <>
                                                               {
                                                                  findStaffHistory.length ?
                                                                     <>
                                                                        {
                                                                           findStaffHistory.map((item, index) => (
                                                                              <>
                                                                                 <div className="card-box joblistbox">
                                                                                    <div className="d-flex flex-wrap justify-content-between">
                                                                                       <h3 className="text-capitalize">{item.job_title_text}</h3>
                                                                                       {/* <h3 className="job_price orange_clr">${item.salary_minimum}/Hour to ${item.salary_maximum}/Hour</h3> */}
                                                                                       <h3 className="job_price orange_clr">
                                                                                          ${(item.fixed_salary != '0.00') ? item.fixed_salary : `${item.salary_minimum}/Hour to ${item.salary_maximum}/Hour`}
                                                                                       </h3>
                                                                                    </div>
                                                                                    <div>
                                                                                      {item.searchtype == 'manually' ? <span className="tags">{item.job_type_text}</span> : <span className="tags">{item.industry_text}</span>}
                                                                                       <span className="tags float-right bgdarkgreen">{item.searchtype == 'quick_search' ? 'Quick Search' : 'Manually search'}</span>
                                                                                    </div>
                                                                                    {item.searchtype == 'manually' ? <div className="d-flex flex-wrap">
                                                                                       <div className="icontxt"><img src="/front/images/calender.png"></img> Offer date: <span>{item.created_date_format}</span></div>
                                                                                       <div className="icontxt"><img src="/front/images/clock.png"></img> Offer expire date: <span>{item.expired_date_format}</span></div>
                                                                                       <div className="icontxt"><img src="/front/images/location.png"></img> Work location: <span>{item.job_location_text}</span></div>
                                                                                       <div className="icontxt"><img src="/front/images/bag.png"></img> Job type: <span>{item.job_type_text}</span></div>
                                                                                       <div className="icontxt"><img src="/front/images/star.png"></img> Experience: <span>{item.total_experience_year + '.' + item.total_experience_month}</span></div>
                                                                                    </div> :
                                                                                       (
                                                                                          <>
                                                                                             <div className="d-flex flex-wrap">
                                                                                                <div className="icontxt"><img src="/front/images/location.png"></img> Work location: <span>{item.job_location}</span></div>
                                                                                                <div className="icontxt"><img src="/front/images/bag.png" /> Number of staff: <span>{item.staff_number}</span></div>
                                                                                             </div>
                                                                                          </>
                                                                                       )
                                                                                    }
                                                                                    <div className="d-flex flex-wrap">
                                                                                       {/* <Link to={'/manual-search?type=draft_job&jobofferid='+item.id} className="icontxt icontxtbtm icontxtview"><img src="/front/images/eyeicon.png"></img> Preview</Link> */}
                                                                                       <Link onClick={() => dispatch(recdeshjobactioncheck({ id: item.id, actiontype: 'edit', screentype: 'draft'  , searchtype: item.searchtype }))} className="icontxt icontxtbtm icontxtedit"><img src="/front/images/editicon.png"></img>Edit </Link>
                                                                                       <Link onClick={() => showDeleteeModal(item.id, 'draft')} className="icontxt icontxtbtm icontxtdelete"><img src="/front/images/deletenew.png"></img> Delete</Link>
                                                                                       {/* <Link className="icontxt icontxtbtm icontxtoause"><img src="/front/images/active.png"></img> Activate</Link> */}
                                                                                    </div>
                                                                                 </div>
                                                                              </>
                                                                           ))
                                                                        }

                                                                     </>
                                                                     :
                                                                     <><div className="card-box joblistbox data_not_found_card"><p>There are no drafted job</p></div></>
                                                               }
                                                            </>
                                                      }





                                                   </div>


                                                   {
                                                      (findStaffHistory.length && jobDataLength.draftdata_length > 10) ?
                                                         <>
                                                            <div className="card-box">
                                                               <nav aria-label="Page navigation">
                                                                  <ul className="pagination mb-0 justify-content-center">
                                                                     <RenderDraftPaginationNumbers />
                                                                  </ul>
                                                               </nav>
                                                            </div>
                                                         </>
                                                         :
                                                         <></>
                                                   }
                                                </div>}
                                             </>
                                          }

                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>

                     {/* modals */}
                     <Modal show={isResumeModalShow} onHide={closeJobDeleteModal}>
                        <div className="modal-dialog1 findastaffmodle">
                           <div className="modal-content">
                              <div className="modal-body p-5">
                                 <button type="button" className="btn-close" onClick={closeJobDeleteModal}></button>
                                 {/* <div className="text-center mb-3">
                                       <img src="/front/images/deletenew.png" />
                                    </div> */}
                                 {
                                    (deleteItemType == 'draft') ?
                                       <>
                                          <h4 className="text-center mb-4">Do you want to close this job?</h4>
                                       </>
                                       :
                                       <>
                                          <h4 className="text-center ">Do you want to close this job?</h4>
                                          <p className="text-center mb-3"><i className="fa fa-info-circle"></i> This job will be moved in completed job</p>
                                       </>
                                 }

                                 <div className="btnsgroup text-center">
                                    <Link onClick={deletefindStaffJob} className="batn batn-orange" data-bs-dismiss="modal">Yes</Link>
                                    <Link onClick={closeJobDeleteModal} className="batn batn-orange batn-border">No</Link>
                                 </div>
                                 {isDataRemoving ? <><h5 className="text-center mb-4 mt-4">Loading......</h5></> : <></>}
                              </div>
                           </div>
                        </div>
                     </Modal>


                     {/* modals */}
                     <Modal show={isRescheduleModalShow} onHide={() => setisRescheduleModalShow(false)}>
                        <div className="modal-dialog1 findastaffmodle">
                           <div className="modal-body p-3">
                              <button type="button" className="btn-close" onClick={() => setisRescheduleModalShow(false)} ></button>
                              <h3>Job Reschedule</h3>
                              <div className="commonform">
                                 <form>
                                    <div className="row">
                                       <div className="col-md-12 form-group mb-2">
                                          <label className="form-label" for="exampleInputEmail1">Rescheduled time<span className="starr">*</span></label>
                                          <input type="time" className="form-control" placeholder="Enter the rescheduled time"></input>
                                       </div>
                                       <div className="col-md-12 form-group mb-2">
                                          <label className="form-label" for="exampleInputEmail1">Resone for Reschedule.<span className="starr">*</span></label>
                                          <textarea className="form-control" name="reschedule" maxlength="500" required="" rows="4" placeholder="Enter the resone for reschedule"></textarea>
                                       </div>
                                       <div className="col-md-12 form-group mt-3"><button type="submit" className="batn batn-orange w-100" fdprocessedid="vhjem">Send Request</button></div>
                                    </div>
                                 </form>
                              </div>
                           </div>
                        </div>
                     </Modal>



                  </>
                  :
                  <><ProfileNotCompleted /></>
            }


         </div>
      </>
   );
}
export default RecruiterDashboard;