import React, { useState, useEffect, Component } from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink, useParams, useNavigate, Link } from 'react-router-dom';
import { Form, Button, Col, InputGroup, Modal, Row } from 'react-bootstrap';

import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';
//hideAndSeekMyRequestModel
import SuperAdminSidebar from './superadmin_sidebar';
import SuppReqConverationComp from '../SuppReqConverationComp';
import LiveChatWindow from './AdminLiveChatWindow';
// import SoundLib from './../SoundLib';

import { Howl } from 'howler';
// Import the sound file
import beepSound from '../soundlib/notification_sound.mp3';

const AdminLiveSuppot = () => {

    const typeofpriority = ['High', 'Medium', 'Low'];
    const statusType = ['All', 'Open', 'Awating', 'Solved'];
    const [liveChatModule, setLiveChatModule] = useState(false);
    const useriddata = JSON.parse(localStorage.getItem("squadGologinSession"));
    const [orderByData, setorderByData] = useState('ASC');
    // const [handleInput, sethandleInput] = useState('');
    const [myRequestDataArr, setmyRequestDataArr] = useState([]);
    const [chatHistoryData, setchatHistoryData] = useState([]);
    const [myRequestDataArrCopy, setmyRequestDataArrCopy] = useState([]);
    const [screenLoader, setScreenLoader] = useState(true);
    const [currentEditId, setcurrentEditId] = useState(0);
    const [getUnreadMessage, setUnreadMessage] = useState(0);
    //total_unread
    const [isBlockModelShow, setIsBlockModelShow] = useState(false);
    const [searchValue, setsearchValue] = useState('');
    const playBeep = () => { const sound = new Howl({ src: [beepSound], }); sound.play(); };
    const recTyeOption = [
        { key: 'pending', value: 'New' },
        { key: "taken", value: "Taken" },
    ];

    const showUserBlockActionExecute = () => {
        get_requesteddata("https://squadgoo.com.au/api.squadgoo.com.au/livesupport_tickets/" + useriddata.login_token + '?blockid=' + currentEditId + '&blockstatus=pending&search=' + searchValue + '&order_by=' + orderByData)
    }

    const get_requesteddata = (apiurl) => {
        setScreenLoader(false);
        (async () => {
            try {
                const resData = await axios.get(apiurl);
                if (resData.data.status == 1) {
                    setchatHistoryData(resData.data.data.history_data);
                    setmyRequestDataArr(resData.data.data.tickets);
                    setIsBlockModelShow(false);
                    setmyRequestDataArrCopy(resData.data.data.tickets);
                    setUnreadMessage(resData.data.data.total_unread);
                    setScreenLoader(false);
                    console.log(resData.data.data.livesupportchat)
                    if (resData.data.data.total_unread != 0) {
                        playBeep();
                    }
                }
            } catch (error) { console.log(error); }
        })();
    }


    useEffect(() => {
        if (useriddata) {
            get_requesteddata("https://squadgoo.com.au/api.squadgoo.com.au/livesupport_tickets/" + useriddata.login_token + '?blockid=' + currentEditId + '&blockstatus=pending&search=' + searchValue + '&order_by=' + orderByData);
            // const intervalId = setInterval(() => {
            //     get_requesteddata("https://squadgoo.com.au/api.squadgoo.com.au/livesupport_tickets/" + useriddata.login_token + '?callby=autoload&blockid=' + currentEditId + '&blockstatus=pending&search=' + searchValue + '&order_by=' + orderByData);
            // }, 5000);
            // return () => { clearInterval(intervalId); };
        }
    }, [orderByData, searchValue]);



    const handleInput = (event) => {
        // setSearchTerm(event.target.value);
        let term = event.target.value;
        if (term.length > 0 && term != 'All') {

            const filteredData = myRequestDataArr.filter((item) =>
                item.user_details.first_name.toLowerCase().includes(term.toLowerCase()) ||
                item.user_details.last_name.toLowerCase().includes(term.toLowerCase()) ||
                item.user_details.contact_number.toLowerCase().includes(term.toLowerCase())
            );
            //user_details.first_name last_name contact_number
            setmyRequestDataArr(filteredData)
        } else {
            setmyRequestDataArr(myRequestDataArrCopy)
        }
    };




    const [chatWindows, setChatWindows] = useState([]);

    const openChatWindow = (rowId) => {
        if (!chatWindows.includes(rowId)) {
            setChatWindows((prevChatWindows) => [...prevChatWindows, rowId]);
        }
    };

    const closeChatWindow = (rowId) => {
        setChatWindows((prevChatWindows) =>
            prevChatWindows.filter((windowId) => windowId !== rowId)
        );
    };



    const [isStaffModelShow, setIsStaffModelShow] = useState(false);
    const [isDataLodading, setIsDataLodading] = useState(false);
    const [currentWindowIsShowing, setCurrentWindowIsShowing] = useState('open_chat');
    const [getItemData, setItemData] = useState([]);
    const [staffDataArray, setStaffDataArray] = useState([]);
    const [supportMessageArray, setSupportMessageArray] = useState([]);
    const [chatHistoryModalShow, setChatHistoryModalShow] = useState(false);
    
    const getAdminStaffData = (data) => {
        (async () => {
            setIsDataLodading(false);
            setItemData(data);
            try {
                const resGet = await axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/api/admin/admin_staff_data/${useriddata.login_token}`);
                if (resGet.data.status == 1) {
                    setIsStaffModelShow(true);
                    setIsDataLodading(true);
                    setStaffDataArray(resGet.data.data);
                    console.log(getItemData);
                }
            } catch (error) { console.log(error) }
        })();
    }
    
    const assingedToUser = (userId) => {
        (async () => {
            try {
                var formData = new FormData();
                formData.append('login_token', useriddata.login_token);
                formData.append('user_id', userId);
                formData.append('live_chat_id', getItemData.support_token_id);
                const getResp = await axios.post(`https://squadgoo.com.au/api.squadgoo.com.au/api/admin/live_chat_assing`, formData);
                if (getResp.data.status == 1) {
                    get_requesteddata("https://squadgoo.com.au/api.squadgoo.com.au/livesupport_tickets/" + useriddata.login_token + '?blockid=' + currentEditId + '&blockstatus=pending&search=' + searchValue + '&order_by=' + orderByData);
                    toast.success(getResp.data.message);
                    setIsStaffModelShow(false);
                }
            } catch (error) { }
        })();
    }
    
    
    const chatListStatusChange = (support_token_id, statusValue) => {
        (async () => {
            const formData = new FormData();
            formData.append('live_chat_id', support_token_id);
            formData.append('status', statusValue);
            formData.append('login_token', useriddata.login_token);
            const getRes = await axios.post(`https://squadgoo.com.au/api.squadgoo.com.au/api/admin/live_chat_statuschange`, formData);
            if (getRes.data.status == 1) {
                get_requesteddata("https://squadgoo.com.au/api.squadgoo.com.au/livesupport_tickets/" + useriddata.login_token + '?blockid=' + currentEditId + '&blockstatus=pending&search=' + searchValue + '&order_by=' + orderByData);
                toast.success(getRes.data.message);
            }
        })();
    }
    
    const [ischatHisLodading, setIschatHisLodading] = useState(false);
    const getChatHistory = (itemdata) => {
        setSupportMessageArray([]);
        setChatHistoryModalShow(true);
        setIschatHisLodading(false);
        (
            async () => {
                const getData = await axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/api/admin/support_message_by_token/${useriddata.login_token}/${itemdata.support_token_id}`);
                if (getData.data.status == 1) {
                    setSupportMessageArray(getData.data.data)
                    setIschatHisLodading(false);
                }
            }
        )();
    }


    

    const handleMinimizeChat = (userId) => {
        // Minimize chat window
        // You can implement this functionality as per your requirements
    };

    return (
        <>
            <div className="dashboarwrap">
                <div className="pagebanner">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2>Live chats</h2>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <ToastContainer />*/}
                <div className="jobbtmsec py-5 helppage">
                    <div className="container">

                        <div className="row">
                            <div className="col-md-3 adminsidebar">
                                <SuperAdminSidebar></SuperAdminSidebar>
                            </div>
                            <div className="col-md-9">
                                {/* <SoundLib /> */}

                                <div class="col-md-12 mb-2">

                                </div>
                                <div className="box mb-4">
                                    {
                                        <>
                                            <div className="table_filter mb-2 d-flex align-items-center justify-content-between commonform">
                                                <div className="tblsearch">
                                                    <input type="text" className="form-control"
                                                        placeholder="Search..."
                                                        onChange={handleInput}
                                                    />
                                                </div>


                                                {/* currentWindowIsShowing, setCurrentWindowIsShowing */}
                                                <div class="switch-field mr-0">
                                                    <div class="switchfieldinner">
                                                        <input type="radio" id="OpenChats" onClick={() => setCurrentWindowIsShowing('open_chat')} name="open_chat" checked={(currentWindowIsShowing == 'open_chat') ? true : false} />
                                                        <label for="OpenChats">Open Chats</label>
                                                    </div>
                                                    <div class="switchfieldinner">
                                                        <input type="radio" id="ChatHistory" onClick={() => setCurrentWindowIsShowing('chat_history')} name="chat_history" checked={(currentWindowIsShowing == 'chat_history') ? true : false} />
                                                        <label for="ChatHistory">Chat History</label>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                screenLoader ?
                                                    <>
                                                        <div className={`tab-pane fade show active mt45px`} >
                                                            <div className='loader_container'>
                                                                <p className='text-center'><img className='width-100 loader_image' src={'/front/images/loader.gif'} /></p>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <div className="table-responsive">
                                                        <table className="table admintbl profiletbl">
                                                            <thead>
                                                                <tr>
                                                                    {/* <th className='cursor_pointer'>ID</th> */}
                                                                    <th className='cursor_pointer' onClick={() => setorderByData((orderByData == 'ASC' ? 'DESC' : 'ASC'))}>
                                                                        ID
                                                                        {orderByData == 'ASC' ? <i className="fa fa-angle-down ml-5"></i> : <i className="fa fa-angle-up ml-5"></i>}
                                                                    </th>
                                                                    <th>Request by</th>
                                                                    <th>Assign to</th>
                                                                    <th>Contact</th>
                                                                    <th>Request date</th>
                                                                    {/* <th>Status</th> */}
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <>
                                                                    {
                                                                        (currentWindowIsShowing == 'open_chat' && myRequestDataArr.length) ?
                                                                            <>
                                                                                {
                                                                                    myRequestDataArr.map((item, index) => (
                                                                                        <tr>
                                                                                            <td>{item.support_token_id}</td>
                                                                                            <td className='text-capitalize'>
                                                                                                <Link className="text-website text-decoration-none1" to={`${item.user_details.user_type == 3 ? '/jobseeker-detail?backto=admintickethistory&id=' + item.user_details.unique_id : '/recruiter-detail?backto=adminlivehistory&id=' + item.user_details.unique_id}`} >
                                                                                                    {item.user_details.first_name + ' ' + item.user_details.last_name}
                                                                                                </Link>
                                                                                                {item.total_unread != 0 ? <span className="badge bg-danger rounded-pill ml-1">{item.total_unread}</span> : ''}
                                                                                            </td>
                                                                                            <td className='text-capitalize'>
                                                                                                {
                                                                                                    item.staff_details.length == 0 ? 'Not Assign' :
                                                                                                        <Link className="text-website text-decoration-none1" to={'/users?id=' + item.ticket_is_assigned} >{(item.staff_details.first_name + ' ' + item.staff_details.last_name)}</Link>
                                                                                                }
                                                                                            </td>
                                                                                            <td>
                                                                                                {
                                                                                                    item.user_details.contact_country_code
                                                                                                        ?
                                                                                                        <>+{item.user_details.contact_country_code}-{item.user_details.contact_number}</>
                                                                                                        :
                                                                                                        <>-----</>
                                                                                                }

                                                                                            </td>
                                                                                            <td>{item.created_date}</td>
                                                                                            {/* <td>
                                                                                                {item.support_status}
                                                                                                <select className="width-120 form-control ml-3 text-capitalize" onChange={(e) => chatListStatusChange(item.support_token_id, e.target.value)} >
                                                                                                    {
                                                                                                        recTyeOption.map((item2, index) => (
                                                                                                            <option selected={item2.key == item.support_status} value={item2.key} key={item2.key}>{item2.value}</option>
                                                                                                        ))
                                                                                                    }
                                                                                                </select>
                                                                                            </td> */}
                                                                                            <td>

                                                                                                <div className="d-flex">
                                                                                                    {
                                                                                                        chatWindows.includes(item.support_token_id) ?
                                                                                                            <span data-toggle="tooltip" title="Chat with user" 
                                                                                                            onClick={() => closeChatWindow(item.support_token_id)} 
                                                                                                            className="ml-3" >
                                                                                                                <i className="faicon fa fa-comment-o" aria-hidden="true"></i>
                                                                                                            </span>
                                                                                                            
                                                                                                            :
                                                                                                            <span data-toggle="tooltip" title="Chat with user" onClick={() => openChatWindow(item.support_token_id)} className="ml-3" >
                                                                                                                <i className="faicon fa fa-comment-o" aria-hidden="true"></i>
                                                                                                            </span>
                                                                                                    }

                                                                                                    <span onClick={() => getAdminStaffData(item)} className="ml-3 cursor_pointer" data-toggle="tooltip" title="Ticket assing to" >
                                                                                                        <i class="faicon fa fa-user-o ml-3" aria-hidden="true"></i>
                                                                                                    </span>


                                                                                                    <span onClick={() => getChatHistory(item)} className="ml-3 cursor_pointer" data-toggle="tooltip" title="Chat History" >
                                                                                                        <i className="faicon fa fa-history ml-3" aria-hidden="true"></i>
                                                                                                    </span>

                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    ))
                                                                                }
                                                                            </>
                                                                            :
                                                                            (currentWindowIsShowing == 'chat_history' && chatHistoryData.length) ?
                                                                                <>
                                                                                    {
                                                                                        chatHistoryData.map((item, index) => (
                                                                                            <tr>
                                                                                                <td>{item.support_token_id}</td>
                                                                                                <td className='text-capitalize'>
                                                                                                    <Link className="text-website text-decoration-none1" to={`${item.user_details.user_type == 3 ? '/jobseeker-detail?backto=admintickethistory&id=' + item.user_details.unique_id : '/recruiter-detail?backto=adminlivehistory&id=' + item.user_details.unique_id}`} >
                                                                                                        {item.user_details.first_name + ' ' + item.user_details.last_name}
                                                                                                    </Link>
                                                                                                    {item.total_unread != 0 ? <span className="badge bg-danger rounded-pill ml-1">{item.total_unread}</span> : ''}
                                                                                                </td>
                                                                                                <td className='text-capitalize'>
                                                                                                    {
                                                                                                        item.staff_details.length == 0 ? 'Not Assign' :
                                                                                                            <Link className="text-website text-decoration-none1" to={'/users?id=' + item.ticket_is_assigned} >{(item.staff_details.first_name + ' ' + item.staff_details.last_name)}</Link>
                                                                                                    }
                                                                                                </td>
                                                                                                <td>
                                                                                                    {
                                                                                                        item.user_details.contact_country_code
                                                                                                            ?
                                                                                                            <>+{item.user_details.contact_country_code}-{item.user_details.contact_number}</>
                                                                                                            :
                                                                                                            <>-----</>
                                                                                                    }

                                                                                                </td>
                                                                                                <td>{item.created_date}</td>
                                                                                                {/* <td>Completed</td> */}
                                                                                                <td>
                                                                                                    <span onClick={() => getChatHistory(item)} className="ml-3 cursor_pointer" data-toggle="tooltip" title="Chat History" >
                                                                                                        <i className="faicon fa fa-history" aria-hidden="true"></i>
                                                                                                    </span>
                                                                                                </td>
                                                                                            </tr>
                                                                                        ))
                                                                                    }
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    <tr>
                                                                                        <td className='text-center' colSpan={6}>No information available to display</td>
                                                                                    </tr>
                                                                                </>
                                                                    }


                                                                </>

                                                            </tbody>
                                                        </table>
                                                    </div>
                                            }
                                        </>
                                    }

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>


            <Modal className="modal fade modalsmall jobacceptmodle" id="jobacceptmodle" show={isBlockModelShow} onHide={() => setIsBlockModelShow(false)}>
                <div className="modal-dialog1">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button type="button" className="btn-close" onClick={() => setIsBlockModelShow(false)}></button>
                            <div className="text-center mb-3">
                                {/* <img src="/front/images/greencheck.svg" /> */}
                            </div>
                            <h5 className="text-center mb-4">Do you want to change status?</h5>

                            <div className="btnsgroup text-center">
                                <Link onClick={showUserBlockActionExecute} className="batn batn-orange" >Yes</Link>
                                <Link onClick={() => setIsBlockModelShow(false)} className="batn batn-orange batn-border">No</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>



            <Modal className="modal fade modalmediam chat_history_section" id="chat_history_section" show={chatHistoryModalShow} onHide={() => setChatHistoryModalShow(false)}>
                <div className="modal-dialog1">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button type="button" className="btn-close" onClick={() => setChatHistoryModalShow(false)}></button>
                            <div className='chats'>
                                {
                                    ischatHisLodading ?
                                    <>Loading History</>
                                    :
                                    (supportMessageArray.length == 0) ?
                                    <>content not found.</>
                                    :
                                    <>
                                      <h3>Chat History</h3>
                                      {
                                        supportMessageArray.map(message => (
                                            <div key={message.id} className="message">
                                                <h4 className='m-0'></h4>
                                                 <p dangerouslySetInnerHTML={{ __html: message.message }}></p>
                                                <p>Posted by: {message.first_name} {message.last_name} - {message.created_date}</p>
                                            </div>
                                        ))
                                      }
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>


            {/* isStaffModelShow, setIsStaffModelShow  staffDataArray*/}
            <Modal className="modal fade modalmediam staff_list_model" id="staff_list_model" show={isStaffModelShow} onHide={() => setIsStaffModelShow(false)}>
                <div className="modal-dialog1">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button type="button" className="btn-close" onClick={() => setIsStaffModelShow(false)}></button>
                            <div className="text-center mb-3">
                                {/* <img src="/front/images/greencheck.svg" /> */}
                            </div>
                            {/* <h5 className="text-center mb-4">Do you want to change status?</h5> */}
                            <div>
                                {
                                    (isDataLodading == false) ?
                                        <>Loading......</>
                                        :
                                        <>
                                            <div className='table-responsive'>


                                                <table className='table table-striped'>
                                                    <thead>
                                                        <th>Full Name</th>
                                                        <th>Assign to</th>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            (staffDataArray.length > 0)
                                                            &&
                                                            staffDataArray.map((item) => (
                                                                <>
                                                                    {

                                                                        (getItemData.ticket_is_assigned != item.user_id) &&
                                                                        < tr >
                                                                            <td className='text-capitalize'>{item.first_name + ' ' + item.last_name}</td>
                                                                            <td>
                                                                                <button onClick={() => assingedToUser(item.user_id)} className='btn btn-success'>
                                                                                    Assign
                                                                                </button>
                                                                            </td>
                                                                        </tr >
                                                                    }
                                                                </>

                                                            ))
                                                        }
                                                    </tbody>


                                                </table>
                                            </div>
                                        </>
                                }

                            </div>
                            {/* <div className="btnsgroup text-center">
                                <Link onClick={showUserBlockActionExecute} className="batn batn-orange" >Yes</Link>
                                <Link onClick={() => setIsStaffModelShow(false)} className="batn batn-orange batn-border">No</Link>
                            </div> */}
                        </div>
                    </div>
                </div>
            </Modal >

            {/* Render chat windows based on chatWindows state */}
            <div className='main-chatbox' >
                {
                    chatWindows.map((windowId) => (
                        <>
                            <div className="livechatbox">
                                <div className="card" id="chat2">
                                    <div onClick={() => closeChatWindow(windowId)} className="card-header d-flex justify-content-between align-items-center p-3">
                                        <h5 className="mb-0">Live Chat</h5>
                                        <span><i class="fa fa-angle-down" aria-hidden="true"></i></span>
                                    </div>
                                    <LiveChatWindow livechataction={windowId} />
                                </div>
                            </div>
                        </>
                    ))
                }
            </div>

    

        </>
    );
}


export default AdminLiveSuppot;