import React, { useCallback, useState, useEffect } from 'react';
import { Form, Modal } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux'
import { basicContactValidationCheck } from '../redux_state/reducres/checkDocumentstatus';

const ContactDetailsComp = ({ sendMessageToParent, userdetails }) => {
    const [timeIntervalSecond, setTimeIntervalSecond] = useState(0);
    const [contactModalShow, setcontactModalShow] = useState(false);
    const [seconds, setSeconds] = useState(0); // 300 seconds for 5 minutes
    const [isRunning, setIsRunning] = useState(true);

    useEffect(() => {
        (async () => {
            try {
                const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/code_will_be_expire");
                if (resData.data.status == 1) {
                    setTimeIntervalSecond(parseInt(resData.data.data));
                }
            } catch (error) { console.log(error); }
        })();
    }, []);

    /*--------------------------------------------------------------------*/

    const dispatch = useDispatch();
    const getNewSessionData = async () => {
        try {
            const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/userdetailforsession/" + userdetails.login_token);
            if (resData.data.status == 1) { localStorage.setItem('squadGologinSession', JSON.stringify(resData.data.data)) }
        } catch (error) { console.log(error); }
    }

    //console.log(userdetails);

    const [validated, setValidated] = useState(false);


    /*--------------------Email verification action -----------------------------*/
    const [varificationCode, setVarificationCode] = useState('');
    //const [validated, setValidated] = useState(false);
    const [serverResMessage, setServerResMessage] = useState('');
    const [serverSuccessMessage, setServerSuccessMessage] = useState('');
    const [isNumberVarified, setIsNumberVarified] = useState(0);
    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            const formData = new FormData();
            formData.append('unique_id', userdetails.unique_id);
            formData.append('contact_number', userdetails.contact_number);
            formData.append('contact_country_code', userdetails.contact_country_code);
            formData.append('contact_varification_code', varificationCode);
            axios.post('https://squadgoo.com.au/api.squadgoo.com.au/check_contact_activation', formData)
                .then((response) => {
                    if (response.data.status == 1) {
                        setIsNumberVarified(1);
                        setServerResMessage(response.data.message);
                        getNewSessionData();
                        dispatch(basicContactValidationCheck(1));
                        setTimeout(function () { closeModal(); }, 3000);
                        sendMessageToParent(true);
                        // if ((sendMessageToParent)) {
                        // }
                        toast.success(response.data.message);
                    }
                    else { setServerResMessage(response.data.message); setTimeout(function () { setServerResMessage('') }, 5000); }
                })
                .catch((error) => { console.error(error); });
        }
        setValidated(true);
    };

    const resendVerificationCode = (type = 'resend') => {
        const formData = new FormData();
        formData.append('unique_id', userdetails.unique_id);
        formData.append('contact_number', userdetails.contact_number);
        formData.append('contact_country_code', userdetails.contact_country_code);
        formData.append('contact_varification_code', varificationCode);
        axios.post(`https://squadgoo.com.au/api.squadgoo.com.au/contact_resend_verification?type=${type}`, formData)
            .then((response) => {
                if (response.data.status == 1) {
                    setSeconds(timeIntervalSecond);
                    setServerSuccessMessage(response.data.message);
                     setTimeout(function () { setServerSuccessMessage('') }, 5000);
                }
            })
            .catch((error) => { console.error(error); });
    }


    const closeModal = () => { setcontactModalShow(false); }

    const showModal = () => {
        console.log('show.......');
        setcontactModalShow(true); resendVerificationCode('init');
    }

    useEffect(() => {
        let interval = null;
        if (isRunning && seconds > 0) {
            interval = setInterval(() => { setSeconds((prevSeconds) => prevSeconds - 1); }, 1000);
        } else if (!isRunning && interval !== null) { clearInterval(interval); }

        return () => clearInterval(interval); // Cleanup on component unmount
    }, [isRunning, seconds]);



    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };
    const styleComp = { fontSize: '13px', color: 'red' }

    return (
        <>
            {/* <ToastContainer />*/}
            {/* ----------------------------------------- */}

            <span className="linktxt cursor_pointer" onClick={() => showModal()} >
                Verify mobile number
            </span>

            <Modal show={contactModalShow} onHide={closeModal}>
                <div className="modal-dialog1 findastaffmodle">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button type="button" className="btn-close" onClick={closeModal} data-bs-dismiss="modal" aria-label="Close"></button>
                            <div className="loginsignupwrapformwrap">
                                <Form noValidate validated={validated} onSubmit={handleSubmit} className="commonform lofinsignupform loginform">
                                    <div className="formbranding text-center"><img src="/front/images/formlogo.png" /></div>
                                    <div className="text-center my-4"><img src="/front/images/varifyicon.png" /></div>
                                    <h2 className="text-center mb-2">Verify your contact number</h2>
                                    <div className="text-center formbtmtxt mb-4">
                                        <p>An OTP has been sent to your contact number.</p>
                                    </div>
                                    <div className="form-group mb-2">
                                        <input
                                            required
                                            type="text"
                                            onChange={(event) => setVarificationCode(event.target.value)}
                                            className="form-control varificationfield"
                                            placeholder="Enter verification code"
                                        />
                                    </div>
                                    <div className="form-group mb-2 text-center">

                                        {(seconds > 0) ? <span style={styleComp}>{(seconds ? 'OTP code expire will be expired in : ' : 'Expired: ') + formatTime(seconds)}</span> :
                                            <><sapn className={`cursor_pointer  ${isNumberVarified == 1 ? 'text-success' : 'orange_clr'}`} onClick={resendVerificationCode} >Re-send verification code</sapn></>
                                        }

                                    </div>
                                    {serverResMessage ? <p className={`${isNumberVarified == 1 ? 'text-success' : 'text-danger'}  text-center  mb-0`} >{serverResMessage}</p> : ''}
                                    {serverSuccessMessage ? <p className={` text-success text-center  mb-0`} >{serverSuccessMessage}</p> : ''}
                                    <div className="form-group mb-2"><input type="Submit" className="batn batn-orange" value="Verify" /></div>

                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            {/* ----------------------------------------- */}
        </>
    );
}
export default ContactDetailsComp;