import React, { useState, useEffect } from 'react';

function DateTimeInput(prop) {
  const [minDate, setMinDate] = useState('');
  const [maxDate, setMaxDate] = useState('');
    //console.log(prop.day);
  useEffect(() => {
    // Get the current date and time
    const currentDate = new Date();
    const currentDateTimeISO = currentDate.toISOString().slice(0, 16);

   

    // Set min date to current date and time
    setMinDate(currentDateTimeISO);
    if(prop.startFrom){
      const tomorrowDate = new Date(currentDate);
      tomorrowDate.setDate(tomorrowDate.getDate() + prop.startFrom); // Add one day
      const tomorrowDateTimeISO = tomorrowDate.toISOString().slice(0, 16);
      setMinDate(tomorrowDateTimeISO);
    } 
    

    // Set max date to current date plus five days
    const maxDateTime = new Date(currentDate);
    maxDateTime.setDate(maxDateTime.getDate() + prop.day);
    const maxDateTimeISO = maxDateTime.toISOString().slice(0, 16);
    setMaxDate(maxDateTimeISO);
  }, []);

  return (
    <input
      type="datetime-local"
      id="schedule_time"
      name="schedule_date_time"
      min={minDate}
      max={maxDate}
      className="form-control form-control-lg"
    />
  );
}

export default DateTimeInput;