import React from 'react';

const NotFound = () => {

    console.log('asdss');
  return (
    <div className="trrmssec">
        <div className="container">
            <div className="row">
              <div className="col-md-3">
              </div>
               <div className="col-md-6">
                     <img src="/front/images/404.png" className="img-fluid"></img>
                     <h2 className="text-center">Oops! You've Found a Lost Page</h2>
                     <p className="text-center">It seems like you've stumbled upon a page that has gone on a little adventure of its own. Don't worry; you're not lost in cyberspace – just a bit off the beaten path. Let's help you get back on track:</p>
                     <div className="text-center my-3"><a className="batn batn-orange" href="/">Back To Home</a></div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default NotFound;