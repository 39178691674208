import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

function DateFilter({ onDateChange }) {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleDateChange = () => {
    // Pass the selected date range to the parent component for filtering
    onDateChange(startDate, endDate);
  };

  return (
    <div>
        {/* <select className="form-control ml-3 text-capitalize"><option value="abn">ABN</option><option value="tfn">TFN</option></select> */}
      <DatePicker
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        placeholderText="From Date"
        className="form-control" 
      />
      <DatePicker
        selected={endDate}
        onChange={(date) => setEndDate(date)}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        placeholderText="To Date"
        className="form-control" 
      />
      <button className="batn batn-orange p-2 date_filter_btn" onClick={handleDateChange}>Apply Filter</button>
    </div>
  );
}

export default DateFilter;
