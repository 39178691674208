import Wizard from 'react-simple-step-wizard';
import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink, useParams, useNavigate } from 'react-router-dom';
import { Form, Button, Col, InputGroup, Modal, Row } from 'react-bootstrap';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import { docStatusValueCheck } from '../../redux_state/reducres/checkDocumentstatus';
import PhoneVerificationModel from '../../admin/PhoneVerificationModel';
import Select from 'react-select';
import Cropper from 'react-easy-crop';
import { getCroppedImg } from '../../expocomp/cropper-utils';
import TaxInput from '../../expocomp/AbnTfnTaxComp';
import GoogleAutocomplete from '../../expocomp/GoogleAutocomplate';
import customStyles from '../../expocomp/SelectStyleComp';
import ABBREVIATIONS from '../../json/abbreviations.json';

const RecruiterAccountSetting = ({ usertype }) => {
   const dispatch = useDispatch();
   const [userdetails, setUserdetails] = useState([]);
   const [industryType, setIndustryType] = useState([]);

   const [documentTypeArray, setDocumnetType] = useState([]);
   const [profilePostionArray, setProfilePostion] = useState([]);
   const [varifyDocumentList, setVarifyDocumentList] = useState([]);
   const [varifyDocumentListOption, setVarifyDocumentListOption] = useState([]);
   const [getAllCountry, setGetallCountry] = useState([]);


   useEffect(() => {
      console.log(usertype);
      if (usertype == 'indivisual') {
         setStep(5);
      } else if (usertype == 'recruiter') {
         setStep(2);
      }

      (async () => {
         try {
            const response = await axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/localstorage_common_data?calling_by_api`);
            const data = response.data;
            if (data.status == 1) {
               setGetallCountry(data.data.get_all_country);
               localStorage.setItem('sessionStorageData', JSON.stringify(data.data))
            }
         } catch (error) { console.error(error); }
      })();
      (async () => {
         try {
            const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/reprasentative_position_list");
            if (resData.data.status == 1) { setProfilePostion(resData.data.data.option_array) }
         } catch (error) { console.log(error); }
      })();

      (async () => {
         try {
            const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/get_all_address_type");
            if (resData.data.status == 1) {
               // alert();
               setVarifyDocumentList(resData.data.data.results);
               setVarifyDocumentListOption(resData.data.data.option_array);
            }
         } catch (error) { console.log(error); }
      })();

   }, []);
   //profilePostionArray documentTypeArray
   // const profilepic = (userdetails.profilepic) ? userdetails.profilepic : '/front/images/user.svg';
   const [profilePicUrl, setProfilePicUrl] = useState('/front/images/user.svg');
   //const profilepic = (userdetails.profilepic) ? userdetails.profilepic : '/front/images/user.svg';
   const [profilePicUrlDefault, setProfilePicUrlDefault] = useState(true);

   const [userUploadIdentityDocuments, setUserUploadIdentityDocuments] = useState([]);
   const [userUploadAddressDocuments, setUserUploadAddressDocuments] = useState([]);
   const [userUploadPaySlipDocuments, setUserUploadPaySlipDocuments] = useState([]);
   const [userUploadResumeDocuments, setUserUploadResumeDocuments] = useState([]);
   const [userUploadVisaDocument, setUserUploadVisaDocument] = useState([]);
   const [companyRegistrationDocument, setCompanyRegistrationDocument] = useState([]);
   const [companyAddressDocument, setCompanyAddressDocument] = useState([]);
   const navigate = useNavigate();
   const alphabeticRegex = /^[a-zA-Z]*$/;
   const getUserDetailsFun = (userid) => {
      (async () => {
         try {
            const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/userdetails/" + userid + '?callingby=accountsetup');
            if (resData.data.status == 1) {

               const resDataArr = resData.data.data.results;
               if (resDataArr.is_loggedin_firsttime == 0) {
                  setUserdetails(resData.data.data.results);
                  if (resData.data.data.results.profilepic) {
                     setProfilePicUrl(resData.data.data.results.profilepic);
                     setProfilePicUrlDefault(false)
                  }
                  setUserUploadIdentityDocuments(resDataArr.identity_document_proof);
                  setUserUploadAddressDocuments(resDataArr.address_document_proof);
                  setUserUploadPaySlipDocuments(resDataArr.payment_pay_slip);
                  setUserUploadResumeDocuments(resDataArr.user_document_resume);
                  setUserUploadVisaDocument(resDataArr.user_document_visatype);
                  setCompanyRegistrationDocument(resDataArr.company_registration_document);
                  setCompanyAddressDocument(resDataArr.company_address_document);
                  localStorage.setItem('signup_userdetails', JSON.stringify(resDataArr));
               } else {
                  navigate('/')
               }
            }
         } catch (error) { console.log(error); }

      })();
   }

   const [signupId, setSignupId] = useState(true);
   const [selectedOptionindiOrCompany, setSelectedOptionindiOrCompany] = useState('company');

   // const getSignupUserData = () => {
   //    const signup_userdetails_string = localStorage.getItem('signup_userdetails');
   //    return JSON.parse(signup_userdetails_string);
   // }
   const DayNumberLoop = (attr = '') => { const itemElements = []; itemElements.push(<option value="">DD</option>); for (let i = 1; i < 32; i++) { itemElements.push(<option selected={(attr != '' && attr.select_attr == i) ? true : false} value={i}>{i}</option>); } return itemElements }
   // const MonthNumberLoop = (attr = '') => { const itemElements = []; itemElements.push(<option value="">MM</option>); for (let i = 1; i < 13; i++) { itemElements.push(<option selected={(attr != '' && attr.select_attr == i) ? true : false} value={i}>{i}</option>); } return itemElements }
   const YearNumberLoop = (attr = '') => { const itemElements = []; itemElements.push(<option value="">YYYY</option>); for (let i = 1950; i < 2012; i++) { itemElements.push(<option selected={(attr != '' && attr.select_attr == i) ? true : false} value={i}>{i}</option>); } return itemElements }
   //const YearsNumbersLoop = (attr = '') => { const itemElements = []; itemElements.push(<option value="">YYYY</option>); for (let i = 2000; i < 2024; i++) { itemElements.push(<option selected={(attr != '' && attr.select_attr == i) ? true : false} value={i}>{i}</option>); } return itemElements }

   //const YearExpNumberLoop = (attr = '') => { const itemElements = []; itemElements.push(<option value="">Years</option>); for (let i = 1; i < 50; i++) { itemElements.push(<option selected={(attr != '' && attr.select_attr == i) ? true : false} value={i}>{i}</option>); } return itemElements }
   //const MonthExpNumberLoop = (attr = '') => { const itemElements = []; itemElements.push(<option value="">Month</option>); for (let i = 1; i < 12; i++) { itemElements.push(<option selected={(attr != '' && attr.select_attr == i) ? true : false} value={i}>{i}</option>); } return itemElements }
   const Months = [{ name: 'January', key: 1, abbreviation: 'Jan' }, { name: 'February', key: 2, abbreviation: 'Feb' }, { name: 'March', key: 3, abbreviation: 'Mar' }, { name: 'April', key: 4, abbreviation: 'Apr' }, { name: 'May', key: 5, abbreviation: 'May' }, { name: 'June', key: 6, abbreviation: 'Jun' }, { name: 'July', key: 7, abbreviation: 'Jul' }, { name: 'August', key: 8, abbreviation: 'Aug' }, { name: 'September', key: 9, abbreviation: 'Sep' }, { name: 'October', key: 10, abbreviation: 'Oct' }, { name: 'November', key: 11, abbreviation: 'Nov' },
   { name: 'December', key: 12, abbreviation: 'Dec' },];
   const MonthsHtml = (attr = '') => { const itemElements = []; itemElements.push(<option value="">Month</option>); Months.map((item, index) => (itemElements.push(<option selected={(attr != '' && attr.select_attr == item.name) ? true : false} value={item.name}>{item.name}</option>))); return itemElements }

   useEffect(() => {
      const queryParameters = new URLSearchParams(window.location.search)
      const userid = queryParameters.get("id");
      const stepnumber = queryParameters.get("step");

      if (userid) {
         setSignupId(userid);
         (async () => {
            var is_user_login = localStorage.getItem('is_user_login');
            if (is_user_login == 1) {
               localStorage.clear();
            }
            getUserDetailsFun(userid);

            try {
               const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/get_all_states");
               if (resData.data.status == 1) { localStorage.setItem('get_all_states', JSON.stringify(resData.data.data.results)); localStorage.setItem('get_all_city', []); }
            } catch (error) { console.log(error); }

            try {
               const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/get_all_industry");
               if (resData.data.status == 1) { setIndustryType(resData.data.data.results); }
            } catch (error) { console.log(error); }

            try {
               const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/get_all_documenttype");
               if (resData.data.status == 1) { setDocumnetType(resData.data.data.results); }
            } catch (error) { console.log(error); }

         })();

      } else {
         setTimeout(function () { window.location.replace("/login"); }, 100)
      }
   }, []);

   const scrollUpZero = () => { window.scrollTo({ top: 0, behavior: 'smooth' }); }
   const [step, setStep] = useState(1);
   const handleNext = () => { ((selectedOptionindiOrCompany == 'individual' && step == 2) ? setStep((prevStep) => prevStep + 2) : setStep((prevStep) => prevStep + 1)); };
   const handleSkipStep = () => { ((selectedOptionindiOrCompany == 'individual' && step == 2) ? setStep((prevStep) => prevStep + 2) : setStep((prevStep) => prevStep + 1)); };
   const handlePrevious = () => { ((selectedOptionindiOrCompany == 'individual' && step == 4) ? setStep((prevStep) => prevStep - 2) : setStep((prevStep) => prevStep - 1)); };
   const get_all_states_string = localStorage.getItem('get_all_states');
   const get_all_states = JSON.parse(get_all_states_string);
   useEffect(() => { window.scrollTo({ top: 0, behavior: 'smooth' }); console.log("Counter value: ", step); }, [step]);


   const redirectToDashboard = () => {
      const formData = new FormData();
      console.log(userdetails)
      formData.append('email', userdetails.email);
      formData.append('login_token', userdetails.login_token);
      formData.append('password', userdetails.login_token);
      //https://squadgoo.com.au/api.squadgoo.com.au/signup
      axios.post('https://squadgoo.com.au/api.squadgoo.com.au/login', formData)
         .then((response) => {
            console.log(response.data);
            if (response.data.status == 1) {
               let getuserdata = response.data.data.user_data;
               let session_storage_data = response.data.data.session_storage_data;
               localStorage.setItem('squadGologin', JSON.stringify(getuserdata))
               localStorage.setItem('profileStatus', getuserdata.profile_enabled)
               localStorage.setItem('squadGologinSession', JSON.stringify(getuserdata))
               localStorage.setItem('sessionStorageData', JSON.stringify(session_storage_data))
               localStorage.setItem('validationdataCheckData', JSON.stringify(getuserdata.validationdata_check));
               dispatch(docStatusValueCheck({ doctype: 'all', type: 'onload', sessiondata: JSON.stringify(getuserdata.validationdata_check) }));
               if (getuserdata.user_type == 1) {
                  localStorage.setItem('squadGoUserType', 'admin');
                  navigate('/admin-dashboard')
               } else if (getuserdata.user_type == 2) {
                  localStorage.setItem('squadGoUserType', 'recruiter');
                  navigate('/recruiter/dashboard')
               } else if (getuserdata.user_type == 3) {
                  localStorage.setItem('squadGoUserType', 'jobseeker');
                  navigate('/jobseeker/dashboard')
               } else {
                  navigate('/')
               }
            } else {
               navigate('/login')
            }
            //setIsSubmitting(false);
            //navigate('/login');
         })
         .catch((error) => {
            console.error(error);
            //setIsSubmitting(false);
         });
   }


   /*--------------------------------------"camera"--------------------------------------------------*/
   /*camera work*/
   const [showModal, setShowModal] = useState(false);
   const handleShowModal = () => { setShowModal(true); cameraOff() };
   const handleHideModal = () => { setShowModal(false); cameraOff(); setTakePicFromCam(false); };

   const videoRef = useRef(null);
   const [capturedImage, setCapturedImage] = useState(profilePicUrl ? profilePicUrl : '/front/images/user.svg');
   const [stream, setStream] = useState(null);
   const [takePicFromCam, setTakePicFromCam] = useState(false);
   const cameraOff = () => { if (stream) { const tracks = stream.getTracks(); tracks.forEach((track) => track.stop()); setStream(null); }; };

   const startCamera = async () => {
      setCapturedImage('')
      try {
         const stream = await navigator.mediaDevices.getUserMedia({ video: true });
         if (videoRef.current) { videoRef.current.srcObject = stream; setStream(stream); }
      } catch (error) { console.error('Error accessing camera:', error); }
   };

   const captureImage = () => {
      const canvas = document.createElement('canvas');
      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;
      const context = canvas.getContext('2d');
      context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
      const dataURL = canvas.toDataURL('image/png');
      setCapturedImage(dataURL);
      cameraOff(); setTakePicFromCam(true);
   };


   const stopCamera = () => {
      if (stream) {
         cameraOff();
         setCapturedImage(profilePicUrl ? 'https://squadgoo.com.au/api.squadgoo.com.au/' + profilePicUrl : '/front/images/user.svg')
      }
      setCapturedImage(false);
      setTakePicFromCam(false);
   };

   const uploadImage = async () => {
      const queryParameters = new URLSearchParams(window.location.search)
      const userid = queryParameters.get("id");
      try {
         const formData = new FormData();
         formData.append('file', capturedImage);
         formData.append('uploaded_for', 'profilepic');
         formData.append('userid', userid);
         const response = await axios.post(`https://squadgoo.com.au/api.squadgoo.com.au/profile_pic_upload_bycamra`,
            formData, { headers: { 'Content-Type': 'multipart/form-data' } });
         console.log('File uploaded successfully:', response.data);
         if (response.data.status == 1) {
            // toast.success('Uploaded Successfully!');
            setProfilePicUrl('https://squadgoo.com.au/api.squadgoo.com.au/' + response.data.data); setProfilePicUrlDefault(false);
            setShowModal(false);
         } else { toast.warning(response.data.message); }
      } catch (error) { console.error('Error uploading file:', error); }
   };
   /*----------------------------------------------------------------------------------------*/
   /*---------------------------file captured ----------------------------------*/
   const [imageSrc, setImageSrc] = useState(null);
   const [crop, setCrop] = useState({ x: 0, y: 0 });
   const [zoom, setZoom] = useState(1);
   const [showModalCrop, setShowModalCrop] = useState(false);
   const [croppedImage, setCroppedImage] = useState(null);

   const handleCropFileChange = (event) => {
      const file = event.target.files[0];
      if (file) {
         const reader = new FileReader();
         reader.onload = (e) => {
            setImageSrc(e.target.result);
            setShowModalCrop(true); // Open the modal to crop the image
         };
         reader.readAsDataURL(file);
      }
   };



   const onCropComplete = async (croppedArea, croppedAreaPixels) => {
      try {
         const croppedData = await getCroppedImg(imageSrc, croppedAreaPixels);
         const reader = new FileReader();
         reader.onloadend = () => {
            setCapturedImage(reader.result);
         };
         if (croppedData.blob) {
            reader.readAsDataURL(croppedData.blob);
         }
         setCroppedImage(croppedData.fileUrl);
      } catch (error) {
         console.error('Error during cropping:', error);
      }
   };

   const handleSave = () => {
      if (croppedImage) {
         uploadImage();
         setProfilePicUrl(croppedImage);
         console.log('Cropped image to upload:', croppedImage);
      }
      setShowModalCrop(false);
   };



   /*----------------------------------------------------------------------------------------*/

   const go1stStep = () => { setStep(1); };
   const go2ndStep = () => { setStep(2); };
   const go3rdStep = () => { setStep(3); };
   const go4thStep = () => { setStep(4); };
   const go5thStep = () => { setStep(5); };

   /******STEPS AND FUNCTION************/
   const formdataValue = (field, value, calling_by_api = 1) => {
      let length = value.length;
      if (field == 'address_user_state') { length = 3 }
      if (field == 'accept_agreeiment') { length = 3 }
      if (field == 'experience_years') { length = 3 }
      if (field == 'experience_months') { length = 3 }
      if (field == 'address_unit_no') { length = 3 }
      if (field == 'company_or_individual') {
         setSelectedOptionindiOrCompany(value)
      }
      //setSelectedOptionindiOrCompany
      let calling_api = (calling_by_api == 1) ? 'https://squadgoo.com.au/api.squadgoo.com.au/profile_field_update' : 'https://squadgoo.com.au/api.squadgoo.com.au/company_address_field_update';
      if (length > 2) {
         const queryParameters = new URLSearchParams(window.location.search)
         const userid = queryParameters.get("id");
         const formData = new FormData();
         formData.append('field_value', value);
         formData.append('uploaded_for', field);
         formData.append('userid', userid);
         axios.post(calling_api, formData)
            .then((response) => {
               console.log(response);
            })
            .catch((error) => {
               console.error(error);
               //setIsSubmitting(false);
            });
      }
   };
   const fileUploadToServer = async (field, file, calling_api = 'profile_update') => {
      const queryParameters = new URLSearchParams(window.location.search)
      const userid = queryParameters.get("id");
      try {
         const formData = new FormData();
         formData.append('file', file);
         formData.append('uploaded_for', field);
         formData.append('userid', userid);
         const response = await axios.post(
            `https://squadgoo.com.au/api.squadgoo.com.au/${(calling_api == 'work_experience') ? 'work_experience_fileupload' : 'profile_pic_upload'}`,
            formData, { headers: { 'Content-Type': 'multipart/form-data' } });
         console.log('File uploaded successfully:', response.data);
         if (response.data.status == 1) {
            // toast.success('Uploaded Successfully!');
            if (field == 'profilepic') {
               setProfilePicUrl(response.data.data.fullurl);
            }
         }
      } catch (error) {
         console.error('Error uploading file:', error);
      }
   };

   const editFileFromServer = async (field, id) => { }
   const removeFileFromServer = async (field, id) => {
      if (window.confirm("Do you want to remove this file?")) {
         try {
            const formData = new FormData();
            formData.append('field', field);
            formData.append('file_id', id);
            const response = await axios.post(
               `https://squadgoo.com.au/api.squadgoo.com.au/userdetails_upload_file_remove`,
               formData, { headers: { 'Content-Type': 'multipart/form-data' } });
            if (response.data.status == 1) {
               toast.success('Removed Successfully!');
               if (field == 'identity_document_proof') { setUserUploadIdentityDocuments(response.data.data) }
               if (field == 'address_document_proof') { setUserUploadAddressDocuments(response.data.data) }
               if (field == 'workexperience_payslips') { setUserUploadPaySlipDocuments(response.data.data) }
               if (field == 'user_document_resume') { setUserUploadResumeDocuments(response.data.data) }
               if (field == 'user_document_visatype') { setUserUploadVisaDocument(response.data.data) }
               if (field == 'company_registration_document') { setCompanyRegistrationDocument(response.data.data) }
               if (field == 'company_address_document') { setCompanyAddressDocument(response.data.data) }

            } else { toast.warning(response.data.message); }
         } catch (error) { console.error('Error uploading file:', error); }
      }
   }

   const Step1 = () => {
      const checkRecruiterType = (type) => {
         if (type === 'comapny_value') {
            console.log('if condition')
            setStep((prevStep) => prevStep + 1)
         } else if (type === 'individual') {
            console.log('else condition')
            setStep((prevStep) => prevStep + 4)
         }
      }
      return (<div>
         <div className="stepscont">
            <div className="row">
               <div className="col-md-12 stepscontright py-5">
                  <form className="commonform">
                     <h2 className="d-flex align-items-center justify-content-center mb-5 text-center"   >
                        Are you a company or individual?</h2>
                     <div className="col-md-12">
                        <div className="form-group taxtype mb-2">
                           <div className="switch-field square-switch-field mr-0 buttonradiofield">
                              <div className="switchfieldinner">
                                 <input type="radio" id="company" onChange={(e) => checkRecruiterType('comapny_value')} name="recruitertype" />
                                 <label htmlFor="company">Company</label>
                              </div>
                              <div className="switchfieldinner">
                                 <input type="radio" id="individual" onChange={(e) => checkRecruiterType('individual')} name="recruitertype" />
                                 <label htmlFor="individual">Individual</label>
                              </div>
                           </div>
                        </div>
                     </div>
                  </form>
               </div>
            </div>
         </div>
      </div>);
   };

   const Step2 = () => {
      const checkReprasentativeType = (type) => {
         if (type === 'representative_company') {
            setStep((prevStep) => prevStep + 1)
         } else if (type === 'company_owner') {
            setStep((prevStep) => prevStep + 2)
         }

         const formData = new FormData();
         const queryParameters = new URLSearchParams(window.location.search)
         const userid = queryParameters.get("id");
         formData.append('user_type_role', (type === 'representative_company' ? 'rec_reprasentative' : 'rec_owners'));
         formData.append('userid', userid);
         const response = axios.post(
            `https://squadgoo.com.au/api.squadgoo.com.au/userdetails_data_update`,
            formData, { headers: { 'Content-Type': 'multipart/form-data' } });
         // if (response.data.status == 1) {
         // } 
      }
      return (<div>
         <div className="stepscont">
            <div className="row">
               <div className="col-md-12 stepscontright py-5">
                  <form className="commonform">
                     <h2 className="step-backbtnwrap d-flex align-items-start text-center mb-5"  >

                        {/* <span className="stepbackarrow mr-1" >
                           <img src="/front/images/stepbackarrow.svg" onClick={go1stStep} ></img>
                        </span> */}
                        Are you a representative of a company or owner?
                     </h2>
                     <div className="col-md-12">
                        <div className="form-group taxtype mb-2">
                           <div className="switch-field square-switch-field mr-0 buttonradiofield">
                              <div className="switchfieldinner">
                                 <input type="radio" id="representative_company" onChange={(e) => checkReprasentativeType('representative_company')} name="representative_company" />
                                 <label htmlFor="representative_company">Representative </label>
                              </div>
                              <div className="switchfieldinner">
                                 <input type="radio" id="company_owner" onChange={(e) => checkReprasentativeType('company_owner')} name="company_owner" />
                                 <label htmlFor="company_owner">Owner</label>
                              </div>
                           </div>
                        </div>
                     </div>
                  </form>
               </div>
            </div>
         </div>
      </div>);
   };

   /* ****************************reprasentative company detail**************************/
   const reprasentativeNext = () => { setStep((prevStep) => prevStep + 1) };
   const reprasentativeNext31 = () => { setStep(31) }

   /*upload profile*/
   const Step3 = () => {

      const removeProfilePic = () => {
         setProfilePicUrl('');
         setProfilePicUrlDefault(true);
         formdataValue('profilepic', 'blank')
      }

      const profileUploadToServer = (e) => {

         const file = e.target.files[0];
         const maxSizeInBytes = 1 * 1024 * 1024; // For example, 1 MB
         if (file.size > maxSizeInBytes) {
            console.log("File size is greater than the specified limit.");
            toast.warning('File size is greater than the specified limit.');
         } else {
            setProfilePicUrl(URL.createObjectURL(e.target.files[0]));
            setProfilePicUrlDefault(false);
            fileUploadToServer('profilepic', e.target.files[0]);
         }
      }


      console.log('reprasentative -- photo step 3')
      return (
         <>
            <div>
               <div className="stepscont">
                  <div className="row">
                     <div className="col-md-12 stepscontright">
                        <form className="commonform">
                           <h3 className="step-backbtnwrap d-flex align-items-center" >
                              <span className="stepbackarrow mr-1" >
                                 <img src="/front/images/stepbackarrow.svg" onClick={go2ndStep} ></img>
                              </span> Upload your logo
                           </h3>

                           <div className="text-center form-group mb-3 mt-3">
                              <div className="profilephotodiv">

                                 <input className='signuppage_profile' type="file" accept="image/*" onChange={handleCropFileChange} />
                                 <img src={profilePicUrl}></img>
                                 {
                                    (profilePicUrlDefault) ?
                                       <>
                                          <div className="prfileupload">
                                             {/* <input
                                                type="file"
                                                accept="image/x-png,image/gif,image/jpeg ,application/pdf"
                                                id="profilepic"
                                                onChange={profileUploadToServer}
                                             ></input> */}
                                             <input id="profilepic" type="file" accept="image/*" onChange={handleCropFileChange} />
                                             <span>Upload Logo</span>
                                          </div>
                                       </>
                                       : ''
                                 }
                                 <div className="dropdown pimgdrop">
                                    <button className=" dropdown-toggle" type="button" id="pimgdropmenu" data-bs-toggle="dropdown" aria-expanded="false">
                                       <i className="fa fa-pencil" aria-hidden="true"></i>
                                    </button>
                                    {
                                       (!profilePicUrlDefault) ?
                                          <>
                                             <ul className="dropdown-menu" aria-labelledby="pimgdropmenu">
                                                <li>
                                                   <a className="dropdown-item updatepro" href="#">Update
                                                      {/* <input type="file" accept="image/x-png,image/gif,image/jpeg ,application/pdf"
                                                   id="profilepic"
                                                   onChange={profileUploadToServer} ></input> */}
                                                      <input id="profilepic" type="file" accept="image/*" onChange={handleCropFileChange} />
                                                   </a>
                                                </li>
                                                <li  ><a onClick={(e) => handleShowModal()} className="dropdown-item" href="javascript:void(0)">Capture Image</a></li>
                                                <li  ><a onClick={(e) => removeProfilePic('profilepic')} className="dropdown-item" href="javascript:void(0)">Remove</a></li>
                                             </ul>
                                          </>
                                          :
                                          <>
                                             <ul className="dropdown-menu" aria-labelledby="pimgdropmenu">
                                                <li>
                                                   <a className="dropdown-item updatepro" href="#">Upload Photo
                                                      {/* <input type="file" accept="image/x-png,image/jpeg"
                                                         id="profilepic"
                                                         onChange={profileUploadToServer} ></input> */}
                                                      <input id="profilepic" type="file" accept="image/*" onChange={handleCropFileChange} />
                                                   </a>
                                                </li>
                                                <li  ><a onClick={(e) => handleShowModal()} className="dropdown-item" href="javascript:void(0)">Capture Image</a></li>
                                             </ul>
                                          </>
                                    }
                                    {/* <ul className="dropdown-menu" aria-labelledby="pimgdropmenu">
                                    <li><a className="dropdown-item updatepro" href="#">Update

                                       <input type="file" accept="image/x-png,image/gif,image/jpeg ,application/pdf"
                                          id="profilepic"
                                          onChange={profileUploadToServer} ></input></a></li>
                                    <li  ><a onClick={(e) => removeProfilePic('profilepic')} className="dropdown-item" href="javascript:void(0)">Remove</a></li>
                                 </ul> */}
                                 </div>
                                 <div className="editprofileicon">
                                 </div>
                              </div>
                           </div>
                           <div className="mb-3">
                              <p className="mb-0"><strong>Logo guidelines:</strong></p>
                              <p className="mb-0">1. logo should have a transparent background,</p>
                              <p className="mb-0">2. 400x400 is the required resolution/dimension for the logo.</p>
                              <p className="mb-0">3. We only accepts PNG, SVG images with the maximum size 1MB</p>
                           </div>

                        </form>
                     </div>
                  </div>
               </div>
               <div className="btnsgroup">
                  <div className="text-center">
                     <button className="batn batn-orange w-100" onClick={reprasentativeNext31} type="button" fdprocessedid="fpb8r9">Save and Continue</button>
                  </div>
                  <div className="text-center  mt-2">
                     <button className="btn-link" onClick={reprasentativeNext31} type="button" fdprocessedid="9k1hy">Skip this step</button>
                  </div>
               </div>
            </div>
         </>
      )


   };

   /*Representative's basic details and contact number */
   const Step31 = () => {
      console.log('reprasentative -- basic details Step31')
      // const [positionList, setPositionList] = useState([]);
      const get_all_states_string = localStorage.getItem('get_all_states');
      const get_all_states = JSON.parse(get_all_states_string);
      const [subRubSggestions, setSubRubSggestions] = useState([])
      const [inputValue, setInputValue] = useState('');


      const setSubrubsIteminArray = (value) => { setInputValue(value); formdataValue('address_suburbs', value); if (value.length > 2) { fetchSuggestions(value); } else { setSubRubSggestions([]) } };
      const getSubrubByli = (value) => { setInputValue(value); formdataValue('address_suburbs', value); setSubRubSggestions([]) };

      // Function to fetch suggestions from the API
      const fetchSuggestions = async (value) => {
         try {
            const response = await axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/get_all_subrubs_name/${value}?responsetype=autocomplete`);
            const data = response.data;
            setSubRubSggestions(data);
         } catch (error) { console.error(error); }
      };

      const [besicDetails, setBasicDetailsData] = useState({
         name_salute: userdetails.name_salute,
         user_position: userdetails.user_position,
         identity_proof_type: userdetails.identity_proof_type,
         first_name: userdetails.first_name,
         middle_name: userdetails.middle_name,
         last_name: userdetails.last_name,
         dob_day: userdetails.dob_day,
         dob_month: userdetails.dob_month,
         dob_year: userdetails.dob_year,
      });


      const basiDetailsUpdatefun = (event) => {
         const { name, value } = event.target;
         if ((name == 'first_name' || name == 'middle_name' || name == 'last_name') && !alphabeticRegex.test(value)) {
            return null;
         }

         setBasicDetailsData((prevFormData) => ({
            ...prevFormData,
            [name]: value
         }));
         //formdataValue(name, value)
      };

      const [validated, setValidated] = useState(false);
      const [validatedCnClass, setValidatedCnClass] = useState(false);

      const onBasicDetailsSubmit = async (event) => {
         event.preventDefault();
         const form = event.currentTarget;
         if (contactNumber.length == 0) {
            setValidatedCnClass(true)
         }
         if (form.checkValidity() === false) {
            event.preventDefault(); event.stopPropagation(); console.log('error');
         } else {
            console.log('not error');
            if (contactNumber.length < 9) {
               console.log(event.target.contact_number.value.length)

               setValidatedCnClass(true)
            } else {
               try {
                  const queryParameters = new URLSearchParams(window.location.search)
                  const userid = queryParameters.get("id");
                  const formData = new FormData();
                  formData.append('name_salute', event.target.name_salute.value);
                  formData.append('first_name', event.target.first_name.value);
                  formData.append('middle_name', event.target.middle_name.value);
                  formData.append('last_name', event.target.last_name.value);
                  formData.append('dob_day', event.target.dob_day.value);
                  formData.append('dob_month', event.target.dob_month.value);
                  formData.append('dob_year', event.target.dob_year.value);

                  formData.append('contact_country_code', event.target.contact_country_code.value);
                  formData.append('contact_number', contactNumber);
                  formData.append('user_position', besicDetails.user_position);
                  formData.append('user_type', 4);
                  formData.append('userid', userid);
                  const response = await axios.post(
                     `https://squadgoo.com.au/api.squadgoo.com.au/userdetails_data_update`,
                     formData, { headers: { 'Content-Type': 'multipart/form-data' } });
                  if (response.data.status == 1) {
                     getUserDetailsFun(userid);
                     reprasentativeNext()
                  } else { toast.warning(response.data.message); }
               } catch (error) { console.error('Error uploading file:', error); }
            }
            // handleNext()
         }
         setValidated(true);
      };

      const [contactNumber, setContactNumber] = useState(userdetails.contact_number)
      const [contactNumberError, setContactNumberError] = useState('')
      const checkContactNumer = (event) => {
         const value = event.target.value; const regex = /^[0-9\b]+$/; setContactNumber('');
         if (value === '' || regex.test(value)) {
            setContactNumber(value);
            setContactNumberError('');
            if (value.length > 9) {
               //setContactNumberError(value.length)
               (async () => {
                  try {
                     const resData = await axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/mobilecheck/${userdetails.unique_id}/${value}`);
                     if (resData.data.status == 1) {
                        setContactNumber('');
                        setContactNumberError(resData.data.message);
                     }
                  } catch (error) { console.log(error); }
               })();
            }

         }
      }




      const profilePostionChange = (select) => {
         // setIsAddNewJobModalShow(false);
         // setWorkExperienceTitle(select.label);
         // setDefaultJobTitle(select.value);
         //besicDetails.user_position
         //setBasicDetailsData( (...prevData) => 'user_position' : select.value );

         setBasicDetailsData((prevData) => ({ ...prevData, 'user_position': select.value }))
         // if (select.value === 'other') { setSetNewJobTitleValue(''); setIsAddNewJobModalShow(true) }
      }

      return (<>
         <div className="stepscont">
            <div className="row">
               <div className="col-md-12 stepscontright">
                  <Form noValidate validated={validated} onSubmit={onBasicDetailsSubmit}>
                     <h3 className="d-flex align-items-center">
                        <span className="stepbackarrow mr-1" >
                           <img src="/front/images/stepbackarrow.svg" onClick={go3rdStep} ></img>
                        </span>Representative's basic details
                     </h3>
                     <div className="row">
                        <div className="col-md-6">
                           <div className="d-flex">
                              <div className="form-group mb-2 mr-2">
                                 <label className="form-label">Salute<span className="starr">*</span></label>
                                 <select name="name_salute" className="form-control" onChange={basiDetailsUpdatefun} >
                                    {
                                       ABBREVIATIONS.map((item) => (
                                          <option selected={(besicDetails.name_salute == item.value) ? true : false} key={item.id} value={item.value}>{item.value}</option>
                                       ))
                                    }
                                 </select>
                              </div>
                              <div className="form-group mb-2">
                                 <label className="form-label">First name<span className="starr">*</span></label>
                                 <input type="text" className="form-control" name="first_name" required value={besicDetails.first_name} onChange={basiDetailsUpdatefun} placeholder="Andrew" />
                              </div>
                           </div>
                        </div>
                        <div className="col-md-6">
                           <div className="form-group mb-2">
                              <label className="form-label">Middle name</label>
                              <input type="text" className="form-control" name="middle_name" onChange={basiDetailsUpdatefun} value={besicDetails.middle_name} placeholder="Enter middle name" />
                           </div>
                        </div>
                        <div className="col-md-6">
                           <div className="form-group mb-2">
                              <label className="form-label">Last name<span className="starr">*</span></label>
                              <input type="text" className="form-control" name="last_name" required value={besicDetails.last_name} onChange={basiDetailsUpdatefun} placeholder="Enderson" />
                           </div>
                        </div>
                        <div className="col-md-6">

                           <div className="form-group mb-2">
                              <label className="form-label">Date of birth<span className="starr">*</span></label>
                              <div className="d-flex datefildgruop">
                                 <select name="dob_day" required className="form-control mr-2 datefild" onChange={basiDetailsUpdatefun}>
                                    {<DayNumberLoop select_attr={besicDetails.dob_day} />}
                                 </select>
                                 <select name="dob_month" required className="form-control mr-2 monthfild" onChange={basiDetailsUpdatefun}>
                                    {<MonthsHtml select_attr={besicDetails.dob_month} />}
                                 </select>
                                 <select name="dob_year" required className="form-control yearfild" onChange={basiDetailsUpdatefun} >
                                    {<YearNumberLoop select_attr={besicDetails.dob_year} />}
                                 </select>
                              </div>
                           </div>
                        </div>


                        {/* <div className="col-md-6">
                           <div className="form-group mb-2">
                              <label className="form-label">E-mail <span className="starr">*</span></label>
                              <input disabled={true} type="readonly" className="form-control" placeholder="andrew_enderson@gmail.com" value={userdetails.email} /></div>
                        </div> */}
                        <div className="col-md-12 ">
                           <div className="form-group mb-2">
                              <label className="form-label">Phone number <span className="starr">*</span></label>
                              <div className={validatedCnClass ? 'phonefield d-flex align-items-center bordercss' : 'phonefield d-flex align-items-center'} >
                                 <select className="form-control" name='contact_country_code' >
                                    {
                                       getAllCountry.length ?
                                          <>
                                             {
                                                getAllCountry.map((item, index) => (
                                                   <option selected={userdetails.contact_country_code == item.phonecode ? true : false} value={item.phonecode}>+{item.phonecode}</option>
                                                ))
                                             }
                                          </>
                                          : ''
                                    }
                                 </select>
                                 <input
                                    type="tel"
                                    required={true}
                                    name='contact_number'
                                    className="form-control"
                                    placeholder="Enter mobile number"
                                    maxLength={10}
                                    onChange={checkContactNumer}
                                    value={contactNumber}
                                 />
                              </div>
                              {contactNumberError && <div className='uniquenu_error'>
                                 {contactNumberError}
                              </div>}
                              {
                                 (contactNumber.length > 9) &&
                                 <div className="d-flex justify-content-end">
                                    <PhoneVerificationModel userdetails={userdetails} />
                                 </div>
                              }
                           </div>
                        </div>

                        <div className="col-md-12">
                           <div className="form-group mb-2">
                              <label className="form-label">Position <span className="starr">*</span></label>
                              {/* <Select></Select> */}
                              <Select className='react_select' name="work_expe_jobtitle_id" required aria-label="4"
                                 options={profilePostionArray}
                                 isSearchable
                                 placeholder="Search..."
                                 styles={customStyles}
                                 onChange={profilePostionChange}
                                 value={profilePostionArray.find(item => item.value == besicDetails.user_position)}
                              />

                              {/* <select className="form-control mr-2" required={true}>
                                 <option value={''}>Select Position</option>
                                 {
                                    profilePostionArray.length ?
                                       <>
                                          {
                                             profilePostionArray.map((item, index) => (
                                                <>
                                                   <option value={item.id}>{item.positions}</option>
                                                </>
                                             ))
                                          }
                                       </> : ''

                                 }
                              </select> */}
                           </div>
                        </div>
                     </div>
                     <div className="btnsgroup">
                        <div className="text-center">
                           <button className="batn batn-orange w-100" type="submit" >Save and Continue</button>
                        </div>
                        <div className="text-center  mt-2">
                           <button className="btn-link" onClick={reprasentativeNext} type="button">Skip this step</button>
                        </div>
                     </div>
                  </Form>
               </div>
            </div>
         </div>

      </>)
   }


   /*Provide director's contact details*/
   const Step32 = () => {
      console.log('reprasentative -- director\'s contact details Step32')
      const [contactNumber, setContactNumber] = useState('')
      const checkContactNumer = (event) => { const value = event.target.value; const regex = /^[0-9\b]+$/; setContactNumber(''); if (value === '' || regex.test(value)) { setContactNumber(value); } }
      const [directorDetails, setDirectorDetails] = useState({
         name_salute: '',
         first_name: '',
         middle_name: '',
         last_name: '',
         email_id: '',
      });
      useEffect(() => {
         const queryParameters = new URLSearchParams(window.location.search)
         const userid = queryParameters.get("id");
         (async () => {
            try {
               const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/get_directors_details_by_userid/" + userid);
               if (resData.data.status == 1) {
                  setDirectorDetails(resData.data.data.results)
                  setContactNumber(resData.data.data.results.contact_number)
               }
            } catch (error) { console.log(error); }

         })();
      }, []);

      const basiDetailsUpdatefun = (event) => {
         const { name, value } = event.target;
         if ((name == 'first_name' || name == 'middle_name' || name == 'last_name') && !alphabeticRegex.test(value)) {
            return null;
         }

         setDirectorDetails((prevFormData) => ({
            ...prevFormData,
            [name]: value
         }));
         //formdataValue(name, value)
      };

      const [validated, setValidated] = useState(false);
      const [validatedCnClass, setValidatedCnClass] = useState(false);

      const onDirectorsDetailsSubmit = async (event) => {
         event.preventDefault();
         const form = event.currentTarget;
         setValidatedCnClass(false);
         if (!contactNumber) { setValidatedCnClass(true) }
         if (form.checkValidity() === false) {
            event.preventDefault(); event.stopPropagation(); console.log('error');
         } else {
            console.log('not error');
            try {
               const queryParameters = new URLSearchParams(window.location.search)
               const userid = queryParameters.get("id");
               const formData = new FormData();
               formData.append('name_salute', event.target.name_salute.value);
               formData.append('first_name', directorDetails.first_name);
               formData.append('middle_name', directorDetails.middle_name);
               formData.append('last_name', directorDetails.last_name);
               formData.append('email_id', directorDetails.email_id);
               formData.append('contact_number', contactNumber);
               formData.append('userid', userid);
               const response = await axios.post(
                  `https://squadgoo.com.au/api.squadgoo.com.au/directors_details_field_update`,
                  formData, { headers: { 'Content-Type': 'multipart/form-data' } });
               if (response.data.status == 1) {
                  getUserDetailsFun(userid);
                  reprasentativeNext()
               } else { toast.warning(response.data.message); }
            } catch (error) { console.error('Error uploading file:', error); }
            // handleNext()
         }
         setValidated(true);
      };

      return (
         <>
            <div className="stepscont">
               <div className="row">
                  <div className="col-md-12 stepscontright">
                     <Form noValidate validated={validated} onSubmit={onDirectorsDetailsSubmit}>
                        <h3 className="d-flex align-items-center">
                           <span className="stepbackarrow mr-1" >
                              <img src="/front/images/stepbackarrow.svg" onClick={handlePrevious} ></img>
                           </span>Provide director's contact details
                        </h3>
                        <div className="row">

                           <div className="col-md-6">
                              <div className="d-flex">
                                 <div className="form-group mb-2 mr-2">
                                    <label className="form-label">Salute <span className="starr">*</span></label>
                                    <select required={true} className="form-control" name='name_salute' onChange={basiDetailsUpdatefun} >
                                       <option value={'Mr.'}>Mr.</option>
                                       <option value={'Miss.'} selected={(directorDetails.name_salute == 'Miss.') ? true : false}>Miss.</option>
                                    </select>
                                 </div>
                                 <div className="form-group mb-2">
                                    <label className="form-label">First name <span className="starr">*</span></label>
                                    <input type="text" required={true} className="form-control" placeholder="Andrew" value={directorDetails.first_name} name='first_name' onChange={basiDetailsUpdatefun} />
                                 </div>
                              </div>
                           </div>
                           <div className="col-md-6">
                              <div className="form-group mb-2">
                                 <label className="form-label">Middle name <span className="starr"></span></label>
                                 <input type="text" className="form-control" placeholder="Enter middle name" value={directorDetails.middle_name} name='middle_name' onChange={basiDetailsUpdatefun} />
                              </div>
                           </div>
                           <div className="col-md-6">
                              <div className="form-group mb-2">
                                 <label className="form-label">Last name <span className="starr">*</span></label>
                                 <input type="text" required={true} className="form-control" placeholder="Enderson" value={directorDetails.last_name} name='last_name' onChange={basiDetailsUpdatefun} />
                              </div>
                           </div>
                           <div className="col-md-6">
                              <div className="form-group mb-2">
                                 <label className="form-label">Phone <span className="starr">*</span></label>
                                 <div className={validatedCnClass ? 'phonefield d-flex align-items-center bordercss' : 'phonefield d-flex align-items-center'} >
                                    <select className="form-control" >
                                       <option>+62</option>
                                       <option>+92</option>
                                    </select>
                                    <input
                                       type="tel"
                                       required={true}
                                       className="form-control"
                                       placeholder="Enter mobile number"
                                       maxlength="10"
                                       value={contactNumber}
                                       onChange={checkContactNumer}
                                    />
                                 </div>
                              </div>
                           </div>
                           <div className="col-md-12">
                              <div className="form-group mb-2">
                                 <label className="form-label">E-mail <span className="starr">*</span></label>
                                 <div className=" d-flex align-items-center">
                                    <input required={true} type="email" className="form-control" placeholder="Enter e-mail address" value={directorDetails.email_id} name='email_id' onChange={basiDetailsUpdatefun} />
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="btnsgroup">
                           <div className="text-center"><button className="batn batn-orange w-100" type="submit" >Save and Continue</button></div>
                           <div className="text-center  mt-2"><button className="btn-link" onClick={reprasentativeNext} type="button" fdprocessedid="9k1hy">Skip this step</button></div>
                        </div>
                     </Form>
                  </div>
               </div>
            </div>

         </>
      )
   }

   /*Company details*/
   const Step33 = () => {
      console.log('reprasentative -- Company details Step33')
      const [companyDetails, setCompanyDetails] = useState({
         name: '',
         website: '',
         team_size: '',
         industry: '',
      });

      const setCompanyDetailsFun = (event) => {
         const { name, value } = event.target;
         setCompanyDetails((prevFormData) => ({
            ...prevFormData,
            [name]: value
         }));
         //formdataValue(name, value)
      };


      useEffect(() => {
         const queryParameters = new URLSearchParams(window.location.search)
         const userid = queryParameters.get("id");
         (async () => {
            try {
               const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/get_company_details_by_userid/" + userid);
               if (resData.data.status == 1) {
                  setCompanyDetails(resData.data.data.results)
               }
            } catch (error) { console.log(error); }
         })();
      }, []);


      const [getAllIndustry, setGetAllIndustry] = useState([]);
      const [getAllIndustryOptions, setGetAllIndustryOptions] = useState([]);
      useEffect(() => {
         const queryParameters = new URLSearchParams(window.location.search)
         const userid = queryParameters.get("id");
         (async () => {
            try {
               const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/get_all_industry");
               if (resData.data.status == 1) {
                  setGetAllIndustry(resData.data.data.results);
                  setGetAllIndustryOptions(resData.data.data.option);
               }
            } catch (error) { console.log(error); }
         })();
      }, []);


      const [validated, setValidated] = useState(false);
      const [validatedCnClass, setValidatedCnClass] = useState('');
      const onCompanyDetailsSubmit = async (event) => {
         event.preventDefault();
         setValidatedCnClass('bordercss');
         const queryParameters = new URLSearchParams(window.location.search)
         const userid = queryParameters.get("id");
         const form = event.currentTarget;
         if (form.checkValidity() === false) {
            if (!companyDetails.industry) {
               setValidatedCnClass('bordercss');
            }
            event.preventDefault(); event.stopPropagation();
         } else {
            try {

               if (!companyDetails.industry) {

                  setValidatedCnClass('bordercss');
                  return;
               }
               const formData = new FormData();
               formData.append('name', companyDetails.name);
               formData.append('website', companyDetails.website);
               formData.append('team_size', companyDetails.team_size);
               formData.append('industry', companyDetails.industry);
               formData.append('user_id', userid);
               const response = await axios.post(
                  `https://squadgoo.com.au/api.squadgoo.com.au/company_address_field_update`,
                  formData, { headers: { 'Content-Type': 'multipart/form-data' } });
               if (response.data.status == 1) {
                  getUserDetailsFun(userid);
                  reprasentativeNext();
               } else { toast.warning(response.data.message); }
            } catch (error) { console.error('Error uploading file:', error); }
         }
         setValidated(true);
      };


      const [loadingData, setloadingData] = useState(false);

      const [companyRegistrationDocumentContents, setCompanyRegistrationDocumentContents] = useState(companyRegistrationDocument);
      const userFileUploadToServercomapnyRegisFun = async (field, file) => {
         const maxSizeInBytes = 1 * 1024 * 1024; // For example, 1 MB
         if (file.size > maxSizeInBytes) {
            console.log("File size is greater than the specified limit.");
            toast.warning('File size is greater than the specified limit.');
         } else {
            console.log("File size is within the specified limit.");
            const queryParameters = new URLSearchParams(window.location.search)
            const userid = queryParameters.get("id");
            setloadingData(true)
            try {
               const formData = new FormData();
               formData.append('file', file);
               formData.append('uploaded_for', field);
               formData.append('userid', userid);
               formData.append('maxSizeInBytes', maxSizeInBytes);
               const response = await axios.post(
                  `https://squadgoo.com.au/api.squadgoo.com.au/userdetails_file_uploads`,
                  formData, { headers: { 'Content-Type': 'multipart/form-data' } });
               //console.log('File uploaded successfully:', response.data);
               setloadingData(false)
               if (response.data.status == 1) {
                  setCompanyRegistrationDocumentContents(response.data.data.upload_array)
               } else { toast.warning(response.data.message); }
            } catch (error) { console.error('Error uploading file:', error); }
         }
      };


      /*-----------------*/
      const profileDocumentChange = (select) => {
         setValidatedCnClass('bordercss');
         if (select.value) {
            setValidatedCnClass('');
         }
         setCompanyDetails((prevData) => ({ ...prevData, 'industry': select.value }))
      }
      return (
         <>
            <div className="stepscont">
               <div className="row">
                  <div className="col-md-12 stepscontright">
                     <Form noValidate validated={validated} onSubmit={onCompanyDetailsSubmit} >
                        <h3 className="step-backbtnwrap d-flex align-items-center" >
                           <span className="stepbackarrow mr-1" >
                              <img src="/front/images/stepbackarrow.svg" onClick={handlePrevious} ></img>
                           </span>Company details
                        </h3>

                        <div className="row">
                           <div className="col-md-6">
                              <div className="form-group mb-2">
                                 <label className="form-label">Company name <span className="starr">*</span></label>
                                 <input required={true} onChange={setCompanyDetailsFun} name="name" value={companyDetails.name} type="text" className="form-control" placeholder="Enter company name" />
                              </div>
                           </div>
                           <div className="col-md-6">
                              <div className="form-group mb-2">
                                 <label className="form-label">Company website <span className="starr">*</span></label>
                                 <input required={true} onChange={setCompanyDetailsFun} name="website" value={companyDetails.website} type="text" className="form-control" placeholder="Enter companyn website" />
                              </div>
                           </div>
                           <div className="col-md-6">
                              <div className="form-group mb-2">
                                 <label className="form-label">Company size <span className="starr">*</span></label>
                                 <input required={true} onChange={setCompanyDetailsFun} name="team_size" value={companyDetails.team_size} type="number" min="2" className="form-control" placeholder="Enter Team Size" />
                              </div>
                           </div>
                           <div className="col-md-6">
                              <div className="form-group mb-2">
                                 <label className="form-label">Industry <span className="starr">*</span></label>

                                 <Select
                                    className={`react_select ${validatedCnClass}`}
                                    name="address_document_proof_type"
                                    options={getAllIndustryOptions}
                                    isSearchable
                                    required={true}
                                    placeholder="Search..."
                                    styles={customStyles}
                                    onChange={profileDocumentChange}
                                    value={getAllIndustryOptions.find(item => item.value == companyDetails.industry)}
                                 />

                                 {/* <select className="form-control" required={true} name="industry" onChange={setCompanyDetailsFun}  >
                                    <option value={''}>Select industry</option>
                                    {
                                       getAllIndustry.length ?
                                          <>
                                             {
                                                getAllIndustry.map((item, index) => (
                                                   <>
                                                      <option value={item.id}>{item.industry}</option>
                                                   </>
                                                ))
                                             }
                                          </>
                                          : ''
                                    }
                                 </select> */}
                              </div>
                           </div>
                           <div className="col-md-12 mb-1">
                              <div className="form-group mb-2">
                                 <label className="form-label">{companyRegistrationDocumentContents.length >= 1 ? 'Uploaded' : 'Upload'} registration document <span className="starr">*</span> <span className="infowrap"><i className="fa fa-info"></i><span className="onhoverinfo">Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum</span></span></label>
                                 {
                                    <>
                                       {
                                          companyRegistrationDocumentContents.map((item, index) => (
                                             <>
                                                <div className="documentwrap d-flex align-items-center mb-2">
                                                   <a className='document_list' target="_blank" href={'https://squadgoo.com.au/api.squadgoo.com.au/' + item.document_url}>
                                                      <p>Registration document {index + 1}</p>
                                                   </a>
                                                   <div className="documentwrapinner d-flex align-items-center">
                                                      {/* <span className='cursor_pointer' onClick={e => viewFileFromServer('identity_document_proof' , item.id) }><img src="/front/images/eye.svg" /></span> */}
                                                      <span className='cursor_pointer ml-2' onClick={e => editFileFromServer('company_registration_document', item.id)}><img src="/front/images/edit.png" /></span>
                                                      <span className='cursor_pointer ml-2' onClick={e => removeFileFromServer('company_registration_document', item.id)}><img src="/front/images/delete.png" /></span>
                                                   </div>
                                                </div>
                                             </>
                                          ))
                                       }
                                    </>
                                 }
                                 {
                                    companyRegistrationDocumentContents.length >= 1 ?
                                       ''
                                       :
                                       <>
                                          <div className="form-group identityproof">
                                             <div className="customuploadsec">
                                                {!loadingData && <input type="file" accept="image/x-png,image/jpg,image/jpeg ,application/pdf" onChange={(e) => userFileUploadToServercomapnyRegisFun('company_registration_document', e.target.files[0])} />}
                                                <div className="customuploadinner text-center">
                                                   <div className="my-4"><img src="/front/images/uploadicon.png" className="mt-4" /></div>
                                                   <p className="mb-0">{loadingData ? 'Document is uploading.....' : 'Click here to upload a document'}</p>
                                                </div>
                                             </div>
                                             <p className="mt-1 purpleclr font12 mb-0">Accept only jpg, jpeg, png and pdf files (Max file size 1 MB)</p>
                                          </div>
                                       </>
                                 }

                              </div>
                           </div>
                        </div>
                        <div className="btnsgroup">
                           <div className="text-center">
                              <button disabled={loadingData} className="batn batn-orange w-100" type="submit" >Save and Continue</button></div>
                           <div className="text-center  mt-2">
                              <button disabled={loadingData} className="btn-link" onClick={reprasentativeNext} type="button" fdprocessedid="9k1hy">Skip this step</button>
                           </div>
                        </div>
                     </Form>
                  </div>
               </div>
            </div>

         </>
      )
   }

   /*Company Address*/
   const Step34 = () => {

      console.log('reprasentative -- Company Address Step34')
      //const DOCUENT_ADDRESS_ARR = varifyDocumentList ;
      const [companyDetails, setCompanyDetails] = useState({
         full_address: userdetails.full_address,
         address_unit_no: userdetails.address_unit_no,
         address_building_no: userdetails.address_building_no,
         address_street_name: userdetails.address_street_name,
         address_suburbs: userdetails.address_suburbs,
         address_state: userdetails.address_state,
         address_document_type: userdetails.address_document_type,
      });



      const [subRubSggestions, setSubRubSggestions] = useState([])
      const [inputValue, setInputValue] = useState(companyDetails.address_suburbs);
      // Function to handle user input
      const setSubrubsIteminArray = (value) => { setInputValue(value); if (value.length > 2) { fetchSuggestions(value); } else { setSubRubSggestions([]) } };
      const getSubrubByli = (value) => { setInputValue(value); setSubRubSggestions([]); };
      // Function to fetch suggestions from the API
      const fetchSuggestions = async (value) => {
         try {
            const response = await axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/get_all_subrubs_name/${value}?responsetype=autocomplete`);
            const data = response.data;
            setSubRubSggestions(data);
         } catch (error) { console.error(error); }
      };

      const getCompanyDetailsById = () => {
         const queryParameters = new URLSearchParams(window.location.search)
         const userid = queryParameters.get("id");
         (async () => {
            try {
               const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/get_company_details_by_userid/" + userid);
               if (resData.data.status == 1) {
                  setCompanyDetails(resData.data.data.results)
               }
            } catch (error) { console.log(error); }
         })();
      }

      const setCompanyDetailsFun = (event) => {
         const { name, value } = event.target;
         setCompanyDetails((prevFormData) => ({
            ...prevFormData,
            [name]: value
         }));
         //formdataValue(name, value)
      };

      useEffect(() => { getCompanyDetailsById() }, []);
      const [validated, setValidated] = useState(false);
      const [validatedCnClass, setValidatedCnClass] = useState(false);
      const onCompanyDetailsSubmit = async (event) => {
         event.preventDefault();
         const queryParameters = new URLSearchParams(window.location.search)
         const userid = queryParameters.get("id");
         const form = event.currentTarget;
         if (form.checkValidity() === false) {
            event.preventDefault(); event.stopPropagation();
         } else {
            try {
               const formData = new FormData();
               formData.append('address_unit_no', companyDetails.address_unit_no);
               formData.append('address_building_no', companyDetails.address_building_no);
               formData.append('address_street_name', companyDetails.address_street_name);
               formData.append('address_suburbs', inputValue);
               formData.append('address_state', companyDetails.address_state);
               // formData.append('address_document_type', companyDetails.address_document_type);
               formData.append('user_id', userid);
               formData.append('address_document_type', companyDetails.address_document_type);
               const response = await axios.post(
                  `https://squadgoo.com.au/api.squadgoo.com.au/company_address_field_update`,
                  formData, { headers: { 'Content-Type': 'multipart/form-data' } });
               if (response.data.status == 1) {
                  getCompanyDetailsById();
                  getUserDetailsFun(userid);
                  reprasentativeNext();
               } else { toast.warning(response.data.message); }
            } catch (error) { console.error('Error uploading file:', error); }
         }
         setValidated(true);
      };
      const [loadingData, setloadingData] = useState(false);

      const [companyAddressDocumentContents, setCompanyAddressDocumentContents] = useState(companyAddressDocument);
      const userFileUploadToServercomapnyFun = async (field, file) => {
         const maxSizeInBytes = 5 * 1024 * 1024; // For example, 1 MB
         if (file.size > maxSizeInBytes) {
            console.log("File size is greater than the specified limit.");
            toast.warning('File size is greater than the specified limit.');
         } else {
            console.log("File size is within the specified limit.");
            const queryParameters = new URLSearchParams(window.location.search)
            const userid = queryParameters.get("id");
            setloadingData(true);
            try {
               const formData = new FormData();
               formData.append('file', file);
               formData.append('uploaded_for', field);
               formData.append('userid', userid);
               formData.append('maxSizeInBytes', maxSizeInBytes);
               const response = await axios.post(
                  `https://squadgoo.com.au/api.squadgoo.com.au/userdetails_file_uploads`,
                  formData, { headers: { 'Content-Type': 'multipart/form-data' } });
               //console.log('File uploaded successfully:', response.data);
               setloadingData(false)
               if (response.data.status == 1) {
                  setCompanyAddressDocumentContents(response.data.data.upload_array)
               } else { toast.warning(response.data.message); }
            } catch (error) { console.error('Error uploading file:', error); }
         }
      };

      /*----------------------------------------------*/

      const profileDocumentChange = (select) => {
         setCompanyDetails((prevData) => ({ ...prevData, 'address_document_type': select.value }))
      }

      /*----------------------------------------------*/
      const [address, setAddress] = useState({
         lat: '',
         lng: '',
         unit: '',
         houseNumber: '',
         streetName: '',
         suburb: '',
         city: '',
         state: '',
         country: '',
         placevalue: ''
      });

      //console.log(userdetails);
      const [googleAddressValue, setGoogleAddress] = useState(userdetails.full_address);
      const googleAuotoCompelte = (newAddress) => {
         setAddress(newAddress);
         setGoogleAddress(`${newAddress.placevalue}`)
      };
      const handleInputChange = (e) => { setGoogleAddress(e.target.value); };

      useEffect(() => {
         console.log(address);
         if (address && address.houseNumber) {
            setCompanyDetails((prevFormData) => ({ ...prevFormData, ['address_building_no']: address.houseNumber }));
         }
         if (address && address.state) {
            const stateObject = get_all_states.find((obj) => obj.state === address.state);
            if (stateObject) {
               setCompanyDetails((prevFormData) => ({ ...prevFormData, ['address_user_state']: stateObject.id }));
            }
         }

         if (address && address.streetName) {
            setCompanyDetails((prevFormData) => ({ ...prevFormData, ['address_street_name']: address.streetName }));
         }

         if (address && address.city) {
            setInputValue(address.city);
            //setAddressDetails((prevFormData) => ({ ...prevFormData, ['address_street_name']: address.city }));
         }
      }, [address]);

      return (
         <>
            <div className="stepscont">
               <div className="row">
                  <div className="col-md-12 stepscontright">
                     <Form noValidate validated={validated} onSubmit={onCompanyDetailsSubmit} >
                        <h3 className="step-backbtnwrap d-flex align-items-center" >
                           <span className="stepbackarrow mr-1" >
                              <img src="/front/images/stepbackarrow.svg" onClick={handlePrevious} ></img>
                           </span>Company address
                        </h3>
                        <div className="row">

                           <div className="col-md-12">
                              <div className="form-group mb-2 mr-2">
                                 <label className="form-label">Full address <span className="starr">*</span></label>
                                 <GoogleAutocomplete
                                    onSelect={googleAuotoCompelte}
                                    required={true}
                                    name='full_address'
                                    value={googleAddressValue}
                                    placeHolder='Enter full address'
                                    className="form-control"
                                    onChange={handleInputChange}
                                 />
                              </div>
                           </div>

                           <div className="col-md-6">
                              <div className="d-flex">
                                 <div className="form-group mb-2 mr-2">
                                    <label className="form-label">Unit no<span className="starr">*</span></label>
                                    <input name="address_unit_no" required={true} onChange={setCompanyDetailsFun} value={companyDetails.address_unit_no} type="text" className="form-control" placeholder="Unit" />
                                 </div>
                                 <div className="form-group mb-2">
                                    <label className="form-label">Building no<span className="starr">*</span></label>
                                    <input name="address_building_no" required={true} onChange={setCompanyDetailsFun} value={companyDetails.address_building_no} type="text" className="form-control" placeholder="Building no" />
                                 </div>
                              </div>
                           </div>

                           <div className="col-md-6">
                              <div className="form-group mb-2">
                                 <label className="form-label">Street name<span className="starr">*</span></label>
                                 <input name="address_street_name" required={true} onChange={setCompanyDetailsFun} value={companyDetails.address_street_name} type="text" className="form-control" placeholder="Enter Street name" />
                              </div>
                           </div>
                           <div className="col-md-6">
                              <div className="form-group mb-2">
                                 <label className="form-label">Suburb<span className="starr">*</span></label>
                                 <input type="text" className="form-control" required name='address_subrub' value={inputValue} placeholder="Enter your suburb" onChange={(e) => setSubrubsIteminArray(e.target.value)} />
                                 {
                                    subRubSggestions.length ?
                                       <>
                                          <ul className='auto_search_ul'>
                                             {
                                                subRubSggestions.map((suggestion) => (<li onClick={(e) => getSubrubByli(suggestion.name)} key={suggestion.id}>{suggestion.name}</li>))
                                             }
                                          </ul>
                                       </>
                                       :
                                       ''
                                 }
                              </div>
                           </div>

                           <div className="col-md-6">
                              <div className="form-group mb-2">
                                 <label className="form-label">State<span className="starr">*</span></label>
                                 <select className="form-control mr-2" required={true} onChange={setCompanyDetailsFun} value={companyDetails.address_state} name="address_state" >
                                    <option value={''}>Select State</option>
                                    {get_all_states.map((item, index) => (<option value={item.id} >{item.state}</option>))}
                                 </select>
                              </div>
                           </div>

                           <div className="col-md-12">
                              <div className="form-group mb-2">
                                 <label className="form-label">Document for address proof <span className="starr">*</span> <span className="infowrap"><i className="fa fa-info"></i><span className="onhoverinfo">Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum</span></span></label>
                                 <Select className='react_select' name="address_document_proof_type" required aria-label="4"
                                    options={varifyDocumentListOption}
                                    isSearchable
                                    placeholder="Search..."
                                    styles={customStyles}
                                    isDisabled={(companyDetails.address_document_type && companyAddressDocumentContents.length >= 1) ? true : false}
                                    onChange={profileDocumentChange}
                                    value={varifyDocumentListOption.find(item => item.value == companyDetails.address_document_type)}
                                 />

                                 {/* <select disabled={(companyDetails.address_document_type && companyAddressDocumentContents.length >= 1) ? true : false} className="form-control" required={true} onChange={setCompanyDetailsFun} value={companyDetails.address_document_proof_type} name="address_document_proof_type" >
                                    <option value='' >Select document</option>
                                    {
                                       varifyDocumentList.map((item, index) => (
                                          <option selected={companyDetails.address_document_type == item.document_id ? true : false} value={item.document_id}>{item.document_type}</option>
                                       ))
                                    }
                                 </select> */}
                              </div>
                           </div>
                           <div className="col-md-12">
                              <div className="form-group mb-2">
                                 <div className="form-group addressproofdoc mb-3">
                                    <label className="form-label">Upload address proof document <span className="starr">*</span> </label>
                                    {
                                       <>
                                          {
                                             companyAddressDocumentContents.map((item, index) => (
                                                <>
                                                   <div className="documentwrap d-flex align-items-center mb-2">
                                                      <a className='document_list' target="_blank" href={'https://squadgoo.com.au/api.squadgoo.com.au/' + item.document_url}>
                                                         <p>Address Document {index + 1}</p>
                                                      </a>
                                                      <div className="documentwrapinner d-flex align-items-center">
                                                         {/* <span className='cursor_pointer' onClick={e => viewFileFromServer('identity_document_proof' , item.id) }><img src="/front/images/eye.svg" /></span> */}
                                                         <span className='cursor_pointer ml-2' onClick={e => editFileFromServer('company_address_document', item.id)}><img src="/front/images/edit.png" /></span>
                                                         <span className='cursor_pointer ml-2' onClick={e => removeFileFromServer('company_address_document', item.id)}><img src="/front/images/delete.png" /></span>
                                                      </div>
                                                   </div>
                                                </>
                                             ))
                                          }
                                       </>
                                    }
                                    {
                                       companyAddressDocumentContents.length >= 1 ?
                                          ''
                                          :
                                          <>
                                             <div className="customuploadsec">
                                                {!loadingData && <input type="file" accept="image/x-png,image/jpg,image/jpeg ,application/pdf" onChange={(e) => userFileUploadToServercomapnyFun('company_address_document', e.target.files[0])} />}
                                                <div className="customuploadinner text-center">
                                                   <div className="my-4"><img src="/front/images/uploadicon.png" className="mt-4" /></div>
                                                   <p className="mb-0">{loadingData ? 'Document is uploading.....' : 'Click here to upload a file'}</p>
                                                </div>
                                             </div>
                                             <p className="mt-1 purpleclr font12 mb-0">Accept only jpg, jpeg, png and pdf files (Max file size 1 MB)</p>
                                          </>
                                    }

                                 </div>
                              </div>
                           </div>

                        </div>
                        <div className="btnsgroup">
                           <div className="text-center">
                              <button disabled={loadingData} className="batn batn-orange w-100" type="submit">Save and Continue</button>
                           </div>
                           <div className="text-center  mt-2">
                              <button disabled={loadingData} className="btn-link" onClick={reprasentativeNext} type="button" fdprocessedid="9k1hy">Skip this step</button>
                           </div>
                        </div>

                     </Form>
                  </div>
               </div>
            </div>

         </>
      )
   }

   /*Tax Details*/
   const Step35 = () => {
      console.log('reprasentative -- contact details Step35');
      // const [contactNumber, setContactNumber] = useState(userdetails.contact_number)
      // const checkContactNumer = (event) => { const value = event.target.value; const regex = /^[0-9\b]+$/; setContactNumber(''); if (value === '' || regex.test(value)) { setContactNumber(value); } }
      const [isChecked, setIsChecked] = useState(userdetails.terms_and_condition);
      const handleCheckboxChange = () => { setIsChecked(!isChecked); };
      const [taxInformationNumber, setTaxInformationNumber] = useState(userdetails.tax_type_value)
      const setTaxTypeFun = (event) => { setTaxInformationNumber(event.target.value); };

      const [validated, setValidated] = useState(false);
      //const [validatedCnClass, setValidatedCnClass] = useState(false);
      const onContactDetailsSubmit = async (event) => {
         event.preventDefault();
         const queryParameters = new URLSearchParams(window.location.search)
         const userid = queryParameters.get("id");
         const form = event.currentTarget;
         // setValidatedCnClass(false);

         //if (!event.target.contact_number.value) { setValidatedCnClass(true) }
         if (form.checkValidity() === false) {
            event.preventDefault(); event.stopPropagation();
         } else {
            try {
               const formData = new FormData();
               // formData.append('contact_country_code', event.target.contact_country_code.value);
               // formData.append('contact_number', contactNumber);
               formData.append('tax_type_value', taxInformationNumber);
               formData.append('tax_type', 'abn');
               formData.append('terms_and_condition', (isChecked ? 1 : 0));
               formData.append('userid', userid);
               const response = await axios.post(`https://squadgoo.com.au/api.squadgoo.com.au/userdetails_data_update`,
                  formData, { headers: { 'Content-Type': 'multipart/form-data' } });
               if (response.data.status == 1) {
                  getUserDetailsFun(userid);
                  indivisaualNext()
               } else { toast.warning(response.data.message); }
            } catch (error) { console.error('Error uploading file:', error); }
         }
         setValidated(true);
      };
      return (<>
         <div className="stepscont">
            <div className="row">
               <div className="col-md-12 stepscontright">
                  <Form noValidate validated={validated} onSubmit={onContactDetailsSubmit} >
                     <h3 className="step-backbtnwrap d-flex align-items-center" >
                        <span className="stepbackarrow mr-1" >
                           <img src="/front/images/stepbackarrow.svg" onClick={handlePrevious} ></img>
                        </span>Tax Information
                     </h3>
                     <div className="row">

                        <div className="col-md-12">
                           <div className="form-group mb-2">
                              <label className="form-label">ABN <span className="starr">*</span></label>
                              <input
                                 required={true}
                                 type="text"
                                 name='tax_type_value'
                                 className="form-control"
                                 maxlength={11}
                                 onChange={setTaxTypeFun}
                                 value={taxInformationNumber}
                                 placeholder="Enter ABN number"
                              />
                           </div>
                        </div>
                        <div className="col-md-12 mb-3 mt-2">
                           <div className="form-group mb-2">


                              <div className="form-check">
                                 <input required={true} checked={isChecked} onChange={handleCheckboxChange} name="terms_and_condition" type="checkbox" className="form-check-input" />
                                 <label className="checklabel mr-3 ml-2">I accept
                                    <Link target='_blank' to={`/privacy-policy`} className="linktxt"> Privacy policy</Link> and
                                    <Link target='_blank' to={`/terms-and-conditions`} className="linktxt"> Terms &amp; conditions</Link> agreement.
                                 </label>
                                 <div className="invalid-feedback">You must agree before submitting.</div>
                              </div>

                           </div>
                        </div>

                     </div>
                     <div className="btnsgroup">
                        <div className="text-center">
                           <button className="batn batn-orange w-100" type="submit" >Submit</button>
                        </div>
                        <div className="text-center  mt-2"><button onClick={indivisaualNext} className="btn-link" type="button" fdprocessedid="9k1hy">Skip this step</button></div>
                     </div>
                  </Form>
               </div>
            </div>
         </div>

      </>)

   }

   /*thankyou*/
   const Step36 = () => {
      console.log('reprasentative -- contact details Step36')
      return (
         <>
            <div className="stepscont">
               <div className="row">
                  <div className="col-md-12 stepscontright">
                     <form className="commonform">
                        <div className="row">
                           <div className="col-md-12 text-center laststepp py-4">
                              <img src="/front/images/checkborder.png" />
                              <p className="mt-4 mb-3">Your profile created successfully!</p>
                              <div onClick={redirectToDashboard} className="batn batn-orange">Go to dashboard</div>
                           </div>
                        </div>
                     </form>
                  </div>
               </div>
            </div>
         </>
      )
   }
   /************************************** company owner **********************************/

   const companyOwnerNext = () => { setStep((prevStep) => prevStep + 1) };
   const companyOwnerNext41 = () => { setStep(41) }

   /*upload profile*/
   const Step4 = () => {


      const removeProfilePic = () => {
         setProfilePicUrl('');
         setProfilePicUrlDefault(true);
         formdataValue('profilepic', 'blank')
      }

      const profileUploadToServer = (e) => {
         // setProfilePicUrl(URL.createObjectURL(e.target.files[0])); setProfilePicUrlDefault(false);
         // fileUploadToServer('profilepic', e.target.files[0]);
         const file = e.target.files[0];
         const maxSizeInBytes = 1 * 1024 * 1024; // For example, 1 MB
         if (file.size > maxSizeInBytes) {
            console.log("File size is greater than the specified limit.");
            toast.warning('File size is greater than the specified limit.');
         } else {
            setProfilePicUrl(URL.createObjectURL(e.target.files[0]));
            setProfilePicUrlDefault(false);
            fileUploadToServer('profilepic', e.target.files[0]);
         }
      }

      console.log('owner -- logo step 4')
      return (
         <div>
            <div className="stepscont">
               <div className="row">
                  <div className="col-md-12 stepscontright">
                     <form className="commonform">
                        <h3 className="step-backbtnwrap d-flex align-items-center" >
                           <span className="stepbackarrow mr-1" >
                              <img src="/front/images/stepbackarrow.svg" onClick={go2ndStep} ></img>
                           </span> Upload your logo
                        </h3>

                        <div className="text-center form-group mb-3 mt-3">
                           <div className="profilephotodiv">
                              <input className='signuppage_profile' type="file" accept="image/*" onChange={handleCropFileChange} />
                              <img src={profilePicUrl}></img>
                              {
                                 (profilePicUrlDefault) ?
                                    <>
                                       <div className="prfileupload">
                                          {/* <input
                                             type="file"
                                             accept="image/x-png,image/gif,image/jpeg ,application/pdf"
                                             id="profilepic"
                                             onChange={profileUploadToServer}
                                          ></input> */}
                                          <input id="profilepic" type="file" accept="image/*" onChange={handleCropFileChange} />
                                          <span>Upload Logo</span>
                                       </div>
                                    </>
                                    : ''
                              }
                              <div className="dropdown pimgdrop">
                                 <button className=" dropdown-toggle" type="button" id="pimgdropmenu" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="fa fa-pencil" aria-hidden="true"></i>
                                 </button>
                                 {
                                    (!profilePicUrlDefault) ?
                                       <>
                                          <ul className="dropdown-menu" aria-labelledby="pimgdropmenu">
                                             <li>
                                                <a className="dropdown-item updatepro" href="#">Update
                                                   {/* <input type="file" accept="image/x-png,image/gif,image/jpeg ,application/pdf"
                                                   id="profilepic"
                                                   onChange={profileUploadToServer} ></input> */}
                                                   <input id="profilepic" type="file" accept="image/*" onChange={handleCropFileChange} />
                                                </a>
                                             </li>
                                             <li  ><a onClick={(e) => handleShowModal()} className="dropdown-item" href="javascript:void(0)">Capture Image</a></li>
                                             <li  ><a onClick={(e) => removeProfilePic('profilepic')} className="dropdown-item" href="javascript:void(0)">Remove</a></li>
                                          </ul>
                                       </>
                                       :
                                       <>
                                          <ul className="dropdown-menu" aria-labelledby="pimgdropmenu">
                                             <li>
                                                <a className="dropdown-item updatepro" href="#">Upload Photo
                                                   {/* <input type="file" accept="image/x-png,image/jpeg"
                                                      id="profilepic"
                                                      onChange={profileUploadToServer} ></input> */}
                                                   <input id="profilepic" type="file" accept="image/*" onChange={handleCropFileChange} />
                                                </a>
                                             </li>
                                             <li  ><a onClick={(e) => handleShowModal()} className="dropdown-item" href="javascript:void(0)">Capture Image</a></li>
                                          </ul>
                                       </>
                                 }
                                 {/* <ul className="dropdown-menu" aria-labelledby="pimgdropmenu">
                                    <li><a className="dropdown-item updatepro" href="#">Update

                                       <input type="file" accept="image/x-png,image/gif,image/jpeg ,application/pdf"
                                          id="profilepic"
                                          onChange={profileUploadToServer} ></input></a></li>
                                    <li  ><a onClick={(e) => removeProfilePic('profilepic')} className="dropdown-item" href="javascript:void(0)">Remove</a></li>
                                 </ul> */}
                              </div>
                              <div className="editprofileicon">
                              </div>
                           </div>
                        </div>
                        <div className="mb-3">
                           <p className="mb-0"><strong>Logo guidelines:</strong></p>
                           <p className="mb-0">1. logo should have a transparent background,</p>
                           <p className="mb-0">2. 400x400 is the required resolution/dimension for the logo.</p>
                           <p className="mb-0">3. We only accepts PNG, SVG images with the maximum size 1MB</p>

                        </div>

                     </form>
                  </div>
               </div>
            </div>
            <div className="btnsgroup">
               <div className="text-center">
                  <button className="batn batn-orange w-100" onClick={companyOwnerNext41} type="button" fdprocessedid="fpb8r9">Save and Continue</button>
               </div>
               <div className="text-center  mt-2">
                  <button className="btn-link" onClick={companyOwnerNext41} type="button" fdprocessedid="9k1hy">Skip this step</button>
               </div>
            </div>
         </div>
      )
   };

   /*Owner's basic details */
   const Step41 = () => {
      console.log('company- Basic details Step41');
      const [identityDocumentType, setIdentityDocumentType] = useState([]);
      const [fileLoadingContent, setfileLoadingContent] = useState(false);
      useEffect(() => {
         (async () => {
            try {
               const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/get_all_identity_documenttype");
               if (resData.data.status == 1) { setIdentityDocumentType(resData.data.data.results); }
            } catch (error) { console.log(error); }
         })();
      }, []);


      const [besicDetails, setBasicDetailsData] = useState({
         name_salute: userdetails.name_salute,
         identity_proof_type: userdetails.identity_proof_type,
         first_name: userdetails.first_name,
         middle_name: userdetails.middle_name,
         last_name: userdetails.last_name,
         dob_day: userdetails.dob_day,
         dob_month: userdetails.dob_month,
         dob_year: userdetails.dob_year,
         identity_document_proof_front: userdetails.identity_document_proof_front,
         identity_document_proof_backend: userdetails.identity_document_proof_backend
      });


      const basiDetailsUpdatefun = (event) => {
         const { name, value } = event.target;
         if ((name == 'first_name' || name == 'middle_name' || name == 'last_name') && !alphabeticRegex.test(value)) {
            return null;
         }
         setBasicDetailsData((prevFormData) => ({
            ...prevFormData,
            [name]: value
         }));
      };

      const [validated, setValidated] = useState(false);
      const onBasicDetailsSubmit = async (event) => {
         event.preventDefault();
         const form = event.currentTarget;
         if (form.checkValidity() === false) {
            event.preventDefault(); event.stopPropagation(); console.log('error');
         } else {
            console.log('not error');
            setfileLoadingContent(true);
            try {
               const queryParameters = new URLSearchParams(window.location.search)
               const userid = queryParameters.get("id");
               const formData = new FormData();
               formData.append('name_salute', event.target.name_salute.value);
               formData.append('first_name', event.target.first_name.value);
               formData.append('middle_name', event.target.middle_name.value);
               formData.append('last_name', event.target.last_name.value);
               formData.append('dob_day', event.target.dob_day.value);
               formData.append('dob_month', event.target.dob_month.value);
               formData.append('dob_year', event.target.dob_year.value);
               formData.append('identity_proof_type', event.target.identity_proof_type.value);
               formData.append('userid', userid);
               formData.append('user_type', 2); /*indivisual recruiter*/
               const response = await axios.post(
                  `https://squadgoo.com.au/api.squadgoo.com.au/userdetails_data_update`,
                  formData, { headers: { 'Content-Type': 'multipart/form-data' } });
               if (response.data.status == 1) {
                  getUserDetailsFun(userid);
                  companyOwnerNext();
                  setfileLoadingContent(false);
               } else { toast.warning(response.data.message); setfileLoadingContent(false); }
            } catch (error) { console.error('Error uploading file:', error); }
         }
         setValidated(true);
      };


      const [userUploadIdentityDocumentsContent, setUserUploadIdentityDocumentsContent] = useState(userUploadIdentityDocuments);
      const userFileUploadToServerBasicFun = async (field, file) => {
         const maxSizeInBytes = 5 * 1024 * 1024; // For example, 1 MB
         if (file.size > maxSizeInBytes) {
            console.log("File size is greater than the specified limit.");
            toast.warning('File size is greater than the specified limit.');
         } else {
            console.log("File size is within the specified limit.");
            const queryParameters = new URLSearchParams(window.location.search)
            const userid = queryParameters.get("id");
            setfileLoadingContent(true);
            try {
               const formData = new FormData();
               formData.append('file', file);
               formData.append('uploaded_for', field);
               formData.append('userid', userid);
               formData.append('maxSizeInBytes', maxSizeInBytes);
               const response = await axios.post(
                  `https://squadgoo.com.au/api.squadgoo.com.au/userdetails_file_uploads`,
                  formData, { headers: { 'Content-Type': 'multipart/form-data' } });
               //console.log('File uploaded successfully:', response.data);
               if (response.data.status == 1) {
                  setUserUploadIdentityDocumentsContent(response.data.data.upload_array); setfileLoadingContent(false);
               } else { console.log(response.data.message); setfileLoadingContent(false); }
            } catch (error) { console.error('Error uploading file:', error); }
         }
      };

      return (<>
         <div className="stepscont">
            <div className="row">
               <div className="col-md-12 stepscontright">


                  {/* ----------------------- */}
                  <Form noValidate validated={validated} onSubmit={onBasicDetailsSubmit}>
                     <div className="row">
                        <div className="col-md-12 stepscontright">

                           <h3 className="step-backbtnwrap d-flex align-items-center" >
                              <span className="stepbackarrow mr-1" >
                                 <img src="/front/images/stepbackarrow.svg" onClick={go4thStep} ></img>
                              </span>Owner's basic details
                           </h3>
                           <div className="row">
                              <div className="col-md-6">
                                 <div className="d-flex">
                                    <div className="form-group mb-2 mr-2">
                                       <label className="form-label">Salute<span className="starr">*</span></label>
                                       <select name="name_salute" className="form-control" onChange={basiDetailsUpdatefun} >
                                          {
                                             ABBREVIATIONS.map((item) => (
                                                <option selected={(besicDetails.name_salute == item.value) ? true : false} key={item.id} value={item.value}>{item.value}</option>
                                             ))
                                          }
                                       </select>
                                    </div>
                                    <div className="form-group mb-2">
                                       <label className="form-label">First name<span className="starr">*</span></label>
                                       <input type="text" className="form-control" name="first_name" required value={besicDetails.first_name} onChange={basiDetailsUpdatefun} placeholder="Andrew" />
                                    </div>
                                 </div>
                              </div>
                              <div className="col-md-6">
                                 <div className="form-group mb-2">
                                    <label className="form-label">Middle name</label>
                                    <input type="text" className="form-control" name="middle_name" onChange={basiDetailsUpdatefun} value={besicDetails.middle_name} placeholder="Enter middle name" />
                                 </div>
                              </div>
                              <div className="col-md-6">
                                 <div className="form-group mb-2">
                                    <label className="form-label">Last name<span className="starr">*</span></label>
                                    <input type="text" className="form-control" name="last_name" required value={besicDetails.last_name} onChange={basiDetailsUpdatefun} placeholder="Enderson" />
                                 </div>
                              </div>
                              <div className="col-md-6">

                                 <div className="form-group mb-2">
                                    <label className="form-label">Date of birth<span className="starr">*</span></label>
                                    <div className="d-flex datefildgruop">
                                       <select name="dob_day" required className="form-control mr-2 datefild" onChange={basiDetailsUpdatefun}>
                                          {<DayNumberLoop select_attr={besicDetails.dob_day} />}
                                       </select>
                                       <select name="dob_month" required className="form-control mr-2 monthfild" onChange={basiDetailsUpdatefun}>
                                          {<MonthsHtml select_attr={besicDetails.dob_month} />}
                                       </select>
                                       <select name="dob_year" required className="form-control yearfild" onChange={basiDetailsUpdatefun} >
                                          {<YearNumberLoop select_attr={besicDetails.dob_year} />}
                                       </select>
                                    </div>
                                 </div>
                              </div>
                              <div className="col-md-12">

                                 <div className="form-group mb-2">
                                    <label className="form-label">Document for identity proof <span className="starr">*</span> <span className="infowrap"><i className="fa fa-info"></i><span className="onhoverinfo">Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum</span></span></label>
                                    <select disabled={(besicDetails.identity_proof_type && userUploadIdentityDocumentsContent.length > 0) ? true : false} className="form-control" required={true} onChange={basiDetailsUpdatefun} name="identity_proof_type" fdprocessedid="m7dnpr">
                                       <option value="">Select document</option>
                                       {
                                          identityDocumentType.map((item, index) => (
                                             <option selected={(besicDetails.identity_proof_type == item.id) ? true : false} value={item.id}>{item.name}</option>
                                          ))
                                       }
                                    </select>
                                 </div>
                              </div>

                              <div className="col-md-12 mb-1">
                                 <div className="form-group mb-2">
                                    <label className="form-label">{
                                       userUploadIdentityDocumentsContent.length > 1 ?
                                          'Uploaded identity proof' :
                                          userUploadIdentityDocumentsContent.length > 0 ?
                                             'Upload identity proof back side' :
                                             'Upload identity proof front side'}
                                       <span className="starr">*</span>
                                    </label>
                                    {

                                       <>
                                          {
                                             userUploadIdentityDocumentsContent.map((item, index) => (
                                                <>
                                                   <div className="documentwrap d-flex align-items-center mb-2">
                                                      <a className='document_list' target="_blank" href={'https://squadgoo.com.au/api.squadgoo.com.au/' + item.document_url}>
                                                         <p>Identity Document {index + 1}</p>
                                                      </a>
                                                      <div className="documentwrapinner d-flex align-items-center">
                                                         {/* <span className='cursor_pointer' onClick={e => viewFileFromServer('identity_document_proof' , item.id) }><img src="/front/images/eye.svg" /></span> */}
                                                         <span className='cursor_pointer ml-2' onClick={e => editFileFromServer('identity_document_proof', item.id)}><img src="/front/images/edit.png" /></span>
                                                         <span className='cursor_pointer ml-2' onClick={e => removeFileFromServer('identity_document_proof', item.id)}><img src="/front/images/delete.png" /></span>
                                                      </div>
                                                   </div>
                                                </>
                                             ))
                                          }
                                       </>

                                    }
                                    {
                                       userUploadIdentityDocumentsContent.length >= 2 ?
                                          ''
                                          :
                                          <>
                                             <div className="form-group identityproof">
                                                <div className="customuploadsec">
                                                   {!fileLoadingContent && <input type="file" accept="image/x-png,image/gif,image/jpeg ,application/pdf" onChange={(e) => userFileUploadToServerBasicFun('identity_document_proof', e.target.files[0])}></input>}
                                                   <div className="customuploadinner text-center">
                                                      <div className="my-4"><img src="/front/images/uploadicon.png" className="mt-4"></img></div>
                                                      <p className="mb-0">{fileLoadingContent ? 'Document is uploading.....' : 'Click here to upload a file'}</p>
                                                   </div>
                                                </div>
                                                <p className="mt-1 purpleclr font12 mb-0">Accept only jpg, jpeg, png and pdf files (Max file size 1 MB)</p>
                                             </div>
                                          </>
                                    }
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="text-center">
                        <Button disabled={fileLoadingContent} className="batn batn-orange w-100" type="submit" >Save and Continue</Button>
                     </div>
                     <div className="text-center  mt-2"><button className="btn-link" type="button" onClick={companyOwnerNext}>Skip this step</button></div>
                  </Form>
                  {/* ----------------------- */}
               </div>
            </div>
         </div>
      </>);
   }

   /*Owner's basic address */
   const Step42 = () => {
      console.log('Owner -- Company details Step42')
      const get_all_states_string = localStorage.getItem('get_all_states');
      const get_all_states = JSON.parse(get_all_states_string);
      const [userUploadIdentityDocumentsContent, setUserUploadIdentityDocumentsContent] = useState(userUploadAddressDocuments);

      //const signup_userdetails  =  getSignupUserData();
      const [subRubSggestions, setSubRubSggestions] = useState([])
      const [inputValue, setInputValue] = useState(userdetails.address_suburbs);
      // Function to handle user input
      const setSubrubsIteminArray = (value) => { setInputValue(value); if (value.length > 2) { fetchSuggestions(value); } else { setSubRubSggestions([]) } };
      const getSubrubByli = (value) => { setInputValue(value); setSubRubSggestions([]); };
      // Function to fetch suggestions from the API
      const fetchSuggestions = async (value) => {
         try {
            const response = await axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/get_all_subrubs_name/${value}?responsetype=autocomplete`);
            const data = response.data;
            setSubRubSggestions(data);
         } catch (error) { console.error(error); }
      };
      /*****************"Form edit "*******************/
      const [addressDetails, setAddressDetails] = useState({
         full_address: userdetails.full_address,
         address_unit_no: userdetails.address_unit_no,
         address_building_no: userdetails.address_building_no,
         address_street_name: userdetails.address_street_name,
         address_suburbs: userdetails.address_suburbs,
         address_user_state: userdetails.address_user_state,
         address_document_proof_type: userdetails.address_document_proof_type,
      });

      const addressDetailsUpdateFun = (event) => {
         const { name, value } = event.target;
         setAddressDetails((prevFormData) => ({
            ...prevFormData,
            [name]: value
         }));
      };

      const [validated, setValidated] = useState(false);
      const onAddressDetailsSubmit = async (event) => {
         event.preventDefault();
         const queryParameters = new URLSearchParams(window.location.search)
         const userid = queryParameters.get("id");
         const form = event.currentTarget;
         if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
         } else {
            try {
               const formData = new FormData();
               formData.append('full_address', event.target.full_address.value);
               formData.append('address_unit_no', event.target.address_unit_no.value);
               formData.append('address_building_no', event.target.address_building_no.value);
               formData.append('address_street_name', event.target.address_street_name.value);
               formData.append('address_suburbs', event.target.address_subrub.value);
               formData.append('address_user_state', event.target.address_user_state.value);
               // formData.append('address_document_proof_type', event.target.address_document_proof_type.value);
               formData.append('address_document_proof_type', addressDetails.address_document_proof_type);
               formData.append('userid', userid);
               const response = await axios.post(
                  `https://squadgoo.com.au/api.squadgoo.com.au/userdetails_data_update`,
                  formData, { headers: { 'Content-Type': 'multipart/form-data' } });
               if (response.data.status == 1) {
                  //toast.success('Removed Successfully!');
                  getUserDetailsFun(userid);
                  handleNext()
               } else { toast.warning(response.data.message); }
            } catch (error) { console.error('Error uploading file:', error); }
            //handleNext()
         }
         setValidated(true);
      };

      const [fileLoadingContent, setLoadingContent] = useState(0);
      const userFileUploadToServerAddress = async (field, file) => {
         const maxSizeInBytes = 5 * 1024 * 1024; // For example, 1 MB
         if (file.size > maxSizeInBytes) {
            console.log("File size is greater than the specified limit.");
            toast.warning('File size is greater than the specified limit.');
         } else {
            console.log("File size is within the specified limit.");
            const queryParameters = new URLSearchParams(window.location.search)
            const userid = queryParameters.get("id");
            try {
               setLoadingContent(1);
               const formData = new FormData();
               formData.append('file', file);
               formData.append('uploaded_for', field);
               formData.append('userid', userid);
               formData.append('maxSizeInBytes', maxSizeInBytes);
               const response = await axios.post(
                  `https://squadgoo.com.au/api.squadgoo.com.au/userdetails_file_uploads`,
                  formData, { headers: { 'Content-Type': 'multipart/form-data' } });
               //console.log('File uploaded successfully:', response.data);
               setLoadingContent(0);
               if (response.data.status == 1) {
                  setUserUploadIdentityDocumentsContent(response.data.data.upload_array)
                  //toast.success('Uploaded Successfully!');
               } else { /*toast.warning(response.data.message);*/ }
            } catch (error) {  /*console.error('Error uploading file:', error);*/ }
         }
      };



      /*----------------------------------------------*/
      const profileDocumentChange = (select) => {
         setAddressDetails((prevData) => ({ ...prevData, 'address_document_proof_type': select.value }))
      }


      /*----------------------------------------------*/
      const [address, setAddress] = useState({
         lat: '',
         lng: '',
         unit: '',
         houseNumber: '',
         streetName: '',
         suburb: '',
         city: '',
         state: '',
         country: '',
         placevalue: ''
      });

      //console.log(userdetails);
      const [googleAddressValue, setGoogleAddress] = useState(userdetails.full_address);
      const googleAuotoCompelte = (newAddress) => {
         setAddress(newAddress);
         setGoogleAddress(`${newAddress.placevalue}`)
      };
      const handleInputChange = (e) => { setGoogleAddress(e.target.value); };

      useEffect(() => {
         console.log(address);
         if (address && address.houseNumber) {
            setAddressDetails((prevFormData) => ({ ...prevFormData, ['address_building_no']: address.houseNumber }));
         }
         if (address && address.state) {
            const stateObject = get_all_states.find((obj) => obj.state === address.state);
            if (stateObject) {
               setAddressDetails((prevFormData) => ({ ...prevFormData, ['address_user_state']: stateObject.id }));
            }
         }

         if (address && address.streetName) {
            setAddressDetails((prevFormData) => ({ ...prevFormData, ['address_street_name']: address.streetName }));
         }

         if (address && address.city) {
            setInputValue(address.city);
         }
      }, [address]);

      return (
         <div>

            <Form noValidate validated={validated} onSubmit={onAddressDetailsSubmit} >
               <div className="stepscont">
                  <div className="row">
                     <div className="col-md-12 stepscontright">
                        <h3 className="step-backbtnwrap d-flex align-items-center">
                           <span ><img src="/front/images/stepbackarrow.svg" onClick={handlePrevious}></img></span>
                           Owner's Address
                        </h3>
                        <div className="row">
                           <div className="col-md-12">
                              <div className="form-group mb-2 mr-2">
                                 <label className="form-label">Full address <span className="starr">*</span></label>
                                 {address && <GoogleAutocomplete
                                    onSelect={googleAuotoCompelte}
                                    required={true}
                                    name='full_address'
                                    value={googleAddressValue}
                                    placeHolder='Enter full address'
                                    className="form-control"
                                    onChange={handleInputChange}
                                 />}
                              </div>
                           </div>
                           <div className="col-md-6">
                              <div className="d-flex">
                                 <div className="form-group mb-2 mr-2">
                                    <label className="form-label">Unit no</label>
                                    <input name="address_unit_no" value={addressDetails.address_unit_no} onChange={addressDetailsUpdateFun} type="text" className="form-control" placeholder="Unit no" />
                                 </div>
                                 <div className="form-group mb-2">
                                    <label className="form-label">House number<span className="starr">*</span></label>
                                    <input name="address_building_no" value={addressDetails.address_building_no} required onChange={addressDetailsUpdateFun} type="text" className="form-control" placeholder="Building no" />
                                 </div>
                              </div>
                           </div>
                           <div className="col-md-6">
                              <div className="form-group mb-2">
                                 <label className="form-label">Street name<span className="starr">*</span></label>
                                 <input name="address_street_name" value={addressDetails.address_street_name} required onChange={addressDetailsUpdateFun} type="text" className="form-control" placeholder="Enter Street name" />
                              </div>
                           </div>
                           <div className="col-md-6">
                              <div className="form-group mb-2">
                                 <label className="form-label">Suburb<span className="starr">*</span></label>
                                 <input type="text" className="form-control" required name='address_subrub' value={inputValue} placeholder="Enter your suburb" onChange={(e) => setSubrubsIteminArray(e.target.value)} />
                                 {
                                    subRubSggestions.length ?
                                       <>
                                          <ul className='auto_search_ul'>
                                             {
                                                subRubSggestions.map((suggestion) => (<li onClick={(e) => getSubrubByli(suggestion.name)} key={suggestion.id}>{suggestion.name}</li>))
                                             }
                                          </ul>
                                       </>
                                       :
                                       ''
                                 }
                              </div>
                           </div>
                           <div className="col-md-6">
                              <div className="form-group mb-2">
                                 <label className="form-label">State<span className="starr">*</span></label>
                                 <select className="form-control mr-2" required name="address_user_state" onChange={addressDetailsUpdateFun}>
                                    <option value="">Select state</option>
                                    {get_all_states.map((item, index) => (<option selected={addressDetails.address_user_state == item.id ? true : false} value={item.id} >{item.state}</option>))}
                                 </select>
                              </div>
                           </div>
                           <div className="col-md-12">
                              <div className="form-group mb-2">
                                 <label className="form-label">Document for address proof <span className="infowrap"><i className="fa fa-info"></i><span className="onhoverinfo">Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum</span></span></label>
                                 {/* <select className="form-control" disabled={(addressDetails.address_document_proof_type && userUploadIdentityDocumentsContent.length > 0) ? true : false} name="address_document_proof_type" onChange={addressDetailsUpdateFun} >
                                    <option value='' >Select document</option>
                                    {
                                       varifyDocumentList.map((item, index) => (
                                          <option selected={addressDetails.address_document_proof_type == item.document_id ? true : false} value={item.document_id}>{item.document_type}</option>
                                       ))
                                    }
                                 </select> */}
                                 <Select className='react_select' name="address_document_proof_type" required aria-label="4"
                                    options={varifyDocumentListOption}
                                    isDisabled={(addressDetails.address_document_proof_type && userUploadIdentityDocumentsContent.length > 0) ? true : false}
                                    isSearchable
                                    placeholder="Search..."
                                    styles={customStyles}
                                    onChange={profileDocumentChange}
                                    value={varifyDocumentListOption.find(item => item.value == addressDetails.address_document_proof_type)}
                                 />

                              </div>
                           </div>
                           <div className="col-md-12">
                              <div className="form-group mb-2">
                                 <div className="form-group addressproofdoc mb-3">
                                    <label className="form-label">
                                       {
                                          userUploadIdentityDocumentsContent.length >= 2 ?
                                             'Uploaded address docuement' :
                                             userUploadIdentityDocumentsContent.length > 0 ?
                                                'Upload address document second' :
                                                'Upload address document first'
                                       } </label>
                                    {
                                       <>
                                          {
                                             userUploadIdentityDocumentsContent.map((item, index) => (
                                                <>
                                                   <div className="documentwrap d-flex align-items-center mb-2">
                                                      <a className='document_list' target="_blank" href={'https://squadgoo.com.au/api.squadgoo.com.au/' + item.document_url}>
                                                         <p>Address Proof Document {index + 1}</p>
                                                      </a>
                                                      <div className="documentwrapinner d-flex align-items-center">
                                                         <span className='cursor_pointer ml-2 editfile' onClick={e => editFileFromServer('address_document_proof', item.id)}><img src="/front/images/edit.png" /></span>
                                                         <span className='cursor_pointer ml-2' onClick={e => removeFileFromServer('address_document_proof', item.id)}><img src="/front/images/delete.png" /></span>
                                                      </div>
                                                   </div>
                                                </>
                                             ))
                                          }
                                       </>
                                    }
                                    {
                                       userUploadIdentityDocumentsContent.length >= 2 ?
                                          ''
                                          :
                                          <>
                                             <div className="form-group identityproof">
                                                <div className="customuploadsec">
                                                   {!fileLoadingContent && <input type="file" accept="image/x-png,image/gif,image/jpeg ,application/pdf" onChange={(e) => userFileUploadToServerAddress('address_document_proof', e.target.files[0])}  ></input>}
                                                   <div className="customuploadinner text-center">
                                                      <div className="my-4"><img src="/front/images/uploadicon.png" className="mt-4"></img></div>
                                                      <p className="mb-0">{fileLoadingContent ? 'Document is uploading......' : 'Click here to upload a file'}</p>
                                                   </div>
                                                </div>
                                                <p className="mt-1 purpleclr font12 mb-0">Accept only jpg, jpeg, png and pdf files (Max file size 1 MB)</p>
                                             </div>
                                          </>
                                    }
                                 </div>
                              </div>
                           </div>
                           <div className="btnsgroup">
                              <div className="text-center">
                                 <button disabled={fileLoadingContent} className="batn batn-orange w-100" type="submit" >Save and Continue</button>
                              </div>
                              <div className="text-center  mt-2"><button className="btn-link" type="button" onClick={handleNext}>Skip this step</button></div>
                           </div>

                        </div>
                     </div>
                  </div>
               </div>
            </Form>
         </div>
      );
   };

   /*Company details*/
   const Step43 = () => {
      console.log('Owner -- Company details Step43')
      const [companyDetails, setCompanyDetails] = useState({
         name: '',
         website: '',
         team_size: '',
         industry: '',
      });



      const setCompanyDetailsFun = (event) => {
         const { name, value } = event.target;
         setCompanyDetails((prevFormData) => ({
            ...prevFormData,
            [name]: value
         }));
         //formdataValue(name, value)
      };


      useEffect(() => {
         const queryParameters = new URLSearchParams(window.location.search)
         const userid = queryParameters.get("id");
         (async () => {
            try {
               const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/get_company_details_by_userid/" + userid);
               if (resData.data.status == 1) {
                  setCompanyDetails(resData.data.data.results)
               }
            } catch (error) { console.log(error); }
         })();
      }, []);

      const [getAllIndustry, setGetAllIndustry] = useState([]);
      const [getAllIndustryOptions, setGetAllIndustryOptions] = useState([]);
      useEffect(() => {
         const queryParameters = new URLSearchParams(window.location.search)
         const userid = queryParameters.get("id");
         (async () => {
            try {
               const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/get_all_industry");
               if (resData.data.status == 1) {
                  setGetAllIndustry(resData.data.data.results);
                  setGetAllIndustryOptions(resData.data.data.option);
               }
            } catch (error) { console.log(error); }
         })();
      }, []);


      const [validated, setValidated] = useState(false);
      const onCompanyDetailsSubmit = async (event) => {
         event.preventDefault();
         const queryParameters = new URLSearchParams(window.location.search)
         const userid = queryParameters.get("id");
         const form = event.currentTarget;
         if (form.checkValidity() === false) {
            event.preventDefault(); event.stopPropagation();
         } else {
            try {
               const formData = new FormData();
               formData.append('name', companyDetails.name);
               formData.append('website', companyDetails.website);
               formData.append('team_size', companyDetails.team_size);
               formData.append('industry', companyDetails.industry);
               formData.append('user_id', userid);
               const response = await axios.post(
                  `https://squadgoo.com.au/api.squadgoo.com.au/company_address_field_update`,
                  formData, { headers: { 'Content-Type': 'multipart/form-data' } });
               if (response.data.status == 1) {
                  getUserDetailsFun(userid);
                  companyOwnerNext();
               } else { toast.warning(response.data.message); }
            } catch (error) { console.error('Error uploading file:', error); }
         }
         setValidated(true);
      };

      const [fileLoadingContent, setfileLoadingContent] = useState(false);
      const [companyRegistrationDocumentContents, setCompanyRegistrationDocumentContents] = useState(companyRegistrationDocument);
      const userFileUploadToServercomapnyRegisFun = async (field, file) => {
         const maxSizeInBytes = 5 * 1024 * 1024; // For example, 1 MB
         if (file.size > maxSizeInBytes) {
            console.log("File size is greater than the specified limit.");
            toast.warning('File size is greater than the specified limit.');
         } else {
            console.log("File size is within the specified limit.");
            const queryParameters = new URLSearchParams(window.location.search)
            const userid = queryParameters.get("id");
            setfileLoadingContent(true);
            try {
               const formData = new FormData();
               formData.append('file', file);
               formData.append('uploaded_for', field);
               formData.append('userid', userid);
               formData.append('maxSizeInBytes', maxSizeInBytes);
               const response = await axios.post(
                  `https://squadgoo.com.au/api.squadgoo.com.au/userdetails_file_uploads`,
                  formData, { headers: { 'Content-Type': 'multipart/form-data' } });
               //console.log('File uploaded successfully:', response.data);
               setfileLoadingContent(false);
               if (response.data.status == 1) {
                  setCompanyRegistrationDocumentContents(response.data.data.upload_array)
               } else { toast.warning(response.data.message); }
            } catch (error) { console.error('Error uploading file:', error); }
         }
      };

      /*------------------------*/
      const profileDocumentChange = (select) => {
         setCompanyDetails((prevData) => ({ ...prevData, 'industry': select.value }))
      }

      return (
         <>
            <div className="stepscont">
               <div className="row">
                  <div className="col-md-12 stepscontright">
                     <Form noValidate validated={validated} onSubmit={onCompanyDetailsSubmit} >
                        <h3 className="step-backbtnwrap d-flex align-items-center" >
                           <span className="stepbackarrow mr-1" >
                              <img src="/front/images/stepbackarrow.svg" onClick={handlePrevious} ></img>
                           </span>Company details
                        </h3>
                        <div className="row">
                           <div className="col-md-6">
                              <div className="form-group mb-2">
                                 <label className="form-label">Company name <span className="starr">*</span></label>
                                 <input required={true} onChange={setCompanyDetailsFun} name="name" value={companyDetails.name} type="text" className="form-control" placeholder="Enter company name" />
                              </div>
                           </div>
                           <div className="col-md-6">
                              <div className="form-group mb-2">
                                 <label className="form-label">Company website <span className="starr">*</span></label>
                                 <input required={true} onChange={setCompanyDetailsFun} name="website" value={companyDetails.website} type="text" className="form-control" placeholder="Enter companyn website" />
                              </div>
                           </div>
                           <div className="col-md-6">
                              <div className="form-group mb-2">
                                 <label className="form-label">Company size <span className="starr">*</span></label>
                                 <input required={true} onChange={setCompanyDetailsFun} name="team_size" value={companyDetails.team_size} type="number" min="2" className="form-control" placeholder="Enter Team Size" />
                              </div>
                           </div>
                           <div className="col-md-6">
                              <div className="form-group mb-2">
                                 <label className="form-label">Industry <span className="starr">*</span></label>

                                 <Select className='react_select' name="address_document_proof_type" required aria-label="4"
                                    options={getAllIndustryOptions}
                                    isSearchable
                                    placeholder="Search..."
                                    styles={customStyles}
                                    onChange={profileDocumentChange}
                                    value={getAllIndustryOptions.find(item => item.value == companyDetails.industry)}

                                 />
                                 {/* <select className="form-control" required={true} name="industry" onChange={setCompanyDetailsFun}  >
                                    <option value={''}>Select Industry</option>
                                    {
                                       getAllIndustry.length ?
                                          <>
                                             {
                                                getAllIndustry.map((item, index) => (
                                                   <>
                                                      <option selected={companyDetails.industry == item.id ? true : false} value={item.id}>{item.industry}</option>
                                                   </>
                                                ))
                                             }
                                          </>
                                          : ''
                                    }
                                 </select> */}


                              </div>
                           </div>
                           <div className="col-md-12 mb-1">
                              <div className="form-group mb-2">
                                 <label className="form-label">Upload registration document <span className="starr">*</span> <span className="infowrap"><i className="fa fa-info"></i><span className="onhoverinfo">Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum</span></span></label>
                                 {
                                    <>
                                       {
                                          companyRegistrationDocumentContents.map((item, index) => (
                                             <>
                                                <div className="documentwrap d-flex align-items-center mb-2">
                                                   <a className='document_list' target="_blank" href={'https://squadgoo.com.au/api.squadgoo.com.au/' + item.document_url}>
                                                      <p>Registration document {index + 1}</p>
                                                   </a>
                                                   <div className="documentwrapinner d-flex align-items-center">
                                                      {/* <span className='cursor_pointer' onClick={e => viewFileFromServer('identity_document_proof' , item.id) }><img src="/front/images/eye.svg" /></span> */}
                                                      <span className='cursor_pointer ml-2' onClick={e => editFileFromServer('company_registration_document', item.id)}><img src="/front/images/edit.png" /></span>
                                                      <span className='cursor_pointer ml-2' onClick={e => removeFileFromServer('company_registration_document', item.id)}><img src="/front/images/delete.png" /></span>
                                                   </div>
                                                </div>
                                             </>
                                          ))
                                       }
                                    </>
                                 }
                                 {
                                    companyRegistrationDocumentContents.length >= 1 ?
                                       ''
                                       :
                                       <>
                                          <div className="form-group identityproof">
                                             <div className="customuploadsec">
                                                {!fileLoadingContent && <input type="file" accept="image/x-png,image/gif,image/jpeg ,application/pdf" onChange={(e) => userFileUploadToServercomapnyRegisFun('company_registration_document', e.target.files[0], true)} />}
                                                <div className="customuploadinner text-center">
                                                   <div className="my-4"><img src="/front/images/uploadicon.png" className="mt-4" /></div>
                                                   <p className="mb-0">{fileLoadingContent ? 'Document is uploading.....' : 'Click here to upload a document'}</p>
                                                </div>
                                             </div>
                                             <p className="mt-1 purpleclr font12 mb-0">Accept only jpg, jpeg, png and pdf files (Max file size 1 MB)</p>
                                          </div>
                                       </>
                                 }

                              </div>
                           </div>
                        </div>
                        <div className="btnsgroup">
                           <div className="text-center">
                              <button disabled={fileLoadingContent} className="batn batn-orange w-100" type="submit" >Save and Continue</button></div>
                           <div className="text-center  mt-2"><button className="btn-link" onClick={companyOwnerNext} type="button" fdprocessedid="9k1hy">Skip this step</button></div>
                        </div>
                     </Form>
                  </div>
               </div>
            </div>

         </>
      )
   }

   /*Company Address*/
   const Step44 = () => {

      console.log('Owner -- Company Address Step44')
      //const DOCUENT_ADDRESS_ARR = varifyDocumentList ;

      const [companyDetails, setCompanyDetails] = useState({
         full_address: '',
         address_unit_no: '',
         address_building_no: '',
         address_street_name: '',
         address_suburbs: '',
         address_state: '',
         address_document_type: '',
      });

      const [subRubSggestions, setSubRubSggestions] = useState([])
      const [inputValue, setInputValue] = useState(companyDetails.address_suburbs);
      // Function to handle user input
      const setSubrubsIteminArray = (value) => { setInputValue(value); if (value.length > 2) { fetchSuggestions(value); } else { setSubRubSggestions([]) } };
      const getSubrubByli = (value) => { setInputValue(value); setSubRubSggestions([]); };
      // Function to fetch suggestions from the API
      const fetchSuggestions = async (value) => {
         try {
            const response = await axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/get_all_subrubs_name/${value}?responsetype=autocomplete`);
            const data = response.data;
            setSubRubSggestions(data);
         } catch (error) { console.error(error); }
      };

      const getCompanyDetailsById = () => {
         const queryParameters = new URLSearchParams(window.location.search)
         const userid = queryParameters.get("id");
         (async () => {
            try {
               const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/get_company_details_by_userid/" + userid);
               if (resData.data.status == 1) {
                  setCompanyDetails(resData.data.data.results);
                  setInputValue(resData.data.data.results.address_suburbs);
               }
            } catch (error) { console.log(error); }
         })();
      }
      const setCompanyDetailsFun = (event) => {
         const { name, value } = event.target;
         setCompanyDetails((prevFormData) => ({
            ...prevFormData,
            [name]: value
         }));
         //formdataValue(name, value)
      };


      useEffect(() => { getCompanyDetailsById() }, []);


      const [validated, setValidated] = useState(false);
      const [fileLoadingContent, setfileLoadingContent] = useState(false);
      const [validatedCnClass, setValidatedCnClass] = useState(false);
      const onCompanyDetailsSubmit = async (event) => {
         event.preventDefault();
         const queryParameters = new URLSearchParams(window.location.search)
         const userid = queryParameters.get("id");
         const form = event.currentTarget;
         if (form.checkValidity() === false) {
            event.preventDefault(); event.stopPropagation();
         } else {
            try {
               setfileLoadingContent(true);
               const formData = new FormData();
               formData.append('address_unit_no', companyDetails.address_unit_no);
               formData.append('address_building_no', companyDetails.address_building_no);
               formData.append('address_street_name', companyDetails.address_street_name);
               formData.append('address_suburbs', inputValue);
               formData.append('address_state', companyDetails.address_state);
               //formData.append('address_document_type', companyDetails.address_document_type);
               formData.append('address_document_type', companyDetails.address_document_type);
               formData.append('user_id', userid);
               const response = await axios.post(
                  `https://squadgoo.com.au/api.squadgoo.com.au/company_address_field_update`,
                  formData, { headers: { 'Content-Type': 'multipart/form-data' } });
               if (response.data.status == 1) {
                  getCompanyDetailsById();
                  getUserDetailsFun(userid);
                  companyOwnerNext(); setfileLoadingContent(false)
               } else { console.log(response.data.message); setfileLoadingContent(false) }
            } catch (error) { console.error('Error uploading file:', error); }
         }
         setValidated(true);
      };


      const [companyAddressDocumentContents, setCompanyAddressDocumentContents] = useState(companyAddressDocument);
      // const [fileLoadingContent, setfileLoadingContent] = useState(false);
      const userFileUploadToServercomapnyFun = async (field, file) => {
         const maxSizeInBytes = 5 * 1024 * 1024; // For example, 1 MB
         if (file.size > maxSizeInBytes) {
            console.log("File size is greater than the specified limit.");
            toast.warning('File size is greater than the specified limit.');
         } else {
            console.log("File size is within the specified limit.");
            const queryParameters = new URLSearchParams(window.location.search)
            const userid = queryParameters.get("id");
            setfileLoadingContent(true);
            try {
               const formData = new FormData();
               formData.append('file', file);
               formData.append('uploaded_for', field);
               formData.append('userid', userid);
               formData.append('maxSizeInBytes', maxSizeInBytes);
               const response = await axios.post(
                  `https://squadgoo.com.au/api.squadgoo.com.au/userdetails_file_uploads`,
                  formData, { headers: { 'Content-Type': 'multipart/form-data' } });
               //console.log('File uploaded successfully:', response.data);
               if (response.data.status == 1) {
                  setCompanyAddressDocumentContents(response.data.data.upload_array); setfileLoadingContent(false);
               } else { toast.warning(response.data.message); setfileLoadingContent(false); }
            } catch (error) { console.error('Error uploading file:', error); }
         }
      };



      /*----------------------------------------------*/

      const profileDocumentChange = (select) => {
         setCompanyDetails((prevData) => ({ ...prevData, 'address_document_type': select.value }))
      }

      /*----------------------------------------------*/
      const [address, setAddress] = useState({
         lat: '',
         lng: '',
         unit: '',
         houseNumber: '',
         streetName: '',
         suburb: '',
         city: '',
         state: '',
         country: '',
         placevalue: ''
      });

      //console.log(userdetails);
      const [googleAddressValue, setGoogleAddress] = useState(userdetails.full_address);
      const googleAuotoCompelte = (newAddress) => {
         setAddress(newAddress);
         setGoogleAddress(`${newAddress.placevalue}`)
      };
      const handleInputChange = (e) => { setGoogleAddress(e.target.value); };

      useEffect(() => {
         console.log(address);
         if (address && address.houseNumber) {
            setCompanyDetails((prevFormData) => ({ ...prevFormData, ['address_building_no']: address.houseNumber }));
         }
         if (address && address.state) {
            const stateObject = get_all_states.find((obj) => obj.state === address.state);
            if (stateObject) {
               setCompanyDetails((prevFormData) => ({ ...prevFormData, ['address_user_state']: stateObject.id }));
            }
         }

         if (address && address.streetName) {
            setCompanyDetails((prevFormData) => ({ ...prevFormData, ['address_street_name']: address.streetName }));
         }

         if (address && address.city) {
            setInputValue(address.city);
         }
      }, [address]);


      return (
         <>
            <div className="stepscont">
               <div className="row">
                  <div className="col-md-12 stepscontright">
                     <Form noValidate validated={validated} onSubmit={onCompanyDetailsSubmit} >
                        <h3 className="step-backbtnwrap d-flex align-items-center" >
                           <span className="stepbackarrow mr-1" >
                              <img src="/front/images/stepbackarrow.svg" onClick={handlePrevious} ></img>
                           </span>Company Address
                        </h3>

                        <div className="row">

                           <div className="col-md-12">
                              <div className="form-group mb-2 mr-2">
                                 <label className="form-label">Full address <span className="starr">*</span></label>
                                 {address && <GoogleAutocomplete
                                    onSelect={googleAuotoCompelte}
                                    required={true}
                                    name='full_address'
                                    value={googleAddressValue}
                                    placeHolder='Enter full address'
                                    className="form-control"
                                    onChange={handleInputChange}
                                 />}
                              </div>
                           </div>

                           <div className="col-md-6">
                              <div className="d-flex">
                                 <div className="form-group mb-2 mr-2">
                                    <label className="form-label">Unit no<span className="starr">*</span></label>
                                    <input name="address_unit_no" required={true} onChange={setCompanyDetailsFun} value={companyDetails.address_unit_no} type="text" className="form-control" placeholder="Unit" />
                                 </div>
                                 <div className="form-group mb-2">
                                    <label className="form-label">Building no<span className="starr">*</span></label>
                                    <input name="address_building_no" required={true} onChange={setCompanyDetailsFun} value={companyDetails.address_building_no} type="text" className="form-control" placeholder="Building no" />
                                 </div>
                              </div>
                           </div>

                           <div className="col-md-6">
                              <div className="form-group mb-2">
                                 <label className="form-label">Street name<span className="starr">*</span></label>
                                 <input name="address_street_name" required={true} onChange={setCompanyDetailsFun} value={companyDetails.address_street_name} type="text" className="form-control" placeholder="Enter Street name" />
                              </div>
                           </div>
                           <div className="col-md-6">
                              <div className="form-group mb-2">
                                 <label className="form-label">Suburb<span className="starr">*</span></label>
                                 <input type="text" className="form-control" required name='address_subrub' value={inputValue} placeholder="Enter your suburb" onChange={(e) => setSubrubsIteminArray(e.target.value)} />
                                 {
                                    subRubSggestions.length ?
                                       <>
                                          <ul className='auto_search_ul'>
                                             {
                                                subRubSggestions.map((suggestion) => (<li onClick={(e) => getSubrubByli(suggestion.name)} key={suggestion.id}>{suggestion.name}</li>))
                                             }
                                          </ul>
                                       </>
                                       :
                                       ''
                                 }
                              </div>
                           </div>

                           <div className="col-md-6">
                              <div className="form-group mb-2">
                                 <label className="form-label">State<span className="starr">*</span></label>
                                 <select className="form-control mr-2" required={true} onChange={setCompanyDetailsFun} value={companyDetails.address_state} name="address_state" >
                                    <option value={''}>Select State</option>
                                    {get_all_states.map((item, index) => (<option value={item.id} >{item.state}</option>))}
                                 </select>
                              </div>
                           </div>

                           <div className="col-md-12">
                              <div className="form-group mb-2">
                                 <label className="form-label">Document for address proof <span className="starr">*</span> <span className="infowrap"><i className="fa fa-info"></i><span className="onhoverinfo">Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum</span></span></label>

                                 {/* <select disabled={(companyDetails.address_document_type != 0 && companyAddressDocumentContents.length > 0) ? true : false} className="form-control" required={true} onChange={setCompanyDetailsFun} value={companyDetails.address_document_proof_type} name="address_document_proof_type" >
                                    <option value={'0'} >Select document</option>
                                    {
                                       varifyDocumentList.map((item, index) => (
                                          <option selected={companyDetails.address_document_type == item.document_id ? true : false} value={item.document_id}>{item.document_type}</option>
                                       ))
                                    }
                                 </select> */}
                                 {/*    isDisabled={true} */}
                                 <Select className='react_select' name="address_document_type" required aria-label="4"
                                    options={varifyDocumentListOption}
                                    isSearchable
                                    isDisabled={(companyDetails.address_document_type != 0 && companyAddressDocumentContents.length > 0) ? true : false}
                                    placeholder="Search..."
                                    styles={customStyles}
                                    onChange={profileDocumentChange}
                                    value={varifyDocumentListOption.find(item => item.value == companyDetails.address_document_type)}
                                 />

                              </div>
                           </div>
                           <div className="col-md-12">
                              <div className="form-group mb-2">
                                 <div className="form-group addressproofdoc mb-3">
                                    <label className="form-label">Upload document for address proof<span className="starr">*</span> </label>
                                    {
                                       <>
                                          {
                                             companyAddressDocumentContents.map((item, index) => (
                                                <>
                                                   <div className="documentwrap d-flex align-items-center mb-2">
                                                      <a className='document_list' target="_blank" href={'https://squadgoo.com.au/api.squadgoo.com.au/' + item.document_url}>
                                                         <p>Address Document {index + 1}</p>
                                                      </a>
                                                      <div className="documentwrapinner d-flex align-items-center">
                                                         {/* <span className='cursor_pointer' onClick={e => viewFileFromServer('identity_document_proof' , item.id) }><img src="/front/images/eye.svg" /></span> */}
                                                         <span className='cursor_pointer ml-2' onClick={e => editFileFromServer('company_address_document', item.id)}><img src="/front/images/edit.png" /></span>
                                                         <span className='cursor_pointer ml-2' onClick={e => removeFileFromServer('company_address_document', item.id)}><img src="/front/images/delete.png" /></span>
                                                      </div>
                                                   </div>
                                                </>
                                             ))
                                          }
                                       </>
                                    }
                                    {
                                       companyAddressDocumentContents.length >= 3 ?
                                          ''
                                          :
                                          <>
                                             <div className="customuploadsec">
                                                {!fileLoadingContent && <input type="file" accept="image/x-png,image/gif,image/jpeg ,application/pdf" onChange={(e) => userFileUploadToServercomapnyFun('company_address_document', e.target.files[0], true)} />}
                                                <div className="customuploadinner text-center">
                                                   <div className="my-4"><img src="/front/images/uploadicon.png" className="mt-4" /></div>
                                                   <p className="mb-0">{fileLoadingContent ? 'Document is uploading......' : 'Click here to upload a file'}</p>
                                                </div>
                                             </div>
                                             <p className="mt-1 purpleclr font12 mb-0">Accept only jpg, jpeg, png and pdf files (Max file size 1 MB)</p>
                                          </>
                                    }

                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="btnsgroup">
                           <div className="text-center">
                              <button disabled={fileLoadingContent} className="batn batn-orange w-100" type="submit">Save and Continue</button>
                           </div>
                           <div className="text-center  mt-2"><button className="btn-link" onClick={companyOwnerNext} type="button" fdprocessedid="9k1hy">Skip this step</button></div>
                        </div>
                     </Form>
                  </div>
               </div>
            </div>

         </>
      )
   }

   /*Contact Details*/
   const Step45 = () => {
      console.log(step)
      console.log('Owner -- contact details Step45');
      //const [contactNumber, setContactNumber] = useState(userdetails.contact_number)
      //const checkContactNumer = (event) => { const value = event.target.value;  const regex = /^[0-9\b]+$/;  setContactNumber('') ; if (value === '' || regex.test(value)) { setContactNumber(value) ; } }
      const [validatedCnClass, setValidatedCnClass] = useState(false);
      const [contactNumber, setContactNumber] = useState(userdetails.contact_number)
      const [contactNumberError, setContactNumberError] = useState('')
      const checkContactNumer = (event) => {
         const value = event.target.value; const regex = /^[0-9\b]+$/; setContactNumber('');
         if (value === '' || regex.test(value)) {
            setContactNumber(value);
            setContactNumberError('');
            if (value.length > 9) {
               //setContactNumberError(value.length)
               (async () => {
                  try {
                     const resData = await axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/mobilecheck/${userdetails.unique_id}/${value}`);
                     if (resData.data.status == 1) {
                        setContactNumber('');
                        setContactNumberError(resData.data.message);
                     }
                  } catch (error) { console.log(error); }
               })();
            }

         }
      }

      const [isChecked, setIsChecked] = useState(userdetails.terms_and_condition);
      const handleCheckboxChange = () => { setIsChecked(!isChecked); };
      const [taxInformationNumber, setTaxInformationNumber] = useState(userdetails.tax_type_value)
      const setTaxTypeFun = (event) => { setTaxInformationNumber(event.target.value); };

      const [validated, setValidated] = useState(false);

      const onContactDetailsSubmit = async (event) => {
         event.preventDefault();
         const queryParameters = new URLSearchParams(window.location.search)
         const userid = queryParameters.get("id");
         const form = event.currentTarget;
         setValidatedCnClass(false);

         if (!event.target.contact_number.value) { setValidatedCnClass(true) }
         if (form.checkValidity() === false) {
            event.preventDefault(); event.stopPropagation();
         } else {
            try {
               const formData = new FormData();
               formData.append('contact_country_code', event.target.contact_country_code.value);
               formData.append('contact_number', contactNumber);
               formData.append('tax_type_value', taxInformationNumber);
               formData.append('tax_type', 'abn');
               formData.append('terms_and_condition', (isChecked ? 1 : 0));
               formData.append('userid', userid);
               const response = await axios.post(`https://squadgoo.com.au/api.squadgoo.com.au/userdetails_data_update`,
                  formData, { headers: { 'Content-Type': 'multipart/form-data' } });
               if (response.data.status == 1) {
                  getUserDetailsFun(userid);
                  companyOwnerNext()
               } else { toast.warning(response.data.message); }
            } catch (error) { console.error('Error uploading file:', error); }
         }
         setValidated(true);
      };



      return (<>
         <div className="stepscont">
            <div className="row">
               <div className="col-md-12 stepscontright">
                  <Form noValidate validated={validated} onSubmit={onContactDetailsSubmit} >
                     <h3 className="step-backbtnwrap d-flex align-items-center" >
                        <span className="stepbackarrow mr-1" >
                           <img src="/front/images/stepbackarrow.svg" onClick={handlePrevious} ></img>
                        </span>Contact details
                     </h3>
                     <div className="row">
                        {/* <div className="col-md-6">
                           <div className="form-group mb-2">
                              <label className="form-label">E-mail <span className="starr">*</span></label>
                              <input disabled={true} type="readonly" className="form-control" placeholder="andrew_enderson@gmail.com" value={userdetails.email} fdprocessedid="25b4b" /></div>
                        </div> */}
                        <div className="col-md-12 ">
                           <div className="form-group mb-2">
                              <label className="form-label">Phone number <span className="starr">*</span></label>
                              <div className={validatedCnClass ? 'phonefield d-flex align-items-center bordercss' : 'phonefield d-flex align-items-center'} >
                                 <select className="form-control" name='contact_country_code' fdprocessedid="9952c">
                                    {
                                       getAllCountry.length ?
                                          <>
                                             {
                                                getAllCountry.map((item, index) => (
                                                   <option selected={userdetails.contact_country_code == item.phonecode ? true : false} value={item.phonecode}>+{item.phonecode}</option>
                                                ))
                                             }
                                          </>
                                          : ''
                                    }
                                 </select>
                                 <input
                                    type="tel"
                                    required={true}
                                    name='contact_number'
                                    className="form-control"
                                    placeholder="Enter mobile number"
                                    maxLength={10}
                                    onChange={checkContactNumer}
                                    value={contactNumber}
                                 />
                              </div>
                              {contactNumberError && <div className='uniquenu_error'>
                                 {contactNumberError}
                              </div>}
                              {
                                 (contactNumber.length > 9) &&
                                 <div className="d-flex justify-content-end">
                                    <PhoneVerificationModel userdetails={userdetails} />
                                 </div>
                              }
                              {/* <div className="d-flex justify-content-end"><a className="linktxt" data-bs-toggle="modal" data-bs-target="#phone-varificationmodle">Verify mobile number</a></div> */}
                           </div>
                        </div>
                        <div className="col-md-12">
                           <h3 className="mb-2">Tax Information</h3>
                        </div>
                        <div className="col-md-12">
                           <div className="form-group mb-2">
                              <label className="form-label">ABN <span className="starr">*</span></label>
                              <input
                                 required={true}
                                 type="text"
                                 name='tax_type_value'
                                 className="form-control"
                                 maxlength={11}
                                 onChange={setTaxTypeFun}
                                 value={taxInformationNumber}
                                 placeholder="Enter ABN number"
                              />
                           </div>
                        </div>
                        <div className="col-md-12 mb-3 mt-2">
                           <div className="form-group mb-2">


                              <div className="form-check">
                                 <input required={true} checked={isChecked} onChange={handleCheckboxChange} name="terms_and_condition" type="checkbox" className="form-check-input" />
                                 <label className="checklabel mr-3 ml-2">I accept
                                    <Link target='_blank' to={`/privacy-policy`} className="linktxt"> Privacy policy</Link> and
                                    <Link target='_blank' to={`/terms-and-conditions`} className="linktxt"> Terms &amp; conditions</Link> agreement.
                                 </label>
                                 <div className="invalid-feedback">You must agree before submitting.</div>
                              </div>

                           </div>
                        </div>

                     </div>
                     <div className="btnsgroup">
                        <div className="text-center">
                           <button className="batn batn-orange w-100" type="submit" >Submit</button>
                        </div>
                     </div>
                  </Form>
               </div>
            </div>
         </div>

      </>)


   }

   /*profile created*/
   const Step46 = () => {
      return (
         <>
            <div className="stepscont">
               <div className="row">
                  <div className="col-md-12 stepscontright">
                     <form className="commonform">
                        <div className="row">
                           <div className="col-md-12 text-center laststepp py-4">
                              <img src="/front/images/checkborder.png" />
                              <p className="mt-4 mb-3">Your profile created successfully!</p>
                              <div>
                                 <Link onClick={redirectToDashboard} className="batn batn-orange">Go to dashboard</Link>
                              </div>
                           </div>
                        </div>
                     </form>
                  </div>
               </div>
            </div>
         </>
      )
   }
   /*************************************** individual detail ******************************/

   const indivisaualNext = () => { setStep((prevStep) => prevStep + 1) };
   const indivisaualNext51 = () => { setStep(51) }

   /*upload profile*/
   const Step5 = () => {


      const removeProfilePic = () => {
         setProfilePicUrl('');
         setProfilePicUrlDefault(true);
         formdataValue('profilepic', 'blank')
      }

      const profileUploadToServer = (e) => {
         const file = e.target.files[0];
         const maxSizeInBytes = 1 * 1024 * 1024; // For example, 1 MB
         if (file.size > maxSizeInBytes) {
            console.log("File size is greater than the specified limit.");
            toast.warning('File size is greater than the specified limit.');
         } else {
            setProfilePicUrl(URL.createObjectURL(e.target.files[0]));
            setProfilePicUrlDefault(false);
            fileUploadToServer('profilepic', e.target.files[0]);
         }
      }

      console.log(profilePicUrl)
      console.log(profilePicUrlDefault)
      console.log('indivisual -- photo step 5')
      return (
         <div>
            <div className="stepscont">
               <div className="row">
                  <div className="col-md-12 stepscontright">
                     <form className="commonform">
                        <h3 className="step-backbtnwrap d-flex align-items-center" >
                           {/* <span className="stepbackarrow mr-1" >
                              <img src="/front/images/stepbackarrow.svg" onClick={go1stStep} ></img>
                           </span>  */}
                           Upload your photo
                        </h3>

                        <div className="text-center form-group mb-3 mt-3">
                           <div className="profilephotodiv">
                              <input className='signuppage_profile' type="file" accept="image/*" onChange={handleCropFileChange} />
                              <img src={profilePicUrl}></img>
                              {
                                 (profilePicUrlDefault) ?
                                    <>
                                       <div className="prfileupload">
                                          <input id="profilepic" type="file" accept="image/*" onChange={handleCropFileChange} />
                                          <span>Upload Photo</span>
                                       </div>
                                    </>
                                    : ''
                              }
                              <div className="dropdown pimgdrop">
                                 <button className=" dropdown-toggle" type="button" id="pimgdropmenu" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="fa fa-pencil" aria-hidden="true"></i>
                                 </button>
                                 {
                                    (!profilePicUrlDefault) ?
                                       <>
                                          <ul className="dropdown-menu" aria-labelledby="pimgdropmenu">
                                             <li>
                                                <a className="dropdown-item updatepro" href="#">Update
                                                   <input id="profilepic" type="file" accept="image/*" onChange={handleCropFileChange} />
                                                </a>
                                             </li>
                                             <li  ><a onClick={(e) => handleShowModal()} className="dropdown-item" href="javascript:void(0)">Capture Image</a></li>
                                             <li  ><a onClick={(e) => removeProfilePic('profilepic')} className="dropdown-item" href="javascript:void(0)">Remove</a></li>
                                          </ul>
                                       </>
                                       :
                                       <>
                                          <ul className="dropdown-menu" aria-labelledby="pimgdropmenu">
                                             <li>
                                                <a className="dropdown-item updatepro" href="#">Upload Photo
                                                   <input id="profilepic" type="file" accept="image/*" onChange={handleCropFileChange} />
                                                </a>
                                             </li>
                                             <li  ><a onClick={(e) => handleShowModal()} className="dropdown-item" href="javascript:void(0)">Capture Image</a></li>
                                          </ul>
                                       </>
                                 }
                                 {/* <ul className="dropdown-menu" aria-labelledby="pimgdropmenu">
                                    <li><a className="dropdown-item updatepro" href="#">Update

                                       <input type="file" accept="image/x-png,image/gif,image/jpeg ,application/pdf"
                                          id="profilepic"
                                          onChange={profileUploadToServer} ></input></a></li>
                                    <li  ><a onClick={(e) => removeProfilePic('profilepic')} className="dropdown-item" href="javascript:void(0)">Remove</a></li>
                                 </ul> */}
                              </div>
                              <div className="editprofileicon">
                              </div>
                           </div>
                        </div>
                        <div className="mb-3">
                           <p className="mb-0"><strong>Logo guidelines:</strong></p>
                           <p className="mb-0">1. Show your whole face and tops of your shoulders,</p>
                           <p className="mb-0">2. Take your sunglasses and hat off,</p>
                           <p className="mb-0">3. No photo of a photo, filters or alterations.</p>
                           <p className="mb-0">4. We only accepts JPG, JPEG  &amp; PNG images with the maximum size 1MB</p>
                        </div>

                     </form>
                  </div>
               </div>
            </div>
            <div className="btnsgroup">
               <div className="text-center">
                  <button className="batn batn-orange w-100" onClick={indivisaualNext51} type="button" fdprocessedid="fpb8r9">Save and Continue</button>
               </div>
               <div className="text-center  mt-2">
                  <button className="btn-link" onClick={indivisaualNext51} type="button" fdprocessedid="9k1hy">Skip this step</button>
               </div>
            </div>
         </div>
      )
   };

   /*Basic details*/
   const Step51 = () => {

      console.log('indivisual -- Basic details step 51');

      const [identityDocumentType, setIdentityDocumentType] = useState([])

      useEffect(() => {
         (async () => {
            try {
               const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/get_all_identity_documenttype");
               if (resData.data.status == 1) { setIdentityDocumentType(resData.data.data.results); }
            } catch (error) { console.log(error); }
         })();
      }, []);


      const [besicDetails, setBasicDetailsData] = useState({
         name_salute: userdetails.name_salute,
         identity_proof_type: userdetails.identity_proof_type,
         first_name: userdetails.first_name,
         middle_name: userdetails.middle_name,
         last_name: userdetails.last_name,
         dob_day: userdetails.dob_day,
         dob_month: userdetails.dob_month,
         dob_year: userdetails.dob_year,
         identity_document_proof_front: userdetails.identity_document_proof_front,
         identity_document_proof_backend: userdetails.identity_document_proof_backend
      });


      const basiDetailsUpdatefun = (event) => {
         const { name, value } = event.target;
         if ((name == 'first_name' || name == 'middle_name' || name == 'last_name') && !alphabeticRegex.test(value)) {
            return null;
         }
         setBasicDetailsData((prevFormData) => ({
            ...prevFormData,
            [name]: value
         }));
      };

      const [validated, setValidated] = useState(false);
      const onBasicDetailsSubmit = async (event) => {
         event.preventDefault();
         const form = event.currentTarget;
         if (form.checkValidity() === false) {
            event.preventDefault(); event.stopPropagation(); console.log('error');
         } else {
            console.log('not error');
            try {
               const queryParameters = new URLSearchParams(window.location.search)
               const userid = queryParameters.get("id");
               const formData = new FormData();
               formData.append('name_salute', event.target.name_salute.value);
               formData.append('first_name', event.target.first_name.value);
               formData.append('middle_name', event.target.middle_name.value);
               formData.append('last_name', event.target.last_name.value);
               formData.append('dob_day', event.target.dob_day.value);
               formData.append('dob_month', event.target.dob_month.value);
               formData.append('dob_year', event.target.dob_year.value);
               formData.append('identity_proof_type', event.target.identity_proof_type.value);
               formData.append('userid', userid);
               formData.append('user_type', 2); /*indivisual recruiter*/
               const response = await axios.post(
                  `https://squadgoo.com.au/api.squadgoo.com.au/userdetails_data_update`,
                  formData, { headers: { 'Content-Type': 'multipart/form-data' } });
               if (response.data.status == 1) {
                  getUserDetailsFun(userid);
                  indivisaualNext()
               } else { toast.warning(response.data.message); }
            } catch (error) { console.error('Error uploading file:', error); }
         }
         setValidated(true);
      };

      const [loadingData, setloadingData] = useState(false);
      const [userUploadIdentityDocumentsContent, setUserUploadIdentityDocumentsContent] = useState(userUploadIdentityDocuments);
      const userFileUploadToServerBasicFun = async (field, file) => {
         const maxSizeInBytes = 5 * 1024 * 1024; // For example, 1 MB
         if (file.size > maxSizeInBytes) {
            console.log("File size is greater than the specified limit.");
            toast.warning('File size is greater than the specified limit.');
         } else {
            console.log("File size is within the specified limit.");
            const queryParameters = new URLSearchParams(window.location.search)
            const userid = queryParameters.get("id");
            setloadingData(true);
            try {
               const formData = new FormData();
               formData.append('file', file);
               formData.append('uploaded_for', field);
               formData.append('userid', userid);
               formData.append('maxSizeInBytes', maxSizeInBytes);
               const response = await axios.post(
                  `https://squadgoo.com.au/api.squadgoo.com.au/userdetails_file_uploads`,
                  formData, { headers: { 'Content-Type': 'multipart/form-data' } });
               //console.log('File uploaded successfully:', response.data);
               setloadingData(false);
               if (response.data.status == 1) {
                  setUserUploadIdentityDocumentsContent(response.data.data.upload_array)
               } else { toast.warning(response.data.message); }
            } catch (error) { console.error('Error uploading file:', error); }
         }
      };

      return (<>
         <div className="stepscont">
            <div className="row">
               <div className="col-md-12 stepscontright">


                  {/* ----------------------- */}
                  <Form noValidate validated={validated} onSubmit={onBasicDetailsSubmit}>
                     <div className="row">
                        <div className="col-md-12 stepscontright">

                           <h3 className="step-backbtnwrap d-flex align-items-center" >
                              <span className="stepbackarrow mr-1" >
                                 <img src="/front/images/stepbackarrow.svg" onClick={go5thStep} ></img>
                              </span>Basic details
                           </h3>
                           <div className="row">
                              <div className="col-md-6">
                                 <div className="d-flex">
                                    <div className="form-group mb-2 mr-2">
                                       <label className="form-label">Salute<span className="starr">*</span></label>
                                       <select name="name_salute" className="form-control" onChange={basiDetailsUpdatefun} >
                                          {
                                             ABBREVIATIONS.map((item) => (
                                                <option selected={(besicDetails.name_salute == item.value) ? true : false} key={item.id} value={item.value}>{item.value}</option>
                                             ))
                                          }
                                       </select>
                                    </div>
                                    <div className="form-group mb-2">
                                       <label className="form-label">First name<span className="starr">*</span></label>
                                       <input type="text" className="form-control" name="first_name" required value={besicDetails.first_name} onChange={basiDetailsUpdatefun} placeholder="Andrew" />
                                    </div>
                                 </div>
                              </div>
                              <div className="col-md-6">
                                 <div className="form-group mb-2">
                                    <label className="form-label">Middle name</label>
                                    <input type="text" className="form-control" name="middle_name" onChange={basiDetailsUpdatefun} value={besicDetails.middle_name} placeholder="Enter middle name" />
                                 </div>
                              </div>
                              <div className="col-md-6">
                                 <div className="form-group mb-2">
                                    <label className="form-label">Last name<span className="starr">*</span></label>
                                    <input type="text" className="form-control" name="last_name" required value={besicDetails.last_name} onChange={basiDetailsUpdatefun} placeholder="Enderson" />
                                 </div>
                              </div>
                              <div className="col-md-6">

                                 <div className="form-group mb-2">
                                    <label className="form-label">Date of birth<span className="starr">*</span></label>
                                    <div className="d-flex datefildgruop">
                                       <select name="dob_day" required className="form-control mr-2 datefild" onChange={basiDetailsUpdatefun}>
                                          {<DayNumberLoop select_attr={besicDetails.dob_day} />}
                                       </select>
                                       <select name="dob_month" required className="form-control mr-2 monthfild" onChange={basiDetailsUpdatefun}>
                                          {<MonthsHtml select_attr={besicDetails.dob_month} />}
                                       </select>
                                       <select name="dob_year" required className="form-control yearfild" onChange={basiDetailsUpdatefun} >
                                          {<YearNumberLoop select_attr={besicDetails.dob_year} />}
                                       </select>
                                    </div>
                                 </div>
                              </div>
                              <div className="col-md-12">

                                 <div className="form-group mb-2">
                                    <label className="form-label">Document for identity proof <span className="starr">*</span> <span className="infowrap"><i className="fa fa-info"></i><span className="onhoverinfo">Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum</span></span></label>
                                    <select disabled={(besicDetails.identity_proof_type && userUploadIdentityDocumentsContent.length > 0) ? true : false} className="form-control" required={true} onChange={basiDetailsUpdatefun} name="identity_proof_type" fdprocessedid="m7dnpr">
                                       <option value={''}>Select document</option>
                                       {
                                          identityDocumentType.map((item, index) => (
                                             <option selected={(besicDetails.identity_proof_type == item.id) ? true : false} value={item.id}>{item.name}</option>
                                          ))
                                       }

                                    </select>
                                 </div>
                              </div>

                              <div className="col-md-12 mb-1">
                                 <div className="form-group mb-2">
                                    <label className="form-label">{userUploadIdentityDocumentsContent.length > 1 ? 'Uploaded identity proof' : userUploadIdentityDocumentsContent.length > 0 ? 'Upload your identity proof back side' : 'Upload your identity proof front side'}<span className="starr">*</span> </label>
                                    {

                                       <>
                                          {
                                             userUploadIdentityDocumentsContent.map((item, index) => (
                                                <>
                                                   <div className="documentwrap d-flex align-items-center mb-2">
                                                      <a className='document_list' target="_blank" href={'https://squadgoo.com.au/api.squadgoo.com.au/' + item.document_url}>
                                                         <p>Identity Document {index + 1}</p>
                                                      </a>
                                                      <div className="documentwrapinner d-flex align-items-center">
                                                         {/* <span className='cursor_pointer' onClick={e => viewFileFromServer('identity_document_proof' , item.id) }><img src="/front/images/eye.svg" /></span> */}
                                                         <span className='cursor_pointer ml-2' onClick={e => editFileFromServer('identity_document_proof', item.id)}><img src="/front/images/edit.png" /></span>
                                                         <span className='cursor_pointer ml-2' onClick={e => removeFileFromServer('identity_document_proof', item.id)}><img src="/front/images/delete.png" /></span>
                                                      </div>
                                                   </div>
                                                </>
                                             ))
                                          }
                                       </>

                                    }
                                    {
                                       userUploadIdentityDocumentsContent.length >= 2 ?
                                          ''
                                          :
                                          <>
                                             <div className="form-group identityproof">
                                                <div className="customuploadsec">
                                                   {!loadingData && <input type="file" accept="image/x-png,image/gif,image/jpeg ,application/pdf" onChange={(e) => userFileUploadToServerBasicFun('identity_document_proof', e.target.files[0], true)}></input>}
                                                   <div className="customuploadinner text-center">
                                                      <div className="my-4"><img src="/front/images/uploadicon.png" className="mt-4"></img></div>
                                                      <p className="mb-0">{loadingData ? 'Document is uploading...' : 'Click here to upload a file'}</p>
                                                   </div>
                                                </div>
                                                <p className="mt-1 purpleclr font12 mb-0">Accept only jpg, jpeg, png and pdf files (Max file size 1 MB)</p>
                                             </div>
                                          </>
                                    }
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="text-center">
                        <Button disabled={loadingData} className="batn batn-orange w-100" type="submit" >Save and Continue</Button>
                     </div>
                     <div className="text-center  mt-2">
                        <button disabled={loadingData} className="btn-link" type="button" onClick={indivisaualNext}>Skip this step</button>
                     </div>
                  </Form>
                  {/* ----------------------- */}




               </div>
            </div>
         </div>

      </>);
   };

   /*Address details*/
   const Step52 = () => {
      console.log('indivisual -- Address details step 52');

      console.log(varifyDocumentListOption);

      const get_all_states_string = localStorage.getItem('get_all_states');
      const get_all_states = JSON.parse(get_all_states_string);
      //const signup_userdetails  =  getSignupUserData();
      const [subRubSggestions, setSubRubSggestions] = useState([])
      const [inputValue, setInputValue] = useState(userdetails.address_suburbs);
      // Function to handle user input
      const setSubrubsIteminArray = (value) => { setInputValue(value); if (value.length > 2) { fetchSuggestions(value); } else { setSubRubSggestions([]) } };
      const getSubrubByli = (value) => { setInputValue(value); setSubRubSggestions([]); };
      // Function to fetch suggestions from the API
      const fetchSuggestions = async (value) => {
         try {
            const response = await axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/get_all_subrubs_name/${value}?responsetype=autocomplete`);
            const data = response.data;
            setSubRubSggestions(data);
         } catch (error) { console.error(error); }
      };
      /*****************"Form edit "*******************/
      const [addressDetails, setAddressDetails] = useState({
         full_address: userdetails.full_address,
         address_unit_no: userdetails.address_unit_no,
         address_building_no: userdetails.address_building_no,
         address_street_name: userdetails.address_street_name,
         address_suburbs: userdetails.address_suburbs,
         address_user_state: userdetails.address_user_state,
         address_document_proof_type: userdetails.address_document_proof_type,
      });

      //setInputValue(userdetails.address_suburbs);
      const addressDetailsUpdateFun = (event) => {
         const { name, value } = event.target;
         setAddressDetails((prevFormData) => ({
            ...prevFormData,
            [name]: value
         }));
         //formdataValue(name, value)
      };


      const [validated, setValidated] = useState(false);
      const onAddressDetailsSubmit = async (event) => {
         event.preventDefault();
         const queryParameters = new URLSearchParams(window.location.search)
         const userid = queryParameters.get("id");
         const form = event.currentTarget;
         if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
         } else {
            try {
               const formData = new FormData();
               formData.append('full_address', event.target.full_address.value);
               formData.append('address_unit_no', event.target.address_unit_no.value);
               formData.append('address_building_no', event.target.address_building_no.value);
               formData.append('address_street_name', event.target.address_street_name.value);
               formData.append('address_suburbs', event.target.address_subrub.value);
               formData.append('address_state', event.target.address_user_state.value);
               formData.append('address_document_type', addressDetails.address_document_proof_type);

               //addressDetails.address_document_proof_type
               formData.append('user_id', userid);
               const response = await axios.post(
                  `https://squadgoo.com.au/api.squadgoo.com.au/company_address_field_update`,
                  formData, { headers: { 'Content-Type': 'multipart/form-data' } });
               if (response.data.status == 1) {
                  //toast.success('Removed Successfully!');
                  getUserDetailsFun(userid);
                  handleNext()
               } else { toast.warning(response.data.message); }
            } catch (error) { console.error('Error uploading file:', error); }
            //handleNext()
         }
         setValidated(true);
      };


      const [loadingData, setloadingData] = useState(false);
      const [userUploadAddressDocumentsContents, setUserUploadAddressDocumentsContents] = useState(userUploadAddressDocuments);
      const userFileUploadToServerAddressFun = async (field, file) => {
         const maxSizeInBytes = 5 * 1024 * 1024; // For example, 1 MB
         if (file.size > maxSizeInBytes) {
            console.log("File size is greater than the specified limit.");
            toast.warning('File size is greater than the specified limit.');
         } else {
            console.log("File size is within the specified limit.");
            const queryParameters = new URLSearchParams(window.location.search)
            const userid = queryParameters.get("id");
            setloadingData(true)
            try {
               const formData = new FormData();
               formData.append('file', file);
               formData.append('uploaded_for', field);
               formData.append('userid', userid);
               formData.append('maxSizeInBytes', maxSizeInBytes);
               const response = await axios.post(
                  `https://squadgoo.com.au/api.squadgoo.com.au/userdetails_file_uploads`,
                  formData, { headers: { 'Content-Type': 'multipart/form-data' } });
               //console.log('File uploaded successfully:', response.data);
               if (response.data.status == 1) {
                  setUserUploadAddressDocumentsContents(response.data.data.upload_array)
               } else { toast.warning(response.data.message); }
               setloadingData(false)
            } catch (error) { console.error('Error uploading file:', error); }
         }
      };

      //const DOCUENT_ADDRESS_ARR = ['License' , 'Passport' , 'Electricity Bill' , 'Pension card'] ;

      /*----------------------------------------------*/

      const profileDocumentChange = (select) => {
         setAddressDetails((prevData) => ({ ...prevData, 'address_document_proof_type': select.value }))
      }

      /*----------------------------------------------*/
      const [address, setAddress] = useState({
         lat: '',
         lng: '',
         unit: '',
         houseNumber: '',
         streetName: '',
         suburb: '',
         city: '',
         state: '',
         country: '',
         placevalue: ''
      });

      //console.log(userdetails);
      const [googleAddressValue, setGoogleAddress] = useState(userdetails.full_address);
      const googleAuotoCompelte = (newAddress) => {
         setAddress(newAddress);
         setGoogleAddress(`${newAddress.placevalue}`)
      };
      const handleInputChange = (e) => { setGoogleAddress(e.target.value); };

      useEffect(() => {
         console.log(address);
         if (address && address.houseNumber) {
            setAddressDetails((prevFormData) => ({ ...prevFormData, ['address_building_no']: address.houseNumber }));
         }
         if (address && address.state) {
            const stateObject = get_all_states.find((obj) => obj.state === address.state);
            if (stateObject) {
               setAddressDetails((prevFormData) => ({ ...prevFormData, ['address_user_state']: stateObject.id }));
            }
         }

         if (address && address.streetName) {
            setAddressDetails((prevFormData) => ({ ...prevFormData, ['address_street_name']: address.streetName }));
         }

         if (address && address.city) {
            setInputValue(address.city);
         }
      }, [address]);

      return (<>
         <div className="stepscont">
            <div className="row">
               <div className="col-md-12 stepscontright">

                  <Form noValidate validated={validated} onSubmit={onAddressDetailsSubmit} >
                     <div className="stepscont">
                        <div className="row">
                           <div className="col-md-12 stepscontright">
                              <h3 className="step-backbtnwrap d-flex align-items-center">
                                 <span ><img src="/front/images/stepbackarrow.svg" onClick={handlePrevious}></img></span>
                                 Provide your address
                              </h3>
                              <div className="row">

                                 <div className="col-md-12">
                                    <div className="form-group mb-2 mr-2">
                                       <label className="form-label">Full address <span className="starr">*</span></label>
                                       {address && <GoogleAutocomplete
                                          onSelect={googleAuotoCompelte}
                                          required={true}
                                          name='full_address'
                                          value={googleAddressValue}
                                          placeHolder='Enter full address'
                                          className="form-control"
                                          onChange={handleInputChange}
                                       />}
                                    </div>
                                 </div>

                                 <div className="col-md-6">
                                    <div className="d-flex">
                                       <div className="form-group mb-2 mr-2">
                                          <label className="form-label">Unit no</label>
                                          <input name="address_unit_no" value={addressDetails.address_unit_no} onChange={addressDetailsUpdateFun} type="text" className="form-control" placeholder="Unit no" />
                                       </div>
                                       <div className="form-group mb-2">
                                          <label className="form-label">House number<span className="starr">*</span></label>
                                          <input name="address_building_no" value={addressDetails.address_building_no} required onChange={addressDetailsUpdateFun} type="text" className="form-control" placeholder="Building no" />
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-6">
                                    <div className="form-group mb-2">
                                       <label className="form-label">Street name<span className="starr">*</span></label>
                                       <input name="address_street_name" value={addressDetails.address_street_name} required onChange={addressDetailsUpdateFun} type="text" className="form-control" placeholder="Enter Street name" />
                                    </div>
                                 </div>
                                 <div className="col-md-6">
                                    <div className="form-group mb-2">
                                       <label className="form-label">Suburb<span className="starr">*</span></label>
                                       <input type="text" className="form-control" required name='address_subrub' value={inputValue} placeholder="Enter your suburb" onChange={(e) => setSubrubsIteminArray(e.target.value)} />
                                       {
                                          subRubSggestions.length ?
                                             <>
                                                <ul className='auto_search_ul'>
                                                   {
                                                      subRubSggestions.map((suggestion) => (<li onClick={(e) => getSubrubByli(suggestion.name)} key={suggestion.id}>{suggestion.name}</li>))
                                                   }
                                                </ul>
                                             </>
                                             :
                                             ''
                                       }
                                    </div>
                                 </div>
                                 <div className="col-md-6">
                                    <div className="form-group mb-2">
                                       <label className="form-label">State<span className="starr">*</span></label>
                                       <select className="form-control mr-2" required name="address_user_state" onChange={addressDetailsUpdateFun}>
                                          <option value="">Select state</option>
                                          {get_all_states.map((item, index) => (<option selected={addressDetails.address_user_state == item.id ? true : false} value={item.id} >{item.state}</option>))}
                                       </select>
                                    </div>
                                 </div>
                                 <div className="col-md-12">
                                    <div className="form-group mb-2">
                                       <label className="form-label">Document for address proof <span className="infowrap"><i className="fa fa-info"></i><span className="onhoverinfo">Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum</span></span></label>


                                       <Select className='react_select' name="address_document_proof_type" required aria-label="4"
                                          options={varifyDocumentListOption}
                                          isSearchable
                                          placeholder="Search..."
                                          styles={customStyles}
                                          onChange={profileDocumentChange}
                                          value={varifyDocumentListOption.find(item => item.value == addressDetails.address_document_proof_type)}
                                       />
                                       {/* 
                                       profileDocumentChange address_document_proof_type
                                       <select className="form-control" disabled={addressDetails.address_document_proof_type && userUploadAddressDocumentsContents.length > 0 ? true : false} name="address_document_proof_type" onChange={addressDetailsUpdateFun} >
                                          <option value='' >Select document</option>
                                          {
                                             varifyDocumentList.map((item, index) => (
                                                <option selected={addressDetails.address_document_proof_type == item.document_id ? true : false} value={item.document_type}>{item.document_type}</option>
                                             ))
                                          }
                                       </select> */}
                                    </div>
                                 </div>
                                 <div className="col-md-12">
                                    <div className="form-group mb-2">
                                       <div className="form-group addressproofdoc mb-3">
                                          <label className="form-label">
                                             {
                                                userUploadAddressDocumentsContents.length > 1
                                                   ? 'Uploaded document'
                                                   : userUploadAddressDocumentsContents.length > 0 ? 'Upload address document second' : 'Upload address document first'
                                             }
                                          </label>
                                          {
                                             <>
                                                {
                                                   userUploadAddressDocumentsContents.map((item, index) => (
                                                      <>
                                                         <div className="documentwrap d-flex align-items-center mb-2">
                                                            <a className='document_list' target="_blank" href={'https://squadgoo.com.au/api.squadgoo.com.au/' + item.document_url}>
                                                               <p>Address Proof Document {index + 1}</p>
                                                            </a>
                                                            <div className="documentwrapinner d-flex align-items-center">
                                                               <span className='cursor_pointer ml-2' onClick={e => editFileFromServer('address_document_proof', item.id)}><img src="/front/images/edit.png" /></span>
                                                               <span className='cursor_pointer ml-2' onClick={e => removeFileFromServer('address_document_proof', item.id)}><img src="/front/images/delete.png" /></span>
                                                            </div>
                                                         </div>
                                                      </>
                                                   ))
                                                }
                                             </>
                                          }
                                          {
                                             userUploadAddressDocumentsContents.length >= 2 ?
                                                ''
                                                :
                                                <>
                                                   <div className="form-group identityproof">
                                                      <div className="customuploadsec">
                                                         {!loadingData && <input type="file" accept="image/x-png,image/gif,image/jpeg ,application/pdf" onChange={(e) => userFileUploadToServerAddressFun('address_document_proof', e.target.files[0])}></input>}
                                                         <div className="customuploadinner text-center">
                                                            <div className="my-4"><img src="/front/images/uploadicon.png" className="mt-4"></img></div>
                                                            <p className="mb-0">{loadingData ? 'Document is uploading....' : 'Click here to upload a file'}</p>
                                                         </div>
                                                      </div>
                                                      <p className="mt-1 purpleclr font12 mb-0">Accept only jpg, jpeg, png and pdf files (Max file size 1 MB)</p>
                                                   </div>
                                                </>
                                          }
                                       </div>
                                    </div>
                                 </div>
                                 <div className="btnsgroup">
                                    <div className="text-center">
                                       <button disabled={loadingData} className="batn batn-orange w-100" type="submit" >Save and Continue</button>
                                    </div>
                                    <div className="text-center  mt-2">
                                       <button disabled={loadingData} className="btn-link" type="button" onClick={indivisaualNext}>Skip this step</button>
                                    </div>
                                 </div>

                              </div>
                           </div>
                        </div>
                     </div>
                  </Form>

               </div>
            </div>
         </div>

      </>)
   };

   /*Contact Details*/
   const Step53 = () => {
      console.log('indivisual -- Contact details step 53');
      const [validatedCnClass, setValidatedCnClass] = useState(false);
      const [contactNumber, setContactNumber] = useState(userdetails.contact_number)
      const [contactNumberError, setContactNumberError] = useState('')
      const checkContactNumer = (event) => {
         const value = event.target.value; const regex = /^[0-9\b]+$/; setContactNumber('');
         setContactNumberError('');
         if (value === '' || regex.test(value)) {
            setContactNumber(value);
            if (value.length > 9) {
               //setContactNumberError(value.length)
               (async () => {
                  try {
                     const resData = await axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/mobilecheck/${userdetails.unique_id}/${value}`);
                     if (resData.data.status == 1) {
                        setContactNumber('');
                        setContactNumberError(resData.data.message);
                     }
                  } catch (error) { console.log(error); }
               })();
            }

         }
      }

      const [isChecked, setIsChecked] = useState(userdetails.terms_and_condition);
      const handleCheckboxChange = () => { setIsChecked(!isChecked); };
      const [taxInformationNumber, setTaxInformationNumber] = useState(userdetails.tax_type_value)
      const setTaxTypeFun = (event) => { setTaxInformationNumber(event.target.value); };

      const [validated, setValidated] = useState(false);
      //const [validatedCnClass, setValidatedCnClass] = useState(false);
      const [taxTypeClass, setTaxTypeClass] = useState(false);
      const onContactDetailsSubmit = async (event) => {
         event.preventDefault();
         const queryParameters = new URLSearchParams(window.location.search)
         const userid = queryParameters.get("id");
         const form = event.currentTarget;
         setValidatedCnClass(false);
         if (!event.target.contact_number.value) { setValidatedCnClass(true) }
         if (form.checkValidity() === false) {
            event.preventDefault(); event.stopPropagation();
         } else if (taxInformationNumber.length != 11) {
            setTaxTypeClass(true);
         } else {
            try {
               const formData = new FormData();
               formData.append('contact_country_code', event.target.contact_country_code.value);
               formData.append('contact_number', contactNumber);
               formData.append('tax_type_value', taxInformationNumber);
               formData.append('tax_type', 'abn');
               formData.append('terms_and_condition', (isChecked ? 1 : 0));
               formData.append('userid', userid);
               const response = await axios.post(`https://squadgoo.com.au/api.squadgoo.com.au/userdetails_data_update`,
                  formData, { headers: { 'Content-Type': 'multipart/form-data' } });
               if (response.data.status == 1) {
                  getUserDetailsFun(userid);
                  indivisaualNext()
               } else { toast.warning(response.data.message); }
            } catch (error) { console.error('Error uploading file:', error); }
         }
         setValidated(true);
      };



      return (<>
         <div className="stepscont">
            <div className="row">
               <div className="col-md-12 stepscontright">
                  <Form noValidate validated={validated} onSubmit={onContactDetailsSubmit} >
                     <h3 className="step-backbtnwrap d-flex align-items-center" >
                        <span className="stepbackarrow mr-1" >
                           <img src="/front/images/stepbackarrow.svg" onClick={handlePrevious} ></img>
                        </span>Contact details
                     </h3>
                     <div className="row">
                        {/* <div className="col-md-6">
                           <div className="form-group mb-2">
                              <label className="form-label">E-mail <span className="starr">*</span></label>
                              <input disabled={true} type="readonly" className="form-control" placeholder="andrew_enderson@gmail.com" value={userdetails.email} fdprocessedid="25b4b" /></div>
                        </div> */}
                        <div className="col-md-12 ">
                           <div className="form-group mb-2">
                              <label className="form-label">Phone number <span className="starr">*</span></label>
                              <div className={validatedCnClass ? 'phonefield d-flex align-items-center bordercss' : 'phonefield d-flex align-items-center'} >
                                 <select className="form-control" name='contact_country_code' >
                                    {
                                       getAllCountry.length ?
                                          <>
                                             {
                                                getAllCountry.map((item, index) => (
                                                   <option selected={userdetails.contact_country_code == item.phonecode ? true : false} value={item.phonecode}>+{item.phonecode}</option>
                                                ))
                                             }
                                          </>
                                          : ''
                                    }
                                 </select>
                                 <input
                                    type="tel"
                                    required={true}
                                    name='contact_number'
                                    className="form-control"
                                    placeholder="Enter mobile number"
                                    maxLength={10}
                                    onChange={checkContactNumer}
                                    value={contactNumber}
                                 />
                              </div>
                              {contactNumberError && <div className='uniquenu_error'>
                                 {contactNumberError}
                              </div>}
                              {
                                 (contactNumber.length > 9) &&
                                 <div className="d-flex justify-content-end">
                                    <PhoneVerificationModel userdetails={userdetails} />
                                 </div>
                              }
                              {/* <div className="d-flex justify-content-end"><a className="linktxt" data-bs-toggle="modal" data-bs-target="#phone-varificationmodle">Verify mobile number</a></div> */}
                           </div>
                        </div>
                        <div className="col-md-12">
                           <h3 className="mb-2">Tax Information</h3>
                        </div>
                        <div className="col-md-12">
                           <div className="form-group mb-2">
                              <label className="form-label">ABN</label>
                              <input
                                 type="text"
                                 name='tax_type_value'
                                 className={`form-control ${taxTypeClass ? 'bordercss' : ''}`}
                                 maxlength={11}
                                 onChange={setTaxTypeFun}
                                 value={taxInformationNumber}
                                 placeholder="Enter ABN number"
                              />
                           </div>
                        </div>
                        <div className="col-md-12 mb-3 mt-2">
                           <div className="form-group mb-2">


                              <div className="form-check">
                                 <input required={true} checked={isChecked} onChange={handleCheckboxChange} name="terms_and_condition" type="checkbox" className="form-check-input" />
                                 <label className="checklabel mr-3 ml-2">I accept
                                    <Link target='_blank' to={`/privacy-policy`} className="linktxt"> Privacy policy</Link> and
                                    <Link target='_blank' to={`/terms-and-conditions`} className="linktxt"> Terms &amp; conditions</Link> agreement.
                                 </label>
                                 <div className="invalid-feedback">You must agree before submitting.</div>
                              </div>

                           </div>
                        </div>

                     </div>
                     <div className="btnsgroup">
                        <div className="text-center">
                           <button className="batn batn-orange w-100" type="submit" >Save and Continue</button>
                        </div>
                        <div className="text-center  mt-2"><button onClick={indivisaualNext} className="btn-link" type="button" fdprocessedid="9k1hy">Skip this step</button></div>
                     </div>
                  </Form>
               </div>
            </div>
         </div>

      </>)
   };

   const Step54 = () => {
      return (<>
         <div className="stepscont">
            <div className="row">
               <div className="col-md-12 stepscontright">
                  <form className="commonform">
                     <div className="row">
                        <div className="col-md-12 text-center laststepp py-4">
                           <img src="/front/images/checkborder.png" />
                           <p className="mt-4 mb-3">Your profile created successfully!</p>
                           <div onClick={redirectToDashboard} className="batn batn-orange">Go to dashboard</div>
                        </div>
                     </div>
                  </form>
               </div>
            </div>
         </div>
      </>)
   }

   return (
      <div className="accountsetupwrap dashboarwrap">
         {/* <ToastContainer />*/}
         <div className="accountsetupinner">
            <div className='first'></div>

            {step === 1 && (<div><Step1 stepLabel="Basic Details" /></div>)}
            {step === 2 && (<div><Step2 stepLabel="Basic Details" /></div>)}

            {/* Reprasentative steps */}
            {step === 3 && (<div><Step3 /></div>)}
            {step === 31 && (<div><Step31 /></div>)}
            {step === 32 && (<div><Step32 /></div>)}
            {step === 33 && (<div><Step33 /></div>)}
            {step === 34 && (<div><Step34 /></div>)}
            {step === 35 && (<div><Step35 /></div>)}
            {step === 36 && (<div><Step36 /></div>)}
            {/* {step === 37 && (<div><Step37 /></div>)} */}


            {/* company owner steps */}
            {step === 4 && (<div><Step4 /></div>)}
            {step === 41 && (<div><Step41 /></div>)}
            {step === 42 && (<div><Step42 /></div>)}
            {step === 43 && (<div><Step43 /></div>)}
            {step === 44 && (<div><Step44 /></div>)}
            {step === 45 && (<div><Step45 /></div>)}
            {step === 46 && (<div><Step46 /></div>)}

            {/* indivisaual show */}
            {step === 5 && (<div> <Step5 /></div>)}
            {step === 51 && (<div> <Step51 /></div>)}
            {step === 52 && (<div> <Step52 /></div>)}
            {step === 53 && (<div> <Step53 /></div>)}
            {step === 54 && (<div> <Step54 /></div>)}


         </div>



         <div className="modal fade modalmedium phone-varificationmodle" id="phone-varificationmodle" tabIndex="-1" aria-labelledby="phone-varificationmodleLabel" aria-hidden="true">
            <div className="modal-dialog">
               <div className="modal-content">
                  <div className="modal-body loginsignupwrapformwrap">
                     <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                     <Form className="commonform lofinsignupform loginform">
                        <div className="formbranding text-center"><img src="/front/images/formlogo.png" /></div>
                        <div className="text-center my-4"><img src="/front/images/varifyicon.png" /></div>
                        <h2 className="text-center mb-2">Verify your phone number</h2>
                        <div className="text-center formbtmtxt mb-4"><p>We have sent a code on your Phone number</p></div>
                        <div className="form-group mb-2">
                           <input required type="text" className="form-control varificationfield" placeholder="Enter the code sent on your phone number"
                           />
                        </div>
                        <div className="form-group mb-2"><NavLink className="">Re-send verification code</NavLink></div>
                        <div className="form-group mb-2"><input type="button" className="batn batn-orange" value="Verify" data-bs-toggle="modal" data-bs-target="#phonvarifiedpop" data-bs-dismiss="modal" /></div>
                     </Form>
                  </div>
               </div>
            </div>
         </div>


         <div className="modal fade modalmedium phonvarifiedpop" id="phonvarifiedpop" tabIndex="-1" aria-labelledby="phonvarifiedpopLabel" aria-hidden="true">
            <div className="modal-dialog">
               <div className="modal-content">
                  <div className="modal-body loginsignupwrapformwrap">
                     <form className="commonform lofinsignupform loginform">
                        <div className="text-center my-5"><img src="/front/images/varified.png" /></div>
                        <h2 className="text-center mb-2">Congratulation!!</h2>
                        <div className="text-center formbtmtxt mb-4">
                           <p>Your phone number has been verified successfully</p>
                        </div>
                        <div className="form-group mb-2"><input type="Submit" className="batn batn-orange" value="Continue with your profile" /></div>
                     </form>
                  </div>
               </div>
            </div>
         </div>

         <Modal show={showModal} onHide={handleHideModal}>
            <div className="modal-dialog1 findastaffmodle">
               <div className="modal-content">
                  <div className="modal-body">
                     <button type="button" className="btn-close" onClick={handleHideModal} data-bs-dismiss="modal" aria-label="Close"></button>
                     {
                        capturedImage ?
                           <>
                              <img src={capturedImage} alt="Captured" className='captured_image' />
                              <div className="form-group mb-2  text-center mt-2">

                                 {
                                    takePicFromCam ?
                                       <>
                                          <button className='batn batn-orange mr-2  text-capitalize' onClick={startCamera}>Re-Capture</button>
                                          <button className='batn batn-blue  mr-2 ' onClick={uploadImage}>Set as Profile</button>
                                       </>
                                       :
                                       <>
                                          <button className='batn batn-orange mr-2  text-capitalize' onClick={startCamera}>Start Camera</button>
                                       </>
                                 }
                              </div>
                           </>
                           :
                           <>
                              <video className='captured_image' ref={videoRef} width="400" height="400" autoPlay />
                              <div className="form-group mb-2 text-center mt-2">
                                 <button className='batn batn-orange mr-1 ml-1  text-capitalize' onClick={captureImage}>Take a picture</button>
                                 <button className='batn batn-blue  text-capitalize  mr-1 ml-1 ' onClick={stopCamera}>Stop Camara</button>
                              </div>
                           </>
                     }
                  </div>
               </div>
            </div>
         </Modal>
         <Modal show={showModalCrop} onHide={() => setShowModalCrop(false)}>
            <Modal.Body>
               <button onClick={() => setShowModalCrop(false)} type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               <div style={{ position: 'relative', width: '100%', height: '300px' }}>
                  <Cropper
                     image={imageSrc}
                     crop={crop}
                     zoom={zoom}
                     aspect={1} // Makes the crop area square
                     onCropChange={setCrop}
                     onZoomChange={setZoom}
                     onCropComplete={onCropComplete}
                     cropShape="round" // Set the cropping area to a circle
                  />
               </div>
               <input
                  type="range"
                  min="1"
                  max="3"
                  step="0.1"
                  value={zoom}
                  className='width-100percent mt-2'
                  onChange={(e) => setZoom(Number(e.target.value))}
               />
            </Modal.Body>
            <div className='form-group mb-2  text-center mt-2'>

               <Button className='batn batn-orange mr-2  text-capitalize' variant="secondary" onClick={() => setShowModalCrop(false)}>
                  Cancel
               </Button>
               <Button className='batn batn-orange mr-2  text-capitalize' variant="primary" onClick={handleSave}>
                  Save
               </Button>
            </div>
         </Modal>


      </div>
   );
}
export default RecruiterAccountSetting;