import React, { useState, useEffect, Component } from 'react';
import axios from 'axios';
import 'react-datepicker/dist/react-datepicker.css';
import { Link } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';

const DocumentSample = () => {
  


   return (
      <>
       
         <div className="dashboarwrap">
            <div className="pagebanner">
               <div className="container">
                  <div className="row">
                     <div className="col-md-12">
                        <h2>Document Sample</h2>
                     </div>
                  </div>
               </div>
            </div>
            <div className="jobbtmsec py-5">
               <div className="container">
                  <div className="row">
                  <div className="col-md-12 mb-4">
                     <div className="card-box documentsamplebox">
                        <div className="row align-items-center">
                           <div className="col-md-12">
                           <h3 className="mb-4 text-center">Identity Proof</h3>
                           </div>
                            <div className="col-md-3">
                               <img className="w-100" src="/front/images/idfront.png"/> 
                            </div>
                            <div className="col-md-3">
                               <img className="w-100" src="/front/images/idback.png"/>
                            </div>
                            <div className="col-md-6">
                               <div className="trust-right documentcontlist">
                                   
                                    <p className="d-flex"><img src="/front/images/rightIcon.svg"/><span>Phosfluorescently predominate leveraged architectur and vertical catalysts for Energ tically conceptualize standardized.</span></p>
                                    <p className="d-flex"><img src="/front/images/rightIcon.svg"/><span>Phosfluorescently predominate leveraged architectur and vertical catalysts for Energ tically conceptualize standardized.</span></p>
                                    <p className="d-flex"><img src="/front/images/rightIcon.svg"/><span>Phosfluorescently predominate leveraged architectur and vertical catalysts for Energ tically conceptualize standardized.</span></p>
                                    <p className="d-flex"><img src="/front/images/rightIcon.svg"/><span>Phosfluorescently predominate leveraged architectur and vertical catalysts for Energ tically conceptualize standardized.</span></p>
                                  
                                 </div>
                                

                            </div>
                        </div>
                        
                        
                        
                     </div>
                  </div>

                  <div className="col-md-12 mb-4">
                     <div className="card-box documentsamplebox">
                        <div className="row align-items-center">
                           <div className="col-md-12">
                           <h3 className="mb-4 text-center">Identity Proof</h3>
                           </div>
                            <div className="col-md-3">
                               <img className="w-100" src="/front/images/idfront.png"/> 
                            </div>
                            <div className="col-md-3">
                               <img className="w-100" src="/front/images/idback.png"/>
                            </div>
                            <div className="col-md-6">
                               <div className="trust-right documentcontlist">
                                   
                                    <p className="d-flex"><img src="/front/images/rightIcon.svg"/><span>Phosfluorescently predominate leveraged architectur and vertical catalysts for Energ tically conceptualize standardized.</span></p>
                                    <p className="d-flex"><img src="/front/images/rightIcon.svg"/><span>Phosfluorescently predominate leveraged architectur and vertical catalysts for Energ tically conceptualize standardized.</span></p>
                                    <p className="d-flex"><img src="/front/images/rightIcon.svg"/><span>Phosfluorescently predominate leveraged architectur and vertical catalysts for Energ tically conceptualize standardized.</span></p>
                                    <p className="d-flex"><img src="/front/images/rightIcon.svg"/><span>Phosfluorescently predominate leveraged architectur and vertical catalysts for Energ tically conceptualize standardized.</span></p>
                                  
                                 </div>
                                

                            </div>
                        </div>
                        
                        
                        
                     </div>
                  </div>

                  <div className="col-md-12 mb-4">
                     <div className="card-box documentsamplebox">
                        <div className="row align-items-center">
                           <div className="col-md-12">
                           <h3 className="mb-4 text-center">Identity Proof</h3>
                           </div>
                            <div className="col-md-3">
                               <img className="w-100" src="/front/images/idfront.png"/> 
                            </div>
                            <div className="col-md-3">
                               <img className="w-100" src="/front/images/idback.png"/>
                            </div>
                            <div className="col-md-6">
                               <div className="trust-right documentcontlist">
                                   
                                    <p className="d-flex"><img src="/front/images/rightIcon.svg"/><span>Phosfluorescently predominate leveraged architectur and vertical catalysts for Energ tically conceptualize standardized.</span></p>
                                    <p className="d-flex"><img src="/front/images/rightIcon.svg"/><span>Phosfluorescently predominate leveraged architectur and vertical catalysts for Energ tically conceptualize standardized.</span></p>
                                    <p className="d-flex"><img src="/front/images/rightIcon.svg"/><span>Phosfluorescently predominate leveraged architectur and vertical catalysts for Energ tically conceptualize standardized.</span></p>
                                    <p className="d-flex"><img src="/front/images/rightIcon.svg"/><span>Phosfluorescently predominate leveraged architectur and vertical catalysts for Energ tically conceptualize standardized.</span></p>
                                  
                                 </div>
                                

                            </div>
                        </div>
                        
                        
                        
                     </div>
                  </div>

                  <div className="col-md-12 mb-4">
                     <div className="card-box documentsamplebox">
                        <div className="row align-items-center">
                           <div className="col-md-12">
                           <h3 className="mb-4 text-center">Identity Proof</h3>
                           </div>
                            <div className="col-md-3">
                               <img className="w-100" src="/front/images/idfront.png"/> 
                            </div>
                            <div className="col-md-3">
                               <img className="w-100" src="/front/images/idback.png"/>
                            </div>
                            <div className="col-md-6">
                               <div className="trust-right documentcontlist">
                                   
                                    <p className="d-flex"><img src="/front/images/rightIcon.svg"/><span>Phosfluorescently predominate leveraged architectur and vertical catalysts for Energ tically conceptualize standardized.</span></p>
                                    <p className="d-flex"><img src="/front/images/rightIcon.svg"/><span>Phosfluorescently predominate leveraged architectur and vertical catalysts for Energ tically conceptualize standardized.</span></p>
                                    <p className="d-flex"><img src="/front/images/rightIcon.svg"/><span>Phosfluorescently predominate leveraged architectur and vertical catalysts for Energ tically conceptualize standardized.</span></p>
                                    <p className="d-flex"><img src="/front/images/rightIcon.svg"/><span>Phosfluorescently predominate leveraged architectur and vertical catalysts for Energ tically conceptualize standardized.</span></p>
                                  
                                 </div>
                                

                            </div>
                        </div>
                        
                        
                        
                     </div>
                  </div>
                  </div>
               </div>
            </div>

         </div>
      </>
   );
}
export default DocumentSample;