import React, { useState, useEffect, Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import { Form } from 'react-bootstrap';

import { toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';

import { useSelector, useDispatch } from 'react-redux';
import { admin_side_profile_verification } from '../../../redux_state/reducres/adminSideVerificationStatus';
import { admin_side_usercontact_verification } from '../../../redux_state/reducres/adminSideVerificationStatus';
import customStyles from '../../../expocomp/SelectStyleComp';
const UserBasicDetails = () => {
    const alphabeticRegex = /^[a-zA-Z]*$/;
    const [selectedOptionsEductions, setSelectedOptionsEductions] = React.useState([]);

    const dispatch = useDispatch();
    const loggedInUser = JSON.parse(localStorage.getItem("squadGologinSession"));
    const sessionStorageData = JSON.parse(localStorage.getItem("sessionStorageData"));
    const [moduleAccessibility] = useState(typeof (loggedInUser.accessibility) != undefined ? loggedInUser.accessibility.jobseekers : [{
        view: true,
        edit: true,
        delete: true,
    }]);
    const [isAddedQulification, setisAddedQulification] = useState(false);
    const [allEducationListOptions, setAllEducationListOptions] = useState([]);
    const [allQualificationListOptions, setAllQualificationListOptions] = useState([]);
    const handleSelectChangeEduction = (selected) => { setSelectedOptionsEductions(selected); };
    const handleSelectChangeQulification = (selected) => { setSelectedOptionsQulification(selected); };
    //const customStyles = { option: (provided, state) => ({ ...provided, padding: '3px 10px', }), };
    const [qualificationProofDocuments, setqualificationProofDocuments] = useState([]);
    const [fileLoadingContent, setfileLoadingContent] = useState(false);


    var countryCodeList = [];
    //const [countryCodeList , setCountryCodeList] = useState([]);
    if (typeof (sessionStorageData.get_all_country) != 'undefined') {
        // setCountryCodeList(sessionStorageData.get_all_country)
        countryCodeList = sessionStorageData.get_all_country;
    }
    //console.log(sessionStorageData.get_all_countryd)
    const editAccessibility = (moduleAccessibility != undefined && typeof (moduleAccessibility.edit) != undefined) ? moduleAccessibility.edit : true;
    const DayNumberLoop = (attr = '') => { const itemElements = []; itemElements.push(<option value="">DD</option>); for (let i = 1; i < 32; i++) { itemElements.push(<option selected={(attr != '' && attr.select_attr == i) ? true : false} value={i}>{i}</option>); } return itemElements; }
    const MonthsHtml = (attr = '') => { const itemElements = []; itemElements.push(<option value="">Month</option>); Months.map((item, index) => (itemElements.push(<option selected={(attr != '' && attr.select_attr == item.name) ? true : false} value={item.name}>{item.name}</option>))); return itemElements }
    const Months = [{ name: 'January', key: 1, abbreviation: 'Jan' }, { name: 'February', key: 2, abbreviation: 'Feb' }, { name: 'March', key: 3, abbreviation: 'Mar' }, { name: 'April', key: 4, abbreviation: 'Apr' }, { name: 'May', key: 5, abbreviation: 'May' }, { name: 'June', key: 6, abbreviation: 'Jun' }, { name: 'July', key: 7, abbreviation: 'Jul' }, { name: 'August', key: 8, abbreviation: 'Aug' }, { name: 'September', key: 9, abbreviation: 'Sep' }, { name: 'October', key: 10, abbreviation: 'Oct' }, { name: 'November', key: 11, abbreviation: 'Nov' }, { name: 'December', key: 12, abbreviation: 'Dec' },];
    const YearNumberLoop = (attr = '') => { const itemElements = []; itemElements.push(<option value="">YYYY</option>); for (let i = 1950; i < 2012; i++) { itemElements.push(<option selected={(attr != '' && attr.select_attr == i) ? true : false} value={i}>{i}</option>); } return itemElements }

    const currentYear = new Date().getFullYear();
    const YearFutureLoop = (attr = '') => { const itemElements = []; itemElements.push(<option value="">Year</option>); for (let i = currentYear; i <= currentYear + 10; i++) { itemElements.push(<option selected={(attr != '' && attr.select_attr == i) ? true : false} value={i}>{i}</option>); } return itemElements }
    const [selectedOptionsLanguage, setSelectedOptionsLanguage] = React.useState([{ value: '1', label: 'English' }]);
    const handleSelectChangeLanguage = (selected) => { setSelectedOptionsLanguage(selected); };
    const [companyDetails, setCompanyDetails] = useState({
        name: '',
        website: '',
        team_size: '',
        industry: '',
        address_unit_no: '',
        address_building_no: '',
        address_street_name: '',
        address_suburbs: '',
        address_user_state: '',
        address_document_type: '',
    });

    const [userdetails, setUserdetails] = useState([]);
    const [get_all_states, set_get_all_states] = useState([]);
    const [getAllIndustry, setgetAllIndustry] = useState([]);
    const [getAllStates, setgetAllStates] = useState([]);
    const [getAllVisa, setgetAllVisa] = useState([]);
    const [allLanguageListOption, setAllLanguageListOption] = useState([]);
    // const [allQualificationList, setallQualificationList] = useState([]);

    const [varifyDocumentList, setvarifyDocumentList] = useState([]);
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [companyAddressDocumentContents, setCompanyAddressDocumentContent] = useState([]);
    const [editSectionTab, seteditSectionTab] = useState(false);
    const [getUserTypeRole, setgetUserTypeRole] = useState('jobseeker');

    const [userVeri, setUserVeri] = useState(
        {
            profile_summery: false,
            basic_details: false,
            address_details: false,
            company_details: false,
            company_address: false,
            tax_information: false,
            director_contact_details: false,
            visa_details: false,
            education_and_qualification: false
        }
    );
    const [directorsArray, setDirectorsArray] = useState([]);
    const [getAllCountry, setGetallCountry] = useState([]);
    const [loadingContent, setLoadingContent] = useState(false);
    const [educationProofDocuments, setEducationProofDocuments] = useState([]);
    const [selectedOptionsQulification, setSelectedOptionsQulification] = React.useState([]);


    //allLanguageListOption allQualificationList
    const getCallingUserDeatilFun = (userid) => {
        (async () => {
            try {
                setIsDataLoading(true)
                const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/userdetails/" + userid + '?callingby=adminaccount');
                if (resData.data.status == 1) {
                    const getDataarr  =  resData.data.data.results ;
                    setUserdetails(getDataarr);
                    setCompanyDetails(getDataarr.company_details);
                    setCompanyAddressDocumentContent(getDataarr.company_address_document);
                    setUserVeri(getDataarr.verifications_status.statustype);
                    setgetUserTypeRole(getDataarr.verifications_status.usertype);
                    setDirectorsArray(getDataarr.directers_details);

                    //alert();
                    //console.log(resData.data.data.results.verifications_status.verification_status)
                    /*redux validation update */
                    //console.log(resData.data.data.results.verifications_status.verification_status) ;
                    dispatch(admin_side_profile_verification(getDataarr.verifications_status.verification_status));
                    dispatch(admin_side_usercontact_verification(getDataarr.contact_status));
                    /*redux validation check end  */
                    //userdetails.company_details
                    setEducationProofDocuments(getDataarr.user_education_documents);
                    setqualificationProofDocuments(getDataarr.user_qualification_documents);
                    setSelectedOptionsEductions(getDataarr.all_educations_proof ? JSON.parse(getDataarr.all_educations_proof) : []);
                    setSelectedOptionsQulification(getDataarr.all_qualifications ? JSON.parse(getDataarr.all_qualifications) : []);
                    localStorage.setItem('signup_userdetails', JSON.stringify(getDataarr));
                    setIsDataLoading(false)
                }
            } catch (error) { console.log(error); }
        })();
    }

    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        const userid = queryParameters.get("id");
        const sessionStorageData = JSON.parse(localStorage.getItem("sessionStorageData"));

        (async () => {
            if (sessionStorageData) {
                setAllLanguageListOption(sessionStorageData.get_all_language.option);
                setAllQualificationListOptions(sessionStorageData.get_all_eduqualification.option);
                setAllEducationListOptions(sessionStorageData.get_all_eduqualification.option);
            } else {

                try {
                    const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/get_all_eduqualification");
                    if (resData.data.status == 1) {
                        setAllEducationListOptions(resData.data.data.option);
                        setAllQualificationListOptions(resData.data.data.option);
                    }
                } catch (error) { console.log(error); }
                try {
                    const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/get_all_language");
                    if (resData.data.status == 1) { setAllLanguageListOption(resData.data.data.option); } 
                } catch (error) { console.log(error); }
            }

        })();


        set_get_all_states(sessionStorageData.get_all_states);
        setgetAllIndustry(sessionStorageData.get_all_industry);
        setgetAllStates(sessionStorageData.get_all_states);
        setvarifyDocumentList(sessionStorageData.get_all_address_type);
        setgetAllVisa(sessionStorageData.get_all_visatype);
        // setAllQualificationList(sessionStorageData.get_all_eduqualification.results);
        // setallLanguageListOption(sessionStorageData.get_all_language);
        setGetallCountry(sessionStorageData.get_all_country);
        getComapnyDetails();
        if (userid) { getCallingUserDeatilFun(userid); }
    }, []);






    /*----------------address detilas requirement---------------*/
    const [inputValue, setInputValue] = useState('');
    const [subRubSggestions, setSubRubSggestions] = useState([])

    const setSubrubsIteminArray = (value) => { setInputValue(value); if (value.length > 2) { fetchSuggestions(value); } else { setSubRubSggestions([]) } };
    const getSubrubByli = (value) => { setInputValue(value); setSubRubSggestions([]) };
    const fetchSuggestions = async (value) => {
        try {
            const response = await axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/get_all_subrubs_name/${value}?responsetype=autocomplete`);
            const data = response.data;
            setSubRubSggestions(data);
        } catch (error) { console.error(error); }
    };


    /*--------------------Tax information tab---------------------*/
    const [selectedTaxOption, setSelectedTaxOption] = useState('abn');
    console.log(selectedTaxOption);
    const [selectedTaxPlaceholder, setSelectedTaxPlaceholder] = useState('Enter ABN number');
    const [selectedTax, setSelectedTax] = useState('ABN');

    const [taxValueLength, setTaxValueLength] = useState(11);

    const setTaxTypeValueandParam = (value) => {
        console.log(value)
        setSelectedTaxOption(value);
        setTaxValueLength(11)
        setSelectedTax('ABN')
        setSelectedTaxPlaceholder('Enter ABN number')
        if (value == 'tfn') {
            setTaxValueLength(9);
            setSelectedTax('TFN');
            setSelectedTaxPlaceholder('Enter TFN number')
        }
    }

    /*-------------form submit --------------*/

    const [validated, setValidated] = useState(false);
    const onFormSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) { event.preventDefault(); event.stopPropagation(); }
        else {
            try {
                const formData = new FormData(event.target);
                const referenceDataValue = {};
                formData.forEach((value, name) => {
                    if (name.endsWith('[]')) {
                        const inputName = name.slice(0, -2);
                        if (!referenceDataValue[inputName]) {
                            referenceDataValue[inputName] = [value];
                        } else { referenceDataValue[inputName].push(value); }
                    } else { referenceDataValue[name] = value; }
                });
                if (userdetails.unique_id) { formData.append('userid', userdetails.unique_id); }
                const response = await axios.post(`https://squadgoo.com.au/api.squadgoo.com.au/userdetails_data_update`,
                    formData, { headers: { 'Content-Type': 'multipart/form-data' } });
                if (response.data.status == 1) { seteditSectionTab(false); toast.success(response.data.message); }
                else { toast.warning(response.data.message); }

            } catch (error) { console.error('Error uploading file:', error); }
        }
        setValidated(true);
    };

    //setUserDetailsArray userDetailsArray
    const onChangeContent = (event) => {
        const { name, value } = event.target;
        console.log(name);
        setUserdetails((prevFormData) => ({ ...prevFormData, [name]: value }));
    }

    /*----------------director details -----------------*/
    const directorDetailsUpdatefun = (event) => {
        const { name, value } = event.target;
        if ((name == 'first_name' || name == 'middle_name' || name == 'last_name') && !alphabeticRegex.test(value)) {
            return null;
        }

        setDirectorsArray((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
        //formdataValue(name, value)
    };
    const [validatedCnClass, setValidatedCnClass] = useState(false);
    const [contactNumber, setContactNumber] = useState('')
    const onDirectorsDetailsSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        setValidatedCnClass(false);
        if (!contactNumber) { setValidatedCnClass(true) }
        if (form.checkValidity() === false) {
            event.preventDefault(); event.stopPropagation(); console.log('error');
        } else {
            console.log('not error');
            try {
                const userid = userdetails.unique_id;
                const formData = new FormData();
                formData.append('name_salute', event.target.name_salute.value);
                formData.append('first_name', event.target.first_name.value);
                formData.append('middle_name', event.target.middle_name.value);
                formData.append('last_name', event.target.last_name.value);
                formData.append('email_id', event.target.email_id.value);
                formData.append('contact_country_code', event.target.contact_country_code.value);
                formData.append('contact_number', event.target.contact_number.value);
                //formData.append('contact_number', contactNumber);
                formData.append('userid', userid);
                const response = await axios.post(
                    `https://squadgoo.com.au/api.squadgoo.com.au/directors_details_field_update`,
                    formData, { headers: { 'Content-Type': 'multipart/form-data' } });
                if (response.data.status == 1) {
                    seteditSectionTab(false); toast.success(response.data.message);
                } else { }
            } catch (error) { console.error('Error uploading file:', error); }
            // handleNext()
        }
        setValidated(true);
    };
    /*------- company details------------*/


    const [isHidden, setIsHidden] = useState(true);
    const toggleHidden = () => { setIsHidden(!isHidden); };

    const [isHiddenDoc, setIsHiddenDoc] = useState(true);
    const toggleHiddenDoc = () => { setIsHiddenDoc(!isHiddenDoc); };

    const [isHidden2, setIsHidden2] = useState(true);
    const toggleHidden2 = () => { setIsHidden2(!isHidden2); };
    const getComapnyDetails = () => {
        const userid = userdetails.unique_id;
        (async () => {
            try {
                const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/get_company_details_by_userid/" + userid);
                if (resData.data.status == 1) {
                    setCompanyDetails(resData.data.data.results)
                }
            } catch (error) { console.log(error); }
        })();
    }

    const setCompanyDetailsFun = (event) => {
        const { name, value } = event.target;
        setCompanyDetails((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const onCompanyDetailsSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault(); event.stopPropagation();
        } else {
            try {
                const formData = new FormData();
                formData.append('name', companyDetails.name);
                formData.append('website', companyDetails.website);
                formData.append('team_size', companyDetails.team_size);
                formData.append('industry', companyDetails.industry);
                formData.append('user_id', userdetails.unique_id);
                const response = await axios.post(
                    `https://squadgoo.com.au/api.squadgoo.com.au/company_address_field_update`,
                    formData, { headers: { 'Content-Type': 'multipart/form-data' } });
                if (response.data.status == 1) {
                    //loggedInUserFun(userid); 
                    getComapnyDetails();
                    setIsHidden(true);
                    toast.success(response.data.message);
                } else { toast.warning(response.data.message); }
            } catch (error) { console.error('Error uploading file:', error); }
        }
        setValidated(true);
    };

    const onAddressDetailsSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault(); event.stopPropagation();
        } else {
            try {
                const formData = new FormData();
                formData.append('address_unit_no', event.target.address_unit_no.value);
                formData.append('address_building_no', event.target.address_building_no.value);
                formData.append('address_street_name', event.target.address_street_name.value);
                formData.append('address_suburbs', event.target.address_subrub.value);
                formData.append('address_state', event.target.address_user_state.value);
                formData.append('address_document_type', event.target.address_document_proof_type.value);
                formData.append('visa_subclass', event.target.visa_subclass.value);
                formData.append('user_id', userdetails.unique_id);
                const response = await axios.post(
                    `https://squadgoo.com.au/api.squadgoo.com.au/company_address_field_update`,
                    formData, { headers: { 'Content-Type': 'multipart/form-data' } });
                if (response.data.status == 1) {
                    getComapnyDetails();
                    setIsHidden2(true);
                    toast.success(response.data.message);
                } else { toast.warning(response.data.message); }
            } catch (error) { console.error('Error uploading file:', error); }
        }
        setValidated(true);
    };

    const userDetailsVelidationFun = (calltype) => {

        if (calltype == 'profile_summery') {
            setUserVeri((previewForm) => ({ ...previewForm, ['profile_summery']: (userVeri.profile_summery == false ? true : false) }));
        }

        if (calltype == 'basic_details') {
            setUserVeri((previewForm) => ({ ...previewForm, ['basic_details']: (userVeri.basic_details == false ? true : false) }));
        }

        if (calltype == 'address_details') {
            setUserVeri((previewForm) => ({ ...previewForm, ['address_details']: (userVeri.address_details == false ? true : false) }));
        }

        if (calltype == 'company_details') {
            setUserVeri((previewForm) => ({ ...previewForm, ['company_details']: (userVeri.company_details == false ? true : false) }));
        }

        if (calltype == 'company_address') {
            setUserVeri((previewForm) => ({ ...previewForm, ['company_address']: (userVeri.company_address == false ? true : false) }));
        }

        if (calltype == 'tax_information') {
            setUserVeri((previewForm) => ({ ...previewForm, ['tax_information']: (userVeri.tax_information == false ? true : false) }));
        }

        if (calltype == 'director_contact_details') {
            setUserVeri((previewForm) => ({ ...previewForm, ['director_contact_details']: (userVeri.director_contact_details == false ? true : false) }));
        }

        if (calltype == 'education_and_qualification') {
            setUserVeri((previewForm) => ({ ...previewForm, ['education_and_qualification']: (userVeri.education_and_qualification == false ? true : false) }));
        }

        if (calltype == 'visa_details') {
            setUserVeri((previewForm) => ({ ...previewForm, ['visa_details']: (userVeri.visa_details == false ? true : false) }));
        }


        (async () => {
            try {
                const response = await axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/user_details_verifications/${loggedInUser.login_token}/${userdetails.user_id}/${calltype}`);
                if (response.data.status == 1) {
                    toast.success(response.data.message);
                    setUserVeri(response.data.data.statustype);

                    /*redux validation update */
                    dispatch(admin_side_profile_verification(response.data.data.verification_status));
                    dispatch(admin_side_usercontact_verification(response.data.data.contact_status));
                    /*redux validation check end  */
                    //setUserVeri(response.data.data.verifications_status);
                } else {
                    //toast.warning(response.data.message); 
                }
            } catch (error) { console.log(error); }
        })();
    }


    const userFileUploadToServerVisaFun = async (field, file, reference_id = 0) => {
        const maxSizeInBytes = 1 * 1024 * 1024; // For example, 1 MB
        if (file.size > maxSizeInBytes) {
            //console.log("File size is greater than the specified limit.");
            toast.warning('File size is greater than the specified limit.');
        } else {
            // console.log("File size is within the specified limit.");
            const queryParameters = new URLSearchParams(window.location.search)
            const userid = queryParameters.get("id");
            try {
                setLoadingContent(1);
                const formData = new FormData();
                formData.append('file', file);
                formData.append('uploaded_for', field);
                formData.append('userid', userid);
                formData.append('reference_id', reference_id);
                formData.append('maxSizeInBytes', maxSizeInBytes);
                const response = await axios.post(`https://squadgoo.com.au/api.squadgoo.com.au/userdetails_file_uploads`,
                    formData, { headers: { 'Content-Type': 'multipart/form-data' } });
                setLoadingContent(0);
                if (response.data.status == 1) {
                    setqualificationProofDocuments(response.data.data.upload_array)
                } else { toast.warning(response.data.message); }
            } catch (error) { console.error('Error uploading file:', error); }
        }
    };

    const editFileFromServer = async (field, id) => { }
    const removeFileFromServer = async (field, id) => {
        if (window.confirm("Do you want to remove this file?")) {
            try {
                const formData = new FormData();
                formData.append('field', field);
                formData.append('file_id', id);
                const response = await axios.post(
                    `https://squadgoo.com.au/api.squadgoo.com.au/userdetails_upload_file_remove`,
                    formData, { headers: { 'Content-Type': 'multipart/form-data' } });
                if (response.data.status == 1) {
                    toast.success('Removed Successfully!');
                    setqualificationProofDocuments(response.data.data)
                } else { toast.warning(response.data.message); }
            } catch (error) { console.error('Error uploading file:', error); }
        }
    }

    return (
        <>
            {
                isDataLoading ?
                    <>
                        <div className='card-box work-experiencesec commonform mb-4 seekerdocument'>
                            <p className='text-center'><img className='width-100' src={'/front/images/loader.gif'} /></p>
                            <div className={`model_loader display_none ${!isDataLoading ? 'display_none' : ''}`}>
                                <img src={'/front/images/loader.gif'} />
                            </div>
                        </div>
                    </>
                    :
                    <>

                        {/* profile_summery */}
                        <div className="card-box work-experiencesec commonform mb-3">
                            <div className="d-flex align-items-center justify-content-between">
                                <h3 className="mb-3">
                                    Profile summary
                                </h3>
                                <div className='d-flex align-items-center'>
                                    <div className="d-flex" >
                                        {editAccessibility &&
                                            <div className="editbtn" onClick={() => seteditSectionTab(editSectionTab == 'profile_summary' ? false : 'profile_summary')}>
                                                <img src="/front/images/edit.png" />
                                            </div>
                                        }
                                    </div>

                                    <div className='d-flex align-items-center'>
                                        {/* {userVeri.profile_summery ? <><span className='text-green'>Verified</span></> : <><span className='text-red'>Not Verified</span></>} */}
                                        <div className="disableprofilewrapinner border-0 userdtl">
                                            {
                                                userVeri.profile_summery ?
                                                    <><img className='veriunveri-images' src="/front/images/vfy.png" data-toggle="tooltip" data-placement="top" title="Verified"></img></>
                                                    :
                                                    <><img className='veriunveri-images' src="/front/images/uvfy.png" data-toggle="tooltip" data-placement="top" title="Your Profile is in Review"></img></>
                                            }
                                            <input
                                                type="checkbox"
                                                checked={userVeri.profile_summery}
                                                onChange={() => userDetailsVelidationFun('profile_summery')}
                                            />
                                            {/* <span className="slider round"></span> */}
                                        </div>
                                    </div>

                                </div>

                            </div>



                            <div className="editblock">
                                <div className="editblockinner d-flex ">
                                    {userdetails.profile_summary ? userdetails.profile_summary : 'There are no content found'}
                                </div>
                            </div>
                            {
                                editSectionTab == 'profile_summary' &&
                                <>
                                    <div className="editblockinnerbtm">
                                        <Form noValidate validated={validated} onSubmit={onFormSubmit} >
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label">Profile Summary <span className="starr">*</span></label>
                                                        <textarea name='profile_summary' value={userdetails.profile_summary} onChange={onChangeContent} className="form-control" maxLength={500} placeholder="Enter your profile summary" />
                                                    </div>
                                                </div>

                                                <div className="col-md-12 mt-3">
                                                    <button className="batn batn-rounded rounded-orange mr-2">Save</button>
                                                    <button onClick={(e) => seteditSectionTab(false)} className="batn batn-rounded rounded-orange mr-2">Cancel</button>
                                                </div>
                                            </div>

                                        </Form>
                                    </div>
                                </>
                            }
                        </div>

                        {/* basic Details show */}
                        <div className="card-box work-experiencesec commonform mb-3">
                            <div className="d-flex align-items-center justify-content-between">
                                <h3 className="mb-3">
                                    {
                                        getUserTypeRole == 'rec_reprasentative' ?
                                            <>Representative details</>
                                            : <>Basic details</>
                                    }
                                </h3>
                                <div className='d-flex align-items-center'>
                                    <div className="d-flex" >
                                        {editAccessibility && <div className="editbtn" onClick={() => seteditSectionTab(editSectionTab == 'basic_details' ? false : 'basic_details')}><img src="/front/images/edit.png" /></div>}
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        {/* {userVeri.basic_details ? <><span className='text-green'>Verified</span></> : <><span className='text-red'>Not Verified</span></>} */}
                                        <div className="disableprofilewrapinner border-0 userdtl">
                                            {
                                                userVeri.basic_details ?
                                                    <><img className='veriunveri-images' src="/front/images/vfy.png" data-toggle="tooltip" data-placement="top" title="Verified"></img></>
                                                    :
                                                    <><img className='veriunveri-images' src="/front/images/uvfy.png" data-toggle="tooltip" data-placement="top" title="Your Profile is in Review"></img></>
                                            }
                                            <input
                                                type="checkbox"
                                                checked={userVeri.basic_details}
                                                onChange={() => userDetailsVelidationFun('basic_details')}
                                            />
                                            {/* <span className="slider round"></span> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="editblock">
                                <div className="editblockinner d-flex ">
                                    <h4>
                                        <div><span className="mr-2">First name :</span><span className="text-capitalize">{userdetails.first_name ? userdetails.first_name : '.......'} </span></div>
                                        <div><span className="mr-2">Middle name :</span><span className="text-capitalize">{userdetails.middle_name ? userdetails.middle_name : '.......'} </span></div>
                                        <div><span className="mr-2">Last name:</span><span className="text-capitalize">{userdetails.last_name ? userdetails.last_name : '......'} </span></div>
                                        <div><span className="mr-2">Email:</span><span className="text-lowecase">{userdetails.email ? userdetails.email : '......'} </span></div>
                                        <div><span className="mr-2">Contact:</span><span className="text-capitalize">{userdetails.contact_number ? `+${userdetails.contact_country_code} ${userdetails.contact_number}` : '......'} </span></div>
                                        <div><span className="mr-2 firstcoldtl">Date of birth</span><span className="text-capitalize">{userdetails.dob_day}-{userdetails.dob_month}-{userdetails.dob_year}</span></div>
                                    </h4>
                                </div>
                                {editSectionTab == 'basic_details' &&
                                    <div className="editblockinnerbtm">
                                        <Form noValidate validated={validated} onSubmit={onFormSubmit}>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="d-flex">
                                                        <div className="form-group mr-2 salutewrap">
                                                            <label className="form-label">Salute <span className="starr">*</span></label>
                                                            <select className="form-control" onChange={onChangeContent} name='name_salute'>
                                                                <option>Mr.</option>
                                                                <option selected={(userdetails.name_salute == 'Miss.') ? true : false}>Miss.</option>
                                                            </select>
                                                        </div>
                                                        <div className="form-group w-100">
                                                            <label className="form-label">First name <span className="starr">*</span></label>
                                                            <input required={true} type="text" onChange={onChangeContent} value={userdetails.first_name} className="form-control" placeholder="Andrew" name='first_name' />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label">Middle name <span className="starr">*</span></label>
                                                        <input required={true} type="text" onChange={onChangeContent} value={userdetails.middle_name} className="form-control" placeholder="Enter middle name" name='middle_name' />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label">Last name <span className="starr">*</span></label>
                                                        <input required={true} type="text" onChange={onChangeContent} value={userdetails.last_name} className="form-control" placeholder="Enderson" name='last_name' />
                                                    </div>
                                                </div>


                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Contact Number <span className="starr">*</span></label>
                                                        <div className="phonefield d-flex align-items-center">
                                                            <select className="form-control" onChange={onChangeContent} name="contact_country_code">

                                                                {
                                                                    countryCodeList.length ?
                                                                        <>
                                                                            {
                                                                                countryCodeList.map((item, index) => (
                                                                                    <option selected={item.phonecode == userdetails.contact_country_code} key={item.phonecode} value={item.phonecode}>+{item.phonecode}</option>
                                                                                ))
                                                                            }
                                                                        </>
                                                                        :
                                                                        <><option value="61">+61</option></>
                                                                }

                                                            </select>
                                                            <input type="tel" className="form-control" required="" onChange={onChangeContent} value={userdetails.contact_number} name="contact_number" placeholder="Enter mobile number" maxlength="10" />
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Date of birth <span className="starr">*</span></label>
                                                        <div className="d-flex">
                                                            <select className="form-control mr-2" required={true} name="dob_day" onChange={onChangeContent} >
                                                                {<DayNumberLoop select_attr={userdetails.dob_day} />}
                                                            </select>
                                                            <select className="form-control mr-2" required={true} name="dob_month" onChange={onChangeContent} >
                                                                {<MonthsHtml select_attr={userdetails.dob_month} />}
                                                            </select>
                                                            <select className="form-control" required={true} name="dob_year" onChange={onChangeContent} >
                                                                {<YearNumberLoop select_attr={userdetails.dob_year} />}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 mt-3">
                                                    <button className="batn batn-rounded rounded-orange mr-2">Save</button>
                                                    <button onClick={() => seteditSectionTab('basic_details')} className="batn batn-rounded rounded-orange mr-2">Cancel</button>
                                                </div>
                                            </div>
                                        </Form>
                                    </div>}
                            </div>
                        </div>

                        {/* Directer Details show */}
                        {
                            (userdetails.directers_details && userdetails.user_type_role == 'rec_reprasentative') ?
                                <>
                                    <div className="card-box work-experiencesec commonform mb-3">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <h3 className="mb-3">Director's contact details</h3>
                                            <div className='d-flex align-items-center'>
                                                <div className="d-flex" >
                                                    {editAccessibility && <div className="editbtn" onClick={() => seteditSectionTab(editSectionTab == 'directors_details' ? false : 'directors_details')}><img src="/front/images/edit.png" /></div>}
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    {/* {userVeri.director_contact_details ? <><span className='text-green'>Verified</span></> : <><span className='text-red'>Not Verified</span></>} */}
                                                    <div className="disableprofilewrapinner border-0 userdtl">
                                                        {
                                                            userVeri.director_contact_details ?
                                                                <><img className='veriunveri-images' src="/front/images/vfy.png" data-toggle="tooltip" data-placement="top" title="Verified"></img></>
                                                                :
                                                                <><img className='veriunveri-images' src="/front/images/uvfy.png" data-toggle="tooltip" data-placement="top" title="Your Profile is in Review"></img></>
                                                        }
                                                        <input
                                                            type="checkbox"
                                                            checked={userVeri.director_contact_details}
                                                            onChange={() => userDetailsVelidationFun('director_contact_details')}
                                                        />
                                                        {/* <span className="slider round"></span> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="editblock">
                                            <div className="editblockinner d-flex align-items-start">
                                                <h4>
                                                    <div>
                                                        <span className="mr-2">First name :</span>
                                                        <span className="text-capitalize">{directorsArray.first_name ? directorsArray.first_name : '.......'}</span>
                                                    </div>
                                                    <div><span className="mr-2">Middle name :</span><span className="text-capitalize">{directorsArray.middle_name ? directorsArray.middle_name : '......'}</span></div>
                                                    <div><span className="mr-2">Last name:</span><span className="text-capitalize">{directorsArray.last_name ? directorsArray.last_name : '.......'}</span></div>
                                                    <div><span className="mr-2">Phone:</span><span className="text-capitalize">{directorsArray.contact_number ? `${directorsArray.contact_country_code ? '+' + directorsArray.contact_country_code : ''}` + directorsArray.contact_number : '.........'}</span></div>
                                                    <div><span className="mr-2">E-mail:</span><span>{directorsArray.email_id ? directorsArray.email_id : '.........'}</span></div>
                                                </h4>
                                            </div>
                                        </div>
                                        {editSectionTab == 'directors_details' &&
                                            <div className="editblockinnerbtm">
                                                <Form noValnoValidate validated={validated} onSubmit={onDirectorsDetailsSubmit} >
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="d-flex">
                                                                <div className="form-group mr-2">
                                                                    <label className="form-label">Salute</label>
                                                                    <select className="form-control" name='name_salute' onChange={directorDetailsUpdatefun}>
                                                                        <option value={'Mr.'}>Mr.</option>
                                                                        <option value={'Miss.'} selected={(directorsArray.name_salute == 'Miss.') ? true : false}>Miss.</option>
                                                                    </select>
                                                                </div>
                                                                <div className="form-group w-100">
                                                                    <label className="form-label">First name<span className="starr">*</span></label>
                                                                    <input type="text" required={true} className="form-control" maxlength="100" placeholder="Andrew" value={directorsArray.first_name} name='first_name' onChange={directorDetailsUpdatefun} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label className="form-label">Middle name</label>
                                                                <input type="text" className="form-control" maxlength="100" onChange={directorDetailsUpdatefun} value={directorsArray.middle_name} name="middle_name" placeholder="Enter middle name" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label className="form-label">Last name<span className="starr">*</span></label>
                                                                <input type="text" required={true} className="form-control" maxlength="100" onChange={directorDetailsUpdatefun} value={directorsArray.last_name} name="last_name" placeholder="Enderson" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group mb-2">
                                                                <label className="form-label">Phone<span className="starr">*</span></label>
                                                                <div className="phonefield d-flex align-items-center">
                                                                    <select className="form-control" name="contact_country_code" >
                                                                        {
                                                                            getAllCountry.length ?
                                                                                <>
                                                                                    {
                                                                                        getAllCountry.map((item, index) => (
                                                                                            <option selected={directorsArray.contact_country_code == item.phonecode ? true : false} value={item.phonecode}>+{item.phonecode}</option>
                                                                                        ))
                                                                                    }
                                                                                </>
                                                                                : ''
                                                                        }
                                                                    </select>
                                                                    <input type="tel" required={true} name="contact_number" className="form-control" placeholder="Enter mobile number" onChange={directorDetailsUpdatefun} maxlength="10" value={directorsArray.contact_number} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group mb-2">
                                                                <label className="form-label">E-mail<span className="starr">*</span></label>
                                                                <div className="phonefield d-flex align-items-center">
                                                                    <input type="email" required={true} className="form-control" onChange={directorDetailsUpdatefun} placeholder="Enter e-mail address" maxlength="100" value={directorsArray.email_id} name='email_id' />
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className="col-md-12 mt-3">
                                                            <button type='submit' className="batn batn-rounded rounded-orange mr-2">Save</button>
                                                            <button type='button' onClick={() => seteditSectionTab(false)} className="batn batn-rounded rounded-orange mr-2">Cancel</button>
                                                        </div>

                                                    </div>
                                                </Form>
                                            </div>
                                        }

                                    </div>
                                </> :
                                <></>
                        }

                        {/* address Details show */}
                        {userdetails.user_type_role != 'rec_reprasentative' && <div className="card-box work-experiencesec commonform mb-3">
                            <div className="d-flex align-items-center1 justify-content-between">
                                <h3 className="mb-3">Address</h3>
                                {/* {editAccessibility && <div className="editbtn" onClick={() => seteditSectionTab(editSectionTab == 'address_details' ? false : 'address_details')}><img src="/front/images/edit.png" /></div>} */}

                                <div className='d-flex align-items-center'>
                                    <div className="d-flex" >
                                        {editAccessibility && <div className="editbtn" onClick={() => seteditSectionTab(editSectionTab == 'address_details' ? false : 'address_details')}><img src="/front/images/edit.png" /></div>}
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        {/* {userVeri.address_details ? <><span className='text-green'>Verified</span></> : <><span className='text-red'>Not Verified</span></>} */}
                                        <div className="disableprofilewrapinner border-0 userdtl">
                                            {
                                                userVeri.address_details ?
                                                    <><img className='veriunveri-images' src="/front/images/vfy.png" data-toggle="tooltip" data-placement="top" title="Verified"></img></>
                                                    :
                                                    <><img className='veriunveri-images' src="/front/images/uvfy.png" data-toggle="tooltip" data-placement="top" title="Your Profile is in Review"></img></>
                                            }
                                            <input
                                                type="checkbox"
                                                checked={userVeri.address_details}
                                                onChange={() => userDetailsVelidationFun('address_details')}
                                            />
                                            {/* <span className="slider round"></span> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="editblock">
                                <div className="editblockinner d-flex align-items-start">
                                    <h4>
                                        <div><span className="mr-2 firstcoldtl">Unit no :</span><span className="text-capitalize">{userdetails.address_unit_no ? userdetails.address_unit_no : '.......'}</span></div>
                                        <div><span className="mr-2 firstcoldtl">Building no :</span><span className="text-capitalize">{userdetails.address_building_no ? userdetails.address_building_no : '.......'}</span></div>
                                        <div><span className="mr-2 firstcoldtl">Street name :</span><span className="text-capitalize">{userdetails.address_street_name ? userdetails.address_street_name : '.......'}</span></div>
                                        <div><span className="mr-2 firstcoldtl">Suburb :</span><span className="text-capitalize">{userdetails.address_suburbs ? userdetails.address_suburbs : '.......'}</span></div>
                                        <div><span className="mr-2 firstcoldtl">State:</span><span className="text-capitalize">{userdetails.address_user_state_name ? userdetails.address_user_state_name : '.......'}</span></div>
                                    </h4>
                                </div>
                            </div>

                            {
                                editSectionTab == 'address_details' &&
                                <>
                                    <div className="editblockinnerbtm">
                                        <Form noValidate validated={validated} onSubmit={onFormSubmit}>
                                            <div className="row">

                                                <div className="col-md-6">
                                                    <div className="d-flex ">
                                                        <div className="form-group mr-2 w-100">
                                                            <label className="form-label">Unit <span className="starr">*</span></label>
                                                            <input type="text" className="form-control" required name='address_unit_no' value={userdetails.address_unit_no} maxLength={20} onChange={onChangeContent} placeholder="Unit" />
                                                        </div>
                                                        <div className="form-group w-100">
                                                            <label className="form-label">Building no <span className="starr">*</span> </label>
                                                            <input type="text" className="form-control" required name='address_building_no' value={userdetails.address_building_no} onChange={onChangeContent} maxLength={20} placeholder="Building no" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Street name <span className="starr">*</span></label>
                                                        <input type="text" className="form-control" required name='address_street_name' value={userdetails.address_street_name} maxLength={20} onChange={onChangeContent} placeholder="Enter Street name" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Suburb <span className="starr">*</span></label>
                                                        <input type="text" className="form-control" required name='address_suburbs' value={inputValue} placeholder="Enter your suburb" onChange={(e) => setSubrubsIteminArray(e.target.value)} />
                                                        {
                                                            subRubSggestions.length ?
                                                                <>
                                                                    <ul className='auto_search_ul'>
                                                                        {
                                                                            subRubSggestions.map((suggestion) => (<li onClick={(e) => getSubrubByli(suggestion.name)} key={suggestion.id}>{suggestion.name}</li>))
                                                                        }
                                                                    </ul>
                                                                </>
                                                                :
                                                                ''
                                                        }
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label">State <span className="starr">*</span></label>
                                                        <select className="form-control mr-2" required name="address_user_state" onChange={onChangeContent}>
                                                            <option value="">Select state</option>
                                                            {get_all_states.map((item, index) => (<option selected={userdetails.address_user_state == item.id ? true : false} value={item.id} >{item.state}</option>))}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 mt-3">
                                                    <button className="batn batn-rounded rounded-orange mr-2">Save</button>
                                                    <button onClick={(e) => seteditSectionTab(false)} className="batn batn-rounded rounded-orange mr-2">Cancel</button>
                                                </div>
                                            </div>
                                        </Form>
                                    </div>
                                </>
                            }
                        </div>}



                        {/* company_details show */}
                        {
                            (userdetails.company_details && userdetails.user_type_role != 'rec_indivisual' && userdetails.user_type_role != 'jobseeker') ?
                                <>
                                    <div className="card-box work-experiencesec commonform mb-3">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <h3 className="mb-3">Company details</h3>
                                            {/* {editAccessibility && <div className="editbtn" onClick={() => seteditSectionTab(editSectionTab == 'company_details' ? false : 'company_details')}><img src="/front/images/edit.png" /></div>} */}
                                            <div className='d-flex align-items-center'>
                                                <div className="d-flex" >
                                                    {editAccessibility && <div className="editbtn" onClick={() => seteditSectionTab(editSectionTab == 'company_details' ? false : 'company_details')}><img src="/front/images/edit.png" /></div>}
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    {/* {userVeri.company_details ? <><span className='text-green'>Verified</span></> : <><span className='text-red'>Not Verified</span></>} */}
                                                    <div className="disableprofilewrapinner border-0 userdtl">
                                                        {
                                                            userVeri.company_details ?
                                                                <><img className='veriunveri-images' src="/front/images/vfy.png" data-toggle="tooltip" data-placement="top" title="Verified"></img></>
                                                                :
                                                                <><img className='veriunveri-images' src="/front/images/uvfy.png" data-toggle="tooltip" data-placement="top" title="Your Profile is in Review"></img></>
                                                        }
                                                        <input
                                                            type="checkbox"
                                                            checked={userVeri.company_details}
                                                            onChange={() => userDetailsVelidationFun('company_details')}
                                                        />
                                                        {/* <span className="slider round"></span> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="editblock">
                                            <div className="editblockinner d-flex align-items-start">
                                                <h4>
                                                    <div><span className="mr-2 w-40 text-capitalize">Company name :</span><span className="text-capitalize">{companyDetails.name ? companyDetails.name : '..........'}</span></div>
                                                    <div><span className="mr-2 w-40">Company website :</span><span><a target="_blank" href={companyDetails.website} >{companyDetails.website ? userdetails.company_details.website : '........'}</a></span></div>
                                                    <div><span className="mr-2 w-40">Company size :</span><span>{companyDetails.team_size ? companyDetails.team_size : 1} Members</span></div>
                                                    <div><span className="mr-2 w-40 text-capitalize">Industry :</span><span className="text-capitalize">{companyDetails.industry ? companyDetails.industry : '.......'} </span></div>
                                                </h4>
                                            </div>
                                        </div>
                                        {
                                            editSectionTab == 'company_details' &&
                                            <>
                                                <div className="editblock">
                                                    <Form noValidate validated={validated} onSubmit={onCompanyDetailsSubmit} >

                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label className="form-label">Company name <span className="starr">*</span></label>
                                                                    <input required={true} onChange={setCompanyDetailsFun} name="name" value={companyDetails.name} type="text" className="form-control" placeholder="Enter company name" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label className="form-label">Company website <span className="starr">*</span></label>
                                                                    <input required={true} onChange={setCompanyDetailsFun} name="website" value={companyDetails.website} type="text" className="form-control" placeholder="Enter companyn website" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label className="form-label">Company size <span className="starr">*</span></label>
                                                                    <input required={true} onChange={setCompanyDetailsFun} name="team_size" value={companyDetails.team_size} type="number" min="2" className="form-control" placeholder="Enter Team Size" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label className="form-label">Industry <span className="starr">*</span></label>
                                                                    <select className="form-control" required={true} name="industry" onChange={setCompanyDetailsFun}  >
                                                                        <option value="">Select industry</option>
                                                                        {
                                                                            getAllIndustry.length ?
                                                                                <>
                                                                                    {
                                                                                        getAllIndustry.map((item, index) => (
                                                                                            <>
                                                                                                <option selected={companyDetails.industry == item.id ? true : false} value={item.id}>{item.industry}</option>
                                                                                            </>
                                                                                        ))
                                                                                    }
                                                                                </>
                                                                                : ''
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12 mt-3">
                                                                <button type="submit" className="batn batn-rounded rounded-orange mr-2">Save</button>
                                                                <button onClick={() => seteditSectionTab(false)} className="batn batn-rounded rounded-orange mr-2">Cancel</button>
                                                            </div>
                                                        </div>

                                                    </Form>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </>
                                :
                                <></>
                        }

                        {/* company_address address */}
                        {

                            (userdetails.company_details && userdetails.user_type_role != 'rec_indivisual' && userdetails.user_type_role != 'jobseeker') ?
                                <div className="card-box work-experiencesec commonform mb-3">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h3 className="mb-3">Company address</h3>
                                        {/* {editAccessibility && <div className="editbtn" onClick={() => seteditSectionTab(editSectionTab == 'company_address' ? false : 'company_address')}><img src="/front/images/edit.png" /></div>} */}
                                        <div className='d-flex align-items-center'>
                                            <div className="d-flex" >
                                                {editAccessibility && <div className="editbtn" onClick={() => seteditSectionTab(editSectionTab == 'company_address' ? false : 'company_address')}><img src="/front/images/edit.png" /></div>}
                                            </div>
                                            <div className='d-flex align-items-center'>
                                                {/* {userVeri.company_address ? <><span className='text-green'>Verified</span></> : <><span className='text-red'>Not Verified</span></>} */}
                                                <div className="disableprofilewrapinner border-0 userdtl">
                                                    {
                                                        userVeri.company_address ?
                                                            <><img className='veriunveri-images' src="/front/images/vfy.png" data-toggle="tooltip" data-placement="top" title="Verified"></img></>
                                                            :
                                                            <><img className='veriunveri-images' src="/front/images/uvfy.png" data-toggle="tooltip" data-placement="top" title="Your Profile is in Review"></img></>
                                                    }
                                                    <input
                                                        type="checkbox"
                                                        checked={userVeri.company_address}
                                                        onChange={() => userDetailsVelidationFun('company_address')}
                                                    />
                                                    {/* <span className="slider round"></span> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="editblock">
                                        <div className="editblockinner d-flex align-items-start">
                                            <h4>
                                                <div><span className="mr-2 w-40">Unit :</span><span>{companyDetails.address_unit_no ? companyDetails.address_unit_no : '..........'}</span></div>
                                                <div><span className="mr-2 w-40">Building no :</span><span>{companyDetails.address_building_no ? companyDetails.address_building_no : '..........'}</span></div>
                                                <div><span className="mr-2 w-40">Street name :</span><span className="text-capitalize">{companyDetails.address_street_name ? userdetails.company_details.address_street_name : '..........'}</span></div>
                                                <div><span className="mr-2 w-40">Suburb :</span><span>{companyDetails.address_suburbs ? companyDetails.address_suburbs : '..........'}</span></div>
                                                <div><span className="mr-2 w-40">State:</span><span className="text-capitalize">{companyDetails.address_state ? companyDetails.address_state : '..........'}</span></div>
                                                <div><span className="mr-2 w-40">Document Type:</span><span className="text-capitalize">{companyDetails.address_document_type ? companyDetails.address_document_type : '..........'}</span></div>
                                            </h4>
                                            <div className="editbtn"></div>
                                        </div>
                                    </div>
                                    {
                                        editSectionTab == 'company_address' &&
                                        <>
                                            <div className="editblock">
                                                <Form noValidate validated={validated} onSubmit={onAddressDetailsSubmit} >
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="d-flex">
                                                                <div className="form-group mb-2 mr-2">
                                                                    <label className="form-label">Unit no<span className="starr">*</span></label>
                                                                    <input name="address_unit_no" required={true} onChange={setCompanyDetailsFun} value={companyDetails.address_unit_no} type="text" className="form-control" placeholder="Unit" />
                                                                </div>
                                                                <div className="form-group">
                                                                    <label className="form-label">Building no<span className="starr">*</span></label>
                                                                    <input name="address_building_no" required={true} onChange={setCompanyDetailsFun} value={companyDetails.address_building_no} type="text" className="form-control" placeholder="Building no" />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="form-label">Street name<span className="starr">*</span></label>
                                                                <input name="address_street_name" required={true} onChange={setCompanyDetailsFun} value={companyDetails.address_street_name} type="text" className="form-control" placeholder="Enter Street name" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="form-label">Suburb<span className="starr">*</span></label>
                                                                <input type="text" className="form-control" required name='address_subrub' value={inputValue} placeholder="Enter your suburb" onChange={(e) => setSubrubsIteminArray(e.target.value)} />
                                                                {
                                                                    subRubSggestions.length ?
                                                                        <>
                                                                            <ul className='auto_search_ul'>
                                                                                {
                                                                                    subRubSggestions.map((suggestion) => (<li onClick={(e) => getSubrubByli(suggestion.name)} key={suggestion.id}>{suggestion.name}</li>))
                                                                                }
                                                                            </ul>
                                                                        </>
                                                                        :
                                                                        ''
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="form-label">State<span className="starr">*</span></label>
                                                                <select className="form-control mr-2" required={true} onChange={setCompanyDetailsFun} value={companyDetails.address_user_state} name="address_user_state" >
                                                                    {getAllStates.map((item, index) => (<option value={item.id} >{item.state}</option>))}
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <label className="form-label">Document for address proof <span className="starr">*</span> <span className="infowrap"><i className="fa fa-info"></i><span className="onhoverinfo">Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum</span></span></label>
                                                                <select disabled={(companyAddressDocumentContents.length >= 1 && companyDetails.address_document_type) ? true : false} className="form-control" required={true} onChange={setCompanyDetailsFun} value={companyDetails.address_document_proof_type} name="address_document_proof_type" >
                                                                    <option value='' >Select document</option>
                                                                    {
                                                                        varifyDocumentList.map((item, index) => (
                                                                            <option selected={companyDetails.address_document_type == item.document_id ? true : false} value={item.document_id}>{item.document_type}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 mt-3">
                                                            <button type="submit" className="batn batn-rounded rounded-orange mr-2">Save</button>
                                                            <button onClick={() => seteditSectionTab(false)} className="batn batn-rounded rounded-orange mr-2">Cancel</button>
                                                        </div>
                                                    </div>

                                                </Form>
                                            </div>
                                        </>
                                    }
                                </div>
                                :
                                <></>
                        }

                        {/* Tax information */}
                        <div className="card-box license-sec tfn-sec mb-3">
                            <div className="">
                                <div className="d-flex justify-content-between">
                                    <h3 className="mb-3">Tax information</h3>
                                    {/* {editAccessibility && <div className="editbtn" onClick={() => seteditSectionTab(editSectionTab == 'tax_information' ? false : 'tax_information')}><img src="/front/images/edit.png" /></div>} */}
                                    <div className='d-flex align-items-center'>
                                        <div className="d-flex" >
                                            {editAccessibility && <div className="editbtn" onClick={() => seteditSectionTab(editSectionTab == 'tax_information' ? false : 'tax_information')}><img src="/front/images/edit.png" /></div>}
                                        </div>
                                        <div className='d-flex align-items-center'>
                                            {/* {userVeri.tax_information ? <><span className='text-green'>Verified</span></> : <><span className='text-red'>Not Verified</span></>} */}
                                            <div className="disableprofilewrapinner border-0 userdtl">
                                                {
                                                    userVeri.tax_information ?
                                                        <><img className='veriunveri-images' src="/front/images/vfy.png" data-toggle="tooltip" data-placement="top" title="Verified"></img></>
                                                        :
                                                        <><img className='veriunveri-images' src="/front/images/uvfy.png" data-toggle="tooltip" data-placement="top" title="Your Profile is in Review"></img></>
                                                }
                                                <input
                                                    type="checkbox"
                                                    checked={userVeri.tax_information}
                                                    onChange={() => userDetailsVelidationFun('tax_information')}
                                                />
                                                {/* <span className="slider round"></span> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="editblock">
                                    <div className="editblockinner d-flex align-items-start">
                                        <h4>
                                            <div>
                                                <span className="mr-2 w-40 text-uppercase">{userdetails.tax_type} No</span>
                                                <span>{userdetails.tax_type_value}</span>
                                            </div>
                                        </h4>
                                    </div>
                                </div>
                                {/* <div className="d-flex align-items-center justify-content-between mb-3">
                                    <p className="mb-0 largepera"> <span className='text-uppercase'>{userdetails.tax_type} </span><span className='ml-2'>No.</span></p>
                                    <div className="d-flex flex-wrap">
                                        <p className="mb-0">{userdetails.tax_type_value}</p>
                                    </div>
                                </div> */}

                                {
                                    editSectionTab == 'tax_information' &&
                                    <>
                                        <Form noValidate validated={validated} onSubmit={onFormSubmit}>
                                            <div className="row mb-2">
                                                <input type="hidden" value="abn" name='tax_type' ></input>
                                                <div className="col-md-12">
                                                    <div className="form-group mb-2">
                                                        <label className="form-label"><span className="mr-2 w-40 text-uppercase">{userdetails.tax_type} No</span></label>
                                                        <input
                                                            type="text"
                                                            name='tax_type_value'
                                                            className="form-control"
                                                            maxLength={taxValueLength}
                                                            // onChange={(e) => setTaxDefaultValue(e.target.value)} 
                                                            // value={taxDefaultValue} 
                                                            placeholder={selectedTaxPlaceholder}
                                                            value={userdetails.tax_type_value}
                                                            onChange={onChangeContent}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12 mt-3">
                                                <button type='submit' className="batn batn-rounded rounded-orange mr-2">Save</button>
                                                <button type='button' onClick={(e) => seteditSectionTab(false)} className="batn batn-rounded rounded-orange mr-2">Cancel</button>
                                            </div>
                                        </Form>
                                    </>
                                }
                            </div>
                        </div>


                        {/* visa details */}
                        {
                            (userdetails.user_type_role == 'jobseeker') &&
                            <div className="card-box license-sec tfn-sec mb-3">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h3 className="mb-3">Visa details</h3>
                                    {/* {editAccessibility && <div className="editbtn" onClick={() => seteditSectionTab(editSectionTab == 'visa_details' ? false : 'visa_details')}><img src="/front/images/edit.png" /></div>} */}
                                    <div className='d-flex align-items-center'>
                                        <div className="d-flex" >
                                            {editAccessibility && <div className="editbtn" onClick={() => seteditSectionTab(editSectionTab == 'visa_details' ? false : 'visa_details')}><img src="/front/images/edit.png" /></div>}
                                        </div>
                                        <div className='d-flex align-items-center'>
                                            {/* {userVeri.visa_details ? <><span className='text-green'>Verified</span></> : <><span className='text-red'>Not Verified</span></>} */}
                                            <div className="disableprofilewrapinner border-0 userdtl">
                                                {
                                                    userVeri.visa_details ?
                                                        <><img className='veriunveri-images' src="/front/images/vfy.png" data-toggle="tooltip" data-placement="top" title="Verified"></img></>
                                                        :
                                                        <><img className='veriunveri-images' src="/front/images/uvfy.png" data-toggle="tooltip" data-placement="top" title="Your Profile is in Review"></img></>
                                                }
                                                <input
                                                    type="checkbox"
                                                    checked={userVeri.visa_details}
                                                    onChange={() => userDetailsVelidationFun('visa_details')}
                                                />
                                                {/* <span className="slider round"></span> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="editblock">
                                    <div className="editblockinner d-flex align-items-start">
                                        <h4>
                                            <div><span className="mr-2 firstcoldtl">Visa subclass :</span><span>{userdetails.type_of_visa_name ? userdetails.type_of_visa_name : '......'}</span></div>
                                            <div>
                                                <span className="mr-2 firstcoldtl">Visa expiry  :</span><span>
                                                    {userdetails.visa_expire_day ? userdetails.visa_expire_day : 'dd'}-
                                                    {userdetails.visa_expire_month ? userdetails.visa_expire_month : 'mm'}-
                                                    {userdetails.visa_expire_year ? userdetails.visa_expire_year : 'yyyy'}
                                                </span>
                                            </div>
                                            <div><span className="mr-2 firstcoldtl">Visa grant number :</span><span>{userdetails.visa_grant_number ? userdetails.visa_grant_number : '......'}</span></div>

                                        </h4>

                                    </div>
                                    {editSectionTab == 'visa_details' &&
                                        <div className="editblockinnerbtm">
                                            <Form noValnoValidate validated={validated} onSubmit={onFormSubmit} >
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group "> 
                                                            <label className="form-label">Visa subclass <span className="starr">*</span></label>
                                                            <select className="form-control" required={true} name='type_of_visa' onChange={onChangeContent}  >
                                                                <option value="">Select</option>
                                                                {
                                                                    getAllVisa.length ?
                                                                        <>
                                                                            {
                                                                                getAllVisa.map((item, index) => (
                                                                                    <option selected={userdetails.type_of_visa == item.id ? true : false} value={item.id}>{item.visa}</option>
                                                                                ))
                                                                            }
                                                                        </>
                                                                        : ''
                                                                }
                                                            </select>

                                                            {(userdetails.type_of_visa == 6 || userdetails.type_of_visa == 1) && 
                                                            <div class="mt-2">
                                                               <input type="text"  required={true} name="visa_subclass" class="form-control" length={50} placeholder="Visa subclass" onChange={onChangeContent} value={userdetails.visa_subclass} />
                                                            </div>
                                                         }
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group ">
                                                            <label className="form-label">Visa expiry <span className="starr">*</span></label>
                                                            {/* --------------------- */}
                                                            <div className="d-flex datefildgruop  mb-2">
                                                                <select name="visa_expire_day" required={true} className="form-control mr-2 datefild" onChange={onChangeContent}>
                                                                    {<DayNumberLoop select_attr={userdetails.visa_expire_day} />}
                                                                </select>
                                                                <select name="visa_expire_month" required={true} className="form-control mr-2 monthfild" onChange={onChangeContent}>
                                                                    {<MonthsHtml select_attr={userdetails.visa_expire_month} />}
                                                                </select>
                                                                <select name="visa_expire_year" required={true} className="form-control yearfild" onChange={onChangeContent} >
                                                                    {<YearFutureLoop select_attr={userdetails.visa_expire_year} />}
                                                                </select>
                                                            </div>
                                                            {/* --------------------- */}
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group mb-0 ">
                                                            <label className="form-label">Visa grant number</label>
                                                            <div className="">
                                                                <input type="text" maxLength={13} name='visa_grant_number' onChange={onChangeContent} value={userdetails.visa_grant_number} className="form-control" placeholder={'Visa grant number'} />
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="col-md-12 mt-3">
                                                        <button type='submit' className="batn batn-rounded rounded-orange mr-2">Save</button>
                                                        <button type='button' onClick={() => seteditSectionTab(false)} className="batn batn-rounded rounded-orange mr-2">Cancel</button>
                                                    </div>

                                                </div>
                                            </Form>
                                        </div>
                                    }

                                </div>
                            </div>
                        }



                        {/* education details */}
                        {
                            (userdetails.user_type_role == 'jobseeker') &&
                            <div className="card-box license-sec tfn-sec mb-3">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h3 className="mb-3">Education</h3>
                                    {/* {editAccessibility && <div className="editbtn" onClick={() => seteditSectionTab(editSectionTab == 'education_and_qualification' ? false : 'education_and_qualification')}><img src="/front/images/edit.png" /></div>} */}
                                    <div className='d-flex align-items-center'>
                                        <div className="d-flex" >
                                            {editAccessibility && <div className="editbtn" onClick={() => seteditSectionTab(editSectionTab == 'education_and_qualification' ? false : 'education_and_qualification')}><img src="/front/images/edit.png" /></div>}
                                        </div>
                                        <div className='d-flex align-items-center'>
                                            {/* {userVeri.education_and_qualification ? <><span className='text-green'>Verified</span></> : <><span className='text-red'>Not Verified</span></>} */}
                                            <div className="disableprofilewrapinner border-0 userdtl">
                                                {
                                                    userVeri.education_and_qualification ?
                                                        <><img className='veriunveri-images' src="/front/images/vfy.png" data-toggle="tooltip" data-placement="top" title="Verified"></img></>
                                                        :
                                                        <><img className='veriunveri-images' src="/front/images/uvfy.png" data-toggle="tooltip" data-placement="top" title="Your Profile is in Review"></img></>
                                                }
                                                <input
                                                    type="checkbox"
                                                    checked={userVeri.education_and_qualification}
                                                    onChange={() => userDetailsVelidationFun('education_and_qualification')}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="editblock">
                                    <div className="editblockinner d-flex align-items-start">
                                        <h4>
                                            {/* <div><span className="mr-2 firstcoldtl">Highest Qualification :</span><span className="text-capitalize">{userdetails.highest_qualification_text ? userdetails.highest_qualification_text : '..........'}</span></div> */}

                                            <div className="d-flex align-items-center justify-content-between">
                                                <span className="mr-2 firstcoldtl">Education :</span>
                                                <div className="text-capitalize">
                                                    {
                                                        selectedOptionsEductions.length < 2 &&
                                                        selectedOptionsEductions.map((item, index) => (
                                                            <>{item.label} </>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                            <p>
                                                {
                                                    selectedOptionsEductions.length > 1 &&
                                                    selectedOptionsEductions.map((item, index) => (
                                                        <><span className='text-capitalize'> {item.label} </span>{index == selectedOptionsEductions.length - 1 ? '' : ','} </>
                                                    ))
                                                }
                                            </p>
                                            <div>
                                                <span className="mr-2 firstcoldtl">Languages should know :</span>
                                                <span className="text-capitalize">{userdetails.language_text ? userdetails.language_text : '..........'}</span>
                                            </div>
                                        </h4>
                                    </div>
                                    {editSectionTab == 'education_and_qualification' &&
                                        <div className="editblockinnerbtm">
                                            <Form noValnoValidate validated={validated} onSubmit={onFormSubmit} >
                                                <div className="row">
                                                    <div className="row mb-2">

                                                        <div className="col-md-12">
                                                            <div className="form-group mb-2 taxtype">
                                                                <label className="form-label">Education <span className="starr">*</span></label>
                                                                <Select
                                                                    className={`react_select ${isAddedQulification ? 'bordercss' : ''}`}
                                                                    options={allEducationListOptions}
                                                                    isMulti
                                                                    value={selectedOptionsEductions}
                                                                    onChange={handleSelectChangeEduction}
                                                                    placeholder="Select options..."
                                                                    styles={customStyles}
                                                                />
                                                                {
                                                                    (selectedOptionsEductions.length > 0) &&
                                                                    selectedOptionsEductions.map((item, index) => (
                                                                        <>
                                                                            {
                                                                                (!educationProofDocuments[index]) ?
                                                                                    <>
                                                                                        {
                                                                                            <div className="form-group identityproof stepscont mt-2">
                                                                                                <div className="customuploadsec">
                                                                                                    {!fileLoadingContent && <input type="file" accept="image/x-png,image/jpg,image/jpeg ,application/pdf"
                                                                                                        onChange={(e) => userFileUploadToServerVisaFun(`eductions_marksheet`, e.target.files[0], item.value)}
                                                                                                    ></input>}
                                                                                                    <div className="customuploadinner text-center">
                                                                                                        <div className="my-4"><img src="/front/images/uploadicon.png" className="mt-4"></img></div>
                                                                                                        <p className="mb-0">{fileLoadingContent ? `Docuemnt is uploading.....` : `Document upload for ${item.label}`}</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        }
                                                                                        <p className="mt-2 purpleclr font12">Accept only jpg, jpeg, png, doc and pdf files (Max file size 1 MB)</p>
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        <div className="documentwrap d-flex align-items-center mt-2 mb-2">
                                                                                            <a className='document_list' target="_blank" href={'https://squadgoo.com.au/api.squadgoo.com.au/' + educationProofDocuments[index].document_url}>
                                                                                                <p>Education proof {index + 1} - {item.label}</p>
                                                                                            </a>
                                                                                            <div className="documentwrapinner d-flex align-items-center">
                                                                                                <span className='cursor_pointer ml-2 editfile' onClick={e => editFileFromServer('eductions_marksheet', educationProofDocuments[index].id)}><img src="/front/images/edit.png" /></span>
                                                                                                <span className='cursor_pointer ml-2' onClick={e => removeFileFromServer('eductions_marksheet', educationProofDocuments[index].id)}><img src="/front/images/delete.png" /></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                            }
                                                                        </>
                                                                    ))
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="form-group mb-2">
                                                                <label className="form-label">Languages should know <span className="starr">*</span></label>
                                                                <Select
                                                                    options={allLanguageListOption}
                                                                    isMulti
                                                                    value={selectedOptionsLanguage}
                                                                    onChange={handleSelectChangeLanguage}
                                                                    placeholder="Select options..."
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 mt-3">
                                                        <button type='submit' className="batn batn-rounded rounded-orange mr-2">Save</button>
                                                        <button type='button' onClick={() => seteditSectionTab(false)} className="batn batn-rounded rounded-orange mr-2">Cancel</button>
                                                    </div>

                                                </div>
                                            </Form>
                                        </div>
                                    }
                                </div>
                            </div>
                        }


                         {/* Qulification details */}
                         {
                            (userdetails.user_type_role == 'jobseeker') &&
                            <div className="card-box license-sec tfn-sec mb-3">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h3 className="mb-3">Qulification</h3>
                                    {/* {editAccessibility && <div className="editbtn" onClick={() => seteditSectionTab(editSectionTab == 'education_and_qualification' ? false : 'education_and_qualification')}><img src="/front/images/edit.png" /></div>} */}
                                    <div className='d-flex align-items-center'>
                                        <div className="d-flex" >
                                            {editAccessibility && <div className="editbtn" onClick={() => seteditSectionTab(editSectionTab == 'qualification_section' ? false : 'qualification_section')}><img src="/front/images/edit.png" /></div>}
                                        </div>
                                        <div className='d-flex align-items-center'>
                                            {/* {userVeri.education_and_qualification ? <><span className='text-green'>Verified</span></> : <><span className='text-red'>Not Verified</span></>} */}
                                            <div className="disableprofilewrapinner border-0 userdtl">
                                                {
                                                    userVeri.qualification_details ?
                                                        <><img className='veriunveri-images' src="/front/images/vfy.png" data-toggle="tooltip" data-placement="top" title="Verified"></img></>
                                                        :
                                                        <><img className='veriunveri-images' src="/front/images/uvfy.png" data-toggle="tooltip" data-placement="top" title="Your Profile is in Review"></img></>
                                                }
                                                <input
                                                    type="checkbox"
                                                    checked={userVeri.qualification_details}
                                                    onChange={() => userDetailsVelidationFun('qualification_details')}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="editblock">
                                    <div className="editblockinner d-flex align-items-start">
                                        <h4>
                                            {/* <div><span className="mr-2 firstcoldtl">Highest Qualification :</span><span className="text-capitalize">{userdetails.highest_qualification_text ? userdetails.highest_qualification_text : '..........'}</span></div> */}

                                            <div className="d-flex align-items-center justify-content-between">
                                                <span className="mr-2 firstcoldtl">Qulification :</span>
                                                <div className="text-capitalize">
                                                    {
                                                        selectedOptionsQulification.length < 2 &&
                                                        selectedOptionsQulification.map((item, index) => (
                                                            <>{item.label} </>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                            <p>
                                                {
                                                    selectedOptionsQulification.length > 1 &&
                                                    selectedOptionsQulification.map((item, index) => (
                                                        <><span className='text-capitalize'> {item.label} </span>{index == selectedOptionsEductions.length - 1 ? '' : ','} </>
                                                    ))
                                                }
                                            </p>
                                           
                                        </h4>
                                    </div>
                                    {editSectionTab == 'qualification_section' &&
                                        <div className="editblockinnerbtm">
                                            <Form noValnoValidate validated={validated} onSubmit={onFormSubmit} >
                                                <div className="row">
                                                    <div className="row mb-2">

                                                        <div className="col-md-12">
                                                            <div className="form-group mb-2 taxtype">
                                                                <label className="form-label">Education <span className="starr">*</span></label>
                                                                <Select
                                                                    className={`react_select ${isAddedQulification ? 'bordercss' : ''}`}
                                                                    options={allQualificationListOptions}
                                                                    isMulti
                                                                    value={selectedOptionsQulification}
                                                                    onChange={handleSelectChangeQulification}
                                                                    placeholder="Select options..."
                                                                    styles={customStyles}
                                                                />
                                                                {
                                                                    (selectedOptionsQulification.length > 0) &&
                                                                    selectedOptionsQulification.map((item, index) => (
                                                                        <>
                                                                            {
                                                                                (!qualificationProofDocuments[index]) ?
                                                                                    <>
                                                                                        {
                                                                                            <div className="form-group identityproof stepscont mt-2">
                                                                                                <div className="customuploadsec">
                                                                                                    {!fileLoadingContent && <input type="file" accept="image/x-png,image/jpg,image/jpeg ,application/pdf"
                                                                                                        onChange={(e) => userFileUploadToServerVisaFun(`eductions_marksheet`, e.target.files[0], item.value)}
                                                                                                    ></input>}
                                                                                                    <div className="customuploadinner text-center">
                                                                                                        <div className="my-4"><img src="/front/images/uploadicon.png" className="mt-4"></img></div>
                                                                                                        <p className="mb-0">{fileLoadingContent ? `Docuemnt is uploading.....` : `Document upload for ${item.label}`}</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        }
                                                                                        <p className="mt-2 purpleclr font12">Accept only jpg, jpeg, png, doc and pdf files (Max file size 1 MB)</p>
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        <div className="documentwrap d-flex align-items-center mt-2 mb-2">
                                                                                            <a className='document_list' target="_blank" href={'https://squadgoo.com.au/api.squadgoo.com.au/' + qualificationProofDocuments[index].document_url}>
                                                                                                <p>Qualification proof {index + 1} - {item.label}</p>
                                                                                            </a>
                                                                                            <div className="documentwrapinner d-flex align-items-center">
                                                                                                <span className='cursor_pointer ml-2 editfile' onClick={e => editFileFromServer('qualification_proof', qualificationProofDocuments[index].id)}><img src="/front/images/edit.png" /></span>
                                                                                                <span className='cursor_pointer ml-2' onClick={e => removeFileFromServer('qualification_proof', qualificationProofDocuments[index].id)}><img src="/front/images/delete.png" /></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                            }
                                                                        </>
                                                                    ))
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 mt-3">
                                                        <button type='submit' className="batn batn-rounded rounded-orange mr-2">Save</button>
                                                        <button type='button' onClick={() => seteditSectionTab(false)} className="batn batn-rounded rounded-orange mr-2">Cancel</button>
                                                    </div>

                                                </div>
                                            </Form>
                                        </div>
                                    }
                                </div>
                            </div>
                        }




                    </>
            }

        </>
    );
}
export default UserBasicDetails;