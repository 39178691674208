import React, { useState, useEffect, Component } from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink, useParams, useNavigate, Link } from 'react-router-dom';
import { Form, Button, Col, InputGroup, Modal, Row } from 'react-bootstrap';

import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';
//hideAndSeekMyRequestModel
//import SuppReqConverationComp from '../admin/superadmin/supcomp/SuppReqConverationComp';
import SuppReqConverationComp from './SuppReqConverationComp';
import RequestStatus  from '../json/RequestStauts.json'

const MyRequests = () => {
   const userdetails = JSON.parse(localStorage.getItem("squadGologinSession"));
   const typeofpriority = ['High', 'Medium', 'Low'];
   //const statusType2 = ['All', 'Open', 'In Progress', 'Closed'];
   const statusType = RequestStatus;
   
   const [isModalShow , setIsModalShow] = useState(false);
   const [editDataID, setEditDataID] = useState(0);
   const [referenceID, setreferenceID] = useState(0);
   const [myRequestDataFormArr, setmyRequestDataFormArr] = useState({
      title: '',
      description: '',
      priority: 'High',
   });


   const [reportFrom, setreportFrom] = useState('init_support_request');
   useEffect(() => {
      // Parse the URL query parameters
      const queryParams = new URLSearchParams(window.location.search);
      const action = queryParams.get('action');
      const reference_id = queryParams.get('reference_id');
      if (action) {
         if (action == 'new_rquest') {
            setHideAndSeekMyRequestModel(true);
            setEditDataID(0);
            setreferenceID(reference_id);
            setreportFrom('chat_support_request');
         }
      }
   }, []);

   
   const [myRequestDataArr, setmyRequestDataArr] = useState([]);
   const [myRequestDataArrCopy, setmyRequestDataArrCopy] = useState([]);
   const [screenLoader, setScreenLoader] = useState(true);


   const ticketRemove = (id) => {
      (async () => {
         try {
            const formData = new FormData();

            formData.append('request_id', id);
            formData.append('login_token', userdetails.login_token);
            axios.post('https://squadgoo.com.au/api.squadgoo.com.au/requested_ticket_remove', formData)
               .then((response) => {
                  if (response.status == 200 && response.data.status == 1) {
                     toast.success(response.data.message);
                     get_requesteddata();
                     setIsModalShow(false);
                  }
               })
               .catch((error) => { console.error(error); });

         } catch (error) { console.log(error); }
      })();
   }
   
   
   const get_requesteddata = () => {
      (async () => {
         try {
            const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/get_myrequest/" + userdetails.login_token);
            setScreenLoader(false);
            if (resData.data.status == 1) {
               setmyRequestDataArr(resData.data.data);
               setmyRequestDataArrCopy(resData.data.data);
             
            }
         } catch (error) { console.log(error); }
      })();
   }

   useEffect(() => { if (userdetails) { get_requesteddata();  }  }, []);

   const [hideAndSeekMyRequestModel, setHideAndSeekMyRequestModel] = useState(false);
   const [validated, setValidated] = useState(false);

   const onFinalSubmit = async (event) => {
      event.preventDefault();
      const form = event.currentTarget;
      if (form.checkValidity() === false) { event.preventDefault(); event.stopPropagation(); }
      else {
         try {
            const formData = new FormData();
            formData.append('title', event.target.title.value);
            formData.append('description', event.target.description.value);
            formData.append('priority', 'High');
            formData.append('login_token', userdetails.login_token);
            formData.append('report_form', reportFrom);
            formData.append('edit_id', editDataID);
            formData.append('reference_id', referenceID);//reference_id referenceID
            axios.post('https://squadgoo.com.au/api.squadgoo.com.au/add_support_new_request', formData)
               .then((response) => {
                  if (response.status == 200 && response.data.status == 1) {
                     toast.success(response.data.message);
                     setHideAndSeekMyRequestModel(false)
                     get_requesteddata();
                     setmyRequestDataFormArr({
                        title: '',
                        description: '',
                        priority: 'High',
                     })
                  }
                  setEditDataID(0);
               })
               .catch((error) => { console.error(error); });
         } catch (error) { console.log(error); }
      }
      setValidated(true);
   };


   const handleInput = (event) => {
      // setSearchTerm(event.target.value);
      let term = event.target.value;
      if (term.length > 0 && term != 'All') {
         const filteredData = myRequestDataArr.filter((item) =>
            item.id.toLowerCase().includes(term.toLowerCase()) ||
            item.id.toLowerCase().includes("#"+term.toLowerCase()) ||
            item.status.toLowerCase().includes(term.toLowerCase()) ||
            item.title.toLowerCase().includes(term.toLowerCase()) ||
            item.description.toLowerCase().includes(term.toLowerCase())
         );
         setmyRequestDataArr(filteredData)
      } else {
         setmyRequestDataArr(myRequestDataArrCopy)
      }
   };

   const handleInputByStatus = (event) => {
      // setSearchTerm(event.target.value);
      let term = event.target.value;
      //alert(term);
      if (term.length > 0 && term != 'All') {
         const filteredData = myRequestDataArrCopy.filter((item) =>
               item.status.toLowerCase().includes(term.toLowerCase())
         );
         setmyRequestDataArr(filteredData)
      } else {
         setmyRequestDataArr(myRequestDataArrCopy)
      }
   };


   const getMyRequestData = (id, data) => {
      setEditDataID(id)
      setmyRequestDataFormArr({
         title: data.title,
         description: data.description,
         priority: data.priority,
      })
      setHideAndSeekMyRequestModel(true);
   }


   /*-----------------------*/
   const [isShowDescription, setisShowDescription] = useState(false);
   const [sendingData, setsetSendingData] = useState([]);
   const setisShowDescriptionFun = () => {
      setisShowDescription();
      get_requesteddata();
   }
   const getMyRequestDataView = (id, data) => {
      setsetSendingData(data);
      setisShowDescription(true);
   }

   const truncateText = (text, charLimit) => {
      if (text.length > charLimit) {
         return text.slice(0, charLimit) + '...'; // Slice the text and add "..."
       }
       return text;
      // const words = text.split(' '); // Split the text into words
      // if (words.length > wordLimit) {
      //   return words.slice(0, wordLimit).join(' ') + '...'; // Join the first `wordLimit` words and add "..."
      // }
      // return text; // Return the full text if it's within the word limit
    };


   return (
      <>
        {/* <ToastContainer />*/}
        
         <div className="dashboarwrap">
            <div className="pagebanner">
               <div className="container">
                  <div className="row">
                     <div className="col-md-12">
                        <div className="d-flex align-items-center justify-content-between"><h2>My Requests</h2>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="jobbtmsec py-5 helppage">
               <div className="container">
                  <div className="row">
                     {
                        isShowDescription ?
                           <><div className="reportblock">
                              <a className="nav-link active p-0 mb-2" onClick={() => setisShowDescriptionFun(false)}>
                                 <span className="back_to_hist1 cursor_pointer"><i className="fa fa-chevron-left" aria-hidden="true"></i>
                                    Back to List
                                 </span>
                              </a>
                              <SuppReqConverationComp data={sendingData} />
                           </div>
                           </>
                           :
                           <>

                              <div className="col-md-12">
                                 <div className="d-flex justify-content-end supportbuttonwrap mb-4">
                                    <button className="batn batn-orange" onClick={() => { setHideAndSeekMyRequestModel(true); setEditDataID(0); }} >+ New Requst</button>
                                 </div>
                                 <div className="box mb-4">

                                    <div className="table_filter mb-2 d-flex align-items-center justify-content-between commonform">
                                       <div className="tblsearch">
                                          <input type="text" className="form-control"
                                             placeholder="Search..."
                                             onChange={handleInput}
                                          />
                                       </div>
                                       <div className="tblrightfilters d-flex align-items-center">
                                          <select className="form-control ml-3 text-capitalize" onChange={handleInputByStatus} >

                                             {
                                                statusType.map((item, index) => (
                                                   <option key={item.value} value={item.key}>{item.value}</option>
                                                ))
                                             }
                                          </select>
                                       </div>
                                    </div>
                                    <div className="table-responsive">
                                       {
                                          screenLoader ?
                                             <>
                                                <div className='loader_container'>
                                                   <p className='text-center'><img className='width-100 loader_image' src={'/front/images/loader.gif'} /></p>
                                                </div>
                                             </>
                                             :
                                             <>
                                                <table className="table admintbl profiletbl">
                                                   <thead>
                                                      <tr>
                                                         <th>Ticket id</th>
                                                         <th>Source</th>
                                                         <th>Title</th>
                                                         <th>Description</th>
                                                         <th>Status</th>
                                                         <th>Request Date</th>
                                                         <th>Action</th>
                                                      </tr>
                                                   </thead>
                                                   <tbody>
                                                      {
                                                         myRequestDataArr.length ?
                                                            myRequestDataArr.map((item, index) => (
                                                               <tr>
                                                                  <td className="text-capitalize">#{item.id}</td>
                                                                  <td>
                                                                     {
                                                                        item.report_form == 'chat_support_request' ? 
                                                                        'Chat' :
                                                                        item.report_form == 'review_support_request' ? 
                                                                        'Review' :
                                                                        item.report_form == 'request_a_callback' ? 
                                                                        'Callback' :
                                                                        'Ticket'
                                                                     }
                                                                  </td>
                                                                  <td className='text-capitalize'>{truncateText(item.title, 20)}</td>
                                                                  <td>{truncateText(item.description, 50)}</td>
                                                                  <td>
                                                                     {
                                                                        item.status == 'Awating' ?
                                                                        <>In Progress</> :
                                                                        item.status == 'Solved' ?
                                                                        <>Closed</> : 
                                                                        <>Open</>
                                                                     }
                                                                  </td>
                                                                  <td>{item.created_date}</td>
                                                                  <td>
                                                                     <div className='d-flex'>
                                                                        <Link onClick={() => { getMyRequestDataView(item.id, item) }} className=" " ><img src="/front/images/Show.svg" /></Link>
                                                                        <Link onClick={() => { setIsModalShow(item.id) }}  ><i class="faicon fa fa-trash ml-5" aria-hidden="true"></i></Link>
                                                                     </div>

                                                                  </td>
                                                               </tr>
                                                            ))
                                                            :
                                                            <>
                                                               <tr>
                                                                  <td className=" text-center" colSpan={8}>No information available to display</td>
                                                               </tr>
                                                            </>
                                                      }
                                                   </tbody>
                                                </table>
                                             </>
                                       }


                                    </div>
                                 </div>
                              </div>
                           </>
                     }
                  </div>
               </div>
            </div>



            <Modal show={hideAndSeekMyRequestModel} onHide={() => setHideAndSeekMyRequestModel(false)}>
               <div className="modal-dialog1">
                  <div className="modal-content">
                     <div className="modal-body">
                        <button type="button" className="btn-close" onClick={() => setHideAndSeekMyRequestModel(false)} ></button>
                        <h3>Support</h3>
                        <div className="commonform">
                           <Form noValidate validated={validated} onSubmit={onFinalSubmit} >
                              <div className="row">
                                 <div className="col-md-12 form-group mb-2">
                                    <label className="form-label" htmlFor="lastName1">Title <span className="starr">*</span></label>
                                    <input className="form-control" name='title' onChange={(e) => setmyRequestDataFormArr({ ...myRequestDataFormArr, ['title']: e.target.value })} value={myRequestDataFormArr.title} required={true} type="text" placeholder="Enter title" />
                                 </div>
                                 <div className="col-md-12 form-group mb-2">
                                    <label className="form-label" htmlFor="exampleInputEmail1">Description <span className="starr">*</span></label>
                                    <textarea className="form-control" name='description' onChange={(e) => setmyRequestDataFormArr({ ...myRequestDataFormArr, ['description']: e.target.value })} value={myRequestDataFormArr.description} maxLength={5000} required={true} rows="5" placeholder="Enter description"></textarea>
                                 </div>

                                 {
                                 (userdetails.user_type == 1) ?
                                 <div className="col-md-12 form-group mb-2">
                                    <label className="form-label" htmlFor="phone">Priority <span className="starr">*</span></label>
                                    <select className="form-control" name='priority' onChange={(e) => setmyRequestDataFormArr({ ...myRequestDataFormArr, ['priority']: e.target.value })} required={true} >
                                       {
                                          typeofpriority.map((item, index) => (
                                             <option selected={myRequestDataFormArr.priority == item ? true : false} key={item} value={item}>{item}</option>
                                          ))
                                       }
                                    </select>
                                 </div>
                                 :
                                 <input type='hidden' name='priority' value={'High'}  />
                                 }
                                 <div className="col-md-12 form-group mt-3">
                                    <button type='submit' className="batn batn-orange w-100">Send</button>
                                 </div>

                              </div>
                           </Form>
                        </div>
                     </div>
                  </div>
               </div>
            </Modal>

            <Modal className="modal fade ask-jobdeclinedmodle"  show={isModalShow} onHide={() => setIsModalShow(false)} >
               <div className="modal-dialog1">
                  <div className="modal-content">
                     <div className="modal-body">
                        <button type="button" className="btn-close" onClick={() => setIsModalShow(false)} ></button>

                        <div style={{textAlign: "center" , fontSize: '55px'}}  >
                           <i class="fa fa-warning text-danger"></i>
                        </div>
                        <h5 className="text-center mb-2">Do you want to remove this ticket?</h5>
                        <p>Are you sure you want to remove this ticket? This action cannot be undone.</p>
                        <div className="btnsgroup text-center">
                           <Link onClick={() => ticketRemove(isModalShow)}  className="batn batn-orange" >Yes</Link>
                           <Link  onClick={() => setIsModalShow(false)}  className="batn batn-orange batn-border">No</Link>
                        </div>
                     </div>
                  </div>
               </div>
            </Modal>



         </div>
      </>
   );
}
export default MyRequests;