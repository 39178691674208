import { BrowserRouter as Router, Routes, Route ,  Link , useParams ,useNavigate  }  from 'react-router-dom';
import React,{Component, useEffect} from 'react';  
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';  

const options = {
  margin: 10,
  responsiveClass: true,
  nav: false,
  dots: false,
  autoplay: true,
  autoplayTimeout:1000,
  responsive: {
      0: {
          items: 2,
      },
      400: {
          items: 3,
      },
      600: {
          items: 4,
      },
      700: {
          items: 5,
      },
      1000: {
          items: 7,

      },
      1200: {
        items: 10,

    }
  },
};


const optionsn = {
  margin: 20,
  responsiveClass: true,
  nav: false,
  dots: false,
  autoplay: true,
  autoplayTimeout:1000,
  items: 1,
  responsive: {

      600: {
          items: 1,
      },
      1200: {
        items: 2,

    }
  },
};










const Home = () => {

    // const slidercounter  = () =>{
  
    //     var totalItems = document.querySelectorAll('.testslide').length;
    //     console.log(totalItems);
    //     var currentIndex = document.querySelectorAll('.active').index() + 1;
    //     document.querySelectorAll('.num').html(''+currentIndex+'/'+totalItems+'');
    //     var  owl = document.querySelectorAll('.testislider');
    //     owl.owlCarousel();
    //     document.querySelectorAll('.owl-prev').click(function() {
    //         currentIndex = document.querySelectorAll('testslide .owl-item.active').index() + 1;
    //         document.querySelectorAll('.num').html(''+currentIndex+'/'+totalItems+'');
    //     })
    //     }
        

    // useEffect(() => {
    //     slidercounter();
    //   }, []);
      
    return (
     <> 

<div className="mainpages">
        <div className="homebanner">
          <div className="container">
              <div className="row align-items-center">
                 <div className="col-md-6">
                    <div className="banner-left">
                        <h1>Welcome to <span>Modern</span> Workplace</h1>
                        <p>“SQUAD GOO” is the one place solution for your hiring and job searching. With an aim to reduce the time and money taken for all recruiter, jobseekers and individuals.  </p>
                        <Link to="/register" className="batn batn-orange">Join Us</Link>
                    </div>
                 </div>
                 <div className="col-md-6">
                 <video  controls poster="front/images/videoimg.jpg">
                              <source src="/front/images/Office Work Free Stock Video _ One1.mp4" type="video/mp4"></source>
                              </video>
                 </div>
              </div>
          </div>
        </div>


        {/* <div className="homebanner">
          <div className="container">
              <div className="row align-items-center">
                 <div className="col-md-6">
                    <div className="banner-left">
                        <h1>Welcome to <span>Modern</span> Workplace</h1>
                        <p>“SQUAD GOO” is the one place solution for your hiring and job searching. With an aim to reduce the time and money taken for all recruiter, jobseekers and individuals.  </p>
                        <a href="#" className="batn batn-orange">Join Us</a>
                    </div>
                 </div>
                 <div className="col-md-6">
                   <img src="/front/images/homebannerimg.png"></img>
                 </div>
              </div>
          </div>
        </div> */}


        {/* <div className="home_cat">
          
          <div className="container">
             <h2 className="sectionhead">Most demanding jobs categories</h2>
              <div className="row">
                 <div className="col-md-2">
                    <a href="#" className="categery_block">
                        <img src="/front/images/cat2.svg"></img>
                        <p>Tele Caller</p>
                    </a>
                 </div>
                 <div className="col-md-2">
                    <a href="#" className="categery_block">
                        <img src="/front/images/cat2.svg"></img>
                        <p>Delivery Boy</p>
                    </a>
                 </div>
                 <div className="col-md-2">
                    <a href="#" className="categery_block">
                        <img src="/front/images/cat3.svg"></img>
                        <p>Sales</p>
                    </a>
                 </div>
                 <div className="col-md-2">
                    <a href="#" className="categery_block">
                        <img src="/front/images/cat11.svg"></img>
                        <p>Drive</p>
                    </a>
                 </div>
                 <div className="col-md-2">
                    <a href="#" className="categery_block">
                        <img src="/front/images/cat5.svg"></img>
                        <p>Finance</p>
                    </a>
                 </div>
                 <div className="col-md-2">
                    <a href="#" className="categery_block">
                        <img src="/front/images/cat6.svg"></img>
                        <p>Retail</p>
                    </a>
                 </div>
                 <div className="col-md-2">
                    <a href="#" className="categery_block">
                        <img src="/front/images/cat7.svg"></img>
                        <p>Driver</p>
                    </a>
                 </div>
                 <div className="col-md-2">
                    <a href="#" className="categery_block">
                        <img src="/front/images/cat8.svg"></img>
                        <p>Doctor</p>
                    </a>
                 </div>
                 <div className="col-md-2">
                    <a href="#" className="categery_block">
                        <img src="/front/images/cat9.svg"></img>
                        <p>Engineer</p>
                    </a>
                 </div>
                 <div className="col-md-2">
                    <a href="#" className="categery_block">
                        <img src="/front/images/cat10.svg"></img>
                        <p>Transport</p>
                    </a>
                 </div>
                 <div className="col-md-2">
                    <a href="#" className="categery_block">
                        <img src="/front/images/cat11.svg"></img>
                        <p>Date Entry</p>
                    </a>
                 </div>
                 <div className="col-md-2">
                    <a href="#" className="categery_block">
                        <img src="/front/images/cat12.svg"></img>
                        <p>More Jobs</p>
                    </a>
                 </div>
                 

              </div>
          </div>
        </div> */}

<div className="home_cat">
          
          <div className="container">
             <h2 className="sectionhead text-center">Squad Goo features</h2>
              <div className="row">
                 <div className="col-md-2">
                    <a href="/aboutus" className="categery_block">
                     <div className="catimg">
                       <img src="/front/images/feature1.png"></img>
                       </div>
                        <p>Verified profiles</p>
                    </a>
                 </div>
                 <div className="col-md-2">
                    <a href="/aboutus" className="categery_block">
                     <div className="catimg">
                     <img src="/front/images/feature2.png"></img>
                     </div>
                        <p>Pay per match/non-subscription model</p>
                    </a>
                 </div>
                 <div className="col-md-2">
                    <a href="/aboutus" className="categery_block">
                     <div className="catimg">
                     <img src="/front/images/feature3.png"></img>
                     </div>
                        <p>Data colletion safety/privacy</p>
                    </a>
                 </div>
                 <div className="col-md-2">
                    <a href="/aboutus" className="categery_block">
                     <div className="catimg">
                     <img src="/front/images/feature4.png"></img>
                     </div>
                        <p>Manual search</p>
                    </a>
                 </div>
                 <div className="col-md-2">
                    <a href="/aboutus" className="categery_block">
                     <div className="catimg">
                     <img src="/front/images/feature5.png"></img>
                     </div>
                        <p>Quick search</p>
                    </a>
                 </div>
                 <div className="col-md-2">
                    <a href="/aboutus" className="categery_block">
                     <div className="catimg">
                     <img src="/front/images/feature6.png"></img>
                     </div>
                        <p>Full refunds policy</p>
                    </a>
                 </div>
                 <div className="col-md-2">
                    <a href="/aboutus" className="categery_block">
                     <div className="catimg">
                     <img src="/front/images/feature7.png"></img>
                     </div>
                        <p>Matching policy</p>
                    </a>
                 </div>
                 <div className="col-md-2">
                    <a href="/aboutus" className="categery_block">
                     <div className="catimg">
                     <img src="/front/images/feature8.png"></img>
                     </div>
                        <p>Referral bonuses</p>
                    </a>
                 </div>
                 <div className="col-md-2">
                    <a href="/aboutus" className="categery_block">
                     <div className="catimg">
                     <img src="/front/images/feature9.png"></img>
                     </div>
                        <p>Compensation policy</p>
                    </a>
                 </div>
                 <div className="col-md-2">
                    <a href="/aboutus" className="categery_block">
                     <div className="catimg">
                     <img src="/front/images/feature10.png"></img>
                     </div>
                        <p>One place solution</p>
                    </a>
                 </div>
                 <div className="col-md-2">
                    <a href="/aboutus" className="categery_block">
                     <div className="catimg">
                     <img src="/front/images/feature11.png"></img>
                     </div>
                        <p>Contact support features</p>
                    </a>
                 </div>
                 <div className="col-md-2">
                    <a href="/aboutus" className="categery_block">
                     <div className="catimg">
                     <img src="/front/images/feature12.png"></img>
                     </div>
                        <p>Conversation recording</p>
                    </a>
                 </div>
                 <div className="col-md-2">
                    <a href="/aboutus" className="categery_block">
                     <div className="catimg">
                     <img src="/front/images/feature13.png"></img>
                     </div>
                        <p>Quick verification</p>
                    </a>
                 </div>
                 <div className="col-md-2">
                    <a href="/aboutus" className="categery_block">
                     <div className="catimg">
                     <img src="/front/images/feature14.png"></img>
                     </div>
                        <p>Worldwide use case</p>
                    </a>
                 </div>
                 <div className="col-md-2">
                    <a href="/aboutus" className="categery_block">
                     <div className="catimg">
                     <img src="/front/images/feature15.png"></img>
                     </div>
                        <p>Account safety</p>
                    </a>
                 </div>
                 <div className="col-md-2">
                    <a href="/aboutus" className="categery_block">
                     <div className="catimg">
                     <img src="/front/images/feature16.png"></img>
                     </div>
                        <p>Live map functions</p>
                    </a>
                 </div>
                 <div className="col-md-2">
                    <a href="/aboutus" className="categery_block">
                     <div className="catimg">
                     <img src="/front/images/feature17.png"></img>
                     </div>
                        <p>Multiple role function</p>
                    </a>
                 </div>
                 <div className="col-md-2">
                    <a href="/aboutus" className="categery_block">
                    <div className="catimg">
                     <img src="/front/images/feature18.png"></img>
                     </div>
                        <p>Reviews</p>
                    </a>
                 </div>
                 <div className="col-md-12 text-center mt-4"><a href="/aboutus" className="batn batn-orange">Know More</a></div>
                 
                 

              </div>
          </div>
        </div>











        <div className="trustsec">
          
          <div className="container">
            
              <div className="row align-items-center">
                 <div className="col-md-4 trust-left">       
                        <img src="/front/images/trustimg.png" className="img-fluid"></img>
                    </div>
                
                 <div className="col-md-8 trust-right">
                     <h2 className="sectionhead">Solutions we have for you</h2>
                     <div className="tabwarperr mb-2 ">
                        <ul className="nav nav-pills mb-3 comontabs" id="pills-tab" role="tablist">
                           <li className="nav-item" role="presentation"><button className="nav-link active" id="pills-company-tab" data-bs-toggle="pill" data-bs-target="#pills-company" type="button" role="tab" aria-controls="pills-company" aria-selected="true">Company</button></li>
                           <li className="nav-item" role="presentation"><button className="nav-link" id="pills-individual-tab" data-bs-toggle="pill" data-bs-target="#pills-individual" type="button" role="tab" aria-controls="pills-individual" aria-selected="false">Individual</button></li>
                           <li className="nav-item" role="presentation"><button className="nav-link" id="pills-jobseeker-tab" data-bs-toggle="pill" data-bs-target="#pills-jobseeker" type="button" role="tab" aria-controls="pills-jobseeker" aria-selected="false">Jobseeker</button></li>
                        </ul>
                     </div>
                     <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade active show" id="pills-company" role="tabpanel" aria-labelledby="pills-company-tab">
                           
                              {/* front/images/checkrounded.png */}
                           <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>Few steps to complete and eligible employees Infront of your eyes in few sec.</span></p>
                                       <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span> All jobseekers go through profile verification.</span></p>
                                       <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>All jobseeker’s resume and references are cross checked and approved before making their profile active.</span></p>
                                       <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>We charge lowest of fee only when you and applicant connect with each other.</span></p>
                                       <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>We provide chat, video, file transfer function available so taking interviews will be quick and easy.</span></p>
                                       <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>You can also see the reviews of jobseekers posted by previous recruiter.</span></p>
                                       <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>Once a jobseeker gets connected with you, their availability are changes making them unavailable by system for other job offers.</span></p>
                                       <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>Your contact and company details are not visible to Jobseekers until they accept your offer.</span></p>
                                       <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>Jobseekers are shown matching your requirements, such as location, distance to work, qualification.</span></p>
                                       <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>We reduce the acceptance rating of jobseekers, if they reject 100% matched job offers and won’t come at front while you search.</span></p>
                                       <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>Secure communication channel which are recorded for any issues in future.</span></p>
                                       <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>Full refund policy if your selected jobseeker does not show up to work (we will verify first)</span></p>
                                       <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>You can hire national and international jobseekers for any face-to-face job.</span></p>
                                       <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>You don’t need to fill the same job ads again with our repost features.</span></p>
                                       <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>You can see jobseekers profile as many as you like but can have match with only the number of jobseekers you are looking after.</span></p>
                                       <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>Any inactive or low acceptance rating jobseekers are removed from our platforms.</span></p>
                                       <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>You can hire both contractor and employees using filter. </span></p>
                        </div>


                        <div className="tab-pane fade" id="pills-individual" role="tabpanel" aria-labelledby="pills-individual-tab">
                           <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>We charge you one-time fee only when you and contractor start chatting with each other or accepts your offer.</span></p>
                                       <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>Our matching fee are minimal, meaning any one can afford it.</span></p>
                                       <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>We verify everyone on our platform, even their availability, reviews, and history.</span></p>
                                       <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>If contractor can’t do the job, then you will be fully refunded with the amount you spend for our service. (TERM AND CONDITION APPLIES).</span></p>
                                       <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>You pay for the service yourself directly to the contractor, we won’t hassle you in the middle.</span></p>
                                       <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>Immediate hiring facility with same day or hour matching.</span></p>
                                       <                            p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>With our QUICK SEARCH feature, you will be able to get someone to fix the problem immediately.</span></p>
                        </div>

                        <div className="tab-pane fade" id="pills-jobseeker" role="tabpanel" aria-labelledby="pills-jobseeker-tab">
                        <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>You create your profile once and upon verification, you start receiving job offers.</span></p>
<p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>You can directly chat and take calls to job providers immediately once you accept their offer.</span></p>
<p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>We offer both “MANNUAL “AND “QUICK “job offer features. </span></p>
<p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>Manual job offer is for your permanent job according to you profile match.</span></p>
<p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>Quick job offers are for instant jobs same day once received.</span></p>
<p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>You can read the reviews of the company or individuals you are going to work with in advance.</span></p>
<p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>Salary and requirements information’s are provided to you in advance.</span></p>
<p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>You get compensation when a job provider offers you QUICK offer and you were not provided with job. (Terms and conditions apply)</span></p>
<p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>Referring a friend to us and once they get job offers, you get some bonus.</span></p>
<p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>Your details are fully secured and share according to your preferences.</span></p>
<p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>You can get job or be a contractor looking for contract on our system.</span></p>
                        </div>
                         
                     </div>
                     {/* register */}
                     {/* <a href="#" className="batn batn-orange">Join Us Now</a> */}
                     <Link to="/register" className="batn batn-orange">Join Us Now</Link>
                 </div>
               
                 

              </div>
          </div>
         </div>




         <div className="kistartdec">
          
          <div className="container">
            
              <div className="row">
                 <div className="col-md-5 kikstart-left">       
                        <img src="/front/images/kikstartimg.png" className="img-fluid"></img>
                    </div>
                
                 <div className="col-md-7 kiskstart-right">
                     <h2 className="sectionhead mb-4">Abou Us</h2>
                     <p>SQUAD GOO is a one place solution where we help connecting jobs and job providers. We cover every field like employees, contractor, sub-contractors, Businesses, and agencies. We only charge you once you have connection with your desire match, and our fees are super low.</p>
                     <p>SQUAD GOO is motivated to solve the connection problem in the labour market by providing everyone fair chance to improve their market. We ought to remove false ads, dormant applicants, fake reviews and resumes and references. Only fully verified users are given chance to use our services.</p>
                     <div className="counerinner d-flex">
                        <div className="countercol">
                            <h2>500+</h2>
                            <h4>Ready Job Vacancy</h4>
                        </div>
                        <div className="countercol">
                            <h2>10K+</h2>
                            <h4>Active Job Seekers</h4>
                        </div>
                        <div className="countercol">
                            <h2>100+</h2>
                            <h4>Incorporated Companies</h4>
                        </div>
                    </div>
                    <a href="/aboutus" className="batn batn-orange">Know More</a>
                 </div>
               
                 

              </div>
          </div>
         </div>

         <div className="testimonialsec">
            <div className="container">
               <div className="row">
                 <div className="col-md-6 testileft">
                    <h2 className="sectionhead">Meet some of our happy recruiters</h2>
                    
                    <OwlCarousel items={2}  
                        className="owl-theme testislider mb-4"  
                        {...optionsn} >  
                        <div className="testslide">
                          <div className="quoteimg"><img  className="img" src= {'front/images/quoteimg.png'}/></div>
                          <div className="d-flex rating mb-3">
                            <i className="fa fa-star mr-1" aria-hidden="true"></i><i className="fa fa-star mr-1" aria-hidden="true"></i><i className="fa fa-star mr-1" aria-hidden="true"></i><i className="fa fa-star mr-1" aria-hidden="true"></i><i className="fa fa-star-o" aria-hidden="true"></i>                            
                          </div>
                          <p>Nulla porta dolor. duis leo. in hac habitasse platea dictumst. in hac habitasse platea dictumst. maecenas nec odio et ante tincidunt tempus. sed in libero ut nibh placerat accumsan.</p>
                          <div className="testimeta d-flex align-items-center">
                               <img  src= {'front/images/profilepic.png'}/>
                               <div className="testimetainner">
                                   <h5>Stacy William</h5>
                                   <p>Teacher</p>
                               </div>
                          </div>
                        </div> 
                        <div className="testslide">
                          <div className="quoteimg"><img  className="img" src= {'front/images/quoteimg.png'}/></div>
                          <div className="d-flex rating mb-3">
                            <i className="fa fa-star mr-1" aria-hidden="true"></i><i className="fa fa-star mr-1" aria-hidden="true"></i><i className="fa fa-star mr-1" aria-hidden="true"></i><i className="fa fa-star mr-1" aria-hidden="true"></i><i className="fa fa-star-o" aria-hidden="true"></i>                            
                          </div>
                          <p>Nulla porta dolor. duis leo. in hac habitasse platea dictumst. in hac habitasse platea dictumst. maecenas nec odio et ante tincidunt tempus. sed in libero ut nibh placerat accumsan.</p>
                          <div className="testimeta d-flex align-items-center">
                               <img  src= {'front/images/profilepic.png'}/>
                               <div className="testimetainner">
                                   <h5>Stacy William</h5>
                                   <p>Teacher</p>
                               </div>
                          </div>
                        </div> 
                        <div className="testslide">
                          <div className="quoteimg"><img  className="img" src= {'front/images/quoteimg.png'}/></div>
                          <div className="d-flex rating mb-3">
                            <i className="fa fa-star mr-1" aria-hidden="true"></i><i className="fa fa-star mr-1" aria-hidden="true"></i><i className="fa fa-star mr-1" aria-hidden="true"></i><i className="fa fa-star mr-1" aria-hidden="true"></i><i className="fa fa-star-o" aria-hidden="true"></i>                            
                          </div>
                          <p>Nulla porta dolor. duis leo. in hac habitasse platea dictumst. in hac habitasse platea dictumst. maecenas nec odio et ante tincidunt tempus. sed in libero ut nibh placerat accumsan.</p>
                          <div className="testimeta d-flex align-items-center">
                               <img  src= {'front/images/profilepic.png'}/>
                               <div className="testimetainner">
                                   <h5>Stacy William</h5>
                                   <p>Teacher</p>
                               </div>
                          </div>
                        </div>  
                    </OwlCarousel> 
                    {/* <div className="num">
                       https://codepen.io/kumar_a/pen/QpdLqe
                    </div> */}
                    
                    

                 </div>
                 <div className="col-md-6 testiright">
                    <img  src= {'front/images/testiman.png'}/>
                 </div>
               </div>
            </div>
         </div>


         <div className="clientsecc text-center">
          <h3 className="subhead3">Trusted by <span>50+ companies</span> all over the country</h3>
            <div className="container-fluid">
            <OwlCarousel items={10}  
              className="owl-theme"  
              {...options} >  
              <div ><img  className="img" src= {'front/images/clilogo1.png'}/></div>  
              <div><img  className="img" src= {'front/images/clilogo2.png'}/></div>  
              <div><img  className="img" src= {'front/images/clilogo3.png'}/></div>  
              <div><img  className="img" src= {'front/images/clilogo4.png'}/></div>  
              <div><img className="img" src= {'front/images/clilogo5.png'}/></div>  
              <div><img className="img" src= {'front/images/clilogo6.png'}/></div>  
              <div><img className="img" src= {'front/images/clilogo7.png'}/></div>  
              <div><img className="img" src= {'front/images/clilogo8.png'}/></div>  
              <div><img className="img" src= {'front/images/clilogo9.png'}/></div>  
              <div><img className="img" src= {'front/images/clilogo10.png'}/></div>  
              <div ><img  className="img" src= {'front/images/clilogo1.png'}/></div>  
              <div><img  className="img" src= {'front/images/clilogo2.png'}/></div>  
              <div><img  className="img" src= {'front/images/clilogo3.png'}/></div>  
              <div><img  className="img" src= {'front/images/clilogo4.png'}/></div>  
              <div><img className="img" src= {'front/images/clilogo5.png'}/></div>  
              <div><img className="img" src= {'front/images/clilogo6.png'}/></div>  
              <div><img className="img" src= {'front/images/clilogo7.png'}/></div>  
              <div><img className="img" src= {'front/images/clilogo8.png'}/></div>  
              <div><img className="img" src= {'front/images/clilogo9.png'}/></div>  
              <div><img className="img" src= {'front/images/clilogo10.png'}/></div>  
          </OwlCarousel> 
            </div>
         </div>
        
        
        
        
        
        </div>
    
     </>
    );
  }
  export default Home;