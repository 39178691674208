import React, { useState, useEffect, Component } from 'react';
import SuperAdminSidebar from './superadmin_sidebar'

import UserBasicDetails from './supcomp/UserBasicDetails';
import UserDocumentList from './supcomp/UserDocumentList';
import UserSubscription from './supcomp/UserSubscription';
import UserAllPostedJobs from './supcomp/UserAllPostedJobs';
import UserProfileBanner from './supcomp/UserProfileBanner';
import UserChatCommunication from './supcomp/UserChatCommunication';

import UserChatHistory from './supcomp/UserChatHistory';
import AdminWallet from './supcomp/AdminWallet';


const RecruiterDetail = () => {

   const [currentActiveTab, setcurrentActiveTab] = useState('profile_tab');
   const hideAndShowTab = (tab) => {
      setcurrentActiveTab(tab)
   }

 

   return (
      <>
         <div className="dashboarwrap">
            <div className="adminwrap py-4">
               <div className="container">
                  <div className="row">
                     <div className="col-md-3 adminsidebar ">
                        <SuperAdminSidebar></SuperAdminSidebar>
                     </div>
                     <div className="col-md-9 admininfo ">
                        <div className="profilebanner adminjobseekerbanner box mb-3 bannerlinkabove">
                           <UserProfileBanner backcall={'recruiter'} />
                        </div>
                        <nav className="jobseekertabs">
                           <div className="nav nav-tabs" id="nav-tab" role="tablist">
                              <button className={`${currentActiveTab == 'profile_tab' ? 'active' : ''} nav-link`} id="profile-tab" onClick={() => hideAndShowTab('profile_tab')} ><i className="fa fa-user-o mr-2" aria-hidden="true"></i> Profile</button>
                              <button className={`${currentActiveTab == 'documentss_tab' ? 'active' : ''} nav-link`} id="documentss-tab" onClick={() => hideAndShowTab('documentss_tab')} ><i className="fa fa-file-text mr-2" aria-hidden="true"></i> Documents</button>
                              {/* <button className={`${currentActiveTab == 'subscriptions_tab' ? 'active' : ''} nav-link`} id="Subscriptions-tab"  onClick={() => hideAndShowTab('subscriptions_tab')}><i className="fa fa-credit-card mr-2" aria-hidden="true"></i> Subscriptions</button> */}
                              <button className={`${currentActiveTab == 'alljobsposts_tab' ? 'active' : ''} nav-link`} id="alljobsposts-tab" onClick={() => hideAndShowTab('alljobsposts_tab')}><i className="fa fa-bar-chart mr-2" aria-hidden="true"></i> All Jobs Posts</button>
                              <button className={`${currentActiveTab == 'chathistory_tab' ? 'active' : ''} nav-link`} onClick={() => hideAndShowTab('chathistory_tab')} id="chathistory-tab"><i className="fa fa-comment mr-2" aria-hidden="true"></i>Chats</button>
                              <button className={`${currentActiveTab == 'transecection_tab' ? 'active' : ''} nav-link`} onClick={() => hideAndShowTab('transecection_tab')} id="transecection-tab"><i className="fa fa-briefcase mr-2" aria-hidden="true"></i>Wallet</button>

                              {/* <button className={`${currentActiveTab == 'chatcommunication_tab' ? 'active' : ''} nav-link`} onClick={() => hideAndShowTab('chatcommunication_tab')} id="chathistory-tab"><i className="fa fa-comments-o" aria-hidden="true"></i></button> */}

                           </div>
                        </nav>
                        <div className="tab-content" id="nav-tabContent">
                           {currentActiveTab == 'profile_tab' && <div className="tab-pane1" id="profile" >
                              <UserBasicDetails />
                           </div>}
                           {currentActiveTab == 'documentss_tab' && <div className="tab-pane1" id="documentss" >
                              <UserDocumentList />
                           </div>}
                           {/* {currentActiveTab == 'subscriptions_tab' && <div className="tab-pane1" id="Subscriptions" >
                              <UserSubscription />
                           </div>} */}
                           {currentActiveTab == 'alljobsposts_tab' && <div className="tab-pane1" id="alljobsposts">
                              <UserAllPostedJobs />
                           </div>}

                           {currentActiveTab == 'chathistory_tab' && <div className="tab-pane1" id="chathistory" role="tabpanel" aria-labelledby="chathistory-tab">
                              <UserChatHistory />
                           </div>}

                           {currentActiveTab == 'chatcommunication_tab' && <div className="tab-pane1" id="chatcommunication" role="tabpanel" aria-labelledby="chatcommunication-tab">
                              <UserChatCommunication />
                           </div>}
                           {currentActiveTab == 'transecection_tab' && <div className="tab-pane1" id="transectiontab" role="tabpanel" aria-labelledby="transecection_tab">
                              <AdminWallet  user_type={2} />
                           </div>}


                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

      </>
   );
}
export default RecruiterDetail;