import React from 'react';
import { NavLink } from 'react-router-dom';


const ProfileSidebar = () => {

    const userdetails = JSON.parse(localStorage.getItem("squadGologin"));
    const validationdataCheckData = JSON.parse(localStorage.getItem("validationdataCheckData"));

    return (
        <>
            {
                userdetails.user_type == 2 ?
                    <>
                        <div className="card-box profilesidebar">
                            <ul>
                                {
                                    userdetails.user_type_role == 'rec_owners' ?
                                        <>
                                            <li><NavLink to="/recruiter/basic-detail">User's details {validationdataCheckData.basicdob_validation == 0 ? <span className="infowrap"><i className="fa fa-circle"></i><span className="onhoverinfo profile_incomplete">Please add basic details</span></span> : ''} </NavLink></li>
                                            <li><NavLink to="/recruiter/company-detail">Company details {validationdataCheckData.companydetails_validation == 0 ? <span className="infowrap"><i className="fa fa-circle"></i><span className="onhoverinfo profile_incomplete">Please fill all fields</span></span> : ''}</NavLink></li>
                                            <li><NavLink to="/recruiter/contact-detail">Contact details {validationdataCheckData.basiccontact_validation == 0 ? <span className="infowrap"><i className="fa fa-circle"></i><span className="onhoverinfo profile_incomplete">Please add contact details</span></span> : ''}</NavLink></li>
                                            <li><NavLink to="/recruiter/tax-information">Tax information {validationdataCheckData.basictaxtype_validation == 0 ? <span className="infowrap"><i className="fa fa-circle"></i><span className="onhoverinfo profile_incomplete">Please add tax information details</span></span> : ''}</NavLink></li>
                                            <li><NavLink to="/recruiter/profile-summary">Biograph/Bio {validationdataCheckData.profilesummery_validation == 0 ? <span className="infowrap"><i className="fa fa-circle"></i><span className="onhoverinfo profile_incomplete">Please add Profile Summary</span></span> : ''}</NavLink></li>
                                            <li><NavLink to="/recruiter/social-media-settings">Compnay Social media {validationdataCheckData.socialmedia_validation == 0 ? <span className="infowrap"><i className="fa fa-circle"></i><span className="onhoverinfo profile_incomplete">Please fill all fields</span></span> : ''}</NavLink></li>
                                            <li><NavLink to="/recruiter/password-setting">Password</NavLink></li>
                                        </>
                                        :
                                        userdetails.user_type_role == 'rec_reprasentative' ?
                                            <>
                                                <li><NavLink to="/recruiter/basic-detail">Representative details {validationdataCheckData.basicdob_validation == 0 ? <span className="infowrap"><i className="fa fa-circle"></i><span className="onhoverinfo profile_incomplete">Please add basic details</span></span> : ''} </NavLink></li>
                                                <li><NavLink to="/recruiter/directors-details">Director's details  {validationdataCheckData.directordetails_validation == 0 ? <span className="infowrap"><i className="fa fa-circle"></i><span className="onhoverinfo profile_incomplete">Please fill all fields</span></span> : ''} </NavLink></li>
                                                <li><NavLink to="/recruiter/company-detail">Company details  {validationdataCheckData.companydetails_validation == 0 ? <span className="infowrap"><i className="fa fa-circle"></i><span className="onhoverinfo profile_incomplete">Please fill all fields</span></span> : ''} </NavLink></li>
                                                <li><NavLink to="/recruiter/contact-detail">Contact details {validationdataCheckData.basiccontact_validation == 0 ? <span className="infowrap"><i className="fa fa-circle"></i><span className="onhoverinfo profile_incomplete">Please add contact details</span></span> : ''}</NavLink></li>
                                                <li><NavLink to="/recruiter/tax-information">Tax information {validationdataCheckData.basictaxtype_validation == 0 ? <span className="infowrap"><i className="fa fa-circle"></i><span className="onhoverinfo profile_incomplete">Please add tax information details</span></span> : ''}</NavLink></li>
                                                <li><NavLink to="/recruiter/profile-summary">Biograph/Bio {validationdataCheckData.profilesummery_validation == 0 ? <span className="infowrap"><i className="fa fa-circle"></i><span className="onhoverinfo profile_incomplete">Please add Profile Summary</span></span> : ''}</NavLink></li>
                                                <li><NavLink to="/recruiter/social-media-settings">Compnay Social media {validationdataCheckData.socialmedia_validation == 0 ? <span className="infowrap"><i className="fa fa-circle"></i><span className="onhoverinfo profile_incomplete">Please fill all fields</span></span> : ''}</NavLink></li>
                                                <li><NavLink to="/recruiter/password-setting">Password</NavLink></li>
                                            </>
                                            :
                                            <>
                                                <li><NavLink to="/recruiter/basic-detail">Basic details {validationdataCheckData.basicdob_validation == 0 ? <span className="infowrap"><i className="fa fa-circle"></i><span className="onhoverinfo profile_incomplete">Please add basic details</span></span> : ''} </NavLink></li>
                                                <li><NavLink to="/recruiter/address">Address  {validationdataCheckData.basicaddress_validation == 0 ? <span className="infowrap"><i className="fa fa-circle"></i><span className="onhoverinfo profile_incomplete">Please add basic details</span></span> : ''} </NavLink></li>
                                                <li><NavLink to="/recruiter/contact-detail">Contact details {validationdataCheckData.basiccontact_validation == 0 ? <span className="infowrap"><i className="fa fa-circle"></i><span className="onhoverinfo profile_incomplete">Please add contact details</span></span> : ''}</NavLink></li>
                                                <li><NavLink to="/recruiter/tax-information">Tax information {validationdataCheckData.basictaxtype_validation == 0 ? <span className="infowrap"><i className="fa fa-circle"></i><span className="onhoverinfo profile_incomplete">Please add tax information details</span></span> : ''}</NavLink></li>
                                                <li><NavLink to="/recruiter/profile-summary">Biograph/Bio {validationdataCheckData.profilesummery_validation == 0 ? <span className="infowrap"><i className="fa fa-circle"></i><span className="onhoverinfo profile_incomplete">Please add Profile summary</span></span> : ''}</NavLink></li>
                                                <li><NavLink to="/recruiter/social-media-settings">Social media {validationdataCheckData.socialmedia_validation == 0 ? <span className="infowrap"><i className="fa fa-circle"></i><span className="onhoverinfo profile_incomplete">Please fill all fields</span></span> : ''}</NavLink></li>
                                                <li><NavLink to="/recruiter/password-setting">Password</NavLink></li>
                                            </>

                                }

                            </ul>
                        </div>
                    </>
                    :
                    <>

                        <div className="card-box profilesidebar">
                            <ul>
                                <li><NavLink to="/jobseeker/basic-detail">Basic details   {validationdataCheckData.basicdob_validation == 0 ? <span className="infowrap"><i className="fa fa-circle"></i><span className="onhoverinfo profile_incomplete">Please add basic details</span></span> : ''} </NavLink>  </li>
                                <li><NavLink to="/jobseeker/address">Address {validationdataCheckData.basicaddress_validation == 0 ? <span className="infowrap"><i className="fa fa-circle"></i><span className="onhoverinfo profile_incomplete">Please add address details</span></span> : ''} </NavLink></li>
                                <li><NavLink to="/jobseeker/contact-details">Contact details {validationdataCheckData.basiccontact_validation == 0 ? <span className="infowrap"><i className="fa fa-circle"></i><span className="onhoverinfo profile_incomplete">Please add contact details</span></span> : ''}</NavLink></li>
                                <li><NavLink to="/jobseeker/tax-information">Tax information {validationdataCheckData.basictaxtype_validation == 0 ? <span className="infowrap"><i className="fa fa-circle"></i><span className="onhoverinfo profile_incomplete">Please add tax information details</span></span> : ''}</NavLink></li>
                                <li><NavLink to="/jobseeker/visa-detail">Visa details {validationdataCheckData.basicvisa_validation == 0 ? <span className="infowrap"><i className="fa fa-circle"></i><span className="onhoverinfo profile_incomplete">Please add visa details</span></span> : ''}</NavLink></li>
                                <li><NavLink to="/jobseeker/eductions">Education {1 == '0' ? <span className="infowrap"><i className="fa fa-circle"></i><span className="onhoverinfo profile_incomplete">Please add educations details</span></span> : ''}</NavLink></li>
                                <li><NavLink to="/jobseeker/qualification">Extra job qulification {1 == '0' ? <span className="infowrap"><i className="fa fa-circle"></i><span className="onhoverinfo profile_incomplete">Please add educations details</span></span> : ''}</NavLink></li>
                                <li><NavLink to="/jobseeker/profile-summary">Bio {1 == '0' ? <span className="infowrap"><i className="fa fa-circle"></i><span className="onhoverinfo profile_incomplete">Please add Profile Summary</span></span> : ''}</NavLink></li>
                                <li><NavLink to="/jobseeker/social-media-settings">Social media {validationdataCheckData.socialmedia_validation == 0 ? <span className="infowrap"><i className="fa fa-circle"></i><span className="onhoverinfo profile_incomplete">Please fill all fields</span></span> : ''}</NavLink></li>
                                <li><NavLink to="/jobseeker/password-setting">Password</NavLink></li>

                                {/* <li><NavLink to="/reviews">Reviews</NavLink></li> */}
                            </ul>
                        </div>
                    </>
            }


        </>
    );
}
export default ProfileSidebar;