import {Link}  from 'react-router-dom';
const Recruiter = () => {
    return (
     <>
     <div className="mainpages ">
        <div className="banner page-banner">
          <div className="container">
              <div className="row align-items-center">
                 <div className="col-md-6">
                    <div className="banner-left">
                        <h1>Recruiter</h1>
                    </div>
                 </div>
                 <div className="col-md-6">
                   <img src="/front/images/aboutbannerimgg.png"></img>
                 </div>
              </div>
          </div>
        </div>



        <div className="trrmssec pb-0">
            <div className="tabwarperr mt-1 ">
               <ul className="nav nav-pills mb-3 comontabs" id="pills-tab" role="tablist">
                  <li className="nav-item" role="presentation"><button className="nav-link active" id="pills-company-tab" data-bs-toggle="pill" data-bs-target="#pills-company" type="button" role="tab" aria-controls="pills-company" aria-selected="true">Company</button></li>
                  <li className="nav-item" role="presentation"><button className="nav-link" id="pills-individual-tab" data-bs-toggle="pill" data-bs-target="#pills-individual" type="button" role="tab" aria-controls="pills-individual" aria-selected="false">Individual</button></li>
               </ul>
            </div>
            <div className="tab-content" id="pills-tabContent">
               <div className="tab-pane fade active show" id="pills-company" role="tabpanel" aria-labelledby="pills-company-tab">
               <div className="whoweare">
                     <img src="/front/images/sleft.png" className="sectionleftimg sectionabs"></img>
                     <div className="container">
                        <div className="row videorow">
                        <div className="col-md-2">
                           </div>
                        <div className="col-md-8">
                           <video  controls poster="front/images/videoimg.jpg">
                              <source src="/front/images/Office Work Free Stock Video _ One1.mp4" type="video/mp4"></source>
                              </video>
                              </div>
                           
                           
                        </div>
                     </div>
                     <img src="/front/images/sright.png" className="sectionrightimg sectionabs"></img>
                  </div>



                  <div className="aboutleftrightsec recruitersec1">
                     <img src="/front/images/dotsinmg.jpg" className="dotimgsec"></img>
                     <div className="container">
                        <div className="row sucess">
                        <div className="col-md-12  mb-5">
                               <h2 className="sectionhead recmainhead text-center">Mannual search features</h2>
                           </div>
                           <div className="col-md-6 aboutcon">
                              <h2 className="sectionhead mb-3">Join our modern workplace to solve these traditional Problems</h2>
                              <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>Taking time to post job ads.</span></p> 
                              <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>Takes time to get responses from applicant.</span></p> 
                              <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>Unqualified applicant filling up your job ad wasting your time to filter them.</span></p> 
                              <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>Platforms taking higher charges from you, </span></p> 
                              <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>Conversion rates per amount you spend is less.</span></p> 
                              <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>Post boosts are not working anymore. </span></p>
                              <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>No privacy; receiving cold calls and emails from third parties when  posting ads.</span></p> 
                              <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>Applicants who already have jobs are randomly applying to your ad, changes are they will look for other job again.</span></p>
                              <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>Time consuming to take interviews and reference and resume check.</span></p>
                              <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>Updated Location, availability and work history review unknown. </span></p>
                           </div>

                           <div className="col-md-6 aboutcon">
                              <h2 className="sectionhead mb-3">What we provide to solve it</h2>
                              <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>Few steps to complete and eligible employees Infront of your eyes in few sec.</span></p>
                              <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span> All jobseekers go through profile verification.</span></p> 
                              <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>All jobseeker’s resume and references are cross checked and approved before making their profile active.</span></p>
                              <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>We charge lowest of fee only when you and applicant connect with each other.</span></p>
                              <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>We provide chat, video, file transfer function available so taking interviews will be quick and easy.</span></p>
                              <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>You can also see the reviews of jobseekers posted by previous recruiter.</span></p>
                              <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>Once a jobseeker gets connected with you, their availability are changes making them unavailable by system for other job offers.</span></p>
                              <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>Your contact and company details are not visible to Jobseekers until they accept your offer.</span></p>
                              <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>Jobseekers are shown matching your requirements, such as location, distance to work, qualification.</span></p>
                              <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>We reduce the acceptance rating of jobseekers, if they reject 100% matched job offers and won’t come at front while you search.</span></p>
                              <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>Secure communication channel which are recorded for any issues in future.</span></p>
                              <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>Full refund policy if your selected jobseeker does not show up to work (we will verify first).</span></p>
                              <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>You can hire national and international jobseekers for any face-to-face job.</span></p>
                              <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>You don’t need to fill the same job ads again with our repost features.</span></p>
                              <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>You can see jobseekers profile as many as you like but can have match with only the number of job seekers you are looking after.</span></p>
                              <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>Any inactive or low acceptance rating jobseekers are removed from our platforms.</span></p>
                              <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>You can hire both contractor and employees using filter. </span></p>
                           </div>
                        </div>
                        
                     </div>
                  </div>


                  <div className="aboutleftrightsec recruitersec2">
                     <div className="container">
                        <div className="row sucess">
                        <div className="col-md-12 mb-5">
                               <h2 className="sectionhead recmainhead text-center mb-2">Quick search: - I imagine getting staff like food being<br></br> Delivered at your home</h2>
                               <p className="text-center largepera1">Quick search feature is made to make job seeker’s hiring possible in same day or hour. This feature works like an uber eats (food delivery platform).</p>
                              <p className="text-center largepera1">Unlike manual search, Quick search makes possible to hire qualified job seekers immediately using our system auto matching features.</p>
                           </div>
                           <div className="col-md-6 aboutcon">
                              <h2 className="sectionhead mb-3 text-danger">Let’s look at some problems you were facing till now</h2>
                              <p className="d-flex align-items-start"><img src="/front/images/rightIconRed.svg"/><span>Hard to find staff immediately when someone call sick or left the job without notice.</span></p>
                              <p className="d-flex align-items-start"><img src="/front/images/rightIconRed.svg"/><span>Needed more staff than estimated on the day of work and now paying overtime or extended job hours.</span></p>
                              <p className="d-flex align-items-start"><img src="/front/images/rightIconRed.svg"/><span>No idea if the urgent staff you called are on their way to work or not.</span></p>
                           </div>


                           <div className="col-md-6 aboutcon">
                              <h2 className="sectionhead mb-3 text-success">Now here are solutions we provide</h2>
                              <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>Our system will charge you very minimal one-time fee only when staff location is at your workplace.</span></p>
                              <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>Full or partial refund if the job attended the workplace but left the work.</span></p>
                              <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>Job seeker will be brief with dress code and your requirement before they accept your QUICK OFFER.</span></p>
                              <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>Live location view of the job seeker that accepted your offer. </span></p>
                              <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>You can cancel and research if you don’t like the job seeker to whom the system chooses for you.</span></p>
                              <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>System always looks at the acceptance rating of the job seekers before sending them your offer.</span></p>
                              <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>You can QUICK SEARCH as many job seekers you like.</span></p> 
                              <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>You pay the jobseekers yourself according to both of your preferences of payment, we don’t interfere.</span></p>
                              <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>You can filter if you want CONTRACTOR OR EMPLOYEE.</span></p> 
                              <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>We do not send offers to those job seekers whose availability are not updated or profile, resume, reference, experience are not verified.</span></p>
                              <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>No lock in contract or extra charge even if you want to permanently hire the staff you got.</span></p>
                           </div>

                        </div>
                        
                     </div>
                  </div>


               </div>

               <div className="tab-pane fade" id="pills-individual" role="tabpanel" aria-labelledby="pills-individual-tab">
               <div className="whoweare">
                     <img src="/front/images/sleft.png" className="sectionleftimg sectionabs"></img>
                     <div className="container">
                        <div className="row videorow">
                        <div className="col-md-6">
                           <video  controls poster="front/images/videoimg.jpg">
                              <source src="/front/images/Office Work Free Stock Video _ One1.mp4" type="video/mp4"></source>
                              </video>
                              </div>
                           <div className="col-md-6">
                              <div className="banner-left">
                              <p>For public, hiring a contractor for your home or businesses are time consuming. You will be going through various kind of google, Facebook, TikTok ads to find the best for the job.</p>  
                              <p>Whether you are looking for a plumber, roof fixer, electrician or steam cleaning or bond cleaner, removalist and so on etc you will be going through a lot of research on your own.</p> 
                             <p>SQUAD GOO provides a one place solution making it easy to match your desired contractor with one click. You can hire contractor manually: which is generally for later date, or you can view out QUICK SEARCH option that should get you a contractor you need immediately.</p> 
                             <p>You can always filter based on your budget and availability. You can view their profile rating and previous business experiences.</p>
                                    {/* <Link href="#" className="batn batn-orange">Join Us</Link> */}
                                    <Link to="/login" className="batn batn-orange">Join Us</Link>

                              </div>
                           </div>
                           
                        </div>
                     </div>
                     <img src="/front/images/sright.png" className="sectionrightimg sectionabs"></img>
                  </div>



                  <div className="aboutleftrightsec ">
                     <img src="/front/images/dotsinmg.jpg" className="dotimgsec"></img>
                     <div className="container">
                        <div className="row sucess">
                           
                           <div className="col-md-6 aboutcon">
                           <h2 className="sectionhead mb-3">Let’s summarise some basic problems you might be facing</h2>
                          <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>Need immediate fixing at your property but can’t get appointments.</span></p>
                          <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>You look at the top ads and these top ads providers charge you expensive.</span></p>
                          <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>2ots of phone calls and emails and explanation required to arrange a match.</span></p>
                           <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>Most of the google reviews they have are only positive but happens to be a low-quality contractor.</span></p>
                           </div>

                           <div className="col-md-6 aboutcon">
                           <h2 className="sectionhead mb-3">Features we provide</h2>
                            <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>We charge you one-time fee only when you and contractor start chatting with each other or accepts your offer.</span></p>
                            <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>Our matching fee are minimal, meaning any one can afford it.</span></p> 
                            <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>We verify everyone on our platform, even their availability, reviews, and history.</span></p>
                           <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>If contractor can’t do the job, then you will be fully refunded with the amount you spend for our service. (TERM AND CONDITION APPLIES).</span></p>
                            <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>You pay for the service yourself directly to the contractor, we won’t hassle you in the middle.</span></p>
                            <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>Immediate hiring facility with same day or hour matching.</span></p> 
<                            p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>With our QUICK SEARCH feature, you will be able to get someone to fix the problem immediately.</span></p>
                           </div>
                        </div>
                        
                     </div>
                  </div>
               </div>
            </div>
         </div>
        </div>

     </>
    );
  }
  export default Recruiter;