import React, { useState, useEffect, Component } from 'react';

//import SuperAdminSidebar from './superadmin_sidebar'
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';
import StrongPassMsgComp from '../expocomp/StrongPassMsgComp';
const PasswordResetComp = (attr) => {

    const [dataLoading, setDataLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const [profilePicUrl, setProfilePicUrl] = useState('');
    const navigate = useNavigate();

    const [userInputFormData, setUserInputFormData] = useState({
        'first_name': '',
        'last_name': ''
    });
    const [showPassword, setShowPassword] = useState(false);
    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };


    const [showPasswordOld, setShowPasswordOld] = useState(false);
    const handleTogglePasswordVisibilityOld = () => {
        setShowPasswordOld(!showPasswordOld);
    };


    const useriddata = JSON.parse(localStorage.getItem("squadGologinSession"));
    const getUserDetails = (user_id) => {
        (async () => {
            setDataLoading(true);
            try {
                const formData = new FormData();
                axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/admin_staff/${useriddata.login_token}/${user_id}`, formData)
                    .then((response) => {
                        setDataLoading(false);
                        if (response.status == 200 && response.data.status == 1) {
                            let user = response.data.userdata.staff;
                            setProfilePicUrl(user.profilepic ? `https://squadgoo.com.au/api.squadgoo.com.au/${user.profilepic}` : '/front/images/user.svg');
                            setUserInputFormData({
                                'first_name': user.first_name,
                                'last_name': user.last_name
                            })
                        }
                    })
                    .catch((error) => { console.error(error); });
            } catch (error) { console.log(error); }

        })();
    }
    useEffect(() => {
        getUserDetails(1);
    }, []);

    /*----------------basic details----------------------*/
    const setUserInputFormDataFun = (event) => {
        const { name, value } = event.target;
        setUserInputFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };

    
    const onFormSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) { event.preventDefault(); event.stopPropagation(); }
        else {
            const findStafFormDataArray = {};
            const formData = new FormData(event.target);
            formData.forEach((value, name) => { findStafFormDataArray[name] = value; });
            try {
                formData.append('login_token', useriddata.login_token);
                axios.post('https://squadgoo.com.au/api.squadgoo.com.au/superadmin_action', formData)
                    .then((response) => {

                        console.log(response.data.message);
                        if (response.status == 200 && response.data.status == 1) {
                            toast.success(response.data.message);
                        }
                        else { toast.warning(response.data.message); }
                    })
                    .catch((error) => { console.error(error); });
            } catch (error) { console.log(error); }
        }
        setValidated(true);
    };

    /*-----------------------password change-------------------------*/
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [serverResMessage, setServerResMessage] = useState('');
    const [isPasswordMatch, setIsPasswordMatch] = useState(false);
    const [isPasswordStrong, setIsPasswordStrong] = useState(false);

    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        setIsPasswordMatch(newPassword === confirmPassword);
        setIsPasswordStrong(checkPasswordStrength(newPassword));
    };

    const handleConfirmPasswordChange = (e) => {
        const newConfirmPassword = e.target.value;
        setConfirmPassword(newConfirmPassword);
        setIsPasswordMatch(password === newConfirmPassword);
    };
    const [oldPassword, setOldPassword] = useState('');
    const callCallBack = () => { setTimeout(function () { setServerResMessage('') }, 3000); }
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!isPasswordValid) { setServerResMessage("Please enter valid password");callCallBack() }
        //if (isPasswordMatch && isPasswordStrong) {
        else {
            const findStafFormDataArray = {};
            const formData = new FormData(e.target);
            formData.forEach((value, name) => { findStafFormDataArray[name] = value; });
            try {
                formData.append('login_token', useriddata.login_token);
                axios.post('https://squadgoo.com.au/api.squadgoo.com.au/superadmin_password_action', formData)
                    .then((response) => {

                        console.log(response.data.message);
                        if (response.status == 200 && response.data.status == 1) {
                            toast.success(response.data.message);
                        }
                        else { toast.warning(response.data.message); }
                    })
                    .catch((error) => { console.error(error); });
            } catch (error) { console.log(error); }
        } 
        // else {
        // }
    };


    const checkPasswordStrength = (password) => {
        // Define your criteria for a strong password here
        const minLength = 8;
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumbers = /\d/.test(password);
        const hasSpecialChars = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(password);

        return (
            password.length >= minLength &&
            hasUpperCase &&
            hasLowerCase &&
            hasNumbers &&
            hasSpecialChars
        );
    };


    /*-------------------------*/

    //const [password, setPassword] = useState('');
    //const [confirmPassword, setConfirmPassword] = useState('');
    const [criteria, setCriteria] = useState({
        length: false,
        uppercase: false,
        lowercase: false,
        number: false,
        specialChar: false,
    });
    const [isFocused, setIsFocused] = useState(false);
    const [passwordsMatch, setPasswordsMatch] = useState(true);

    useEffect(() => {
        // Update password criteria state whenever the password changes
        setCriteria({
            length: password.length >= 8,
            uppercase: /[A-Z]/.test(password),
            lowercase: /[a-z]/.test(password),
            number: /[0-9]/.test(password),
            specialChar: /[!@#$%^&*]/.test(password),
        });
    }, [password]);

    useEffect(() => {
        // Check if passwords match whenever either password or confirmPassword changes
        setPasswordsMatch(password === confirmPassword);
    }, [password, confirmPassword]);

    const isPasswordValid = Object.values(criteria).every(Boolean);

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        if (!isPasswordValid) {
            setIsFocused(false);
        }
    };

    return (
        <>

            <div className="profilerightwraper stepscont commonform accountsettingpage">

                {
                    (attr.usertype == 'superadmin')
                    &&
                    <div className="card-box work-experiencesec commonform">
                        <div className="d-flex align-items-center justify-content-between">
                            <h3 className="mb-3">Basic Details</h3>
                        </div>
                        <div className="editblock">
                            <div className="editblockinnerbtm">
                                <Form noValidate validated={validated} onSubmit={onFormSubmit}>
                                    <div className="row">

                                        <div className="col-md-6">
                                            <div className="d-flex">
                                                <div className="form-group w-100">
                                                    <label className="form-label">First name <span className="starr">*</span></label>
                                                    <input onChange={setUserInputFormDataFun} value={userInputFormData.first_name} name='first_name' type="text" className="form-control" placeholder="Andrew" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Last name <span className="starr">*</span></label>
                                                <input onChange={setUserInputFormDataFun} value={userInputFormData.last_name} name='last_name' type="text" className="form-control" placeholder="Enderson" />
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label className="form-label">E-mail <span className="starr">*</span></label>
                                                <input type="readonly" className="form-control" value={useriddata.email} /></div>
                                        </div>

                                        <div className="col-md-12 mt-3">
                                            <input type='submit' className="batn batn-orange" value={'Update'}></input>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                }


                <div className={`card-box work-experiencesec commonform  ${(attr.usertype == 'superadmin') ? ' mt-3' : ' mt-0'}`}  >
                    <div className="d-flex align-items-center justify-content-between">
                        <h3 className="mb-3">Password</h3>
                    </div>
                    <div className="editblock">
                        <div className="editblockinnerbtm">
                            <form onSubmit={handleSubmit}>
                                <div className="row">

                                    <div className="col-md-12">
                                        <div className="d-flex">
                                            <div className="form-group w-100">
                                                <label className="form-label">Old Password</label>
                                                <div className="input-group2">

                                                    <div className="passwordwrap p-relative iconfields">
                                                        <input
                                                            type={showPasswordOld ? 'text' : 'password'}
                                                            className="form-control"
                                                            value={oldPassword}
                                                            name='old_password'
                                                            onChange={(e) => setOldPassword(e.target.value)}
                                                            required
                                                        />
                                                        <span className="eyebtn" onClick={handleTogglePasswordVisibilityOld}>
                                                            {/* <img src="/front/images/eye.svg" /> */}
                                                            {showPasswordOld ? <img src="/front/images/eyeclose.svg"></img> :
                                                                <img src="/front/images/eyeopen.svg"></img>}
                                                        </span>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="col-md-6">
                                        <div className="d-flex">
                                            <div className="form-group w-100">
                                                <label className="form-label">New Password</label>
                                                <div className="input-group2">

                                                    <div className="passwordwrap p-relative iconfields">
                                                        <input
                                                            type={showPassword ? 'text' : 'password'}
                                                            className="form-control"
                                                            value={password}
                                                            name='password'
                                                            onChange={handlePasswordChange}
                                                            required
                                                        />
                                                        <span className="eyebtn" onClick={handleTogglePasswordVisibility}>
                                                            {showPassword ? <img src="/front/images/eyeclose.svg"></img> :
                                                                <img src="/front/images/eyeopen.svg"></img>}
                                                        </span>
                                                    </div>

                                                </div>

                                                {!isPasswordStrong && <StrongPassMsgComp />}

                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="d-flex">
                                            <div className="form-group w-100">
                                                <label className="form-label">Confirm Password</label>
                                                <div className="input-group2">
                                                    <div className="passwordwrap p-relative iconfields">
                                                        <input
                                                            type={showPassword ? 'text' : 'password'}
                                                            name="confirm_password"
                                                            className={`form-control ${isPasswordMatch ? 'is-valid' : 'is-invalid'}`}
                                                            value={confirmPassword}
                                                            onChange={handleConfirmPasswordChange}
                                                            required
                                                        />
                                                        <span className="eyebtn" onClick={handleTogglePasswordVisibility}>
                                                            {showPassword ? <img src="/front/images/eyeclose.svg"></img> :
                                                                <img src="/front/images/eyeopen.svg"></img>}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        {!isPasswordMatch && <div className=" text-danger" >Password not match.</div>}
                                    </div> */}

                                    <div className="col-md-6">
                                        <div className="d-flex1">
                                            <div className="form-group">
                                                <label htmlFor="password">Password</label>
                                                <div className="input-group2">
                                                    <div className="passwordwrap p-relative iconfields">


                                                        <input
                                                            type={showPassword ? 'text' : 'password'}
                                                            className="form-control"
                                                            id="password"
                                                            name='password'
                                                            value={password}
                                                            onChange={(e) => setPassword(e.target.value)}
                                                            onFocus={handleFocus}
                                                            onBlur={handleBlur}
                                                            required
                                                        />
                                                        <span className="eyebtn" onClick={handleTogglePasswordVisibility}>
                                                            {/* <img src="/front/images/eye.svg" /> */}
                                                            {showPassword ? <img src="/front/images/eyeclose.svg"></img> :
                                                                <img src="/front/images/eyeopen.svg"></img>}
                                                        </span>
                                                    </div>
                                                </div>
                                                {isFocused  && !isPasswordValid && (
                                                    <ul className="password-criteria">
                                                        <li style={{ color: criteria.length ? 'green' : 'red' }}>
                                                            At least 8 characters
                                                        </li>
                                                        <li style={{ color: criteria.uppercase ? 'green' : 'red' }}>
                                                            At least one uppercase letter
                                                        </li>
                                                        <li style={{ color: criteria.lowercase ? 'green' : 'red' }}>
                                                            At least one lowercase letter
                                                        </li>
                                                        <li style={{ color: criteria.number ? 'green' : 'red' }}>
                                                            At least one number
                                                        </li>
                                                        <li style={{ color: criteria.specialChar ? 'green' : 'red' }}>
                                                            At least one special character (!@#$%^&*)
                                                        </li>
                                                    </ul>
                                                )}
                                            </div>

                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="d-flex1">
                                            <div className="form-group">
                                                <label htmlFor="confirmPassword">Confirm Password</label>
                                                <div className="input-group2">
                                                    <div className="passwordwrap p-relative iconfields">


                                                        <input
                                                            type={showPassword ? 'text' : 'password'}
                                                            id="confirmPassword"
                                                            name="confirm_password"
                                                            className="form-control"
                                                            value={confirmPassword}
                                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                                            required
                                                        />
                                                        <span className="eyebtn" onClick={handleTogglePasswordVisibility}>
                                                            {/* <img src="/front/images/eye.svg" /> */}
                                                            {showPassword ? <img src="/front/images/eyeclose.svg"></img> :
                                                                <img src="/front/images/eyeopen.svg"></img>}
                                                        </span>
                                                    </div>
                                                </div>
                                                {!passwordsMatch && confirmPassword && (
                                                    <div className="password-mismatch" style={{ color: 'red' }}>
                                                        Passwords do not match.
                                                    </div>
                                                )}
                                            </div>

                                        </div>
                                    </div>
                                    {serverResMessage ? <p className="col-md-12 text-danger text-center  mb-0">{serverResMessage}</p> : ''}

                                    <div className="col-md-12 mt-3">
                                        <button
                                            type="submit"
                                            className="batn batn-orange"
                                            disabled={!isPasswordMatch || !isPasswordStrong}
                                        >
                                            Reset
                                        </button>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>


                {
                    (attr.usertype == 'superadmin')
                    &&
                    <>
                        {/* <div className="card-box work-experiencesec commonform mt-3">
                              <div className="d-flex align-items-center justify-content-between">
                                 <h3 className="mb-3">Payment Gatway Settinngs</h3>
                              </div>
                              <div className="editblock">
                                 <div className="editblockinnerbtm">
                                    <div className="row">

                                       <div className="col-md-2 paymentimages">
                                          <div className="form-group">
                                             <label className="form-label">CC Avenue</label>
                                             <img src="https://image4.owler.com/logo/ccavenue_owler_20200307_180446_original.png"></img>
                                          </div>
                                       </div>
                                       <div className="col-md-5">
                                          <div className="form-group"><label className="form-label">CC Avenue keyid</label>
                                             <input type="text" className="form-control" placeholder="Enter CC Avenue keyid" />
                                          </div>
                                       </div>
                                       <div className="col-md-5">
                                          <div className="form-group">
                                             <label className="form-label">CC Avenue secret key</label>
                                             <input type="text" className="form-control" placeholder="Enter CC Avenue secret key" />
                                          </div>
                                       </div>

                                       <div className="col-md-2">
                                          <div className="form-group paymentimages">
                                             <label className="form-label">Razorpay keyid</label>
                                             <img src="https://d6xcmfyh68wv8.cloudfront.net/assets/razorpay-logo-white.svg"></img>
                                          </div>
                                       </div>
                                       <div className="col-md-5">
                                          <div className="form-group"><label className="form-label">Razorpay keyid</label>
                                             <input type="text" className="form-control" placeholder="Enter Razorpay keyid" />
                                          </div>
                                       </div>
                                       <div className="col-md-5">
                                          <div className="form-group">
                                             <label className="form-label">Razorpay secret key</label>
                                             <input type="text" className="form-control" placeholder="Enter Razorpay secret key" />
                                          </div>
                                       </div>

                                       <div className="col-md-2">
                                          <div className="form-group paymentimages">
                                             <label className="form-label">Razorpay</label>
                                             <img src="https://image4.owler.com/logo/ccavenue_owler_20200307_180446_original.png"></img>
                                          </div>
                                       </div>
                                       <div className="col-md-5">
                                          <div className="form-group"><label className="form-label">Razorpay keyid</label>
                                             <input type="text" className="form-control" placeholder="Enter Razorpay keyid" />
                                          </div>
                                       </div>
                                       <div className="col-md-5">
                                          <div className="form-group">
                                             <label className="form-label">Razorpay secret key</label>
                                             <input type="text" className="form-control" placeholder="Enter Razorpay secret key" />
                                          </div>
                                       </div>

                                       <div className="col-md-12 mt-3">
                                          <a href="#" className="batn batn-orange">Update</a>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div> */}
                    </>
                }

            </div>
        </>
    );
}
export default PasswordResetComp;