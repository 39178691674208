import React, { useState, useEffect, Component } from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink, Link, useParams, useNavigate } from 'react-router-dom';
import SIDEBAR_MENU_CONST from './../../json/sidebar_menu_option.json';
import COUNTRY_CODE from './../../json/country_data.json';

import axios from 'axios';

import { Form, Button, Col, InputGroup, Modal, Row } from 'react-bootstrap';
import SuperAdminSidebar from './superadmin_sidebar';


import { ToastContainer, toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';

const Users = () => {


   const [dataisLoading, setDataisLoading] = useState(false);
   const [adminStaffDataArr, setAdminStaffData] = useState([]);
   const [currentActivePage, setCurrentActivePage] = useState(1);
   const [statusType, setstatusType] = useState('all');
   const [totalPageCount, settotalPageCount] = useState(1);
   const paginationLoadContentNo = 10;
   const [adminStaffDataCount, setAdminStaffDataCount] = useState(0);
   const userdetails = JSON.parse(localStorage.getItem("squadGologinSession"));

   const [contactNumber, setContactNumber] = useState('')
   const checkContactNumer = (event) => { const value = event.target.value; const regex = /^[0-9\b]+$/; setContactNumber(''); if (value === '' || regex.test(value)) { setContactNumber(value); } }

   const [editUserID, setEditUserID] = useState(0);
   const [searchText, setSearchText] = useState('');


   const [userInputFormData, setUserInputFormData] = useState({
      'first_name': '',
      'last_name': '',
      'email': '',
      'contact_country_code': '',
      'contact_no': contactNumber,
      'password': '',
      'dob_day': '',
      'dob_month': '',
      'dob_year': '',
      'role_accessibility': '',
   });

   const menuAccess = {
      dashboard: {
         registration: false,
         coins: false,
         job_offers: false,
      },
      jobs_offers: {
         view: false,
         edit: false,
         delete: false,
      },
      recruiters: {
         view: false,
         edit: false,
         delete: false,
      },
      jobseekers: {
         view: false,
         edit: false,
         delete: false,
      },
      reviews: {
         view: false,
         edit: false,
         delete: false,
      },
      chat_history: {
         view: false,
         edit: false,
         delete: false,
      },
      support: {
         view: false,
         edit: false,
         delete: false,
      },
      ticket_support: {
         view: false,
         edit: false,
         delete: false,
      }, 
      livechat_support: {
         view: false,
         edit: false,
         delete: false,
      },
      requested_call_support: {
         view: false,
         edit: false,
         delete: false,
      },
      settings: {
         view: false,
         edit: false,
         delete: false,
      },
      report: {
         view: false,
         edit: false,
         delete: false,
      },
   };
   const [accessibility, setAccessibility] = useState(menuAccess);



   const getDashboardData = (search = '') => {

      const page = (search == '') ? currentActivePage : (1);
      (async () => {
         setDataisLoading(true);
         try {
            const formData = new FormData();
            axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/admin_staffs/${userdetails.login_token}?page=${page}&search=${search}&status=${statusType}`, formData)
               .then((response) => {
                  setDataisLoading(false);
                  if (response.status == 200 && response.data.status == 1) {
                     setAdminStaffData(response.data.data.userdata);
                     setAdminStaffDataCount(response.data.data.total_length);
                     const calculatedTotalPages = Math.ceil(response.data.data.total_length / paginationLoadContentNo);
                     settotalPageCount(calculatedTotalPages);

                  }
               })
               .catch((error) => { console.error(error); });
         } catch (error) { console.log(error); }

      })();
   }

   //useEffect(() => { getDashboardData(); }, []);


   /*-----------------------------------------------------------------------------*/

   const [userDtailsDataArr, setUserDtailsDataArr] = useState([]);
   const [userAccessibilityData, setUserAccessibilityData] = useState([]);

   const getUserDetails = (user_id) => {
      (async () => {
         setDataLoading(true);
         setEditUserID(user_id);
         try {
            const formData = new FormData();
            axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/admin_staff/${userdetails.login_token}/${user_id}`, formData)
               .then((response) => {
                  setDataLoading(false);
                  if (response.status == 200 && response.data.status == 1) {
                     setUserDtailsDataArr(response.data.userdata.staff);
                     console.log(typeof (response.data.userdata.accessibility.dashboard) == 'undefined' ? menuAccess : response.data.userdata.accessibility);
                     //setAccessibility(menuAccess);
                     setAccessibility(typeof (response.data.userdata.accessibility.dashboard) == 'undefined' ? menuAccess : response.data.userdata.accessibility);
                     let user = response.data.userdata.staff;
                     setContactNumber(user.contact_number);
                     setProfilePicUrl(user.profilepic ? `${user.profilepic}` : '/front/images/user.svg');
                     setUserInputFormData({
                        'first_name': user.first_name,
                        'last_name': user.last_name,
                        'email': user.email,
                        'contact_country_code': user.contact_country_code,
                        'contact_no': user.contact_number,
                        'password': user.shw_pass,
                        'dob_day': user.dob_day,
                        'dob_month': user.dob_month,
                        'dob_year': user.dob_year,
                        'role_accessibility': '',
                     });
                     //console.log(user);
                  }
               })
               .catch((error) => { console.error(error); });
         } catch (error) { console.log(error); }

      })();
   }

   const getUserBlock = (user_id) => {
      (async () => {
         setDataLoading(true)
         try {
            const formData = new FormData();
            axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/recruiterblock/${userdetails.login_token}/0/${user_id}?paramtype=block`, formData)
               .then((response) => {
                  setDataLoading(false);
                  if (response.status == 200 && response.data.status == 1) {
                     setisMessageShow(true); setIsBlockModelShow(false); getDashboardData();
                  }
               })
               .catch((error) => { console.error(error); });
         } catch (error) { console.log(error); }

      })();
   }
   useEffect(() => { getDashboardData(); }, [currentActivePage, statusType]);
   const searchKeyValue = (searchkey) => { getDashboardData(searchkey); setSearchText(searchkey) }
   const RenderPaginationNumbers = () => {
      const paginationNumbers = [];
      paginationNumbers.push(<li className="page-item" ><Link onClick={(e) => handlePageChange(currentActivePage - 1)} className="page-link" href="#"><i className="fa fa-angle-left" aria-hidden="true"></i></Link></li>)
      for (let i = 1; i <= totalPageCount; i++) { paginationNumbers.push(<li key={i} className={i === currentActivePage ? 'page-item active' : 'page-item'} ><Link onClick={() => handlePageChange(i)} className="page-link" href="#">{i}</Link></li>); }
      paginationNumbers.push(<li className="page-item" ><Link onClick={(e) => handlePageChange(currentActivePage + 1)} className="page-link" href="#"><i className="fa fa-angle-right" aria-hidden="true"></i></Link></li>)
      return paginationNumbers;
   };
   const handlePageChange = (newPage) => { if (newPage >= 1 && newPage <= totalPageCount) { setCurrentActivePage(newPage); } };

   /*--------------------------*/
   const statsOption = ['all', 'active', 'deactive'];
   //const onStatusChange = (type) => { setstatusType(type) }

   /*-------------------action button -----------------*/
   const [currentEditId, setcurrentEditId] = useState(0);
   const [dataLoading, setDataLoading] = useState(false);
   const [editViewAction, setEditViewAction] = useState(false);
   const [isMessageShow, setisMessageShow] = useState(false);
   const showUserDetails = (id) => { setcurrentEditId(id); getUserDetails(id); setisStffCreatedModelShow(true); }
   const [isBlockModelShow, setIsBlockModelShow] = useState(false);
   const showUserBlockAction = (id) => { setcurrentEditId(id); setIsBlockModelShow(true) }
   const showUserBlockActionExecute = () => { getUserBlock(currentEditId) }
   const showUserEditDetails = (id) => { setcurrentEditId(id); getUserDetails(id); setisStffCreatedModelShow(true); }
   const Months = [{ name: 'January', key: 1, abbreviation: 'Jan' }, { name: 'February', key: 2, abbreviation: 'Feb' }, { name: 'March', key: 3, abbreviation: 'Mar' }, { name: 'April', key: 4, abbreviation: 'Apr' }, { name: 'May', key: 5, abbreviation: 'May' }, { name: 'June', key: 6, abbreviation: 'Jun' }, { name: 'July', key: 7, abbreviation: 'Jul' }, { name: 'August', key: 8, abbreviation: 'Aug' }, { name: 'September', key: 9, abbreviation: 'Sep' }, { name: 'October', key: 10, abbreviation: 'Oct' }, { name: 'November', key: 11, abbreviation: 'Nov' }, { name: 'December', key: 12, abbreviation: 'Dec' },];
   const DayNumberLoop = (attr = '') => { const itemElements = []; itemElements.push(<option value="">DD</option>); for (let i = 1; i < 32; i++) { itemElements.push(<option selected={(attr != '' && attr.select_attr == i) ? true : false} value={i}>{i}</option>); } return itemElements; }
   const MonthsHtml = (attr = '') => { const itemElements = []; itemElements.push(<option value="">Month</option>); Months.map((item, index) => (itemElements.push(<option selected={(attr != '' && attr.select_attr == item.name) ? true : false} value={item.name}>{item.name}</option>))); return itemElements }
   const YearNumberLoop = (attr = '') => { const itemElements = []; itemElements.push(<option value="">YYYY</option>); for (let i = 1950; i < 2012; i++) { itemElements.push(<option selected={(attr != '' && attr.select_attr == i) ? true : false} value={i}>{i}</option>); } return itemElements }
   const useriddata = JSON.parse(localStorage.getItem("squadGologinSession"));
   const [validated, setValidated] = useState(false);
   const [isStffCreatedModelShow, setisStffCreatedModelShow] = useState(false);

   const userStatus = [{ key: 1, name: 'Active' }, { key: 0, name: 'Deactive' }];
   const [formDataRes, setFormDataRes] = useState('');
   const [formDataResSuccess, setFormSuccessDataRes] = useState('');

   const setUserInputFormDataFun = (event) => {
      const { name, value } = event.target;
      setUserInputFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
   };

   
   const isStffCreatedModelShowfun = () => {
      setEditUserID(0);
      setAccessibility(menuAccess);
      setProfilePicUrl('/front/images/user.svg');
      setUserInputFormData({
         'first_name': '', 'last_name': '', 'email': '', 'contact_country_code': '', 'contact_no': contactNumber, 'password': '', 'dob_day': '', 'dob_month': '', 'dob_year': '', 'role_accessibility': '',
      })
      setisStffCreatedModelShow(true)
   }

   const [formSubmitLoading, setformSubmitLoading] = useState(false);
   const onFormSubmit = async (event) => {
      event.preventDefault();
      const form = event.currentTarget;
      if (form.checkValidity() === false) { event.preventDefault(); event.stopPropagation(); }
      else {
         const findStafFormDataArray = {};
         const formData = new FormData(event.target);
         formData.forEach((value, name) => { findStafFormDataArray[name] = value; });
         //accessibility
         console.log(accessibility);
         // formData['accessibility'] =  accessibility ;
         try {
            setformSubmitLoading(true);
            formData.append('login_token', useriddata.login_token);
            formData.append('accessibility', JSON.stringify(accessibility));
            axios.post('https://squadgoo.com.au/api.squadgoo.com.au/admin_staff_create_action', formData)
               .then((response) => {
                  if (response.status == 200 && response.data.status == 1) { getDashboardData() ; setFormSuccessDataRes(response.data.message); setTimeout(() => { setFormSuccessDataRes(''); setisStffCreatedModelShow(false) }, 3000); }
                  else { setFormDataRes(response.data.message); setTimeout(() => { setFormDataRes(''); }, 3000); }
                  setformSubmitLoading(false);
               })
               .catch((error) => { console.error(error); });
         } catch (error) { console.log(error); }
      }
      setValidated(true);
   };


   /*------------------------------------- profile pic upload ------------------------------ */


   const [profilePicUrlDefault, setProfilePicUrlDefault] = useState(true);
   const [passwordEyeChange, setpasswordEyeChange] = useState(true);
   const [profilePicUrl, setProfilePicUrl] = useState('/front/images/user.svg');

   const removeProfilePic = () => { setProfilePicUrl('/front/images/user.svg'); setProfilePicUrlDefault(true); }

   const profileUploadToServer = async (e) => {
      setProfilePicUrl(URL.createObjectURL(e.target.files[0])); setProfilePicUrlDefault(false);
   }


   /*-----------*/


   const handleCheckboxChange = (key, subKey = null) => {
      setAccessibility((prevAccessibility) => {
         if (subKey !== null) {
            return {
               ...prevAccessibility,
               [key]: {
                  ...prevAccessibility[key],
                  [subKey]: !prevAccessibility[key][subKey],
               },
            };
         } else {
            return {
               ...prevAccessibility,
               [key]: !prevAccessibility[key],
            };
         }
      });
   };

   const modules = [
      // {
      //    name: 'Dashboard',
      //    key: 'dashboard',
      //    submodules: ['registration' , 'coins' , 'job_offers'],
      // },
      {
         name: 'Job Offers',
         key: 'jobs_offers',
         submodules: ['view', 'edit', 'delete'],
      },
      {
         name: 'Recruiters',
         key: 'recruiters',
         submodules: ['view', 'edit', 'delete'],
      },
      {
         name: 'Jobseekers',
         key: 'jobseekers',
         submodules: ['view', 'edit', 'delete'],
      },
      {
         name: 'Reviews',
         key: 'reviews',
         submodules: ['view', 'edit', 'delete'],
      },
      {

         name: 'Chat History',
         key: 'chat_history',
         submodules: ['view', 'edit', 'delete'],
      },
      {

         name: 'Support',
         key: 'support',
         submodules: ['view', 'edit', 'delete'],
      },
      {

         name: 'Ticket support',
         key: 'ticket_support',
         submodules: ['view', 'edit', 'delete'],
      },
      {

         name: 'Live chats support',
         key: 'livechat_support',
         submodules: ['view', 'edit', 'delete'],
      },
      {

         name: 'Requested call support',
         key: 'requested_call_support',
         submodules: ['view', 'edit', 'delete'],
      },
      {
         name: 'Settings',
         key: 'settings',
         submodules: ['view', 'edit', 'delete'],
      },
      {
         name: 'Report',
         key: 'report',
         submodules: ['view', 'edit', 'delete'],
      },
   ];

   // ticket_support livechat_support requested_call_support

   return (
      <>
         <div className="dashboarwrap">
            <div className="pagebanner">
               <div className="container">
                  <div className="row">
                     <div className="col-md-12">
                        <h2>Users</h2>
                     </div>
                  </div>
               </div>
            </div>
            {/* <ToastContainer />*/}
            <div className="adminwrap py-4">
               <div className="container">
                  <div className="row">
                     <div className="col-md-3 adminsidebar">
                        <SuperAdminSidebar></SuperAdminSidebar>
                     </div>
                     <div className="col-md-9">
                        <div className="d-flex justify-content-end mb-3">

                           <Link onClick={() => { isStffCreatedModelShowfun() }} className="orange_clr" >+ Add</Link>
                        </div>

                        <div className="box mb-4">

                           <div className="table_filter mb-2 d-flex align-items-center justify-content-between commonform">
                              <div className="tblsearch">
                                 <input onChange={(e) => searchKeyValue(e.target.value)} value={searchText} type="text" className="form-control" placeholder="Search" />
                              </div>
                              <div className="tblrightfilters d-flex align-items-center">
                                 <select className="form-control ml-3 text-capitalize" onChange={(e) => setstatusType(e.target.value)} >
                                    {
                                       statsOption.map((item, index) => (
                                          <option selected={statusType == item} value={item} key={index}>{item}</option>
                                       ))
                                    }

                                 </select>
                                 <span onClick={() => {
                                    setSearchText('');
                                    setstatusType('all');
                                    getDashboardData();
                                 }}
                                    className="reseticon filtericon"  data-toggle="tooltip" title="Reset filters" >
                                    <i className="fa fa-refresh"></i>
                                 </span>
                              </div>


                           </div>


                           <div className="table-responsive">
                              {
                                 !dataisLoading ?
                                    <>
                                       <table className="table admintbl profiletbl">
                                          <thead>
                                             <tr>
                                                {/* <th>Photo</th> */}
                                                <th>Full name</th>
                                                <th>Email</th>
                                                <th>Phone number</th>
                                                {/* <th>TFN</th> */}
                                                {/* <th>profile status</th> */}
                                                <th>Status</th>
                                                <th>Action</th>
                                             </tr>
                                          </thead>
                                          <tbody>
                                             {/* redbtn */}
                                             {/* {
                                             console.log(adminStaffDataArr)
                                          } */}


                                             {

                                                adminStaffDataArr.length ?
                                                   adminStaffDataArr.map((item, index) => (
                                                      <>
                                                         <tr key={item.unique_id}>
                                                            {/* <td><img src={`${item.profilepic ? 'https://squadgoo.com.au/api.squadgoo.com.au/'+item.profilepic : '/front/images/user.svg'}`} className="profileiimg" /></td> */}
                                                            <td className='text-capitalize' >
                                                            <Link onClick={(e) => showUserDetails(item.user_id)} >
                                                               {item.first_name + ' ' + item.last_name}
                                                            </Link>
                                                            </td>
                                                            <td>{item.email ? item.email : '----------'}</td>
                                                            <td>{item.contact_number ? (item.contact_country_code ? '+' + item.contact_country_code + '-' : '') + item.contact_number : '----------'}</td>
                                                            {/* <td></td> */}

                                                            <td>
                                                               <Link onClick={(e) => showUserBlockAction(item.user_id)} className={`text-capitalize btnsmall  ${item.status == 1 ? 'bluebtn' : 'redbtn'}`}>
                                                                  {(item.status == 1) ? 'active' : 'deactive'}
                                                               </Link>
                                                            </td>
                                                            <td>
                                                               <div className="d-flex">
                                                                  <Link to={`/admin_users_chat?chatscreentype=admin&currentid=${item.unique_id}`} className="ml-3"><i className='comenticon fa fa-commenting-o' /></Link>
                                                                  <Link onClick={(e) => showUserDetails(item.user_id)} className="ml-3"><img src="/front/images/Show.svg" /></Link>
                                                                  <Link onClick={(e) => showUserEditDetails(item.user_id)} className="ml-3"> <img className='ediiconimg' src="/front/images/edit.png" /> </Link>
                                                                  {/* <Link onClick={(e) => showUserBlockAction(item.user_id)} className="ml-3"><img src="/front/images/trash.svg" /> </Link> */}
                                                               </div>
                                                            </td>
                                                         </tr>
                                                      </>
                                                   ))
                                                   :
                                                   <>
                                                      <tr>
                                                         <td className='text-center' colSpan={5}> No information available to display</td>
                                                      </tr>
                                                   </>
                                             }


                                          </tbody>
                                       </table>

                                    </>
                                    :
                                    <>
                                       <div className={`tab-pane fade show active mt45px`} >
                                          <div className='loader_container'>
                                             <p className='text-center'><img className='width-100 loader_image' src={'/front/images/loader.gif'} /></p>
                                          </div>
                                       </div>
                                    </>
                              }

                              {
                                 ((adminStaffDataCount > paginationLoadContentNo)) ?
                                    <>
                                       <div className="card-box">
                                          <nav aria-label="Page navigation">
                                             <ul className="pagination mb-0 justify-content-center">
                                                <RenderPaginationNumbers />
                                             </ul>
                                          </nav>
                                       </div>
                                    </>
                                    : ''
                              }
                              {/* <div className="text-center"><a href="#" className="batn batn-orange mt-2">View All profiles</a></div> */}
                           </div>


                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <Modal className="modal fade modallarg  adduserpop" id="adduserpop" show={isStffCreatedModelShow} onHide={() => setisStffCreatedModelShow(false)} >
               <div className="modal-dialog1">
                  <div className="modal-content">
                     <div className="modal-body">


                        <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => setisStffCreatedModelShow(false)}></button>
                        {/* <h3>Add User</h3> */}
                        {
                           !dataLoading ?
                              <>
                                 <Form noValidate validated={validated} onSubmit={onFormSubmit}>
                                    <div className="commonform">
                                       <div className="row">
                                          <div className="col-md-12 stepscontright mb-2 userimagewrap">
                                             {/* <label className="form-label">Upload user's profile photo</label> */}
                                             <div className="text-left form-group mt-3">
                                                <div className="profilephotodiv">
                                                   <img src={profilePicUrl} />
                                                   <div className="dropdown pimgdrop">
                                                      <button className=" dropdown-toggle" type="button" id="pimgdropmenu" data-bs-toggle="dropdown" aria-expanded="false" fdprocessedid="acbpo"><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                                      <ul className="dropdown-menu" aria-labelledby="pimgdropmenu">
                                                         <li><a className="updatepro" href="#">{editUserID ? 'Update' : 'Upload'}
                                                            <input onChange={profileUploadToServer} type="file" name="profilepic" accept="image/x-png,image/gif,image/jpeg ,application/pdf" id="profilepic" />
                                                         </a></li>
                                                         <li><a className="dropdown-item" href="#">Remove Photo</a></li>
                                                      </ul>
                                                   </div>
                                                   <div className="editprofileicon"></div>
                                                </div>
                                             </div>
                                          </div>
                                          <div className="col-md-6 form-group mb-2">
                                             <label className="form-label" htmlFor="firstName1">First name</label>
                                             <input onChange={setUserInputFormDataFun} className="form-control" id="first_name" name="first_name" type="text" required={true} placeholder="Enter your first name" value={userInputFormData.first_name} />
                                          </div>
                                          <div className="col-md-6 form-group mb-2">
                                             <label className="form-label" htmlFor="lastName1">Last name</label>
                                             <input onChange={setUserInputFormDataFun} className="form-control" id="last_name" name="last_name" type="text" required={true} placeholder="Enter your last name" value={userInputFormData.last_name} />
                                          </div>
                                          <div className="col-md-6 form-group mb-2">
                                             <label className="form-label" htmlFor="exampleInputEmail1">Email address</label>
                                             <input onChange={setUserInputFormDataFun} className="form-control getin_touch_email" id="email" name="email" type="email" required={true} placeholder="Enter email" value={userInputFormData.email} />
                                          </div>
                                          <div className="col-md-6 form-group mb-2">
                                             <label className="form-label" htmlFor="phone">Phone </label>
                                             <div className="phonefield d-flex align-items-center">
                                                <select onChange={setUserInputFormDataFun} className="form-control" name="contact_country_code"  >
                                                   {
                                                      COUNTRY_CODE.length ?
                                                         <>
                                                            {
                                                               COUNTRY_CODE.map((item, index) => (
                                                                  <option selected={userInputFormData.contact_country_code == item.phonecode ? true : false} value={item.phonecode}>+{item.phonecode}</option>
                                                               ))
                                                            }
                                                         </>
                                                         : ''
                                                   }
                                                </select>
                                                <input type="tel" className="form-control" name="contact_number" placeholder="Enter mobile number" maxLength={10} onChange={checkContactNumer} value={contactNumber} />
                                             </div>
                                          </div>
                                          <div className="col-md-6 form-group mb-2">
                                             <label className="form-label" htmlFor="credit1">Position</label>
                                             <input className="form-control" id="position" name="position" type="readonly" disabled={true} placeholder="Admin-staff" value={'Admin-Staff'} required={true} />
                                          </div>
                                          <div className="col-md-6 form-group mb-2">
                                             <label className="form-label" htmlFor="website">Password</label>
                                             <div className='passwordwrap p-relative iconfields' >
                                                <input onChange={setUserInputFormDataFun} className="form-control" id="password" name="password" type={passwordEyeChange == true ? 'text' : 'password'} value={userInputFormData.password} required={true} placeholder="Password" />
                                                <span className="eyebtn" onClick={() => setpasswordEyeChange(!passwordEyeChange)}><img src="/front/images/eye.svg" /></span>
                                             </div>
                                          </div>

                                          <div className="col-md-6 form-group mb-2">
                                             <label className="form-label" htmlFor="picker2">Birth date</label>
                                             <div className="d-flex">

                                                <select onChange={setUserInputFormDataFun} name="dob_day" required className="form-control mr-2 datefild" >
                                                   {<DayNumberLoop select_attr={userInputFormData.dob_day} />}
                                                </select>
                                                <select onChange={setUserInputFormDataFun} name="dob_month" required className="form-control mr-2 monthfild" >
                                                   {<MonthsHtml select_attr={userInputFormData.dob_month} />}
                                                </select>
                                                <select onChange={setUserInputFormDataFun} name="dob_year" required className="form-control yearfild"  >
                                                   {<YearNumberLoop select_attr={userInputFormData.dob_year} />}
                                                </select>


                                             </div>
                                          </div>
                                          <div className="col-md-6 form-group mb-2">
                                             <label className="form-label" htmlFor="picker2">User Status</label>
                                             <select className="form-control" id="status" name="status" >
                                                {
                                                   userStatus.map((item, index) => (
                                                      <option key={item.key} value={item.key}>{item.name}</option>
                                                   ))
                                                }
                                             </select>
                                          </div>

                                          <div className="col-md-12 form-group mb-2">
                                             <label className="form-label" htmlFor="picker2">Role Accessibility</label>
                                             <table className='table admintbl profiletbl'>
                                                <thead>
                                                   <tr>
                                                      <th>Features </th>
                                                      <th> Accessibility</th>
                                                   </tr>
                                                </thead>
                                                <tbody>
                                                   {modules.map((module) => (

                                                      <tr key={module.name}>
                                                         <td className='first_class'>
                                                            <label>
                                                               {/* <input
                                                                  type="checkbox"
                                                                  checked={module.submodules.length ? false : accessibility[module.name]}
                                                                  onChange={() => handleCheckboxChange(module.name)}
                                                               /> */}
                                                               {/* <input
                                                                  type="checkbox"
                                                                  onChange={() => handleCheckboxChange(module.name)}
                                                               /> */}
                                                               {module.name.charAt(0).toUpperCase() + module.name.slice(1)}
                                                            </label>
                                                         </td>
                                                         <td>
                                                            {module.submodules.length > 0 && (
                                                               <div>
                                                                  {module.submodules.map((submodule) => (
                                                                     //console.log(submodule)
                                                                     <label className='mr-2' key={submodule}>
                                                                        <input
                                                                           type="checkbox"
                                                                           checked={accessibility[module.key][submodule]}
                                                                           onChange={() => handleCheckboxChange(module.key, submodule)}
                                                                        />
                                                                        {submodule.charAt(0).toUpperCase() + submodule.slice(1)}
                                                                     </label>
                                                                  ))}
                                                               </div>
                                                            )}
                                                         </td>
                                                      </tr>
                                                   ))}
                                                </tbody>
                                             </table>
                                          </div>




                                          {/* <div className="col-md-12 form-group mb-2">
                                             <label className="form-label" htmlFor="picker2">Role Accessibility</label>
                                             <div className="row">
                                                {
                                                   SIDEBAR_MENU_CONST.map((item, index) => (
                                                      <>
                                                         <div key={item.key} className='role_assing col-md-3'>
                                                            <label className="form-label" htmlFor={'role_accessibility' + item.key}>{item.name}</label>
                                                            {
                                                               editUserID ?
                                                                  <input checked={(userAccessibilityData != null && userAccessibilityData[item.name_item] !== undefined) ? true : false} id={`role_accessibility${item.key}`} name={`role_accessibility[${item.name_item}]`} type="checkbox" />
                                                                  :
                                                                  <input id={`role_accessibility${item.key}`} name={`role_accessibility[${item.name_item}]`} type="checkbox" />
                                                            }
                                                         </div>
                                                      </>
                                                   ))
                                                }
                                             </div>
                                          </div> */}

                                          {
                                             formDataRes ?
                                                <>
                                                   <div className='col-md-12' ><div className="alert alert-danger m-0" role="alert">{formDataRes}</div></div>
                                                </> :
                                                <></>
                                          }
                                          {
                                             formDataResSuccess ?
                                                <>
                                                   <div className='col-md-12' ><div className="alert alert-success m-0" role="success">{formDataResSuccess}</div></div>
                                                </> :
                                                <></>
                                          }

                                          {
                                             editUserID ?
                                                <>
                                                   <input type='hidden' value={editUserID} name="user_id" />
                                                </>
                                                : <></>
                                          }

                                          <div className="col-md-6 form-group mt-3">
                                             {
                                                formSubmitLoading ?
                                                   <><button type='submit' disabled={true} className="batn batn-orange">Please wait...</button></>
                                                   :
                                                   <><button type='submit' className="batn batn-orange">{editUserID ? 'Update' : 'Add'} User</button></>
                                             }
                                             {/* <button type='submit' className="batn batn-orange">{editUserID ? 'Update' : 'Add'} User</button> */}
                                          </div>

                                       </div>
                                    </div>
                                 </Form>
                              </>
                              :
                              <>
                                 <div className={`tab-pane fade show active mt45px`} >
                                    <div className='loader_container'>
                                       <p className='text-center'><img className='width-100 loader_image' src={'/front/images/loader.gif'} /></p>
                                    </div>
                                 </div>
                              </>
                        }
                     </div>
                  </div>
               </div>
            </Modal>


            <Modal className="modal fade modalsmall jobacceptmodle" id="jobacceptmodle" show={isBlockModelShow} onHide={() => setIsBlockModelShow(false)}>
               <div className="modal-dialog1">
                  <div className="modal-content">
                     <div className="modal-body">
                        <button type="button" className="btn-close" onClick={() => setIsBlockModelShow(false)}></button>
                        <div className="text-center mb-3">
                           {/* <img src="/front/images/greencheck.svg" /> */}
                        </div>
                        <h5 className="text-center mb-4">Do you want to deactivate account</h5>

                        <div className="btnsgroup text-center">
                           <Link onClick={showUserBlockActionExecute} className="batn batn-orange" >Yes</Link>
                           <Link onClick={() => setIsBlockModelShow(false)} className="batn batn-orange batn-border">No</Link>
                        </div>
                     </div>
                  </div>
               </div>
            </Modal>




            {/*  */}


         </div>
      </>
   );
}
export default Users;