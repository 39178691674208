import React, { useState, useEffect, Component, useRef } from 'react';
import axios from 'axios';
import { isVisible } from '@testing-library/user-event/dist/utils';
import { Link } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';


const ChatHistoryComp = (getdata) => {

    const useriddata = JSON.parse(localStorage.getItem("squadGologinSession"));
    const [screenLoader, setScreenLoader] = useState(false);
    const [chatHistoryData, setChatHistoryData] = useState([]);
    const [chatHistoryDataCopy, setChatHistoryDataCopy] = useState([]);
    const [chatHistorySingleData, setChatHistorySingleData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const [currentActivePage, setCurrentActivePage] = useState(1);
    const [totalPageCount, settotalPageCount] = useState(1);
    const [totalChatData, setTotalChatData] = useState(1);
    const [searchText, setSearchText] = useState('');
    const [searchText2, setSearchText2] = useState('');

    const paginationLoadContentNo = 10;

    const get_chathistory = (apiurl, type = 'onload') => {
        setScreenLoader(true);
        (async () => {
            try {
                const resData = await axios.get(apiurl);
                if (resData.data.status == 1) {
                    setScreenLoader(false);

                    if (type == 'single_data') {
                        setChatHistorySingleData(resData.data.data);
                    } else {
                        setChatHistorySingleData([])
                        setChatHistoryData(resData.data.data);
                        setChatHistoryDataCopy(resData.data.data);
                        setTotalChatData(resData.data.totalcount);
                        const calculatedTotalPages = Math.ceil(resData.data.totalcount / paginationLoadContentNo);
                        settotalPageCount(calculatedTotalPages);
                    }

                }
            } catch (error) { console.log(error); }
        })();
    }

    useEffect(() => {
        if (useriddata) {
            const queryParameters = new URLSearchParams(window.location.search);
            const chat_token_id = queryParameters.get("chat_token_id");
            const user_unique_id = queryParameters.get("id");

            // alert(user_unique_id)
            get_chathistory(`https://squadgoo.com.au/api.squadgoo.com.au/getchathistory/${useriddata.login_token + (user_unique_id ? '?getchat_his_id=' + user_unique_id : '')}`);


            if (chat_token_id) {
                //setChatHistotyDatatokenID(chat_token_id);
                get_chathistory(`https://squadgoo.com.au/api.squadgoo.com.au/getchathistory/${useriddata.login_token}?getchat_his_id=${userUniqueID}&chat_token_id=${chat_token_id}`, 'single_data');
            }
        }


    }, []);


    const [chatHistotyDatatokenID, setChatHistotyDatatokenID] = useState(0);
    const [chatHistotyDatatokenIDData, setChatHistotyDatatokenIDData] = useState(0);
    //const scrollElementRef = useRef(null);

    const [userUniqueID, setuserUniqueID] = useState((typeof (getdata.attr) == 'undefined') ? 0 : getdata.attr);
    const [isCllingAdmin, setisCllingAdmin] = useState((typeof (getdata.attr) == 'undefined') ? false : true);

    console.log(getdata)
    const setChatHistotyDatatokenFun = (tokenid, data) => {
        setChatHistotyDatatokenID(tokenid);
        setChatHistotyDatatokenIDData(data);
        get_chathistory(`https://squadgoo.com.au/api.squadgoo.com.au/getchathistory/${useriddata.login_token}?getchat_his_id=${userUniqueID}&chat_token_id=${tokenid}`, 'single_data');
    }



    /*-------------pagination--------------*/

    useEffect(() => {
    get_chathistory(`https://squadgoo.com.au/api.squadgoo.com.au/getchathistory/${useriddata.login_token}?getchat_his_id=${userUniqueID}&search=${searchTerm}&page=${currentActivePage}&search=${searchText}&search2=${searchText2}`); }, [currentActivePage,searchText , searchText2]);
    const RenderPaginationNumbers = () => {
        const paginationNumbers = [];
        paginationNumbers.push(<li className="page-item" ><Link onClick={(e) => handlePageChange(currentActivePage - 1)} className="page-link" href="#"><i className="fa fa-angle-left" aria-hidden="true"></i></Link></li>)
        for (let i = 1; i <= totalPageCount; i++) { paginationNumbers.push(<li key={i} className={i === currentActivePage ? 'page-item active' : 'page-item'} ><Link onClick={() => handlePageChange(i)} className="page-link" href="#">{i}</Link></li>); }
        paginationNumbers.push(<li className="page-item" ><Link onClick={(e) => handlePageChange(currentActivePage + 1)} className="page-link" href="#"><i className="fa fa-angle-right" aria-hidden="true"></i></Link></li>)
        return paginationNumbers;
    };
    const handlePageChange = (newPage) => { if (newPage >= 1 && newPage <= totalPageCount) { setCurrentActivePage(newPage); } };



    /*---------------------*/

    const handleDownload = async () => {
        try {
            // Fetch the file content from the server
            const response = await fetch('https://squadgoo.com.au/api.squadgoo.com.au/downloads/jobseeker_1125.txt', {
                method: 'GET',
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            // Read the response content as an array buffer
            const fileData = await response.arrayBuffer();

            // Create a blob from the file content
            const blob = new Blob([fileData]);

            // Generate a URL for the file
            const url = URL.createObjectURL(blob);

            // Create a link element
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'yourFileName.txt'); // Set the file name and extension

            // Append the link to the body
            document.body.appendChild(link);

            // Trigger the download
            link.click();

            // Cleanup: Remove the link and the created URL after download
            link.parentNode.removeChild(link);
            URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading the file:', error);
            // Handle errors or show an error message to the user
        }
    };

    const swapValues = () => {
        // Swapping state values without a third variable
        let searchText1 =  searchText ;
        setSearchText(searchText2);
        setSearchText2(searchText1);
        // [setSearchText2, setSearchText] = [setSearchText, setSearchText2];
    };

    return (
        <>
            {
                chatHistotyDatatokenID ?
                    <>
                        <div className="col-md-12">
                            <div className="box mb-4">
                                <div className="box-ttl mb-3 d-flex align-items-center justify-content-between">
                                    <h3>
                                        <span className="back_to_hist cursor_pointer" onClick={() => setChatHistotyDatatokenID(0)}>
                                            <i className="fa fa-chevron-left" aria-hidden="true"></i>
                                        </span>

                                        Chat between <span className='text-capitalize ml-1'>{chatHistotyDatatokenIDData.from_user_data.first_name}</span> and
                                        <span className='text-capitalize ml-1'> {chatHistotyDatatokenIDData.to_user_data.first_name}</span>
                                    </h3>
                                </div>
                                <div className="chats">
                                    {
                                        chatHistorySingleData.length ?
                                            <>
                                                {
                                                    chatHistorySingleData.map((item, index) => (
                                                        <>
                                                            <h4>{item.daydate}</h4>
                                                            {
                                                                item.data.map((itemdata, indexdata) => (
                                                                    <>

                                                                        <p className={`${(item.sender_id != itemdata.sender_id) ? 'person1' : 'person2'}`}  >
                                                                            [ {itemdata.time} ]
                                                                            &#60; {(item.sender_id != itemdata.sender_id) ? chatHistotyDatatokenIDData.from_user_data.first_name : chatHistotyDatatokenIDData.to_user_data.first_name} &#62;

                                                                            {
                                                                                (itemdata.attachment && itemdata.attachment.length) ?
                                                                                    <>
                                                                                        {
                                                                                            itemdata.attachment.map((itemattach, indexattach) => (
                                                                                                <>
                                                                                                    <img className='admin chat_row_attachment' key={indexattach} src={'https://squadgoo.com.au/api.squadgoo.com.au/' + itemattach.attachment_url}></img>
                                                                                                </>
                                                                                            ))
                                                                                        }
                                                                                    </>
                                                                                    :
                                                                                    <></>
                                                                            }
                                                                            <span>{itemdata.message}</span>
                                                                        </p>

                                                                    </>
                                                                ))
                                                            }

                                                        </>
                                                    ))


                                                }
                                                <div className="border-top pt-1 mt-1">
                                                    <button onClick={handleDownload} className="batn batn-orange mt-1"><i className="fa fa-download"></i> Download</button>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <p className='no_conversation_found'> No information available to display</p>
                                            </>
                                    }
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className="col-md-12">
                            <div className="box mb-4">


                                {/* <div className="box-ttl mb-3 d-flex align-items-center justify-content-between">
                                    <h3>Chat History</h3>
                                </div> */}
                                <div className="table_filter mb-2 d-flex align-items-center justify-content-between commonform">
                                    <div className="tblleftfilters d-flex align-items-center">
                                    <div className="tblsearch">
                                        <input type="text" className="form-control"
                                            placeholder="Sender"
                                            onChange={(e) => setSearchText(e.target.value)}
                                            value={searchText}
                                        />
                                    </div>
                                    <span className="mx-1">and</span>
                                    <div className="tblsearch">
                                        <input type="text" className="form-control"
                                            placeholder="Receiver"
                                            onChange={(e) => setSearchText2(e.target.value)}
                                            value={searchText2}
                                        />
                                    </div>
                                    <span className="reseticon filtericon " onClick={swapValues} data-toggle="tooltip" title="Swap Filter">
                                        <i className="fa fa-exchange"></i>
                                    </span>
                                    </div>
                                    {/* <input type="button" className="form-control" /> */}
                                    <div className="tblrightfilters d-flex align-items-center">
                                        {/* <select className="form-control ml-3" fdprocessedid="vnfiw">
                                                               <option>Date</option>
                                                            </select> */}

                                        <div className="d-flex align-items-center datefilter">
                                            <input type="date" className="form-control  text-capitalize mr-2" value=""/>
                                            <span>To</span>
                                            <input type="date" className="form-control  text-capitalize ml-2" value=""/>
                                        </div>


                                        <span onClick={() => {
                                                setSearchText('');
                                            }}  
                                            className="reseticon filtericon" data-toggle="tooltip" title="Reset Filter">
                                            <i className="fa fa-refresh"></i>
                                        </span>
                                    </div>
                                </div>
                                {
                                    !screenLoader ?
                                        <>
                                            <div className="table-responsive">

                                                <table className="table admintbl job-offerstbl chathistorytbl">
                                                    <thead>
                                                        <tr>
                                                            <th>Chat between</th>
                                                            <th>Chat initiation date</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            chatHistoryData.length ?
                                                                <>
                                                                    {
                                                                        chatHistoryData.map((item, index) => (
                                                                            <tr key={item.key}>
                                                                                <td>
                                                                                    <div className="d-flex conversationbox p-0 border-0 align-items-center">
                                                                                        <div className="convimage">
                                                                                            <img src={`${item.from_user_data.profilepic ? 'https://squadgoo.com.au/api.squadgoo.com.au/' + item.from_user_data.profilepic : '/front/images/user.svg'}`} />
                                                                                            <img src={`${item.to_user_data.profilepic ? 'https://squadgoo.com.au/api.squadgoo.com.au/' + item.to_user_data.profilepic : '/front/images/user.svg'}`} />
                                                                                        </div>
                                                                                        <div className="conversationmeta">
                                                                                            <h5>
                                                                                                <span className='text-capitalize mr-2'>
                                                                                                <Link className="text-website text-decoration-none1" to={`${item.from_user_data.user_type == 3 ? '/jobseeker-detail?backto=chathistory&id=' + item.from_user_data.unique_id : '/recruiter-detail?backto=chathistory&id=' + item.from_user_data.unique_id}`} >
                                                                                                    {item.from_user_data.first_name ? item.from_user_data.first_name : '-------'}
                                                                                                </Link>
                                                                                                </span>
                                                                                                and
                                                                                                <span className='text-capitalize  ml-2'>
                                                                                                <Link className="text-website text-decoration-none1 text-capitalize" to={`${item.to_user_data.user_type == 3 ? '/jobseeker-detail?backto=chathistory&id=' + item.to_user_data.unique_id : '/recruiter-detail?backto=chathistory&id=' + item.to_user_data.unique_id}`} >
                                                                                                    {item.to_user_data.first_name ? item.to_user_data.first_name : '-------'}
                                                                                                </Link>
                                                                                                </span>
                                                                                            </h5>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td>{item.created_date}</td>
                                                                                <td>

                                                                                    <span className='cursor_pointer' onClick={() => setChatHistotyDatatokenFun(item.token_id, item)} data-toggle="tooltip" title="View Chat History"><img src="/front/images/Show.svg" /></span>
                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                    }

                                                                </>
                                                                :
                                                                <>
                                                                    <tr>
                                                                        <td colSpan={3}>There are no data found</td>
                                                                    </tr>
                                                                </>
                                                        }

                                                    </tbody>
                                                </table>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className='loader_container'>
                                                <p className='text-center'><img className='width-100 loader_image' src={'/front/images/loader.gif'} /></p>
                                            </div>
                                        </>
                                }

                                {
                                    ((totalChatData > paginationLoadContentNo)) ?
                                        <>
                                            <div className="card-box1">
                                                <nav aria-label="Page navigation">
                                                    <ul className="pagination mb-0 justify-content-center">
                                                        <RenderPaginationNumbers />
                                                    </ul>
                                                </nav>
                                            </div>
                                        </>
                                        : ''
                                }

                            </div>
                        </div>
                    </>
            }
        </>
    );
}
export default ChatHistoryComp;