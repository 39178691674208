import React, { useEffect, useState  } from 'react';

const MapWithDirections = (props) => {


    const RANGE_FROM_LOCATION_METERS =  props.formdata.range_from_location ? parseInt(props.formdata.range_from_location)*1000 : 50000 ;
    const LATITUDE =  parseFloat(props.formdata.latitude);
    const LONGITUDE =  parseFloat(props.formdata.longitude);
    const ORIGIN_POINT  =  props.pathA;
    const DESTINATION_POINT  =  props.pathB;

    useEffect(() => {
      const loadGoogleMapScript = () => {
          const googleMapScript = document.createElement('script');
          googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&callback=initMap`;
          window.document.body.appendChild(googleMapScript);
          googleMapScript.addEventListener('load', initMap);
      };


    
      const initMap = () => {
          if (window.google && window.google.maps) {
              const directionsService = new window.google.maps.DirectionsService();
              const directionsRenderer = new window.google.maps.DirectionsRenderer();
              const map = new window.google.maps.Map(document.getElementById('map2'), {
                  zoom: 7,
                  fullscreenControl: false, // Disable fullscreen control
                  zoomControl: false // Disable zoom control
              });
              directionsRenderer.setMap(map);

              const origin = ORIGIN_POINT ;
              const destination = DESTINATION_POINT ;

            
              const originCircle = new window.google.maps.Circle({
                  strokeColor: '#FF0000',
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                  fillColor: '#FF0000',
                  fillOpacity: 0.35,
                  map: map,
                  center:  { lat: LATITUDE , lng:  LONGITUDE },
                  radius: RANGE_FROM_LOCATION_METERS
              });

              const request = {
                  origin: origin,
                  destination: destination,
                  travelMode: window.google.maps.TravelMode.DRIVING
              };

              directionsService.route(request, function(result, status) {
                  if (status === window.google.maps.DirectionsStatus.OK) {
                      directionsRenderer.setDirections(result);
                      const distanceInMeters = result.routes[0].legs[0].distance.value;
                      const distanceInKm = (distanceInMeters / 1000).toFixed(2); // Convert meters to kilometers
                      setDistance(distanceInKm);
                        addDistanceOverlay(map, distanceInKm);

                  } else {
                      window.alert('Directions request failed due to ' + status);
                  }
              });
          } else {
              console.error('Google Maps API not loaded.');
          }
      };

        // Function to add distance overlay to the map
    const addDistanceOverlay = (map, distance) => {
      const distanceOverlay = new window.google.maps.InfoWindow({
          content: `Distance: ${distance} km`,
          position: map.getCenter()
      });
      //console.log(distanceOverlay);
      distanceOverlay.open(map);
      //console.log(distanceOverlay.open(map));
  };

      loadGoogleMapScript();
  }, [props.pathA, props.pathB]);
// { lat: parseFloat(props.formdata.latitude) , lng:  parseFloat(props.formdata.longitude) },

const [distance, setDistance] = useState(null);

    

  return <><div id="map2" style={{ height: '400px', width: '100%' }} /> <p>{distance} Km.</p></> ;
};

export default MapWithDirections;