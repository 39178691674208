import React, { useState, useEffect, Component } from 'react';
import axios from 'axios';
import { Modal } from 'react-bootstrap';

import SuperAdminSidebar from './superadmin_sidebar'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import ShowSoacialMedia from '../ShowSocialMediaComp';

const DeclinedJob = () => {
   const userdetails = JSON.parse(localStorage.getItem("squadGologin"));
   const [currentEditId, setcurrentEditId] = useState(0);
   const [isBlockModelShow, setIsBlockModelShow] = useState(false);
   //const closeBlockModelShow  = () => { setIsBlockModelShow(false) }
   const showUserBlockAction = (id) => { setcurrentEditId(id); setIsBlockModelShow(true) }
   const status_type = ['Status', 'active', 'completed', 'expired'];
   const posted_by_arr = ['Posted By', 'Recruiter', 'Individual', 'Company'];
   const posted_by_arrobj = [
      { key: 1, keyval: '', value: 'Posted By' },
      { key: 2, keyval: 'rec_reprasentative', value: 'Recruiter' },
      { key: 3, keyval: 'rec_indivisual', value: 'Individual' },
      { key: 4, keyval: 'rec_owners', value: 'Company' }
   ];
   
   const [moduleAccessibility] = useState(typeof (userdetails.accessibility) != undefined ? userdetails.accessibility.jobs_offers : [{
      view: true,
      edit: true,
      delete: true,
   }]);
   const viewAccessibility = (moduleAccessibility != undefined && typeof (moduleAccessibility.view) != undefined) ? moduleAccessibility.view : true;
   const editAccessibility = (moduleAccessibility != undefined && typeof (moduleAccessibility.edit) != undefined) ? moduleAccessibility.edit : true;
   const deleteAccessibility = (moduleAccessibility != undefined && typeof (moduleAccessibility.delete) != undefined) ? moduleAccessibility.delete : true;
   const [jobOfferDataArr, setOfferDataArr] = useState([]);
   const [currentPageActive, setCurrentPageActive] = useState(1);
   const [dataLength, setDataLength] = useState(0);
   const [totalPageLength, setTotalPageLength] = useState(1);
   const [dataisLoading, setDataisLoading] = useState(true);
   const [dataLoading, setDataLoading] = useState(false);
   const [tableOrderType, settableOrderType] = useState('ASC');
   const [searchText, setSearchText] = useState('');
   const [postedByCheck, setPostedByCheck] = useState('');
   const [jobStatus, setJobStatus] = useState('');
   //const [offerDataArr, setofferDataArr] = useState([]);


   const getOffersData = () => {
      (async () => {
         setDataisLoading(true);
         try {
            const formData = new FormData();
            axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/get_declinedjobs_for_superadmin/${userdetails.login_token}?page=${currentPageActive}&search=${searchText}&postedby=${postedByCheck}&status=${jobStatus}&fromDate=${fromDate}&toDate=${toDate}&ordertype=${tableOrderType}`, formData)
               .then((response) => {
                  setDataisLoading(false);
                  console.log(response.data.data);
                  if (response.status == 200 && response.data.status == 1) {
                     setOfferDataArr(response.data.data.offerdata);
                     setDataLength(response.data.data.length);
                     const calculatedTotalPages = Math.ceil(response.data.data.length / 10);
                     setTotalPageLength(calculatedTotalPages);
                  }
               })
               .catch((error) => { console.error(error); });
         } catch (error) { console.log(error); }

      })();
   }
   useEffect(() => { getOffersData(); }, [searchText, postedByCheck, jobStatus, currentPageActive , tableOrderType ]);


   const CheckStatusHtml = (data) => {

      let className = 'bluebtn';
      switch (data.status) {
         case 'expire':
            className = 'btn-danger';
            break;
         case 'completed':
            className = 'btn-green';
            break;

         case 'draft':
            className = 'btn-info';
            break;
      }
      return (
         <div className={`btnsmall text-capitalize text-white ${className} ${data.status}`}>
            {data.status == 'expire' ? 'expired' : data.status}
         </div>
      )
   }


   /*********Pagination**********/
   const handlePageChange = (newPage) => {
      setCurrentPageActive(newPage);
   };

   const RenderCompletedPaginationNumbers = () => {
      const paginationNumbers = [];
      paginationNumbers.push(<li className="page-item" onClick={(e) => handlePageChange(currentPageActive - 1)} ><a className="page-link" href="#"><i className="fa fa-angle-left" aria-hidden="true"></i></a></li>)
      for (let i = 1; i <= totalPageLength; i++) {
         paginationNumbers.push(<li key={i} onClick={() => handlePageChange(i)} className={i === currentPageActive ? 'page-item active' : 'page-item'} ><a className="page-link" href="#">{i}</a></li>);
      }
      paginationNumbers.push(<li className="page-item" onClick={(e) => handlePageChange(currentPageActive + 1)} ><a className="page-link" href="#"><i className="fa fa-angle-right" aria-hidden="true"></i></a></li>)
      return paginationNumbers;
   };


   


   /*----------------date swaping--------------------*/
   const [fromDate, setFromDate] = useState('');
   const [toDate, setToDate] = useState('');

   const handleFromDateChange = (event) => {
      const newFromDate = event.target.value;
      // Check if newFromDate is less than toDate
      if (!toDate || new Date(newFromDate) < new Date(toDate)) {
         setFromDate(newFromDate);
      } else { setFromDate(toDate); setToDate(newFromDate); }
   };

   const handleToDateChange = (event) => {
      const newToDate = event.target.value;
      if (!fromDate || new Date(newToDate) > new Date(fromDate)) {
         setToDate(newToDate);
      } else { setToDate(fromDate); setFromDate(newToDate); }
   };

   useEffect(() => {
      if (fromDate && toDate) { getOffersData(); }
   }, [fromDate, toDate]);


   const showUserBlockActionExecute = () => {
    (async () => {
       setDataLoading(true)
       try {
          const formData = new FormData();
          axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/jobform_and_offers_delete/${userdetails.login_token}/${currentEditId}`, formData)
             .then((response) => {
                setDataLoading(false);
                if (response.status == 200 && response.data.status == 1) {
                   setIsBlockModelShow(false);
                   setcurrentEditId(false);
                   getOffersData();
                   toast.success(response.data.message);
                }
             })
             .catch((error) => { console.error(error); });
       } catch (error) { console.log(error); }

    })();
 }

   return (
      <>
         <div className="dashboarwrap">

            <div className="pagebanner">
               <div className="container">
                  <div className="row">
                     <div className="col-md-12">
                        <h2>Job Offers</h2>
                     </div>
                  </div>
               </div>
            </div>


            {
               (userdetails.email_status == 1 && userdetails.contact_status == 1) ?
                  <>
                     <div className="adminwrap py-4">
                        <div className="container">
                           <div className="row">
                              <div className="col-md-3 adminsidebar">
                                 <SuperAdminSidebar></SuperAdminSidebar>
                              </div>
                              <div className="col-md-9">
                                 <div className="row">
                                    <div className="col-md-12">
                                       <div className="box mb-4">
                                          {/* <div className="box-ttl mb-3 d-flex align-items-center justify-content-between">
                                             <h3>Job offers</h3>
                                          </div> */}
                                          <div className="table_filter mb-2 d-flex align-items-center justify-content-between commonform">
                                             <div className="tblsearch d-flex align-items-center">
                                                {/* <span className="filtericon" data-toggle="tooltip" title="Export in pdf">
                                                   <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
                                                </span> */}
                                                <input onChange={(e) => setSearchText(e.target.value)} value={searchText} type="text" className="form-control" placeholder="Search" />
                                             </div>
                                             <div className="tblrightfilters d-flex align-items-center">
                                                <div className="d-flex align-items-center datefilter">
                                                   <input value={fromDate} onChange={handleFromDateChange} type="date" className="form-control  text-capitalize mr-2"></input>
                                                   <span>To</span>
                                                   <input value={toDate} onChange={handleToDateChange} type="date" className="form-control  text-capitalize ml-2"></input>
                                                </div>
                                                {/* <select onChange={(e) => setPostedByCheck(e.target.value)} className="form-control  text-capitalize ml-3" >
                                                   {
                                                      posted_by_arrobj.map((item, index) => (
                                                         <option selected={postedByCheck == item.keyval} key={item.key} value={item.keyval}>
                                                            <span className='text-capitalize'>{item.value}</span>
                                                         </option>
                                                      ))
                                                   }
                                                </select>
                                                <select onChange={(e) => setJobStatus(e.target.value)} className="form-control ml-3 text-capitalize" >
                                                   {
                                                      status_type.map((item, index) => (
                                                         <option selected={jobStatus == item} key={item} value={item}>
                                                            <span className='text-capitalize'>{item}</span>
                                                         </option>
                                                      ))
                                                   }

                                                </select> */}

                                                <span onClick={() => { setFromDate(''); setToDate(''); setCurrentPageActive(1); setSearchText(''); setPostedByCheck(''); setJobStatus(''); getOffersData() }} className="reseticon filtericon" data-toggle="tooltip" title="Reset filters">
                                                   <i className="fa fa-refresh"></i>
                                                </span>

                                             </div>

                                          </div>
                                     
                                          <div className="table-responsive">
                                             {
                                                !dataisLoading ?
                                                   <>
                                                      <table className="table admintbl text-capitalize1 joboffertbl">
                                                         <thead>
                                                            <tr>
                                                               <th onClick={() => settableOrderType((tableOrderType == 'ASC' ? 'DESC' :'ASC'))}>
                                                               {tableOrderType == 'ASC' ? <i className="fa fa-angle-down ml-5"></i> : <i className="fa fa-angle-up ml-5"></i> }
                                                               </th>
                                                               <th>Job title</th>
                                                               <th>Recruiter</th>
                                                               <th>Staff</th>
                                                               <th>Message</th>
                                                               <th>Reason</th>
                                                               <th>Date</th>
                                                               <th>Action</th>
                                                            </tr>
                                                         </thead>
                                                         <tbody>
                                                            {
                                                               jobOfferDataArr.length ?
                                                                  jobOfferDataArr.map((item, index) => (
                                                                     <>
                                                                        <tr key={item.id}>
                                                                           <td>

                                                                              {
                                                                                 item.user_type_role == 'rec_owners' ? <img className="usertypeicon" data-toggle="tooltip" data-placement="top" title="Owner" src={'front/images/building.svg'}></img>
                                                                                    : <img className="usertypeicon" data-toggle="tooltip" data-placement="top" title="Indivisual" src={'front/images/profile.svg'}></img>
                                                                              }
                                                                           </td>
                                                                           <td className='text-capitalize'>
                                                                              <b>{item.job_title_value}</b>
                                                                           </td>

                                                                           <td className='text-capitalize'>
                                                                              <Link target='_balnk' to={`/recruiter-detail?id=${item.rec_unique_id}`}>
                                                                                 {item.rec_first_name} {item.rec_last_name}
                                                                              </Link>
                                                                           </td>

                                                                           <td className='text-capitalize'>
                                                                              <Link target='_balnk' to={`/recruiter-detail?id=${item.js_unique_id}`}>
                                                                                 {item.js_first_name} {item.js_last_name}
                                                                              </Link>
                                                                           </td>
                                                                           <td className='text-lowercase'>{item.declined_message}</td>
                                                                           <td className='text-lowercase'>{item.declined_reason}</td>
                                                                           <td className='text-lowercase'>{item.created_date}</td>
                                                                           <td>
                                                                              <div className="d-flex">
                                                                                 {editAccessibility && <Link onClick={(e) => showUserBlockAction(item.form_id)} className="ml-3" data-toggle="tooltip" title="Delete Offer "><img src="/front/images/trash.svg" /></Link>}

                                                                                 {/* <Link className="ml-3" onClick={(e) => showUserBlockAction(item.offer_id, item.job_form_id)} >
                                                                                    <i className="faicon fa fa-trash" aria-hidden="true"></i>
                                                                                 </Link> */}
                                                                                 {/* <a href="#" className="ml-3"><img src="/front/images/TickSquare.svg" /></a>
                                                                                 <a href="#" className="ml-3"><img src="/front/images/trash.svg" /></a> */}
                                                                              </div>
                                                                           </td>
                                                                        </tr>
                                                                     </>
                                                                  ))
                                                                  :
                                                                  <>
                                                                     <tr>
                                                                        <td className='text-center' colSpan={8}>No information available to display</td>
                                                                     </tr>
                                                                  </>
                                                            }


                                                         </tbody>
                                                      </table>
                                                      {
                                                         dataLength > 10 ?
                                                            <>
                                                               <div className="card-box">
                                                                  <nav aria-label="Page navigation">
                                                                     <ul className="pagination mb-0 justify-content-center">
                                                                        <RenderCompletedPaginationNumbers />
                                                                     </ul>
                                                                  </nav>
                                                               </div>
                                                            </>
                                                            : <></>
                                                      }
                                                   </>
                                                   :
                                                   <>
                                                      <div className={`tab-pane fade show active mt45px`} >
                                                         <div className='loader_container'>
                                                            <p className='text-center'><img className='width-100 loader_image' src={'/front/images/loader.gif'} /></p>
                                                         </div>
                                                      </div>
                                                   </>
                                             }
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </>
                  :
                  <>
                     Please Activate your account
                  </>
            }
         </div>
         <Modal className="modal fade modalsmall jobacceptmodle" id="jobacceptmodle" show={isBlockModelShow} onHide={() => setIsBlockModelShow(false)} >
                <div className="modal-dialog1">
                <div className="modal-content">
                    <div className="modal-body">
                        <button type="button" className="btn-close" onClick={() => setIsBlockModelShow(false)}></button>
                        <div className="text-center mb-3">
                            {/* <img src="/front/images/greencheck.svg" /> */}
                        </div>
                        <h5 className="text-center mb-4">Do you want to delete?</h5>

                        {
                            dataLoading ?
                            <>
                                <p className='text-center'>Please wait.....</p>
                            </>
                            :
                            <div className="btnsgroup text-center">
                                <Link onClick={() => showUserBlockActionExecute()} className="batn batn-orange">Yes</Link>
                                <Link onClick={() => setIsBlockModelShow(false)} className="batn batn-orange batn-border">No</Link>
                            </div>
                        }

                    </div>
                </div>
                </div>
            </Modal>
      </>
   );
}
export default DeclinedJob;