import React, { useState, useEffect, Component } from 'react';
import { Link } from 'react-router-dom';
import { Form, Modal } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';
import JsrProModalComp from './JsrProModalcom';
import StarRating from '../../expocomp/StarRatingComp';


import { useDispatch } from 'react-redux'
import { recdeshjobactioncheck } from '../../redux_state/reducres/findStaffBtnAction';

const MannualSearch = () => {
   //const navigate = useNavigate();
   const dispatch = useDispatch();
   const [staffSearchResult, setStaffSearchResult] = useState([]);
   const [staffSearchResultAsc, setStaffSearchResultAsc] = useState([]);
   const [staffSearchResultDesc, setStaffSearchResultDesc] = useState([]);
   const [searchJobtitle, setSearchJobtitle] = useState('.....');
   const [jobTypeList, setJobTypeList] = useState([]);
   const [filterData, setFilterData] = useState([]);
   const [filterDataBk, setFilterDataBk] = useState([]);

   const [isValidateLocation, setIsValidateLocation] = useState(false);
   const [locationTags, setLocationTags] = useState([]);
   const [inputValue, setInputValue] = useState('');


   const [isViewDetailModalShow, isSetViewDetailModalShow] = useState(false);
   const [isModalDataLoad, setIsModalDataLoad] = useState(false);
   const [isDataLoading, setIsDataLoading] = useState(true);
   const [staffViewDetailsDataArr, setstaffViewDetailsDataArr] = useState([]);
   const [selectedStaffViewDetailsDataArr, setSelectedStaffViewDetailsDataArr] = useState([]);
   const [currentFormID, setCurrentFormID] = useState(localStorage.getItem('finsStaffFormID'));
   const [jobStatusType, setjobStatusType] = useState('pending');
   /*on compnent load take data from localstaorage*/
   const useriddata = JSON.parse(localStorage.getItem("squadGologinSession"));
   let sessionStorageData = JSON.parse(localStorage.getItem("sessionStorageData"));

   const [shortByData, setShortByData] = useState('asc')


   const getManualSearchData = (formid) => {

      (async () => {
         try {
            const response = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/staff_search/" + useriddata.login_token + "/" + formid + "?shortby=" + shortByData);
            if (response.data.status == 1) {
               setStaffSearchResult(response.data.data.final_array);
               setStaffSearchResultAsc(response.data.data.final_array_asc);
               setStaffSearchResultDesc(response.data.data.final_array);
               setSearchJobtitle(response.data.data.job_title_name);
               setFilterData(response.data.data.filter_data);
               setFilterDataBk(response.data.data.filter_data);
               setLocationTags(response.data.data.filter_data.job_location);
            }
            setIsDataLoading(false);
         } catch (error) { console.log(error); }
      })();
   }

   useEffect(() => {
      const queryParameters = new URLSearchParams(window.location.search)
      const jobofferid = queryParameters.get("formid");
      if (jobofferid) {
         getManualSearchData(jobofferid);
         setCurrentFormID(jobofferid);
         localStorage.setItem('finsStaffFormID', jobofferid);
      } else {
         if (JSON.parse(localStorage.getItem('finsStaffForm1DataArray'))) { getManualSearchData(currentFormID) }
         else { setTimeout(window.location.replace("/recruiter/dashboard"), 300); }
      }
   }, [shortByData]);

   useEffect(() => {
      const sesJobTypeList = sessionStorageData?.get_all_jobtype ? sessionStorageData.get_all_jobtype : [];
      if (sesJobTypeList.length > 0) {
         setJobTypeList(sesJobTypeList);
      } else {
         (async () => {
            try {
               const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/get_all_jobtype");
               if (resData.data.status == 1) { setJobTypeList(resData.data.data.results); }
            } catch (error) { console.log(error); }
         })();
      }
   }, []);


   /*model form details */
   const viewDetailModel = (id, itemdata, status) => {
      setIsModalDataLoad(true); setIsLoadingSendOffer(false);

      console.log(itemdata);
      setjobStatusType(itemdata.offer_sent_status);
      (async () => {
         try {
            const formData = new FormData();
            formData.append('login_token', useriddata.login_token);
            formData.append('user_unique_id', useriddata.unique_id);
            formData.append('staff_id', id);
            axios.post('https://squadgoo.com.au/api.squadgoo.com.au/findstaff_detail', formData)
               .then((response) => {
                  setIsModalDataLoad(false);
                  if (response.status == 200 && response.data.status == 1) { setSelectedStaffViewDetailsDataArr(itemdata); setstaffViewDetailsDataArr(response.data.data); }
               })
               .catch((error) => { console.error(error); });
         } catch (error) { console.log(error); setIsModalDataLoad(false); }

      })()
      isSetViewDetailModalShow(true);
   }
   const closeResumeModal = () => { setIsLoadingSendOffer(false); isSetViewDetailModalShow(false) };
   /*sidebar filter form */
   const handleInputChange = (event) => { setInputValue(event.target.value); };
   const handleInputKeyDown = (event) => { if (event.key === 'Enter' && inputValue !== '') { event.preventDefault(); setLocationTags([...locationTags, inputValue]); setInputValue(''); } };
   const handleTagRemove = (tagToRemove) => { const updatedTags = locationTags.filter((tag) => tag !== tagToRemove); setLocationTags(updatedTags); };
   const YearExpNumberLoop = (attr = '') => { const itemElements = []; itemElements.push(<option value="">Years</option>); for (let i = 1; i < 50; i++) { itemElements.push(<option selected={(attr != '' && attr.select_attr == i) ? true : false} value={i}>{i} {i == 1 ? 'Year' : 'Years'}</option>); } return itemElements }
   const MonthExpNumberLoop = (attr = '') => { const itemElements = []; itemElements.push(<option value="">Month</option>); for (let i = 1; i < 12; i++) { itemElements.push(<option selected={(attr != '' && attr.select_attr == i) ? true : false} value={i}>{i} Month{i == 1 ? '' : 's'}</option>); } return itemElements }

   const onChangeFilterFun = (event) => { const { name, value } = event.target; setFilterData((prevFormData) => ({ ...prevFormData, [name]: value })); };
   const reSetFormData = () => { setFilterData(filterDataBk); }
   const [validated, setValidated] = useState(false);
   const onFinalSubmit = async (event) => {
      event.preventDefault();
      const useriddata = JSON.parse(localStorage.getItem("squadGologinSession"));
      const form = event.currentTarget;
      (locationTags.length == 0) ? setIsValidateLocation(true) : setIsValidateLocation(false);
      if (form.checkValidity() === false) { event.preventDefault(); event.stopPropagation(); }
      else {
         try {
            const findStafFormDataArray = {};
            const formData = new FormData(event.target);
            formData.forEach((value, name) => {
               findStafFormDataArray[name] = value;
            });
            //const formData = new FormData();
            setIsDataLoading(true);
            formData.append('login_token', useriddata.login_token);
            formData.append('form_id', currentFormID);
            formData.append('job_title', filterData.job_title);
            formData.append('user_unique_id', useriddata.unique_id);
            formData.append('job_location', JSON.stringify(locationTags));
            //formData.append('job_location', (locationTags));
            //https://squadgoo.com.au/api.squadgoo.com.au/staff_search/" + useriddata.login_token + "/" + currentFormID
            axios.post(`https://squadgoo.com.au/api.squadgoo.com.au/findstaffdata`, formData)
               .then((response) => {
                  if (response.status == 200 && response.data.status == 1) {
                     setStaffSearchResult(response.data.data.final_array);
                     setFilterData(response.data.data.filter_data);
                     setFilterDataBk(response.data.data.filter_data);
                     setLocationTags(response.data.data.filter_data.job_location);
                  }
                  setIsDataLoading(false);

               })
               .catch((error) => { console.error(error); });
         } catch (error) { console.log(error); }
         //locationTags
      }
      setValidated(true);
   };




   /*send offer details */
   const [isLoadingSendOffer, setIsLoadingSendOffer] = useState(false);
   const sendOfferToStaffModel = (id, itemdata) => {
      setIsLoadingSendOffer(true);
      const useriddata = JSON.parse(localStorage.getItem("squadGologinSession"));
      //currentFormID
      try {
         const formData = new FormData();
         //const formData = new FormData();
         formData.append('user_unique_id', useriddata.unique_id);
         formData.append('filter_data', JSON.stringify(filterData));
         formData.append('send_offerdata', JSON.stringify(
            {
               'job_title': searchJobtitle,
               'candidate_first_name': itemdata.first_name,
               'candidate_last_name': itemdata.last_name
            }
         ));
         formData.append('staff_id', id);
         formData.append('current_form_id', currentFormID);
         formData.append('form_process_type', 'manually');
         //formData.append('job_location', (locationTags));
         axios.post('https://squadgoo.com.au/api.squadgoo.com.au/sendoffer_to_staff', formData)
            .then((response) => {
               setIsLoadingSendOffer(false);
               if (response.status == 200 && response.data.status == 1) {
                  isSetViewDetailModalShow(false);
                  getManualSearchData(currentFormID);
                  toast.success(response.data.message);
               } else {
                  toast.warning(response.data.message);
               }
            })
            .catch((error) => { console.error(error); });
      } catch (error) { console.log(error); }
   }



   const cancelOfferToStaffModel = (id, itemdata) => {
      setIsLoadingSendOffer(true);
      const useriddata = JSON.parse(localStorage.getItem("squadGologinSession"));
      //currentFormID
      try {
         const formData = new FormData();
         //const formData = new FormData();
         formData.append('user_unique_id', useriddata.unique_id);
         formData.append('filter_data', JSON.stringify(filterData));
         formData.append('send_offerdata', JSON.stringify(
            {
               'job_title': searchJobtitle,
               'candidate_first_name': itemdata.first_name,
               'candidate_last_name': itemdata.last_name
            }
         ));
         formData.append('staff_id', id);
         formData.append('current_form_id', currentFormID);
         formData.append('form_process_type', 'manually');
         //formData.append('job_location', (locationTags));
         axios.post('https://squadgoo.com.au/api.squadgoo.com.au/canceloffer_to_staff', formData)
            .then((response) => {
               setIsLoadingSendOffer(false);
               if (response.status == 200 && response.data.status == 1) {
                  isSetViewDetailModalShow(false);
                  getManualSearchData(currentFormID);
                  toast.success(response.data.message);
               } else {
                  toast.warning(response.data.message);
               }
            })
            .catch((error) => { console.error(error); });
      } catch (error) { console.log(error); }
   }



   return (
      <>
         {/* <ToastContainer />*/}
         <div className="dashboarwrap">
            <div className="jobbanner">
               <div className="container">
                  <div className="row">
                     <div className="col-md-12">
                        <h2><span>Search result for</span> {searchJobtitle ? searchJobtitle : '.....'}</h2>
                        <form className="searchform d-flex">
                           <input type="text" className="form-control jobcategorysearch" placeholder="Search for staff here"></input>
                        </form>
                     </div>
                  </div>
               </div>
            </div>
            <div className="jobbtmsec py-4">
               <div className="container">
                  <div className="row">
                     <div className="col-md-3 sidebarwrap">
                        <div className="jobfilter">
                           <h3 className="mb-4">Filters</h3>
                           <div className="jobfilterinner card-box">
                              <Form className="commonform" noValidate validated={validated} onSubmit={onFinalSubmit} >

                                 <div className="filtersecblock">
                                    <div className="form-group">
                                       <label className="form-label">Job type<span className="starr">*</span></label>
                                       <div className="fieldunit">
                                          <select name="job_type" onChange={onChangeFilterFun} className="form-control" required aria-label="Default select example">
                                             <option value=''>Select</option>
                                             {
                                                jobTypeList.map((item, index) => (
                                                   <option selected={filterData.job_type == item.id} value={item.id} datatitle={item.jobtype} >{item.jobtype}</option>
                                                ))
                                             }
                                          </select>
                                       </div>
                                    </div>
                                 </div>

                                 {/* <div className="filtersecblock">
                                    <div className="form-group">
                                       <label className="form-label">Location<span className="starr">*</span></label>
                                       <div className="fieldunit">
                                          
                                       </div>
                                    </div>
                                 </div> */}

                                 <div className="filtersecblock">
                                    <div className="form-group">
                                       <label className="form-label">Range from location<span className="starr">*</span></label>
                                       <div className="fieldunit">
                                          <input required=""
                                             onChange={onChangeFilterFun}
                                             name="range_from_location"
                                             placeholder="Add distance from your location to hire"
                                             type="number" className="form-control " value={filterData.range_from_location} />
                                          <span className="fieldsub">km</span>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="filtersecblock">
                                    <div className="form-group">
                                       <label className="form-label">Total experience needed</label>
                                       <div className="fieldunit row">
                                          {
                                             <>
                                                <div className="col-md-6">
                                                   <select onChange={onChangeFilterFun} name="total_experience_year" className="form-control">
                                                      <YearExpNumberLoop select_attr={filterData.total_experience_year} />
                                                   </select>
                                                </div>
                                                <div className="col-md-6">
                                                   <select onChange={onChangeFilterFun} name="total_experience_month" className="form-control">
                                                      <MonthExpNumberLoop select_attr={filterData.total_experience_month} />
                                                   </select>
                                                </div>
                                             </>
                                          }
                                       </div>
                                    </div>
                                 </div>
                                 <div className="filtersecblock">
                                    <div className="form-group">
                                       <label className="form-label">Salary you are offering<span className="starr">*</span></label>
                                       <div className="fieldunit row">
                                          <div className="col-md-6">
                                             <div className="input-group mb-2 salaryfield2">
                                                <div className="input-group-prepend">
                                                   <div className="input-group-text">$</div>
                                                </div>
                                                <input required={true} type="number" onChange={onChangeFilterFun} value={filterData.salary_minimum} name="salary_minimum" maxlength="3" className="form-control" placeholder="Minimum" />
                                                {/* <p>/per hour</p> */}
                                             </div>
                                          </div>
                                          <div className="col-md-6">
                                             <div className="input-group mb-2 salaryfield2">
                                                <div className="input-group-prepend">
                                                   <div className="input-group-text">$</div>
                                                </div>
                                                <input required={true} type="number" onChange={onChangeFilterFun} value={filterData.salary_maximum} name="salary_maximum" maxlength="3" className="form-control" placeholder="Maximum" />
                                                {/* <p>/per hour</p> */}
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>

                                 <div className="filtersecblock">
                                    <div className="form-group row">
                                       <div className="col-md-12">
                                          <input type="Submit" className="batn batn-orange w-100" value="Apply Filter"></input>
                                       </div>
                                       <div className="col-md-12 mt-2">
                                          <input type="button" onClick={reSetFormData} className="batn batn-orange w-100" value="Reset"></input>
                                       </div>
                                    </div>
                                 </div>

                              </Form>
                           </div>
                        </div>
                     </div>
                     <div className="col-md-9">
                        <div className="d-flex flex-wrap justify-content-between mb-2">
                           <h3>
                              {
                                 staffSearchResult.length ?
                                    <>{staffSearchResult.length} {searchJobtitle} available</>
                                    :
                                    <>{isDataLoading ? <></> : <>{staffSearchResult.length + ' records matched'}</>}</>
                              }
                           </h3>
                           <div className="sort_option d-flex">
                              <p>Sort by</p>
                              <select className="form-control" onChange={(e) => setShortByData(e.target.value)}>
                                 {/* <option value="">Pay</option> */}
                                 <option value="asc">Low to High</option>
                                 <option value="desc" selected={(shortByData == 'desc') ? true : false}>High to Low</option>
                              </select>
                           </div>
                        </div>
                        <div className="joblistingwrap">
                           <div className="row">
                              {isDataLoading}
                              {

                                 isDataLoading ?
                                    <>
                                       <div className={`laodingdata`}>
                                          <img src={'/front/images/loader.gif'} />
                                       </div>
                                    </>
                                    :
                                    staffSearchResult.length ?
                                       <>
                                          {
                                             staffSearchResult.map((item, index) => (
                                                <>
                                                   <div className="col-md-4 ">
                                                      <div className="card-box joblistbox staflistw staflistwnew">
                                                         <div className="staffPprice_match ml-auto cursor_pointer" onClick={() => viewDetailModel(item.unique_id, item, item.offer_status)} >
                                                            <h3 className="job_price">${item.expect_salary_from_decimal}</h3>
                                                            <div className="d-flex">
                                                               {
                                                                  item.offer_sent_status == 'not_sent' ?
                                                                     <><span className="matchbar"><span className={`matchbariner w${item.profile_match}`}></span></span>{item.profile_match}% matched</>
                                                                     :
                                                                     <>
                                                                        {item.offer_sent_status}
                                                                        <span style={{ width: '52%' }}></span>
                                                                        <div class="">{item.profile_match}% matched</div>
                                                                     </>
                                                               }


                                                            </div>
                                                         </div>
                                                         <div className="d-flex flex-wrap stafflisttop">
                                                            <div className="staffimgg"><img src={`https://squadgoo.com.au/api.squadgoo.com.au/${item.profilepic}`} /></div>
                                                            <div className="staffnamerating">
                                                               <h3 className='text-capitalize cursor_pointer ' onClick={() => viewDetailModel(item.unique_id, item, item.offer_status)}>{item.first_name + ' ' + item.last_name}</h3>
                                                               <div className="reting d-flex">
                                                                  <div className="stars d-flex">
                                                                     <StarRating rating={(typeof (item.total_rating_count) != 'undefined' && item.total_rating_count.average_rating != null) ? item.total_rating_count.average_rating : 0} />
                                                                  </div>
                                                                  <p>
                                                                     <Link className='m-0 cursor_pointer' to={`/reviews?uniqueid=${item.unique_id}`} >
                                                                        {(typeof (item.total_rating_count) != 'undefined' && item.total_rating_count.average_rating != null) ? item.total_rating_count.average_rating : 0} |
                                                                        <span className='ml-2' >{item.total_rating_count.total_count} Reviews</span>
                                                                     </Link>
                                                                  </p>
                                                               </div>
                                                               <div className="d-flex flex-wrap stafdata">
                                                                  <div className="icontxt">Location: <span className='text-capitalize'>{item.prefered_location_string.prefered_location ? item.prefered_location_string.prefered_location : '.......'}</span></div>
                                                               </div>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </>
                                             ))
                                          }
                                       </>
                                       :
                                       <>
                                          <div className="card-box joblistbox staflistw">
                                             {isDataLoading ?
                                                <>Loading......</> :
                                                <>
                                                   Profile Not matched <br></br>
                                                   <Link
                                                      onClick={() => dispatch(recdeshjobactioncheck({ id: currentFormID, actiontype: 'edit', screentype: 'active_job', searchtype: "manually" }))} className="text-orange ">Edit post
                                                   </Link>
                                                </>
                                             }
                                          </div>
                                       </>
                              }

                           </div>
                        </div>
                        {
                           staffSearchResult.length > 10 ?
                              <>
                                 <div className="card-box">
                                    <nav aria-label="Page navigation">
                                       <ul className="pagination mb-0 justify-content-center">
                                          <li className="page-item "><a className="page-link" href="#"><i className="fa fa-angle-left" aria-hidden="true"></i></a></li>
                                          <li className="page-item active"><a className="page-link" href="#">1</a></li>
                                          <li className="page-item"><a className="page-link" href="#"><i className="fa fa-angle-right" aria-hidden="true"></i></a></li>
                                       </ul>
                                    </nav>
                                 </div>
                              </>
                              : ''
                        }
                     </div>
                  </div>
               </div>
            </div>


            {/* send offer to staff */}
            {isViewDetailModalShow && <Modal className="modal fade modallarg staffmodal jobmodle admininfo" show={isViewDetailModalShow} onHide={closeResumeModal}>
               <div className="modal-dialog1 viewdedatil_model">

                  <div className="modal-content">
                     <div className="modal-body">
                        <button type="button" className="btn-close" onClick={closeResumeModal}></button>
                        <div className="joblistbox">
                           {/* jobseeker profile model */}
                           <JsrProModalComp data={staffViewDetailsDataArr} jobStatusType={jobStatusType} />
                           {(jobStatusType == 'not_sent') ?

                              <div className="btnsgroup">
                                 {/* <Link onClick={() => sendOfferToStaffModel(staffViewDetailsDataArr.unique_id, staffViewDetailsDataArr)} className="batn batn-orange" disabled={isLoadingSendOffer} >Send offer
                                    {isLoadingSendOffer ? <><img src={'/front/images/loader.gif'} /></> : <></>}
                                 </Link>
                                 <Link onClick={closeResumeModal} className="batn batn-orange" >Cancel</Link> */}

                                 <button onClick={() => sendOfferToStaffModel(staffViewDetailsDataArr.unique_id, staffViewDetailsDataArr)} className="batn batn-orange" disabled={isLoadingSendOffer} >Send offer
                                    {isLoadingSendOffer ? <><img src={'/front/images/loading2.gif'} /></> : <></>}
                                 </button>
                                 <button onClick={closeResumeModal} className="batn batn-orange" >Cancel</button>
                              </div> :

                              (jobStatusType == 'pending') ?
                                 <>
                                    <>
                                       <button onClick={() => cancelOfferToStaffModel(staffViewDetailsDataArr.unique_id, staffViewDetailsDataArr)} className="batn batn-orange" disabled={isLoadingSendOffer} >Cancel offer
                                          {isLoadingSendOffer ? <><img src={'/front/images/loading2.gif'} /></> : <></>}
                                       </button>
                                    </>
                                 </>
                                 :
                                 <></>
                           }

                        </div>
                     </div>
                     <div className={`model_loader ${!isModalDataLoad ? 'display_none' : ''}`}>
                        <img src={'/front/images/loader.gif'} />
                     </div>
                  </div>
               </div>
            </Modal>}

         </div>
      </>
   );
}
export default MannualSearch;