import { createSlice } from '@reduxjs/toolkit'

export const adminSideVerificationStatus = createSlice({
  name: 'adminSideVerificationStatus',
  initialState: {
    adminside_verification : 0,
    admin_side_ucontact_verification : 0 
  }, 
  reducers: {

    admin_side_profile_verification: (state , action) => {
        state.adminside_verification = action.payload ;
    },
    admin_side_usercontact_verification: (state , action) => {
        state.admin_side_ucontact_verification = action.payload ;
    }
   
  },
})
export const { 
        admin_side_profile_verification , 
        admin_side_usercontact_verification  
    } = adminSideVerificationStatus.actions

export default adminSideVerificationStatus.reducer