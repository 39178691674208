import React, { useState, useEffect, Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';
import WorkExpComp from '../../WorkExpComp';
const JobSeekWorkHistory = () => {
    const loggedInUser = JSON.parse(localStorage.getItem("squadGologin"));
    const [userID, setuserID] = useState(0);
    const [jobseekerData, setJobseekerData] = useState([]);

    const [isUserVerified, setIsUserVerified] = useState(false);
    const [isDocuemntLoad, setisDocuemntLoad] = useState('false');

    useEffect(() => {
       // console.log(loggedInUser.login_token);
        const queryParameters = new URLSearchParams(window.location.search)
        const userid = queryParameters.get("id");
        if (userid) {
            setuserID(userid);
            getUserDetailsFun(userid);
        }
    }, []);


    const getUserDetailsFun = (userid) => {
        setisDocuemntLoad('false');
        {
            userid && (async () => {
                try {
                    const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/get_jobseeker_workhistory/"+loggedInUser.login_token+"/" + userid);
                    if (resData.data.status == 1) {
                        setisDocuemntLoad(true);
                        setJobseekerData(resData.data.data);
                    }
                } catch (error) { console.log(error); }
            })()
        }
    }





    return (
        <>

        {
            jobseekerData.length > 0 ?
            jobseekerData.map((itme,index) => (
                <>
                <div className="joblistingwrap">
                    <div className="card-box joblistbox1 mb-3">
                        <div className="d-flex flex-wrap justify-content-between">
                            <h3 >{itme.get_job_title}</h3>
                            <h3 className="job_price orange_clr">
                            {itme.fixed_salary != '0.00' ? '$'+itme.fixed_salary : ('$'+itme.salary_maximum+'/Hour '+'$'+itme.salary_minimum+'/Hour')}
                            </h3>
                        </div>
                        <div>
                            <h5 className="mb-3">Hired by: 
                            <Link className="text-website text-decoration-none1 text-capitalize ml-2" to={`/recruiter-detail?id=${itme.recruiter_id}&backto=jobseekerpage&jsid=${itme.jobseeker_id}`} >{itme.get_userdetail.first_name+' '+itme.get_userdetail.last_name}</Link></h5>
                        </div>
                        <div className="d-flex flex-wrap">
                            <div className="icontxt"><img src="/front/images/calender.png" /> Offer date: <span>{itme.offer_created_date} - Till now</span></div>
                            <div className="icontxt"><img src="/front/images/clock.png" /> Offer status: <span className='text-capitalize'>{itme.offer_status}</span></div>
                        </div>
                    </div>
                </div>
                </>
            ))
            :
            <>
             <div className='card-box work-experiencesec commonform mb-4 seekerdocument'>
                <h3>Work history is not found</h3>
             </div>
            </>
        }
            
        </>
    );
}
export default JobSeekWorkHistory;