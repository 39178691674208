import React, { useState, useEffect, Component } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';

const SearchResult = () => {

   const navigate = useNavigate();

   const [screenLoader, setScreenLoader] = useState(false);
   const [searchString, setSearchString] = useState(false);
   const [isMordeContactDetails, setIsMordeContactDetails] = useState(true);
   const [faqs, setFAQs] = useState([]);
   const get_faq = (apiurl, type = 'onload') => {
      (async () => {
         try {
            const resData = await axios.get(apiurl);
            if (resData.data.status == 1) {
               setScreenLoader(false);
               setFAQs(resData.data.data);
            }
         } catch (error) { console.log(error); }
      })();
   }
   useEffect(() => { get_faq(`https://squadgoo.com.au/api.squadgoo.com.au/getfaq`); }, []);


   const searchSteingData = () => {
      if (searchString) {

         navigate('/search-results?s=' + searchString)
         //search-results a
      }
   }
   return (
      <>
         <div className="dashboarwrap">
            <div className="adminwrap">
               <div className="helpbanner">
                  <div className="container">
                     <div className="row align-items-center">
                        <div className="col-md-5">
                           <h2>Find a solution fast.</h2>
                           <h4>how can we help you?</h4>
                           <div className="searchform d-flex"><input type="text" className="form-control" placeholder="Search by keywords" /><input type="submit" value="Search" /></div>
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-6">
                           <img src="/front/images/suppoertbanner.png" className="img-fluid"></img>
                        </div>
                     </div>
                  </div>
               </div>

               <div className="searchresultcont py-5">
                  <div className="container">
                     <div className="box mb-4">
                        <div className="box-ttl mb-3 d-flex align-items-center justify-content-between">
                           <h3>Search Results</h3>
                           <div className="resultcount mr-0">
                              <p><i>80 results for "How to find a job?"</i></p>
                           </div>
                        </div>

                        {
                           faqs.map((item, index) => (
                              <>
                                 <div className="reportblock"  key={item.id} >
                                    <h4>{item.question}</h4>
                                    <p className="mb-0">
                                       {item.answer}
                                       <a href="#">Read More</a>
                                    </p>
                                 </div>
                              </>
                           ))
                        }
                        
                       
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
}
export default SearchResult;