import React, { useEffect } from 'react';

const MapWithDirections = () => {
  useEffect(() => {

    console.log(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
    
    const loadGoogleMapScript = () => {
      const googleMapScript = document.createElement('script');
      googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&callback=initMap`;
      window.document.body.appendChild(googleMapScript);
      googleMapScript.addEventListener('load', initMap);
    };

    const initMap = () => {
      if (window.google && window.google.maps) {
        const indore = { lat: 22.7196, lng: 75.8577 }; // Indore coordinates
        const map = new window.google.maps.Map(document.getElementById('map'), {
        center: indore,
        zoom: calculateZoomLevel(50000) // Initial zoom level for 50 km radius
        });

        // Dummy job seeker locations
        const jobSeekerLocations = [
        { name: 'Job Seeker 1', location: { lat: 22.7196, lng: 75.8577 } }, // Indore
        { name: 'Job Seeker 2', location: { lat:22.675183, lng: 75.6028926 } }, // 1km away from Indore
        { name: 'Job Seeker 3', location: { lat: 22.6680767, lng: 75.5027806 } }  // 2km away from Indore
        ];

        //22.6680767,75.5027806

        jobSeekerLocations.forEach(jobSeeker => {
        createMarker(jobSeeker);
        });

        function createMarker(jobSeeker) {
        const marker = new window.google.maps.Marker({
            map: map,
            position: jobSeeker.location
        });

        const infowindow = new window.google.maps.InfoWindow({
            content: `<strong>${jobSeeker.name}</strong>`
        });

        marker.addListener('click', function() {
            infowindow.open(map, marker);
        });
        }

        // Add circle representing 50 km radius around Indore
        const circle = new window.google.maps.Circle({
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0.35,
        map: map,
        center: indore,
        radius: 50000 // 50 km in meters
        });

        // Function to calculate optimal zoom level based on circle radius
        function calculateZoomLevel(radius) {
        const scale = radius / 500;
        return Math.round(16 - Math.log(scale) / Math.log(2));
        }

       } else {
        console.error('Google Maps API not loaded.');
      }
    };

    loadGoogleMapScript();
  }, []);

  return <div id="map" style={{ height: '400px', width: '100%' }} />;
};

export default MapWithDirections;