const Profile = () => {
   return (
   <>
   <div className="dashboarwrap">
      <div className="profilebanner">
         <div className="container">
            <div className="row">
               <div className="col-md-12">
                  <h2>Profile</h2>
                  <div className="row">
                     <div className="col-md-6">
                     <div className="profiletopsec d-flex">
                        <div className="profiletopleft">
                            <div className="profileimgsec">
                                <img src="/front/images/profile.png" />
                                <div className="profilecamera">
                                   <input type="file" />
                                   <img src="/front/images/camera.png" />
                                </div>
                                
                            </div>
                        </div>

                        <div className="profiletopright">
                            <h3 className="d-flex">John Doe <img src="/front/images/profilecheck.png" /></h3>
                            <p className="d-flex"><img src="/front/images/mail.svg" /> john_doe1232@gmail.com</p>
                            <p className="d-flex"><img src="/front/images/phone.png" /> +61 125 8745</p>
                        </div>
                        </div>
                     </div>
                     <div className="col-md-6 text-right">
                         <div className="reting d-flex">
                             <div className="stars d-flex">
                                <div className="star"><img src="/front/images/fillstar.svg" /></div>
                                <div className="star"><img src="/front/images/fillstar.svg" /></div>
                                <div className="star"><img src="/front/images/fillstar.svg" /></div>
                                <div className="star"><img src="/front/images/fillstar.svg" /></div>
                                <div className="star"><img src="/front/images/starredopen.png" /></div>
                             </div>
                             <p>4.0 (12 reviews)</p>
                         </div>
                         
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div className="jobbtmsec py-4">
         <div className="container">
         
            <div className="row">
               <div className="col-md-3 profilesidebarwrap">
                   <div className="card-box profilesidebar">
                       <ul>
                       <li><a href="/profile" className="active">Resume</a></li>
                          <li><a href="/jobseeker/work-experience">Work experience</a></li>
                          <li><a href="/license">License</a></li>
                          <li><a href="/tfn-no">TFN No.</a></li>
                          <li><a href="/email-and-phone">Email and phone</a></li>
                          <li><a href="/availability" >Availability</a></li>
                          <li><a href="/locations">Locations</a></li>
                          <li><a href="/salary">Salary</a></li>
                          <li><a href="/skills">Skills</a></li>
                       </ul>
                   </div>
               </div>
               <div className="col-md-9">
                 <div className="profilerightwraper">
                     <div className="card-box resumesec">
                        <div className="d-flex align-items-center justify-content-between">
                          <h3 className="mb-0">Resume</h3>
                          <div className="documentwrap d-flex align-items-center">
                             <p>my_resume.docx</p>
                             <a href="#"><img src="/front/images/download.png" /></a>
                             <a href="#"><img src="/front/images/delete.png" /></a>
                          </div>
                        </div>
                     </div>


                     <div className="card-box work-experiencesec mt-4">
                        <div className="d-flex align-items-center justify-content-between">
                          <h3 className="mb-0">Work experience</h3>
                          <a href="#" className="batn batn-rounded rounded-orange">Verify</a>
                        </div>
                     </div>


                     <div className="card-box license-sec mt-4">
                        <div className="">
                          <h3 className="">License</h3>
                          <div className="d-flex align-items-center justify-content-between mb-4">
                             <p className="mb-0 largepera">Heavy driving license</p>
                             <div className="d-flex flex-wrap">
                                <div className="documentwrap d-flex align-items-center">
                                   <p>driving_license_front.jpg</p>
                                   <a href="#"><img src="/front/images/download.png" /></a>
                                   <a href="#"><img src="/front/images/delete.png" /></a>
                                </div>
                                <div className="documentwrap d-flex align-items-center">
                                   <p>driving_license_back.jpg</p>
                                   <a href="#"><img src="/front/images/download.png" /></a>
                                   <a href="#"><img src="/front/images/delete.png" /></a>
                                </div>
                             </div>
                        </div>

                        <div className="d-flex align-items-center justify-content-between mb-4">
                             <p className="mb-0 largepera">COVID - 19 certificate</p>
                             <div className="documentwrap d-flex align-items-center">
                                <p>corona_certificate.pdf</p>
                                <a href="#"><img src="/front/images/download.png" /></a>
                                <a href="#"><img src="/front/images/delete.png" /></a>
                             </div>
                        </div>

                        <div className="d-flex align-items-center justify-content-between mb-4">
                             <p className="mb-0 largepera">Character certificate</p>
                             <div className="fileuploadbtn">
                              <input type="file"></input>
                              <a href="#"  className="batn batn-rounded rounded-orange">Upload</a>
                              </div>
                             </div>
                        
                        </div>
                     </div>


                     <div className="card-box tfn-sec mt-4">
                        <div className="">
                          <h3 className="mb-4">TFN No.</h3>
                          <div className="d-flex align-items-center justify-content-between">
                             <p className="mb-0">547896325 <a href="#"><img src="/front/images/edit.png" /></a></p>
                             <a href="#" className="batn batn-rounded rounded-orange">Verify</a>
                          </div>
                          
                        </div>
                     </div>

                     <div className="card-box tfn-sec mt-4">
                        <div className="">
                          <h3 className="mb-3">Email and phone</h3>
                          <div className="d-flex align-items-center justify-content-between mb-3">
                             <p className="mb-0">john_doe234@gmail.com</p>
                             <a href="#" className="batn batn-rounded rounded-green">Verified</a>
                          </div>
                          <div className="d-flex align-items-center justify-content-between">
                             <p className="mb-0">+61 254 7854</p>
                             <a href="#" className="batn batn-rounded rounded-orange">Verify</a>
                          </div>
                        </div>
                     </div>


                     <div className="card-box availability-sec mt-4">
                        <div className="">
                          <h3 className="mb-3">Availability</h3>
                          <p className="subheadpera">Select the days and hours which is convenient for your job</p>
                          <div className="d-flex align-items-center mb-2">
                             <span className="checkboxwraper">
                                <input type="checkbox" required="" />
                                <span className="checkspan"></span>
                             </span>
                             <p className="mb-0">Moday</p>
                             <input type="text" placeholder="00:00 am" className="form-control"></input>
                             <p className="mb-0">To</p>
                             <input type="text" placeholder="00:00 am" className="form-control"></input>
                          </div>
                          <div className="d-flex align-items-center mb-2">
                             <span className="checkboxwraper">
                                <input type="checkbox" required="" />
                                <span className="checkspan"></span>
                             </span>
                             <p className="mb-0">Tuesday</p>
                             <input type="text" placeholder="00:00 am" className="form-control"></input>
                             <p className="mb-0">To</p>
                             <input type="text" placeholder="00:00 am" className="form-control"></input>
                          </div>
                          <div className="d-flex align-items-center mb-2">
                             <span className="checkboxwraper">
                                <input type="checkbox" required="" />
                                <span className="checkspan"></span>
                             </span>
                             <p className="mb-0">Wednesday</p>
                             <input type="text" placeholder="00:00 am" className="form-control"></input>
                             <p className="mb-0">To</p>
                             <input type="text" placeholder="00:00 am" className="form-control"></input>
                          </div>
                          <div className="d-flex align-items-center mb-2">
                             <span className="checkboxwraper">
                                <input type="checkbox" required="" />
                                <span className="checkspan"></span>
                             </span>
                             <p className="mb-0">Thursday</p>
                             <input type="text" placeholder="00:00 am" className="form-control"></input>
                             <p className="mb-0">To</p>
                             <input type="text" placeholder="00:00 am" className="form-control"></input>
                          </div>
                          <div className="d-flex align-items-center mb-2">
                             <span className="checkboxwraper">
                                <input type="checkbox" required="" />
                                <span className="checkspan"></span>
                             </span>
                             <p className="mb-0">Friday</p>
                             <input type="text" placeholder="00:00 am" className="form-control"></input>
                             <p className="mb-0">To</p>
                             <input type="text" placeholder="00:00 am" className="form-control"></input>
                          </div>
                          <div className="d-flex align-items-center mb-2">
                             <span className="checkboxwraper">
                                <input type="checkbox" required="" />
                                <span className="checkspan"></span>
                             </span>
                             <p className="mb-0">Saturday</p>
                             <input type="text" placeholder="00:00 am" className="form-control"></input>
                             <p className="mb-0">To</p>
                             <input type="text" placeholder="00:00 am" className="form-control"></input>
                          </div>
                          <div className="d-flex align-items-center mb-2">
                             <span className="checkboxwraper">
                                <input type="checkbox" required="" />
                                <span className="checkspan"></span>
                             </span>
                             <p className="mb-0">Sunday</p>
                             <input type="text" placeholder="00:00 am" className="form-control"></input>
                             <p className="mb-0">To</p>
                             <input type="text" placeholder="00:00 am" className="form-control"></input>
                          </div>
                          
                        </div>
                     </div>


                     <div className="card-box location-sec mt-4">
                        <div className="">
                          <h3 className="mb-3">Location</h3>
                          <p className="subheadpera">Select the locations that are convenient for your job</p>
                          <div className="d-flex align-items-center tabsgropu flex-wrap mb-2">
                             <div className="roundedtab">Sydney</div>
                             <div className="roundedtab">Melbourne</div>
                             <div className="roundedtab">Brisbane</div>
                             <div className="roundedtab">Perth</div>
                             <div className="roundedtab">Adelaide</div>
                             <div className="roundedtab">Canberra</div>
                             <div className="roundedtab">Hobart</div>
                             <div className="roundedtab">Darwin</div>
                             <div className="roundedtab">Melbourne</div>
                             <div className="roundedtab">Sydney</div>
                             <div className="roundedtab">Canberra</div>
                             <div className="roundedtab">Hobart</div>
                             <div className="roundedtab">Darwin</div>
                             <div className="roundedtab">Melbourne</div>
                             <div className="roundedtab">Sydney</div>
                             <div className="roundedtab">Melbourne</div>
                             <div className="roundedtab">Brisbane</div>
                             <div className="roundedtab">Perth</div>
                             <div className="roundedtab">Adelaide</div>
                             <div className="roundedtab">Canberra</div>
                             <div className="roundedtab">Hobart</div>
                             <div className="roundedtab">Darwin</div>
                             <div className="roundedtab">Melbourne</div>
                             <div className="roundedtab">Sydney</div>
                             <div className="roundedtab">Canberra</div>
                             <div className="roundedtab">Hobart</div>
                             <div className="roundedtab">Darwin</div>
                             <div className="roundedtab">Melbourne</div>    
                          </div>
                        </div>
                     </div>


                     <div className="card-box salary-sec mt-4">
                        <div className="d-flex align-items-center justify-content-between flex-wrap mb-2">
                          <h3 className="mb-3">Salary</h3>
                          <div className="form-group">
                                 <div className="switch-field">
                                    <input type="radio" id="monthly" name="jobtype" value="monthly" checked/>
                                    <label htmlFor="monthly">Monthly</label>
                                    <input type="radio" id="weekly" name="jobtype" value="weekly" />
                                    <label htmlFor="weekly">Weekly</label>
                                    <input type="radio" id="hourly" name="jobtype" value="hourly" />
                                    <label htmlFor="hourly">Hourly</label>
                                 </div>
                             </div>
                          </div>
                          <div className="d-flex align-items-end  mb-2 commonform">
                              <div className="form-group"><label className="form-label">Minimum salary</label><input type="text" className="form-control" placeholder="$ Enter minimum salary" /></div> 
                              <div className="form-group"><label className="form-label">Maximum salary</label><input type="text" className="form-control" placeholder="$ Enter maximum salary" /></div> 
                              <a href="#" className="batn batn-rounded rounded-orange">Save</a> 
                          </div>
                        
                     </div>

                     <div className="card-box skills-sec mt-4">
                        <div className="">
                          <h3 className="mb-3">Skills</h3>
                         
                          <div className="d-flex align-items-center tabsgropu flex-wrap mb-2">
                             <div className="roundedtab">Plumber</div>
                             <div className="roundedtab">Electrician</div>
                             <div className="roundedtab">Gardner</div>
                             <div className="roundedtab">Welder</div>
                             <div className="roundedtab">Chef</div>
                             <div className="roundedtab">Driver</div>
                             <div className="roundedtab">Crane Operator</div>
                             <div className="roundedtab">Plumber</div>
                             <div className="roundedtab">Plumber</div>
                             <div className="roundedtab">Electrician</div>
                             <div className="roundedtab">Gardner</div>
                             <div className="roundedtab">Welder</div>
                             <div className="roundedtab">Chef</div>
                             <div className="roundedtab">Driver</div>
                             <div className="roundedtab">Crane Operator</div>
                             <div className="roundedtab">Plumber</div>
                             <div className="roundedtab">Plumber</div>
                             <div className="roundedtab">Electrician</div>
                             <div className="roundedtab">Gardner</div>
                             <div className="roundedtab">Welder</div>
                             <div className="roundedtab">Chef</div>
                             <div className="roundedtab">Driver</div>
                             <div className="roundedtab">Crane Operator</div>
                          </div>
                        </div>
                     </div>


                 </div>
               </div>
            </div>
         </div>
      </div>
   

      <div className="modal fade modallarg docuploadmodal" id="docuploadmodal" tabindex="-1" aria-labelledby="docuploadmodalLabel" aria-hidden="true">
         <div className="modal-dialog">
            <div className="modal-content">
               <div className="modal-body">
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  <h3 className="mb-4">Upload minimum 3 consecutive months salary slip</h3>
                  <div className="row">
                     <div className="col-md-4">
                        <div className="customuploadsec">
                           <input type="file"></input>
                           <div className="customuploadinner text-center">
                              <img src="/front/images/uploadicon.png" className="mb-4"></img>
                              <p className="mb-0">drag and drop here</p>
                              <p className="mb-0 text-dark">or</p>
                              <p className="mb-0"><a className="linktxt" href="#">upload from computer</a></p>
                           </div>
                        </div>
                     </div>
                     <div className="col-md-4">
                        <div className="customuploadsec">
                           <input type="file"></input>
                           <div className="customuploadinner text-center">
                              <img src="/front/images/uploadicon.png" className="mb-4"></img>
                              <p className="mb-0">drag and drop here</p>
                              <p className="mb-0 text-dark">or</p>
                              <p className="mb-0"><a className="linktxt" href="#">upload from computer</a></p>
                           </div>
                        </div>
                     </div>
                     <div className="col-md-4">
                        <div className="customuploadsec">
                           <input type="file"></input>
                           <div className="customuploadinner text-center">
                              <img src="/front/images/uploadicon.png" className="mb-4"></img>
                              <p className="mb-0">drag and drop here</p>
                              <p className="mb-0 text-dark">or</p>
                              <p className="mb-0"><a className="linktxt" href="#">upload from computer</a></p>
                           </div>
                        </div>
                     </div>
                     <div className="col-md-4"></div>
                     <div className="col-md-4"></div>
                  </div>
                 <div className="btnsgroup"><a href="#" className="batn batn-orange">Submit</a></div>
               </div>
            </div>
         </div>
      </div>
    

   </div>
   </>
   );
   }
   export default Profile;