import React, { useState, useEffect, Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import {  toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';
import DocumentsComp from '../../DocumentsCom';
import { useDispatch } from 'react-redux';

import { admin_side_profile_verification } from '../../../redux_state/reducres/adminSideVerificationStatus';

const UserBasicDetails = () => {

   const dispatch = useDispatch();
   const loggedInUser = JSON.parse(localStorage.getItem("squadGologinSession"));
   const [userID, setuserID] = useState(0);
   const [isallDocumentVerified , setisallDocumentVerified] = useState('false');
   const [isDocuemntLoad , setisDocuemntLoad] = useState('false');

   useEffect(() => {
      const queryParameters = new URLSearchParams(window.location.search)
      const userid = queryParameters.get("id");
      if(userid != 0){
         //console.log("queryParameters="+userid);
         getCallingUserDeatilFun(userid);
         setuserID(userid)
      }
   }, []);

   
   const getCallingUserDeatilFun = (userid) => {
      setisDocuemntLoad('false');
      (async () => {
          try {
              const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/userdetails/" + userid + '?callingby=adminaccount');
              if (resData.data.status == 1) {
                  /*redux validation update */
                  setisDocuemntLoad(true);
                   dispatch(admin_side_profile_verification(resData.data.data.results.verifications_status.verification_status));
                  setisallDocumentVerified(resData.data.data.results.verifications_status.statustype.document_verification)
                  /*redux validation check end  */
              }
          } catch (error) { console.log(error); }
      })();
  }


   
   const userDetailsVelidationFun = (calltype) => {
      (async () => {
          try {
              const response = await axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/user_details_verifications/${loggedInUser.login_token}/${userID}/document_verification?idtype=unique_id`);
              if (response.data.status == 1) {
                  toast.success(response.data.message);
                  setisallDocumentVerified(isallDocumentVerified == true ? false : true);
                  /*redux validation update */
                  dispatch(admin_side_profile_verification(response.data.data.verification_status));
                  //userdata_id
                  getCallingUserDeatilFun(userID);
                 // getCallingUserDeatilFun(userID);
                  //dispatch(admin_side_usercontact_verification(response.data.data.contact_status));
                  /*redux validation check end  */
                  //setUserVeri(response.data.data.verifications_status);
              } else {
                  //toast.warning(response.data.message); 
              }
          } catch (error) { console.log(error); }
      })();
  }


   return (
      <>
         <div className="card-box work-experiencesec commonform mb-4 seekerdocument">
            <div className="row align-items-start editfieldss">
               <div className="col-md-12 d-flex justify-content-between pb-0">
                  <h3 className="mb-3">Documents</h3>


                  <div className="disableprofilewrapinner border-0">
                     {
                        isallDocumentVerified == 'false' ?
                        <></>
                        : isallDocumentVerified == true ?
                        <><img className='veriunveri-images' src="/front/images/vfy.png"></img></>
                        :<><img className='veriunveri-images' src="/front/images/uvfy.png"></img></>
                     }
                     <input
                        type="checkbox"
                        checked={isallDocumentVerified}
                        onChange={() => userDetailsVelidationFun('document_verification')}
                     />
                     {/* <span className="slider round"></span> */}
                  </div>
               </div>
               
               {
                  (userID == 0) ? 
                  <p className='text-center mt-10'><img className='width-100' src={'/front/images/loader.gif'} /></p>
                  :
                  <>
                  {(isDocuemntLoad == true)  &&<DocumentsComp attr={userID} />}
                  </>
               } 
               
            </div>
         </div>
      </>
   );
}
export default UserBasicDetails;