import React, { useState } from 'react';
import { Form, Button, Col, InputGroup, Modal, Row } from 'react-bootstrap';


const ReferEarnComponent = () => {

    const useriddata = JSON.parse(localStorage.getItem("squadGologinSession"));
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const inviteLink = `http://squadgo.ustowe.co.uk/register?referralcode=${useriddata.unique_id}`; // Replace with your actual referral link

    // Function to handle copying referral link to clipboard
    const copyToClipboard = (param) => {

        if(param == 'code'){
            navigator.clipboard.writeText(useriddata.unique_id);
        } else {
            navigator.clipboard.writeText(inviteLink);
        }
        alert(`Referral ${param ? param :'link'  } copied to clipboard!`);
        setModalIsOpen(false); // Close modal after copying
    };

    // Function to handle inviting via email
    const shareByEmail = () => {
        const subject = 'Join me and earn coins!';
        const body = `Hey, check out this awesome app! Join using my referral link: ${inviteLink}`;
        window.location.href = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
        // setModalIsOpen(false); // Close modal after email invitation
    };

    // Function to handle inviting via WhatsApp  
    const shareOnWhatsApp = () => {
        const text = `Join me and earn coins! Click here: ${inviteLink}`;
        const url = `https://api.whatsapp.com/send?text=${encodeURIComponent(text)}`;
       // const url = `https://wa.me/?text=${encodeURIComponent(text)}`;
        //console.log(url);
        window.open(url, '_blank', 'width=600,height=400');
        //setModalIsOpen(false); // Close modal after WhatsApp invitation
    };

    const shareOnFacebook = () => {
        const text = "Join me! ";
        const url = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(inviteLink);
        window.open(url, '_blank', 'width=600,height=400');
    };
    
    const shareOnLinkedIn = () => {
        const text = "Join me! ";
        const url = 'https://www.linkedin.com/sharing/share-offsite/?url=' + encodeURIComponent(inviteLink);
        window.open(url, '_blank', 'width=600,height=400');
    };

    return (
        <div className="row">
            <div className="col-md-12">
                <div className="box mb-4 lightbgg referearn">
                    <div className="d-flex align-items-center">
                        <div className="col-md-8">
                            <h2>Refer & Earn Coins Now! </h2>
                            <p>Don't miss out on the excitement – the more friends you bring, the more coins you earn. Let the referrals begin!</p>
                            <button className="batn batn-orange" onClick={() => setModalIsOpen(true)}>Refer Now</button>
                        </div>

                        <div className="col-md-4">
                            <img src="/front/images/Refer-Earn-Apps.png" className="img-fluid" alt="Refer and Earn Apps" />
                        </div>
                    </div>
                </div>
            </div>



            {/* Modal */}
            <Modal show={modalIsOpen} onHide={() => setModalIsOpen(false)} >
                <div className="modal-dialog1">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button onClick={() => setModalIsOpen(false)} type="button" className="btn-close"></button>
                            <h3 className="modal-title">Refer & Earn </h3>
                        </div>
                        <div className="modal-body">
                        <label className='mb-0 font-bold'>Referral Link</label>
                            <div className="input-group mb-3">
                                    <input type="text" value={inviteLink} className="form-control" placeholder="Referral links" aria-label="Referral links" aria-describedby="basic-addon2" />
                                    <div className="input-group-append">
                                        <button  onClick={() => copyToClipboard('link')}  className="btn btn-outline-secondary inviteurl_copybtn" type="button"><i className="fa fa-copy"></i></button>
                                    </div>
                            </div>
                            
                            <label className='mb-0 font-bold'>Referral Code</label>
                            <div className="input-group mb-3">
                                    <input type="text" value={useriddata.unique_id} className="form-control" placeholder="Referral links" aria-label="Referral links" aria-describedby="basic-addon2" />
                                    <div className="input-group-append">
                                        <button  onClick={() => copyToClipboard('code')}  className="btn btn-outline-secondary inviteurl_copybtn" type="button"><i className="fa fa-copy"></i></button>
                                    </div>
                            </div>
                            <h4>Share on</h4>
                            <div className='invite_other_options'>
                                <div className="invite_share-option">
                                    <button onClick={shareOnFacebook} className="share-button btn" style={{ color: '#fff', backgroundColor: '#3b5998' }}>
                                        <i className="fa fa-facebook-f"></i>  Facebook
                                    </button>
                                </div>
                                <div className="sinvite_hare-option">
                                    <button onClick={shareOnWhatsApp} className="share-button btn" style={{ color: '#fff', backgroundColor: '#25d366' }}>
                                        <i className="fa fa-whatsapp"></i>  WhatsApp
                                    </button>
                                </div>
                                <div className="invite_share-option">
                                    <button onClick={shareOnLinkedIn} className="share-button btn" style={{ color: '#fff', backgroundColor: '#0077b5' }}>
                                        <i className="fa fa-linkedin"></i>  LinkedIn
                                    </button>
                                </div>
                                <div className="invite_share-option">
                                    <button onClick={shareByEmail} className="share-button btn" style={{ color: '#fff', backgroundColor: '#3498db' }}>
                                        <i className="fa fa-envelope"></i>   Email
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </Modal>




        </div>
    );
};

export default ReferEarnComponent;