import React, { useState ,useEffect , Component} from 'react';
import { BrowserRouter as Router, Routes, Route ,  NavLink , useParams ,useNavigate  }  from 'react-router-dom';
import axios from 'axios';
import ProfileBanner from './profile_header';
import ProfileSidebar from './profile_sidebar';

import { Form, Button , Col , InputGroup , Row} from 'react-bootstrap';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import { useDispatch } from 'react-redux'
import { userProfileSummery } from '../redux_state/reducres/checkDocumentstatus';
import { userdataarray_status } from '../redux_state/reducres/userDataRegister';
const ProfileSummary = () => {
   const dispatch = useDispatch();
   let textLimit =  500 ;
   const [isHidden, setIsHidden] = useState(true);
   const [totalWordCount, setTotalWordCount] = useState(0);
   const toggleHidden = () => {  setIsHidden(!isHidden); };
   
   const userdetails  =  JSON.parse(localStorage.getItem("squadGologin"));
   const [userDetailsArray, setUserDetailsArray] = useState([]);
   
   const [loadingData, setloadingData] = useState(false);
   const [isAccVerified, setIsAccVerified] = useState('false');
   const getUserDetails = () => {
      setloadingData(true);
      (async () => {
         try {
            const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/userdetails/"+userdetails.unique_id);
            if(resData.data.status == 1){  
               setUserDetailsArray(resData.data.data.results);

               dispatch(userdataarray_status(resData.data.data.results));
               //localStorage.setItem('squadGologinSession',JSON.stringify(resData.data.data.results))
               //console.log(userDetailsArray);
               setloadingData(false);
               setIsAccVerified(resData.data.data.results.verifications_status.statustype.profile_summery);
            }
         } catch (error) { console.log(error); }
      })();
   }

   useEffect(() => {
      if(userdetails){  getUserDetails() ; } else { setTimeout(function(){ window.location.replace("/login"); },100) }
   }, []);

   const [validated, setValidated] = useState(false);
   const onAddressDetailsSubmit = async (event) => {
      event.preventDefault();
      const form = event.currentTarget;
      if (form.checkValidity() === false) { event.preventDefault(); event.stopPropagation();} 
      else {
         try {
            const formData = new FormData(event.target);
            const referenceDataValue = {};
            formData.forEach((value, name) => {
               if (name.endsWith('[]')) {
                  const inputName = name.slice(0, -2); 
                  if (!referenceDataValue[inputName]) { referenceDataValue[inputName] = [value];
                  } else {  referenceDataValue[inputName].push(value); }
               } else {  referenceDataValue[name] = value; }
            });
            if (userdetails.unique_id) { formData.append('userid', userdetails.unique_id); }
            const response = await axios.post(`https://squadgoo.com.au/api.squadgoo.com.au/userdetails_data_update`,
            formData, { headers: { 'Content-Type': 'multipart/form-data' } });
            if (response.data.status == 1) {  
               setIsHidden(true);  getUserDetails();  toast.success(response.data.message);
               if(userdetails.validationdata_check.basicdob_validation == 0) { 
                  /*redux validation update */
                  dispatch(userProfileSummery(1));
                  /*redux validation check end  */
               };
            } 
            else {  toast.warning(response.data.message);  }
         } catch (error) {  console.error('Error uploading file:', error); } 
      }
      setValidated(true);
   };


   function numberOfWords(str) {
      const words = str.match(/\S+/g);
      let totalReturn  = 0 ;
      try {
         totalReturn = str.length;
         // if(words.length!==0){
         //    totalReturn = words.length;
         // }
      } catch (error) { console.log('lenght') ; }
     
      setTotalWordCount(totalReturn);
      return totalReturn ;
  }

   const profileSummaryChange = (event) => {
      const { name, value } = event.target;
      let setTotalWordCountvalu  =  numberOfWords(value) ;
      console.log(setTotalWordCountvalu);
      if(setTotalWordCountvalu <= textLimit){         
         setUserDetailsArray((prevFormData) => ({...prevFormData, [name]: value  }));
      } 
   }

   return ( 
         <>
        {/* <ToastContainer />*/}
         <div className="dashboarwrap">
            <ProfileBanner />
            <div className="jobbtmsec py-5 admininfo">
               <div className="container">
               
                  <div className="row">
                     <div className="col-md-3 profilesidebarwrap">
                        <ProfileSidebar />
                     </div>
                     <div className="col-md-9">
                        <div className="profilerightwraper stepscont commonform basicdetail">
                           <div className="card-box work-experiencesec commonform profile-summary">
                              
                              <div className="d-flex align-items-center justify-content-between">
                                 <h3 className="mb-3">
                                    Biograph
                                    {
                                       isAccVerified == true ?
                                          <><img className='veri_unveri_icon' src="/front/images/vfy.png" data-toggle="tooltip" data-placement="top" title="verified"></img></> :
                                          isAccVerified == false ?
                                             <><img className='veri_unveri_icon' src="/front/images/uvfy.png" data-toggle="tooltip" data-placement="top" title="Your Profile is in Review"></img></> :
                                             <></>
                                    }
                                 </h3>
                                 {/* { isAccVerified == false && <div className="editbtn" onClick={toggleHidden} ><img src="/front/images/edit.png"/></div>} */}
                                 {<div className="editbtn" onClick={toggleHidden} ><img src="/front/images/edit.png"/></div>}
                              </div>
                              <div className="editblock">
                                 <div className="editblockinner d-flex ">
                                    <h4>
                                    <div><span className="mr-2">Biograph/Bio :</span>
                                    
                                    <span className=''>
                                       {loadingData 
                                          ?
                                          <>Loading.....</>
                                          :
                                          <>{userDetailsArray.profile_summary ? userDetailsArray.profile_summary : 'No content found.'}</>
                                       }
                                       
                                    </span>
                                    </div>
                                     
                                    </h4>
                                 </div>
                                 {!isHidden && 
                                 <div className="editblockinnerbtm">
                                    <Form noValidate validated={validated} onSubmit={onAddressDetailsSubmit} >
                                       <div className="row">
                                          <div className="col-md-12">
                                             <div className="form-group">
                                                <label className="form-label">Profile Summary <span className="starr">*</span></label>
                                                <textarea name='profile_summary' onChange={profileSummaryChange}  value={userDetailsArray.profile_summary} maxLength={500} className="form-control" placeholder="Enter your profile summary" />
                                                {(totalWordCount >=  textLimit) && <label className='text-danger'>You can't enter text more then 500.</label>}
                                             </div>
                                          </div>
                                          
                                          <div className="col-md-12 mt-3">
                                                <button  className="batn batn-rounded rounded-orange mr-2">Save</button>
                                                <button onClick={ (e) => setIsHidden(true)} className="batn batn-rounded rounded-orange mr-2">cancel</button>
                                             </div>

                                       </div>
                                       
                                    </Form>
                                 </div>
                                 }

                              </div>
                           </div>

                        </div>
                     </div>

                  </div>
               </div>
            </div>

         </div>
         </>
      );
   }
   export default ProfileSummary;