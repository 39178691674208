import React, { useState ,useEffect , Component} from 'react';
import axios from 'axios';
import { isVisible } from '@testing-library/user-event/dist/utils';

const Locations = () => {
   const userdetails  =  JSON.parse(localStorage.getItem("squadGologin"));
   const [userDetailByAPI, setUserDetailByAPI] = useState([]);
   const [locationTags, setLocationTags] = useState([]);
   const [inputValue, setInputValue] = useState('');


   useEffect(() => {
      if(userdetails.unique_id){
         (async () => {
            try {
               const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/userdetails/"+userdetails.unique_id);
               if(resData.data.status == 1){
                  setUserDetailByAPI(resData.data.data.results);
                  setLocationTags(resData.data.data.results.prefered_location_array)
                  //setInputValue(['aa,aaadad,adasd', 'ddd', 'aa,aaadad,adasd', 'asd', 'ads'])
               }
            } catch (error) { console.log(error); }
         })();
   
      } else { 
         setTimeout(function(){ window.location.replace("/login"); },100)
      }
   }, []);

   useEffect(() => {
      if(locationTags.length){

         formdataValue('prefered_location' ,locationTags )
      }
   }, [locationTags]);
   const formdataValue = (field,value) => {
      let length = value.length;
        // if(length > 2){         
            const userid = userdetails.unique_id;
            const formData = new FormData();
            formData.append('field_value', value);
            formData.append('uploaded_for', field);
            formData.append('userid', userid);
            axios.post('https://squadgoo.com.au/api.squadgoo.com.au/profile_field_update', formData)
            .then((response) => { console.log(response);  })
            .catch((error) => { console.error(error); }); 
        // }
   };

   console.log(userDetailByAPI)
   
      const handleInputChange = (event) => { setInputValue(event.target.value); };
   
      const handleInputKeyDown = (event) => {
         if (event.key === 'Enter' && inputValue !== '') {
            setLocationTags([...locationTags, inputValue]);
            setInputValue('');
            //console.log(locationTags);
           // formdataValue('prefered_location' ,locationTags )
         }
      };
   
      const handleTagRemove = (tagToRemove) => {
         const updatedTags = locationTags.filter((tag) => tag !== tagToRemove);
         setLocationTags(updatedTags);
         formdataValue('prefered_location' ,locationTags )
      };

      const [isVisible, setIsVisible] = useState(false);
      const hideAndSeek = () => {
         setIsVisible(prevState => !prevState);
      }
      //hideAndSeek
   return (
   <>
   <div className="dashboarwrap">
   <div className="pagebanner">
         <div className="container">
            <div className="row">
               <div className="col-md-12">
                  <h2>Locations</h2>
               </div>
            </div>
         </div>
      </div>
      <div className="jobbtmsec py-4">
         <div className="container">
         
         <div className="row">
               <div className="col-md-12">
                  <div className="profilerightwraper stepscont commonform basicdetail">
                     <div className="card-box work-experiencesec commonform">
                     <div className="row align-items-start editfieldss">
                        <div className="col-md-12">
                        <div className="editblock">
                            <label>Preferred locations</label>
                            <div className="editblockinner d-flex align-items-start">
                                <h4>
                                         <div className="d-flex align-items-center tabsgropu flex-wrap mt-1">
                                          
                                          {
                              locationTags.map((tag, index) => (
                                 <div className="roundedtab" key={index}>{tag}</div>
                              ))
                           }
                                          </div>
                               
                                </h4>
                                <div className="editbtn"  onClick={hideAndSeek} ><img src="/front/images/edit.png"></img></div>
                            </div>
                            {
                              isVisible && <div className="editblockinnerbtm">
                              <div className="form-group mb-3">
                              
                              <div className="tagswrap d-flex flex-wrap align-items-center mb-4">
                                    
                                    {
                              locationTags.map((tag, index) => (
                                 <div className="singletag d-flex" key={index}> 
                                    
                                    <span>{tag}</span>
                                    <a onClick={() => handleTagRemove(tag)}>
                                       <img src="/front/images/closetime.svg"></img>
                                    </a>
                                 </div>
                              ))
                           }
                                    <input
                           type="text"
                           value={inputValue}
                           onChange={handleInputChange}
                           onKeyDown={handleInputKeyDown}
                           className="form-control" placeholder="Add location"
                           />
                                 </div>
                              </div>
                            </div>
                            }
                        </div>
                        </div>

                       
                       {/* <div className="col-md-12">
                        <div className="editwrapbntns text-left"><button className="batn batn-orange" type="button" role="button">Update</button></div>
                        </div> */}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   

   
    

   </div>
   </>
   );
   }
   export default Locations;