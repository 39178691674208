import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink, useParams, useNavigate } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';
// import TimerComponent from '../../expocomp/TimerComp';
import PhoneVerificationModel from '../../admin/PhoneVerificationModel';

const ContactVerifications = () => {

    const [userdetails, setuserdetails] = useState([])

    const [contactNumber, setContactNumber] = useState('');
    const [storedContactNumber, setStoredContactNumber] = useState('');
    const [contactNumberError, setContactNumberError] = useState('');
    const [isVerified, setIsVerified] = useState(true);

    const [contactDetails, setContactDetails] = useState({
        unique_id: userdetails.unique_id,
        login_token: userdetails.login_token,
        contact_country_code: userdetails.contact_country_code,
        contact_number: userdetails.contact_number,
        contact_status: userdetails.contact_status,
    });

    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        const candidate = queryParameters.get("id");

        const status = queryParameters.get("status");
        // get_userdata("https://api.careerhamster.com/userdetails/"+candidate)
        window.scrollTo(0, 0);


        (async () => {
            try {
                const responceData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/userdetails/" + candidate);
                if (responceData.data.status == 1) {
                    let user = responceData.data.data.results;
                    setuserdetails(user);
                    storedContactNumber(user.contact_number);
                    setContactNumber(user.contact_number);
                    //contact_number
                }
                else { navigate('/login') }
            } catch (error) { console.log(error); }

            try {
                const response = await axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/localstorage_common_data?calling_by_api`);
                const data = response.data;
                if (data.status == 1) {
                    const storageData = data.data;
                    setGetallCountry(storageData.get_all_country);
                }
            } catch (error) { console.error(error); }

        })();
    }, [])




    const checkContactNumer = (event) => {
        const value = event.target.value; const regex = /^[0-9\b]+$/; setContactNumber('');
        if(value.length > 0) { setIsVerified(false) } else { setIsVerified(true) }
        if (value === '' || regex.test(value)) {
            setContactNumber(value);
            setContactNumberError('');
            if (value.length > 9) {
                setContactDetails((prevFormData) => ({ ...prevFormData, ['contact_status']: 0 }));
                if (storedContactNumber != value) {
                    (async () => {
                        try {
                            const resData = await axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/mobilecheck/${userdetails.unique_id}/${value}`);
                            if (resData.data.status == 1) {
                                setContactNumber('');
                                setContactNumberError(resData.data.message);
                            }
                        } catch (error) { console.log(error); }
                    })();
                }
            }
            setContactDetails((prevFormData) => ({ ...prevFormData, ['contact_number']: value }));
        }
    }

    const changeContactcode = (code) => { setContactDetails((preData) => ({ ...preData, ['contact_country_code']: code })) }

    const [validated, setValidated] = useState(false);
    const [validatedCnClass, setValidatedCnClass] = useState(false);
    const [getAllCountry, setGetallCountry] = useState([]);
    const [isContactNumberVerify, setisContactNumberVerify] = useState((contactDetails.contact_status == 1 ? false : false));
    const isNumberVerificationDoneFun = (fromChidPass) => { setisContactNumberVerify(fromChidPass); setContactDetails((preData) => ({ ...preData, ['contact_status']: 1 })) ;setIsVerified(true) }


    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.stopPropagation();
        } else {
          const formData = new FormData();
          formData.append('unique_id', "");
          if(isVerified == false){
            setContactNumberError('ddddddd sdd');
        }
        }
        setValidated(true);
      };
    const navigate = useNavigate();
    return (
        <>

            <div className="loginsignupwrap">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="loginsignupwrapformwrap">
                                <Form noValidate validated={validated} onSubmit={handleSubmit} className="commonform lofinsignupform loginform">
                                    <div className="formbranding text-center"><img src="/front/images/formlogo.png" /></div>
                                    <div className="text-center my-4"><img src="/front/images/varifyicon.png" /></div>
                                    <h2 className="text-center mb-2">Please enter contact number</h2>
                                    {/* <div className="text-center formbtmtxt mb-4">
                                        <p>We have sent a code on your email address</p>
                                    </div> */}
                                    

                                    <div className="form-group mb-2">
                                        <label className="form-label">Phone number <span className="starr">*</span></label>
                                        <div className={validatedCnClass ? 'phonefield d-flex align-items-center bordercss' : 'phonefield d-flex align-items-center'}>
                                            <select className="form-control" name="contact_country_code" onChange={(e) => changeContactcode(e.target.value)} >
                                                {
                                                    getAllCountry.length ?
                                                        <>
                                                            {
                                                                getAllCountry.map((item, index) => (
                                                                    <option value={item.phonecode}>+{item.phonecode}</option>
                                                                ))
                                                            }
                                                        </>
                                                        : ''
                                                }

                                            </select>
                                            <input 
                                                type="tel" 
                                                className="form-control" 
                                                required 
                                                name="contact_number" 
                                                placeholder="Enter mobile number" 
                                                maxLength={10} 
                                                onChange={checkContactNumer} 
                                                value={contactNumber}
                                               
                                            />
                                        </div>
                                        {contactNumberError && <div className='uniquenu_error'>
                                            {contactNumberError}
                                        </div>}


                                        {
                                            (isContactNumberVerify == false) && (contactNumber.length > 9) && (contactDetails.contact_status == 0) &&
                                            <div className="d-flex justify-content-end">
                                                <PhoneVerificationModel sendMessageToParent={isNumberVerificationDoneFun} userdetails={contactDetails} />
                                            </div>
                                        }
                                    </div>
                                    {/* {serverResMessage ? <p className="alert alert-danger text-center  mb-0">{serverResMessage}</p> : ''} */}
                                    {/* {serverResMessageSuccess ? <p className="alert alert-success text-center  mb-0">{serverResMessageSuccess}</p> : ''} */}
                                    <div className="form-group mb-2">
                                        <input 
                                            type="Submit" 
                                            className="batn batn-orange" 
                                            value="Continue" 
                                            disabled={isVerified ? false :  true}
                                        />
                                    </div>
                                    <div className="text-center formbtmtxt">
                                        <p>Already have an account?  <NavLink className="" to="/login">Log In</NavLink>
                                        </p>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default ContactVerifications;