import React, { useState, useEffect, Component } from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink, useParams, useNavigate } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';
import ProfileBanner from '../profile_header';
import ProfileSidebar from '../profile_sidebar';

import { ToastContainer, toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';
import { useSelector, useDispatch } from 'react-redux'
import { basicTaxTypeValidationCheck } from '../../redux_state/reducres/checkDocumentstatus';
import CheckValidationComp from '../CheckValidationComp';
import ModalDialog from '../../expocomp/WarningModal';
import TaxInput from '../../expocomp/AbnTfnTaxComp';
const TaxInformation = () => {
   const dispatch = useDispatch();
   const [selectedTaxOption, setSelectedTaxOption] = useState('tfn');
   const [isHidden, setIsHidden] = useState(true);
   const userdetails = JSON.parse(localStorage.getItem("squadGologin"));
   const [userDetailsArray, setUserDetailsArray] = useState([]);
   const [isAccVerified, setIsAccVerified] = useState('false');


   const [tax_type_value, setTaxTypeValue] = useState('');
   const [tax_type_value2, setTaxTypeValue2] = useState('');
   const [selectedOption, setSelectedOption] = useState('tfn');
   const [taxValues, setTaxValues] = useState({
      tfn: '',
      abn: ''
   });

   

   useEffect(() => {
      if (userdetails.unique_id) { getUserDetails() } else { setTimeout(function () { window.location.replace("/login"); }, 100) }
   }, []);


   const toggleHidden = () => { setIsHidden(!isHidden); };
   const hideFormDetails = () => { setIsHidden(true); }

   const getUserDetails = () => {
      (async () => {
         try {
            const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/userdetails/" + userdetails.unique_id);
            if (resData.data.status == 1) {
               const dataArry = resData.data.data.results;
               const texttype = dataArry.tax_type ? dataArry.tax_type.toLowerCase() : 'tfn';
               const tax_type_value = dataArry.tax_type_value ? dataArry.tax_type_value : '';
               const tax_type_value2 = dataArry.tax_type_value2 ? dataArry.tax_type_value2 : '';
               setUserDetailsArray(dataArry);
               setTaxTypeValue(tax_type_value);
               setTaxTypeValue2(tax_type_value2);
               setSelectedOption(texttype)
               setTaxValues({
                  tfn: texttype == 'both' ? tax_type_value : texttype == 'tfn' ? tax_type_value : '',
                  abn: texttype == 'both' ? tax_type_value2 : texttype == 'abn' ? tax_type_value : ''
               });
               setSelectedTaxOption(dataArry.tax_type);
               setIsAccVerified(dataArry.verifications_status.statustype.tax_information);
            }
         } catch (error) { console.log(error); }
      })();
   }




   const [validated, setValidated] = useState(false);
   const onvisaDetailsSubmit = async (e) => {
      e.preventDefault();
      const form = e.currentTarget;

      if (form.checkValidity() === false) {
         setValidated(true); // Show validation errors
         return; // Don't proceed if the form is invalid
      }

      if (isAccVerified == true) {
         setIsModalShow(true);
      } else {
         formSubmitData();
      }

      setValidated(false); // Reset validation state
   };


   const formSubmitData = async () => {
      try {
         const formData = new FormData();
         formData.append('tax_type_value', tax_type_value + ',' + tax_type_value2);
         formData.append('tax_type', selectedOption.toUpperCase());
         if (userdetails.unique_id) {
            formData.append('userid', userdetails.unique_id);
         }
         const response = await axios.post(
            `https://squadgoo.com.au/api.squadgoo.com.au/userdetails_data_update`,
            formData,
            { headers: { 'Content-Type': 'multipart/form-data' } }
         );
         if (response.data.status === 1) {
            getUserDetails(); // Refresh user details
            toast.success(response.data.message);
            setIsModalShow(false); // Close the modal
         } else {
            toast.warning(response.data.message);
            setIsModalShow(false); // Close the modal
         }
      } catch (error) {
         console.error('Error during form submission:', error);
         toast.error('An error occurred while submitting the form.');
      }
   };




   const handleTaxValuesChange = (values) => {
      setTaxValues(values);
      if (selectedOption == 'both') { setTaxTypeValue(values.tfn); setTaxTypeValue2(values.abn); }
      if (selectedOption == 'abn') { setTaxTypeValue(values.abn); }
      if (selectedOption == 'tfn') { setTaxTypeValue(values.abn); }
   };
   const handleOptionChange = (option) => { setSelectedOption(option); };


   /*----------modal dilog function------------------*/
   const [isModalShow, setIsModalShow] = useState(false);
   const actionConfirmed = () => { formSubmitData(); };



   return (
      <>
         {/* <ToastContainer />*/}

         <div className="dashboarwrap">
            <ProfileBanner />
            <div className="jobbtmsec py-5">
               <div className="container">

                  <div className="row">
                     <div className="col-md-3 profilesidebarwrap">
                        <ProfileSidebar />
                     </div>
                     <div className="col-md-9">
                        <div className="profilerightwraper stepscont commonform basicdetail">

                           <div className="card-box license-sec tfn-sec">
                              <div className="">
                                 <div className="d-flex justify-content-between">
                                    <h3 className="mb-3">
                                       Tax Information
                                       {
                                          isAccVerified == true ?
                                             <><img className='veri_unveri_icon' src="/front/images/vfy.png" data-toggle="tooltip" data-placement="top" title="verified"></img></> :
                                             isAccVerified == false ?
                                                <><img className='veri_unveri_icon' src="/front/images/uvfy.png" data-toggle="tooltip" data-placement="top" title="Your Profile is in Review"></img></> :
                                                <></>
                                       }
                                    </h3>
                                    {<div className="editbtn" onClick={toggleHidden}  ><img src="/front/images/edit.png" /></div>}
                                 </div>

                                 <div className="d-flex align-items-center justify-content-between mb-3">
                                    <p className="mb-0 largepera">
                                       {userDetailsArray.tax_type === 'tfn' ? 'TFN' : ((userDetailsArray.tax_type === 'BOTH' || userDetailsArray.tax_type === 'both') ? 'BOTH' : 'ABN')} No.
                                    </p>
                                    <div className="d-flex flex-wrap">
                                       {
                                          (selectedTaxOption === 'both' || selectedTaxOption === 'BOTH') ?
                                             <>
                                                <p className="mb-0">
                                                   <b>TFN: </b> {(userDetailsArray.tax_type_value ? '-' + userDetailsArray.tax_type_value : '..........')} <br />
                                                </p>
                                                <p className="mb-0 ml-1">
                                                   <b>ABN: </b> {(userDetailsArray.tax_type_value2 ? '-' + userDetailsArray.tax_type_value2 : '..........')}
                                                </p>
                                             </>
                                             :
                                             <>
                                                <p className="mb-0">{userDetailsArray.tax_type_value ? userDetailsArray.tax_type_value : '..........'}</p>
                                             </>
                                       }

                                    </div>
                                 </div>
                                 {!isHidden &&
                                    <div className="">
                                       <Form noValidate validated={validated} onSubmit={onvisaDetailsSubmit}>
                                          <div className="row mb-2">
                                            
                                             <TaxInput onValuesChange={handleTaxValuesChange} onOptionChange={handleOptionChange} defaultOption={selectedOption} defTaxValues={taxValues} className={'square-switch-field mr-0'} />
                                           

                                          </div>
                                          <div className="col-md-12 mt-3">
                                             <button type='submit' disabled={!taxValues.tfn} className="batn batn-rounded rounded-orange mr-2">Save</button>
                                             <button type='button' onClick={hideFormDetails} className="batn batn-rounded rounded-orange mr-2">Cancel</button>
                                          </div>
                                       </Form>
                                       <ModalDialog
                                          show={isModalShow}
                                          onClose={() => setIsModalShow(false)} // Close the modal without taking action
                                          onConfirm={actionConfirmed} // Action to take when confirming
                                          title="Existing details are already verified, Are you sure to change it?"
                                          message="This may cause you to wait for re-verification from Squad Goo."
                                       />
                                    </div>
                                 }
                              </div>
                           </div>

                        </div>
                     </div>
                  </div>
               </div>
            </div>


            <div className="modal fade modallarg docuploadmodal" id="docuploadmodal" tabindex="-1" aria-labelledby="docuploadmodalLabel" aria-hidden="true">
               <div className="modal-dialog">
                  <div className="modal-content">
                     <div className="modal-body">
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        <h3 className="mb-4">Upload minimum 3 consecutive months salary slip</h3>
                        <div className="row">
                           <div className="col-md-4">
                              <div className="customuploadsec">
                                 <input type="file"></input>
                                 <div className="customuploadinner text-center">
                                    <img src="/front/images/uploadicon.png" className="mb-4"></img>
                                    <p className="mb-0">drag and drop here</p>
                                    <p className="mb-0 text-dark">or</p>
                                    <p className="mb-0"><a className="linktxt" href="#">upload from computer</a></p>
                                 </div>
                              </div>
                           </div>
                           <div className="col-md-4">
                              <div className="customuploadsec">
                                 <input type="file"></input>
                                 <div className="customuploadinner text-center">
                                    <img src="/front/images/uploadicon.png" className="mb-4"></img>
                                    <p className="mb-0">drag and drop here</p>
                                    <p className="mb-0 text-dark">or</p>
                                    <p className="mb-0"><a className="linktxt" href="#">upload from computer</a></p>
                                 </div>
                              </div>
                           </div>
                           <div className="col-md-4">
                              <div className="customuploadsec">
                                 <input type="file"></input>
                                 <div className="customuploadinner text-center">
                                    <img src="/front/images/uploadicon.png" className="mb-4"></img>
                                    <p className="mb-0">drag and drop here</p>
                                    <p className="mb-0 text-dark">or</p>
                                    <p className="mb-0"><a className="linktxt" href="#">upload from computer</a></p>
                                 </div>
                              </div>
                           </div>
                           <div className="col-md-4"></div>
                           <div className="col-md-4"></div>
                        </div>
                        <div className="btnsgroup"><a href="#" className="batn batn-orange">Submit</a></div>
                     </div>
                  </div>
               </div>
            </div>


         </div>
      </>
   );
}
export default TaxInformation;