import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Modal, Row } from 'react-bootstrap';

import { Link } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';
const Reviews = (prop) => {

    const useriddata = JSON.parse(localStorage.getItem("squadGologinSession"));
    const [myReviewDataArr, setmyReviewDataArr] = useState([]);
    const [myReviewDataArrCopy, setmyReviewDataArrCopy] = useState([]);
    const [screenLoader, setScreenLoader] = useState(true);
    const [editAction, seteditAction] = useState(true);
    console.log(prop)

    const get_Revieweddata = (param) => {
        (async () => {
            try {
                const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/get_allreview/" + useriddata.login_token + (param == 0 ? '' : '?uniqueid='+param)  );
                setScreenLoader(false);
                if (resData.data.status == 1) {
                    setmyReviewDataArr(resData.data.data);
                    setmyReviewDataArrCopy(resData.data.data);
                 
                    seteditAction(resData.data.editaction);
                    console.log('caling....')
                }
            } catch (error) { console.log(error); }
        })();
    }

    useEffect(() => {
        if (useriddata) {
            const queryParameters = new URLSearchParams(window.location.search)
            const uniqueid = queryParameters.get("uniqueid");
            get_Revieweddata(uniqueid ? uniqueid : 0 );
        }
    }, []);



    const StarRating = ({ rating }) => {
        const stars = [1, 2, 3, 4, 5];
        return (
            <>
                {
                    stars.map((star) => (
                        <span
                            key={star}
                            className={star <= rating ? 'star font-14px active' : 'star font-14px'}
                        >
                            ★
                        </span>

                    ))
                }
            </>
        )
    }

    /*----------delete--------*/
    const [isBlockModelShow, setIsBlockModelShow] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const [reviewItemID, setreviewItemID] = useState(0);
    const [actionType, setactionType] = useState(0);
    const reportToBlockFun = (data, action) => {
        setreviewItemID(data.id);
        setIsBlockModelShow(data.id);
        setactionType(action);
    }
    const wantToBlockData = () => {
        (async () => {
            setBtnLoading(true)
            try {
                const formData = new FormData();
                axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/action_review_report/${useriddata.login_token}/${reviewItemID}?actionid=${actionType}`, formData)
                    .then((response) => {
                        setBtnLoading(false);
                        if (response.status == 200 && response.data.status == 1) {
                            toast.success(response.data.message);
                            setIsBlockModelShow(false);
                            const queryParameters = new URLSearchParams(window.location.search)
                            const uniqueid = queryParameters.get("uniqueid");
                            get_Revieweddata(uniqueid ? uniqueid : 0 );
                        }
                    })
                    .catch((error) => { console.error(error); });
            } catch (error) { console.log(error); }

        })();
    }
    /*--------------------------------------------------------*/
    const typeofpriority = ['High', 'Medium', 'Low'];
    const [validated, setValidated] = useState(false);
   const onFinalSubmit = async (event) => {
      event.preventDefault();
      const form = event.currentTarget;
      if (form.checkValidity() === false) { event.preventDefault(); event.stopPropagation(); }
      else {

         try {
            const formData = new FormData();

            formData.append('title', event.target.title.value);
            formData.append('description', event.target.description.value);
            formData.append('priority', 'High');
            formData.append('login_token', useriddata.login_token);
            formData.append('report_form', 'review_support_request');
            formData.append('reference_id', reviewItemID);
            axios.post('https://squadgoo.com.au/api.squadgoo.com.au/add_support_new_request', formData)
               .then((response) => {
                  if (response.status == 200 && response.data.status == 1) {
                    wantToBlockData();
                    // toast.success(response.data.message);
                  }
               })
               .catch((error) => { console.error(error); });
         } catch (error) { console.log(error); }
      }
      setValidated(true);
   };

    return (
        <>

           {/* <ToastContainer />*/}

            {
                screenLoader ?
                    <>
                        <div className='loader_container'>
                            <p className='text-center'><img className='width-100 loader_image' src={'/front/images/loader.gif'} /></p>
                        </div>
                    </>
                    :
                    myReviewDataArr.length ?
                        myReviewDataArr.map((item, index) => (
                            <>
                                <div className={item.is_published == 1 ? "card-box mb-3" : "saferonbg card-box mb-3"}>
                                    <div className="reviewarap">
                                        <div className="d-flex">
                                            <div className="reviewimg"> <img className="profile-pic" src={item.send_from_user.profilepic ? item.base_url + item.send_from_user.profilepic : '/front/images/user.svg'} /> </div>
                                            <div className="d-flex flex-column reviewttls">
                                                <h3 className="mt-2 mb-0 text-capitalize">{item.send_from_user.first_name + ' ' + item.send_from_user.last_name}</h3>
                                                <div>
                                                    <p className="text-left">
                                                        <StarRating rating={item.rating} />
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="ml-auto reviewdate d-flex align-items-start">
                                                <p className="text-muted pt-5 pt-sm-3">
                                                    {item.date_format} 


                                                    { <span className='ml-5'>({item.is_published == 1 ? 'Published' : 'Under Review'})</span>}
                                                </p>
                                                {/* {editAction} */} 
                                                {editAction == 1 && item.is_admin_published == 0  &&
                                                <div className="chatbocoptions ml-3">
                                                    <a href="#" className="dropdown-toggle" type="button" id="cahtoptiondrop" data-bs-toggle="dropdown" aria-expanded="false"><img src="/front/images/chardots.png" /></a>
                                                    <ul className="dropdown-menu" aria-labelledby="cahtoptiondrop">
                                                        <li>
                                                            {
                                                                item.is_published == 1 ?
                                                                    <><Link className="dropdown-item" onClick={() => reportToBlockFun(item, 0)} >Report</Link></>
                                                                    :
                                                                    // <><Link className="dropdown-item" onClick={() => reportToBlockFun(item, 1)} >Under Review</Link></>
                                                                    <><Link className="dropdown-item"  >Under Review</Link></>
                                                            }

                                                        </li>
                                                    </ul>
                                                </div>}
                                            </div>
                                        </div>
                                        <div className="row text-left reviewdesc">
                                            <h4 className="blue-text mt-2">"{item.title}"</h4>
                                            <p className="content">{item.description}</p>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )) :
                        <>
                            <div className="card-box mb-3 text-center"><p>This profile has no reviews.</p></div>
                        </>
            }

            <Modal className="modal fade  jobacceptmodle" id="jobacceptmodle" show={isBlockModelShow} onHide={() => setIsBlockModelShow(false)}>
                <div className="modal-dialog1">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button type="button" className="btn-close" onClick={() => setIsBlockModelShow(false)}></button>
                            {/* <h5 className="text-center mb-4">Do you want to {actionType  == 1 ? 'remove' : '' } report this review to admin?</h5>
                            <div className="btnsgroup text-center">
                                <Link onClick={wantToBlockData} className="batn batn-orange" >Yes</Link>
                                <Link onClick={() => setIsBlockModelShow(false)} className="batn batn-orange batn-border">No</Link>
                            </div> */}
                            <h3>Do you want to report?</h3>
                            <div className="commonform">
                                <Form noValidate validated={validated} onSubmit={onFinalSubmit} >
                                    <div className="row">
                                        
                                        <div className="col-md-12 form-group mb-2">
                                            <label className="form-label" htmlFor="exampleInputEmail1">Description <span className="starr">*</span></label>
                                            <textarea className="form-control" name='description'  required={true} rows="5" placeholder="Enter description"></textarea>
                                        </div>
                                        <div className="col-md-12 form-group mb-2">
                                            <label className="form-label" htmlFor="lastName1">Expectation' <span className="starr">*</span></label>
                                            <input className="form-control" name='title'  required={true} type="text" placeholder="What expectation you want from this report" />
                                        </div>
                                        {/* <div className="col-md-12 form-group mb-2">
                                            <label className="form-label" htmlFor="phone">Priority <span className="starr">*</span></label>
                                            <select className="form-control" name='priority'  required={true} >
                                                {
                                                    typeofpriority.map((item, index) => (
                                                        <option  key={item} value={item}>{item}</option>
                                                    ))
                                                }
                                            </select>
                                        </div> */}
                                        <div className="col-md-12 form-group mt-3">
                                            <button type='submit' disabled={btnLoading} className="batn batn-orange w-100">{btnLoading ?  'Please wait.....' : 'Send'}</button>
                                        </div>

                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

        </>
    );
}
export default Reviews;