import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentForm from './StripePaymentComp';
//import { ApiDataContextProvider } from './ApiDataContext';
const stripePromise = loadStripe('pk_test_lBXWelMRrHeRF6MI01zyqYo0');

const PaymentProcess = ({product_id, product_price  , coins_want_purchase , coin_rate}) => {
  const productId = product_id ;
  const productPrice = product_price ;



  return (
    <>
    <Elements stripe={stripePromise}>
      <PaymentForm 
        productId={productId} 
        productPrice={productPrice} 
        coins_want_purchase={coins_want_purchase} 
        coin_rate={coin_rate} 
      />
    </Elements>
    </>
  );
};

export default PaymentProcess;