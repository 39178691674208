import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink, useParams, useNavigate } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';
import StrongPassMsgComp from '../../expocomp/StrongPassMsgComp';
const ForgotConfirmPassword = () => {

  const [validated, setValidated] = useState(false);
  const [verificationCode, setVerificationCode] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [candidateEmailId, setCandidateEmailId] = useState(false);
  const [inputTypePass, setinputTypePass] = useState('password');
  const [serverResMessage, setServerResMessage] = useState('');
  const [serverResMessageSucc, setServerResMessageSucc] = useState('');


  const navigate = useNavigate();
  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search)
    const candidate = queryParameters.get("id");
    window.scrollTo(0, 0);

    (candidate) ? setCandidateEmailId(candidate) : navigate('/login');
  }, [])


  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      const formData = new FormData();
      formData.append('forgot_otp', verificationCode);
      formData.append('reset_pwd', password);
      formData.append('reset_cnpwd', confirmPassword);
      formData.append('genered_email_id', candidateEmailId);
      axios.post('https://squadgoo.com.au/api.squadgoo.com.au/update_password', formData)
        .then((response) => {
          console.log(response)
          if (response.data.status == 1) { setServerResMessageSucc(response.data.message); setTimeout(function () { setServerResMessage(''); navigate('/login'); }, 1000); }
          else { setServerResMessage(response.data.message); setTimeout(function () { setServerResMessage('') }, 5000); }
        })
        .catch((error) => { console.error(error); });
    }
    setValidated(true);
  };



  const [isPasswordMatch, setIsPasswordMatch] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [isPasswordStrong, setIsPasswordStrong] = useState(false);


  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setIsPasswordMatch(newPassword === confirmPassword);
    setIsPasswordStrong(checkPasswordStrength(newPassword));
  };

  const handleConfirmPasswordChange = (e) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);
    setIsPasswordMatch(password === newConfirmPassword);
  };

  const checkPasswordStrength = (password) => {
    // Define your criteria for a strong password here
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /\d/.test(password);
    const hasSpecialChars = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(password);

    return (
      password.length >= minLength &&
      hasUpperCase &&
      hasLowerCase &&
      hasNumbers &&
      hasSpecialChars
    );
  };



  return (
    <>
      <div className="loginsignupwrap">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="loginsignupwrapformwrap">
                <Form noValidate validated={validated} onSubmit={handleSubmit} className="commonform lofinsignupform loginform">
                  <div className="formbranding text-center">
                    <img src="/front/images/formlogo.png"></img>
                  </div>
                  <h2 className="text-center">Reset your password</h2>
                  <div className="text-center formbtmtxt mb-4"><p>Enter the email address associated with your account and we will send you a link to reset your password.</p></div>
                  {/* <div className="form-group mb-2">
                    <label className="form-label">Email Verification Code</label>
                    <div class="passwordwrap p-relative iconfields">
                      <img class=" keywidthicon" src="/front/images/keyimg.png"></img>
                      <input
                        type="text"
                        required={true}
                        className="form-control emailfield"
                        onChange={(event) => setVerificationCode(event.target.value)}
                        placeholder="Enter your verification code"
                      />
                    </div>
                  </div> */}
                  <div className="form-group mb-2">
                    <label className="form-label">New Password<span class="starr">*</span> </label>

                    <div class="passwordwrap p-relative iconfields">
                      <img class="fieldicons" src="/front/images/lock.svg"></img>
                      <input
                        type={inputTypePass}
                        required={true}
                        className="form-control passwordfield"
                        value={password}
                        onChange={handlePasswordChange}
                        placeholder="Enter Password"
                      />
                      <span class="eyebtn" onClick={() => setinputTypePass((inputTypePass == 'password') ? 'text' : 'password')} >
                        {/* <img src="/front/images/eye.svg" /> */}
                        {(inputTypePass == 'password') ? <img src="/front/images/eyeclose.svg"></img> :
                          <img src="/front/images/eyeopen.svg"></img>}
                      </span>
                    </div>
                    {
                      password && !isPasswordStrong && <StrongPassMsgComp />
                    }

                  </div>
                  <div className="form-group mb-2">
                    <label className="form-label">Confirm Password<span class="starr">*</span> </label>

                    <div class="passwordwrap p-relative iconfields">
                      <img class="fieldicons" src="/front/images/lock.svg"></img>
                      <input
                        type={inputTypePass}
                        required={true}
                        className="form-control passwordfield"
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        placeholder="Enter Confirm Password"
                      />
                      <span class="eyebtn" onClick={() => setinputTypePass((inputTypePass == 'password') ? 'text' : 'password')} >
                        {/* <img src="/front/images/eye.svg" /> */}
                        {(inputTypePass == 'password') ? <img src="/front/images/eyeclose.svg"></img> :
                          <img src="/front/images/eyeopen.svg"></img>}
                      </span>
                    </div>
                  </div>

                  {
                    (password && confirmPassword && password != confirmPassword) ? <p className="text-danger text-center  mb-0">Password not match</p> : ''
                  }

                  {
                    serverResMessage ? <p className="text-danger text-center  mb-0">{serverResMessage}</p> : ''
                  }

                  {
                    serverResMessageSucc ? <p className="text-success text-center  mb-0">{serverResMessageSucc}</p> : ''
                  }
                  <div className="form-group mb-2">
                    {/* <input type="Submit" className="batn batn-orange" value="Continue"></input> */}
                    <button type="submit" className="batn batn-orange" disabled={!isPasswordMatch || !isPasswordStrong} >
                      Continue
                    </button>
                  </div>

                  <div className="text-center formbtmtxt">
                    <p>Not a member yet   <NavLink to="/register" className="formlink">Join now</NavLink></p>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotConfirmPassword;


