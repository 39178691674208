import React, { useState, useEffect, Component } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';

import TransectionTime from '../json/transction_time.json';
import TransectionData from '../json/transection_data.json';
import PageLimitJson from '../json/pagelimit.json';
import UerTypeOption from '../json/usertype.json';
import RecWalletWithdraw from './recruiter/recWalletWithdraw';


import PdfExportButton from '../admin/superadmin/supcomp/PdfExportButton';
import ExcelExportButton from '../admin/superadmin/supcomp/ExcelExportButton';
import { Modal,Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
//import PdfExportButton from '../admin/supcomp/';

//import PdfExportButton from './supcomp/PdfExportButton';
const RecWalletHist = ({ onChildDataChange }) => {




    //const navigate = useNavigate();
    const useriddata = JSON.parse(localStorage.getItem("squadGologinSession"));
    const [traHistDateFrom, settraHistDateFrom] = useState('');
    const [traHistDateTo, settraHistDateTo] = useState('');
    const [transectionType, setTransectionType] = useState('');
    const [transectionTime, setTransectionTime] = useState('');
    const [loadingData, setLoadingData] = useState(true);
    const [totalPageCount, settotalPageCount] = useState(1);
    const [currentActivePage, setCurrentActivePage] = useState(1);
    const [pageLimitNo, setPageLimitNo] = useState(10);
    const [tableOrderType, settableOrderType] = useState('DESC');
    const [userTypeValue, setUserTypeValue] = useState('');
    const [exportData, setExportData] = useState([]);



    const reSetFilterTable = () => {
        settraHistDateFrom('');
        settraHistDateTo('');
        setTransectionType('');
        setTransectionTime('');
        setUserTypeValue('');
        setCurrentActivePage(1);
        settableOrderType('DESC');
    }


    const [coinsData, setCoinsData] = useState({
        current_squad_go_coins: 0,
        total_available_coins: 0,
        total_coins_purchased: 0,
        total_coins_used: 0,
        total_coins_withdraw: 0,
        total_coins_withdraw_pending: 0,
        total_reffered_coins: 0,
        transection_history: []
    });

    const [jsWalletData, setJsWalletData] = useState(
        {
            total_performance_coins: 0,
            total_compensation_coins: 0,
            total_reffered_coins: 0,
            total_withdraw_coins: 0,
            total_available_coins: 0,
            transection_history: [],
        }
    );

    const [isgetUserID, setGetUserID] = useState(0);
    const getCoinsData = async (userid = 0) => {
        try {
            const response = await axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/api/wallet/get_tansections/${useriddata.login_token}/${useriddata.user_id}?page=${currentActivePage}&usertype=${userTypeValue}&from=${traHistDateFrom}&to=${traHistDateTo}&type=${transectionType}&time=${transectionTime}&oderby=${tableOrderType}&encrypted_id=${userid}&limit=${pageLimitNo}`);
            if (response.status === 200 && response.data.status == 1) {
                const coindatavalue = response.data.data.coindata;
                let row_length = parseInt(response.data.data.row_length);
                setCoinsData(response.data.data.coindata);
                setJsWalletData(coindatavalue);
                setExportData(coindatavalue.exportdata);
                setGetUserID(userid ? userid : 0)
                onChildDataChange(coindatavalue);

                const calculatedTotalPages = Math.ceil(row_length / pageLimitNo);
                settotalPageCount(calculatedTotalPages);
                setLoadingData(false);

                RenderPaginationNumbers(calculatedTotalPages)

            } else { console.error('Error:', response.status); setLoadingData(false); }
        } catch (error) { console.error('Error:', error); }
    }


    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        const userid = queryParameters.get("id");
        getCoinsData(userid ? userid : '');
    }, [traHistDateFrom, pageLimitNo, traHistDateTo, transectionType, transectionTime, userTypeValue, currentActivePage]);



    /*callback function */
    const [withDrawformShow, setWithDrawformShow] = useState(false);
    const backTotheTransection = (responce) => {
        setWithDrawformShow(responce)
    }



    /*pagination table */
    const RenderPaginationNumbers = (totalPageCount) => {

        //totalPageCount
        if (totalPageCount > 0) {

            const paginationNumbers = [];
            paginationNumbers.push(<li className="page-item" ><Link onClick={(e) => handlePageChange(currentActivePage - 1)} className="page-link" href="#"><i className="fa fa-angle-left" aria-hidden="true"></i></Link></li>)
            for (let i = 1; i <= totalPageCount; i++) { paginationNumbers.push(<li key={i} className={i === currentActivePage ? 'page-item active' : 'page-item'} ><Link onClick={() => handlePageChange(i)} className="page-link" href="#">{i}</Link></li>); }
            paginationNumbers.push(<li className="page-item" ><Link onClick={(e) => handlePageChange(currentActivePage + 1)} className="page-link" href="#"><i className="fa fa-angle-right" aria-hidden="true"></i></Link></li>)
            return paginationNumbers;
        } else {
            return '';
        }
    };
    const handlePageChange = (newPage) => { if (newPage >= 1 && newPage <= totalPageCount) { setCurrentActivePage(newPage); } };


    /*-----------------------------*/

    const transectionReportAction = (id) => {
        alert(id)
    }

    const transectionCancelAction = (id) => {
        alert(id)
    }

    /*----------------------------------------------------------*/
    const [hideAndSeekMyRequestModel, setHideAndSeekMyRequestModel] = useState(false);
const [validated, setValidated] = useState(false);
const [reportFrom, setreportFrom] = useState('init_support_request');
const [editDataID, setEditDataID] = useState([]);
   const [referenceID, setreferenceID] = useState(0);
const [myRequestDataFormArr, setmyRequestDataFormArr] = useState({
   title: '',
   description: '',
   priority: 'High',
});


const onFinalSubmit = async (event) => {
   event.preventDefault();
   const form = event.currentTarget;
   if (form.checkValidity() === false) { event.preventDefault(); event.stopPropagation(); }
   else {
      try {
         const formData = new FormData();
         formData.append('title', event.target.title.value);
         formData.append('description', event.target.description.value);
         formData.append('priority', 'High');
         formData.append('login_token', useriddata.login_token);
         formData.append('report_form', reportFrom);
         formData.append('edit_id', editDataID);
         formData.append('reference_id', referenceID);//reference_id referenceID
         axios.post('https://squadgoo.com.au/api.squadgoo.com.au/add_support_new_request', formData)
            .then((response) => {
               if (response.status == 200 && response.data.status == 1) {
                  toast.success(response.data.message);
                 // toast.success()
                  setHideAndSeekMyRequestModel(false)
                  setmyRequestDataFormArr({
                     title: '',
                     description: '',
                     priority: 'High',
                  })
               }
               setEditDataID(0);
            })
            .catch((error) => { console.error(error); });
      } catch (error) { console.log(error); }
   }
   setValidated(true);
};


    return (
        <>
            {(useriddata.user_type == 2) && <div className="row">
                <div className="col-md-20 col-sm-6 mb-4">
                    <div onClick={(e) => setTransectionType('coins_purchases')} className={`cursor_pointer box ststicsbox d-flex justify-content-between greenbg ${transectionType == 'coins_purchases' ? 'border_blue' : ''}`}>
                        <div className="ststicsboxleft">
                            <p>Coins purchases</p>
                            <h2 className='cursor_pointer'  >{coinsData.total_coins_purchased} <i className="fa fa-arrow-down" aria-hidden="true"></i></h2>
                        </div>

                        <div className="ststicsboxright"><img src="/front/images/ion.png" /></div>
                    </div>
                </div>
                <div className="col-md-20 col-sm-6 mb-4">
                    <div onClick={(e) => setTransectionType('refferred_coins')} className={`cursor_pointer box ststicsbox d-flex justify-content-between greenbg ${transectionType == 'refferred_coins' ? 'border_blue' : ''}`}>
                        <div className="ststicsboxleft">
                            <p>Refferred coins</p>
                            <h2 className="dashboard_tile cursor_pointer">
                                {coinsData.total_reffered_coins}  <i className="fa fa-arrow-down" aria-hidden="true"></i>
                            </h2>
                        </div>
                        <div className="ststicsboxright"><img src="/front/images/loss.png" /></div>
                    </div>
                </div>
                <div className="col-md-20 col-sm-6 mb-4">
                    <div onClick={(e) => setTransectionType('jobpost_charge')} className={`cursor_pointer box ststicsbox d-flex justify-content-between redbg ${transectionType == 'jobpost_charge' ? 'border_blue' : ''}`}>
                        <div className="ststicsboxleft">
                            <p>Total coins used</p>
                            <h2 className="dashboard_tile cursor_pointer" >
                                {coinsData.total_coins_used}  <i className="fa fa-arrow-up" aria-hidden="true"></i>
                            </h2>
                        </div>
                        <div className="ststicsboxright"><img src="/front/images/deposit.png" /></div>
                    </div>
                </div>
                <div className="col-md-20 col-sm-6 mb-4">
                    <div onClick={(e) => setTransectionType('withdraw_pending')} className={`cursor_pointer box ststicsbox d-flex justify-content-between redbg ${transectionType == 'withdraw_pending' ? 'border_blue' : ''}`}>
                        <div className="ststicsboxleft">
                            <p>Pending withdrawn</p>
                            <h2 className="dashboard_tile" >
                                {coinsData.total_coins_withdraw_pending}  <i className="fa fa-arrow-up" aria-hidden="true"></i>
                            </h2>

                        </div>
                        <div className="ststicsboxright"><img src="/front/images/jobcin.png" /></div>
                    </div>
                </div>
                <div className="col-md-20 col-sm-6 mb-4">
                    <div onClick={(e) => setTransectionType('withdraw')} className={`cursor_pointer box ststicsbox d-flex justify-content-between redbg ${transectionType == 'withdraw' ? 'border_blue' : ''}`} >
                        <div className="ststicsboxleft">
                            <p>Total withdrawn</p>
                            <h2 className="dashboard_tile">
                                {coinsData.total_coins_withdraw}  <i className="fa fa-arrow-up" aria-hidden="true"></i>
                            </h2>
                        </div>
                        <div className="ststicsboxright"><img src="/front/images/jobcin.png" /></div>
                    </div>
                </div>
            </div>}

            {(useriddata.user_type == 3) && <div className="row">
                <div className="col-md-20 col-sm-6 mb-4">
                    <div onClick={(e) => setTransectionType('performance')} className={`box ststicsbox d-flex justify-content-between greenbg ${transectionType == 'performance' ? 'border_blue' : ''}`}>
                        <div className="ststicsboxleft">
                            <p>Performance coins</p>
                            <h2>{jsWalletData.total_performance_coins} <i className="fa fa-arrow-down" aria-hidden="true"></i></h2>
                        </div>
                        <div className="ststicsboxright"><img src="/front/images/ion.png" /></div>
                    </div>
                </div>
                <div className="col-md-20 col-sm-6 mb-4">
                    <div onClick={(e) => setTransectionType('compensation')} className={`box ststicsbox d-flex justify-content-between greenbg ${transectionType == 'compensation' ? 'border_blue' : ''}`} >
                        <div className="ststicsboxleft">
                            <p>Compensation coins</p>
                            <h2>{jsWalletData.total_compensation_coins} <i className="fa fa-arrow-down" aria-hidden="true"></i></h2>
                        </div>
                        <div className="ststicsboxright"><img src="/front/images/ion.png" /></div>
                    </div>
                </div>
                <div className="col-md-20 col-sm-6 mb-4">
                    <div onClick={(e) => setTransectionType('refferred_coins')} className={`cursor_pointer box ststicsbox d-flex justify-content-between greenbg ${transectionType == 'refferred_coins' ? 'border_blue' : ''}`} >
                        <div className="ststicsboxleft">
                            <p>Referral coins</p>
                            <h2 className="dashboard_tile">
                                {jsWalletData.total_reffered_coins} <i className="fa fa-arrow-down" aria-hidden="true"></i></h2>

                        </div>
                        <div className="ststicsboxright"><img src="/front/images/deposit.png" /></div>
                    </div>
                </div>
                <div className="col-md-20 col-sm-6 mb-4">
                    <div onClick={(e) => setTransectionType('withdraw_pending')} className={`cursor_pointer box ststicsbox d-flex justify-content-between redbg ${transectionType == 'withdraw_pending' ? 'border_blue' : ''}`}>
                        <div className="ststicsboxleft">
                            <p>Pending withdrawn</p>
                            <h2 className="dashboard_tile">
                                {jsWalletData.total_withdraw_coins}
                                <i className="fa fa-arrow-up" aria-hidden="true"></i>

                            </h2>
                        </div>
                        <div className="ststicsboxright"><img src="/front/images/loss.png" /></div>
                    </div>
                </div>
                <div className="col-md-20 col-sm-6 mb-4">
                    <div onClick={(e) => setTransectionType('withdraw')} className={`cursor_pointer box ststicsbox d-flex justify-content-between redbg ${transectionType == 'withdraw' ? 'border_blue' : ''}`} >
                        <div className="ststicsboxleft">
                            <p>Total withdrawn</p>
                            <h2 className="dashboard_tile">
                                {jsWalletData.total_withdraw_coins} <i className="fa fa-arrow-up" aria-hidden="true"></i>
                            </h2>
                        </div>
                        <div className="ststicsboxright"><img src="/front/images/loss.png" /></div>
                    </div>
                </div>

            </div>}
            {
                <div className="row">
                    <div className="col-md-12">
                        <div className="box mb-4">
                            <div className="table_filter mb-2 d-flex align-items-center justify-content-between commonform">
                                <div className=' d-flex'>


                                    <select className="form-select form-select-sm page_limit" id="dt-length-0" onChange={(e) => setPageLimitNo(e.target.value)}>
                                        {PageLimitJson.map((option, index) => (
                                            <option key={index} value={option.value}>
                                                {option.key}
                                            </option>
                                        ))}
                                    </select>


                                    <h3 style={{ marginLeft: "8px", marginTop: "6px" }}>Transaction History</h3>
                                </div>
                                <div className="tblrightfilters d-flex align-items-center">
                                    {/* settraHistDateFrom settraHistDateTo  */}
                                    <div className="d-flex align-items-center datefilter">
                                        <input type="date" className="form-control  text-capitalize mr-2" onChange={(e) => settraHistDateFrom(e.target.value)} value={traHistDateFrom} />
                                        <span>To</span>
                                        <input type="date" className="form-control  text-capitalize ml-2" onChange={(e) => settraHistDateTo(e.target.value)} value={traHistDateTo} />
                                    </div>

                                    {/* UerTypeOption */}
                                    {(useriddata.user_type == 1) && <select className="form-control ml-3 text-capitalize" onChange={(e) => setUserTypeValue(e.target.value)} >
                                        <option value="">User Type</option>
                                        {
                                            UerTypeOption.map((item, index) => (
                                                <>
                                                    <option selected={userTypeValue == item.value} value={item.value}>{item.name}</option>
                                                </>
                                            ))
                                        }
                                    </select>}

                                    {/* transectionType, transectionTime */}
                                    <select className="form-control  ml-3 text-capitalize" onChange={(e) => setTransectionType(e.target.value)}  >
                                        <option value="">All</option>
                                        {
                                            TransectionData.map((item, index) => (
                                                <>
                                                    <option selected={transectionType == item.value} value={item.value}>{item.name}</option>
                                                </>
                                            ))
                                        }
                                    </select>
                                    <select className="form-control ml-3 text-capitalize" onChange={(e) => setTransectionTime(e.target.value)} >
                                        {
                                            TransectionTime.map((item, index) => (
                                                <>
                                                    <option selected={transectionTime == item.value} value={item.value}>{item.name}</option>
                                                </>
                                            ))
                                        }
                                    </select>

                                    <div className="d-flex align-items-center datefilter ">
                                        <PdfExportButton jsonData={exportData} export_for={'walletHistory'} />
                                        <ExcelExportButton jsonData={exportData} export_for={'recruiter'} />
                                        <span onClick={reSetFilterTable} className="reseticon filtericon" data-toggle="tooltip" title="Reset filters">
                                            <i className="fa fa-refresh"></i>
                                        </span>
                                    </div>

                                </div>
                            </div>


                            <div className="table-responsive">
                                {(useriddata.user_type == 1) && <table className="table admintbl text-capitalize1 transectiontbl">
                                    <thead>
                                        <tr>
                                            <th>User Name</th>
                                            <th>User Type</th>
                                            <th>Transactios</th>
                                            <th>Type</th>
                                            <th>Number of coins</th>
                                            <th>Amount(USD)</th>
                                            <th>Date of Transaction</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {
                                            loadingData ?
                                                <>
                                                    <tr>
                                                        <td colSpan={7} className='text-center'>Loading data....</td>
                                                    </tr>
                                                </> : coinsData.transection_history.length == 0 ?
                                                    <>
                                                        <tr>
                                                            <td colSpan={7} className='text-center'>Data not found</td>
                                                        </tr>
                                                    </>
                                                    :
                                                    coinsData.transection_history.map((item, index) => (
                                                        <tr>
                                                            <td className="text-capitalize">{item.first_name + ' ' + item.last_name}</td>
                                                            <td>{item.user_type == 2 ? 'Recruiter' : item.user_type == 3 ? 'Jobseeker' : 'Other'}</td>
                                                            <td className="text-capitalize">

                                                                {
                                                                    (item.transection_type == 'stripe_purchased') ?
                                                                        <><i className="fa fa-arrow-down clrgreen mr-1" aria-hidden="true"></i> Coin purchased</>
                                                                        :
                                                                        <><i className="fa fa-arrow-up clrred mr-1" aria-hidden="true"></i> {item.transection_type2}</>
                                                                }
                                                            </td>
                                                            <td className="text-capitalize"> {(item.transection_type == 'stripe_purchased') ? <>Credit pending</> : <>Debit pending</>}</td>
                                                            <td className="text-capitalize">{item.coins_transection}</td>
                                                            <td className="text-capitalize">AUD {item.amount}</td>
                                                            <td className="text-capitalize">{item.formatted_purchase_date}</td>
                                                        </tr>
                                                    ))
                                        }


                                    </tbody>
                                </table>}

                                {(useriddata.user_type == 2 || useriddata.user_type == 3) && <table className="table admintbl text-capitalize1 transectiontbl">
                                    <thead>
                                        <tr>
                                            <th>Transaction Name</th>
                                            <th>Type</th>
                                            <th>Amount(USD)</th>
                                            <th>Number of coins</th>
                                            <th>Date of Transaction</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {

                                            loadingData ?
                                                <>
                                                    <tr>
                                                        <td colSpan={5} className='text-center'>Loading data....</td>
                                                    </tr>
                                                </> : coinsData.transection_history.length == 0 ?
                                                    <>
                                                        <tr>
                                                            <td colSpan={5} className='text-center'>Data not found</td>
                                                        </tr>
                                                    </>
                                                    :
                                                    coinsData.transection_history.map((item, index) => (
                                                        <tr>
                                                            <td className="text-capitalize">
                                                                {
                                                                    (item.transection_type == 'stripe_purchased') ?
                                                                        <><i className="fa fa-arrow-down clrgreen mr-1" aria-hidden="true"></i> Coin purchased</>
                                                                        :
                                                                        <><i className="fa fa-arrow-up clrred mr-1" aria-hidden="true"></i> {item.transection_type2}</>
                                                                }
                                                            </td>

                                                            <td className="text-capitalize"> {(item.transection_type == 'stripe_purchased') ? <>Credit</> : <>Debit</>}</td>
                                                            <td className="text-capitalize">AUD {item.amount}</td>
                                                            <td className="text-capitalize">{item.coins_transection}</td>
                                                            <td className="text-capitalize">{item.formatted_purchase_date}</td>
                                                            <td className="text-capitalize">
                                                                {
                                                                    (item.transection_type == "withdraw_pending") ?
                                                                        <>
                                                                            <span style={{ display: 'inline-block' }} onClick={() => setHideAndSeekMyRequestModel(item.transection_id)} className='cursor_pointer text_safron'>Report</span>
                                                                            {/* <span style={{ display: 'inline-block' }} onClick={() => transectionCancelAction(item.transection_id)} className='cursor_pointer text_safron ml-2'>Cancel</span> */}
                                                                        </>
                                                                        :
                                                                        <>-------- -------</>
                                                                }

                                                            </td>
                                                        </tr>
                                                    ))
                                        }
                                    </tbody>
                                </table>}
                            </div>

                            <>
                                {!loadingData &&
                                    <nav aria-label="Page navigation">
                                        <ul className="pagination mb-0 justify-content-center">
                                            {/* <RenderPaginationNumbers /> */}
                                            {
                                                RenderPaginationNumbers(totalPageCount)
                                            }
                                        </ul>
                                    </nav>
                                }
                            </>
                        </div>
                    </div>
                </div>
            }



            <Modal show={hideAndSeekMyRequestModel} onHide={() => setHideAndSeekMyRequestModel(false)}>
                <div className="modal-dialog1">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button type="button" className="btn-close" onClick={() => setHideAndSeekMyRequestModel(false)} ></button>
                            <h3>Report</h3>
                            <div className="commonform">
                                <Form noValidate validated={validated} onSubmit={onFinalSubmit} >
                                    <div className="row">
                                        <div className="col-md-12 form-group mb-2">
                                            <label className="form-label" htmlFor="lastName1">Title <span className="starr">*</span></label>
                                            <input className="form-control" name='title' onChange={(e) => setmyRequestDataFormArr({ ...myRequestDataFormArr, ['title']: e.target.value })} value={myRequestDataFormArr.title} required={true} type="text" placeholder="Enter title" />
                                        </div>
                                        <div className="col-md-12 form-group mb-2">
                                            <label className="form-label" htmlFor="exampleInputEmail1">Description <span className="starr">*</span></label>
                                            <textarea className="form-control" name='description' onChange={(e) => setmyRequestDataFormArr({ ...myRequestDataFormArr, ['description']: e.target.value })} value={myRequestDataFormArr.description} maxLength={5000} required={true} rows="5" placeholder="Enter description"></textarea>
                                        </div>
                                        <input type='hidden' name='priority' value={'High'} />
                                        <div className="col-md-12 form-group mt-3">
                                            <button type='submit' className="batn batn-orange w-100">Send</button>
                                        </div>

                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

        </>

    );
}
export default RecWalletHist;