import React, { useState, useRef } from 'react';
import { LoadScript, Autocomplete, GoogleMap, Circle } from '@react-google-maps/api';
import axios from 'axios';

function GoogleAutoCompleteSearch() {
  const [locationInfo, setLocationInfo] = useState(null);
  const [radius, setRadius] = useState(5000); // Default radius in meters
  const [rangeInput, setRangeInput] = useState(5); // Default range in kilometers
  const GAPI = process.env.REACT_APP_GOOGLE_MAPS_API_KEY; // Replace with your Google Maps API key
  //const GAPI = 'AIzaSyAs9hhWwqqhJO9V-0bJwD2xRvLYBurGqyA'; // Replace with your Google Maps API key
  const autocompleteRef = useRef(null);

  const handlePlaceSelect = async () => {
    const place = autocompleteRef.current.getPlace();
    console.log('Place:', place);

    if (!place.geometry) {
      console.error("No details available for input: ", place);
      return;
    }

    try {
      const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?place_id=${place.place_id}&key=${GAPI}&libraries=places&v=weekly&callback=initMap`);
      
      
      if (response.data.results.length > 0) {
        console.log(response.data.results[0].geometry.location);
        const { lat, lng } = response.data.results[0].geometry.location;
        const formattedAddress = response.data.results[0].formatted_address;
        // const postalCode = response.data.results[0].address_components.find(component => component.types.includes('postal_code')).long_name;
        console.log(lat);
        console.log(lng);
        setLocationInfo({ lat, lng });
      } else {
        console.error("No results found for the provided address.");
      }
    } catch (error) {
      console.error("Error fetching geocode data:", error);
    }
  };

  const handleRangeChange = (event) => {
    const newRange = parseFloat(event.target.value);
    setRadius(newRange * 1000); // Convert kilometers to meters
    setRangeInput(newRange);
  };

  const mapStyles = {
    height: "400px",
    width: "100%"
  };

  return (
    <LoadScript
      googleMapsApiKey={GAPI}
      libraries={['places']}
    >
      <Autocomplete
        onLoad={(autocomplete) => {
          console.log('Autocomplete loaded:', autocomplete);
          autocompleteRef.current = autocomplete;
        }}
        onPlaceChanged={handlePlaceSelect}
      >
        <input
          type="text"
          placeholder="Enter a location"
          style={{
            width: '100%',
            height: '40px',
            paddingLeft: '16px',
            marginTop: '2px',
            marginBottom: '12px',
            borderRadius: '3px',
            border: '1px solid #ddd',
            fontSize: '14px',
            zIndex: 999
          }}
        />
      </Autocomplete>

      Location 
      {locationInfo && (
        <div>
          <p>Latitude: {locationInfo.lat}</p>
          <p>Longitude: {locationInfo.lng}</p>
          {/* <p>Address: {locationInfo.formattedAddress}</p> */}
          {/* <p>Pincode: {locationInfo.postalCode}</p> */}
        </div>
      )}
      <br></br>

      <label>
        Range (km):
        <input
          type="range"
          min="1"
          max="20"
          step="1"
          value={rangeInput}
          onChange={handleRangeChange}
        />
        {rangeInput} km
      </label>

      <GoogleMap
        mapContainerStyle={mapStyles}
        zoom={10}
        center={locationInfo ? { lat: locationInfo.lat, lng: locationInfo.lng } : { lat: 40.7128, lng: -74.006 }}
      >
        {radius && (
          <Circle
            center={locationInfo ? { lat: locationInfo.lat, lng: locationInfo.lng } : { lat: 40.7128, lng: -74.006 }}
            radius={radius}
            options={{
              strokeColor: "#000000",
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: "#000000",
              fillOpacity: 0.35,
              clickable: false,
              draggable: false,
              editable: false,
              visible: true,
              radius: radius
            }}
          />
        )}
      </GoogleMap>
    </LoadScript>
  );
}

export default GoogleAutoCompleteSearch;
