import React, { useState, useEffect, Component } from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink, useParams, useNavigate } from 'react-router-dom';
import { Form, Modal } from 'react-bootstrap';
import axios from 'axios';
import ProfileBanner from '../profile_header';
import ProfileSidebar from '../profile_sidebar';

import { ToastContainer, toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';
import { useSelector, useDispatch } from 'react-redux'
import { basicVisaValidationCheck } from '../../redux_state/reducres/checkDocumentstatus';
import CheckValidationComp from '../CheckValidationComp';
import ModalDialog from '../../expocomp/WarningModal';
const VisaDetail = () => {

   const dispatch = useDispatch();
   const [isHidden, setIsHidden] = useState(true);

   const [isHidden2, setIsHidden2] = useState(true);
   const toggleHidden2 = () => { setIsHidden2(!isHidden2); };



   const userdetails = JSON.parse(localStorage.getItem("squadGologin"));
   //const [userDetailsArray, setUserDetailsArray] = useState([]);
   const [getAllVisa, setGetallVisatype] = useState([]);
   const [userUploadVisaDocumentContents, setUserUploadVisaDocumentContents] = useState([]);
   const Months = [{ name: 'January', key: 1, abbreviation: 'Jan' }, { name: 'February', key: 2, abbreviation: 'Feb' }, { name: 'March', key: 3, abbreviation: 'Mar' }, { name: 'April', key: 4, abbreviation: 'Apr' }, { name: 'May', key: 5, abbreviation: 'May' }, { name: 'June', key: 6, abbreviation: 'Jun' }, { name: 'July', key: 7, abbreviation: 'Jul' }, { name: 'August', key: 8, abbreviation: 'Aug' }, { name: 'September', key: 9, abbreviation: 'Sep' }, { name: 'October', key: 10, abbreviation: 'Oct' }, { name: 'November', key: 11, abbreviation: 'Nov' }, { name: 'December', key: 12, abbreviation: 'Dec' },];
   const DayNumberLoop = (attr = '') => { const itemElements = []; itemElements.push(<option value="">DD</option>); for (let i = 1; i < 32; i++) { itemElements.push(<option selected={(attr != '' && attr.select_attr == i) ? true : false} value={i}>{i}</option>); } return itemElements; }
   const MonthsHtml = (attr = '') => { const itemElements = []; itemElements.push(<option value="">Month</option>); Months.map((item, index) => (itemElements.push(<option selected={(attr != '' && attr.select_attr == item.name) ? true : false} value={item.name}>{item.name}</option>))); return itemElements }
   const currentYear = new Date().getFullYear();
   const YearFutureLoop = (attr = '') => { const itemElements = []; itemElements.push(<option value="">Year</option>); for (let i = currentYear; i <= currentYear + 10; i++) { itemElements.push(<option selected={(attr != '' && attr.select_attr == i) ? true : false} value={i}>{i}</option>); } return itemElements }
   const [isAccVerified, setIsAccVerified] = useState('false');
   const [iAmAustralian, setIAmAustralian] = useState(userdetails.i_am_australian);
   const [visaDetails, setVisaDetails] = useState(
      {
         type_of_visa: userdetails.type_of_visa,
         visa_expire_on: userdetails.visa_expire_on,
         visa_expire_day: userdetails.visa_expire_day,
         visa_expire_month: userdetails.visa_expire_month,
         visa_expire_year: userdetails.visa_expire_year,
         visa_grant_number: userdetails.visa_grant_number,
         visa_subclass: userdetails.visa_subclass,
      });

   const toggleHidden = () => { setIsHidden(!isHidden); };

   const getUserDetails = () => {
      (async () => {
         try {
            const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/userdetails/" + userdetails.unique_id);
            if (resData.data.status == 1) {
               //setUserDetailsArray(resData.data.data.results);
               setIAmAustralian(resData.data.data.results.i_am_australian);
               setVisaDetails(resData.data.data.results);
               setUserUploadVisaDocumentContents(resData.data.data.results.user_document_visatype);
               setIsAccVerified(resData.data.data.results.verifications_status.statustype.visa_details);
            }
         } catch (error) { console.log(error); }
      })();
   }

   useEffect(() => {
      if (userdetails.unique_id) {
         getUserDetails();
         (async () => {
            try {
               const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/get_all_visatype");
               if (resData.data.status == 1) { setGetallVisatype(resData.data.data.results); }
            } catch (error) { console.log(error); }
         })();
      } else { setTimeout(function () { window.location.replace("/login"); }, 100) }
   }, []);



   const [validated, setValidated] = useState(false);
   const onvisaDetailsSubmit = (e) => {
      e.preventDefault();
      const form = e.currentTarget;
      setIsModalShow(false);
      if (form.checkValidity() === false) {
         setValidated(true); // Show validation errors
         return; // Don't proceed if the form is invalid
      }
     
      if (isAccVerified == true && iAmAustralian == 0) {
         setIsModalShow(true);
      } else {
         formSubmitData();
      }

      setValidated(false); // Reset validation state
   };


   const formSubmitData = async () => {
      try {
         const formData = new FormData();
         formData.append('type_of_visa', visaDetails.type_of_visa);
         formData.append('visa_expire_day', visaDetails.visa_expire_day);
         formData.append('visa_expire_month', visaDetails.visa_expire_month);
         formData.append('visa_expire_year', visaDetails.visa_expire_year);
         formData.append('visa_grant_number', visaDetails.visa_grant_number);
         formData.append('i_am_australian', iAmAustralian);
         formData.append('visa_subclass', visaDetails.visa_subclass);
         if (userdetails.unique_id) {
            formData.append('userid', userdetails.unique_id);
         }
         const response = await axios.post(
            `https://squadgoo.com.au/api.squadgoo.com.au/userdetails_data_update`,
            formData,
            { headers: { 'Content-Type': 'multipart/form-data' } }
         );
         if (response.data.status === 1) {
            getUserDetails(); // Refresh user details
            toast.success(response.data.message);
            setIsModalShow(false); // Close the modal
            dispatch(basicVisaValidationCheck(0));
            if(iAmAustralian == 0){
               setIsDocumentsModalShow(true);
            }
         } else {
            toast.warning(response.data.message);
            setIsModalShow(false); // Close the modal
         }
      } catch (error) {
         console.error('Error during form submission:', error);
         toast.error('An error occurred while submitting the form.');
      }
   };






   //userDetailsArray

   const visaDetailsUpdateFun = (event) => {
      const { name, value } = event.target;
      setVisaDetails((prevFormData) => ({ ...prevFormData, [name]: value }));
   };





   /*--------------------------------------------------------*/

   const [isModalShow, setIsModalShow] = useState(false);
   const actionConfirmed = () => { formSubmitData(); };

   /*---------------------------fileuploadCode-----------------------------*/
   const [isDocumentsModalShow, setIsDocumentsModalShow] = useState(false);
   const [fileLoadingContent, setFileLoadingContent] = useState(false);

   const onAddressDuocumentSubmit = () => {
      return false;
   }
   const userFileUploadToServerVisaFun = async (field, file) => {
      const maxSizeInBytes = 5 * 1024 * 1024; // For example, 1 MB
      if (file.size > maxSizeInBytes) {
         console.log("File size is greater than the specified limit.");
         toast.warning('File size is greater than the specified limit.');
      } else {
         console.log("File size is within the specified limit.");
         try {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('uploaded_for', field);
            formData.append('userid', userdetails.unique_id);
            formData.append('maxSizeInBytes', maxSizeInBytes);
            const response = await axios.post(
               `https://squadgoo.com.au/api.squadgoo.com.au/userdetails_file_uploads`,
               formData, { headers: { 'Content-Type': 'multipart/form-data' } });
            //console.log('File uploaded successfully:', response.data);
            if (response.data.status == 1) {
               setUserUploadVisaDocumentContents(response.data.data.upload_array);
               dispatch(basicVisaValidationCheck(1));
               setIsDocumentsModalShow(false);
            } else { toast.warning(response.data.message); }
         } catch (error) { console.error('Error uploading file:', error); }
      }
   };

   const removeFileFromServer = async (field, id) => {
      if (window.confirm("Do you want to remove this file?")) {
         try {
            const formData = new FormData();
            formData.append('field', field);
            formData.append('file_id', id);
            const response = await axios.post(
               `https://squadgoo.com.au/api.squadgoo.com.au/userdetails_upload_file_remove`,
               formData, { headers: { 'Content-Type': 'multipart/form-data' } });
            if (response.data.status == 1) {
               toast.success('Removed Successfully!');
               setUserUploadVisaDocumentContents(response.data.data)

            } else { toast.warning(response.data.message); }
         } catch (error) { console.error('Error uploading file:', error); }
      }
   }


   return (
      <>
         {/* <ToastContainer />*/}

         <div className="dashboarwrap">
            <ProfileBanner />
            <div className="jobbtmsec py-5 admininfo">
               <div className="container">

                  <div className="row">
                     <div className="col-md-3 profilesidebarwrap">
                        <ProfileSidebar />
                     </div>

                     <div className="col-md-9">
                        <div className="profilerightwraper stepscont commonform basicdetail">
                           <div className="card-box work-experiencesec commonform">
                              <div className="d-flex align-items-center justify-content-between">
                                 <h3 className="mb-3">
                                    Visa details
                                    {
                                       isAccVerified == true ?
                                          <><img className='veri_unveri_icon' src="/front/images/vfy.png" data-toggle="tooltip" data-placement="top" title="verified"></img></> :
                                          isAccVerified == false ?
                                             <><img className='veri_unveri_icon' src="/front/images/uvfy.png" data-toggle="tooltip" data-placement="top" title="Your Profile is in Review"></img></> :
                                             <></>
                                    }
                                 </h3>
                                 {<div className="editbtn" onClick={toggleHidden} ><img src="/front/images/edit.png" /></div>}
                              </div>
                              <div className="editblock">
                                 <div className="editblockinner d-flex align-items-start">
                                    {(iAmAustralian == 1 && isHidden) ? 'I am an australian citizen' : ''}
                                 </div>
                                 {iAmAustralian == 0 && <div className="editblockinner d-flex align-items-start">
                                    <h4>
                                       <div>
                                          <span className="mr-2 firstcoldtl">Visa subclass :</span>
                                          <span>{visaDetails.type_of_visa_name ? visaDetails.type_of_visa_name : '......'}</span>
                                       </div>
                                       <div>
                                          <span className="mr-2 firstcoldtl">Visa expiry  :</span>
                                          <span>
                                             {visaDetails.visa_expire_day ? visaDetails.visa_expire_day : 'dd'}-
                                             {visaDetails.visa_expire_month ? visaDetails.visa_expire_month : 'mm'}-
                                             {visaDetails.visa_expire_year ? visaDetails.visa_expire_year : 'yyyy'}
                                          </span>
                                       </div>
                                       <div>
                                          <span className="mr-2 firstcoldtl">Visa grant number :</span>
                                          <span>{visaDetails.visa_grant_number ? visaDetails.visa_grant_number : '......'}</span>
                                       </div>
                                    </h4>
                                 </div>}

                                
                                 {!isHidden &&
                                    <div className="editblockinnerbtm" style={{
                                       paddingTop: '9px' ,
                                       marginTop: '15px' ,
                                       borderTop: '1px solid #ccc' 
                                    }}>
                                       <Form noValidate validated={validated} onSubmit={onvisaDetailsSubmit}>
                                          <div className="row">
                                             <div className="col-md-12">
                                                <div class="commonchecbox d-flex"><span class="agrrimentbox">
                                                   <input type="checkbox" onChange={() => setIAmAustralian(iAmAustralian == 0 ? 1 : 0)} checked={iAmAustralian == 1 ? true : false}  value={iAmAustralian} id="i_am_australian" name="i_am_australian" />
                                                   <span class="checkafter"></span></span>
                                                   <label class="checklabel" for="i_am_australian">
                                                      I am an australian citizen
                                                   </label>
                                                </div>
                                             </div>
                                             {
                                                iAmAustralian == 0 &&
                                                <>

                                                   <div className="col-md-6">
                                                      <div className="form-group ">
                                                         <label className="form-label">Visa subclass <span className="starr">*</span></label>
                                                         <select className="form-control" required={true} name='type_of_visa' onChange={visaDetailsUpdateFun}  >
                                                            <option value="">Select</option>
                                                            {
                                                               getAllVisa.length ?
                                                                  <>
                                                                     {
                                                                        getAllVisa.map((item, index) => (
                                                                           <option selected={visaDetails.type_of_visa == item.id ? true : false} value={item.id}>{item.visa}</option>
                                                                        ))
                                                                     }
                                                                  </>
                                                                  : ''
                                                            }
                                                         </select>
                                                         {(visaDetails.type_of_visa == 6 || visaDetails.type_of_visa == 1) && 
                                                            <div class="mt-2">
                                                               <input type="text"  required={true} name="visa_subclass" class="form-control" length={50} placeholder="Visa subclass" onChange={visaDetailsUpdateFun} value={visaDetails.visa_subclass} />
                                                            </div>
                                                         }
                                                      </div>
                                                   </div>
                                                   <div className="col-md-6">
                                                      <div className="form-group ">
                                                         <label className="form-label">Visa expiry <span className="starr">*</span></label>
                                                         {/* --------------------- */}
                                                         <div className="d-flex datefildgruop  mb-2">
                                                            <select name="visa_expire_day" style={{ width: '150px' }} required={true} className="form-control mr-2 datefild width-100px" onChange={visaDetailsUpdateFun}>
                                                               {<DayNumberLoop select_attr={visaDetails.visa_expire_day} />}
                                                            </select>
                                                            <select name="visa_expire_month" style={{ width: '150px' }} required={true} className="form-control mr-2 monthfild width-100px" onChange={visaDetailsUpdateFun}>
                                                               {<MonthsHtml select_attr={visaDetails.visa_expire_month} />}
                                                            </select>
                                                            <select name="visa_expire_year" style={{ width: '150px' }} required={true} className="form-control yearfild width-100px" onChange={visaDetailsUpdateFun} >
                                                               {<YearFutureLoop select_attr={visaDetails.visa_expire_year} />}
                                                            </select>
                                                         </div>
                                                         {/* --------------------- */}
                                                      </div>
                                                   </div>

                                                   <div className="col-md-6">
                                                      <div className="form-group mb-0 ">
                                                         <label className="form-label">Visa grant number</label>
                                                         <div className="">
                                                            <input type="text" maxLength={13} name='visa_grant_number' value={visaDetails.visa_grant_number} onChange={visaDetailsUpdateFun} className="form-control" placeholder={'Visa grant number'} />
                                                         </div>
                                                      </div>
                                                   </div>
                                                </>
                                             }


                                             <div className="col-md-12 mt-3">
                                                <button type='submit' className="batn batn-rounded rounded-orange mr-2">Save</button>
                                                <button type='button' onClick={(e) => setIsHidden(true)} className="batn batn-rounded rounded-orange mr-2">Cancel</button>
                                             </div>

                                          </div>
                                       </Form>

                                       {/* ---------------file upload data---------------- */}

                                       <Modal show={isDocumentsModalShow} onHide={() => setIsDocumentsModalShow(false)} className="documentnewmodal">
                                          <div className="modal-dialog1 findastaffmodle">
                                             <div className="modal-content">
                                                <div className="modal-body p-3">
                                                   <button type="button" className="btn-close" onClick={() => setIsDocumentsModalShow(false)} disabled={fileLoadingContent} ></button>
                                                   <h4 className="">Upload document</h4>
                                                   <div className="row stepscont">
                                                      <div className="col-md-12 stepscontright">
                                                         <div className="row">
                                                            <Form noValidate validated={validated} onSubmit={onAddressDuocumentSubmit}>
                                                               <div className="row">

                                                                  <div className="col-md-12">
                                                                     <div className="form-group mb-2">
                                                                        <label className="form-label">Upload Document </label>
                                                                        <div className="addressproofdoc">

                                                                           {
                                                                              (userUploadVisaDocumentContents.length >= 1) ?
                                                                                 <><h5 className='cantupload'>You can't upload more document</h5></>
                                                                                 :
                                                                                 <>
                                                                                    <div className="customuploadsec1 form-group identityproof newfiletype">
                                                                                       <input className='form-control' type="file" accept="image/x-png,image/gif,image/jpeg ,application/pdf" onChange={(e) => userFileUploadToServerVisaFun('address_document_proof', e.target.files[0])} />
                                                                                    </div>
                                                                                    <p className="mt-2 purpleclr font12">jpg, pdf (Max file size 5 MB)</p>
                                                                                 </>
                                                                           }

                                                                        </div>
                                                                     </div>
                                                                  </div>
                                                                  {(fileLoadingContent == true) && <p className='text-center'>Please wait....</p>}
                                                                  {(fileLoadingContent == false && userUploadVisaDocumentContents.length < 1) && <div className="col-md-12">
                                                                     <button onClick={(e) => setIsHidden(true)} className="batn batn-rounded rounded-orange mr-2">Save</button>
                                                                     <button onClick={(e) => setIsHidden(true)} className="batn batn-rounded rounded-orange mr-2">Cancel</button>
                                                                  </div>}

                                                               </div>
                                                            </Form>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </Modal>
                                       {/* ---------------file upload data---------------- */}
                                       <ModalDialog
                                          show={isModalShow}
                                          onClose={() => setIsModalShow(false)} // Close the modal without taking action
                                          onConfirm={actionConfirmed} // Action to take when confirming
                                          title="Existing details are already verified, Are you sure to change it?"
                                          message="This may cause you to wait for re-verification from Squad Goo."
                                       />
                                    </div>
                                 }

                              </div>
                           </div>

                           {/* display_none */}
                           <div className="card-box work-experiencesec commonform mt-4 display_none">
                              <div className="d-flex justify-content-between">
                                 <h3 className="mb-3">Document proof</h3>
                                 <div className="editbtn" onClick={toggleHidden2} ><img src="/front/images/edit.png" /></div>
                              </div>



                              <div className="editblock">
                                 <div className="editblockinner">
                                    <div className="d-flex align-items-center justify-content-between mb-2">
                                       <p className="mb-0 largepera">Visa</p>
                                       <div className="d-flex flex-wrap">

                                          {
                                             <>
                                                {
                                                   userUploadVisaDocumentContents.map((item, index) => (
                                                      <>
                                                         <div className="documentwrap d-flex align-items-center mr-2">
                                                            <a className='document_list' target="_blank" href={'https://squadgoo.com.au/api.squadgoo.com.au/' + item.document_url}>
                                                               <p>Visa Document {index + 1}</p>
                                                            </a>
                                                            <div className="documentwrapinner d-flex align-items-center">
                                                               {/* <a className='cursor_pointer ml-2' target="_blank" href={'https://squadgoo.com.au/api.squadgoo.com.au/'+item.document_url}  download ><img src="/front/images/download.png" /></a> */}
                                                               <span className='cursor_pointer ml-2' onClick={e => removeFileFromServer('user_document_visatype', item.id)}><img src="/front/images/delete.png" /></span>
                                                            </div>
                                                         </div>
                                                      </>
                                                   ))
                                                }
                                             </>
                                          }
                                          {/* <div className="documentwrap d-flex align-items-center mr-2">
                                                <p>visa_front.jpg</p>
                                                <a href="#"><img src="/front/images/download.png"/></a><a href="#"><img src="/front/images/delete.png"/></a>
                                             </div> */}
                                       </div>
                                    </div>

                                 </div>
                                 {!isHidden2 &&
                                    <div className="editblockinnerbtm">
                                       <Form noValidate validated={validated} onSubmit={onvisaDetailsSubmit}>
                                          <div className="row">

                                             <div className="col-md-6">
                                                <div className="form-group addressproofdoc mb-3">
                                                   <label className="form-label">Upload your visa proof<span className="starr">*</span>
                                                      <span className="infowrap"><i className="fa fa-info"></i>
                                                         <span className="onhoverinfo">Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum</span>
                                                      </span>
                                                   </label>



                                                   {
                                                      userUploadVisaDocumentContents.length >= 2 ?
                                                         <><h5 className='cantupload'>You can't upload more document</h5></>
                                                         :
                                                         <>
                                                            <div className="customuploadsec">
                                                               <input type="file" accept="image/x-png,image/gif,image/jpeg ,application/pdf" onChange={(e) => userFileUploadToServerVisaFun('user_document_visatype', e.target.files[0], true)} />
                                                               <div className="customuploadinner text-center">
                                                                  <div className="my-4"><img src="/front/images/uploadicon.png" className="mt-4" /></div>
                                                                  <p className="mb-0">click here to upload a file</p>
                                                               </div>
                                                            </div>
                                                         </>
                                                   }

                                                   <p className="mt-2 purpleclr font12">jpg, pdf (Max file size 5 MB)</p>
                                                </div>
                                             </div>
                                             <div className="col-md-12 mt-3">
                                                <button href="#" className="batn batn-rounded rounded-orange mr-2">Save</button>
                                                <button href="#" onClick={(e) => setIsHidden2(true)} className="batn batn-rounded rounded-orange mr-2">Cancel</button>
                                             </div>
                                          </div>
                                       </Form>
                                    </div>
                                 }
                              </div>
                           </div>

                        </div>
                     </div>

                  </div>
               </div>
            </div>



         </div>
      </>
   );
}
export default VisaDetail;