import React, { useState, useEffect, Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';
import WorkExpComp from '../../WorkExpComp';
const JobSeekWorkExp = () => {
    const loggedInUser = JSON.parse(localStorage.getItem("squadGologin"));
    const [userID, setuserID] = useState(0);
    const [userDetailsArr, setuserDetailsArr] = useState([]);

    const [isUserVerified, setIsUserVerified] = useState(false);
    const [isDocuemntLoad, setisDocuemntLoad] = useState('false');

    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        const userid = queryParameters.get("id");
        if (userid) {
            setuserID(userid);
            getUserDetailsFun(userid);
        }
    }, []);


    const getUserDetailsFun = (userid) => {
        setisDocuemntLoad('false');
        {userid && (async () => {
            try {
                const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/userdetails/" + userid);
                if (resData.data.status == 1) {
                    setisDocuemntLoad(true);
                    setuserDetailsArr(resData.data.data.results);
                    console.log(resData.data.data.results.verifications_status);
                    setIsUserVerified(resData.data.data.results.verifications_status.statustype.work_experience_verification);
                }
            } catch (error) { console.log(error); }
        })()}
    }



    const loggedInUserVelidationFun = (calltype) => {
        (async () => {
            try {
                const response = await axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/user_details_verifications/${loggedInUser.login_token}/${userDetailsArr.user_id}/${calltype}`);
                if (response.data.status == 1) {
                    toast.success(response.data.message);
                    getUserDetailsFun(userID);
                }
            } catch (error) { console.log(error); }
        })();
    }


    return (
        <>
            <div className="card-box work-experiencesec seekerworkexperienceadmin commonform mb-3">
                <div className="row align-items-start editfieldss">
                    <div className="d-flex justify-content-between pb-0">
                        <h3 className="mb-3">Work Experience</h3>
                        {
                            (loggedInUser.user_type == 1)
                            &&
                            <>
                                <div>
                                    <div className="disableprofilewrapinner border-0">
                                        {
                                            isUserVerified ?
                                                <><img className='veriunveri-images' src="/front/images/vfy.png"></img></>
                                                :
                                                <><img className='veriunveri-images' src="/front/images/uvfy.png"></img></>
                                        }
                                        <input
                                            type="checkbox"
                                            checked={isUserVerified}
                                            onChange={() => { setIsUserVerified(!isUserVerified); loggedInUserVelidationFun('work_experience_verification') }}
                                        />
                                        {/* <span className="slider round"></span> */}
                                    </div>
                                </div>
                            </>

                        }
                    </div>
                    {/* <WorkExpComp  attr={userID} /> */}
                    {
                        (userID == 0) ?
                            <p className='text-center mt-10'><img className='width-100' src={'/front/images/loader.gif'} /></p>
                            :
                            <>
                              {(isDocuemntLoad == true)  && <WorkExpComp attr={userID} />}
                            </>
                    }
                </div>
            </div>
        </>
    );
}
export default JobSeekWorkExp;