import { BrowserRouter as Router, Routes, Route ,  NavLink , useParams ,useNavigate  }  from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';

const ProfileNotCompleted = () => {
   const userdetails  =  JSON.parse(localStorage.getItem("squadGologinSession"));
    return (
    <>
    <div className="dashboarwrap profilenotcompleted">
       <div className="jobbanner">
          <div className="container">
             <div className="row">
                <div className="col-md-12">
                   <h2>Dashboard</h2>
                   <form className="searchform d-flex">
                      <input type="text" className="form-control" placeholder="Search your job offers here"></input><input type="submit" value="Search"></input>
                   </form>
                </div>
             </div>
          </div>
       </div>
       <div className="jobbtmsec py-4">
          <div className="container">
             <div className="row">
                <div className="col-md-12">
                <ul className="nav nav-tabs jobtab d-flex" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="nav-link jobtablink active" id="activejob-tab" data-bs-toggle="tab" data-bs-target="#activejob" type="button" role="tab" aria-controls="home" aria-selected="true">Active job offers</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link jobtablink" id="completedjob-tab" data-bs-toggle="tab" data-bs-target="#completedjob" type="button" role="tab" aria-controls="profile" aria-selected="false">Completed job offers</button>
                    </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="activejob" role="tabpanel" aria-labelledby="activejob-tab">
                <div className="row">
               
                <div className="col-md-12">
                   <div className="d-flex flex-wrap justify-content-between mb-2">
                      <h3>0 active job offers</h3>
                   </div>
                   <div className="prfilenotcomp">
                     <div className="prfilenotcompinner">
                          <img src="/front/images/noprofileimhg.png"></img>
                          <p>It s looks like you haven't completed your profile<br></br>Go to <NavLink to={`/basic-detail`}  className="formlink">Profile</NavLink> to complete it</p>
                       </div>
                   </div>
              
                </div>


               </div>
                </div>
                <div className="tab-pane fade" id="completedjob" role="tabpanel" aria-labelledby="completedjob-tab">
                <div className="row">
                <div className="col-md-3 sidebarwrap">
                   <div className="jobfilter">
                      <h3 className="mb-4">Filters</h3>
                      <div className="jobfilterinner card-box">
                         <form className="commonform">
                            <div className="filtersecblock mb-4">
                               <div className="sidebarttl d-flex justify-content-between flex-wrap">
                                  <h4>Location</h4>
                                  <a href="#">Reset</a>
                               </div>
                               <div className="form-group">
                                  <select className="form-control searchcity">
                                     <option>Select City</option>
                                     <option>Select City</option>
                                     <option>Select City</option>
                                  </select>
                               </div>
                            </div>
                            <div className="filtersecblock mb-4">
                               <div className="sidebarttl d-flex justify-content-between flex-wrap">
                                  <h4>Job Category</h4>
                                  <a href="#">Reset</a>
                               </div>
                               <div className="form-group">
                                  <input type="text" className="form-control jobcategorysearch"/>
                                  <div className="serch_categorylist">
                                     <label className="checkboxlabl"><input type="checkbox"></input><span></span>Electrician</label>
                                     <label className="checkboxlabl"><input type="checkbox"></input><span></span>Plumber</label>
                                     <label className="checkboxlabl"><input type="checkbox"></input><span></span>Constructer</label>
                                     <label className="checkboxlabl"><input type="checkbox"></input><span></span>Driver</label>
                                     <label className="checkboxlabl"><input type="checkbox"></input><span></span>Care taker</label>
                                     <label className="checkboxlabl"><input type="checkbox"></input><span></span>Home tutor</label>
                                     <label className="checkboxlabl"><input type="checkbox"></input><span></span>Pet care</label>
                                     <label className="checkboxlabl"><input type="checkbox"></input><span></span>Cleaner</label>
                                     <label className="checkboxlabl"><input type="checkbox"></input><span></span>Chef</label>
                                  </div>
                               </div>
                            </div>
                            <div className="filtersecblock mb-4">
                               <div className="sidebarttl d-flex justify-content-between flex-wrap">
                                  <h4>Job type</h4>
                                  <a href="#">Reset</a>
                               </div>
                               <div className="form-group">
                                  <div className="switch-field">
                                     <input type="radio" id="monthly" name="jobtype" value="monthly" checked/>
                                     <label htmlFor="monthly">Monthly</label>
                                     <input type="radio" id="weekly" name="jobtype" value="weekly" />
                                     <label htmlFor="weekly">Weekly</label>
                                     <input type="radio" id="hourly" name="jobtype" value="hourly" />
                                     <label htmlFor="hourly">Hourly</label>
                                  </div>
                               </div>
                            </div>
                            <div className="filtersecblock">
                               <div className="sidebarttl d-flex justify-content-between flex-wrap">
                                  <h4>Pay rate</h4>
                                  <a href="#">Reset</a>
                               </div>
                               <div className="form-group">
                                  <label className="form-label">Min</label>
                                  <input type="text" className="form-control" placeholder="$" />
                               </div>
                               <div className="form-group mb-2">
                                  <label className="form-label">Max</label>
                                  <input type="text" className="form-control" placeholder="$" />
                               </div>
                               <div className="form-group">
                                  <input type="Submit" className="batn batn-orange w-100" value="Apply Filter"></input>
                               </div>
                            </div>
                         </form>
                      </div>
                   </div>
                </div>
                <div className="col-md-9">
                   <div className="d-flex flex-wrap justify-content-between mb-2">
                      <h3>0 Completed job offers</h3>
                   </div>
                   <div className="prfilenotcomp">
                     <div className="prfilenotcompinner">
                          <img src="/front/images/noprofileimhg.png"></img>
                          <p>It looks like you haven't completed your profile<br></br>Go to <NavLink to={`/accountsetup?id=${userdetails.unique_id}`}  className="formlink">Profile</NavLink> to complete it</p>
                       </div>
                   </div>
                    
                </div>
               </div>
                </div>
            </div>
                  
                </div>
             </div>
             
            
          </div>
       </div>


       <div className="modal fade modalsmall jobacceptmodle" id="jobacceptmodle" tabIndex="-1" aria-labelledby="jobacceptmodleLabel" aria-hidden="true">
          <div className="modal-dialog">
             <div className="modal-content">
                <div className="modal-body">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <div className="text-center mb-3">
                    <img src="/front/images/greencheck.svg" />
                    </div>
                <h5 className="text-center mb-4">You have successfully accepted the job offer!</h5>
                
                   <div className="btnsgroup text-center">
                         <a href="#" className="batn batn-orange" data-bs-dismiss="modal">Chat now</a>
                         <a href="#" className="batn batn-orange batn-border">View company profile</a>
                      </div>
                </div>
             </div>
          </div>
       </div>
       
       <div className="modal fade modallarg jobmodle" id="jobsetailmodle" tabIndex="-1" aria-labelledby="jobsetailmodleLabel" aria-hidden="true">
          <div className="modal-dialog">
             <div className="modal-content">
                <div className="modal-body">
                   <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                   <div className="joblistbox">
                      <div className="d-flex flex-wrap justify-content-between">
                         <h3>Full house painting</h3>
                         <h3 className="job_price orange_clr">$500/month</h3>
                      </div>
                      <div><span className="tags">House Management</span></div>
                      <p>Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Sed hendrerit. Maecenas nec odio et ante condimentum rhoncus, sem quam semper libero condimentum rhoncus, sem quam...</p>
                      <div className="d-flex flex-wrap">
                         <div className="icontxt"><img src="/front/images/calender.png"></img> Offer date: <span>14 Apr</span></div>
                         <div className="icontxt"><img src="/front/images/clock.png"></img> Offer expire in: <span>1 day</span></div>
                         <div className="icontxt"><img src="/front/images/location.png"></img> Location: <span>Sydney</span></div>
                         <div className="icontxt"><img src="/front/images/bag.png"></img> Job type: <span>Monthly</span></div>
                         <div className="icontxt"><img src="/front/images/star.png"></img> Experience: <span>2.5 years</span></div>
                      </div>
                      <hr>
                      </hr>
                      <h5>License and certificates</h5>
                      <p>Jobseeker should have following license and certificates:</p>
                      <ul>
                         <li>1. Heavy driving license </li>
                         <li>2. COVID - 19 certificate (optional) </li>
                         <li>3. Character certificate</li>
                      </ul>
                      <hr>
                      </hr>
                      <h5>Education qualification</h5>
                      <p>Jobseeker should have following degree and diplomas:</p>
                      <ul>
                         <li>1. HSC pass with 80%.</li>
                         <li>2. SSC pass with 60%.</li>
                         <li>3. Two year Industrial training diploma. (optional)</li>
                      </ul>
                      <hr>
                      </hr>
                      <h5>Language known</h5>
                      <p>Jobseeker should be fluent in following languages:</p>
                      <ul>
                         <li>1. English.</li>
                         <li>2. France.</li>
                      </ul>
                      <hr>
                      </hr>
                      <h5>About recruiter</h5>
                      <div className="aboutrecuruter d-flex">
                         <div className="recruterimg">
                            <img src="/front/images/recruterimg.png"></img>
                         </div>
                         <div className="recruterabout">
                            <p>Nunc nec neque. Duis vel nibh at velit scelerisque suscipit. Praesent nonummy mi in odio. Phasellus volutpat, metus eget egestas mollis, lacus lacus blandit dui, id egestas quam mauris ut lacus. Nulla consequat massa quis enim sed a libero.</p>
                            <p><a href="#" className="linktxt">www.info.com</a></p>
                         </div>
                      </div>
                      <div className="btnsgroup">
                         <a href="#" className="batn batn-green" data-bs-toggle="modal" data-bs-target="#jobacceptmodle" data-bs-dismiss="modal">Accept Offer</a>
                         <a href="#" className="batn batn-red" data-bs-toggle="modal" data-bs-target="#jobdeclinemodle" data-bs-dismiss="modal">Decline Offer</a>
                      </div>
                   </div>
                </div>
             </div>
          </div>
       </div>

       <div className="modal fade modallarg jobdeclinedmodle" id="jobdeclinemodle" tabIndex="-1" aria-labelledby="jobdeclinemodleLabel" aria-hidden="true">
          <div className="modal-dialog">
             <div className="modal-content">
                <div className="modal-body">
                   <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                   <form className="commonform">
                      <div className="from-group mb-3">
                         <h4>Please tell us the reason to decline this job offer</h4>
                         <textarea className="form-control" rows="7" placeholder="Describe here"></textarea>
                      </div>
                      <div className="from-group">
                      <a href="#" className="batn batn-orange" value="Submit" data-bs-toggle="modal" data-bs-target="#ask-jobdeclinedmodle" data-bs-dismiss="modal">Submit</a>
                      </div>
                   </form>
                </div>
             </div>
          </div>
       </div>



       <div className="modal fade modalsmall ask-jobdeclinedmodle" id="ask-jobdeclinedmodle" tabIndex="-1" aria-labelledby="ask-jobdeclinedmodleLabel" aria-hidden="true">
          <div className="modal-dialog">
             <div className="modal-content">
                <div className="modal-body">
                   <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                   <h5 className="text-center mb-4">You can't see this offer again once you decline it Are you sure to decline this offer?</h5>
                   <div className="btnsgroup text-center">
                         <a href="#" className="batn batn-orange" data-bs-toggle="modal" data-bs-target="#jobdeclinesucess" data-bs-dismiss="modal">Yes</a>
                         <a href="#" className="batn batn-orange batn-border">No</a>
                      </div>
                </div>
             </div>
          </div>
       </div>


       <div className="modal fade modalsmallest jobdeclinesucess" id="jobdeclinesucess" tabIndex="-1" aria-labelledby="jobdeclinesucessLabel" aria-hidden="true">
          <div className="modal-dialog">
             <div className="modal-content">
                <div className="modal-body">
                   <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                   <div className="text-center mb-3"><img src="/front/images/greencheck.svg"></img></div>
                   <h5 className="text-center mb-4">Reason submitted successfully!</h5>
                </div>
             </div>
          </div>
       </div>
       

    </div>
    </>
    );
    }
    export default ProfileNotCompleted;