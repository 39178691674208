import React, { useState, useEffect, useMemo, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, NavLink, useParams, useNavigate } from 'react-router-dom';
import { Modal,Button } from 'react-bootstrap';
import axios from 'axios';

import { toast } from 'react-toastify';
import { useUser } from './ApiDataContext';
import { useSelector } from 'react-redux';


import Cropper from 'react-easy-crop';
import { getCroppedImg } from '../expocomp/cropper-utils';

//import 'react-toastify/dist/ReactToastify.css';
//import { useSelector, useDispatch } from 'react-redux';
const ProfileBanner = () => {
   const userdetails =  JSON.parse(localStorage.getItem("squadGologinSession"));
   const userdataredux2 = useSelector(state => state.userDataArrayValue.userdata_array)  ;
   const userdataredux = userdataredux2 ? userdataredux2 : userdetails ;
   const profileStatus = JSON.parse(localStorage.getItem("profileStatus"));
   const capturedProfilePhotoSetting = (localStorage.getItem("capturedProfilePhotoSetting"));
   const setprofilepic = (capturedProfilePhotoSetting) ? capturedProfilePhotoSetting : userdetails.profilepic;
   //'https://squadgoo.com.au/api.squadgoo.com.au/'+profilePicUrl : '/front/images/user.svg'
   const [profilePicUrl, setProfilePicUrl] = useState(setprofilepic);
   //const [profilePicUrl, setProfilePicUrl] = useState('');
   const [totalRating, setTotalRating] = useState(0);
   const [isModalShow , setIsModalShow] = useState(false);
   //

   const [totalAvgRating, setTotalAvgRating] = useState(0.0);

   const [setIsDataLoading, setsetIsDataLoading] = useState(true);
   //const [isUserVerified, setisUserVerified] = useState('false');
   //const [isUserContactVerified, setisUserContactVerified] = useState(0);
   const { loginUserData } = useUser();

   
   const isUserVerified = useMemo(() => {
      if (loginUserData) {
         return loginUserData.verifications_status.verification_status;
      }
      return null;
   }, [loginUserData]);

   const isUserContactVerified = useMemo(() => {
      if (loginUserData) {
         return loginUserData.verifications_status.contact_status;
      }
      return null;
   }, [loginUserData]);

   useEffect(() => {
      if (userdetails.unique_id) {
         if (typeof (userdetails.total_rating_count) != 'undefined') {
            setTotalRating(userdetails.total_rating_count.total_count);
            setTotalAvgRating(userdetails.total_rating_count.average_rating);
         }
         if (loginUserData) {
            setsetIsDataLoading(false);
         }
      } else {
         setTimeout(function () { window.location.replace("/login"); }, 100);
      }
   }, [userdetails, loginUserData]);

   const removeProfilePic = async () => {
         setIsModalShow(false)
         //if (window.confirm("Do you want to remove this file?")) {
         try {
            const formData = new FormData();
            formData.append('field_value', '');
            formData.append('uploaded_for', 'profilepic');
            formData.append('userid', userdetails.unique_id);
            const response = await axios.post(
               `https://squadgoo.com.au/api.squadgoo.com.au/profile_field_update`,
               formData, { headers: { 'Content-Type': 'multipart/form-data' } });
            if (response.data.status == 1) {
               toast.success('Removed Successfully!');
               setProfilePicUrl(''); 
               //setShowModal(false); 
               setCapturedImage('/front/images/user.svg');
            } else { toast.warning(response.data.message); }
         } catch (error) { console.error('Error uploading file:', error); }
      //}
   }
   const [profileStatusCheck, setProfileStatusCheck] = useState(profileStatus);
   const onProfileStatusChange = (event) => {
      setProfileStatusCheck(0);
      let checkvalue = 0;
      if (event.target.checked == true) {
         checkvalue = 1;
         setProfileStatusCheck(1);
      }
      const formData = new FormData();
      formData.append('field_value', checkvalue);
      formData.append('uploaded_for', 'profile_enabled');
      formData.append('userid', userdetails.unique_id);
      axios.post('https://squadgoo.com.au/api.squadgoo.com.au/profile_field_update', formData)
         .then((response) => {
            if (response.data.status == 1) {
               localStorage.setItem('profileStatus', checkvalue);
               //toast.success(`Profile ${checkvalue == 1 ? 'enabled' : 'disabled'} successfully!`);
               if (userdetails.user_type == 2) {
                  toast.success(`${checkvalue == 1 ? 'Your profile is currently active to find right candidates.' : 'Your profile is currently inactive to find right candidates.'}`);
               } else {
                  toast.success(`${checkvalue == 1 ? 'Now your profile is active to receive offers from recruiters.' : 'Your profile is currently inactive for receiving offers from recruiters.'}`);
               }

            }
         })
         .catch((error) => { console.error(error); });
   }


   /*modela hid enad seek*/
   const [showModal, setShowModal] = useState(false);
   const handleShowModal = () => { setShowModal(true); cameraOff() };
   const handleHideModal = () => { setShowModal(false); cameraOff(); setTakePicFromCam(false); };



   /* images capture */
   const videoRef = useRef(null);
   const [capturedImage, setCapturedImage] = useState(profilePicUrl ? 'https://squadgoo.com.au/api.squadgoo.com.au/' + profilePicUrl : '/front/images/user.svg');
   const [stream, setStream] = useState(null);
   const [takePicFromCam, setTakePicFromCam] = useState(false);
   const cameraOff = () => { if (stream) { const tracks = stream.getTracks(); tracks.forEach((track) => track.stop()); setStream(null); }; };

   const startCamera = async () => {
      setCapturedImage('')
      try {
         const stream = await navigator.mediaDevices.getUserMedia({ video: true });
         if (videoRef.current) { videoRef.current.srcObject = stream; setStream(stream); }
      } catch (error) { console.error('Error accessing camera:', error); }
   };

   const captureImage = () => {
      const canvas = document.createElement('canvas');
      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;
      const context = canvas.getContext('2d');
      context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
      const dataURL = canvas.toDataURL('image/png');
      setCapturedImage(dataURL);
      cameraOff(); setTakePicFromCam(true);
   };


   const stopCamera = () => {
      if (stream) {
         cameraOff()
         setCapturedImage(profilePicUrl ? 'https://squadgoo.com.au/api.squadgoo.com.au/' + profilePicUrl : '/front/images/user.svg')
      }
   };

   const backProfilePic = () => {
      cameraOff()
      setTakePicFromCam(false);
      setCapturedImage(profilePicUrl ? 'https://squadgoo.com.au/api.squadgoo.com.au/' + profilePicUrl : '/front/images/user.svg')
   }


   const uploadImage = async () => {
      // Upload the image to the server using a POST request
      try {
         const formData = new FormData();
         formData.append('file', capturedImage);
         formData.append('uploaded_for', 'profilepic');
         formData.append('userid', userdetails.unique_id);
         const response = await axios.post(`https://squadgoo.com.au/api.squadgoo.com.au/profile_pic_upload_bycamra`,
            formData, { headers: { 'Content-Type': 'multipart/form-data' } });
         console.log('File uploaded successfully:', response.data);
         if (response.data.status == 1) {
            toast.success('Uploaded Successfully!');
            //setProfilePicUrl(response.data.data.fullurl); setProfilePicUrlDefault(false);
            localStorage.setItem('capturedProfilePhotoSetting', response.data.data);
            setProfilePicUrl(response.data.data);
            setCapturedImage(response.data.data);
            setShowModal(false);
         } else { toast.warning(response.data.message); }
      } catch (error) { console.error('Error uploading file:', error); }
   };

   /*-------------upload image by system-------------*/
   const [uplaodingImg, setUplaodingImg] = useState(false)
   const profileUploadToServer = async (e) => {
      //setProfilePicUrl(URL.createObjectURL(e.target.files[0])); 
      // fileUploadToServer('profilepic', e.target.files[0]);
      const file = e.target.files[0];
      const maxSizeInBytes = 5 * 1024 * 1024; // For example, 1 MB
      if (file.size > maxSizeInBytes) {
         console.log("File size is greater than the specified limit.");
         toast.warning('File size is greater than the specified limit.');
      } else {
         const userid = userdetails.unique_id;
         try {
            setUplaodingImg(true);
            const formData = new FormData();
            formData.append('file', file);
            formData.append('uploaded_for', 'profilepic');
            formData.append('userid', userid);
            const response = await axios.post(`https://squadgoo.com.au/api.squadgoo.com.au/profile_pic_upload`,
            formData, { headers: { 'Content-Type': 'multipart/form-data' } });
            console.log('File uploaded successfully:', response.data);
            if (response.data.status == 1) {
               // toast.success('Uploaded Successfully!');
               localStorage.setItem('capturedProfilePhotoSetting', response.data.data.url);
               setProfilePicUrl(response.data.data.url);
               setCapturedImage(response.data.data.fullurl);
               setUplaodingImg(false);
            } else { toast.warning(response.data.message); setUplaodingImg(false); }
         } catch (error) { console.error('Error uploading file:', error); }
      }
   }

   const StarRating = ({ rating }) => {
      const stars = [1, 2, 3, 4, 5];
      return (
         <>
            {
               stars.map((star) => (
                  star <= rating
                     ?
                     <><div className="star"><img src="/front/images/fillstar.svg" /></div></>
                     :
                     <><div className="star"><img src="/front/images/starredopen.png" /></div></>

               ))
            }
         </>
      )
   }


   /*-----------------------------------------------*/
   const [imageSrc, setImageSrc] = useState(null);
   const [crop, setCrop] = useState({ x: 0, y: 0 });
   const [zoom, setZoom] = useState(1);
   const [showModalCrop, setShowModalCrop] = useState(false);
   const [croppedImage, setCroppedImage] = useState(null);

   const handleCropFileChange = (event) => {
      const file = event.target.files[0];
      if (file) {
         const reader = new FileReader();
         reader.onload = (e) => {  setImageSrc(e.target.result); setShowModalCrop(true); };
         reader.readAsDataURL(file);
      }
   };

   const onCropComplete = async (croppedArea, croppedAreaPixels) => {

      try {
         const croppedData = await getCroppedImg(imageSrc, croppedAreaPixels);
         const reader = new FileReader();
         reader.onloadend = () => {  setCapturedImage(reader.result); };
         if (croppedData.blob) {reader.readAsDataURL(croppedData.blob); }
         setCroppedImage(croppedData.fileUrl); 
      } catch (error) { console.error('Error during cropping:', error); }
   };

   const handleSave = () => {
      if (croppedImage) {
         // Do something with the cropped image
         uploadImage();
         setProfilePicUrl(croppedImage);
         console.log('Cropped image to upload:', croppedImage);
      }
      setShowModalCrop(false); // Close the modal after saving
   }; 

   return (
      <>
         {/* <ToastContainer />*/}
         <div className="profilebanner">
            <div className="container">
               <div className="row">
                  <div className="col-md-12">
                     <h2>Profile</h2>
                     <div className="row">
                        <div className="col-md-6">
                           <div className="profiletopsec d-flex">
                              <div className="profiletopleft">
                                 <div className="profileimgsec">
                                    <img src={profilePicUrl ? 'https://squadgoo.com.au/api.squadgoo.com.au/' + profilePicUrl : '/front/images/user.svg'} />
                                    <div className="profilecamera" onClick={handleShowModal} >
                                       <img src="/front/images/camera.png" onClick={handleShowModal} />
                                    </div>
                                 </div>
                              </div>
                              <div className="profiletopright">
                                 <h3 className="m-0 d-flex text-capitalize">
                                    {(userdataredux.first_name ?? '----') + ' ' + (userdataredux.last_name ?? '-----')}
                                    
                                    {
                                       isUserVerified == true ?
                                          <><img className='' src="/front/images/varified.png" data-toggle="tooltip" data-placement="top" title="verified"></img></> :
                                          isUserVerified == false ?
                                             <><img className='' src="/front/images/unvarifiedpng.jpg" data-toggle="tooltip" data-placement="top" title="Your Profile is in Review"></img></> :
                                             <></>
                                    }
                                 </h3>
                                 <h3 className='mb-1'>
                                 (#{userdetails.unique_id})
                                 </h3>
                                 <p className="d-flex mt-1">
                                    <img src="/front/images/mail.svg" />
                                    {(userdetails.email ?? '----')}
                                    {
                                    userdetails.validationdata_check.basicemail_validation == 0 ? 
                                    <><NavLink to={userdetails.user_type == 3  ? '/jobseeker/contact-details' : '/recruiter/contact-detail'} className='text-danger text-capitalize ml-2'>(unverified)</NavLink></> 
                                    : ''
                                    }
                                 </p>
                                 <p className="d-flex mt-1">
                                    <img src="/front/images/phone.png" />
                                    {(userdataredux.contact_number ? `+${userdataredux.contact_country_code} ${userdataredux.contact_number}` : '000 000 0000')}
                                    {
                                       setIsDataLoading == true ? '' :
                                          (isUserContactVerified == 0) ?
                                             <><NavLink to={userdetails.user_type == 3  ? '/jobseeker/contact-details' : '/recruiter/contact-detail'} className='text-danger text-capitalize ml-2'>(unverified)</NavLink></> :
                                             ''
                                    }
                                 </p>


                              </div>
                           </div>
                        </div>
                        <div className="col-md-6 text-right">
                           <div className="reting d-flex">
                              <div className="stars d-flex">
                                 <StarRating rating={(typeof (totalRating) != 'undefined') ? totalRating : 0} />
                              </div>
                              <p>
                                 {/* <Link className='m-0 cursor_pointer' to={`/reviews?uniqueid=${userdetails.unique_id}`} > */}
                                 <Link className='m-0 cursor_pointer' to={`/reviews`} >
                                    {(typeof (totalRating) != 'undefined' && totalRating != null) ? totalAvgRating : 0} |
                                    <span className='ml-2' >{totalRating} Reviews</span>
                                 </Link>
                              </p>
                           </div>
                           {
                              <div className="disableprofilewrap" data-toggle="tooltip" data-placement="top" title={isUserVerified == true ? 'Your profile is in Review' : 'Please complete the profile to Activate your profile.'}>
                                 {profileStatusCheck ? 'Profile Status' : 'Profile Status'}

                                 <div className="disableprofilewrapinner">
                                    {
                                       isUserVerified == true ?
                                          <><input type="checkbox" onChange={onProfileStatusChange} checked={profileStatusCheck == 1 ? true : false} /><span className="slider round"></span></>
                                          :
                                          <><input type="checkbox" checked={false} /><span className="slider round disable_slider"></span></>
                                    }
                                 </div>
                              </div>
                           }

                        </div>
                     </div>
                  </div>
               </div>
            </div>
            {userdataredux.profile_summary && <div className='container'>
               <div className='col-md-12'>
                  <p className="mt-3 text-whight"><strong className="whiteclr">Biograph/Bio :</strong> {userdataredux.profile_summary}</p>
               </div>
            </div>}
         </div>



         <Modal show={showModal} onHide={handleHideModal}>
            <div className="modal-dialog1 findastaffmodle">
               <div className="modal-content">
                  <div className="modal-body">
                     <button type="button" className="btn-close" onClick={handleHideModal} data-bs-dismiss="modal" aria-label="Close"></button>
                     {
                        capturedImage ?
                           <>
                               <input className="profilephoto_input" type="file" accept="image/jpeg, image/png" onChange={handleCropFileChange} />
                              <img src={capturedImage} alt="Captured" className='captured_image' /> 
                              <div className="form-group mb-2  text-center mt-2">

                                 {
                                    takePicFromCam ?
                                       <>
                                          <button className='batn batn-orange mr-2  text-capitalize' onClick={startCamera}>Re-Capture</button>
                                          <button className='batn batn-orange  mr-2 ' onClick={uploadImage}>Set as Profile</button>
                                          <button className='batn batn-orange  mr-2 ' onClick={() => setIsModalShow(true)}>Remove Profile</button>
                                          <button className='batn batn-orange ' onClick={backProfilePic}>Back</button>
                                       </>
                                       :
                                       <>
                                          <button className='batn batn-orange mr-2  text-capitalize' onClick={startCamera}>Start Camera</button>
                                          <div className='systemupload mr-2'>
                                             {/* <input type='file' accept="image/x-png,image/gif,image/jpeg ,application/pdf" id="profilepic" onChange={profileUploadToServer} ></input> */}
                                             <input id="profilepic" type="file" accept="image/jpeg, image/png" onChange={handleCropFileChange} />
                                             <a className='batn batn-orange ' >{uplaodingImg ? 'Uploading....' : 'Upload From System'}  </a>
                                          </div>
                                          <button className='batn batn-orange ' onClick={() => setIsModalShow(true) }>Remove Profile</button>
                                       </>
                                 }


                                 {/* <button className='batn batn-orange ' onClick={uploadImage}>Set us Profile Image</button> */}
                              </div>
                           </>
                           :
                           <>
                              <video className='captured_image' ref={videoRef} width="400" height="400" autoPlay />
                              <div className="form-group mb-2 text-center mt-2">
                                 <button className='batn batn-orange mr-2  text-capitalize' onClick={captureImage}>Take a picture</button>
                                 <button className='batn batn-orange  text-capitalize  mr-2 ' onClick={stopCamera}>Stop Camra</button>

                                 <button className='batn batn-orange ' onClick={backProfilePic}>Back</button>
                              </div>
                           </>
                     }
                  </div>
               </div>
            </div>
         </Modal>

         
         <Modal className="modal fade ask-jobdeclinedmodle" style={{marginTop:'8%'}} show={isModalShow} onHide={() => setIsModalShow(false)} >
            <div className="modal-dialog1">
               <div className="modal-content">
                  <div className="modal-body">
                     <button type="button" className="btn-close" onClick={() => setIsModalShow(false)} ></button>
                     <h5 className="text-center mb-4">Do you want to remove this profile picture?</h5>
                     <div className="btnsgroup text-center">
                        <Link onClick={removeProfilePic} className="batn batn-orange" >Yes</Link>
                        <Link  onClick={() => setIsModalShow(false)}  className="batn batn-orange batn-border">No</Link>
                     </div>
                  </div>
               </div>
            </div>
         </Modal>


         <Modal show={showModalCrop} onHide={() => setShowModalCrop(false)}>
               <Modal.Body>
                  <button onClick={() => setShowModalCrop(false)} type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  <div style={{ position: 'relative', width: '100%', height: '300px' }}>
                     <Cropper
                        image={imageSrc}
                        crop={crop}
                        zoom={zoom}
                        aspect={1} // Makes the crop area square
                        onCropChange={setCrop}
                        onZoomChange={setZoom}
                        onCropComplete={onCropComplete}
                        cropShape="round" // Set the cropping area to a circle
                     />
                  </div>
                  <input
                     type="range"
                     min="1"
                     max="3"
                     step="0.1"
                     value={zoom}
                     className='width-100percent mt-2'
                     onChange={(e) => setZoom(Number(e.target.value))}
                  />
               </Modal.Body>
               <div className='form-group mb-2  text-center mt-2'>

                  <Button className='batn batn-orange mr-2  text-capitalize' variant="secondary" onClick={() => setShowModalCrop(false)}>
                     Cancel
                  </Button>
                  <Button className='batn batn-orange mr-2  text-capitalize' variant="primary" onClick={handleSave}>
                     Save
                  </Button>
               </div>
            </Modal>

      </>
   );
}
export default ProfileBanner;