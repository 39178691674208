import React, { useState, useEffect, Component } from 'react';
import { useDispatch } from 'react-redux'
import WorkExpComponent from '../WorkExpComp';
import CheckValidationComp  from '../CheckValidationComp';
const WorkExperience = () => {
   //const auth = localStorage.getItem('squadGologin');
   const userdetails = JSON.parse(localStorage.getItem("squadGologin"));
  
   useEffect(() => {
      if (!userdetails) {
         setTimeout(function () { window.location.replace("/login"); }, 100)
      } 
   }, []);

   return (
      <>
        
         <div className="dashboarwrap">
            <div className="pagebanner">
               <div className="container">
                  <div className="row">
                     <div className="col-md-12">
                        <h2>Work experience</h2>
                     </div>
                  </div>
               </div>
            </div>
           
            <div className="jobbtmsec py-5">
               <div className="container">
                  <div className="row">
                  <WorkExpComponent />
                  </div>
               </div>
            </div>
         </div>
      </>
   );
}
export default WorkExperience;