import React, { useEffect,useState } from 'react';
import { Link } from 'react-router-dom';

const AboutUs = () => {
   const useriddata = JSON.parse(localStorage.getItem("squadGologinSession"));

   const [userTypeRoleLink , setUserTypeLink ] =  useState('/login');
   //user_type
   
   console.log(useriddata)
   useEffect(() => {
      window.scrollTo(0, 0);
      if(useriddata){
         setUserTypeLink((useriddata.user_type == 3) ? '/jobseeker/transections' : '/recruiter/transections') 
      }
   }, []); // 
   return (
      <>
         <div className="mainpages">
            <div className="banner page-banner">
               <div className="container">
                  <div className="row align-items-center">
                     <div className="col-md-6">
                        <div className="banner-left">
                           <h1>About Us</h1>
                        </div>
                     </div>
                     <div className="col-md-6">
                        <img src="/front/images/aboutbannerimgg.png"></img>
                     </div>
                  </div>
               </div>
            </div>


            <div className="countersec">
               <div className="container">
                  <div className="row align-items-center">
                     <div className="col-md-12">
                        <div className="counerinner d-flex">
                           <div className="countercol">
                              <h2>500+</h2>
                              <h4>Ready Job Vacancy</h4>
                           </div>
                           <div className="countercol">
                              <h2>10K+</h2>
                              <h4>Active Job Seekers</h4>
                           </div>
                           <div className="countercol">
                              <h2>100+</h2>
                              <h4>Incorporated Companies</h4>
                           </div>
                        </div>
                     </div>

                  </div>
               </div>
            </div>


            <div className="whoweare">
               <img src="/front/images/sleft.png" className="sectionleftimg sectionabs"></img>
               <div className="container">
                  <div className="row">
                     <div className="col-md-12">
                        <div className="banner-left">
                           <h2 className="sectionhead">Who we are</h2>
                           <p>SQUAD GOO is a one place solution where we help connecting jobs and job providers. We cover every field like employees, contractor, sub-contractors, Businesses, and agencies. We only charge you once you have connection with your desire match, and our fees are super low. </p>
                           <p>SQUAD GOO is motivated to solve the connection problem in the labour market by providing everyone fair chance to improve their market. We ought to remove false ads, dormant applicants, fake reviews and resumes and references. Only fully verified users are given chance to use our services. </p>
                           <Link to={`/register`} className="batn batn-orange">Join Us</Link>
                        </div>
                     </div>
                  </div>
               </div>
               <img src="/front/images/sright.png" className="sectionrightimg sectionabs"></img>
            </div>



            <div className="aboutleftrightsec ">
               <img src="/front/images/dotsinmg.jpg" className="dotimgsec"></img>
               <div className="container">
                  <div className="row sucess flex-md-row-reverse">
                     <div className="col-md-6">
                        <img src="/front/images/sucessimg.jpg" className="img-fluid"></img>
                     </div>
                     <div className="col-md-6 aboutcon">
                        <h2 className="sectionhead">After analysing labour market, we found sole problems as mentioned below</h2>
                        <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg" /><span>Hard for Businesses to find appropriate candidates, wasting time and money during the process. Businesses often hire third party such as Recruitment agencies paying extra.</span></p>
                        <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg" /><span>Contractors or sub-contractors are wasting a lot of money on advertisement to find clients.</span></p>
                        <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg" /><span>Individuals are having hustle getting a good contractor for their home or caring.</span></p>
                        <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg" /><span>Employees are wasting their time getting a reliable and filtered jobs when there are dozens of jobs posting sites they must go through.</span></p>
                        <p>We are bringing all in one solution (modern workplace) for Labour market. Our aim is to match all parties with such a minimal charges and best technology that you will love it.</p>
                     </div>
                  </div>
                  {/* <div className="row workforc">
                 <div className="col-md-6">
                   <img src="/front/images/workflow.jpg" className="img-fluid"></img>
                 </div>
                 <div className="col-md-6">
                    <h2 className="sectionhead">Squad Goo your workforce</h2>
                    <p>Morbi ac felis. Donec vitae sapien ut libero venenatis faucibus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Praesent ac massa at ligula laoreet iaculis. Aenean vulputate eleifend tellus. Fusce neque. Sed consequat, leo eget bibendum sodales, augue velit cursus nunc, quis gravida magna mi a libero. Fusce neque. Nunc nulla. Integer ante arcu, accumsan a, consectetuer eget, posuere ut, mauris.</p>
                    <p>Curabitur vestibulum aliquam leo. Sed cursus turpis vitae tortor. Etiam sit amet orci eget eros faucibus tincidunt. Praesent nec nisl a purus blandit viverra. Nam quam nunc, blandit vel, luctus pulvinar, hendrerit id, lorem. Nullam quis ante. Cras non dolor. In dui magna, posuere eget, vestibulum et, tempor auctor, justo. Nunc interdum lacus sit amet orci. Donec interdum, metus et hendrerit aliquet, dolor diam sagittis ligula, eget egestas libero turpis vel mi.</p>
                 </div>
              </div> */}
               </div>
            </div>

            <div className="faq  grayfaq">
               <div className="container">
                  <div className="mx-auto text-center">
                     <h2 className="mb-5">Features</h2>
                  </div>

                  <div className="accordion" id="accordionExample">
                     <div className="accordion-item">
                        <h2 className="accordion-header" id="fea1">
                           <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefea1" aria-expanded="true" aria-controls="collapsefea1">
                              1. VERIFIED PROFILE
                           </button>
                        </h2>
                        <div id="collapsefea1" className="accordion-collapse collapse show" aria-labelledby="fea1" data-bs-parent="#accordionExample">
                           <div className="accordion-body">
                              <p>We make sure that all profiles on our platform are scam free and legit. We run and verify, licenses, any qualifications, history of experience, reviews, references, name, address, ABN or TFN, resumes, legal checks, availability, visas (optional), phone number and email addresses of users ( COMPANY, COMPANY REPRESENTATIVES, BUSINESSES, CONTRACTORS, SUB CONTRACTORS, EMPLOYEES AND  INDIVIDUALS). </p>
                              <p>SQUAD GOO makes sure we don’t have dormant accounts luring around our platform for a long time. We double check for any scam or identity thief attempts.</p>
                           </div>
                        </div>
                     </div>
                     <div className="accordion-item">
                        <h2 className="accordion-header" id="fea2">
                           <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefea2" aria-expanded="false" aria-controls="collapsefea2">
                              2. PAY PER MATCH/NON-SUBSCRIPTION MODE
                           </button>
                        </h2>
                        <div id="collapsefea2" className="accordion-collapse collapse" aria-labelledby="fea2" data-bs-parent="#accordionExample">
                           <div className="accordion-body">
                              <p>We do not charge you on subscription model which we believe gives little room for small businesses and individuals to try any platforms. Our coin bases system will allow you to use our both MANNUAL SEARCH AND QUICK SEARCH features at the same time. You can always deposit and withdraw coins from our platform as your wish. We refund any amount you spend if the match happens to be unsuccessful even after the matching (Terms and conditions applies). </p>
                              <p>Once you have a match with other party/individuals then we will hold the coins and deducted upon successful communication. You will be able to withdraw the excess coin you deposited if you want to quite the platforms or want to pause the service.</p>
                              <p>We charge different prices for MANNUAL SEARCH and QUICK SEARCH which makes pay per match model perfect for you as you don’t have to have subscription for both features.</p>
                              <p>Please 
                                 
                                 <Link className='ml-1 mr-1' to={userTypeRoleLink}>click here (coins and purchase section)</Link> 
                              to find more about charges.</p>
                           </div>
                        </div>
                     </div>
                     <div className="accordion-item">
                        <h2 className="accordion-header" id="fea3">
                           <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefea3" aria-expanded="false" aria-controls="collapsefea3">
                              3. DATA COLLETION SAFETY/PRIVACY
                           </button>
                        </h2>
                        <div id="collapsefea3" className="accordion-collapse collapse" aria-labelledby="fea3" data-bs-parent="#accordionExample">
                           <div className="accordion-body"><p>All the details and data collected by SQUAD GOO are highly secured and only shared with your potential or already matched users with disclaimers before. You may choose not to share certain information’s to other parties through our app or website. We are aware of a lot of scammers and hackers looming around for your data’s, which we make 100% sure are not misused or targeted. Your contact, personal information’s won’t be shared to each other unless we have a match.</p></div>

                        </div>
                     </div>
                     <div className="accordion-item">
                        <h2 className="accordion-header" id="fea4">
                           <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefea4" aria-expanded="false" aria-controls="collapsefea4">
                              4. MANNUAL SEARCH
                           </button>
                        </h2>
                        <div id="collapsefea4" className="accordion-collapse collapse" aria-labelledby="fea4" data-bs-parent="#accordionExample">

                           <div className="accordion-body"><p>With an aim to provide swift hiring within few minutes, MANNUAL SEARCH provides job providers with matched profiles which can be further selected by the job providers themselves to send an offer. This feature makes sure the filtered jobseekers or contractor’s acceptance rating is respected. System makes sure that low acceptance rating profile are low visible or kicked out of the platform, so we have only genuine users on our platforms.</p></div>

                        </div>
                     </div>
                     <div className="accordion-item">
                        <h2 className="accordion-header" id="fea5">
                           <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefea5" aria-expanded="false" aria-controls="collapsefea5">
                              5. QUICK SEARCH
                           </button>
                        </h2>
                        <div id="collapsefea5" className="accordion-collapse collapse" aria-labelledby="fea5" data-bs-parent="#accordionExample">

                           <div className="accordion-body"><p>Quick search feature works completely different from Manual search with the same aim. QUICK SEARCH is here to make sure hiring processes is as easy as ordering a food through delivery drivers like. With simple information’s and activating this option, the system will automatically find match and send them directly to your workplace. It ensures that both parties can communicate, map views of each other until jobseekers meets job providers.</p>
                              <p>Job providers can always double check to whom the system send offers to, then may edit later. The coins we charge for this service will be little higher than MANNUAL SEARCH but worth it if you need staff same day 24 hours 7 days a week.</p></div>
                        </div>
                     </div>
                     <div className="accordion-item">
                        <h2 className="accordion-header" id="fea6">
                           <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefea6" aria-expanded="false" aria-controls="collapsefea6">
                              6. FULL REFUNDS POLICY
                           </button>
                        </h2>
                        <div id="collapsefea6" className="accordion-collapse collapse" aria-labelledby="fea6" data-bs-parent="#accordionExample">


                           <div className="accordion-body"><p>SQUAD GOO believes, “we don’t deserve your money until you are satisfied”. Returning your hard-earned money when our platform could not meet what it is advertised for then, you have your money back. HOWEVER, we are a business ourselves, so we go through a lot of checks to ensure someone is not false claiming refunds.</p></div>
                        </div>
                     </div>
                     <div className="accordion-item">
                        <h2 className="accordion-header" id="fea7">
                           <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefea7" aria-expanded="false" aria-controls="collapsefea7">
                              7. MATCHING POLICY
                           </button>
                        </h2>
                        <div id="collapsefea7" className="accordion-collapse collapse" aria-labelledby="fea7" data-bs-parent="#accordionExample">


                           <div className="accordion-body"><p> Depending upon which search option you took, Matching policies might work differently. However, we try matching 100% of your requirements with 100% matched jobseekers/contractors. If the system or you cannot find 100% matched profile, then system will let you know the matching percentage and show you other profile from where you can choose yourself. </p></div>
                        </div>
                     </div>
                     <div className="accordion-item">
                        <h2 className="accordion-header" id="fea8">
                           <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefea8" aria-expanded="false" aria-controls="collapsefea8">
                              8. REFERRAL BONUSES
                           </button>
                        </h2>
                        <div id="collapsefea8" className="accordion-collapse collapse" aria-labelledby="fea8" data-bs-parent="#accordionExample">


                           <div className="accordion-body">
                              <p> We want to engage more users on our platform and getting referral from already registered users is effective ways to do it. It’s not only for us but you will be earning coins as bonuses per referral you send us to our platforms. Later those bonuses coins can be withdrawal to your bank account respecting our  <Link to={'/terms-and-conditions'}>Terms and conditions</Link>.
                              </p></div>
                        </div>
                     </div>
                     <div className="accordion-item">
                        <h2 className="accordion-header" id="fea9">
                           <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefea9" aria-expanded="false" aria-controls="collapsefea9">
                              9. COMPENSATION POLICY
                           </button>
                        </h2>
                        <div id="collapsefea9" className="accordion-collapse collapse" aria-labelledby="fea9" data-bs-parent="#accordionExample">


                           <div className="accordion-body"><p> We understand that system and parties might make some error and can cause some parties waste of their time. If system finds that jobseekers time were wasted intentionally, or job providers carelessly did not work according to the term and conditions then jobseekers/ contractors will receive compensation from job providers’ account. Same way if job providers time and effort were wasted by the jobseekers, then full or partial refund might activate.</p></div>
                        </div>
                     </div>
                     <div className="accordion-item">
                        <h2 className="accordion-header" id="fea10">
                           <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefea10" aria-expanded="false" aria-controls="collapsefea10">
                              10. ONE PLACE SOLUTION
                           </button>
                        </h2>
                        <div id="collapsefea10" className="accordion-collapse collapse" aria-labelledby="fea10" data-bs-parent="#accordionExample">


                           <div className="accordion-body"><p> We are the only platform that provides connection between jobseekers, contractors, sub-contractors, individuals, businesses in a smooth way, you no longer must go back to traditional hiring platform that will drain your money and time. We are on both website and android and apple mobile application. Our platform is made so simple that you will get around soon after few times of uses. With all the advance and additional features on our platforms, we aim to remove all the possible defects of traditional hiring platforms making it trustworthy of millions of people around the globe. Once you have this website or mobile application, you no longer need to sign up somewhere else for a job or jobseekers.</p></div>
                        </div>
                     </div>
                     <div className="accordion-item">
                        <h2 className="accordion-header" id="fea11">
                           <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefea11" aria-expanded="false" aria-controls="collapsefea11">
                              11. CONTACT SUPPORT FEATURES
                           </button>
                        </h2>
                        <div id="collapsefea11" className="accordion-collapse collapse" aria-labelledby="fea11" data-bs-parent="#accordionExample">


                           <div className="accordion-body"><p> SQUAD GOO is very user friendly, and we make sure you get in touch with us as soon as possible. We have live chat, email, comments, complaints, and call options available for you to directly get in touch with us. We are making all above contact method 24/7 so you don’t have to wait for business hours to get in touch with us. You also may choose your own language while communicating with us, making sure we have smooth conversation.</p></div>
                        </div>
                     </div>
                     <div className="accordion-item">
                        <h2 className="accordion-header" id="fea12">
                           <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefea12" aria-expanded="false" aria-controls="collapsefea12">
                              12. CONVERSATION RECORDING
                           </button>
                        </h2>
                        <div id="collapsefea12" className="accordion-collapse collapse" aria-labelledby="fea12" data-bs-parent="#accordionExample">


                           <div className="accordion-body"><p> There are conversation features on our app and website between the parties after the match. Users can do video, audio, documents and photos and text with each other. However, we aim to record every conversation happening so the scamming and false parties may be caught immediately. We believe, our platform is greatly professional rather than personal, so recording all conversation gives potential victim to ask for the conversation records if needed for legal or work related or future reference.</p></div>
                        </div>
                     </div>
                     <div className="accordion-item">
                        <h2 className="accordion-header" id="fea13">
                           <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefea13" aria-expanded="false" aria-controls="collapsefea13">
                              13. QUICK VERIFICATION
                           </button>
                        </h2>
                        <div id="collapsefea13" className="accordion-collapse collapse" aria-labelledby="fea13" data-bs-parent="#accordionExample">


                           <div className="accordion-body"><p>24/7 available verification teams make sure as soon as you upload any documents or details, they are reviewed within 24 hours.</p>  </div>
                        </div>
                     </div>
                     <div className="accordion-item">
                        <h2 className="accordion-header" id="fea14">
                           <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefea14" aria-expanded="false" aria-controls="collapsefea14">
                              14. WORLDWIDE USE CASE
                           </button>
                        </h2>
                        <div id="collapsefea14" className="accordion-collapse collapse" aria-labelledby="fea14" data-bs-parent="#accordionExample">


                           <div className="accordion-body"><p> SQUAD GOO aims to expand world-wide. This will give flexibility to users that wants to work in same or different countries.</p></div>
                        </div>
                     </div>
                     <div className="accordion-item">
                        <h2 className="accordion-header" id="fea15">
                           <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefea15" aria-expanded="false" aria-controls="collapsefea15">
                              15. ACCOUNT SAFETY
                           </button>
                        </h2>
                        <div id="collapsefea15" className="accordion-collapse collapse" aria-labelledby="fea15" data-bs-parent="#accordionExample">


                           <div className="accordion-body"><p> TWO factor authentication and special technology aims to reduce your account from being misused by unknown parties.</p></div>
                        </div>
                     </div>
                     <div className="accordion-item">
                        <h2 className="accordion-header" id="fea16">
                           <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefea16" aria-expanded="false" aria-controls="collapsefea16">
                              16. LIVE MAP FUNCTIONS
                           </button>
                        </h2>
                        <div id="collapsefea16" className="accordion-collapse collapse" aria-labelledby="fea16" data-bs-parent="#accordionExample">


                           <div className="accordion-body"><p> Matched parties in QUICK SEARCH can see each other’s location and know when they are going to meet once jobseekers start heading to work.</p></div>
                        </div>
                     </div>
                     <div className="accordion-item">
                        <h2 className="accordion-header" id="fea16">
                           <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefea16" aria-expanded="false" aria-controls="collapsefea16">
                              17. MULTIPLE ROLE FUNCTION
                           </button>
                        </h2>
                        <div id="collapsefea16" className="accordion-collapse collapse" aria-labelledby="fea16" data-bs-parent="#accordionExample">


                           <div className="accordion-body"><p> A contractor can use our platform as jobseekers from businesses and individuals whereas, they can also pose as job providers. These multi role function ensures you don’t have to go through traditional platforms. We are all in one.</p></div>
                        </div>
                     </div>
                     <div className="accordion-item">
                        <h2 className="accordion-header" id="fea18">
                           <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefea18" aria-expanded="false" aria-controls="collapsefea18">
                              18. REVIEWS
                           </button>
                        </h2>
                        <div id="collapsefea18" className="accordion-collapse collapse" aria-labelledby="fea18" data-bs-parent="#accordionExample">
                           <div className="accordion-body"><p>All users have reviews function available once they have a match or already had match before. This ensures all parties can decide themselves weather a user is good enough for your match. HOWEVER, reviews won’t affect your visibility on our platform. You can always report the reviews you think are irrelevant or are misused reviews.</p></div>


                        </div>
                     </div>
                  </div>

               </div>
            </div>



            {/* 

        <div className="whoweare">
          <img src="/front/images/sleft.png" className="sectionleftimg sectionabs"></img>
          <div className="container">
              <div className="row">
                 <div className="col-md-12">
                    <div className="banner">
                        <h2 className="sectionhead">Features</h2>
                    <p id="sec1"><strong>1. VERIFIED PROFILES</strong></p>
<p>WE make sure that all profiles on our platform are scam free and legit. We run and verify, licenses, any qualifications, history of experience, reviews, references, name, address, ABN or TFN, resumes, legal checks, availability, visas (optional), phone number and email addresses of users ( COMPANY, COMPANY REPRESENTATIVES, BUSINESSES, CONTRACTORS, SUB CONTRACTORS, EMPLOYEES AND  INDIVIDUALS). </p>
<p>SQUAD GOO makes sure we don’t have dormant accounts luring around our platform for a long time. We double check for any scam or identity thief attempts.</p> 
<p id="sec2"><strong>2. PAY PER MATCH/NON-SUBSCRIPTION MODEL</strong></p>
<p>We do not charge you on subscription model which we believe gives little room for small businesses and individuals to try any platforms. Our coin bases system will allow you to use our both MANNUAL SEARCH AND QUICK SEARCH features at the same time. You can always deposit and withdraw coins from our platform as your wish. We refund any amount you spend if the match happens to be unsuccessful even after the matching (Terms and conditions applies). </p>
<p>Once you have a match with other party/individuals then we will hold the coins and deducted upon successful communication. You will be able to withdraw the excess coin you deposited if you want to quite the platforms or want to pause the service.</p>  
<p>We charge different prices for MANNUAL SEARCH and QUICK SEARCH which makes pay per match model perfect for you as you don’t have to have subscription for both features.</p>  
<p>Please <a href="#">click here (coins and purchase section)</a> to find more about charges.</p>
<p id="sec3"><strong>DATA COLLETION SAFETY/PRIVACY</strong></p>
<p>All the details and data collected by SQUAD GOO are highly secured and only shared with your potential or already matched users with disclaimers before. You may choose not to share certain information’s to other parties through our app or website. We are aware of a lot of scammers and hackers looming around for your data’s, which we make 100% sure are not misused or targeted. Your contact, personal information’s won’t be shared to each other unless we have a match.</p>

<p id="sec4"><strong>MANNUAL SEARCH</strong></p>
<p>With an aim to provide swift hiring within few minutes, MANNUAL SEARCH provides job providers with matched profiles which can be further selected by the job providers themselves to send an offer. This feature makes sure the filtered jobseekers or contractor’s acceptance rating is respected. System makes sure that low acceptance rating profile are low visible or kicked out of the platform, so we have only genuine users on our platforms.</p>


<p id="sec5"><strong>QUICK SEARCH</strong></p> 
<p>Quick search feature works completely different from Manual search with the same aim. QUICK SEARCH is here to make sure hiring processes is as easy as ordering a food through delivery drivers like. With simple information’s and activating this option, the system will automatically find match and send them directly to your workplace. It ensures that both parties can communicate, map views of each other until jobseekers meets job providers.</p>

<p>Job providers can always double check to whom the system send offers to, then may edit later. The coins we charge for this service will be little higher than MANNUAL SEARCH but worth it if you need staff same day 24 hours 7 days a week.</p>

<p id="sec6"><strong>FULL REFUNDS POLICY</strong></p> 
<p>SQUAD GOO believes, “we don’t deserve your money until you are satisfied”. Returning your hard-earned money when our platform could not meet what it is advertised for then, you have your money back. HOWEVER, we are a business ourselves, so we go through a lot of checks to ensure someone is not false claiming refunds.</p>

<p id="sec7"><strong>MATCHING POLICY</strong></p> 
<p> Depending upon which search option you took, Matching policies might work differently. However, we try matching 100% of your requirements with 100% matched jobseekers/contractors. If the system or you cannot find 100% matched profile, then system will let you know the matching percentage and show you other profile from where you can choose yourself. </p>

<p id="sec8"><strong>REFERRAL BONUSES</strong></p> 
<p> We want to engage more users on our platform and getting referral from already registered users is effective ways to do it. It’s not only for us but you will be earning coins as bonuses per referral you send us to our platforms. Later those bonuses coins can be withdrawal to your bank account respecting our Terms and conditions.</p>

<p id="sec9"><strong>COMPENSATION POLICY</strong></p> 
<p> We understand that system and parties might make some error and can cause some parties waste of their time. If system finds that jobseekers time were wasted intentionally, or job providers carelessly did not work according to the term and conditions then jobseekers/ contractors will receive compensation from job providers’ account. Same way if job providers time and effort were wasted by the jobseekers, then full or partial refund might activate.</p>

<p id="sec10"><strong>ONE PLACE SOLUTION</strong></p> 
<p> We are the only platform that provides connection between jobseekers, contractors, sub-contractors, individuals, businesses in a smooth way, you no longer must go back to traditional hiring platform that will drain your money and time. We are on both website and android and apple mobile application. Our platform is made so simple that you will get around soon after few times of uses. With all the advance and additional features on our platforms, we aim to remove all the possible defects of traditional hiring platforms making it trustworthy of millions of people around the globe. Once you have this website or mobile application, you no longer need to sign up somewhere else for a job or jobseekers.</p>

<p id="sec11"><strong>CONTACT SUPPORT FEATURES</strong></p> 
<p> SQUAD GOO is very user friendly, and we make sure you get in touch with us as soon as possible. We have live chat, email, comments, complaints, and call options available for you to directly get in touch with us. We are making all above contact method 24/7 so you don’t have to wait for business hours to get in touch with us. You also may choose your own language while communicating with us, making sure we have smooth conversation.</p>

<p id="sec12"><strong>CONVERSATION RECORDING</strong></p> 
<p> There are conversation features on our app and website between the parties after the match. Users can do video, audio, documents and photos and text with each other. However, we aim to record every conversation happening so the scamming and false parties may be caught immediately. We believe, our platform is greatly professional rather than personal, so recording all conversation gives potential victim to ask for the conversation records if needed for legal or work related or future reference.</p>

<p id="sec13"><strong>QUICK VERIFICATION</strong></p> 
<p>24/7 available verification teams make sure as soon as you upload any documents or details, they are reviewed within 24 hours.</p>  

<p id="sec14"><strong>WORLDWIDE USE CASE</strong></p> 
<p> SQUAD GOO aims to expand world-wide. This will give flexibility to users that wants to work in same or different countries.</p>

<p id="sec15"><strong>ACCOUNT SAFETY</strong></p> 
<p> TWO factor authentication and special technology aims to reduce your account from being misused by unknown parties.</p>

<p id="sec16"><strong>LIVE MAP FUNCTIONS</strong></p> 
<p> Matched parties in QUICK SEARCH can see each other’s location and know when they are going to meet once jobseekers start heading to work.</p>

<p id="sec17"><strong>MULTIPLE ROLE FUNCTION</strong></p> 
<p> A contractor can use our platform as jobseekers from businesses and individuals whereas, they can also pose as job providers. These multi role function ensures you don’t have to go through traditional platforms. We are all in one.</p>

<p id="sec18"><strong>REVIEWS</strong></p> 
<p> All users have reviews function available once they have a match or already had match before. This ensures all parties can decide themselves weather a user is good enough for your match. HOWEVER, reviews won’t affect your visibility on our platform. You can always report the reviews you think are irrelevant or are misused reviews.</p>
                    
                    </div>
                 </div>
              </div>

             
          </div>
          <img src="/front/images/sright.png" className="sectionrightimg sectionabs"></img>
        </div>
             */}


            {/* <div className="teamsec">
         <div className="container">
           <h2 className="sectionhead">Meet our team</h2>
            <div className="row">
                <div className="col-lg-3 col-md-6">
                   <div className="teammenber">
                      <div className="teamtop">
                         <img src="/front/images/teamimg.jpg" className="img-fluid"></img>
                        
                        <div className="teamoverlay align-items-center d-flex justify-content-center">
                            <a href="#" className="batn batn-orange">Know More</a>
                        </div>
                      </div>
                      <div className="teabsocialmedea d-flex justify-content-center">
                             <a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a>
                             <a href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a>
                             <a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a>
                        </div>
                      <div className="teambtm text-center">
                          <h3>John Deo</h3>
                          <p>Chief Executive Officer</p> 
                      </div>
                   </div>
                </div>

                <div className="col-lg-3 col-md-6">
                   <div className="teammenber">
                      <div className="teamtop">
                         <img src="/front/images/teamimg.jpg" className="img-fluid"></img>
                        
                        <div className="teamoverlay align-items-center d-flex justify-content-center">
                            <a href="#" className="batn batn-orange">Know More</a>
                        </div>
                      </div>
                      <div className="teabsocialmedea d-flex justify-content-center">
                             <a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a>
                             <a href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a>
                             <a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a>
                        </div>
                      <div className="teambtm text-center">
                          <h3>John Deo</h3>
                          <p>Chief Executive Officer</p> 
                      </div>
                   </div>
                </div>

                <div className="col-lg-3 col-md-6">
                   <div className="teammenber">
                      <div className="teamtop">
                         <img src="/front/images/teamimg.jpg" className="img-fluid"></img>
                        
                        <div className="teamoverlay align-items-center d-flex justify-content-center">
                            <a href="#" className="batn batn-orange">Know More</a>
                        </div>
                      </div>
                      <div className="teabsocialmedea d-flex justify-content-center">
                             <a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a>
                             <a href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a>
                             <a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a>
                        </div>
                      <div className="teambtm text-center">
                          <h3>John Deo</h3>
                          <p>Chief Executive Officer</p> 
                      </div>
                   </div>
                </div>

                <div className="col-lg-3 col-md-6">
                   <div className="teammenber">
                      <div className="teamtop">
                         <img src="/front/images/teamimg.jpg" className="img-fluid"></img>
                        
                        <div className="teamoverlay align-items-center d-flex justify-content-center">
                            <a href="#" className="batn batn-orange">Know More</a>
                        </div>
                      </div>
                      <div className="teabsocialmedea d-flex justify-content-center">
                             <a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a>
                             <a href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a>
                             <a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a>
                        </div>
                      <div className="teambtm text-center">
                          <h3>John Deo</h3>
                          <p>Chief Executive Office</p>
                      </div>
                   </div>
                </div>
            </div>

            <div className="text-center"><a href="#" className="batn batn-orange">Join Our Team</a></div>
         </div>
      </div> */}




         </div>

      </>
   );
}
export default AboutUs;