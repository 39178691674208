import React, { useState, useEffect, Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';
import ChatHistoryComp from '../supcomp/ChatHistoryComp';
const UserChatHistory = () => {

    const [userID, setuserID] = useState(0);
    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        const userid = queryParameters.get("id");
        setuserID(userid)
    }, []);


    return (
        <>

            {
                (userID == 0) ?
                    <p className='text-center mt-10'><img className='width-100' src={'/front/images/loader.gif'} /></p>
                    :
                    <>
                        <ChatHistoryComp attr={userID} />
                    </>
            }
        </>
    );
}
export default UserChatHistory;