import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
//import SIDEBAR_MENU_CONST from './../../json/sidebar_menu_option.json';
const SuperAdminSidebar = () => {
    let location = useLocation();
    const userdetails = JSON.parse(localStorage.getItem("squadGologinSession"));
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const selectOption = (option) => {
        setSelectedOption(option);
        setIsOpen(false);
    };



    return (
        <>
            <div className="adminmenu box mb-4">
                {/* <ul>
                {
                    SIDEBAR_MENU_CONST.map((item , index) => (

                        item.child.length ?  
                        <li  key={item.key} onClick={() => handleParentClick(item.key)} className="dropdown" >
                            <Link  key={item.key} className="dropdown-toggle" type="button"  data-bs-toggle="dropdown" aria-expanded={(ischildMenuCall == true  &&  currentParentActiveIndex == item.key) ?  true : false}>{item.name}</Link>
                            <ul className={`dropdown-menu  ${(ischildMenuCall == true  &&  currentParentActiveIndex == item.key) ? 'show' : ''}`}  aria-labelledby="settingdrop">
                            <ChildMenu list={item.child} />
                            </ul>
                        </li> 
                        :   
                        <li key={item.key} ><Link  onClick={() => {locationPathActive(item.url); setischildMenuCall(false)}} className={location.pathname === '/'+item.url ? 'link active' : 'link'} key={item.key} to={`/${item.url}`}>{item.name}</Link></li> 
                       
                    ))
                }   
            </ul> */}
                {/*  {module.name.charAt(0).toUpperCase() + module.name.slice(1)} */}
                {/* userdetails.accessibility */}
                {
                   // console.log(userdetails.accessibility)
                    // console.log(userdetails.accessibility.dashboard)
                }
                {
                    userdetails.user_type == 4
                        ?
                        <>
                            <ul>
                                {
                                    userdetails.accessibility.dashboard && <li><Link className={`link  ${location.pathname === '/admin-dashboard' ? 'active' : ''}`} to="/admin-dashboard">Dashboard</Link></li>
                                }


                                {
                                    userdetails.accessibility.jobs_offers
                                    && userdetails.accessibility.jobs_offers.view && <li><Link className={`link  ${location.pathname === '/offers' ? 'active' : ''}`} to="/offers">Job offers</Link></li>
                                }

                                {
                                    userdetails.accessibility.jobseekers
                                    && userdetails.accessibility.jobseekers.view && <li><Link className={`link  ${(location.pathname === '/jobseekers' || location.pathname === '/jobseeker-detail') ? 'active' : ''}`} to="/jobseekers">Jobseekers</Link></li>
                                }
                                {
                                    userdetails.accessibility.recruiters
                                    && userdetails.accessibility.recruiters.view && <li><Link className={`link  ${(location.pathname === '/recruiters' || location.pathname === '/recruiter-detail') ? 'active' : ''}`} to="/recruiters">Recruiters</Link></li>
                                }


                                {
                                    userdetails.accessibility.reviews
                                    && userdetails.accessibility.reviews.view && <li><Link className={`link  ${location.pathname === '/admin_all_reviews' ? 'active' : ''}`} to="/admin_all_reviews">Reviews</Link></li>
                                }

                                {
                                    userdetails.accessibility.chat_history
                                    && userdetails.accessibility.chat_history.view && <li><Link className={`link  ${location.pathname === '/chat-history' ? 'active' : ''}`} to="/chat-history">Chat history</Link></li>
                                }

                                {
                                    userdetails.accessibility.support
                                    && userdetails.accessibility.support.view && <li className="dropdown">
                                        <a
                                            className={`dropdown-toggle ${(location.pathname == '/admin-ticket-history' || location.pathname == '/admin-livechat-history' || location.pathname == '/admin-call-history') ? 'show' : ''}`}
                                            type="button" data-bs-toggle="dropdown"
                                            aria-expanded={(location.pathname == '/admin-ticket-history' || location.pathname == '/admin-livechat-history' || location.pathname == '/admin-call-history') ? 'true' : 'false'}
                                            to="/admin_all_reviews">Support
                                        </a>
                                        <ul className={`dropdown-menu ${(location.pathname == '/admin-ticket-history' || location.pathname == '/admin-livechat-history' || location.pathname == '/admin-call-history') ? 'show' : ''}`} aria-labelledby="settingdrop">
                                            <li><Link className={`link  ${location.pathname === '/admin-ticket-history' ? 'active' : ''}`} to="/admin-ticket-history">Tickets</Link></li>
                                            <li><Link className={`link  ${location.pathname === '/admin-livechat-history' ? 'active' : ''}`} to="/admin-livechat-history">Live chats</Link></li>
                                            <li><Link className={`link  ${location.pathname === '/admin-call-history' ? 'active' : ''}`} to="/admin-call-history">Requested calls</Link></li>
                                        </ul>
                                    </li>
                                }

                                {
                                    userdetails.accessibility.settings
                                    && userdetails.accessibility.settings.view && <li className="dropdown">
                                        {/* <a className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" to="/admin_all_reviews">Settings</a> */}
                                        <a
                                            className={`dropdown-toggle ${(location.pathname == '/admin-wallet-setting' ||  location.pathname == '/account-setting' || location.pathname == '/website_cms' || location.pathname == '/cms_faq' || location.pathname == '/cms_options_settings') ? 'show' : ''}`}
                                            type="button" data-bs-toggle="dropdown"
                                            aria-expanded={(location.pathname == '/account-setting' || location.pathname == '/website_cms' || location.pathname == '/cms_faq' || location.pathname == '/cms_options_settings') ? 'true' : 'false'}
                                            to="/admin_all_reviews">Settings
                                        </a>
                                        <ul className={`dropdown-menu ${(location.pathname == '/admin-wallet-setting' || location.pathname == '/account-setting' || location.pathname == '/website_cms' || location.pathname == '/cms_faq' || location.pathname == '/cms_options_settings') ? 'show' : ''}`} aria-labelledby="settingdrop">
                                            <li><Link className={`link  ${location.pathname === '/account-setting' ? 'active' : ''}`} to="/account-setting">Account settings</Link></li>
                                            <li><Link className={`link  ${location.pathname === '/admin-wallet-setting' ? 'active' : ''}`} to="/admin-wallet-setting">wallet settings</Link></li>
                                            <li><Link target='_blank' className={`link  ${location.pathname === '/website_cms' ? 'active' : ''}`} to="/website_cms">Website</Link></li>
                                            {/* <li><Link className={`link  ${location.pathname  === '/subscription-packages' ? 'active' :'' }`} to="/subscription-packages">Subscription Packages</Link></li> */}
                                            <li><Link className={`link  ${location.pathname === '/cms_faq' ? 'active' : ''}`} to="/cms_faq">CMS FAQ</Link></li>
                                            <li><Link className={`link  ${location.pathname === '/cms_options_settings' ? 'active' : ''}`} to="/cms_options_settings">CMS options</Link></li>
                                        </ul>
                                    </li>
                                }
                                {
                                    userdetails.accessibility.report
                                    && userdetails.accessibility.report.view && <li className="dropdown">
                                        {/* <a className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" to="/admin_all_reviews">Report</a> */}
                                        <a
                                            className={`dropdown-toggle ${(location.pathname == '/attendence-report' || location.pathname == '/live-view' || location.pathname == '/survey-report' || location.pathname == '/admin_ato') ? 'show' : ''}`}
                                            type="button" data-bs-toggle="dropdown"
                                            aria-expanded={(location.pathname == '/attendence-report' || location.pathname == '/live-view' || location.pathname == '/survey-report' || location.pathname == '/admin_ato') ? 'true' : 'false'}
                                            to="/admin_all_reviews">Report
                                        </a>
                                        <ul className={`dropdown-menu  ${(location.pathname == '/attendence-report' || location.pathname == '/live-view' || location.pathname == '/survey-report' || location.pathname == '/admin_ato') ? 'show' : 'false'}`} aria-labelledby="settingdrop">
                                            <li className="dropdown">
                                                <a className="dropdown-toggle" type="button" id="settingdrop" data-bs-toggle="dropdown" aria-expanded="false" to="/admin_all_reviews">Tax Purpose</a>
                                                <ul className="dropdown-menu" aria-labelledby="settingdrop">
                                                    <li><Link className="dropdown-item" to="/income">Income</Link></li>
                                                    <li><Link className="dropdown-item" to="/expenses">Expenses</Link></li>
                                                    <li><Link className="dropdown-item" to="/payment-history">Payment history</Link></li>
                                                    <li><Link className="dropdown-item" to="/gross-report">Gross Report</Link></li>
                                                </ul>
                                            </li>
                                            <li><Link className={`link  ${location.pathname === '/attendence-report' ? 'active' : ''}`} to="/attendence-report">Attendence Report</Link></li>
                                            <li><Link className={`link  ${location.pathname === '/live-view' ? 'active' : ''}`} to="/live-view">Live View</Link></li>
                                            <li><Link target='_blank' className={`link  ${location.pathname === '/survey-report' ? 'active' : ''}`} to="/survey-report">Survey Report</Link></li>
                                            <li><Link target='_blank' className={`link  ${location.pathname === '/admin_ato' ? 'active' : ''}`} to="/admin_ato">ATO</Link></li>
                                        </ul>
                                    </li>
                                }
                            </ul>
                        </>
                        :
                        <>
                            <ul>
                                <li><Link className={`link  ${location.pathname === '/admin-dashboard' ? 'active' : ''}`} to="/admin-dashboard">Dashboard</Link></li>
                                {/* <li><Link className={`link  ${location.pathname === '/offers' ? 'active' : ''}`} to="/offers">Job offers</Link></li> */}
                                <li className="dropdown">
                                    <a
                                        className={`dropdown-toggle ${(location.pathname == '/offers' || location.pathname == '/declined-jobs') ? 'show' : ''}`}
                                        type="button" data-bs-toggle="dropdown"
                                        aria-expanded={(location.pathname == '/offers' || location.pathname == '/declined-jobs') ? 'true' : 'false'}
                                        to="/admin_all_reviews">Jobs
                                    </a>
                                    <ul className={`dropdown-menu ${(location.pathname == '/offers' || location.pathname == '/declined-jobs') ? 'show' : ''}`} aria-labelledby="settingdrop">
                                        <li><Link className={`link  ${location.pathname === '/offers' ? 'active' : ''}`} to="/offers">Job offers</Link></li>
                                        <li><Link className={`link  ${location.pathname === '/declined-jobs' ? 'active' : ''}`} to="/declined-jobs">Declined jobs</Link></li>
                                    </ul>
                                </li>
                                <li><Link className={`link  ${(location.pathname === '/recruiters' || location.pathname === '/recruiter-detail') ? 'active' : ''}`} to="/recruiters">Recruiters</Link></li>
                                <li><Link className={`link  ${(location.pathname === '/jobseekers' || location.pathname === '/jobseeker-detail') ? 'active' : ''}`} to="/jobseekers">Jobseekers</Link></li>
                                <li><Link className={`link  ${(location.pathname === '/users' || location.pathname === '/admin_users_chat') ? 'active' : ''}`} to="/users">Staff</Link></li>
                                <li><Link className={`link  ${location.pathname === '/admin_all_reviews' ? 'active' : ''}`} to="/admin_all_reviews">Reviews</Link></li>
                                <li><Link className={`link  ${location.pathname === '/chat-history' ? 'active' : ''}`} to="/chat-history">Chat history</Link></li>
                                
                                {/* lead-collection */}
                                <li className="dropdown">
                                    <a
                                        className={`dropdown-toggle ${(location.pathname == '/lead-collection' || location.pathname == '/admin-ticket-history' || location.pathname == '/admin-livechat-history' || location.pathname == '/admin-call-history') ? 'show' : ''}`}
                                        type="button" data-bs-toggle="dropdown"
                                        aria-expanded={(location.pathname == '/admin-ticket-history' || location.pathname == '/admin-livechat-history' || location.pathname == '/admin-call-history') ? 'true' : 'false'}
                                        to="/admin_all_reviews">Support
                                    </a>
                                    <ul className={`dropdown-menu ${(location.pathname == '/lead-collection' || location.pathname == '/admin-ticket-history' || location.pathname == '/admin-livechat-history' || location.pathname == '/admin-call-history') ? 'show' : ''}`} aria-labelledby="settingdrop">
                                        <li><Link className={`link  ${location.pathname === '/admin-ticket-history' ? 'active' : ''}`} to="/admin-ticket-history">Tickets</Link></li>
                                        <li><Link className={`link  ${location.pathname === '/admin-livechat-history' ? 'active' : ''}`} to="/admin-livechat-history">Live chats</Link></li>
                                        <li><Link className={`link  ${location.pathname === '/admin-call-history' ? 'active' : ''}`} to="/admin-call-history">Requested calls</Link></li>
                                        <li><Link className={`link  ${location.pathname === '/lead-collection' ? 'active' : ''}`} to="/lead-collection">Lead Collection</Link></li>
                                    </ul>
                                </li>
                                <li className="dropdown">
                                    {/* <a className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" to="/admin_all_reviews">Settings</a> */}
                                    <a
                                        className={`dropdown-toggle ${(location.pathname == '/account-setting' || location.pathname == '/website_cms' || location.pathname == '/cms_faq' || location.pathname == '/cms_options_settings') ? 'show' : ''}`}
                                        type="button" data-bs-toggle="dropdown"
                                        aria-expanded={(location.pathname == '/account-setting' || location.pathname == '/website_cms' || location.pathname == '/cms_faq' || location.pathname == '/cms_options_settings') ? 'true' : 'false'}
                                        to="/admin_all_reviews">Settings
                                    </a>
                                    <ul className={`dropdown-menu ${(location.pathname == '/account-setting' || location.pathname == '/website_cms' || location.pathname == '/cms_faq' || location.pathname == '/cms_options_settings') ? 'show' : ''}`} aria-labelledby="settingdrop">
                                        <li><Link className={`link  ${location.pathname === '/account-setting' ? 'active' : ''}`} to="/account-setting">Account settings</Link></li>
                                        <li><Link className={`link  ${location.pathname === '/admin-wallet-setting' ? 'active' : ''}`} to="/admin-wallet-setting">Wallet settings</Link></li>
                                        <li><Link target='_blank' className={`link  ${location.pathname === '/website_cms' ? 'active' : ''}`} to="/website_cms">Website</Link></li>
                                        {/* <li><Link className={`link  ${location.pathname  === '/subscription-packages' ? 'active' :'' }`} to="/subscription-packages">Subscription Packages</Link></li> */}
                                        <li><Link className={`link  ${location.pathname === '/cms_faq' ? 'active' : ''}`} to="/cms_faq">CMS FAQ</Link></li>
                                        <li><Link className={`link  ${location.pathname === '/cms_options_settings' ? 'active' : ''}`} to="/cms_options_settings">CMS options</Link></li>
                                    </ul>
                                </li>
                                <li className="dropdown">
                                    {/* <a className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" to="/admin_all_reviews">Report</a> */}
                                    <a
                                        className={`dropdown-toggle ${(location.pathname == '/attendence-report' || location.pathname == '/live-view' || location.pathname == '/survey-report' || location.pathname == '/admin_ato') ? 'show' : ''}`}
                                        type="button" data-bs-toggle="dropdown"
                                        aria-expanded={(location.pathname == '/attendence-report' || location.pathname == '/live-view' || location.pathname == '/survey-report' || location.pathname == '/admin_ato') ? 'true' : 'false'}
                                        to="/admin_all_reviews">Reports
                                    </a>
                                    <ul className={`dropdown-menu  ${(location.pathname == '/attendence-report' || location.pathname == '/live-view' || location.pathname == '/survey-report' || location.pathname == '/admin_ato') ? 'show' : 'false'}`} aria-labelledby="settingdrop">
                                        <li className="dropdown">
                                            <div className="custom-dropdown">
                                                {/* <div className="dropdown-header" onClick={toggleDropdown}>
                                    {selectedOption || 'Select an Option'}
                                </div> */}
                                                <a className="dropdown-header dropdown-toggle" type="button" id="settingdrop2" onClick={toggleDropdown}>Tax purpose</a>
                                                {isOpen && (
                                                    <ul className="dropdown-list">

                                                        <li><Link className="dropdown-item" to="/income">Income</Link></li>
                                                        <li><Link className="dropdown-item" to="/expenses">Expenses</Link></li>
                                                        <li><Link className="dropdown-item" to="/payment-history">Payment history</Link></li>
                                                        <li><Link className="dropdown-item" to="/gross-report">Gross report</Link></li>

                                                    </ul>
                                                )}
                                            </div>

                                            {/*                                 
                                            <a className="dropdown-toggle" type="button" id="settingdrop" data-bs-toggle="dropdown" aria-expanded="false" to="/admin_all_reviews">Tax Purpose</a>
                                            <ul className="dropdown-menu" aria-labelledby="settingdrop">
                                                <li><Link className="dropdown-item" to="/income">Income</Link></li>
                                                <li><Link className="dropdown-item" to="/expenses">Expenses</Link></li>
                                                <li><Link className="dropdown-item" to="/payment-history">Payment History</Link></li>
                                                <li><Link className="dropdown-item" to="/gross-report">Gross Report</Link></li>
                                            </ul> */}
                                        </li>
                                        <li><Link className={`link  ${location.pathname === '/attendence-report' ? 'active' : ''}`} to="/attendence-report">Attendence report</Link></li>
                                        <li><Link className={`link  ${location.pathname === '/live-view' ? 'active' : ''}`} to="/live-view">Live View</Link></li>
                                        <li><Link target='_blank' className={`link  ${location.pathname === '/survey-report' ? 'active' : ''}`} to="/survey-report">Survey report</Link></li>
                                        <li><Link target='_blank' className={`link  ${location.pathname === '/admin_ato' ? 'active' : ''}`} to="/admin_ato">ATO</Link></li>
                                    </ul>
                                </li>
                            </ul>

                        </>
                }
            </div>
        </>
    );
}
export default SuperAdminSidebar;