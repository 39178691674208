import { createSlice } from '@reduxjs/toolkit'

export const checkDocumentstatus = createSlice({
  name: 'checkDocumentstatus',
  initialState: {
    docsstate: {
            adminside_verification : 0 , 
            admin_side_ucontact_verification : 0 , 
            basicaddress_validation : 0 , 
            basiccontact_validation: 0 ,
            basicdetail_validation: 0,
            basicdob_validation: 0,
            basicemail_validation : 0,
            basictaxtype_validation : 0,
            basicvisa_validation :  0,
            prederedjob_validation :  0,
            profiledisabled_validation :  0,
            profilesummery_validation :  0,
            userdoc_validation : 0,
            companydetails_validation : 0 ,
            companyaddress_validation : 0 ,
            directordetails_validation : 0 ,
            wordexp_validation : 0
    },
     
  }, 
  reducers: {

    workExpDocumentStatus: (state , action) => {
        state.docsstate.wordexp_validation = action.payload ;
        const validationdataCheckData = JSON.parse(localStorage.getItem("validationdataCheckData"));
        const docdataforSet = { ...validationdataCheckData, wordexp_validation: 1 };
        localStorage.setItem('validationdataCheckData', JSON.stringify(docdataforSet))
    },

    documentCheckValidation: (state , action) => {
        state.docsstate.userdoc_validation = action.payload ;
        const validationdataCheckData = JSON.parse(localStorage.getItem("validationdataCheckData"));
        const docdataforSet = { ...validationdataCheckData, userdoc_validation: 1 };
        localStorage.setItem('validationdataCheckData', JSON.stringify(docdataforSet))
    },
    
    preferedJobValidationCheck: (state , action) => {
        state.docsstate.prederedjob_validation = action.payload ;
        const validationdataCheckData = JSON.parse(localStorage.getItem("validationdataCheckData"));
        const docdataforSet = { ...validationdataCheckData, prederedjob_validation: 1 };
        localStorage.setItem('validationdataCheckData', JSON.stringify(docdataforSet))
    },
    
    basicDetailsValidationCheck: (state , action) => {
        console.log(action);
         state.docsstate.basicdob_validation = action.payload ;
         const validationdataCheckData = JSON.parse(localStorage.getItem("validationdataCheckData"));
         const docdataforSet = { ...validationdataCheckData, basicdob_validation: 1 };
         localStorage.setItem('validationdataCheckData', JSON.stringify(docdataforSet))
    },
    basicAddressValidationCheck: (state , action) => {
        state.docsstate.basicaddress_validation = action.payload ;
        const validationdataCheckData = JSON.parse(localStorage.getItem("validationdataCheckData"));
        const docdataforSet = { ...validationdataCheckData, basicaddress_validation: 1 };
        localStorage.setItem('validationdataCheckData', JSON.stringify(docdataforSet))
    },
    basicTaxTypeValidationCheck: (state , action) => {
        state.docsstate.basictaxtype_validation = action.payload ;
        const validationdataCheckData = JSON.parse(localStorage.getItem("validationdataCheckData"));
        const docdataforSet = { ...validationdataCheckData, basictaxtype_validation: 1 };
        localStorage.setItem('validationdataCheckData', JSON.stringify(docdataforSet))
    },
    basicVisaValidationCheck: (state , action) => {
        console.log('----------'); 
        state.docsstate.basicvisa_validation = action.payload ;
        const validationdataCheckData = JSON.parse(localStorage.getItem("validationdataCheckData"));
        const docdataforSet = { ...validationdataCheckData, basicvisa_validation: 1 };
        localStorage.setItem('validationdataCheckData', JSON.stringify(docdataforSet))
    },
    basicContactValidationCheck: (state , action) => {
        state.docsstate.basiccontact_validation = action.payload ;
        const validationdataCheckData = JSON.parse(localStorage.getItem("validationdataCheckData"));
        const docdataforSet = { ...validationdataCheckData, basiccontact_validation: 1 };
        localStorage.setItem('validationdataCheckData', JSON.stringify(docdataforSet))
    },
    companyDetailsValidation: (state , action) => {
        state.docsstate.companydetails_validation = action.payload ;
        const validationdataCheckData = JSON.parse(localStorage.getItem("validationdataCheckData"));
        const docdataforSet = { ...validationdataCheckData, companydetails_validation: 1 };
        localStorage.setItem('validationdataCheckData', JSON.stringify(docdataforSet))
    },

    companyAddressValidation: (state , action) => {
        state.docsstate.companyaddress_validation = action.payload ;
        const validationdataCheckData = JSON.parse(localStorage.getItem("validationdataCheckData"));
        const docdataforSet = { ...validationdataCheckData, companyaddress_validation: 1 };
        localStorage.setItem('validationdataCheckData', JSON.stringify(docdataforSet))
    },
    
    directorsDetailsValidation: (state , action) => {
        state.docsstate.directordetails_validation = action.payload ;
        const validationdataCheckData = JSON.parse(localStorage.getItem("validationdataCheckData"));
        const docdataforSet = { ...validationdataCheckData, directordetails_validation: 1 };
        localStorage.setItem('validationdataCheckData', JSON.stringify(docdataforSet))
    },
    
    userProfileSummery: (state , action) => {
        state.docsstate.profilesummery_validation = action.payload ;
        const validationdataCheckData = JSON.parse(localStorage.getItem("validationdataCheckData"));
        const docdataforSet = { ...validationdataCheckData, profilesummery_validation: 1 };
        localStorage.setItem('validationdataCheckData', JSON.stringify(docdataforSet))
    },
    
    userSocialMedia: (state , action) => {
        state.docsstate.socialmedia_validation = action.payload ;
        const validationdataCheckData = JSON.parse(localStorage.getItem("validationdataCheckData"));
        const docdataforSet = { ...validationdataCheckData, socialmedia_validation: 1 };
        localStorage.setItem('validationdataCheckData', JSON.stringify(docdataforSet))
    },
 
    docStatusValueCheck: (state , action) => {
       if(action.payload.doctype == 'all' && action.payload.type == 'onload'){
            state.docsstate =  action.payload.sessiondata ;
       }
    } ,
    admin_side_profile_verification: (state , action) => {
        state.docsstate.adminside_verification = action.payload ;
    },
    admin_side_usercontact_verification: (state , action) => {
        state.docsstate.admin_side_ucontact_verification = action.payload ;
    }
  },
})
export const { 
        workExpDocumentStatus , 
        preferedJobValidationCheck , 
        documentCheckValidation , 
        basicDetailsValidationCheck , 
        basicAddressValidationCheck , 
        basicTaxTypeValidationCheck , 
        basicVisaValidationCheck , 
        basicContactValidationCheck ,
        companyDetailsValidation ,
        companyAddressValidation ,
        userProfileSummery ,
        userSocialMedia ,
        directorsDetailsValidation ,
        admin_side_profile_verification , 
        admin_side_usercontact_verification , 
        docStatusValueCheck 
    } = checkDocumentstatus.actions

export default checkDocumentstatus.reducer