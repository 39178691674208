import * as React from 'react';
import { ZegoUIKitPrebuilt } from '@zegocloud/zego-uikit-prebuilt';


function randomID(len) {
  let result = '';
  if (result) return result;
  var chars = '12345qwertyuiopasdfgh67890jklmnbvcxzMNBVCZXASDQWERTYHGFUIOLKJP',
    maxPos = chars.length,
    i;
  len = len || 5;
  for (i = 0; i < len; i++) {
    result += chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return result;
}

export function getUrlParams(
  url = window.location.href
) {
  let urlStr = url.split('?')[1];
  return new URLSearchParams(urlStr);
}


export default function App() {
      const roomID = getUrlParams().get('roomID') || randomID(5);
      let myMeeting = async (element) => {
     // generate Kit Token
      const appID = 379138714;
      const serverSecret = "b8d815e72ed5247d292878a4b1624d4e";
      const kitToken =  ZegoUIKitPrebuilt.generateKitTokenForTest(appID, serverSecret, roomID,  randomID(5),  randomID(5));

        
     // Create instance object from Kit Token.
      const zp = ZegoUIKitPrebuilt.create(kitToken);


      console.log(ZegoUIKitPrebuilt);
      // start the call
      zp.joinRoom({
        container: element,
        sharedLinks: [
          {
            name: 'Personal link',
            url:
             window.location.protocol + '//' + 
             window.location.host + window.location.pathname +
              '?roomID=' +
              roomID,
          },
        ],
        turnOnCameraWhenJoining : false ,
        showAudioVideoSettingsButton : false ,
        useFrontFacingCamera : false ,
        showOnlyAudioUser : true ,
        showInviteToCohostButton : false ,
        showRemoveCohostButton : false ,
        maxUsers : 2 ,
        scenario: {
          mode: ZegoUIKitPrebuilt.VoiceCall, // To implement 1-on-1 calls, modify the parameter here to [ZegoUIKitPrebuilt.OneONoneCall].
        },
      });


  };

  return (
    <>
    <div
      className="myCallContainer2"
      ref={myMeeting}
      style={{ width: '100vw', height: '100vh' }}
    ></div>
    </>
  );
}