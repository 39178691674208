import SuperAdminSidebar from './superadmin_sidebar'
const PaymentHistory = () => {
   return (
      <>
         <div className="dashboarwrap">

            <div className="pagebanner">
               <div className="container">
                  <div className="row">
                     <div className="col-md-12">
                        <h2>Payment History</h2>
                     </div>
                  </div>
               </div>
            </div>

            <div className="adminwrap py-4">
               <div className="container">
                  <div className="row">
                     <div className="col-md-3 adminsidebar">
                        <SuperAdminSidebar></SuperAdminSidebar>
                     </div>
                     <div className="col-md-9">
                        
                     <div className="row paymenthistory">
                        <div className="col-md-4 mb-4">
                           <div className="box ststicsbox d-flex justify-content-between coinbox Totalearning">
                              <div className="ststicsboxleft">
                                 <p>Total coin purchased</p>
                                 <h2>54904 <span>Coins</span></h2>
                              </div>
                              <div className="ststicsboxright"><img src="/front/images/revenue.png"/></div>
                           </div>
                        </div>
                        <div className="col-md-4 mb-4">
                           <div className="box ststicsbox d-flex justify-content-between coinbox">
                              <div className="ststicsboxleft">
                                 <p>Total coin purchased by Jobseeker</p>
                                 <h2>30495 <span>Coins</span></h2>
                              </div>
                              <div className="ststicsboxright"><img src="/front/images/userr.png"/></div>
                           </div>
                        </div>
                        <div className="col-md-4 mb-4">
                           <div className="box ststicsbox d-flex justify-content-between coinbox">
                              <div className="ststicsboxleft">
                                 <p>Total coin purchased by Recruiter</p>
                                 <h2>24,409 <span>Coins</span></h2>
                              </div>
                              <div className="ststicsboxright"><img src="/front/images/userert.png"/></div>
                           </div>
                        </div>
                        <div className="col-md-4 mb-4">
                           <div className="box ststicsbox d-flex justify-content-between coinbox">
                              <div className="ststicsboxleft">
                                 <p>Total coin used</p>
                                 <h2>24,409 <span>Coins</span></h2>
                              </div>
                              <div className="ststicsboxright"><img src="/front/images/loss.png"/></div>
                           </div>
                        </div>
                        <div className="col-md-4 mb-4">
                           <div className="box ststicsbox d-flex justify-content-between coinbox">
                              <div className="ststicsboxleft">
                              <p>Total coin used by Jobseeker</p>
                                 <h2>24,409 <span>Coins</span></h2>
                              </div>
                              <div className="ststicsboxright"><img src="/front/images/buyer.png"/></div>
                           </div>
                        </div>
                        <div className="col-md-4 mb-4">
                           <div className="box ststicsbox d-flex justify-content-between coinbox">
                              <div className="ststicsboxleft">
                              <p>Total coin used by Recruiter</p>
                                 <h2>24,409 <span>Coins</span></h2>
                              </div>
                              <div className="ststicsboxright"><img src="/front/images/deposit.png"/></div>
                           </div>
                        </div>
                     </div>

                     <div className="row">
                    

                        <div className="col-md-12">
                           <div className="box mb-4">
                                 <div className="box-ttl mb-3 d-flex align-items-center justify-content-between">    
                                       <h3>Total coin purchased</h3>
                                       
                                       <div className="">
                                       <div className="switch-field mr-0">
                                       <div className="switchfieldinner">
                                       <input type="radio" id="Jobseeker" name="usertype"/>
                                       <label htmlFor="Jobseeker">Jobseeker</label>
                                       </div>
                                       <div className="switchfieldinner">
                                       <input type="radio" id="Recruiter" name="usertype"/>
                                       <label htmlFor="Recruiter">Recruiter</label>
                                       </div>
                                       </div>

                                       <div className="switch-field mr-0 ml-3">
                                       <div className="switchfieldinner">
                                       <input type="radio" id="monthly" name="yearwitch"/>
                                       <label htmlFor="monthly">Monthly</label>
                                       </div>
                                       <div className="switchfieldinner">
                                       <input type="radio" id="quaterly" name="yearwitch"/>
                                       <label htmlFor="quaterly">Quaterly</label>
                                       </div>
                                       <div className="switchfieldinner">
                                       <input type="radio" id="yearly" name="yearwitch"/>
                                       <label htmlFor="yearly">Yearly</label>
                                       </div>
                                       </div>
                                       </div>
                                 </div>
                                 <img src="/front/images/graph.jpg" className="w-100"></img>
                           </div>
                        </div>


                        <div className="col-md-12">
                           <div className="box mb-4">
                                 <div className="box-ttl mb-3 d-flex align-items-center justify-content-between">    
                                       <h3>Total coin used</h3>
                                       
                                       <div className="">
                                       <div className="switch-field mr-0">
                                       <div className="switchfieldinner">
                                       <input type="radio" id="Jobseeker1" name="usertype1"/>
                                       <label htmlFor="Jobseeker1">Jobseeker</label>
                                       </div>
                                       <div className="switchfieldinner">
                                       <input type="radio" id="Recruiter1" name="usertype1"/>
                                       <label htmlFor="Recruiter1">Recruiter</label>
                                       </div>
                                       </div>

                                       <div className="switch-field mr-0 ml-3">
                                       <div className="switchfieldinner">
                                       <input type="radio" id="monthly1" name="yearwitch1"/>
                                       <label htmlFor="monthly1">Monthly</label>
                                       </div>
                                       <div className="switchfieldinner">
                                       <input type="radio" id="quaterly1" name="yearwitch1"/>
                                       <label htmlFor="quaterly1">Quaterly</label>
                                       </div>
                                       <div className="switchfieldinner">
                                       <input type="radio" id="yearly1" name="yearwitch1"/>
                                       <label htmlFor="yearly1">Yearly</label>
                                       </div>
                                       </div>
                                       </div>
                                 </div>
                                 <img src="/front/images/graph.jpg" className="w-100"></img>
                           </div>
                        </div>

                     </div>


                     <div className="row">
                        <div className="col-md-12">
                           <div className="box mb-4">
                              <div className="table_filter mb-2 d-flex align-items-center justify-content-between commonform">
                                 <div className="tblsearch"><input type="text" className="form-control" placeholder="Search" fdprocessedid="61naqt"/></div>
                                 <div className="tblrightfilters d-flex align-items-center">
                                    <select className="form-control" fdprocessedid="57d4zre">
                                       <option>All</option>
                                       <option>Jobseeker</option>
                                       <option>Recruiter</option>
                                    </select>
                                    <select className="form-control ml-3" fdprocessedid="q0h1yu">
                                       <option>Date</option>
                                       <option>Monthly</option>
                                       <option>Quaterly</option>
                                       <option>Yearly</option>
                                    </select>
                                 </div>
                              </div>
                              <div className="table-responsive">
                                 <table className="table admintbl">
                                    <thead>
                                       <tr>
                                          <th>User Name</th>
                                          <th>Email</th>
                                          <th>User Type</th>
                                          <th>Date of Purchase</th>
                                          <th>No. of Coin Purchades</th>
                                          
                                       </tr>
                                    </thead>
                                    <tbody>
                                       <tr>
                                          <td><a>Daniel Marten</a></td>
                                          <td>daniel@yopmail.com</td>
                                          <td>Recruiter</td>
                                          <td>22 November 2022</td>
                                          <td>3453</td>
                                       </tr>
                                       <tr>
                                          <td><a>Daniel Marten</a></td>
                                          <td>daniel@yopmail.com</td>
                                          <td>Recruiter</td>
                                          <td>22 November 2022</td>
                                          <td>3453</td>
                                       </tr>
                                       <tr>
                                          <td><a>Daniel Marten</a></td>
                                          <td>daniel@yopmail.com</td>
                                          <td>Recruiter</td>
                                          <td>22 November 2022</td>
                                          <td>3453</td>
                                       </tr>
                                       <tr>
                                          <td><a>Daniel Marten</a></td>
                                          <td>daniel@yopmail.com</td>
                                          <td>Recruiter</td>
                                          <td>22 November 2022</td>
                                          <td>3453</td>
                                       </tr>
                                       <tr>
                                          <td><a>Daniel Marten</a></td>
                                          <td>daniel@yopmail.com</td>
                                          <td>Recruiter</td>
                                          <td>22 November 2022</td>
                                          <td>3453</td>
                                       </tr>
                                       <tr>
                                          <td><a>Daniel Marten</a></td>
                                          <td>daniel@yopmail.com</td>
                                          <td>Recruiter</td>
                                          <td>22 November 2022</td>
                                          <td>3453</td>
                                       </tr>
                                       <tr>
                                          <td><a>Daniel Marten</a></td>
                                          <td>daniel@yopmail.com</td>
                                          <td>Recruiter</td>
                                          <td>22 November 2022</td>
                                          <td>3453</td>
                                       </tr>
                                       
                                    </tbody>
                                 </table>
                                 <div className="card-box">
                                    <nav aria-label="Page navigation">
                                       <ul className="pagination mb-0 justify-content-center">
                                          <li className="page-item"><a className="page-link" href="#"><i className="fa fa-angle-left" aria-hidden="true"></i></a></li>
                                          <li className="page-item active"><a className="page-link" href="#">1</a></li>
                                          <li className="page-item"><a className="page-link" href="#">2</a></li>
                                          <li className="page-item"><a className="page-link" href="#"><i className="fa fa-angle-right" aria-hidden="true"></i></a></li>
                                       </ul>
                                    </nav>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>


                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
}
export default PaymentHistory;