const Skills = () => {
   return (
   <>
   <div className="dashboarwrap">
      <div className="profilebanner">
         <div className="container">
            <div className="row">
               <div className="col-md-12">
                  <h2>Profile</h2>
                  <div className="row">
                     <div className="col-md-6">
                     <div className="profiletopsec d-flex">
                        <div className="profiletopleft">
                            <div className="profileimgsec">
                                <img src="/front/images/profile.png" />
                                <div className="profilecamera">
                                   <input type="file" />
                                   <img src="/front/images/camera.png" />
                                </div>
                                
                            </div>
                        </div>

                        <div className="profiletopright">
                            <h3 className="d-flex">John Doe <img src="/front/images/profilecheck.png" /></h3>
                            <p className="d-flex"><img src="/front/images/mail.svg" /> john_doe1232@gmail.com</p>
                            <p className="d-flex"><img src="/front/images/phone.png" /> +61 125 8745</p>
                        </div>
                        </div>
                     </div>
                     <div className="col-md-6 text-right">
                         <div className="reting d-flex">
                             <div className="stars d-flex">
                                <div className="star"><img src="/front/images/fillstar.svg" /></div>
                                <div className="star"><img src="/front/images/fillstar.svg" /></div>
                                <div className="star"><img src="/front/images/fillstar.svg" /></div>
                                <div className="star"><img src="/front/images/fillstar.svg" /></div>
                                <div className="star"><img src="/front/images/starredopen.png" /></div>
                             </div>
                             <p>4.0 (12 reviews)</p>
                         </div>
                         
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div className="jobbtmsec py-4">
         <div className="container">
         
            <div className="row">
               <div className="col-md-3 profilesidebarwrap">
                   <div className="card-box profilesidebar">
                       <ul>
                       <li><a href="/profile">Resume</a></li>
                          <li><a href="/jobseeker/work-experience">Work experience</a></li>
                          <li><a href="/license">License</a></li>
                          <li><a href="/tfn-no">TFN No.</a></li>
                          <li><a href="/email-and-phone">Email and phone</a></li>
                          <li><a href="/availability">Availability</a></li>
                          <li><a href="/locations">Locations</a></li>
                          <li><a href="/salary">Salary</a></li>
                          <li><a href="/skills" className="active">Skills</a></li>
                       </ul>
                   </div>
               </div>
               <div className="col-md-9">
                 <div className="profilerightwraper">
                    

                     <div className="card-box skills-sec">
                        <div className="">
                          <h3 className="mb-3">Skills</h3>
                         
                          <div className="d-flex align-items-center tabsgropu flex-wrap mb-2">
                             <div className="roundedtab">Plumber</div>
                             <div className="roundedtab">Electrician</div>
                             <div className="roundedtab">Gardner</div>
                             <div className="roundedtab">Welder</div>
                             <div className="roundedtab">Chef</div>
                             <div className="roundedtab">Driver</div>
                             <div className="roundedtab">Crane Operator</div>
                             <div className="roundedtab">Plumber</div>
                             <div className="roundedtab">Plumber</div>
                             <div className="roundedtab">Electrician</div>
                             <div className="roundedtab">Gardner</div>
                             <div className="roundedtab">Welder</div>
                             <div className="roundedtab">Chef</div>
                             <div className="roundedtab">Driver</div>
                             <div className="roundedtab">Crane Operator</div>
                             <div className="roundedtab">Plumber</div>
                             <div className="roundedtab">Plumber</div>
                             <div className="roundedtab">Electrician</div>
                             <div className="roundedtab">Gardner</div>
                             <div className="roundedtab">Welder</div>
                             <div className="roundedtab">Chef</div>
                             <div className="roundedtab">Driver</div>
                             <div className="roundedtab">Crane Operator</div>
                          </div>
                        </div>
                     </div>


                 </div>
               </div>
            </div>
         </div>
      </div>
   

      <div className="modal fade modallarg docuploadmodal" id="docuploadmodal" tabindex="-1" aria-labelledby="docuploadmodalLabel" aria-hidden="true">
         <div className="modal-dialog">
            <div className="modal-content">
               <div className="modal-body">
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  <h3 className="mb-4">Upload minimum 3 consecutive months salary slip</h3>
                  <div className="row">
                     <div className="col-md-4">
                        <div className="customuploadsec">
                           <input type="file"></input>
                           <div className="customuploadinner text-center">
                              <img src="/front/images/uploadicon.png" className="mb-4"></img>
                              <p className="mb-0">drag and drop here</p>
                              <p className="mb-0 text-dark">or</p>
                              <p className="mb-0"><a className="linktxt" href="#">upload from computer</a></p>
                           </div>
                        </div>
                     </div>
                     <div className="col-md-4">
                        <div className="customuploadsec">
                           <input type="file"></input>
                           <div className="customuploadinner text-center">
                              <img src="/front/images/uploadicon.png" className="mb-4"></img>
                              <p className="mb-0">drag and drop here</p>
                              <p className="mb-0 text-dark">or</p>
                              <p className="mb-0"><a className="linktxt" href="#">upload from computer</a></p>
                           </div>
                        </div>
                     </div>
                     <div className="col-md-4">
                        <div className="customuploadsec">
                           <input type="file"></input>
                           <div className="customuploadinner text-center">
                              <img src="/front/images/uploadicon.png" className="mb-4"></img>
                              <p className="mb-0">drag and drop here</p>
                              <p className="mb-0 text-dark">or</p>
                              <p className="mb-0"><a className="linktxt" href="#">upload from computer</a></p>
                           </div>
                        </div>
                     </div>
                     <div className="col-md-4"></div>
                     <div className="col-md-4"></div>
                  </div>
                 <div className="btnsgroup"><a href="#" className="batn batn-orange">Submit</a></div>
               </div>
            </div>
         </div>
      </div>
    

   </div>
   </>
   );
   }
   export default Skills;