import React, { useState, useEffect, Component } from 'react';
import { Link ,useNavigate ,useLocation } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

import axios from 'axios';
import { toast } from 'react-toastify';
// //import 'react-toastify/dist/ReactToastify.css';
import UserChatCommunication from './UserChatCommunication';
import { useSelector, useDispatch } from 'react-redux';
import ShowSoacialMedia   from '../../ShowSocialMediaComp';


const UserProfileBanner = (prop) => {
    const navigate =  useNavigate();
   // const selector =  useSelector();
   const dispatch = useDispatch();
     const isUserVerified = useSelector((state) => state.adminSideVerificationStatus.adminside_verification);
     const isUserContactVerified = useSelector((state) => state.adminSideVerificationStatus.admin_side_ucontact_verification);

     const isUserVerified2 = useSelector((state) => state);
    //  const isUserContactVerified2 = useSelector((state) => state);

    console.log(isUserVerified2.adminSideVerificationStatus);
    //const isUserVerified = true;
    //const isUserContactVerified = true;
   // console.log(isUserContactVerified);
    let location = useLocation();
    const [userdetails, setUserdetails] = useState([]);
    const profilepic = (userdetails.profilepic) ? userdetails.profilepic : '/front/images/user.svg';
    const [isScreenLoad, setisScreenLoad] = useState(false);
    const [chatShcreenToggole, setchatShcreenToggole] = useState(false);
    const [profileActivated, setprofileActivated] = useState(false);
    const [isProfileUnblock, setIsProfileUnblock] = useState(false);
    const [isContactVerified, setisContactVerified] = useState(false);
    const [getUserid, setgetUserid] = useState(false);
    const [profilePicUrl, setProfilePicUrl] = useState(profilepic);
    const [backTo, setBackto] = useState('');
    const [jsid, setjsid] = useState('');
    

    
    const getUserDetailsFun = (userid) => {
        setisScreenLoad(true);
        (async () => {
            try {
                const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/userdetails/" + userid + '?callingby=adminaccount');
                if (resData.data.status == 1) {
                    setUserdetails(resData.data.data.results);
                   
                    // if(resData.data.data.results.profile_enabled == 1 &&  resData.data.data.results.profile_disabled_by_admin == 0){
                    if(resData.data.data.results.profile_enabled == 1){
                        setprofileActivated(true);
                    }
                    if(resData.data.data.results.status == 1){
                        setIsProfileUnblock(true);
                    } 
                    if (resData.data.data.results.profilepic) {
                        setProfilePicUrl(resData.data.data.results.profilepic);
                    }
                    setisContactVerified(resData.data.data.results.contact_status);
                    localStorage.setItem('signup_userdetails', JSON.stringify(resData.data.data.results));
                    setisScreenLoad(false);
                }
            } catch (error) { console.log(error); }
        })();
    }
    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        const userid = queryParameters.get("id");
        const backto = queryParameters.get("backto");
        setjsid(queryParameters.get("jsid"));
        setBackto(backto);
        if (userid) { setgetUserid(userid); getUserDetailsFun(userid); }
        else {
            if(location.pathname == '/recruiter-detail'){ navigate('/recruiters'); } 
            else {  navigate('/jobseekers'); }
        }
    }, []);

    const StarRating = ({ rating }) => {
        const stars = [1, 2, 3, 4, 5];
        return (
            <>
                {
                    stars.map((star) => (
                        star <= rating
                            ?
                            <><div className="star"><img src="/front/images/fillstar.svg" /></div></>
                            :
                            <><div className="star"><img src="/front/images/starredopen.png" /></div></>

                    ))
                }
            </>
        )
    }


    const [isActionModalShow, isSetActionModalShow] = useState(false);
    const [isDisabledAction, setisDisabledAction] = useState(false);
    const adminUserStatusAction = () => {
        const sgls = JSON.parse(localStorage.getItem("squadGologinSession"));
        const login_token = sgls.login_token;
        const profile_disabled_by_admin = userdetails.profile_disabled_by_admin;
        setisDisabledAction(true);
        (async () => {
            try {
                const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/profile_disabled_admin_status/" + login_token + '?getuserid=' + getUserid + '&status=' + profile_disabled_by_admin);
                setisDisabledAction(false);
                if (resData.data.status == 1) {
                    

                    if(resData.data.data.profile_status == 'enabled'){
                        setprofileActivated(true);
                        toast.success("This profile has been activated.");
                        
                    } else {
                        setprofileActivated(false);
                        toast.success("This profile has been deactivated.");
                    }

                   // getUserDetailsFun();
                    // setUserdetails((previewForm) => (
                    //     {
                    //         ...previewForm,
                    //         ['profile_disabled_by_admin']: (resData.data.data.profile_status == 'enabled' ? 0 : 1)
                    //     }
                    // ))
                    // setUserdetails((previewForm) => (
                    //     {
                    //         ...previewForm,
                    //         ['profile_enabled']: (resData.data.data.profile_status == 'enabled' ? 0 : 1)
                    //     }
                    // ))
                    isSetActionModalShow(false);
                }
            } catch (error) { console.log(error); }
        })();
    }


    
    const [isBlockActionModalShow, isSetBlockActionModalShow] = useState(false);
    const adminBlockUnBlockAction = () => {
        const sgls = JSON.parse(localStorage.getItem("squadGologinSession"));
        const login_token = sgls.login_token;
        const profile_disabled_by_admin = userdetails.profile_disabled_by_admin;
        setisDisabledAction(true);
        (async () => {
            try {
                const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/profile_block_admin_status/" + login_token + '?getuserid=' + getUserid + '&status=' + profile_disabled_by_admin);
                if (resData.data.status == 1) {
                    toast.success(resData.data.message);
                    //getUserDetailsFun(getUserid);
                    setIsProfileUnblock(resData.data.profile_status);
                    setisDisabledAction(false);
                    isSetBlockActionModalShow(false)
                    //setIsProfileBlock(resData.data.data.profile_status);
                } 
            } catch (error) { console.log(error); }
        })();

        
    }

    return (
        <>
           {/* <ToastContainer />*/}
            <div className="">
                <div className="row">
                    <div className="col-md-12">
                        {
                            isScreenLoad == true ?
                                <></>
                                :
                                <div className="row">

                                    {
                                            backTo == 'callhistory' ?
                                            <>
                                                <Link className="nav-link active" to={'/admin-call-history'}>
                                                    <span className="back_to_hist1 cursor_pointer"><i className="fa fa-chevron-left" aria-hidden="true"></i> Back to List</span>
                                                </Link>
                                            </>
                                            : backTo == 'allreviewpage' ?
                                            <>
                                                <Link className="nav-link active" to={'/admin_all_reviews'}>
                                                    <span className="back_to_hist1 cursor_pointer"><i className="fa fa-chevron-left" aria-hidden="true"></i> Back to List</span>
                                                </Link>
                                            </>
                                            : backTo == 'chathistory' ?
                                            <>
                                                <Link className="nav-link active" to={'/chat-history'}>
                                                    <span className="back_to_hist1 cursor_pointer"><i className="fa fa-chevron-left" aria-hidden="true"></i> Back to List</span>
                                                </Link>
                                            </>
                                            : backTo == 'admintickethistory' ?
                                            <>
                                                <Link className="nav-link active" to={'/admin-ticket-history'}>
                                                    <span className="back_to_hist1 cursor_pointer"><i className="fa fa-chevron-left" aria-hidden="true"></i> Back to List</span>
                                                </Link>
                                            </>
                                            : backTo == 'adminlivehistory' ?
                                            <>
                                                <Link className="nav-link active" to={'/admin-livechat-history'}>
                                                    <span className="back_to_hist1 cursor_pointer"><i className="fa fa-chevron-left" aria-hidden="true"></i> Back to List</span>
                                                </Link>
                                            </>
                                            : backTo == 'adminticallhistory' ?
                                            <>
                                                <Link className="nav-link active" to={'/admin-call-history'}>
                                                    <span className="back_to_hist1 cursor_pointer"><i className="fa fa-chevron-left" aria-hidden="true"></i> Back to List</span>
                                                </Link>
                                            </>
                                            : backTo == 'jobseekerpage' ?
                                            <>
                                                <Link className="nav-link active" to={'/jobseeker-detail?id='+jsid}>
                                                    <span className="back_to_hist1 cursor_pointer"><i className="fa fa-chevron-left" aria-hidden="true"></i> Back to List</span>
                                                </Link>
                                            </>
                                            :
                                            <>
                                                <Link className="nav-link active" to={(prop.backcall == 'jobseeker') ? '/jobseekers' : '/recruiters'}>
                                                    <span className="back_to_hist1 cursor_pointer"><i className="fa fa-chevron-left" aria-hidden="true"></i> Back to List</span>
                                                </Link>
                                            </>
                                    }

                                    <div className="col-md-6">
                                        <div className="profiletopsec d-flex">
                                            <div className="profiletopleft">
                                                <div className="profileimgsec">
                                                    <img src={profilePicUrl} />
                                                </div>
                                            </div>
                                            <div className="profiletopright">
                                                <h3 className="d-flex text-capitalize m-0">
                                                    {userdetails.first_name} {userdetails.last_name}
                                                    {
                                                        isUserVerified ?
                                                            <><img src="/front/images/varified.png" /></>
                                                            :
                                                            <><img src="/front/images/unvarified.png" /></>
                                                    }
                                                </h3>
                                                <b>#{getUserid}</b>
                                                {/* total_rating_count */}
                                                <p className="d-flex"><img src="/front/images/mail.svg" />{userdetails.email}</p>
                                                <p className="d-flex"><img src="/front/images/phone.png" />{userdetails.contact_country_code ? '+' + userdetails.contact_country_code+' ' : ''}
                                                    {userdetails.contact_number ? userdetails.contact_number : '000 000 0000'}
                                                    <span className={`text-capitalize ml-2 ${ (isUserContactVerified == 1) ? 'text-success ' : 'text-danger'}`} >({(isUserContactVerified == 1) ? 'Verified' : 'Unverified'})</span>
                                                </p>
                                                <ShowSoacialMedia data={userdetails} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 text-right">
                                        <div className="reting d-flex">
                                            <div className="stars d-flex">
                                                <StarRating rating={(typeof (userdetails.total_rating_count) != 'undefined' && userdetails.total_rating_count.average_rating != null) ? userdetails.total_rating_count.average_rating : 0} />
                                            </div>
                                            <p>{(typeof (userdetails.total_rating_count) != 'undefined' && userdetails.total_rating_count.average_rating != null) ? userdetails.total_rating_count.average_rating : 0}
                                                (<Link className='text-safron' to={`/admin_all_reviews?userid=${getUserid}&backto=userdescription`}>
                                                    {typeof (userdetails.total_rating_count) != 'undefined' ? userdetails.total_rating_count.total_count : 0} reviews
                                                </Link>
                                                )
                                            </p>
                                        </div>
                                        
                                        {/* profileActivated isProfileBlock */}
                                        <div className="disableprofilewrap adminsidetoggle" >
                                             {(profileActivated) ? 'Activated' : 'Deactivated'} 
                                            <div className="disableprofilewrapinner mr-1" data-toggle="tooltip" data-placement="top" title="Active and deactive profile">
                                                <input type="checkbox"
                                                    onChange={() => isSetActionModalShow(true)}
                                                    checked={(profileActivated == true) ? true : false} />
                                                <span className="slider round"></span>
                                            </div>
                                        
                                            <span className='ml-1'>{(isProfileUnblock == true) ? 'Unblocked' : 'Blocked'}</span> 
                                            <div className="disableprofilewrapinner"  data-toggle="tooltip" data-placement="top" title="Block and unblock profile">
                                                <input type="checkbox"
                                                    onChange={() => isSetBlockActionModalShow(true)}
                                                    checked={(isProfileUnblock == true) ? true : false} />
                                                <span className="slider round"></span>
                                            </div>
                                        </div>
                                        <div className="detailpagechat">
                                            <span className='text-safron cursor_pointer ml-2' onClick={() => setchatShcreenToggole(!chatShcreenToggole)} ><i className="fa fa-comments-o" aria-hidden="true"></i> {chatShcreenToggole ? 'Hide chat' : 'Chat Now'}  </span>
                                        </div>
                                        <p className='text-right'>Acceptance rating : {userdetails.accepting_percent}%</p>
                                    </div>
                                </div>

                        }
                    </div>
                </div>
            </div>

            {chatShcreenToggole &&
                <div className='mt-3 detailpagechats'>
                    <button type="button" className="btn-close admin_chat_window" onClick={() => setchatShcreenToggole(!chatShcreenToggole)}></button>
                    <UserChatCommunication />
                </div>
            }


            <Modal show={isActionModalShow} onHide={()=> isSetActionModalShow(false)}>
                <div className="modal-dialog1 findastaffmodle">
                    <div className="modal-content">
                        <div className="modal-body p-5">
                            <button type="button" className="btn-close" onClick={()=> isSetActionModalShow(false)}></button>
                            <h4 className="text-center mb-4">Do you want to {(profileActivated == true) ? 'deactivate' : 'active'}  this profile?</h4>
                            <div className="btnsgroup text-center">
                                <button onClick={adminUserStatusAction} className="batn batn-orange" data-bs-dismiss="modal">Yes</button>
                                <button onClick={()=> isSetActionModalShow(false)} className="batn batn-orange batn-border">No</button>
                            </div>
                            {isDisabledAction ? <><h5 className="text-center mb-4 mt-4">Loading......</h5></> : <></>}
                        </div>
                    </div>
                </div>
            </Modal>
            {/* profileActivated isProfileBlock */}
            <Modal show={isBlockActionModalShow} onHide={()=> isSetBlockActionModalShow(false)}>
                <div className="modal-dialog1 findastaffmodle">
                    <div className="modal-content">
                        <div className="modal-body p-5">
                            <button type="button" className="btn-close" onClick={()=> isSetBlockActionModalShow(false)}></button>
                            <h4 className="text-center mb-4">Do you want to {(isProfileUnblock == true) ? 'block' : 'unblock'}  this profile?</h4>
                            <div className="btnsgroup text-center">
                                <button onClick={adminBlockUnBlockAction} className="batn batn-orange" data-bs-dismiss="modal">Yes</button>
                                <button onClick={()=> isSetBlockActionModalShow(false)} className="batn batn-orange batn-border">No</button>
                            </div>
                            {isDisabledAction ? <><h5 className="text-center mb-4 mt-4">Loading......</h5></> : <></>}
                        </div>
                    </div>
                </div>
            </Modal>

        </>
    );
}
export default UserProfileBanner;