import React, { useState, useEffect, useRef, Component } from 'react';
//import { toast, ToastContainer } from 'react-toastify';
////import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router, Routes, Route, NavLink, useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import { Howl } from 'howler';
// Import the sound file
import beepSound from './soundlib/notification_sound.mp3';

const LiveChatWindow = (prop) => {

    const userData = JSON.parse(localStorage.getItem("squadGologinSession"));
    const sessionStorageData = JSON.parse(localStorage.getItem("sessionStorageData"));
    const liveChatTokenID = (localStorage.getItem("liveChatTokenID"));

    // console.log(liveChatTokenID)
    // console.log(typeof(liveChatTokenID));




    const [liveChatModule, setLiveChatModule] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [smileFaceOptions, setsmileFaceOptions] = useState(false);
    const [isCahtInit, setisCahtInit] = useState(liveChatTokenID ? true : false);
    const [chatInitStatus, setchatInitStatus] = useState(liveChatTokenID ? 2 : 1);
    const [sendMessageText, setsendMessageText] = useState('');

    console.log('liveChatModule');
    console.log(liveChatModule);

    //    / const [isCahtInit, setisCahtInit] = useState(false);

    const [ScreenLoader, setScreenLoader] = useState(false);

    //console.log(sessionStorageData.get_livesupport_optiondd)
    const jobSupprtOption = typeof (sessionStorageData.get_livesupport_option) != undefined ? sessionStorageData.get_livesupport_option : [
        {
            "id": 1,
            "key_name": "technical",
            "label": "Technical Support",
            "description": "Assistance with technical issues related to using the job portal. Help with navigating the website or app. Troubleshooting problems with account settings or features."
        },
        {
            "id": 2,
            "key_name": "account",
            "label": "Account Support",
            "description": "Help with creating or updating a user account. Assistance with login issues or password resets. Questions about account security."
        },
        {
            "id": 3,
            "key_name": "jobApplication",
            "label": "Job Application Support",
            "description": "Guidance on how to apply for jobs on the platform. Assistance with submitting resumes or other application materials. Clarification on job listing details."
        },
        {
            "id": 4,
            "key_name": "payment",
            "label": "Payment or Subscription Support",
            "description": "Help with payment-related issues for premium services or subscriptions. Assistance with billing inquiries."
        },
        {
            "id": 5,
            "key_name": "feedback",
            "label": "Feedback and Suggestions",
            "description": "A category for users to provide feedback on the platform or suggest improvements."
        },
        {
            "id": 6,
            "key_name": "abuse",
            "label": "Abuse or Violation Reporting",
            "description": "Reporting inappropriate content, behavior, or job postings."
        },
        {
            "id": 7,
            "key_name": "general",
            "label": "General Inquiry",
            "description": "A catch-all category for questions that don't fit into the above categories."
        }
    ];

    //console.log(livesupportoption);
    const sendLiveChatNotification = (supportFor) => {
        (async () => {
            try {
                const resData = await axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/livesupport_send_notification/${userData.login_token}/${supportFor}`);
                if (resData.data.status == 1) {
                    localStorage.setItem('liveChatTokenID', resData.data.data.tokenid)
                }
            } catch (error) { console.log(error); }
        })();
    }


    const getChatSuppotyType = (supportFor) => {
        setisCahtInit(true);
        setchatInitStatus(2);
        sendLiveChatNotification(supportFor);
    }


    const [wantToCloseChat, setwantToCloseChat] = useState(false);
    const [isMessageBlank, setisMessageBlank] = useState(false);
    const [attachment, setAttachment] = useState(null);
    const [capturedImage, setcapturedImage] = useState(null);
    const [getChatMessgaesData, setgetChatMessgaesData] = useState([]);

    const sendMessageToTeam = (message) => {
        if (isCahtInit == true) {
            setisMessageBlank(false);
            if (message) {
                setsmileFaceOptions(false);
                const formData = new FormData();
                formData.append('message', message);
                formData.append('login_token', userData.login_token);
                formData.append('live_chat_token', localStorage.getItem("liveChatTokenID"));
                if (attachment) { formData.append('attachment', attachment); }
                if (capturedImage) { formData.append('attachment', capturedImage); }
                axios.post('https://squadgoo.com.au/api.squadgoo.com.au/livesupport_message_send',
                    formData,
                    { headers: { 'Content-Type': 'multipart/form-data' } }
                )
                    .then((response) => {


                        if (response.status == 200 && response.data.status == 1) {
                            setchatInitStatus(3);
                            setsendMessageText('');
                            setgetChatMessgaesData(response.data.data.chatdata);
                        }

                        if (response.status == 200 && response.data.status == 2) {
                            localStorage.removeItem('liveChatTokenID');
                            setisCahtInit(false);
                            setchatInitStatus(1);
                            setErrorMessage('Please select support type!');
                            setTimeout(setErrorMessage(''), 3000);
                        }
                    })
                    .catch((error) => { console.error(error); });

            } else {
                setisMessageBlank(true);
            }

        } else {
            alert('Please select following select options')
        }
    }

    const playBeep = () => {
        const sound = new Howl({
            src: [beepSound],
        });
        sound.play();
    };

    const get_faq = (apiurl, type = 'onload') => {
        //setScreenLoader(true);
        (async () => {
            try {
                const resData = await axios.get(apiurl);
                if (resData.data.status == 1)
                    setchatInitStatus(3); {
                    setgetChatMessgaesData(resData.data.data.chatdata);

                    if (resData.data.data.total_unread != 0) {
                        playBeep();
                    }
                }
            } catch (error) { console.log(error); }
        })();
    }

    useEffect(() => {
        console.log(prop);
        if (liveChatTokenID) {
            get_faq(`https://squadgoo.com.au/api.squadgoo.com.au/livesupport_get_messages/${userData.login_token}/${liveChatTokenID}`);


            const intervalId = setInterval(() => {
                get_faq(`https://squadgoo.com.au/api.squadgoo.com.au/livesupport_get_messages/${userData.login_token}/${liveChatTokenID}`);
            }, 5000);

            return () => {
                clearInterval(intervalId);
            };
        }
    }, []);


    const setLiveChatModuleFun = (getshow) => {
        setLiveChatModule(getshow);
        if (getshow == false) {
            get_faq(`https://squadgoo.com.au/api.squadgoo.com.au/livesupport_offline/${userData.login_token}/${liveChatTokenID}`);
        }
    }


    const closeConversation = (getshow) => {
        (async () => {
            try {
                const resData = await axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/livesupport_close_conversation/${userData.login_token}/${liveChatTokenID}`);
                if (resData.data.status == 1) {
                    localStorage.removeItem("liveChatTokenID");
                    setLiveChatModule(false);
                    setsmileFaceOptions(false);
                    setisCahtInit(false);
                    setchatInitStatus(1);
                }
            } catch (error) { console.log(error); }
        })();
    }

    /*---------------component call------------------*/

    const handleInputChange = (inputText) => {
        //const inputText = event.target.value;

        // Regular expressions to match email addresses and phone numbers
        const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,6}\b/g;
        const phoneRegex = /\b\d{10}\b/g;

        // Function to replace matched text with hyperlinks
        const replaceEmailsAndPhones = (text) => {
            const textWithEmailLinks = text.replace(emailRegex, (match) => {
                return `<a href="mailto:${match}">${match}</a>`;
            });

            const textWithPhoneLinks = textWithEmailLinks.replace(phoneRegex, (match) => {
                return `<a href="tel:${match}">${match}</a>`;
            });

            return textWithPhoneLinks;
        };

        const transformedMessage = replaceEmailsAndPhones(inputText);

        return (transformedMessage);
    };
    const CheckFileType = (data) => {
        //console.log(data.data)
        return (
            <>
                {
                    data.data.extenstion == 'pdf' ?
                        <>
                            <a target='_blank' href={'https://squadgoo.com.au/api.squadgoo.com.au/' + data.data.attachment_url} >
                                <img className='chat_row_attachment' key={data.data.id} src={`/front/images/pdfimage.png`} />
                            </a>
                        </>
                        :
                        <>
                            <img className='chat_row_attachment' key={data.data.id} src={'https://squadgoo.com.au/api.squadgoo.com.au/' + data.data.attachment_url}></img>
                        </>
                }
            </>
        )
    }
    //livesupport_close_conversation
    const WantToCloseChatHtml = () => {
        return (
            <>
                <div className="card">
                    <div className="card-body text-center">
                        <p className="card-text">Do you want to close the conversation?</p>
                        <button onClick={() => closeConversation('yes')} type="button" className="btn btn-success mr-1">Yes</button>
                        <button onClick={() => sendMessageToTeam('No')} type="button" className="btn btn-danger">No</button>
                    </div>
                </div>
            </>
        )
    }

    /*--------------------------------------------------------*/
    const [isToggleAttachmentModal, setisToggleAttachmentModal] = useState(false);
    const [loadingBtn, setloadingBtn] = useState(false);
    const [sendMessageArea, setsendMessageArea] = useState('');
    const [file, setFile] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);

    const attachmentModalCloseFun = () => {
        setisToggleAttachmentModal(false);
        handleRemoveFile()
    }


    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
            setAttachment(selectedFile);

            if (selectedFile.type.startsWith('image/')) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    setImageUrl(e.target.result);
                };
                reader.readAsDataURL(selectedFile);
            } else if (selectedFile.type === 'application/pdf') {
                setImageUrl('/path-to-default-pdf-image.jpg');
            } else {
                // Handle non-image and non-PDF files as needed
            }
        }
    };
    const handleRemoveFile = () => {
        setFile(null);
        setImageUrl(null);
        setAttachment(null);
        setloadingBtn(false);
    };
    const sendMessageWithAttachemnt = () => {
        (async () => {
            try {
                setloadingBtn(true);
                const formData = new FormData();
                formData.append('message', sendMessageArea);
                if (attachment) { formData.append('attachment', attachment); }
                axios.post('https://squadgoo.com.au/api.squadgoo.com.au/chat_mesage_create', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
                    .then((response) => {
                        if (response.status == 200 && response.data.status == 1) {
                            handleRemoveFile();
                            setisToggleAttachmentModal(false)
                        }
                    })
                    .catch((error) => { console.error(error); });
            } catch (error) { console.log(error); }

        })();
    };

    return (<>
        {(userData) != null && liveChatModule &&
            <>
                <div className="livechatbox">
                    <div className="card" id="chat2">


                        {/* <div onClick={() => setLiveChatModuleFun(!liveChatModule)} className="card-header d-flex justify-content-between align-items-center p-3">
                        <h5 className="mb-0">Live Chat</h5>
                        <span><i className="fa fa-chevron-up" aria-hidden="true"></i>
                        </span>
                    </div> */}

                        <div className="livechat_message_body card-body perfect-scrollbar ps ps--active-y" data-mdb-perfect-scrollbar="true">


                            {isCahtInit == false && <div className="d-flex1 flex-row1 justify-content-start">
                                <>
                                    <h5 className='text-safron'>
                                        Hi {userData.first_name + ' ' + userData.last_name} ,<br />
                                        Which type support you looking?
                                    </h5>
                                    <div>

                                        {
                                            typeof (jobSupprtOption) != 'undefined' && jobSupprtOption.map((item, index) => (
                                                <>
                                                    <p onClick={() => getChatSuppotyType(item.id)} className="cursor_pointer small p-2 ms-0 mb-1 rounded-3 chatcolor2">{item.label}</p>
                                                </>
                                            ))
                                        }

                                        {/* <p className="small p-2 ms-0 mb-1 rounded-3 chatcolor2">How are you ...???</p> */}
                                        {/* <p className="small p-2 ms-0 mb-1 rounded-3 chatcolor2">What are you doing tomorrow? Can we come up a bar?</p> */}
                                    </div>
                                </>
                            </div>}
                            {
                                chatInitStatus == 2 &&
                                <>
                                    <p className="small p-2 ms-0 mb-1 rounded-3 chatcolor2">
                                        Hi {userData.first_name + ' ' + userData.last_name}
                                        <br />
                                        Our internal support will be contact you soon
                                    </p>
                                </>
                            }

                            {
                                chatInitStatus == 3 && getChatMessgaesData.length ?
                                    <>
                                        {
                                            getChatMessgaesData.map((item, index) => (
                                                <>
                                                    <div className="chatday text-center"><span className="line"></span><span>{item.daydate}</span><span className="line"></span></div>
                                                    {
                                                        item.data.map((itemdata, indexdata) => (
                                                            <>
                                                                <div key={itemdata.id} className={`chatlinw  ${itemdata.send_by} ${item.sender_id != itemdata.send_by ? 'client' : ''}`}>
                                                                    {
                                                                        itemdata.delete_status == 1 ?
                                                                            <>
                                                                                <div className={`chatmsg mt-1 ${item.sender_id != itemdata.send_by ? 'text-white' : 'text-1'}`}>
                                                                                    <i>This message deleted by you on {itemdata.delete_status_date}</i>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                {/* <div className="chattime">{itemdata.time}</div> */}

                                                                                {

                                                                                    itemdata.send_by != itemdata.sender_id
                                                                                }
                                                                                <div className="chatmsg mb-1">
                                                                                    <div className='message_contiainer'>
                                                                                        {
                                                                                            (itemdata.attachment && itemdata.attachment.length) ?
                                                                                                <>
                                                                                                    {
                                                                                                        itemdata.attachment.map((itemattach, indexattach) => (
                                                                                                            <>
                                                                                                                <CheckFileType data={itemattach} ></CheckFileType>
                                                                                                            </>
                                                                                                        ))
                                                                                                    }
                                                                                                </>
                                                                                                :
                                                                                                <></>
                                                                                        }
                                                                                        {
                                                                                            itemdata.message == 'do_you_want_to_close_conversation' ?
                                                                                                <>
                                                                                                    <WantToCloseChatHtml />
                                                                                                </>
                                                                                                :
                                                                                                <div className={`${item.sender_id != itemdata.send_by ? 'text-1' : 'text-white'}`} dangerouslySetInnerHTML={{ __html: handleInputChange(itemdata.message).replace(/\n/g, '<br>') }} />
                                                                                        }

                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                    }
                                                                </div>
                                                            </>
                                                        ))
                                                    }

                                                </>
                                            ))
                                        }
                                    </>
                                    :
                                    <>
                                    </>
                            }



                            {smileFaceOptions && <div className="facecard">
                                <div className="faceratings">
                                    <Link onClick={(e) => sendMessageToTeam('<img src="/front/images/face1.png" />')} href="#"><img src="/front/images/face1.png" /></Link>
                                    <Link onClick={(e) => sendMessageToTeam('<img src="/front/images/face2.png" />')} href="#"><img src="/front/images/face2.png" /></Link>
                                    <Link onClick={(e) => sendMessageToTeam('<img src="/front/images/face3.png" />')} href="#"><img src="/front/images/face3.png" /></Link>
                                    <Link onClick={(e) => sendMessageToTeam('<img src="/front/images/face4.png" />')} href="#"><img src="/front/images/face4.png" /></Link>
                                    <Link onClick={(e) => sendMessageToTeam('<img src="/front/images/face5.png" />')} href="#"><img src="/front/images/face5.png" /></Link>
                                </div>
                            </div>}

                        </div>

                        {/* front/images/user.svg */}
                        <div className="card-footer text-muted d-flex justify-content-start align-items-center p-3">
                            <img src={userData.profilepic == null ? '/front/images/user.svg' : 'https://squadgoo.com.au/api.squadgoo.com.au/' + userData.profilepic} alt="avatar 3" className="chatavatar" />
                            <input onChange={(e) => setsendMessageText(e.target.value)} type="text" value={sendMessageText} className={`${(isMessageBlank == true) ? 'livechaterror_border' : ''} form-control form-control-lg`} id="exampleFormControlInput1" placeholder="Type message" fdprocessedid="jq0r4b" />
                            {
                                isCahtInit &&
                                <>
                                    {/* <span onClick={() => setsmileFaceOptions(!smileFaceOptions)} className="cursor_pointer ms-3 text-muted" href="#!"><i className="fa fa-paperclip" aria-hidden="true"></i></span> */}
                                    <span onClick={() => setsmileFaceOptions(!smileFaceOptions)} className="cursor_pointer ms-3 text-muted" href="#!"><i className="fa fa-smile-o fontstyle" aria-hidden="true"></i></span>
                                    <span className='cursor_pointer ms-3 text-muted' onClick={() => setisToggleAttachmentModal(true)} >
                                        <i class="fa fa-paperclip fontstyle"></i>
                                        {/* <img src="/front/images/attachment.svg" /> */}
                                    </span>

                                </>
                            }

                            <span className="ml-1 cursor_pointer" onClick={() => sendMessageToTeam(sendMessageText)} ><i className="fa fa-paper-plane" aria-hidden="true"></i></span>


                        </div>
                        {
                            errorMessage && <p className='livechaterror'>{errorMessage}</p>
                        }
                    </div>
                </div>

                <Modal show={isToggleAttachmentModal} onHide={() => attachmentModalCloseFun(0)}>
                    <div className="modal-dialog1 findastaffmodle">
                        <div className="modal-content">
                            <div className="modal-body p-3">
                                <button type="button" className="btn-close" onClick={() => attachmentModalCloseFun(0)}></button>
                                {/* {cllingDeleteType} */}
                                <h4 className="text-center mb-4">
                                    <div>
                                        {(file && imageUrl) ? (
                                            <div>
                                                <button onClick={handleRemoveFile} className="batn batn-orange batn-border modal_back" >
                                                    <i className="fa fa-arrow-circle-left" aria-hidden="true"></i>
                                                </button>
                                                {file.type.startsWith('image/') ? (
                                                    <div>
                                                        <img src={imageUrl} alt="Selected file" style={{ maxWidth: '100%' }} />
                                                    </div>
                                                ) : file.type === 'application/pdf' ? (
                                                    <div>
                                                        <img src={`/front/images/pdfimage.png`} alt="PDF File" style={{ maxWidth: '100%' }} />
                                                    </div>
                                                ) : (
                                                    <div>
                                                        File type: {file.type}
                                                    </div>
                                                )}

                                                {
                                                    !loadingBtn ?
                                                        <>
                                                            <div className='cahtfooterouter p-0 pt-2'>
                                                                <div className='cahtfooter_modal d-flex align-items-center justify-content-between'>
                                                                    <textarea type="text" onChange={(e) => setsendMessageArea(e.target.value)} placeholder="Type something..." className="chat_textarea chat_textarea_modal border " spellcheck="false"></textarea>
                                                                    <Link onClick={sendMessageWithAttachemnt}><img src="/front/images/send.svg" /></Link>
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        <><p className='text-center'>Please wait attachment is uploading to server.....</p></>}

                                            </div>
                                        ) :
                                            <>
                                                <div className="form-group identityproof stepscont">
                                                    <div className="customuploadsec">
                                                        <input
                                                            type="file"
                                                            accept=".pdf, .jpg, .jpeg, .png"
                                                            onChange={handleFileChange}
                                                            id="fileInput"
                                                        />
                                                        <div className="customuploadinner text-center">
                                                            <div className="my-4"><img src="/front/images/uploadicon.png" className="mt-4" /></div>
                                                            <p className="mb-0">Click here to upload a file</p>

                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </h4>

                            </div>
                        </div>
                    </div>
                </Modal>
            </>
        }
    </>)

};



const LiveChatWindow2 = (prop) => {
    const [liveChatModule, setLiveChatModule] = useState(prop.livechataction);
    console.log(prop.livechataction)
    return (<>
        {liveChatModule &&
            <div className="livechatbox">
                <div className="card" id="chat2">


                    <div onClick={() => setLiveChatModule(!liveChatModule)} className="card-header d-flex justify-content-between align-items-center p-3">
                        <h5 className="mb-0">Live Chat</h5>
                        <span><i className="fa fa-chevron-up" aria-hidden="true"></i>
                        </span>
                    </div>

                    <div className="card-body perfect-scrollbar ps ps--active-y" data-mdb-perfect-scrollbar="true">
                        <div className="d-flex flex-row justify-content-start">
                            <img src="/front/images/profile.png" alt="avatar 1" className="chatavatar" />
                            <div>
                                <p className="small p-2 ms-3 mb-1 rounded-3 chatcolor2">Hi</p>
                                <p className="small p-2 ms-3 mb-1 rounded-3 chatcolor2">How are you ...???</p>
                                <p className="small p-2 ms-3 mb-1 rounded-3 chatcolor2">What are you doing tomorrow? Can we come up a bar?</p>
                                <p className="small ms-3 mb-3 rounded-3 text-muted">23:58</p>
                            </div>
                        </div>
                        <div className="divider d-flex align-items-center mb-4">
                            <p className="text-center mx-3 mb-0">Today</p>
                        </div>
                        <div className="d-flex flex-row justify-content-end mb-4 pt-1">
                            <div>
                                <p className="small p-2 me-3 mb-1 text-white rounded-3 bg-primary">Hiii, I'm good.</p>
                                <p className="small p-2 me-3 mb-1 text-white rounded-3 bg-primary">How are you doing?</p>
                                <p className="small p-2 me-3 mb-1 text-white rounded-3 bg-primary">Long time no see! Tomorrow office. will be free on sunday.</p>
                                <p className="small me-3 mb-3 rounded-3 text-muted d-flex justify-content-end">00:06</p>
                            </div>
                            <img src="/front/images/teamimg.jpg" alt="avatar 1" className="chatavatar" />
                        </div>
                        <div className="d-flex flex-row justify-content-start mb-4">
                            <img src="/front/images/profile.png" alt="avatar 1" className="chatavatar" />
                            <div>
                                <p className="small p-2 ms-3 mb-1 rounded-3 chatcolor2">Okay</p>
                                <p className="small p-2 ms-3 mb-1 rounded-3 chatcolor2">We will go on Sunday?</p>
                                <p className="small ms-3 mb-3 rounded-3 text-muted">00:07</p>
                            </div>
                        </div>
                        <div className="d-flex flex-row justify-content-end mb-4">
                            <div>
                                <p className="small p-2 me-3 mb-1 text-white rounded-3 bg-primary">That's awesome!</p>
                                <p className="small p-2 me-3 mb-1 text-white rounded-3 bg-primary">I will meet you Sandon Square sharp at 10 AM</p>
                                <p className="small p-2 me-3 mb-1 text-white rounded-3 bg-primary">Is that okay?</p>
                                <p className="small me-3 mb-3 rounded-3 text-muted d-flex justify-content-end">00:09</p>
                            </div>
                            <img src="/front/images/teamimg.jpg" alt="avatar 1" className="chatavatar" />
                        </div>
                        <div className="d-flex flex-row justify-content-start mb-4">
                            <img src="/front/images/profile.png" alt="avatar 1" className="chatavatar" />
                            <div>
                                <p className="small p-2 ms-3 mb-1 rounded-3 chatcolor2">Okay i will meet you on Sandon Square</p>
                                <p className="small ms-3 mb-3 rounded-3 text-muted">00:11</p>
                            </div>
                        </div>
                        <div className="d-flex flex-row justify-content-end mb-4">
                            <div>
                                <p className="small p-2 me-3 mb-1 text-white rounded-3 bg-primary">Do you have pictures of Matley Marriage?</p>
                                <p className="small me-3 mb-3 rounded-3 text-muted d-flex justify-content-end">00:11</p>
                            </div>
                            <img src="/front/images/teamimg.jpg" alt="avatar 1" className="chatavatar" />
                        </div>
                        <div className="d-flex flex-row justify-content-start mb-4">
                            <img src="/front/images/profile.png" alt="avatar 1" className="chatavatar" />
                            <div>
                                <p className="small p-2 ms-3 mb-1 rounded-3 chatcolor2">Sorry I don't have. i changed my phone.</p>
                                <p className="small ms-3 mb-3 rounded-3 text-muted">00:13</p>
                            </div>
                        </div>
                        <div className="d-flex flex-row justify-content-end">
                            <div>
                                <p className="small p-2 me-3 mb-1 text-white rounded-3 bg-primary">Okay then see you on sunday!!</p>
                                <p className="small me-3 mb-3 rounded-3 text-muted d-flex justify-content-end">00:15</p>
                            </div>
                            <img src="/front/images/teamimg.jpg" alt="avatar 1" className="chatavatar" />
                        </div>

                        <div className="facecard">
                            <div className="heading">
                                <h3>Help Us Improve Your Experience!</h3>
                                <p>Please Rate Your Satisfaction with Our Service</p>
                            </div>
                            <div className="faceratings">
                                <a href="#"><img src="/front/images/face1.png" /></a>
                                <a href="#"><img src="/front/images/face2.png" /></a>
                                <a href="#"><img src="/front/images/face3.png" /></a>
                                <a href="#"><img src="/front/images/face4.png" /></a>
                                <a href="#"><img src="/front/images/face5.png" /></a>

                            </div>
                        </div>


                    </div>
                    <div className="card-footer text-muted d-flex justify-content-start align-items-center p-3"><img src="/front/images/profile.png" alt="avatar 3" className="chatavatar" /><input type="text" className="form-control form-control-lg" id="exampleFormControlInput1" placeholder="Type message" fdprocessedid="jq0r4b" /><a className="ms-3 text-muted" href="#!"><i className="fa fa-paperclip" aria-hidden="true"></i>
                    </a><a className="ms-3 text-muted" href="#!"><i className="fa fa-smile-o" aria-hidden="true"></i>
                        </a><a className="ms-3" href="#!"><i className="fa fa-paper-plane" aria-hidden="true"></i>
                        </a>
                    </div>
                </div>
            </div>
        }
    </>)

};
export default LiveChatWindow;
