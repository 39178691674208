import { configureStore } from '@reduxjs/toolkit';
import counterReducer from './reducres/counterSlice';
import fsmodelActionButton from './reducres/findStaffBtnAction';
import checkDocumentstatus from './reducres/checkDocumentstatus';
import adminSideVerificationStatus from './reducres/adminSideVerificationStatus';
import userDataArrayValue from './reducres/userDataRegister';

export default configureStore({
  reducer: {
    counter: counterReducer,
    fsmodelaction: fsmodelActionButton,
    check_doc_status: checkDocumentstatus,
    adminSideVerificationStatus: adminSideVerificationStatus,
    userDataArrayValue: userDataArrayValue,
  },
})