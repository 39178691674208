import Wizard from 'react-simple-step-wizard';
import React, { useState ,useEffect , Component} from 'react';
import { BrowserRouter as Router, Routes, Route ,  NavLink , useParams ,useNavigate  }  from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';





const Step1 = () => {
   
   return (<div>
      <div className="stepscont">
           <div className="row">
               <div className="col-md-12 stepscontright">
               <form className="commonform">
                  <h3>Upload your profile photo</h3>
                  
                      <div className="text-center form-group mb-3 mt-3">
                      <div className="profilephotodiv">
                           <img src="/front/images/user.svg"></img>
                           
                           </div>
                        </div> 
                        <div className="mb-3">
                          <p className="mb-0"><strong>Photo guidelines:</strong></p> 
                          <p className="mb-0">1. Show your whole face and tops of your shoulders</p> 
                          <p className="mb-0">2. Take your sunglasses and hat off.</p> 
                           <p className="mb-0">3. No photos of a photo, filters or alterations.</p>
                        </div>
                     
                      <div className="form-group incometexdoc mb-3">
                          
                          <div className="customuploadsec">
                             <input type="file"   ></input>
                             <div className="customuploadinner text-center">
                                <div className="my-4"><img src="/front/images/uploadicon.png" className="mt-4"></img></div>
                                <p className="mb-0">drag and drop here</p>
                             </div>
                          </div>
                          <p className="mt-2 purpleclr font12">jpg, png, pdf (Max file size 5 MB)</p>
                      </div>
                      
                  </form>
               </div>
           </div>
      </div>
  </div>);
};


const Step2 = () => {

   
   return  (<div>
      <div className="stepscont">
           <div className="row">
               <div className="col-md-12 stepscontright">
               <form className="commonform">
                  <h4 className="sucessmessage-steps d-flex align-items-center"><img src="/front/images/greencheck2.svg"></img> Profile photo uploaded successfully!</h4>
                  
                      <div className="text-center form-group mb-3 mt-3">
                      <div className="profilephotodiv">
                           <img src="/front/images/profile.png"></img>
                           <span className="iconfileupload editprofileicon">
                             <img src="/front/images/profileediticon.svg"></img>
                             <input type="file"  ></input>
                           </span>
                           </div>
                        </div> 
                        <div className="mb-3">
                          <p className="mb-0"><strong>Photo guidelines:</strong></p> 
                          <p className="mb-0">1. Show your whole face and tops of your shoulders</p> 
                          <p className="mb-0">2. Take your sunglasses and hat off.</p> 
                           <p className="mb-0">3. No photos of a photo, filters or alterations.</p>
                        </div>
                     
                     
                      
                  </form>
               </div>
           </div>
      </div>


      
   </div>);
};

const Step3 = () => {

   return  (<div>
      <div className="stepscont">
         <div className="row">
             <div className="col-md-12 stepscontright">
             <form className="commonform">
                    <h3 className="step-backbtnwrap d-flex align-items-center"> <span className="stepbackarrow mr-1" ><img src="/front/images/stepbackarrow.svg"></img></span>
 Are you a company or individual?</h3>
                    <div className="row">
                    <div className="col-md-6">
                        <div className="form-group taxtype mb-2">
                           <div className="switch-field square-switch-field mr-0 ">
                              <div className="switchfieldinner"><input type="radio" id="company" name="recruitertype"/><label htmlFor="company">Company</label></div>
                              <div className="switchfieldinner"><input type="radio" id="individual" name="recruitertype"/><label htmlFor="individual">Individual</label></div>
                           </div>
                        </div>
                     </div>
                    <div className="col-md-12"><h4>Address</h4></div>
                        <div className="col-md-6">
                        <div className="form-group mb-2">
                              <label className="form-label">Salute</label>
                              <select className="form-control">
                                 <option>Mr.</option>
                                 <option>Miss.</option>
                              </select>
                           </div>
                        </div>
                        <div className="col-md-6">
                        <div className="form-group mb-2">
                              <label className="form-label">First name</label>
                              <input type="text" className="form-control" placeholder="Andrew"/>
                           </div>
                        </div>
                        <div className="col-md-6">
                        <div className="form-group mb-2">
                              <label className="form-label">Middle name</label>
                              <input type="text" className="form-control" placeholder="Enter middle name"/>
                           </div>
                        </div>
                        <div className="col-md-6">
                        <div className="form-group mb-2">
                              <label className="form-label">Last name</label>
                              <input type="text" className="form-control" placeholder="Enderson"/>
                           </div>
                        </div>
                     
                        <div className="col-md-12 mb-1">
                        <div className="form-group mb-2">
                              <label className="form-label">Upload your identity proof</label>
                              <div className="form-group identityproof">
                        
                                    <div className="customuploadsec">
                                       <input type="file"></input>
                                       <div className="customuploadinner text-center">
                                          <div className="my-4"><img src="/front/images/uploadicon.png" className="mt-4"></img></div>
                                          <p className="mb-0">drag and drop here</p>
                                       </div>
                                    </div>
                                    <p className="mt-2 purpleclr font12">jpg, png, pdf (Max file size 5 MB)</p>
                              </div>
                           </div>
                        </div>
                        <div className="col-md-12"><h4>Personal Address</h4></div>
                        <div className="col-md-6">
                            <div className="d-flex">
                                 <div className="form-group mb-2 mr-2">
                                    <label className="form-label">Unit</label>
                                    <input type="text" className="form-control" placeholder="Unit"/>  
                                 </div>
                                 <div className="form-group mb-2">
                                    <label className="form-label">Building no</label>
                                    <input type="text" className="form-control" placeholder="Building no"/>  
                                 </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                        <div className="form-group mb-2">
                                    <label className="form-label">Street name</label>
                                    <input type="text" className="form-control" placeholder="Enter Street name"/>  
                                 </div>
                        </div>

                        <div className="col-md-6 mb-3">
                        <div className="form-group mb-2">
                              <label className="form-label">Suburbs</label>
                              <input type="text" className="form-control" placeholder="Enter suburbs"/>
                           </div>
                        </div>
                        <div className="col-md-6 mb-3">
                        <div className="form-group mb-2">
                                 <label className="form-label">State</label>
                                    <select className="form-control mr-2">
                                       <option>Select stare</option>
                                    </select>
                           </div>
                        </div>

                        <div className="col-md-6">
                        <div className="form-group mb-2 graybox">
                                 <label className="form-label">Document for address proof</label>
                                 <div className="commonchecbox d-flex"><span className="agrrimentbox"><input type="checkbox" required=""/><span className="checkafter"></span></span><label className="checklabel">License</label></div>
                                 <div className="commonchecbox d-flex"><span className="agrrimentbox"><input type="checkbox" required=""/><span className="checkafter"></span></span><label className="checklabel">Passport</label></div>
                                 <div className="commonchecbox d-flex"><span className="agrrimentbox"><input type="checkbox" required=""/><span className="checkafter"></span></span><label className="checklabel">Electricity</label></div>
                                 <div className="commonchecbox d-flex"><span className="agrrimentbox"><input type="checkbox" required=""/><span className="checkafter"></span></span><label className="checklabel">Pension card</label></div>
                           </div>
                        </div>

                        <div className="col-md-6">
                        <div className="form-group mb-2">
                        <div className="form-group addressproofdoc mb-3">
                          
                          <div className="customuploadsec">
                             <input type="file"   ></input>
                                 <div className="customuploadinner text-center">
                                    <div className="my-4"><img src="/front/images/uploadicon.png" className="mt-4"></img></div>
                                    <p className="mb-0">drag and drop here</p>
                                 </div>
                              </div>
                              <p className="mt-2 purpleclr font12">jpg, pdf (Max file size 5 MB)</p>
                           </div>
                           </div>
                        </div>

                     
                    </div>
                   
                    

                    
                    
                </form>
             </div>
         </div>
      </div>


      
   </div>);
};

const Step4 = () => <div>


<div className="stepscont">
         <div className="row">
             <div className="col-md-12 stepscontright">
             <form className="commonform">
                    <h3 className="step-backbtnwrap d-flex align-items-center"> <span className="stepbackarrow mr-1" ><img src="/front/images/stepbackarrow.svg"></img></span>
 Company Details</h3>
                    <div className="row">
                    
                        <div className="col-md-6">
                        <div className="form-group mb-2">
                              <label className="form-label">Company name</label>
                              <input type="text" className="form-control" placeholder="Enter company name"/>
                           </div>
                        </div>
                        <div className="col-md-6">
                        <div className="form-group mb-2">
                              <label className="form-label">Company website </label>
                              <input type="text" className="form-control" placeholder="Enter companyn website"/>
                           </div>
                        </div>
                        <div className="col-md-6">
                        <div className="form-group mb-2">
                              <label className="form-label">Company size</label>        
                              <select className="form-control">
                                 <option>Select company size</option>
                              </select>
                           </div>
                        </div>
                        <div className="col-md-6">
                        <div className="form-group mb-2">
                              <label className="form-label">Industry</label>        
                              <select className="form-control">
                                 <option>Select industry</option>
                              </select>
                           </div>
                        </div>
                     
                        <div className="col-md-12 mb-1">
                        <div className="form-group mb-2">
                              <label className="form-label">Upload registration document</label>
                              <div className="form-group identityproof">
                        
                                    <div className="customuploadsec">
                                       <input type="file"></input>
                                       <div className="customuploadinner text-center">
                                          <div className="my-4"><img src="/front/images/uploadicon.png" className="mt-4"></img></div>
                                          <p className="mb-0">drag and drop here</p>
                                       </div>
                                    </div>
                                    <p className="mt-2 purpleclr font12">jpg, png, pdf (Max file size 5 MB)</p>
                              </div>
                           </div>
                        </div>
                        <div className="col-md-12"><h4>Company Address</h4></div>
                        <div className="col-md-6">
                            <div className="d-flex">
                                 <div className="form-group mb-2 mr-2">
                                    <label className="form-label">Unit</label>
                                    <input type="text" className="form-control" placeholder="Unit"/>  
                                 </div>
                                 <div className="form-group mb-2">
                                    <label className="form-label">Building no</label>
                                    <input type="text" className="form-control" placeholder="Building no"/>  
                                 </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                        <div className="form-group mb-2">
                                    <label className="form-label">Street name</label>
                                    <input type="text" className="form-control" placeholder="Enter Street name"/>  
                                 </div>
                        </div>

                        <div className="col-md-6 mb-3">
                        <div className="form-group mb-2">
                              <label className="form-label">Suburbs</label>
                              <input type="text" className="form-control" placeholder="Enter suburbs"/>
                           </div>
                        </div>
                        <div className="col-md-6 mb-3">
                        <div className="form-group mb-2">
                                 <label className="form-label">State</label>
                                    <select className="form-control mr-2">
                                       <option>Select stare</option>
                                    </select>
                           </div>
                        </div>

                        <div className="col-md-6">
                        <div className="form-group mb-2 graybox">
                                 <label className="form-label">Document for address proof</label>
                                 <div className="commonchecbox d-flex"><span className="agrrimentbox"><input type="checkbox" required=""/><span className="checkafter"></span></span><label className="checklabel">License</label></div>
                                 <div className="commonchecbox d-flex"><span className="agrrimentbox"><input type="checkbox" required=""/><span className="checkafter"></span></span><label className="checklabel">Passport</label></div>
                                 <div className="commonchecbox d-flex"><span className="agrrimentbox"><input type="checkbox" required=""/><span className="checkafter"></span></span><label className="checklabel">Electricity</label></div>
                                 <div className="commonchecbox d-flex"><span className="agrrimentbox"><input type="checkbox" required=""/><span className="checkafter"></span></span><label className="checklabel">Pension card</label></div>
                           </div>
                        </div>

                        <div className="col-md-6">
                        <div className="form-group mb-2">
                        <div className="form-group addressproofdoc mb-3">
                          
                          <div className="customuploadsec">
                             <input type="file"   ></input>
                                 <div className="customuploadinner text-center">
                                    <div className="my-4"><img src="/front/images/uploadicon.png" className="mt-4"></img></div>
                                    <p className="mb-0">drag and drop here</p>
                                 </div>
                              </div>
                              <p className="mt-2 purpleclr font12">jpg, pdf (Max file size 5 MB)</p>
                           </div>
                           </div>
                        </div>

                     
                    </div>
                   
                    

                    
                    
                </form>
             </div>
         </div>
      </div>

 
    </div>;

const Step5 = () => {
   return (<div>
           
<div className="stepscont">
         <div className="row">
            
         <div className="col-md-12 stepscontright">
             <form className="commonform">
                    <h3 className="step-backbtnwrap d-flex align-items-center"> <span className="stepbackarrow mr-1" ><img src="/front/images/stepbackarrow.svg"></img></span>
                    Contact Details</h3>
                    <div className="row">
                       
                        <div className="col-md-6">
                        <div className="form-group mb-2">
                              <label className="form-label">E-mail</label>
                              <input type="text" className="form-control" placeholder="andrew_enderson@gmail.com"/>
                           </div>
                        </div>
                        <div className="col-md-6 ">
                        <div className="form-group mb-2">
                            <label className="form-label">Phone number</label>
                             <div className="phonefield d-flex align-items-center">
                                  <select className="form-control">
                                     <option>+62</option>
                                     <option>+92</option>
                                  </select>
                                  <input type="tel" className="form-control" placeholder="Enter mobile number"/>
                             </div>
                             <div className="d-flex justify-content-end"><a className="linktxt">Verify mobile number</a></div>
                              
                           </div>
                        </div>
                        
                        <div className="col-md-12"><h3 className="mb-2">Tax Information</h3></div>
                        <div className="col-md-6">
                        <div className="form-group taxtype mb-2">
                         <label className="form-label">Tax type</label>
                        <div className="switch-field square-switch-field mr-0 ">
                           <div className="switchfieldinner"><input type="radio" id="abn" name="taxinfo" /><label htmlFor="abn">ABN</label></div>
                           <div className="switchfieldinner"><input type="radio" id="tfn" name="taxinfo" /><label htmlFor="tfn">TFN</label></div>
                        </div>
                        </div>
                        </div>
                        <div className="col-md-6">
                        <div className="form-group mb-2">
                                    <label className="form-label">ABN/TFN</label>
                                    <input type="text" className="form-control" placeholder="Enter ABN/TFN"/>  
                                 </div>
                        </div>

                        <div className="col-md-12 mb-3">
                        <div className="form-group mb-2">
                        <label className="form-label">Upload tax proof</label>
                          <div className="customuploadsec"><input type="file"/><div className="customuploadinner text-center"><div className="my-4"><img src="/front/images/uploadicon.png" className="mt-4"/></div><p className="mb-0">drag and drop here</p></div></div>
                          <p className="mt-2 purpleclr font12">jpg, png, pdf (Max file size 5 MB)</p>
                           </div>
                        </div>
                        <div className="col-md-12 mb-3">
                        <div className="form-group mb-2">
                        <div className="commonchecbox d-flex"><span className="agrrimentbox"><input type="checkbox"/><span className="checkafter"></span></span><label className="checklabel">I accept <a href="#" className="linktxt">Privacy policy</a> and <a href="#" className="linktxt">Terms & conditions</a> agreement.</label></div>
                        </div>
                        </div>
                    </div>

                </form>
             </div>
         </div>
    </div>











    </div>);
}





const MyStepTracker = ({ currentStep = 0, steps = [] }) => (
  <div>
    <p>Current step is: {steps[currentStep]}</p>
  </div>
);
const MyNavigator = ({
  getFirstStepProps,
  getLastStepProps,
  getNextStepProps,
  getPrevStepProps,
}) => (
  <div>
    <div className="btnsgroup">
      
   <div className="text-center">
      <button className="batn batn-orange w-100" type="button" {...getNextStepProps()}>
        Upload
      </button>
   </div>

   <div className="text-center">
      <button className="batn batn-purple w-100" type="button" {...getPrevStepProps()}>
      Back
      </button>
   </div>

      <div className="text-center  mt-2">
      <button className="btn-link" type="button" {...getNextStepProps()}>
        Skip this step
      </button>
      </div>
     </div>
   
  </div>
);
 
class FindaStaff extends Component {
  state = { isCustomizeVisible: true };

   constructor(props) {
      super(props);
      this.state = {
            fields: {
               full_name : ((props.loginscreen == 'profile_update') ? props.logindata.data.firstname :  '') ,
            } ,
            errors : {} ,
            loading : false ,
            signupId : false ,
            stateListFetchData : [] 
      };
   }

   componentDidMount() {
      const queryParameters = new URLSearchParams(window.location.search)
      const userid = queryParameters.get("id");
      if(userid){
         this.setState({signupId: userid});
         (async () => {
            try {
               const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/userdetails/"+userid);
               if(resData.data.status == 1){
                  this.setState({userdetails: resData.data.data.results});
                  localStorage.setItem('signup_userdetails',  JSON.stringify(resData.data.data.results) );
               }
            } catch (error) { console.log(error); }
            
            try {
               const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/get_all_states");
               if(resData.data.status == 1){
                  localStorage.setItem('get_all_states',  JSON.stringify(resData.data.data.results) );
                  localStorage.setItem('get_all_city',  []);
               }
            } catch (error) { console.log(error); }

         })();

      } else { 
         // setTimeout(function(){
         //    window.location.replace("/login");
         // },100)
      }
   }   
 
   
   handleStepChange = currentStep => {
      console.log(currentStep);
   };
 
   onClick = () => {
      this.setState(prevState => ({
         isCustomizeVisible: !prevState.isCustomizeVisible,
      }));
   };

   handleFileChange = (event) => {
      alert()
      // this.setState({ selectedFile: event.target.files[0] });
   };
 
  render() {
    const { isCustomizeVisible } = this.state;
    return (
      <div className="accountsetupwrap dashboarwrap">
         <div className="accountsetupinner">
         <Wizard onStepChange={this.handleStepChange}>
            <Wizard.StepTracker />
            <Wizard.Steps>
               <Step1 stepLabel="Tax info" />
               <Step2 stepLabel="Work experience" />
               <Step3 stepLabel="Availability"/>
               <Step4 stepLabel="Location" />
               <Step5 stepLabel="Salary" />
               
               
            </Wizard.Steps>
            {/* You can implement your custom components via render-props */}
            <Wizard.StepTracker>
               {stepTrackerProps => <MyStepTracker {...stepTrackerProps} />}
            </Wizard.StepTracker>
            <Wizard.Navigator>
               {navigatorProps => <MyNavigator {...navigatorProps} />}
            </Wizard.Navigator>
         </Wizard>

         </div>

         <div className="modal fade modalmedium phone-varificationmodle" id="phone-varificationmodle" tabIndex="-1" aria-labelledby="phone-varificationmodleLabel" aria-hidden="true">
            <div className="modal-dialog">
               <div className="modal-content">
                  <div className="modal-body loginsignupwrapformwrap">
                     <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        <Form className="commonform lofinsignupform loginform">
                           <div className="formbranding text-center"><img src="/front/images/formlogo.png"/></div>
                           <div className="text-center my-4"><img src="/front/images/varifyicon.png"/></div>
                           <h2 className="text-center mb-2">Varify your phone number</h2>
                           <div className="text-center formbtmtxt mb-4">
                                 <p>We have sent a code on your Phone number</p>
                           </div>
                           <div className="form-group mb-2">
                              <input 
                                 required
                                 type="text"
                                 className="form-control varificationfield" 
                              />
                           </div>
                           <div className="form-group mb-2">
                                 <NavLink className="">Resend verification code</NavLink>
                           </div>
                           
                           <div className="form-group mb-2"><input type="button" className="batn batn-orange" value="Varify" data-bs-toggle="modal" data-bs-target="#phonvarifiedpop" data-bs-dismiss="modal"/></div>
                           <div className="text-center formbtmtxt">
                                 <p>Already have an account?   
                                 <NavLink className="" to="/login">Log In</NavLink>
                                 </p>
                           </div>
                           </Form>

                  </div>
               </div>
            </div>
         </div>

         <div className="modal fade modalmedium phonvarifiedpop" id="phonvarifiedpop" tabIndex="-1" aria-labelledby="phonvarifiedpopLabel" aria-hidden="true">
            <div className="modal-dialog">
               <div className="modal-content">
                  <div className="modal-body loginsignupwrapformwrap">
               
                        <form className="commonform lofinsignupform loginform">
                           <div className="text-center my-5"><img src="/front/images/varified.png"/></div>
                           <h2 className="text-center mb-2">Congratulation!!</h2>
                           <div className="text-center formbtmtxt mb-4">
                                 <p>Your phone number has been verified successfully</p>
                           </div>
                           
                           <div className="form-group mb-2"><input type="Submit" className="batn batn-orange" value="Continue with your profile"/></div>
                           
                           </form>
                  
                  </div>
               </div>
            </div>
         </div>

      </div>

    );
  }
}
 
export default FindaStaff;