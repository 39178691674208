import React from 'react';

const StarRating = ({ rating }) => {
    const stars = [1, 2, 3, 4, 5];
    return (
        <>
            {stars.map((star) => (
                <div key={star} className={`star ${star <= rating ? 'filled' : 'empty'}`}>
                    <img src={star <= rating ? 'front/images/fillstar.svg' : 'front/images/starredopen.png'} />
                </div>
            ))}
        </>
    );
};
export default StarRating;