import React, { useState, useEffect, useCallback } from 'react';
//import { BrowserRouter as Router, Routes, Route, NavLink, useParams, useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
//import ProfileBanner from '../profile_header';
import ProfileSidebar from '../profile_sidebar';
// import ContactFormComp from '../ContactDetailsComp';

// import { ToastContainer, toast } from 'react-toastify';
// //import 'react-toastify/dist/ReactToastify.css';
// import { useSelector, useDispatch } from 'react-redux'
// import { basicDetailsValidationCheck } from '../../redux_state/reducres/checkDocumentstatus';

// import CheckValidationComp from '../CheckValidationComp';

import RecWalletWithdraw from '../recruiter/recWalletWithdraw';

import TransectionTime from '../../json/transction_time.json';
import TransectionData from '../../json/transection_data.json';
//import RecWalletWithdraw from './recWalletWithdraw';
import ReferEarnComponent from '../ReferEarnComponent';
import RecWalletHist from '../WalletHistoryCom';

const JobseekerWallet = (prop) => {

   const useriddata = JSON.parse(localStorage.getItem("squadGologinSession"));
   const [jsWalletData, setJsWalletData] = useState(
      {
         total_performance_coins: 0,
         total_compensation_coins: 0,
         total_reffered_coins: 0,
         total_withdraw_coins: 0,
         total_current_available_coins: 0,
         transection_history: [],
      }
   );


   //const [parentData, setParentTotalCoinsData] = useState('....');

   // const [isCheckOutPageShow, setIsCheckOutPageShow] = useState((prop.callingby == 'coinpurchase') ?  true :  false);
   // const receiveMessageFromChild = (toggle) => { setIsCheckOutPageShow(toggle); };

   const handleChildDataChange = useCallback((newData) => { setJsWalletData(newData); }, []);
   const [withDrawformShow, setWithDrawformShow] = useState(false);
   const backTotheTransection = (toggle) => {
      setWithDrawformShow(toggle)
   }
   const [walletSettingData, setWalletSettingData] = useState([]);
   const get_porduct_details = async () => {
      try {
         const response = await axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/api/stripe/get_porduct_details`);
         if (response.status === 200 && response.data.status == 1) {
            //setWalletSettingData
            setWalletSettingData(response.data.data)
         } else { console.error('Error:', response.status); }
      } catch (error) { console.error('Error:', error); }
   }

   return (

      <>
         {/* <ToastContainer />*/}
         <div className="dashboarwrap">
            <div className="pagebanner">
               <div className="container">
                  <div className="row">
                     <div className="col-md-12">
                        <h2>Wallet</h2>
                     </div>
                  </div>
               </div>
            </div>
            <div className="jobbtmsec py-5 admininfo">
               <div className="container">
                  <div className="row">
                     {/* <div className="col-md-3 profilesidebarwrap">
                        <ProfileSidebar />
                     </div> */}
                     <div className="col-md-12">
                        <div className="profilerightwraper stepscont walletblocks">

                           <div className="col-md-12 mb-4">
                              <div className="box ststicsbox heiglightbox d-flex justify-content-between">
                                 <div className="ststicsboxleft">
                                    <h2>{jsWalletData.total_current_available_coins} <i className="fa fa-arrow-down" aria-hidden="true"></i></h2>
                                    <p>Current Squad Goo wallet balance</p>
                                 </div>
                                 <div className="ststicsboxright">
                                    {/* <span href="#" className="batn btnrev ">Withdraw Coin</span> */}
                                    <span onClick={() => { setWithDrawformShow(!withDrawformShow); }} className="batn btnrev ml-2">Withdraw Coins</span>

                                    {/* <span onClick={() => { setIsCheckOutPageShow(!isCheckOutPageShow); setWithDrawformShow(false) }} className="batn batn-orange">Purchase Coin</span> */}
                                 </div>
                              </div>
                           </div>
                           <RecWalletHist onChildDataChange={handleChildDataChange} />
                           <ReferEarnComponent />

                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <Modal size="md" id="withdraw_amount_coins" className="modal fade " show={withDrawformShow} onHide={() => setWithDrawformShow(!withDrawformShow)}>
            {
               <>
                  <div className="modal-dialog1 ">
                     <div className="modal-content">
                        <div className="modal-body">
                           <button onClick={() => setWithDrawformShow(!withDrawformShow)} type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                           <RecWalletWithdraw backToParent={backTotheTransection} productdata={walletSettingData} total_available_coins={jsWalletData.total_available_coins} />
                        </div>
                     </div>
                  </div>
               </>
            }
         </Modal>


      </>

   );
}
export default JobseekerWallet;