import React from 'react';
import * as XLSX from 'xlsx';

const ExcelExportButton = ({ jsonData }) => {
  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(jsonData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
    XLSX.writeFile(wb, 'exported_data.xlsx');
  };
  //fa fa-file-excel-o
 // return <button onClick={exportToExcel}>Export to Excel</button>;

  return (
    // <button onClick={exportToPdf}>Export to PDF</button>
    <span className='filtericon' onClick={exportToExcel} data-toggle="tooltip" title="Export in excel">
                                    <img className="downloadicon" src="/front/images/xll.png" />
                                 </span>
  );
};

export default ExcelExportButton;