import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { Form, Button, Col, InputGroup, Modal, Row } from 'react-bootstrap';

import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';
const SuppReqConverationComp = (getdata) => {
    const userdetails = JSON.parse(localStorage.getItem("squadGologinSession"));

    //const statusType2 = ['All', 'Open', 'Awating', 'Solved'];
    const statusType = [
        { key: '', value: 'All' },
        { key: 'Open', value: 'Open' },
        { key: 'Awating', value: 'In Progress' },
        { key: 'Solved', value: 'Closed' },
    ];

    const useriddata = JSON.parse(localStorage.getItem("squadGologinSession"));

    const [isDalaFatching, setisDalaFatching] = useState(true);
    const [conversationData, setconversationData] = useState([]);
    const [hideAndSeekMyRequestModel, sethideAndSeekMyRequestModel] = useState(false);

    var setdata = getdata.data;
    //console.log(getdata.data)
    const [myRequestDataFormArr, setmyRequestDataFormArr] = useState({
        id: setdata.id,
        title: setdata.title,
        description: setdata.description,
        priority: setdata.priority,
        status: setdata.status,
        created_date: setdata.created_date,
        status: setdata.status,
    });
    const [currentStatus, setcurrentStatus] = useState(setdata.status);
    console.log(currentStatus)
    const get_converationdata = (support_id) => {
        setisDalaFatching(true);
        (async () => {
            try {
                const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/get_request_conversation/" + useriddata.login_token + "/" + support_id);
                if (resData.data.status == 1) {
                    setconversationData(resData.data.converations);
                    setisDalaFatching(false);

                    console.log('caling....')
                    // if(type == 'removed'){
                    //    toast.success(resData.data.message);
                    // }
                }
            } catch (error) { console.log(error); }
        })();
    }

    useEffect(() => {
        if (setdata.id) {
            console.log(setdata.id)
            get_converationdata(setdata.id);
        }
    }, []);
    const [validated, setValidated] = useState(false);

    const supportReqConversationSubmit = async (event) => {
        event.preventDefault();
        const statusvalue = event.target.status.value;
        const form = event.currentTarget;
        if (form.checkValidity() === false) { event.preventDefault(); event.stopPropagation(); }
        else {

            try {
                const formData = new FormData();

                formData.append('description', event.target.description.value);
                formData.append('login_token', useriddata.login_token);
                formData.append('support_id', myRequestDataFormArr.id);
                formData.append('edit_id', 0);
                formData.append('status', statusvalue);
                axios.post('https://squadgoo.com.au/api.squadgoo.com.au/add_support_request_converation', formData)
                    .then((response) => {
                        if (response.status == 200 && response.data.status == 1) {
                            toast.success(response.data.message);
                            get_converationdata(myRequestDataFormArr.id)
                            sethideAndSeekMyRequestModel(false);
                            setcurrentStatus(statusvalue);
                            //get_requesteddata()
                        }
                    })
                    .catch((error) => { console.error(error); });
            } catch (error) { console.log(error); }
        }
        setValidated(true);
    };


    /*----------editConvesation--------*/
    const editConvesation = (data) => {
        sethideAndSeekMyRequestModel(true)
    }

    /*----------delete--------*/
    const [isBlockModelShow, setIsBlockModelShow] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const [deletItemID, setdeletItemID] = useState(0);
    const deleteConvesation = (data) => {
        setdeletItemID(data.id);
        setIsBlockModelShow(data.id);
    }

    const wantToBlockData = () => {
        (async () => {
            setBtnLoading(true)
            try {
                const formData = new FormData();
                axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/remove_support_request_converation/${useriddata.login_token}/${deletItemID}`, formData)
                    .then((response) => {
                        setBtnLoading(false);
                        if (response.status == 200 && response.data.status == 1) {
                            toast.success(response.data.message);
                            get_converationdata(myRequestDataFormArr.id)
                            setIsBlockModelShow(false);
                        }
                    })
                    .catch((error) => { console.error(error); });
            } catch (error) { console.log(error); }

        })();
    }


    // Approach 1: Using dangerouslySetInnerHTML
    const DisplayWithDangerouslySetInnerHTML = ({ content }) => {
        // Convert new lines to <br> for display
        const htmlContent = content.replace(/\n/g, '<br/>'); // Replace new lines with <br />

        return <td dangerouslySetInnerHTML={{ __html: htmlContent }} />; // Render raw HTML
    };

    // Approach 2: Converting new lines into JSX elements
    const DisplayWithJSX = ({ content }) => {
        // Split the content by new lines to create paragraphs
        const paragraphs = content.split('\n').map((paragraph, index) => (
            <p key={index}>{paragraph}</p> // Create a paragraph for each split content
        ));

        return <td>{paragraphs}</td>; // Render the array of paragraphs
    };


    return (
        <>

            <div id="contentdata_show">
                <p className="reportdate d-flex"><img src="/front/images/calender.png" />
                    {myRequestDataFormArr.created_date}


                </p>
                <h4 className='text-capitalize m-0'>{myRequestDataFormArr.title}</h4>
                {/* <p className='p-0 m-0'>{myRequestDataFormArr.description}</p> */}
                <DisplayWithJSX content={myRequestDataFormArr.description} />
                <Link onClick={() => sethideAndSeekMyRequestModel(true)} className="nav-link active p-0 mb-2"><span className="back_to_hist1 cursor_pointer">Add Message</span></Link>
            </div>


            {
                isDalaFatching ?
                    <>
                        <div className='mt-1'>
                            Please Wait..
                        </div>
                    </>
                    :
                    <>
                        <div id="contentdata" className=' p-3'>

                            {conversationData.length ? <><h3>Converations history</h3></> : <><p>There is no conversation</p></>}
                            {
                                conversationData.map((item, index) => (
                                    <>
                                        <div id="contentdata_history" className='mb-10 border-bottom pb-2'>
                                            <p className="reportdate d-flex mb-0"><img src="/front/images/calender.png" />{item.created_date}  ({item.suppert_status}) - <span className='text-capitalize'>{(item.user_data.first_name + ' ' + item.user_data.last_name)}</span> </p>
                                            {/* <p className='mb-0'>{item.message}</p> */}
                                            <DisplayWithJSX content={item.message} />
                                            {/* <Link onClick={() => editConvesation(item)} >Edit</Link>
                                            <span className='ml-2 mr-2'>|</span>
                                            <Link className='' onClick={() => deleteConvesation(item)} >Delete</Link> */}
                                        </div>
                                    </>
                                ))
                            }
                        </div>
                    </>
            }



            <Modal show={hideAndSeekMyRequestModel} onHide={() => sethideAndSeekMyRequestModel(false)}>
                <div className="modal-dialog1">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button type="button" className="btn-close" onClick={() => sethideAndSeekMyRequestModel(false)} ></button>
                            <h3>Conversation with support</h3>
                            <div className="commonform">
                                <Form noValidate validated={validated} onSubmit={supportReqConversationSubmit} >
                                    <div className="row">
                                        <div className="col-md-12 form-group mt-3">
                                            <label className="form-label" htmlFor="exampleInputEmail1">Message <span className="starr">*</span></label>
                                            <textarea className="form-control" name='description' maxLength={5000} required={true} rows="5" placeholder="Write your message here"></textarea>
                                        </div>
                                        {(userdetails.user_type == 1) ? <div className="col-md-12 form-group mb-2">
                                            <label className="form-label" htmlFor="phone">Status <span className="starr">*</span></label>
                                            <select className="form-control text-capitalize" name='status'  >
                                                {
                                                    statusType.map((item, index) => (
                                                        <option key={item.value} value={item.key}>{item.value}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                            :
                                            <input type='hidden' name='status' value={'High'} />
                                        }
                                        <div className="col-md-12 form-group mt-1">
                                            <button type='submit' className="batn batn-orange">Send</button>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>



            <Modal className="modal fade modalsmall jobacceptmodle" id="jobacceptmodle" show={isBlockModelShow} onHide={() => setIsBlockModelShow(false)}>
                <div className="modal-dialog1">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button type="button" className="btn-close" onClick={() => setIsBlockModelShow(false)}></button>
                            <h5 className="text-center mb-4">Do you want to delete this message ?</h5>
                            <div className="btnsgroup text-center">
                                <Link onClick={wantToBlockData} className="batn batn-orange" >Yes</Link>
                                <Link onClick={() => setIsBlockModelShow(false)} className="batn batn-orange batn-border">No</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>


        </>
    );
}
export default SuppReqConverationComp;