import React, { useState, useEffect, Component } from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink, useParams, useNavigate, Link } from 'react-router-dom';
import { Form, Button, Col, InputGroup, Modal, Row } from 'react-bootstrap';

import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';
//hideAndSeekMyRequestModel
import SuperAdminSidebar from './superadmin_sidebar';
import SuppReqConverationComp from '../SuppReqConverationComp';

import StatusTypeOption from '../../json/statusTypeOption.json'

const LeadCollectionComp = () => {

    // const typeofpriority = ['High', 'Medium', 'Low'];
    // const statusType = ['All', 'Open', 'Awating', 'Solved'];
    const BASE_ULR = "https://squadgoo.com.au/api.squadgoo.com.au/api/front/get_lead_data/";
    const useriddata = JSON.parse(localStorage.getItem("squadGologinSession"));
    const [orderByData, setorderByData] = useState('ASC');
    // const [handleInput, sethandleInput] = useState('');
    const [myRequestDataArr, setmyRequestDataArr] = useState([]);
    const [myRequestDataArrCopy, setmyRequestDataArrCopy] = useState([]);
    const [screenLoader, setScreenLoader] = useState(true);
    const [currentEditId, setcurrentEditId] = useState(0);
    const [searchText, setSearchText] = useState('');
    const showUserBlockAction = (id, status) => {
        setcurrentEditId(id);
        get_requesteddata(BASE_ULR + useriddata.login_token + '?blockid=' + id + '&blockstatus=' + status + '&search=' + searchValue + '&order_by=' + orderByData)
    }
    const [isBlockModelShow, setIsBlockModelShow] = useState(false);
    const [searchValue, setsearchValue] = useState('');
    const showUserBlockActionExecute = () => {
        get_requesteddata(BASE_ULR + useriddata.login_token + '?blockid=' + currentEditId + '&blockstatus=pending&search=' + searchValue + '&order_by=' + orderByData);
    }



    const get_requesteddata = (apiurl) => {
        setScreenLoader(true);
        (async () => {
            try {
                const resData = await axios.get(apiurl);
                if (resData.data.status == 1) {
                    setmyRequestDataArr(resData.data.data);
                    setIsBlockModelShow(false);
                    setmyRequestDataArrCopy(resData.data.data);
                    setScreenLoader(false);
                }
            } catch (error) { console.log(error); }
        })();
    }


    const [isDesModalShow, setisDesModalShow] = useState(false);
    const [getCurrentcallData, setgetCurrentcallData] = useState([]);
    const showSupportCallData = (data) => {
        setisDesModalShow(true);
        setgetCurrentcallData(data);
    }

    useEffect(() => {
        if (useriddata) { get_requesteddata(BASE_ULR + useriddata.login_token + '?blockid=' + currentEditId + '&blockstatus=pending&search=' + searchValue + '&order_by=' + orderByData); }
    }, [orderByData, searchValue]);


    // const  handleInput  = (e) => {
    //     let value = e.target.value ;
    //     setsearchValue(value);
    // }
    const handleInput = (event) => {
        // setSearchTerm(event.target.value);
        let term = event.target.value;
        setSearchText(term);
        if (term.length > 0 && term != 'All') {

            const filteredData = myRequestDataArr.filter((item) =>
                item.full_name.toLowerCase().includes(term.toLowerCase()) ||
                item.email.toLowerCase().includes(term.toLowerCase()) ||
                item.message.toLowerCase().includes(term.toLowerCase()) ||
                item.status.toLowerCase().includes(term.toLowerCase()) ||
                item.contact_number.toLowerCase().includes(term.toLowerCase())
            );
            //user_details.first_name last_name contact_number
            setmyRequestDataArr(filteredData)
        } else {
            setmyRequestDataArr(myRequestDataArrCopy)
        }
    };
    
    const [statusSearch, setStatusSearch] = useState('All');
    const getDataByStatus = (value) => {
        setStatusSearch(value)
        if (value != 'All') {
            const filteredData = myRequestDataArr.filter((item) =>
                item.status.toLowerCase().includes(value.toLowerCase())
            );
            setmyRequestDataArr(filteredData)
        } else {
            setmyRequestDataArr(myRequestDataArrCopy)
        }
    }


    return (
        <>


            <div className="dashboarwrap">

                <div className="pagebanner">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2>Requested Calls</h2>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <ToastContainer />*/}
                <div className="jobbtmsec py-5 helppage">
                    <div className="container">

                        <div className="row">
                            <div className="col-md-3 adminsidebar">
                                <SuperAdminSidebar></SuperAdminSidebar>
                            </div>
                            <div className="col-md-9">
                                <div className="box mb-4">
                                    {/* searchText setSearchText */}
                                    {

                                        <>
                                            <div className="table_filter mb-2 d-flex align-items-center justify-content-between commonform">
                                                <div className="tblsearch">
                                                    <input type="text" className="form-control"
                                                        placeholder="Search..."
                                                        onChange={handleInput}
                                                        value={searchText}
                                                    />
                                                </div>
                                                <div className="tblrightfilters d-flex align-items-center">

                                                    <select class="form-control ml-3 text-capitalize" onChange={(e) => getDataByStatus(e.target.value)}>
                                                        <option value="All">All</option>
                                                        {
                                                            StatusTypeOption.map((item2, index) => (
                                                                <option value={item2.key} key={item2.key}>{item2.value}</option>
                                                            ))
                                                        }
                                                    </select>


                                                    <span onClick={() => {
                                                        setStatusSearch('');
                                                        setSearchText('');
                                                        get_requesteddata(BASE_ULR + useriddata.login_token + '?blockid=' + currentEditId + '&blockstatus=pending&search=' + searchValue + '&order_by=' + orderByData)
                                                    }}
                                                        className="reseticon filtericon" >
                                                        <i className="fa fa-refresh"></i>
                                                    </span>
                                                </div>
                                            </div>
                                            {
                                                screenLoader ?
                                                    <>
                                                        <div className={`tab-pane fade show active mt45px`} >
                                                            <div className='loader_container'>
                                                                <p className='text-center'><img className='width-100 loader_image' src={'/front/images/loader.gif'} /></p>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <div className="table-responsive">
                                                        <table className="table admintbl profiletbl">
                                                            <thead>
                                                                <tr>
                                                                    <th className='cursor_pointer'>S.N.</th>
                                                                    <th>Name</th>
                                                                    <th>Email</th>
                                                                    <th>Contact No.</th>
                                                                    <th>Created date</th>
                                                                    <th>Status</th>
                                                                    <th>Lead capture</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>


                                                                <>
                                                                    {
                                                                        myRequestDataArr.length ?
                                                                            <>
                                                                                {
                                                                                    myRequestDataArr.map((item, index) => (
                                                                                        <tr>
                                                                                            <td>{index + 1}</td>
                                                                                            <td className='text-capitalize'>{item.full_name}</td>
                                                                                            <td>{item.email}</td>
                                                                                            <td>{item.contact_number}</td>
                                                                                            <td>{item.created_date}</td>
                                                                                            <td>
                                                                                                <select className="width-120 form-control ml-3 text-capitalize" onChange={(e) => showUserBlockAction(item.id, e.target.value)} >
                                                                                                    {
                                                                                                        StatusTypeOption.map((item2, index) => (
                                                                                                            <option selected={item2.key == item.status} value={item2.key} key={item2.key}>{item2.value}</option>
                                                                                                        ))
                                                                                                    }
                                                                                                </select>
                                                                                            </td>
                                                                                            <td className='text-capitalize' >{item.leadcapture_from}</td>
                                                                                            <td>
                                                                                                <div className="d-flex">
                                                                                                    <Link onClick={() => showSupportCallData(item)} data-toggle="tooltip" title="Lead information" className="ml-3"><img src="/front/images/Show.svg" /></Link>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    ))
                                                                                }
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <tr>
                                                                                    <td className='text-center' colSpan={7}>No information available to display</td>
                                                                                </tr>
                                                                            </>
                                                                    }
                                                                </>

                                                            </tbody>
                                                        </table>
                                                    </div>
                                            }
                                        </>
                                    }

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>


            <Modal className="modal fade modalsmall jobacceptmodle" id="jobacceptmodle" show={isBlockModelShow} onHide={() => setIsBlockModelShow(false)}>
                <div className="modal-dialog1">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button type="button" className="btn-close" onClick={() => setIsBlockModelShow(false)}></button>
                            <div className="text-center mb-3">
                                {/* <img src="/front/images/greencheck.svg" /> */}
                            </div>
                            <h5 className="text-center mb-4">Do you want to change status?</h5>

                            <div className="btnsgroup text-center">
                                <Link onClick={showUserBlockActionExecute} className="batn batn-orange" >Yes</Link>
                                <Link onClick={() => setIsBlockModelShow(false)} className="batn batn-orange batn-border">No</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal className="modal fade supportcallhistory" id="supportcallhistory" show={isDesModalShow} onHide={() => setisDesModalShow(false)}>
                <div className="modal-dialog1">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button type="button" className="btn-close" onClick={() => setisDesModalShow(false)}></button>
                            <div>
                                <p className='m-0 '><b> <span className='text-capitalize'>{getCurrentcallData.full_name}</span> ({getCurrentcallData.email})</b></p>
                                Message:
                                <p>
                                    {getCurrentcallData.message}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

        </>
    );
}
export default LeadCollectionComp;