import React, { useState, useEffect, useRef, Component } from 'react';
import SuperAdminSidebar from './superadmin_sidebar';

import axios from 'axios';
import { Link } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';


import BarChart from './supcomp/DashboardBargraph';
// /import DashboardBarChart from './supcomp/DashboardBarChart';

//SuperAdminSidebar
const AdminDashboard = () => {
   const [currentScreen, setcurrentScreen] = useState('registration');
   const [screenLoader, setScreenLoader] = useState(false);
   const [ajaxLoadDone, setajaxLoadDone] = useState(false);
   const [registationStatistic, setregistationStatistic] = useState('monthly');
   const [coinstistic, setcoinsStatistic] = useState('monthly');
   const [jobofferstistic, setjoboffersStatistic] = useState('monthly');

   const [getDashboardData, setGetDashboardData] = useState({ "registration_count": { "recruiter": "7", "jobseeker": "28", "both": "35" }, "coin_count": { "recruiter": "7", "jobseeker": "28", "both": "35" }, "joboffer_count": { "pending": "12", "accepted": "10", "declined": "2" } });

   const [montlyRegistration, setmontlyRegistration] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
   const [quarterlyRegistration, setquarterlyRegistration] = useState([0, 0, 0, 0]);
   const [yearlyRegistration, setyearlyRegistration] = useState([0, 0, 0, 0, 0]);
   const [yearlyLabelsRegistration, setyearlyLabelsRegistration] = useState(['Year1', 'Year2', 'Year3', 'Year4', 'Year5']);



   const [montlyJobOffers, setmontlyJobOffers] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
   const [quarterlyJobOffers, setquarterlyJobOffers] = useState([0, 0, 0, 0]);
   const [yearlyJobOffers, setyearlyJobOffers] = useState([0, 0, 0, 0, 0]);
   const [yearlyLabelsJobOffers, setyearlyLabelsJobOffers] = useState(['Year1', 'Year2', 'Year3', 'Year4', 'Year5']);

   //montlyJobOffers//quarterlyJobOffers yearlyJobOffers yearlyLabelsJobOffers
   const userdetails = JSON.parse(localStorage.getItem("squadGologinSession"));
   const get_dashboarddata = (apiurl) => {
      setScreenLoader(true);
      (async () => {
         try {
            const resData = await axios.get(apiurl);
            if (resData.data.status == 1) {
               setScreenLoader(false);
               setajaxLoadDone(true);
               setGetDashboardData(resData.data.data);

               setmontlyRegistration(resData.data.data.registration_count.montly_registration);
               setquarterlyRegistration(resData.data.data.registration_count.quarterly_registration);
               setyearlyRegistration(resData.data.data.registration_count.yearly_registration);
               setyearlyLabelsRegistration(resData.data.data.registration_count.yearly_registration_label);

               setmontlyJobOffers(resData.data.data.joboffer_count.montly_joboffers);
               setquarterlyJobOffers(resData.data.data.joboffer_count.quarterly_joboffers);
               setyearlyJobOffers(resData.data.data.joboffer_count.yearly_joboffers);
               setyearlyLabelsJobOffers(resData.data.data.joboffer_count.yearly_joboffers_label);

               // console.log(getDashboardData)
            }
         } catch (error) { console.log(error); }
      })();
   }

   useEffect(() => {
      get_dashboarddata(`https://squadgoo.com.au/api.squadgoo.com.au/dashboard_data/${userdetails.login_token}`)
   }, []);


   const userData = {
      labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      datasets: [
         {
            label: 'Monthly',
            backgroundColor: '#667698',
            borderColor: '#667698',
            borderWidth: 2,
            data: montlyRegistration,
         }
      ],
   };
   const options = { scales: { x: { type: 'category', labels: userData.labels, }, y: { beginAtZero: true, }, }, };

   const userDataQuarterly = {
      labels: ['Jan-March', 'April-June', 'July-Sept', 'Oct-Dec'],
      datasets: [
         {
            label: 'Quarterly',
            backgroundColor: '#667698',
            borderColor: '#667698',
            borderWidth: 2,
            data: quarterlyRegistration,
         },
      ],
   };
   const options2 = { scales: { x: { type: 'category', labels: userDataQuarterly.labels, }, y: { beginAtZero: true, }, }, };

   const userDataYearly = {
      labels: yearlyLabelsRegistration,
      datasets: [
         {
            label: 'Yearly',
            backgroundColor: '#667698',
            borderColor: '#667698',
            borderWidth: 2,
            data: yearlyRegistration,
         },
      ],
   };
   const options3 = { scales: { x: { type: 'category', labels: userDataYearly.labels, }, y: { beginAtZero: true, }, }, };
   /*------------------------------------------------------------------------------------*/
   //montlyJobOffers//quarterlyJobOffers yearlyJobOffers yearlyLabelsJobOffers
   const jobOffersMonthlyData = {
      labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      datasets: [
         {
            label: 'Monthly',
            backgroundColor: '#667698',
            borderColor: '#667698',
            borderWidth: 2,
            data: montlyJobOffers,
         }
      ],
   };
   const options4 = { scales: { x: { type: 'category', labels: jobOffersMonthlyData.labels, }, y: { beginAtZero: true, }, }, };

   const jobOffersDataQuarterly = {
      labels: ['Jan-March', 'April-June', 'July-Sept', 'Oct-Dec'],
      datasets: [
         {
            label: 'Quarterly',
            backgroundColor: '#667698',
            borderColor: '#667698',
            borderWidth: 2,
            data: quarterlyJobOffers,
         },
      ],
   };
   const options5 = { scales: { x: { type: 'category', labels: jobOffersDataQuarterly.labels, }, y: { beginAtZero: true, }, }, };

   const jobOffersDataYearly = {
      labels: yearlyLabelsJobOffers,
      datasets: [
         {
            label: 'Yearly',
            backgroundColor: '#667698',
            borderColor: '#667698',
            borderWidth: 2,
            data: yearlyJobOffers,
         },
      ],
   };
   const options6 = { scales: { x: { type: 'category', labels: jobOffersDataYearly.labels, }, y: { beginAtZero: true, }, }, };



   /*----------------------------------------------------------------------*/

   


   return (
      <>
         <div className="dashboarwrap">
         <div className="pagebanner">
               <div className="container">
                  <div className="row">
                     <div className="col-md-12">
                        <h2>Dashboard</h2>
                     </div>
                  </div>
               </div>
            </div>

            {
               (userdetails.email_status == 1 && userdetails.contact_status == 1) ?
                  <>
                     <div className="adminwrap py-4">
                        <div className="container">
                           <div className="row">
                              <div className="col-md-3 adminsidebar">
                                 <SuperAdminSidebar></SuperAdminSidebar>
                              </div>
                              <div className="col-md-9">
                                 {
                                    userdetails.user_type_role == 'admin_staff' ?
                                       <>
                                          <div className="row">
                                             <div className="col-md-12 mb-2">
                                                <div className="box ststicsbox d-flex justify-content-between p-5">
                                                   Need to work on Admin Staff dashboard
                                                </div>
                                             </div>
                                          </div>

                                       </>
                                       :
                                       <>
                                          {
                                             screenLoader == false ?
                                                <>
                                                   <div className="row">
                                                      <div className="col-md-12 mb-2">
                                                         <div className="switch-field mr-0">
                                                            <div className="switchfieldinner">
                                                               <input checked={currentScreen == 'registration' ? true : false} type="radio" id="Registrations" name="yearwitch" onClick={() => setcurrentScreen('registration')} />
                                                               <label for="Registrations">Registrations</label>
                                                            </div>
                                                            <div className="switchfieldinner">
                                                               <input checked={currentScreen == 'coins' ? true : false} type="radio" id="Coins" name="yearwitch" onClick={() => setcurrentScreen('coins')} />
                                                               <label for="Coins">Coins</label>
                                                            </div>
                                                            <div className="switchfieldinner">
                                                               <input checked={currentScreen == 'joboffers' ? true : false} type="radio" id="joboffers" name="yearwitch" onClick={() => setcurrentScreen('joboffers')} />
                                                               <label for="joboffers">Job offers</label>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>


                                                   {currentScreen == 'registration' &&

                                                      <>
                                                         <div className="row">
                                                            <div className="col-md-4 mb-4">
                                                               <div className="box ststicsbox d-flex justify-content-between">
                                                                  <div className="ststicsboxleft">
                                                                     <p>Total Registrations </p>
                                                                     <h2>{getDashboardData.registration_count.both ? getDashboardData.registration_count.both : 0}</h2>
                                                                  </div>
                                                                  <div className="ststicsboxright">
                                                                     <img src="/front/images/sregister.svg"></img>
                                                                  </div>
                                                               </div>
                                                            </div>
                                                            <div className="col-md-4 mb-4">
                                                               <div className="box ststicsbox d-flex justify-content-between">
                                                                  <div className="ststicsboxleft">
                                                                     <p>Total Jobseekers </p>
                                                                     <h2 className='dashboard_tile'>
                                                                        <Link to={'/jobseekers?backto=dashboard'}>
                                                                           {getDashboardData.registration_count.jobseeker}
                                                                        </Link>
                                                                     </h2>
                                                                  </div>
                                                                  <div className="ststicsboxright">
                                                                     <img src="/front/images/sverification.svg"></img>
                                                                  </div>
                                                               </div>
                                                            </div>
                                                            <div className="col-md-4 mb-4">
                                                               <div className="box ststicsbox d-flex justify-content-between">
                                                                  <div className="ststicsboxleft">
                                                                     <p>Total Recruiters</p>
                                                                     <h2 className='dashboard_tile'>
                                                                        <Link to={'/recruiters?backto=dashboard'}>
                                                                           {getDashboardData.registration_count.recruiter}
                                                                        </Link>
                                                                     </h2>
                                                                  </div>
                                                                  <div className="ststicsboxright">
                                                                     <img src="/front/images/ssubscription.svg"></img>
                                                                  </div>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="row">
                                                            <div className="col-md-12">
                                                               <div className="box mb-4">
                                                                  <div className="box-ttl mb-3 d-flex align-items-center justify-content-between">
                                                                     <h3></h3>
                                                                     <div className="switch-field mr-0">
                                                                        <div className="switchfieldinner">
                                                                           <input checked={registationStatistic == 'monthly' ? true : false} onClick={() => setregistationStatistic('monthly')} type="radio" id="monthly" name="registration_statistick" />
                                                                           <label htmlFor="monthly">Monthly</label>
                                                                        </div>
                                                                        <div className="switchfieldinner">
                                                                           <input checked={registationStatistic == 'quarterly' ? true : false} onClick={() => setregistationStatistic('quarterly')} type="radio" id="quarterly" name="registration_statistick" />
                                                                           <label htmlFor="quarterly">Quarterly</label>
                                                                        </div>
                                                                        <div className="switchfieldinner">
                                                                           <input checked={registationStatistic == 'yearly' ? true : false} onClick={() => setregistationStatistic('yearly')} type="radio" id="yearly" name="registration_statistick" />
                                                                           <label htmlFor="yearly">Yearly</label>
                                                                        </div>
                                                                     </div>
                                                                  </div>

                                                                  {
                                                                     ajaxLoadDone == true ?
                                                                        <>

                                                                           {registationStatistic === 'monthly' && <BarChart data={userData} options={options} />}
                                                                           {registationStatistic === 'quarterly' && <BarChart data={userDataQuarterly} options={options2} />}
                                                                           {registationStatistic === 'yearly' && <BarChart data={userDataYearly} options={options3} />}
                                                                        </>
                                                                        :
                                                                        <></>
                                                                  }

                                                               </div>
                                                            </div>
                                                         </div>
                                                      </>
                                                   }

                                                   {currentScreen == 'coins' && <><div className="row">
                                                      <div className="col-md-4 mb-4">
                                                         <div className="box ststicsbox d-flex justify-content-between">
                                                            <div className="ststicsboxleft">
                                                               <p>Coins purchased</p>
                                                               <h2>500</h2>
                                                            </div>
                                                            <div className="ststicsboxright">
                                                               <img src="/front/images/sregister.svg"></img>
                                                            </div>
                                                         </div>
                                                      </div>
                                                      <div className="col-md-4 mb-4">
                                                         <div className="box ststicsbox d-flex justify-content-between">
                                                            <div className="ststicsboxleft">
                                                               <p>Coins Withdrawn</p>
                                                               <h2>38</h2>
                                                            </div>
                                                            <div className="ststicsboxright">
                                                               <img src="/front/images/sverification.svg"></img>
                                                            </div>
                                                         </div>
                                                      </div>
                                                      <div className="col-md-4 mb-4">
                                                         <div className="box ststicsbox d-flex justify-content-between">
                                                            <div className="ststicsboxleft">
                                                               <p>Rewards Paid</p>
                                                               <h2>241</h2>
                                                            </div>
                                                            <div className="ststicsboxright">
                                                               <img src="/front/images/ssubscription.svg"></img>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                      <div className="row">
                                                         <div className="col-md-12">
                                                            <div className="box mb-4">
                                                               <div className="box-ttl mb-3 d-flex align-items-center justify-content-between">
                                                                  <h3>Income</h3>
                                                                  <div className="switch-field mr-0">
                                                                     <div className="switchfieldinner">
                                                                        <input checked={coinstistic == 'monthly' ? true : false} onClick={() => setcoinsStatistic('monthly')} type="radio" id="monthly" name="coin_statistick" />
                                                                        <label htmlFor="monthly">Monthly</label>
                                                                     </div>
                                                                     <div className="switchfieldinner">
                                                                        <input checked={coinstistic == 'quarterly' ? true : false} onClick={() => setcoinsStatistic('quarterly')} type="radio" id="quarterly" name="coin_statistick" />
                                                                        <label htmlFor="quarterly">Quarterly</label>
                                                                     </div>
                                                                     <div className="switchfieldinner">
                                                                        <input checked={coinstistic == 'yearly' ? true : false} onClick={() => setcoinsStatistic('yearly')} type="radio" id="yearly" name="coin_statistick" />
                                                                        <label htmlFor="yearly">Yearly</label>
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                               <img src="/front/images/graph.jpg" className="w-100"></img>
                                                            </div>
                                                         </div>
                                                      </div></>}

                                                   {currentScreen == 'joboffers' && <><div className="row">
                                                      <div className="col-md-4 mb-4">
                                                         <div className="box ststicsbox d-flex justify-content-between">
                                                            <div className="ststicsboxleft">
                                                               <p>Sent</p>
                                                               <h2>{getDashboardData.joboffer_count.accepted}</h2>
                                                            </div>
                                                            <div className="ststicsboxright">
                                                               <img src="/front/images/sregister.svg"></img>
                                                            </div>
                                                         </div>
                                                      </div>
                                                      <div className="col-md-4 mb-4">
                                                         <div className="box ststicsbox d-flex justify-content-between">
                                                            <div className="ststicsboxleft">
                                                               <p>Accepted</p>
                                                               <h2>{getDashboardData.joboffer_count.accepted}</h2>
                                                            </div>
                                                            <div className="ststicsboxright">
                                                               <img src="/front/images/sverification.svg"></img>
                                                            </div>
                                                         </div>
                                                      </div>
                                                      <div className="col-md-4 mb-4">
                                                         <div className="box ststicsbox d-flex justify-content-between">
                                                            <div className="ststicsboxleft">
                                                               <p>Rejected</p>
                                                               <h2>{getDashboardData.joboffer_count.declined}</h2>
                                                            </div>
                                                            <div className="ststicsboxright">
                                                               <img src="/front/images/ssubscription.svg"></img>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                      <div className="row">
                                                         <div className="col-md-12">
                                                            <div className="box mb-4">
                                                               <div className="box-ttl mb-3 d-flex align-items-center justify-content-between">
                                                                  <h3>Income</h3>
                                                                  <div className="switch-field mr-0">
                                                                     <div className="switchfieldinner">
                                                                        {/* setjoboffersStatistic jobofferstistic */}
                                                                        <input checked={jobofferstistic == 'monthly' ? true : false} onClick={() => setjoboffersStatistic('monthly')} type="radio" id="monthly" name="joboffer_statistick" />
                                                                        <label htmlFor="monthly">Monthly</label>
                                                                     </div>
                                                                     <div className="switchfieldinner">
                                                                        <input type="radio" checked={jobofferstistic == 'quarterly' ? true : false} onClick={() => setjoboffersStatistic('quarterly')} id="quarterly" name="joboffer_statistick" />
                                                                        <label htmlFor="quarterly">Quarterly</label>
                                                                     </div>
                                                                     <div className="switchfieldinner">
                                                                        <input type="radio" checked={jobofferstistic == 'yearly' ? true : false} onClick={() => setjoboffersStatistic('yearly')} id="yearly" name="joboffer_statistick" />
                                                                        <label htmlFor="yearly">Yearly</label>
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                               {/* jobOffersMonthlyData jobOffersDataQuarterly  jobOffersDataYearly */}
                                                               {
                                                                  ajaxLoadDone == true ?
                                                                     <>
                                                                     {console.log(jobOffersMonthlyData)}
                                                                        {jobofferstistic === 'monthly' && <BarChart data={jobOffersMonthlyData} options={options4} />}
                                                                        {jobofferstistic === 'quarterly' && <BarChart data={jobOffersDataQuarterly} options={options5} />}
                                                                        {jobofferstistic === 'yearly' && <BarChart data={jobOffersDataYearly} options={options6} />}
                                                                     </>
                                                                     :
                                                                     <></>
                                                               }

                                                            </div>
                                                         </div>
                                                      </div></>}
                                                </>
                                                :
                                                <>
                                                   <div className={`tab-pane fade show active mt45px`} >
                                                      <div className='loader_container'>
                                                         <p className='text-center'><img className='width-100 loader_image' src={'/front/images/loader.gif'} /></p>
                                                      </div>
                                                   </div>
                                                </>
                                          }
                                       </>
                                 }
                              </div>
                           </div>
                        </div>
                     </div>
                  </>
                  :
                  <>
                     Please Activate your account
                  </>
            }
         </div>
      </>
   );
}
export default AdminDashboard;