import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink, useParams, useNavigate } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux'
import { docStatusValueCheck } from '../../redux_state/reducres/checkDocumentstatus';
import { userdataarray_status } from '../../redux_state/reducres/userDataRegister';
import { useUser } from '../../admin/ApiDataContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import GoogleLogin from './gLogin'
const Login = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 110);
    const auth = localStorage.getItem('squadGologin');
    if (auth) {
      navigate('/')
    }
  });


  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [validated, setValidated] = useState(false);
  const [showFailMessage, setShowFailMessage] = useState(false);
  const [showFailMessageLink, setShowFailMessageLink] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  // Load the saved username and rememberMe preference from local storage on component mount
  useEffect(() => {
    const storedUsername = getCookie('login_username');
    const storedPassword = getCookie('login_password');
    const storedRememberMe = getCookie('login_rememberMe');

    // console.log(storedUsername);
    if (storedRememberMe === 'true') {

      setEmail(storedUsername || '');
      setPassword(storedPassword || '');
      setRememberMe(true);
    }
  }, []);

  // Handle changes to the "Remember Me" checkbox
  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
  };


  const getCookie = (cname) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  const { getDataDuringLogin } = useUser();
  const onFormSubmit = (event) => {
    event.preventDefault();

    if (rememberMe) {
      console.log('stored')
      document.cookie = "login_username=" + email;
      document.cookie = "login_password=" + password;
      document.cookie = "login_rememberMe=" + rememberMe;

    } else {
      console.log('not stored')
      document.cookie = "login_username= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
      document.cookie = "login_password= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
      document.cookie = "login_rememberMe= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";

    }

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      const formData = new FormData();
      formData.append('email', email);
      formData.append('password', password);
      axios.post('https://squadgoo.com.au/api.squadgoo.com.au/login', formData)
        .then((response) => {
          console.log(response.data);
          if (response.data.status == 1) {
            let getuserdata = response.data.data.user_data;
            let session_storage_data = response.data.data.session_storage_data;

            dispatch(userdataarray_status(getuserdata));
            localStorage.setItem('app_version', 1);
            localStorage.setItem('is_user_login', 1);
            localStorage.setItem('squadGologin', JSON.stringify(getuserdata));
            localStorage.setItem('profileStatus', getuserdata.profile_enabled)
            localStorage.setItem('squadGologinSession', JSON.stringify(getuserdata))
            localStorage.setItem('sessionStorageData', JSON.stringify(session_storage_data))
            localStorage.setItem('validationdataCheckData', JSON.stringify(getuserdata.validationdata_check));
            dispatch(docStatusValueCheck({ doctype: 'all', type: 'onload', sessiondata: JSON.stringify(getuserdata.validationdata_check) }));
            getDataDuringLogin(getuserdata.unique_id);
            if (getuserdata.user_type == 1) {
              localStorage.setItem('squadGoUserType', 'admin');
              navigate('/admin-dashboard')
            } else if (getuserdata.user_type == 4) {
              localStorage.setItem('squadGoUserType', 'admin');
              navigate('/admin-dashboard')
            } else if (getuserdata.user_type == 2) {
              localStorage.setItem('squadGoUserType', 'recruiter');
              navigate('/recruiter/dashboard')
            } else if (getuserdata.user_type == 3) {
              localStorage.setItem('squadGoUserType', 'jobseeker');
              navigate('/jobseeker/dashboard')
            } else {
              navigate('/')
            }

          } else {
            setShowFailMessage(response.data.message)
            setShowFailMessageLink(response.data.link)
            setTimeout(function () {
              setShowFailMessage(false)
            }, 4000)
          }
          //setIsSubmitting(false);
          //navigate('/login');
        })
        .catch((error) => {
          console.error(error);
          //setIsSubmitting(false);
        });
    }
    setValidated(true);
  }

  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };


  return (
    <>

      <div className="loginsignupwrap">
        <div className="container">
          <div className="row">
            <div className="col-md-12">

              <div className="loginsignupwrapformwrap">
                <Form noValidate validated={validated} onSubmit={onFormSubmit} className="commonform lofinsignupform loginform">
                  <div className="formbranding text-center">
                    <img src="/front/images/squadgoo_logo.png"></img>
                  </div>

                  <h2 className="text-center">Sign in to your account</h2>
                  <div className="form-group mb-2">
                    <label className="form-label">Email Address</label>
                    <div className="iconfields">
                      <img className="fieldicons" src="/front/images/message.svg"></img>
                      <input
                        type="email" className="form-control emailfield"
                        placeholder="Enter your email address"
                        name="email"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group mb-2">
                    <label className="form-label">Enter your password</label>
                    <div className="passwordwrap p-relative iconfields">
                      <img className="fieldicons" src="/front/images/lock.svg"></img>
                      <input
                        type={showPassword ? 'text' : 'password'}
                        className="form-control passwordfield"
                        placeholder="Enter your password"
                        value={password}
                        name="password"
                        onChange={(event) => setPassword(event.target.value)}
                        required
                      />

                      <span className="eyebtn" onClick={handleTogglePassword}  >
                        {showPassword ? <img src="/front/images/eyeclose.svg"></img> :
                          <img src="/front/images/eyeopen.svg"></img>}
                      </span>

                    </div>
                  </div>
                  <div className="form-group mb-2 d-flex align-items-center">
                    <span className="simplecheck"><input
                      type="checkbox"
                      checked={rememberMe}
                      onChange={handleRememberMeChange}
                      name="rememberme"
                      id="rememberme"

                    /><span></span></span>
                    <label for="rememberme" className='ml-2 rememberme'>Remember Me</label>


                    <NavLink to="/forgot-password" className="formlink ml-auto">Forgot password?</NavLink>
                  </div>
                  {
                    showFailMessage ?
                      <><p className="text-danger mb-0 text-center">{showFailMessage}</p></>
                      : ''
                  }
                  {showFailMessageLink && <><div className='text-center' dangerouslySetInnerHTML={{ __html: showFailMessageLink }} /></>}
                  <div className="form-group mb-2">
                    <button type="Submit" className="batn batn-orange"  >Login</button>
                    {/* <input type="Submit" className="batn batn-orange" value="Login"></input> */}
                  </div>
                 
                </Form>
                <div className="form-group mb-2">
                    <GoogleOAuthProvider clientId="68903946618-gnk17k93slg69rhdbg5pdnee2brbo6to.apps.googleusercontent.com">
                      <GoogleLogin />
                    </GoogleOAuthProvider>
                  </div>
                  <div className="text-center formbtmtxt">
                    <p>Not a member yet?  <NavLink to="/register" className="formlink text-safron">Join Now</NavLink></p>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;


