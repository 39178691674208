import React from 'react';

import ProfileBanner from '../profile_header';
import ProfileSidebar from '../profile_sidebar';

import { ToastContainer, toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';
import ContactFormComp from '../ContactDetailsComp';

const ContactDetail = () => {

   return (
      <>
         {/* <ToastContainer />*/}
         <div className="dashboarwrap">
            <ProfileBanner />
            <div className="jobbtmsec py-5">
               <div className="container">

                  <div className="row">
                     <div className="col-md-3 profilesidebarwrap">
                        <ProfileSidebar />
                     </div>
                     <div className="col-md-9">
                        <div className="profilerightwraper stepscont commonform basicdetail">
                           <ContactFormComp screen="contact_page" />
                        </div>
                     </div>
                  </div>


               </div>
            </div>
         </div>
      </>
   );
}
export default ContactDetail;