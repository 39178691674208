import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import axios from 'axios';
import EnquiryTypeJson from '../json/EnquiryType.json'
const ContactFormComp = ({ screen }) => {
    const [validated, setValidated] = useState(false);
    const [serverResponce, setServerResponce] = useState('');

    const [formData, setFormData] = useState({
        full_name: '',
        email: '',
        contact_number: '',
        subject_type: '',
        message: '',
        enquiry_type: '',
    });





    const handleInputChange = (e) => {
        const { name, value } = e.target; // Get the name and value from the event target
        setFormData((prev) => ({
            ...prev, // Copy existing form data
            [name]: value, // Update the specific field based on 'name' attribute
        }));
    };





    const handleSubmit = (event) => {
        const form = event.currentTarget;
        console.log(event.target);
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            const formData = new FormData();
            formData.append('full_name', event.target.full_name.value);
            formData.append('email', event.target.email.value);
            formData.append('contact_number', event.target.contact_number.value);
            formData.append('message', event.target.message.value);
            formData.append('subject_type', event.target.subject_type.value);
            formData.append('enquiry_type', event.target.enquiry_type.value);
            formData.append('leadcapture_from', screen);
            axios.post('https://squadgoo.com.au/api.squadgoo.com.au/api/front/lead_create', formData)
                .then((response) => {
                    console.log(response.status);
                    if (response.data.status == 1) {
                        setFormData({
                            full_name: '',
                            email: '',
                            contact_number: '',
                            message: '',
                        });
                        setServerResponce(response.data.message);
                        setTimeout(() => {
                            setServerResponce('');
                        }, 5000);
                    }
                })
                .catch((error) => { console.error(error); });
        }
        setValidated(true);
    };
    //screen
    return (
        <Form className={`row  ${screen != 'footer' ? 'commonform lofinsignupform registerform' : ''}`} noValidate validated={validated} onSubmit={handleSubmit}>

            <div className="col-md-6 mb-3">
                <div class="iconfields">
                    {screen != 'footer' && <img class="fieldicons" src="/front/images/name.svg" />}
                    <input
                        type="text"
                        pattern="[A-Za-z\s]+"
                        required
                        className="form-control"
                        placeholder="Your name"
                        name="full_name"
                        value={formData.full_name} onChange={handleInputChange}
                    />
                    <Form.Control.Feedback type="invalid">
                        Please provide a valid name (only letters and spaces allowed).
                    </Form.Control.Feedback>
                </div>
            </div>
            <div className="col-md-6 mb-3">
                <div class="iconfields">
                    {screen != 'footer' && <img class="fieldicons" src="/front/images/message.svg" />}
                    <input
                        type="email"
                        maxLength="50"
                        required
                        className="form-control"
                        placeholder="Your email"
                        name="email"
                        value={formData.email} onChange={handleInputChange}
                    />
                    <Form.Control.Feedback type="invalid">
                        Please provide a valid email (up to 50 characters).
                    </Form.Control.Feedback>
                </div>
            </div>
            <div className={(screen != 'footer') ? "col-md-6 mb-3" : "col-md-12 mb-3"}>
                <div class="iconfields">
                    {screen != 'footer' && <img class="fieldicons" src="/front/images/telephone.png" />}
                    <input
                        type="tel"
                        pattern="[0-9]{10}"
                        required
                        className="form-control"
                        placeholder="Your phone"
                        name="contact_number"
                        maxLength={10}
                        value={formData.contact_number} onChange={handleInputChange}
                    />
                    <Form.Control.Feedback type="invalid">
                        Please provide a valid phone number (exactly 10 digits).
                    </Form.Control.Feedback>
                </div>
            </div>

            {(screen != 'footer') ? <div className={"col-md-6 mb-3 "}>
                <div class="iconfields">
                <i class="fa fa-question-circle-o fieldicons" aria-hidden="true"></i>

                    <select  className='form-control' name='enquiry_type' required={true}>
                        <option value="">Enquiry type</option>
                        {EnquiryTypeJson.map(option => (
                            <option key={option.value} value={option.value}>
                                {option.key}
                            </option>
                        ))}
                    </select>
                </div>
            </div> : <><input type="hidden" name="enquiry_type" value="" /></>}



            {(screen != 'footer') ? <div className="col-md-12 mb-3">
                {/* {<img class="fieldicons" src="/front/images/telephone.png" />} */}
                <input
                    type="text"
                    required
                    className="form-control"
                    placeholder="Subject"
                    name="subject_type"
                    maxLength={150}
                    value={formData.subject_type} onChange={handleInputChange}
                />

            </div> : <input type="hidden" name="subject_type" value="" /> }



            <div className="col-md-12 mb-3">
                <textarea
                    className="form-control"
                    placeholder="Write your message here"
                    maxLength="300"
                    name='message'
                    value={formData.message} onChange={handleInputChange}
                ></textarea>
                <Form.Control.Feedback type="invalid">
                    Please provide a message (up to 300 characters).
                </Form.Control.Feedback>
            </div>

            <div className="col-md-12">
                <input type="submit" className="batn batn-orange" value="Submit" />
            </div>
            {
                serverResponce && <div className='col-md-12 mt-1'>
                    <p className='alert alert-success'>{serverResponce}</p>
                </div>
            }
        </Form>
    );
}

export default ContactFormComp;
