import React, { useState ,useEffect , Component} from 'react';
import { useDispatch } from 'react-redux';
import { docStatusValueCheck } from '../redux_state/reducres/checkDocumentstatus';

const CheckValidationComp = () => {
   
    const dispatch = useDispatch();
    const validationdataCheckData = JSON.parse(localStorage.getItem("validationdataCheckData"));
    const userdetails = JSON.parse(localStorage.getItem("squadGologin"));
    useEffect(() => {
        if (userdetails) {
            dispatch(docStatusValueCheck({ doctype: 'all', type: 'onload', sessiondata: validationdataCheckData }));
        }
    }, []);

    return ( <span className='' ></span>  );
};

export default CheckValidationComp;