import React, { useState, useEffect, Component } from 'react';
import SuperAdminSidebar from './superadmin_sidebar'
import axios from 'axios';
import { toast } from 'react-toastify';
const AdminWalletSetting = () => {


   const [formData, setFormData] = useState({
      name: '',
      price: '',
      coin_value: 1,
      description: '',
      manualsearch_charge: 2,
      quichsearch_per_match: 5,
      signup_bonus_jobseeker: 1,
      signup_bonus_recruiter: 25,
      referral_bonus_jobseeker: 1,
      referral_bonus_recruiter: 10,
      compensation_percent: 50,
      transaction_fee: 1,
      minimum_deposit: 1,
      minimum_withdrawal_coins: 10,
      minimum_redeem_coins: 5,
   });

   const fetchData = async () => {
      try {
         const response = await fetch('https://squadgoo.com.au/api.squadgoo.com.au/api/stripe/get_porduct_details');
         if (!response.ok) {
            throw new Error('Failed to fetch data');
         }
         const obj = await response.json();
         //  console.log(obj.data);
         setFormData({
            name: obj.data.name,
            price: obj.data.price,
            coin_value: obj.data.coin_value,
            description: obj.data.description,
            manualsearch_charge: obj.data.manualsearch_charge,
            quichsearch_per_match: obj.data.quichsearch_per_match,
            signup_bonus_jobseeker: obj.data.signup_bonus_jobseeker,
            signup_bonus_recruiter: obj.data.signup_bonus_recruiter,
            referral_bonus_jobseeker: obj.data.referral_bonus_jobseeker,
            referral_bonus_recruiter: obj.data.referral_bonus_recruiter,
            compensation_percent: obj.data.compensation_percent,
            transaction_fee: obj.data.transaction_fee,
            minimum_deposit: obj.data.minimum_deposit,
            minimum_withdrawal_coins: obj.data.minimum_withdrawal_coins,
            minimum_redeem_coins: obj.data.minimum_redeem_coins
         });
         // setProducts(data);
      } catch (error) {
         console.error('Error fetching data:', error);
      }
   };

   useEffect(() => {
      fetchData();
   }, []);


   const [errors, setErrors] = useState({});

   const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({
         ...formData,
         [name]: value
      });
   };

   const handleSubmit = async (e) => {
      e.preventDefault();

      // Validation
      const newErrors = {};
      Object.keys(formData).forEach((key) => {
         if (!formData[key]) {
            newErrors[key] = `${key} is required`;
         }
      });
      setErrors(newErrors);

      if (Object.keys(newErrors).length === 0) {


         const useriddata = JSON.parse(localStorage.getItem("squadGologinSession"));
         try {
            const formDataAction = new FormData();
            formDataAction.append('name', formData.name);
            formDataAction.append('price', formData.price);
            formDataAction.append('coin_value', formData.coin_value);
            formDataAction.append('description', formData.description);
            formDataAction.append('login_token', useriddata.login_token);


            formDataAction.append('manualsearch_charge', formData.manualsearch_charge);
            formDataAction.append('quichsearch_per_match', formData.quichsearch_per_match);
            formDataAction.append('signup_bonus_jobseeker', formData.signup_bonus_jobseeker);
            formDataAction.append('signup_bonus_recruiter', formData.signup_bonus_recruiter);
            formDataAction.append('referral_bonus_jobseeker', formData.referral_bonus_jobseeker);
            formDataAction.append('referral_bonus_recruiter', formData.referral_bonus_recruiter);
            formDataAction.append('compensation_percent', formData.compensation_percent);
            formDataAction.append('transaction_fee', formData.transaction_fee);
            formDataAction.append('minimum_deposit', formData.minimum_deposit);
            formDataAction.append('minimum_withdrawal_coins', formData.minimum_withdrawal_coins);
            formDataAction.append('minimum_redeem_coins', formData.minimum_redeem_coins);

            formDataAction.append('product_id', 1);
            const resData = await axios.post(`https://squadgoo.com.au/api.squadgoo.com.au/api/admin/product_update`, formDataAction, { headers: { 'Content-Type': 'multipart/form-data' } });
            if (resData.data.status === 1) {
               //console.log(resData.data.data)
               toast.success(resData.data.message);
               fetchData();
            } else {
               toast.warning(resData.data.message)
            }
         } catch (error) {
            console.log(error);
         }
      }
   };

   return (



      <>

         <div className="dashboarwrap">
            <div className="pagebanner">
               <div className="container">
                  <div className="row">
                     <div className="col-md-12">
                        <h2>Wallet Setting</h2>
                     </div>
                  </div>
               </div>
            </div>
            {/* <ToastContainer />*/}
            <div className="adminwrap py-4">
               <div className="container">
                  <div className="row">
                     <div className="col-md-3 adminsidebar">
                        <SuperAdminSidebar></SuperAdminSidebar>
                     </div>
                     <div className="col-md-9">
                        <div className="profilerightwraper stepscont commonform accountsettingpage">
                           <div className="card-box work-experiencesec commonform">
                              <div className="d-flex align-items-center justify-content-between">
                                 <h3 className="mb-3">Wallet Setting</h3>
                              </div>
                              <div className="editblock">
                                 <div className="editblockinnerbtm">
                                    <h5 className='mb-0 mt-3'>Product</h5>
                                    
                                    <form className='row' onSubmit={handleSubmit}>
                                       <div className="form-group col-md-6">
                                          <label>Name:</label>
                                          <input type="text" className={`form-control ${errors.name ? 'is-invalid' : ''}`} name="name" value={formData.name} onChange={handleChange} />
                                          {errors.name && <div className="invalid-feedback">{errors.name}</div>}
                                       </div>
                                       <div className="form-group col-md-6">
                                          <label>Price:</label>
                                          <input type="text" className={`form-control ${errors.price ? 'is-invalid' : ''}`} name="price" value={formData.price} onChange={handleChange} />
                                          {errors.price && <div className="invalid-feedback">{errors.price}</div>}
                                       </div>
                                       <div className="form-group col-md-6">
                                          <label>Coin Value:</label>
                                          <input type="number" className={`form-control ${errors.coin_value ? 'is-invalid' : ''}`} name="coin_value" value={formData.coin_value} onChange={handleChange} />
                                          {errors.coin_value && <div className="invalid-feedback">{errors.coin_value}</div>}
                                       </div>
                                       <div className="form-group col-md-12">
                                          <label>Description:</label>
                                          <textarea rows={5} className={`form-control ${errors.description ? 'is-invalid' : ''}`} name="description" value={formData.description} onChange={handleChange} />
                                          {errors.description && <div className="invalid-feedback">{errors.description}</div>}
                                       </div>


                                       <h5 className='mb-0 mt-3'>Charges Settings</h5>
                                       <div className="form-group col-md-6">
                                          <label>Manualsearch/match:</label>
                                          <input type="number" className={`form-control ${errors.manualsearch_charge ? 'is-invalid' : ''}`} name="manualsearch_charge" value={formData.manualsearch_charge} onChange={handleChange} />
                                       </div>
                                       <div className="form-group col-md-6">
                                          <label>Quichsearch/match:</label>
                                          <input type="number" className={`form-control ${errors.quichsearch_per_match ? 'is-invalid' : ''}`} name="quichsearch_per_match" value={formData.quichsearch_per_match} onChange={handleChange} />
                                       </div>


                                       <h5 className='mb-0 mt-3'>Signup bonus</h5>
                                       <div className="form-group col-md-6">
                                          <label>Jobseeker:</label>
                                          <input type="number" className={`form-control ${errors.signup_bonus_jobseeker ? 'is-invalid' : ''}`} name="signup_bonus_jobseeker" value={formData.signup_bonus_jobseeker} onChange={handleChange} />
                                          <label className={'hidden_text'}>After successful verified account , {formData.signup_bonus_jobseeker} coin per matched up to {formData.signup_bonus_jobseeker} matches , so up to {formData.signup_bonus_jobseeker} coins</label>
                                       </div>
                                       <div className="form-group col-md-6">
                                          <label>Recruiter/Individual:</label>
                                          <input type="number" className={`form-control ${errors.signup_bonus_recruiter ? 'is-invalid' : ''}`} name="signup_bonus_recruiter" value={formData.signup_bonus_recruiter} onChange={handleChange} />
                                          <label className={'hidden_text'}>{formData.signup_bonus_recruiter} coins , condition is to be verified and signed up , unable to withdraw ;only spend</label>
                                       </div>

                                       <h5 className='mb-0 mt-3'>Referral bonus</h5>
                                       <div className="form-group col-md-6">
                                          <label>Jobseeker: </label>
                                          <input type="number" className={`form-control ${errors.referral_bonus_jobseeker ? 'is-invalid' : ''}`} name="referral_bonus_jobseeker" value={formData.referral_bonus_jobseeker} onChange={handleChange} />
                                          <label className={'hidden_text'}> {formData.referral_bonus_jobseeker} coin per referral , condition is if the new user gets at least one match with recruiter/individua.</label>
                                       </div>
                                       <div className="form-group col-md-6">
                                          <label>Recruiter/Individual: </label>
                                          <input type="number" className={`form-control ${errors.referral_bonus_recruiter ? 'is-invalid' : ''}`} name="referral_bonus_recruiter" value={formData.referral_bonus_recruiter} onChange={handleChange} />
                                          <label className={'hidden_text'}> {formData.referral_bonus_recruiter} coins after depositing and spending {formData.referral_bonus_recruiter} coins (their own money,not bonus ) then person who referred gets 10 coin as bonus .</label>
                                       </div>

                                       <div className="form-group col-md-12">
                                          <h5 className=' mt-3'>Compensation in percent:</h5>
                                          <input type="number" className={`form-control ${errors.compensation_percent ? 'is-invalid' : ''}`} name="compensation_percent" value={formData.compensation_percent} onChange={handleChange} />
                                          <label className={'hidden_text'}> Jobseekers - if on quick search recruiter cancels the job after jobseekers left home or is on the way , then 50% of the charged amount goes to jobseeker and {formData.compensation_percent}% to admin . But if staff cancels the job , recruiter gets full refund auto .</label>
                                       </div>

                                       <div className="form-group col-md-6">
                                          <h5 className=' mt-3'>Transaction fee:</h5>
                                          <input type="number" className={`form-control ${errors.transaction_fee ? 'is-invalid' : ''}`} name="transaction_fee" value={formData.transaction_fee} onChange={handleChange} />
                                       </div>
                                       <div className="form-group col-md-6">
                                          <h5 className=' mt-3'>Minimum deposit:</h5>
                                          <input type="number" className={`form-control ${errors.minimum_deposit ? 'is-invalid' : ''}`} name="minimum_deposit" value={formData.minimum_deposit} onChange={handleChange} />
                                       </div>
                                       <div className="form-group col-md-6">
                                          <h5 className=' mt-3'>Minimum withdrawal coins:</h5>
                                          <input type="number" className={`form-control ${errors.minimum_withdrawal_coins ? 'is-invalid' : ''}`} name="minimum_withdrawal_coins" value={formData.minimum_withdrawal_coins} onChange={handleChange} />
                                       </div>
                                       <div className="form-group col-md-6">
                                          <h5 className=' mt-3'>Minimum redeem coins:</h5>
                                          <input type="number" className={`form-control ${errors.minimum_redeem_coins ? 'is-invalid' : ''}`} name="minimum_redeem_coins" value={formData.minimum_redeem_coins} onChange={handleChange} />
                                       </div>

                                       <div class="col-md-12 form-group mt-3"><button type="submit" class="batn batn-orange">Update</button></div>
                                    </form>


                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
}
export default AdminWalletSetting;