import React, { useState, useEffect, Component } from 'react';
import { useLocation , NavLink, Link , useNavigate } from 'react-router-dom';
import FindStaffModel from '../../admin/recruiter/findstaff_model'
import axios from 'axios';


import { useSelector, useDispatch } from 'react-redux'
//import { fsmodelhideandseek } from '../../redux_state/reducres/findStaffBtnAction';
//import { docStatusValueCheck } from '../../redux_state/reducres/checkDocumentstatus';
import { recdeshjobactioncheck } from '../../redux_state/reducres/findStaffBtnAction';

import debounce from 'lodash.debounce';
import CheckValidationComp  from '../../admin/CheckValidationComp';
import { useUser } from '../../admin/ApiDataContext';

const Header = () => {
   //let location = useLocation();
   //const count = useSelector((state) => state.fsmodelaction.value)
   const dispatch = useDispatch();
   const check_doc_status_data = useSelector((state) => state.check_doc_status.docsstate);
   const useriddata = JSON.parse(localStorage.getItem("squadGologinSession"));
   //const userdata =   JSON.parse(localStorage.getItem("squadGologinSession"));

   //const validationdataCheckData = JSON.parse(localStorage.getItem("validationdataCheckData"));
   const auth = localStorage.getItem('squadGologin');
   const userType = localStorage.getItem('squadGoUserType');
   const navigate = useNavigate();

   const [profilePicUrl, setProfilePicUrl] = useState('/front/images/user.svg');
   const [profileEditUrl, setProfileEditUrl] = useState('/front/images/user.svg');
   const [notificationDataArr, setNotificationDataArr] = useState([]);
   const [notificationDataReadCount, setNotificationDataReadCount] = useState(0);
   const [searchInputSetLaoding, setsearchInputSetLaoding] = useState(false);
   const [searchInputKey, setsearchInputKey] = useState(false);
   const [searchResuleData, setsearchResuleData] = useState([]);
   const [setCurrentPath, setsetCurrentPath] = useState('not_found');
   const  {profilePicUrlCA,profileEditUrlCA , resetContaxtData } =  useUser();
   
   
   const logout = () => {
      (async () => {
         try {
            const resData = await axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/logout/${useriddata.login_token}`);
            if (resData.data.status == 1) {
               console.log(resData.data)
            }
         } catch (error) { console.log(error); }
      })();

      resetContaxtData();
      localStorage.clear(); setTimeout(function () { navigate('/login'); }, 1000);
   };
   
   useEffect(() => {

      const pathname = window.location.pathname;
      setsetCurrentPath(pathname);
      if (useriddata) {
         setProfilePicUrl(profilePicUrlCA);
         setProfileEditUrl(profileEditUrlCA);
      }
   }, [useriddata]);

   useEffect(() => {
      if (useriddata) {      
        
         (async () => {
            try {
               const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/notification/" + useriddata.login_token + '/header?limit=5');
               if (resData.data.status == 1) {
                  setNotificationDataArr(resData.data.data);
                  setNotificationDataReadCount(resData.data.totalcount);
               }
            } catch (error) { console.log(error); }
         })();
      }
   }, []);

   const [isResumeModalShow, isSetResumeModalShow] = useState(false);
   //const sharePostResume = () => { isSetResumeModalShow(true); }


   
   const searchDataValue = (value) => {
      setsearchInputKey(value);
      searchDataValueFun(value)
   }
   const searchDataValueFun = debounce(async (term) => {
      if (term) {
         try {
            setsearchInputSetLaoding(true)
            const response = await axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/searchresult/${useriddata.login_token}/${term}?limit=5&server=local`);
            setsearchInputSetLaoding(false)
            if (response.data.status == 1) {
               setsearchResuleData(response.data.data.htmldata);
            }
         } catch (error) {
            console.error(error);
         }
      } else {
         setsearchResuleData([]);
      }
   }, 300);


   const clearAllNotifications = () => {
      (async () => {
         try {
            const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/notification/" + useriddata.login_token + '/header?limit=5&clearall=1');
            if (resData.data.status == 1) {
               setNotificationDataArr(resData.data.data);
               setNotificationDataReadCount(resData.data.totalcount);
            }
         } catch (error) { console.log(error); }
      })();
   }

   return (
      <>


         { auth && <CheckValidationComp />}
         { auth && userType == 'recruiter' && <FindStaffModel modelStatus={isResumeModalShow} ></FindStaffModel>}

        
         <nav className={(auth) ? 'navbar navbar-expand-md' : 'navbar navbar-expand-md headermain'} >
            <div className="container">
               <NavLink className="navbar-brand" to="/"><img src="/front/images/logo.svg" /></NavLink>

               <div className="mobilehright">
                  {
                     auth ?
                        <>
                           <div className="headerright ml-auto d-flex align-items-center">
                              <div className="hrightlinks comment active">
                                 <NavLink to="/chat"><img src="/front/images/comment.png"></img></NavLink>
                              </div>
                              <div className={`hrightlinks notification notificationdropwrap ${notificationDataReadCount ? 'active' : ''}`} >
                                 <div className="dropdown">
                                    <span className="dropdown-toggle" type="button" id="notificationdropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                       <img src="/front/images/bell.png"></img>
                                    </span>
                                    <div className="dropdown-menu notification-ui_dd" aria-labelledby="notificationdropdown">
                                       <div className="notification-ui_dd-header">
                                          <h3 className="text-center">Notifications</h3>
                                       </div>
                                       <div className="notification-ui_dd-content">
                                          {
                                             notificationDataArr.length ?
                                                <>
                                                   {
                                                      notificationDataArr.map((item, index) => (
                                                         <>
                                                            <a key={item.id} to={`${item.url}`} className={`notification-list notification-list${item.read_status == 0 ? '--unread' : ''} text-dark`}>
                                                               <div className="notification-list_img">
                                                                  <img src={`https://squadgoo.com.au/api.squadgoo.com.au/${item.send_by_details.profilepic}`} alt="user" />
                                                               </div>
                                                               <div className="notification-list_detail">
                                                                  <p><b className='text-capitalize'>{item.send_by_details.first_name + ' ' + item.send_by_details.last_name}</b> <br>
                                                                  </br><span className="text-muted">{item.keyvalue}</span></p>
                                                                  <p className="nt-link text-truncate" dangerouslySetInnerHTML={{ __html: item.message_value }}  ></p>
                                                               </div>
                                                               <p><small>{item.timeago}</small></p>
                                                            </a>
                                                         </>
                                                      ))
                                                   }
                                                </>
                                                :
                                                <>
                                                   <div className="notification-list notification-list--unread text-dark">
                                                      <div className="notification-list_detail">
                                                         <p><span className="text-muted">No notifications available</span></p>
                                                      </div>
                                                   </div>
                                                </>
                                          }
                                       </div>
                                       <div className="notification-ui_dd-footer">
                                          <NavLink to="/notifications" className="orange_clr mr-3 text-center">View All</NavLink>
                                          <NavLink to="/notifications" className="orange_clr mr-3 text-center">Clear All</NavLink>
                                       </div>
                                    </div>
                                 </div>
                              </div>

                              <div className={`hrightlinks wallerth ${-1 > 0 ? 'active' : ''}`}>
                                 <NavLink to={ userType == 'admin' ? '/admin-wallet' : userType == 'recruiter' ? '/recruiter/wallet'  : '/jobseeker/wallet'}  >
                                    <img src="/front/images/walleticon3.png"></img>
                                 </NavLink>
                              </div>

                              <div className="hrightlinks profile dropdown">
                                 <a className="dropdown-toggle" href="#" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img src={profilePicUrl}></img>
                                 </a>
                                 <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li className="nav-item">
                                       <NavLink className="nav-link" to={profileEditUrl}  >My Profile</NavLink>
                                       <NavLink className="nav-link" to={useriddata.user_type ==  3 ? '/jobseeker/reviews' : '/recruiter/reviews'}  >My Reviews</NavLink>
                                       <NavLink className="nav-link" to={useriddata.user_type ==  3 ?  '/jobseeker/transections' : '/recruiter/transections'}  >My Transection</NavLink>
                                       <NavLink className="nav-link" onClick={logout} to="/login"  >Logout</NavLink>
                                    </li>
                                 </ul>
                              </div>
                              {
                                 userType == 'recruiter' ?
                                    <span href="#" className="batn batn-orange findstaffbtn font10px" onClick={() => dispatch(recdeshjobactioncheck({ id: 'new', actiontype: 'preview', screentype: 'new_form' }))}  >Find a staff</span>
                                    : <></>
                              }

                           </div>
                        </>
                        : <></>
                  }

               </div>
               <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
               </button>


               <div className="collapse navbar-collapse" id="navbarSupportedContent">
                  {
                     (auth) ?
                        <>
                           <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                              {
                                 userType == 'jobseeker' ?
                                    <>
                                       <li className="nav-item"><NavLink className="nav-link" to="/jobseeker/dashboard">Dashboard</NavLink></li>
                                       <li className="nav-item"><NavLink className={`nav-link ${check_doc_status_data.wordexp_validation == 0 ? 'profile_incompleted' : ''}`} to="/jobseeker/work-experience">Work Experience {check_doc_status_data.wordexp_validation == 0 ? <span className="infowrap"><i className="fa fa-info"></i><span className="onhoverinfo">Please add workexperience</span></span> : ''}</NavLink></li>
                                       <li className="nav-item"><NavLink className={`nav-link ${check_doc_status_data.prederedjob_validation == 0 ? 'profile_incompleted' : ''}`} to="/jobseeker/preferred-jobs">Preferred Jobs {check_doc_status_data.prederedjob_validation == 0 ? <span className="infowrap"><i className="fa fa-info"></i><span className="onhoverinfo">Please add preferred job</span></span> : ''}</NavLink></li>
                                       <li className="nav-item"><NavLink className={`nav-link ${check_doc_status_data.userdoc_validation} ${check_doc_status_data.userdoc_validation == 0 ? 'profile_incompleted' : ''}`} to="/jobseeker/documents"> Documents {check_doc_status_data.userdoc_validation == 0 ? <span className="infowrap"><i className="fa fa-info"></i><span className="onhoverinfo">Please add document for profile</span></span> : ''}</NavLink></li>
                                       <li className="nav-item dropdown">
                                          <a className={`nav-link dropdown-toggle ${(setCurrentPath === '/support' || setCurrentPath === '/my-requests') ? 'active' : ''}` }   href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                             Help
                                          </a>
                                          <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                             
                                             <li><Link className="dropdown-item" to="/support" > Support</Link></li>
                                             <li><Link className="dropdown-item" to="/my-requests" >My Requests</Link></li>
                                          </ul>
                                       </li>
                                    </>
                                    :
                                    <>
                                       {
                                          userType == 'recruiter' ?
                                             <>

                                                <li className="nav-item">
                                                   <NavLink className="nav-link" to="/recruiter/dashboard">Dashboard</NavLink>
                                                </li>
                                                <li className="nav-item">
                                                   <NavLink className={`nav-link ${check_doc_status_data.userdoc_validation == 0 ? 'profile_incompleted' : ''}`} to="/recruiter/documents">Documents {check_doc_status_data.userdoc_validation == 0 ? <span className="infowrap"><i className="fa fa-info"></i><span className="onhoverinfo">Please add document for profile</span></span> : ''}</NavLink>
                                                </li>


                                                <li className="nav-item dropdown">
                                                   <a className={`nav-link dropdown-toggle ${(setCurrentPath === '/support' || setCurrentPath === '/my-requests') ? 'active' : ''}` }   href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                   {/* <a className={`nav-link dropdown-toggle` }   href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"> */}
                                                      Help
                                                   </a>
                                                   <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                      <li><Link className="dropdown-item" to="/support" >Support</Link></li>
                                                      <li><Link className="dropdown-item" to="/my-requests" >My Requests</Link></li>
                                                   </ul>
                                                </li>
                                             </>
                                             :
                                             <>
                                                <li className="nav-item">
                                                   <NavLink className="nav-link" to="/admin-dashboard">Dashboard</NavLink>
                                                </li>
                                             </>
                                       }
                                    </>
                              }
                           </ul>
                        </>
                        :
                        <>
                           <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                              <li className="nav-item">
                                 <NavLink className="nav-link" to="/">Home</NavLink>
                              </li>
                              <li className="nav-item">
                                 <NavLink className="nav-link" to="/jobseeker">Jobseeker</NavLink>
                              </li>
                              <li className="nav-item">
                                 <NavLink className="nav-link" to="/recruiter">Recruiter</NavLink>
                              </li>
                              <li className="nav-item">
                                 <NavLink className="nav-link" to="/aboutus">About Us</NavLink>
                              </li>
                              <li className="nav-item">
                                 <NavLink className="nav-link" to="/contactus">Contact Us</NavLink>
                              </li>
                           </ul>
                           <div className="headerrigntnew">
                              <NavLink className="batn batn-rounded rounded-orange" to="/login">Login</NavLink>
                           </div>
                           
                        </>
                  }
                  {
                     auth ?
                        <>
                           <div className="headerright ml-auto d-flex align-items-center">
                              {
                                 userType == 'recruiter' ?

                                    <span href="#" className="batn batn-orange findstaffbtn" onClick={() => dispatch(recdeshjobactioncheck({ id: 'new', actiontype: 'preview', screentype: 'new_form' }))}  >Find a staff</span>
                                    : ''
                              }
                              <div className="dropdown hrightlinks">
                                 <span className="searchicon" type="button" id="dropdownMenuButton" onClick={() => { setsearchInputKey(''); setsearchResuleData([]) }} data-bs-toggle="dropdown" aria-expanded="true" fdprocessedid="fes0cd">
                                    <i className="fa fa-search" aria-hidden="true"></i>
                                 </span>
                                 <div className="dropdown-menu searchui_dd" aria-labelledby="dropdownMenuButton" data-bs-popper="none">

                                    <div className="searchui_dd-header">
                                       <div className="chatsearchinner">
                                          <input className="form-control" value={searchInputKey} onChange={(e) => searchDataValue(e.target.value)} type="text" placeholder="Search here" fdprocessedid="ozoodq5" />
                                          <img src="/front/images/chatsearchleft.png" />
                                       </div>
                                    </div>
                                    {
                                       (searchInputKey.length > 0) ?
                                          <>
                                             <div className="searchui_dd-content">
                                                {
                                                   searchInputSetLaoding ?
                                                      <>
                                                         <p>Loading....</p>
                                                      </>
                                                      : (searchInputKey.length && searchResuleData) ?
                                                         <>
                                                            <div dangerouslySetInnerHTML={{ __html: searchResuleData }}></div>
                                                         </> :
                                                         <>
                                                            <p className="text-danger text-center">There are no result found</p>
                                                         </>
                                                }
                                              
                                             </div>
                                   
                                          </>

                                          :
                                          <></>
                                    }
                                 </div>
                              </div>


                              <div className={`hrightlinks comment ${-1 > 0 ? 'active' : ''}`}>
                                 <NavLink to={userType == 'admin' ? '/admin_users_chat' : '/chat'}  >
                                    <img src="/front/images/comment.png"></img>
                                 </NavLink>
                              </div>
                              <div className={`hrightlinks notification notificationdropwrap ${notificationDataReadCount > 0 ? 'active' : ''}`}>
                                 <div className="dropdown">
                                    <span className="dropdown-toggle" type="button" id="notificationdropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                       <img src="/front/images/bell.png"></img>
                                    </span>

                                    <div className="dropdown-menu notification-ui_dd" aria-labelledby="notificationdropdown">
                                       <div className="notification-ui_dd-header">
                                          <h3 className="text-center">Notification</h3>
                                       </div>
                                       <div className="notification-ui_dd-content">
                                          {
                                             notificationDataArr.length ?
                                                <>
                                                   {
                                                      notificationDataArr.map((item, index) => (
                                                         <>
                                                            <a key={item.id} to={`${item.url}`} className={`notification-list notification-list${item.read_status == 0 ? '--unread' : ''} text-dark`}>
                                                               <div className="notification-list_img">
                                                                  <img src={`https://squadgoo.com.au/api.squadgoo.com.au/${item.send_by_details.profilepic}`} alt="user" />
                                                               </div>
                                                               <div className="notification-list_detail">
                                                                  <p><b className='text-capitalize'>{item.send_by_details.first_name + ' ' + item.send_by_details.last_name}</b> <br>
                                                                  </br><span className="text-muted">{item.keyvalue}</span></p>
                                                                  <p className="nt-link text-truncate" dangerouslySetInnerHTML={{ __html: item.message_value }}  ></p>
                                                               </div>
                                                               <p><small>{item.timeago}</small></p>
                                                            </a>
                                                         </>
                                                      ))
                                                   }
                                                </>
                                                :
                                                <>
                                                   <div className="notification-list notification-list--unread text-dark">
                                                      <div className="notification-list_detail">
                                                         <p><span className="text-muted">There are no notifications</span></p>
                                                      </div>
                                                   </div>
                                                </>
                                          }
                                       </div>

                                       <div className="notification-ui_dd-footer text-center">
                                          <NavLink to="/notifications" className="orange_clr mr-3 text-center" >View All</NavLink>
                                          <NavLink onClick={clearAllNotifications} className="orange_clr mr-3 text-center">Clear All</NavLink>
                                       </div>


                                    </div>
                                    
                                 </div>
                              </div>
                              <div className={`hrightlinks wallerth ${-1 > 0 ? 'active' : ''}`}>
                                 <NavLink to={
                                       userType == 'admin' ? '/admin-wallet' :
                                       userType == 'recruiter' ? '/recruiter/wallet'  : '/jobseeker/wallet'
                                 } 
                                 >
                                    <img src="/front/images/wallet_icon.svg"></img>
                                 </NavLink>
                              </div>
                              <div className="hrightlinks profile dropdown">
                                 <a className="dropdown-toggle" href="#" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img src={profilePicUrl}></img>
                                 </a>
                                 <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li className="nav-item">
                                       {
                                          userType == 'admin' ?
                                             <>
                                                <NavLink className="nav-link" to={'/account-setting'}  >My Profile</NavLink>
                                                {/* <NavLink className="nav-link" to={'/reviews'}  >My Reviews</NavLink> */}
                                             </>
                                             :
                                             <>
                                                <NavLink className="nav-link" to={profileEditUrl}  >My Profile</NavLink>
                                                <NavLink className="nav-link" to={useriddata.user_type ==  3 ? '/jobseeker/reviews' : '/recruiter/reviews'}  >My Reviews</NavLink>
                                                <NavLink className="nav-link" to={useriddata.user_type ==  3 ?  '/jobseeker/transections' : '/recruiter/transections'}  >My Transection</NavLink>
                                             </>
                                       }
                                       <NavLink className="nav-link" onClick={logout} to="/login"  >Logout</NavLink>
                                    </li>
                                 </ul>
                              </div>


                           </div>
                        </>
                        : <></>
                  }
               </div>



            </div>
         </nav>
      </>
   );



}
export default Header;