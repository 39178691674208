import { createSlice } from '@reduxjs/toolkit'

export const userDataArrayValue = createSlice({
  name: 'userDataArrayValue',
  initialState: {
    userdata_array: null,
  }, 
  reducers: {
    userdataarray_status: (state , action) => {
         console.log(action.payload);
         state.userdata_array = action.payload;
    }
  },
})
export const { userdataarray_status } = userDataArrayValue.actions
export default userDataArrayValue.reducer