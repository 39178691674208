import React, { useState, useEffect, Component } from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink, useParams, useNavigate, Link } from 'react-router-dom';
import { Form, Button, Col, InputGroup, Modal, Row } from 'react-bootstrap';

import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';
//hideAndSeekMyRequestModel
import SuperAdminSidebar from './superadmin_sidebar';
import SuppReqConverationComp from '../SuppReqConverationComp';

const SupportRequest = () => {

    const typeofpriority = ['High', 'Medium', 'Low'];
    const statusType = ['All', 'Open', 'Awating', 'Solved'];

    const useriddata = JSON.parse(localStorage.getItem("squadGologinSession"));
    const [orderByData, setorderByData] = useState('ASC');
    const [myRequestDataArr, setmyRequestDataArr] = useState([]);
    const [myRequestDataArrCopy, setmyRequestDataArrCopy] = useState([]);
    const [screenLoader, setScreenLoader] = useState(true);
    const [editDataID, setEditDataID] = useState([]);
    const [myRequestDataFormArr, setmyRequestDataFormArr] = useState({
        title: '',
        description: '',
        priority: '',
        status: '',
    });
    const [reportFrom, setreportFrom] = useState('init_support_request');
    const userdetails = JSON.parse(localStorage.getItem("squadGologinSession"));
    const [moduleAccessibility] = useState(typeof (userdetails.accessibility) != undefined ? userdetails.accessibility.reviews : [{
        view: true,
        edit: true,
        delete: true,
    }]);
    const viewAccessibility = (moduleAccessibility != undefined && typeof (moduleAccessibility.view) != undefined) ? moduleAccessibility.view : true;
    const editAccessibility = (moduleAccessibility != undefined && typeof (moduleAccessibility.edit) != undefined) ? moduleAccessibility.edit : true;
    const deleteAccessibility = (moduleAccessibility != undefined && typeof (moduleAccessibility.delete) != undefined) ? moduleAccessibility.delete : true;




    const get_requesteddata = () => {
        setScreenLoader(true);
        (async () => {
            try {
                const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/get_myrequest/" + useriddata.login_token + '?order_by=' + orderByData);
                if (resData.data.status == 1) {
                    setmyRequestDataArr(resData.data.data);
                    setmyRequestDataArrCopy(resData.data.data);
                    setScreenLoader(false);
                }
            } catch (error) { console.log(error); }
        })();
    }

    useEffect(() => {
        if (useriddata) { get_requesteddata(); }
    }, [orderByData]);

    const [searchText, setSearchText] = useState('');
    const handleInput = (event) => {
        // setSearchTerm(event.target.value);
        let term = event.target.value;
        setSearchText(term);
        if (term.length > 0 && term != 'All') {

            const filteredData = myRequestDataArr.filter((item) =>
                item.status.toLowerCase().includes(term.toLowerCase()) ||
                item.id.toLowerCase().includes(term.toLowerCase()) ||
                item.title.toLowerCase().includes(term.toLowerCase()) ||
                item.description.toLowerCase().includes(term.toLowerCase())
            );
            setmyRequestDataArr(filteredData)
        } else {
            setmyRequestDataArr(myRequestDataArrCopy)
        }
    };

    const [selectStatus, setselectStatus] = useState('all');
    const handleInputByStatus = (event) => {
        // setSearchTerm(event.target.value);
        let term = event.target.value;
        setselectStatus(term)
        if (term.length > 0 && term != 'All') {

            const filteredData = myRequestDataArr.filter((item) =>
                item.status.toLowerCase().includes(term.toLowerCase())
            );
            setmyRequestDataArr(filteredData)
        } else {
            setmyRequestDataArr(myRequestDataArrCopy)
        }
    };





    const [hideAndSeekMyRequestModel, setHideAndSeekMyRequestModel] = useState(false);
    const [validated, setValidated] = useState(false);
    const onFinalSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) { event.preventDefault(); event.stopPropagation(); }
        else {

            try {
                const formData = new FormData();

                formData.append('title', event.target.title.value);
                formData.append('description', event.target.description.value);
                formData.append('priority', event.target.priority.value);
                formData.append('status', event.target.status.value);
                formData.append('login_token', useriddata.login_token);
                formData.append('report_form', reportFrom);
                formData.append('edit_id', editDataID);
                axios.post('https://squadgoo.com.au/api.squadgoo.com.au/add_support_new_request', formData)
                    .then((response) => {
                        if (response.status == 200 && response.data.status == 1) {
                            toast.success(response.data.message);
                            setHideAndSeekMyRequestModel(false)
                            get_requesteddata()
                        }
                        setEditDataID(0);
                    })
                    .catch((error) => { console.error(error); });
            } catch (error) { console.log(error); }
        }
        setValidated(true);
    };
    const getMyRequestData = (id, data) => {
        setEditDataID(id)
        setmyRequestDataFormArr({
            title: data.title,
            description: data.description,
            priority: data.priority,
            status: data.status,
        })
        setHideAndSeekMyRequestModel(true);
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Smooth scrolling behavior
        });
    };


    const [isShowDescription, setisShowDescription] = useState(false);
    const [sendingData, setsetSendingData] = useState(false);

    const getMyRequestDataView = (id, data) => {
        scrollToTop();
        setsetSendingData(data);
        setisShowDescription(true);
    }

    const setisShowDescriptionFun = () => {
        setisShowDescription();
        get_requesteddata();
    }

    return (
        <>
            {/* <ToastContainer />*/}
            <div className="dashboarwrap">

                <div className="pagebanner">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2>Tickets</h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="jobbtmsec py-5 helppage">
                    <div className="container">

                        <div className="row">
                            <div className="col-md-3 adminsidebar">
                                <SuperAdminSidebar></SuperAdminSidebar>
                            </div>
                            <div className="col-md-9">
                                <div className="box mb-4">

                                    {
                                        isShowDescription ?
                                            <><div className="reportblock">
                                                <a className="nav-link active p-0 mb-2" onClick={() => setisShowDescriptionFun(false)}>
                                                    <span className="back_to_hist1 cursor_pointer"><i className="fa fa-chevron-left" aria-hidden="true"></i>
                                                        Back to List
                                                    </span>
                                                </a>
                                                <SuppReqConverationComp data={sendingData} />
                                            </div>
                                            </>
                                            :
                                            <>
                                                <div className="table_filter mb-2 d-flex align-items-center justify-content-between commonform">
                                                    <div className="tblsearch">
                                                        <input type="text" className="form-control"
                                                            placeholder="Search..."
                                                            onChange={handleInput}
                                                            value={searchText}
                                                        />
                                                    </div>
                                                    <div className="tblrightfilters d-flex align-items-center">
                                                        <select className="form-control ml-3 text-capitalize" onChange={handleInputByStatus} >
                                                            {
                                                                statusType.map((item, index) => (
                                                                    <option selected={selectStatus == item} key={item} value={item}>{item}</option>
                                                                ))
                                                            }
                                                        </select>
                                                        <span onClick={() => {
                                                            setSearchText('');
                                                            setselectStatus('all');
                                                            setmyRequestDataArr(myRequestDataArrCopy)
                                                        }}
                                                            className="reseticon filtericon" data-toggle="tooltip" title="Reset Filter" >
                                                            <i className="fa fa-refresh"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="table-responsive">

                                                    <table className="table admintbl profiletbl tickettbl">
                                                        <thead>
                                                            <tr>
                                                                <th className='cursor_pointer width-100px' onClick={() => setorderByData(orderByData == 'ASC' ? 'DESC' : 'ASC')}>
                                                                    Ticket ID
                                                                    <i className={`fa fa-angle-${orderByData == 'ASC' ? 'down' : 'up'} ml-5`}></i>
                                                                </th>
                                                                <th>Title</th>
                                                                <th>Request by</th>
                                                                {/* <th>Description</th> */}
                                                                <th>Report From</th>
                                                                <th>Priority</th>
                                                                <th>Status</th>
                                                                <th>Request date</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {
                                                                screenLoader ?
                                                                    <>

                                                                        <tr>
                                                                            <td className="text-capitalize text-center" colSpan={8}> <p className='text-center'><img className='width-100 loader_image' src={'/front/images/loader.gif'} /></p></td>
                                                                        </tr>

                                                                    </>
                                                                    :
                                                                    <>
                                                                        {
                                                                            myRequestDataArr.length ?
                                                                                myRequestDataArr.map((item, index) => (
                                                                                    <tr>

                                                                                        <td className="text-capitalize">#{item.id}</td>
                                                                                        <td className='text-capitalize-first'>{item.title}</td>
                                                                                        <td className='text-capitalize'>
                                                                                        <Link className="text-website text-decoration-none1" to={`${item.user_data.user_type == 3 ? '/jobseeker-detail?backto=admintickethistory&id=' + item.user_data.unique_id : '/recruiter-detail?backto=admintickethistory&id=' + item.user_data.unique_id}`} >
                                                                                            {item.user_data.first_name + ' ' + item.user_data.last_name}
                                                                                        </Link>
                                                                                        </td>
                                                                                        {/* <td>{item.description}</td> */}
                                                                                        <td>
                                                                                            {
                                                                                                item.report_form == 'chat_support_request' ?
                                                                                                    <Link target='_blank' className='text-safron' to={`/chat-history?chat_token_id=${item.reference_id}`}>Chat</Link> :
                                                                                                    item.report_form == 'review_support_request' ?
                                                                                                        <Link target='_blank' className='text-safron' to={`/admin_all_reviews?review_id=${item.reference_id}`}>Review</Link> :
                                                                                                        'Ticket'
                                                                                            }
                                                                                        </td>
                                                                                        <td>{item.priority}</td>
                                                                                        <td><div className="tags">
                                                                                            {/* {item.status} */}
                                                                                            {
                                                                                                item.status == 'Awating' ?
                                                                                                <>In Progress</> :
                                                                                                item.status == 'Solved' ?
                                                                                                <>Closed</> : 
                                                                                                <>Open</>
                                                                                            }
                                                                                        </div></td>
                                                                                        <td>{item.created_date}</td>
                                                                                        <td>
                                                                                            <div className="d-flex">
                                                                                                {/* <Link onClick={() => { getMyRequestData(item.id, item) }} className="text-capitalize btnsmall  bluebtn" >Edit</Link> */}
                                                                                                {/* {viewAccessibility && <Link className="ml-3 faicon" onClick={() => { getMyRequestData(item.id, item) }} data-toggle="tooltip" title="Edit Report"> <img className='ediiconimg' src="/front/images/edit.png" /> </Link>} */}
                                                                                                {deleteAccessibility && <Link className="ml-3" onClick={() => { getMyRequestDataView(item.id, item) }} data-toggle="tooltip" title="View Report"><img src="/front/images/Show.svg" /></Link>}
                                                                                                {/* <Link className="text-capitalize btnsmall  bluebtn" >active</Link> */}
                                                                                            </div>

                                                                                        </td>
                                                                                    </tr>
                                                                                ))
                                                                                :
                                                                                <>
                                                                                    <tr>
                                                                                        <td className=" text-center" colSpan={8}>No information available to display</td>
                                                                                    </tr>
                                                                                </>
                                                                        }
                                                                    </>
                                                            }

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </>
                                    }

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <Modal show={hideAndSeekMyRequestModel} onHide={() => setHideAndSeekMyRequestModel(false)}>
                <div className="modal-dialog1">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button type="button" className="btn-close" onClick={() => setHideAndSeekMyRequestModel(false)} ></button>
                            <h3>Support</h3>
                            <div className="commonform">
                                <Form noValidate validated={validated} onSubmit={onFinalSubmit} >
                                    <div className="row">
                                        <div className="col-md-12 form-group mb-2">
                                            <label className="form-label" htmlFor="lastName1">Title <span className="starr">*</span></label>
                                            <input className="form-control" name='title' onChange={(e) => setmyRequestDataFormArr({ ...myRequestDataFormArr, ['title']: e.target.value })} value={myRequestDataFormArr.title} required={true} type="text" placeholder="Enter title" />
                                        </div>
                                        <div className="col-md-12 form-group mb-2">
                                            <label className="form-label" htmlFor="exampleInputEmail1">Description <span className="starr">*</span></label>
                                            <textarea className="form-control" name='description' onChange={(e) => setmyRequestDataFormArr({ ...myRequestDataFormArr, ['description']: e.target.value })} value={myRequestDataFormArr.description} maxLength={500} required={true} rows="5" placeholder="Enter description"></textarea>
                                        </div>
                                        <div className="col-md-12 form-group mb-2">
                                            <label className="form-label" htmlFor="phone">Priority <span className="starr">*</span></label>
                                            <select className="form-control" name='priority' onChange={(e) => setmyRequestDataFormArr({ ...myRequestDataFormArr, ['priority']: e.target.value })} required={true} >
                                                {
                                                    typeofpriority.map((item, index) => (
                                                        <option selected={myRequestDataFormArr.priority == item ? true : false} key={item} value={item}>{item}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        <div className="col-md-12 form-group mb-2">
                                            <label className="form-label" htmlFor="phone">Status <span className="starr">*</span></label>
                                            <select className="form-control text-capitalize" onChange={(e) => setmyRequestDataFormArr({ ...myRequestDataFormArr, ['status']: e.target.value })} required={true} >
                                                {
                                                    statusType.map((item, index) => (
                                                        <option selected={myRequestDataFormArr.status == item ? true : false} key={item} value={item}>{item}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>


                                        <div className="col-md-12 form-group mt-3">
                                            <button type='submit' className="batn batn-orange w-100">Send</button>
                                        </div>

                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}
export default SupportRequest;