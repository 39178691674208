import React, { useState, useEffect, Component } from 'react';
import axios from 'axios';
import 'react-datepicker/dist/react-datepicker.css';
import { Link } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';
import CheckValidationComp  from './CheckValidationComp';
const Notifications = () => {
   const useriddata = JSON.parse(localStorage.getItem("squadGologinSession"));

   const [notificationDataArr, setNotificationDataArr] = useState([]);
   const [screenLoader, setScreenLoader] = useState(true);


   const get_notification  =  (apiurl,type='onload') => {
         (async () => {
               try {
               const resData = await axios.get(apiurl);
                  if (resData.data.status == 1) {
                     setNotificationDataArr(resData.data.data);
                  
                     if(type == 'removed'){
                        toast.success(resData.data.message);
                     }
                  }
                  setScreenLoader(false);
               } catch (error) { console.log(error); }
         })();
   }

   useEffect(() => {
      if (useriddata) {
         get_notification("https://squadgoo.com.au/api.squadgoo.com.au/notification/" + useriddata.login_token+'/main');
      }
   }, []);

   const viewInReadStatus  = (id) => {
      get_notification("https://squadgoo.com.au/api.squadgoo.com.au/notification/" + useriddata.login_token+'/main?readstatus='+id,'view');
   }

   const deleteNotification  = (id) => {
      get_notification("https://squadgoo.com.au/api.squadgoo.com.au/notification/" + useriddata.login_token+'/main?deleteid='+id ,'removed');
   }
   


   return (
      <>
      {/* <ToastContainer />*/}
      
         <div className="dashboarwrap">
            <div className="pagebanner">
               <div className="container">
                  <div className="row">
                     <div className="col-md-12">
                        <h2>Notifications</h2>
                     </div>
                  </div>
               </div>
            </div>
            <div className="jobbtmsec py-5">
               <div className="container">
                  <div className="row">
                     <div className="col-md-12">
                        <div className="d-flex justify-content-end mb-4 notificationtoplinks"><a href="#" className="orange_clr" >View All</a><a className="ml-3 orange_clr" href="#">Clear All</a></div>
                        <div className="card-box notificationpage">
                           <div className="notification-ui_dd-content">
                              

                              {

                              screenLoader ?
                              <>
                                 <div className='loader_container'>
                                    <p className='text-center'><img className='width-100 loader_image' src={'/front/images/loader.gif'} /></p>
                                 </div>
                              </>
                              :
                              <>
                                 {
                                    notificationDataArr.length  ? 
                                          <>
                                             {
                                                notificationDataArr.map((item,index) => (
                                                   <>
                                                   <div key={item.id}  className={`notification-list notification-list${item.read_status == 0 ? '--unread' : ''} text-dark`}>
                                                      <div className="notification-list_img">
                                                         <img src={`https://squadgoo.com.au/api.squadgoo.com.au/${item.send_by_details.profilepic}`} alt="user" />
                                                      </div>
                                                      <div className="notification-list_detail">
                                                         <p><b className='text-capitalize'>{item.send_by_details.first_name+' '+item.send_by_details.last_name}</b> <br>
                                                         </br><span className="text-muted">{item.keyvalue}</span></p>
                                                         <p className="nt-link text-truncate" dangerouslySetInnerHTML={{ __html: item.message_value }}  ></p>
                                                      </div>
                                                      <p><small>{item.timeago}</small></p>
                                                      <br/>
                                                      <div className="d-flex">
                                                         <Link onClick={() => viewInReadStatus(item.id)} className="ml-3" >
                                                            <img src="/front/images/Show.svg" />
                                                         </Link>
                                                         <Link onClick={() => deleteNotification(item.id)}  className="ml-3" href="/recruiters">
                                                            <i className="faicon fa fa-trash" aria-hidden="true"></i> 
                                                         </Link>
                                                         </div>

                                                   </div>
                                                   </>
                                                )) 
                                             }
                                          </>
                                          :
                                          <>
                                          <div className="notification-list notification-list--unread text-dark">
                                             <div className="notification-list_detail">
                                                <p><span className="text-muted">No notifications available</span></p>
                                             </div>
                                          </div>
                                          </>
                                 }
                              </>

                                 
                              }


                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

         </div>
      </>
   );
}
export default Notifications;