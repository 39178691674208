import React from 'react';

const StrongPassMsgComp = ({ rating }) => {
    return (
        <>
            <span class="inforpop">
                <div class="infoicon">
                    <spn className="text-danger">Password must be strong</spn>
                    <i className=" infoocircle fa fa-info-circle ml-1 text-danger" ></i>
                </div>
                <span class="infodtl infodtlpass">
                    <h4 className=''>Password should be</h4>
                    <p>
                        <span><b>1.) </b>Password should have at least one capital letter.</span><br></br>
                        <span><b>2.) </b>Password should have at least one lowercase letter.</span><br></br>
                        <span><b>3.) </b>Password should have at least one numeric value.</span><br></br>
                        <span><b>4.) </b>Password should have at least one special character.</span><br></br>
                        <span><b>5.) </b>Password should have 8 character.</span>
                    </p>
                </span>
            </span>
        </>
    );
};
export default StrongPassMsgComp;