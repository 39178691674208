import React, { useEffect } from 'react';
import DocumentsComp  from '../DocumentsCom';
import CheckValidationComp  from '../CheckValidationComp';


const Documents = () => {
  
   const userdetails = JSON.parse(localStorage.getItem("squadGologin"));
   useEffect(() => {
      if (!userdetails) {
         setTimeout(function () { window.location.replace("/login"); }, 100)
      } 
   }, []);

   return (
      <>
         
         <div className="dashboarwrap">
            <div className="pagebanner">
               <div className="container">
                  <div className="row">
                     <div className="col-md-12">
                        <h2>Documents</h2>
                     </div>
                  </div>
               </div>
            </div>
               <div className="container">
               <div className="py-5">
                  <DocumentsComp />
                  </div>
               </div>
         </div>
      </>
   );
}
export default Documents;