import React, { useState , useCallback, useEffect } from 'react';
import RecWalletHist from '../WalletHistoryCom';
const JobseekerTransections = () => {


   const [recCoinsData, setRecCoinsData] = useState({
      current_squad_go_coins: 0,
      total_available_coins: 0,
      total_coins_purchased: 0,
      total_coins_used: 0,
      total_coins_withdraw: 0,
      total_coins_withdraw_pending: 0,
      total_reffered_coins: 0,
      transection_history: []
   });

   const handleChildDataChange = useCallback((newData) => { setRecCoinsData(newData); }, []);

   return (

      <>
         {/* <ToastContainer />*/}
         <div className="dashboarwrap">
            <div className="pagebanner">
               <div className="container">
                  <div className="row">
                     <div className="col-md-12">
                        <h2>Transections</h2>
                     </div>
                  </div>
               </div>
            </div>
            <div className="jobbtmsec py-5 admininfo">
               <div className="container">

                  <div className="row">
                     <div className="col-md-12">
                        <RecWalletHist onChildDataChange={handleChildDataChange} />
                     </div>
                  </div>
               </div>
            </div>

         </div>
      </>

   );
}
export default JobseekerTransections;