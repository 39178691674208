// React component using Twilio Video SDK
import React, { useEffect } from 'react';
import Video from 'twilio-video';

const VideoCall = () => {
  useEffect(() => {
    const accessToken = 'your_generated_access_token';
    const roomName = 'room123';

    Video.connect(accessToken, { name: roomName })
      .then((room) => {
        console.log('Connected to room:', room.name);

        room.on('participantConnected', (participant) => {
          console.log('Participant connected:', participant.identity);
        });
      })
      .catch((error) => console.error('Error connecting to room:', error));

    // Cleanup function
    return () => {
      // Disconnect from the room or handle any necessary cleanup
    };
  }, []);

  return <div>Video Call Component</div>;
};

export default VideoCall;
