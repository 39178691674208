import React, { useState, useEffect, Component } from 'react';
import { NavLink, Link } from "react-router-dom";
import ProfileNotCompleted from '../../front/pages/profilenotcompleted';
import { Form, Button, Col, InputGroup, Modal, Row } from 'react-bootstrap';

import axios from 'axios';

import { ToastContainer, toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';

import ChatwidgetList from './chat_widget_list.js';


import { useDispatch } from 'react-redux'
import { recdeshjobactioncheck } from '../../redux_state/reducres/findStaffBtnAction';
import { docStatusValueCheck } from '../../redux_state/reducres/checkDocumentstatus';
const Quicksearchdemo = () => {
   
   const [rating, setRating] = useState(1);
   const [review, setReview] = useState('');

   const handleRatingChange = (newRating) => {
      setRating(newRating);
   };

   const handleReviewChange = (e) => {
      setReview(e.target.value);
   };


   const StarRating = ({ rating, onRatingChange }) => {
      const stars = [1, 2, 3, 4, 5];
      return (
         <>
            <div className="star-rating">
               {
                  stars.map((star) => (
                     <span
                        key={star}
                        className={star <= rating ? 'star active' : 'star'}
                        onClick={() => onRatingChange(star)}
                     >
                        ★
                     </span>
                  ))
               }
            </div>
         </>
      )
   }



   return (
      <>
        {/* <ToastContainer />*/}
         <div className="dashboarwrap">
           
                     <div className="jobbanner">
                        <div className="container">
                           <div className="row">
                              <div className="col-md-12">
                                 <h2>Dashboard</h2>
                                 <form className="searchform d-flex">
                                    <input type="text" className="form-control" placeholder="Search for task"></input><input type="submit" value="Search"></input>
                                 </form>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="jobbtmsec py-4">
                        <div className="container">
                           <div className="row">
                              <div className="col-md-12">
                                 
                                 <div className="tab-content" id="myTabContent">
                                    <div className="row">
                                       <div className="col-md-3 recurutersidbar">
                                          <ChatwidgetList />
                                       </div>
                                       <div className="col-md-9">

                                       <div className="tab-pane fade  show active" id="activejob" role="tabpanel" aria-labelledby="activejob-tab">
                                          <div className="d-flex flex-wrap justify-content-between mb-2">
                                             <h3>3 Active job offers</h3>
                                             <div className="sort_option d-flex">
                                                <p>Sort by</p>
                                                <select className="form-control" fdprocessedid="qvwb18">
                                                   <option value="latest">Latest</option>
                                                   <option value="last_week">Last Week</option>
                                                   <option value="last_2_week">Last 2 Week</option>
                                                </select>
                                             </div>
                                          </div>
                                          <div className="joblistingwrap">
                                             <div className="card-box joblistbox">
                                                <div className="d-flex flex-wrap justify-content-between">
                                                   <h3 className="text-capitalize">Event Coordinator</h3>
                                                   <h3 className="job_price orange_clr">$12/Hour to $14/Hour</h3>
                                                </div>
                                                <div><span className="tags float-right bgdarkgreen">Quick Search</span></div>
                                                <div className="d-flex flex-wrap">
                                                   <div className="icontxt"><img src="/front/images/location.png"/> Location: <span>Indore</span></div>
                                                   <div className="icontxt"><img src="/front/images/bag.png"/> Number of staff: <span>5</span></div>
                                                </div>
                                                <div className="d-flex flex-wrap">
                                                   <a className="icontxt icontxtbtm icontxtview" href="/recruiter/dashboard"><img src="/front/images/eyeicon.png"/> Preview</a>
                                                   <a className="icontxt icontxtbtm icontxtedit" href="/recruiter/dashboard"><img src="/front/images/editicon.png"/>Update </a>
                                                   <a className="icontxt icontxtbtm icontxtedit" href="#"><img src="/front/images/user.svg"/>Candidates</a>
                                                   <a className="icontxt icontxtbtm icontxtdelete" href="/recruiter/dashboard"><img src="/front/images/deletenew.png"/> Delete</a>
                                                   <a className="icontxt icontxtbtm icontxtedit" href="" data-bs-toggle="modal" data-bs-target="#exampleModal"><img src="/front/images/reshedule.png"/> Reschedule</a>
                                                   <div className="icontxt icontxtbtm statics">Statistic:<span><span className="cursor_pointer total_staff text-orange" data-toggle="tooltip" data-placement="top" title="Total Staff need">1</span> |<span className="cursor_pointer total_pending  text-red" data-toggle="tooltip" data-placement="top" title="Total offer pending">0</span> |<span className="cursor_pointer total_accepted text-green" data-toggle="tooltip" data-placement="top" title="Total offer accepted">1</span></span></div>
                                                </div>
                                             </div>


                                             <div className="card-box joblistbox">
                                                <div className="d-flex flex-wrap justify-content-between">
                                                   <h3 className="text-capitalize">Plumber</h3>
                                                   <h3 className="job_price orange_clr">$12/Hour to $18/Hour</h3>
                                                </div>
                                                <div><span className="tags">Full Time</span><span className="tags float-right bgdarkgreen">Quick Search</span></div>
                                                <div className="d-flex flex-wrap">
                                                   <div className="icontxt"><img src="/front/images/location.png"/> Location: <span>Pune</span></div>
                                                   <div className="icontxt"><img src="/front/images/bag.png"/> Number of staff: <span>20</span></div>
                                                </div>
                                                <div className="d-flex flex-wrap" >
                                                   <Link  className="icontxt icontxtbtm icontxtview"><img src="/front/images/eyeicon.png"></img> Preview</Link>
                                                   <Link className="icontxt icontxtbtm icontxtedit"><img src="/front/images/user.svg"></img>Candidates</Link>
                                                   <Link className="icontxt icontxtbtm icontxtview"><img src="/front/images/eyeicon.png"></img> Repost</Link>
                                                   <Link className="icontxt icontxtbtm icontxtedit" data-bs-toggle="modal" data-bs-target="#exampleModal"><img src="/front/images/reshedule.png"></img>Reschedule</Link>
                                                   <Link className="icontxt icontxtbtm icontxtdelete" data-bs-toggle="modal" data-bs-target="#exampleModal1"><img src="/front/images/endjob.png"></img>End Job</Link>

                                                   {/* <button className="batn batn-orange" data-bs-toggle="modal" data-bs-target="#exampleModal"><img src="/front/images/reshedule.png"></img>Reschedule</button> */}
                                                 
                                                </div>
                                             </div>

                                          </div>
                                       </div>


                                       <div className="joblistingwrap">
                                          <div className="row">
                                             <div className="col-md-4 ">
                                                <div className="card-box joblistbox staflistw staflistwnew">
                                                   <div className="staffPprice_match ml-auto cursor_pointer">
                                                      <h3 className="job_price"></h3>
                                                      <div className="d-flex"><span className="matchbar"><span className="matchbariner w90"></span></span>90% matched</div>
                                                   </div>
                                                   <div className="d-flex flex-wrap stafflisttop">
                                                      <div className="staffimgg cursor_pointer"><img src="https://www.squadgoapi.ustowe.co.uk/uploads/users/1692698637profilepic18.jpg"/></div>
                                                      <div className="staffnamerating">
                                                         <h3 className="text-capitalize cursor_pointer">Vishal Sharma</h3>
                                                         <div className="reting d-flex">
                                                            <div className="stars d-flex">
                                                               <div className="star"><img src="/front/images/fillstar.svg"/></div>
                                                               <div className="star"><img src="/front/images/fillstar.svg"/></div>
                                                               <div className="star"><img src="/front/images/fillstar.svg"/></div>
                                                               <div className="star"><img src="/front/images/starredopen.png"/></div>
                                                               <div className="star"><img src="/front/images/starredopen.png"/></div>
                                                            </div>
                                                            <p><a className="m-0 cursor_pointer" href="/reviews?uniqueid=jobseeker-946693718">3.00 |<span className="ml-2">1 reviews</span></a></p>
                                                         </div>
                                                         <div className="d-flex flex-wrap stafdata">
                                                            <div className="icontxt">Location:<span className="text-capitalize">indore,dhar,bhoapl,guna,TEST,asd,test</span></div>
                                                         </div>
                                                         <div><button href="#" className="batn batn-orange small-btn">Withdraw offer</button></div>
                                                      </div>
                                                   </div>
                                                   
                                                </div>
                                             </div>
                                          </div>
                                       </div>

                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>

                   

                 


         </div>



         <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
            <div className="modal-content">
                  <div className="modal-body">
                     <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                     <h3>Job Reschedule</h3>
                     <div className="commonform">
                        <form novalidate="" className="">
                           <div className="row">
                              <div className="col-md-12 form-group mb-2">
                                  <label className="form-label" for="exampleInputEmail1">Rescheduled time<span className="starr">*</span></label>
                                  <input type="time" className="form-control" placeholder="Enter the rescheduled time"></input>
                              </div>
                              <div className="col-md-12 form-group mb-2"><label className="form-label" for="exampleInputEmail1">Resone for Reschedule.<span className="starr">*</span></label><textarea className="form-control" name="reschedule" maxlength="500" required="" rows="4" placeholder="Enter the resone for reschedule"></textarea></div>
                              
                              <div className="col-md-12 form-group mt-3"><button type="submit" className="batn batn-orange w-100" fdprocessedid="vhjem">Send Request</button></div>
                           </div>
                        </form>
                     </div>
                  </div>
               </div>
            </div>
            </div>



            <div className="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="example1ModalLabel" aria-hidden="true">
            <div className="modal-dialog">
            <div className="modal-content">
                  <div className="modal-body">
                     <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                     <h3>End Job</h3>
                     <div className="commonform">
                        <form novalidate="" className="">
                           <div className="row">
                             
                              <div className="col-md-12 form-group mb-2"><label className="form-label" for="exampleInputEmail1">Reason for ending the job.<span className="starr">*</span></label><textarea className="form-control" name="reschedule" maxlength="500" required="" rows="5" placeholder="Enter the resone for ending the job"></textarea></div>
                              
                              <div className="col-md-12 form-group mt-3">
                                 <Link  className="batn batn-orange w-100" data-bs-toggle="modal" data-bs-target="#exampleModal2">End Job</Link>
                                 
                              </div>
                           </div>
                        </form>
                     </div>
                  </div>
               </div>
            </div>
            </div>


            <div className="modal modallarg fade ratingmdl" id="exampleModal2" tabindex="-1" aria-labelledby="example2ModalLabel" aria-hidden="true">
            <div className="modal-dialog">
            <div className="modal-content">
                  <div className="modal-body">
                     <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                     <h3 className="text-center mb-0">Ratings and Reviews</h3>
                     <div className="commonform">
                         <Form>
                           <div className="row reviewlist">
                               <div className="col-md-6">
                               <div className="card-box joblistbox staflistw staflistwnew mt-3 ">
                                    <div className="d-flex flex-wrap stafflisttop">
                                       <div className="staffimgg cursor_pointer mb-0"><img src="https://www.squadgoapi.ustowe.co.uk/uploads/users/1692698637profilepic18.jpg"/></div>
                                       <div className="staffnamerating">
                                          <h3 className="text-capitalize cursor_pointer mb-0">Rajkumar jobseeker</h3>
                                          <div className="d-flex flex-wrap stafdata">
                                             <div className="icontxt">Location:<span className="text-capitalize">indore</span></div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="form-group mb-2">
                                       <label className="form-label" htmlFor="lastName1">Title <span className="starr">*</span></label>
                                       <input className="form-control" required={true} name='title' placeholder="Enter title" />
                                    </div>
                                    <div className="form-group mb-2">
                                       <label className="form-label" htmlFor="exampleInputEmail1">Description <span className="starr">*</span></label>
                                       <textarea className="form-control" required={true} name='description' rows="3" placeholder="Enter description"></textarea>
                                    </div>

                                    <div className="form-group mb-2">
                                       <label className="form-label" htmlFor="exampleInputEmail1">Rating <span className="starr">*</span></label>
                                       <StarRating rating={rating} onRatingChange={handleRatingChange} />
                                    </div>

                                    
                                 </div>
                               </div>

                               <div className="col-md-6">
                               <div className="card-box joblistbox staflistw staflistwnew mt-3 ">
                                    <div className="d-flex flex-wrap stafflisttop">
                                       <div className="staffimgg cursor_pointer mb-0"><img src="https://www.squadgoapi.ustowe.co.uk/uploads/users/1692698637profilepic18.jpg"/></div>
                                       <div className="staffnamerating">
                                          <h3 className="text-capitalize cursor_pointer mb-0">Vishal Sharma</h3>
                                          <div className="d-flex flex-wrap stafdata">
                                             <div className="icontxt">Location:<span className="text-capitalize">indore</span></div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="form-group mb-2">
                                       <label className="form-label" htmlFor="lastName1">Title <span className="starr">*</span></label>
                                       <input className="form-control" required={true} name='title' placeholder="Enter title" />
                                    </div>
                                    <div className="form-group mb-2">
                                       <label className="form-label" htmlFor="exampleInputEmail1">Description <span className="starr">*</span></label>
                                       <textarea className="form-control" required={true} name='description' rows="3" placeholder="Enter description"></textarea>
                                    </div>

                                    <div className="form-group mb-2">
                                       <label className="form-label" htmlFor="exampleInputEmail1">Rating <span className="starr">*</span></label>
                                       <StarRating rating={rating} onRatingChange={handleRatingChange} />
                                    </div>

                                    
                                 </div>
                               </div>

                           </div>

                           <div className="row">
                              <div className="col-md-12 form-group mt-3 d-flex justify-content-center">
                                 <button  className="batn batn-orange endbtn">Submit</button>
                              </div>
                           </div>
                        </Form>
                     </div>
                  </div>
               </div>
            </div>
            </div>
            


      </>
   );
}
export default Quicksearchdemo;