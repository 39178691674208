import React, { useState } from 'react';
import {  useNavigate }  from 'react-router-dom';

import { CardElement,  useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import {  toast } from 'react-toastify';

//import ProfileSidebar from './profile_sidebar';

  const PaymentForm = ({ productId ,productPrice , coins_want_purchase , coin_rate }) => {


    console.log(productPrice); 
    const navigate = useNavigate();
    const stripe = useStripe();
    const elements = useElements();
    const [paymentError, setPaymentError] = useState(null);
    const useriddata = JSON.parse(localStorage.getItem("squadGologinSession"));

    const [btnSubmit , setBtnSubmit] = useState(false);

    const handleSubmit = async (event) => {
      event.preventDefault();

      if (!stripe || !elements) {
        return;
      }

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
      });

      if (error) {
        console.error(error);
        setPaymentError(error.message);
        return;
      }

      try {
        setBtnSubmit(true);
        const formData = new FormData();
        formData.append('productPrice', productPrice);
        formData.append('productId', productId);
        formData.append('coinWantPurchase', coins_want_purchase);
        formData.append('coinRate', coin_rate);
        formData.append('loginToken', useriddata.login_token);
        formData.append('paymentMethodId', paymentMethod.id);
        formData.append('user_id', useriddata.user_id );
        formData.append('email', useriddata.email );
        formData.append('first_name', useriddata.first_name );
        formData.append('last_name', useriddata.last_name );
        //const response = await axios.post('https://squadgoo.com.au/api.squadgoo.com.au/api/stripe/handle-payment-with-card', formData);
        const response = await axios.post('https://squadgoo.com.au/api.squadgoo.com.au/api/stripe/payment_process', formData);
        //console.log(response.data.status == 1)
        if(response.status == 200 &&  response.data.status == 1){
          toast.success(response.data.message);
          // navigate('/recruiter/wallet');
          setBtnSubmit(false);
          window.location.reload();
        } else {
          setBtnSubmit(false);
          toast.warning(response.data.message);
        }
      } catch (err) {
        console.log(err);
      }
    };


    return (
       <>
      <img className='width-100percent payment_card' src="/front/images/cardaccepted.png" ></img>
      <form onSubmit={handleSubmit}>
        <div className="form-control">
          <CardElement />

        </div>
          {paymentError && <p className='mb-1'><div>{paymentError}</div></p>}
          <button  type="Submit" className="btn btn-success mt-2 mr-2" disabled={!stripe}>{btnSubmit ? 'Please wait...' : 'Pay Now'}</button>
          
      </form>
       </>
    );
};

export default PaymentForm;