import React, { useState, useEffect, Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';
import PrefferdJobCom from '../../PrefferdJobCom';
const JobSeekPrefferdJob = () => {

    const [userID, setuserID] = useState(0);
   useEffect(() => {
      const queryParameters = new URLSearchParams(window.location.search)
      const userid = queryParameters.get("id");
     // alert(userid)
      setuserID(userid)
   }, []); 


    return (
        <>
            <div className="card-box work-experiencesec seekerworkexperienceadmin commonform mb-3">
                <div className="row align-items-start editfieldss">
                    <div className="d-flex justify-content-between pb-0">
                        <h3 className="mb-3">Preferred Jobs</h3>
                    </div>
                    {
                  (userID == 0) ? 
                  <p className='text-center mt-10'><img className='width-100' src={'/front/images/loader.gif'} /></p>
                  :
                  <>
                  <PrefferdJobCom attr={userID} />
                  </>
               } 
                </div>
            </div>
        </>
    );
}
export default JobSeekPrefferdJob;