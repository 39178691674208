import React, { useState, useEffect, Component } from 'react';
import PayemntProcess from '../PayemntProcess';
import axios from 'axios';
const RecWalletCheckout = ({ sendMessageToParent ,callingby }) => {

    const   [productData , setProductData ] =  useState(
                    {
                        product_id:  1 ,
                        coin_value:  1 ,
                        name: '' ,
                        description: '' ,
                    }
            );

    //console.log(callingby != 'coinpurchase');
    const [productPrice , setProductPrice ] = useState(1);
    const [productDataLoading , setProductDataLoading ] = useState(false);
    const [productId , setProductID ] = useState(1);
    const [coinValue , setCoinValue ] = useState(productData.coin_value);
    const [noOfCoinsPurchase , setNoOfCoinsPurchase ] = useState(productData.coin_value);

    

    //const [isBackToParent, setIsBackToParent] = useState(true);
    const backToParentComponent  = () => {
        sendMessageToParent(false);
    }

    const getProductData  = async () => {
        try {
            const response = await axios.get('https://squadgoo.com.au/api.squadgoo.com.au/api/stripe/get_porduct_details');
            if (response.status === 200 &&  response.data.status == 1) {
                setProductData(response.data.data);
                setCoinValue(response.data.data.coin_value);
                setProductID(response.data.data.product_id);
                setProductDataLoading(true);
            } else {
                console.error('Error:', response.status);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }
    useEffect( () => {
        getProductData();
    },[]);

    const setTotalNumbersOfUsdFun = (amount) => {
        setProductPrice(amount);
    }

    const formattedNumber = (number) => {
        return Number(number).toFixed(2);
    }


    const setNoOfCoinsPurchaseFun = (coins) => {

        if (coins > coinValue) {
            setNoOfCoinsPurchase(coins);


            setProductPrice(coins/coinValue);
        }
    }

    
    const formatCoinToUSD = (coins) => {
        return  (coins/coinValue).toFixed(2);
    }

    //const [quantity, setQuantity] = useState(1);

    const increment = () => {
        setNoOfCoinsPurchase(prevQuantity => noOfCoinsPurchase + 1);
    };
  
    const decrement = () => {
      if (noOfCoinsPurchase > coinValue) {
        setNoOfCoinsPurchase(prevQuantity => noOfCoinsPurchase - 1);
      }
    };

    return (
        <>
            <div className="form-group mb-2">
                <div className="profilerightwraper_bk stepscont_bk walletblocks_bk">

                    <div className="form-group mb-2">
                        <div className="loginsignupwrap checkouwraper">
                            <div className="container_bk">
                           
                                <div className="row">
                                    <div className="col-md-12">
                                        {
                                        productDataLoading ? <div className="loginsignupwrapformwrap">
                                            {/* <form novalidate="" className="commonform lofinsignupform registerform checkoutpage"> */}
                                                <h3 className="text-center">Deposit Squad goo coin (SGC) </h3>


                                                {/* <div className="input-group">
                                                    <input type="number" className="form-control" value={quantity} onChange={e => setQuantity(parseInt(e.target.value))} />
                                                    <div className="input-group-append">
                                                    <button className="btn btn-outline-secondary" type="button" onClick={decrement}>-</button>
                                                    <button className="btn btn-outline-secondary" type="button" onClick={increment}>+</button>
                                                    </div>
                                                </div> */}

                                                <div className="form-group mb-2">
                                                    <label className="form-label">Coins</label>
                                                    <div className="iconfields">
                                                        <div className="iconfields">

                                                        <div className="input-group">
                                                            <input type="number" className="form-control" value={noOfCoinsPurchase} onChange={e => setNoOfCoinsPurchaseFun(parseInt(e.target.value))} />
                                                            <div className="input-group-append">
                                                            <button className="btn btn-outline-secondary" type="button" onClick={decrement}>-</button>
                                                            <button className="btn btn-outline-secondary" type="button" onClick={increment}>+</button>
                                                            </div>
                                                        </div>

                                                        {/* <input 
                                                                type="number" 
                                                                onChange={(e) => setNoOfCoinsPurchaseFun(e.target.value)} 
                                                                className="form-control" 
                                                                min={coinValue}
                                                                value={noOfCoinsPurchase} 
                                                                placeholder="Enter the number of coins"  
                                                            /> */}
                                                        
                                                        <input 
                                                                type="hidden" 
                                                                onChange={(e) => setTotalNumbersOfUsdFun(e.target.value)} 
                                                                className="form-control" 
                                                                value={productPrice} 
                                                                placeholder="Enter the number of usd for buy coins"  
                                                            />
                                                        </div>
                                                    </div>
                                                    <span className="note">1 AUD = {productData.coin_value} Coins</span>
                                                </div>
                                                {/* <div className='text-center bgbrown' >
                                                    <p className='m-0'>You are purchasing with ${formattedNumber(productPrice)} </p>
                                                    <h3 >{productPrice*coinValue} Coins</h3>
                                                </div> */}
                                                <div className='text-center bgbrown' >
                                                    <h4 className='m-0'>Amount to pay ${formatCoinToUSD(noOfCoinsPurchase)} </h4>
                                                    <h3 >{noOfCoinsPurchase} Coins</h3>
                                                </div>
                                                {/* <div className="text-left formbtmtxt">
                                                    <p>Note</p>
                                                </div> */}
                                                <div className="form-group mb-2 commonchecbox2 pt-2">
                                                    <>
                                                        {productData.description} 
                                                    </>
                                                </div>
                                                <div className="form-group mb-2 mt-4">
                                                    <PayemntProcess 
                                                        coins_want_purchase={noOfCoinsPurchase} 
                                                        coin_rate={coinValue} 
                                                        product_price={productPrice} 
                                                        product_id={productId} 
                                                    />
                                                    {callingby != 'coinpurchase' && <button onClick={backToParentComponent} className="btn btn-danger  backbtnchkout backbtnchkout2 ">Back</button>}
                                                </div>

                                               
                                            {/* </form> */}
                                        </div>
                                        :
                                        <>
                                            <img className='width-100 loader_image ml400px'  src={'/front/images/loader.gif'} />
                                        </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
}
export default RecWalletCheckout;