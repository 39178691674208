import SuperAdminSidebar from './superadmin_sidebar'
const SubscriptionListing = () => {
   return (
      <>
         <div className="dashboarwrap">
            {
               <>

<div className="pagebanner">
               <div className="container">
                  <div className="row">
                     <div className="col-md-12">
                        <h2>Subscription Listing</h2>
                     </div>
                  </div>
               </div>
            </div>


                  <div className="adminwrap py-4">
                     <div className="container">
                        <div className="row">
                           <div className="col-md-3 adminsidebar">
                              <SuperAdminSidebar></SuperAdminSidebar>
                           </div>
                           <div className="col-md-9">
                              <div className="row">
                                 <div className="col-md-12">
                                    <div className="box mb-4">
                                       <div className="table_filter mb-2 d-flex align-items-center justify-content-between commonform">
                                          <div className="tblsearch">
                                             <input type="text" className="form-control" placeholder="Search"></input>
                                          </div>
                                          <div className="tblrightfilters d-flex align-items-center">
                                             <select className="form-control">
                                                <option>all</option>
                                             </select>
                                             <select className="form-control ml-3">
                                                <option>Date</option>
                                             </select>
                                             <select className="form-control ml-3">
                                                <option>Status</option>
                                             </select>
                                          </div>
                                       </div>
                                       <div className="table-responsive">
                                          <table className="table admintbl job-offerstbl">
                                             <thead>
                                                <tr>
                                                   <th>Name</th>
                                                   <th>Email</th>
                                                   <th>Subscription Date</th>
                                                   <th>Plan</th>
                                                   <th>Payment Method</th>
                                                   <th>Status</th>
                                                   <th>Actions</th>
                                                </tr>
                                             </thead>
                                             <tbody>
                                                <tr>
                                                   <td><a>Jorg Marten</a></td>
                                                   <td>jorge@yopmail.com</td>
                                                   <td>22 November 2022</td>
                                                   <td>Basic</td>
                                                   <td><span className="tabltag">Stripe</span></td>
                                                   <td><a href="#" className="btnsmall bluebtn">Active</a></td>
                                                   <td>
                                                      <div className="d-flex">
                                                         <a href="#" className="ml-3"><img src="/front/images/Show.svg" /></a>
                                                         <a href="#" className="ml-3"><img src="/front/images/yellowmail.svg" /></a>
                                                         <a href="#" className="ml-3"><img src="/front/images/trash.svg" /></a>
                                                      </div>
                                                   </td>
                                                </tr>
                                                <tr>
                                                   <td><a>Jorg Marten</a></td>
                                                   <td>jorge@yopmail.com</td>
                                                   <td>22 November 2022</td>
                                                   <td>Basic</td>
                                                   <td><span className="tabltag">Stripe</span></td>
                                                   <td><a href="#" className="btnsmall bluebtn">Active</a></td>
                                                   <td>
                                                      <div className="d-flex">
                                                         <a href="#" className="ml-3"><img src="/front/images/Show.svg" /></a>
                                                         <a href="#" className="ml-3"><img src="/front/images/yellowmail.svg" /></a>
                                                         <a href="#" className="ml-3"><img src="/front/images/trash.svg" /></a>
                                                      </div>
                                                   </td>
                                                </tr>
                                                <tr>
                                                   <td><a>Jorg Marten</a></td>
                                                   <td>jorge@yopmail.com</td>
                                                   <td>22 November 2022</td>
                                                   <td>Basic</td>
                                                   <td><span className="tabltag">Stripe</span></td>
                                                   <td><a href="#" className="btnsmall bluebtn">Active</a></td>
                                                   <td>
                                                      <div className="d-flex">
                                                         <a href="#" className="ml-3"><img src="/front/images/Show.svg" /></a>
                                                         <a href="#" className="ml-3"><img src="/front/images/yellowmail.svg" /></a>
                                                         <a href="#" className="ml-3"><img src="/front/images/trash.svg" /></a>
                                                      </div>
                                                   </td>
                                                </tr>
                                                <tr>
                                                   <td><a>Jorg Marten</a></td>
                                                   <td>jorge@yopmail.com</td>
                                                   <td>22 November 2022</td>
                                                   <td>Basic</td>
                                                   <td><span className="tabltag">Stripe</span></td>
                                                   <td><a href="#" className="btnsmall bluebtn">Active</a></td>
                                                   <td>
                                                      <div className="d-flex">
                                                         <a href="#" className="ml-3"><img src="/front/images/Show.svg" /></a>
                                                         <a href="#" className="ml-3"><img src="/front/images/yellowmail.svg" /></a>
                                                         <a href="#" className="ml-3"><img src="/front/images/trash.svg" /></a>
                                                      </div>
                                                   </td>
                                                </tr>
                                                <tr>
                                                   <td><a>Jorg Marten</a></td>
                                                   <td>jorge@yopmail.com</td>
                                                   <td>22 November 2022</td>
                                                   <td>Basic</td>
                                                   <td><span className="tabltag">Stripe</span></td>
                                                   <td><a href="#" className="btnsmall bluebtn">Active</a></td>
                                                   <td>
                                                      <div className="d-flex">
                                                         <a href="#" className="ml-3"><img src="/front/images/Show.svg" /></a>
                                                         <a href="#" className="ml-3"><img src="/front/images/yellowmail.svg" /></a>
                                                         <a href="#" className="ml-3"><img src="/front/images/trash.svg" /></a>
                                                      </div>
                                                   </td>
                                                </tr>
                                                <tr>
                                                   <td><a>Jorg Marten</a></td>
                                                   <td>jorge@yopmail.com</td>
                                                   <td>22 November 2022</td>
                                                   <td>Basic</td>
                                                   <td><span className="tabltag">Stripe</span></td>
                                                   <td><a href="#" className="btnsmall bluebtn">Active</a></td>
                                                   <td>
                                                      <div className="d-flex">
                                                         <a href="#" className="ml-3"><img src="/front/images/Show.svg" /></a>
                                                         <a href="#" className="ml-3"><img src="/front/images/yellowmail.svg" /></a>
                                                         <a href="#" className="ml-3"><img src="/front/images/trash.svg" /></a>
                                                      </div>
                                                   </td>
                                                </tr>
                                                <tr>
                                                   <td><a>Jorg Marten</a></td>
                                                   <td>jorge@yopmail.com</td>
                                                   <td>22 November 2022</td>
                                                   <td>Basic</td>
                                                   <td><span className="tabltag">Stripe</span></td>
                                                   <td><a href="#" className="btnsmall bluebtn">Active</a></td>
                                                   <td>
                                                      <div className="d-flex">
                                                         <a href="#" className="ml-3"><img src="/front/images/Show.svg" /></a>
                                                         <a href="#" className="ml-3"><img src="/front/images/yellowmail.svg" /></a>
                                                         <a href="#" className="ml-3"><img src="/front/images/trash.svg" /></a>
                                                      </div>
                                                   </td>
                                                </tr>
                                                <tr>
                                                   <td><a>Jorg Marten</a></td>
                                                   <td>jorge@yopmail.com</td>
                                                   <td>22 November 2022</td>
                                                   <td>Basic</td>
                                                   <td><span className="tabltag">Stripe</span></td>
                                                   <td><a href="#" className="btnsmall bluebtn">Active</a></td>
                                                   <td>
                                                      <div className="d-flex">
                                                         <a href="#" className="ml-3"><img src="/front/images/Show.svg" /></a>
                                                         <a href="#" className="ml-3"><img src="/front/images/yellowmail.svg" /></a>
                                                         <a href="#" className="ml-3"><img src="/front/images/trash.svg" /></a>
                                                      </div>
                                                   </td>
                                                </tr>
                                                <tr>
                                                   <td><a>Jorg Marten</a></td>
                                                   <td>jorge@yopmail.com</td>
                                                   <td>22 November 2022</td>
                                                   <td>Basic</td>
                                                   <td><span className="tabltag">Stripe</span></td>
                                                   <td><a href="#" className="btnsmall bluebtn">Active</a></td>
                                                   <td>
                                                      <div className="d-flex">
                                                         <a href="#" className="ml-3"><img src="/front/images/Show.svg" /></a>
                                                         <a href="#" className="ml-3"><img src="/front/images/yellowmail.svg" /></a>
                                                         <a href="#" className="ml-3"><img src="/front/images/trash.svg" /></a>
                                                      </div>
                                                   </td>
                                                </tr>
                                                <tr>
                                                   <td><a>Jorg Marten</a></td>
                                                   <td>jorge@yopmail.com</td>
                                                   <td>22 November 2022</td>
                                                   <td>Basic</td>
                                                   <td><span className="tabltag">Stripe</span></td>
                                                   <td><a href="#" className="btnsmall bluebtn">Active</a></td>
                                                   <td>
                                                      <div className="d-flex">
                                                         <a href="#" className="ml-3"><img src="/front/images/Show.svg" /></a>
                                                         <a href="#" className="ml-3"><img src="/front/images/yellowmail.svg" /></a>
                                                         <a href="#" className="ml-3"><img src="/front/images/trash.svg" /></a>
                                                      </div>
                                                   </td>
                                                </tr>
                                             </tbody>
                                          </table>
                                          <div className="text-center"><a href="#" className="batn batn-orange mt-2">View All Subscriptions</a></div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </>
            }
         </div>
      </>
   );
}
export default SubscriptionListing;