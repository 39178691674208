import React, { useState, useEffect } from 'react';
import { Form, Modal } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import Select from 'react-select';

//import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux'
import { basicAddressValidationCheck } from '../redux_state/reducres/checkDocumentstatus';
import GoogleAutocomplete from '../expocomp/GoogleAutocomplate';
import ModalDialog from '../expocomp/WarningModal';
import { documentCheckValidation } from '../redux_state/reducres/checkDocumentstatus'
import customStyles from '../expocomp/SelectStyleComp';

const UserAddressComp = () => {
    const dispatch = useDispatch();
    const [isHidden, setIsHidden] = useState(true);
    //const [isHidden2, setIsHidden2] = useState(true);
    //const toggleHidden2 = () => { setIsHidden2(!isHidden2); };
    const userdetails = JSON.parse(localStorage.getItem("squadGologinSession"));
    //const [userDetailsArray, setUserDetailsArray] = useState([]);
    const [get_all_states, setGetallstates] = useState([]);
    const [subrubValue, setSubrubValue] = useState('');
    //const [varifyDocumentList, setVarifyDocumentList] = useState([]);
    //const [userUploadAddressDocumentsContent, setUserUploadAddressDocumentsContent] = useState([]);
    const [addressDetails, setAddressDetails] = useState({
        full_address: userdetails.full_address,
        address_unit_no: userdetails.address_unit_no,
        address_building_no: userdetails.address_building_no,
        address_street_name: userdetails.address_street_name,
        address_suburbs: userdetails.address_suburbs,
        address_user_state: userdetails.address_user_state,
        address_document_proof_type: userdetails.address_document_proof_type,
    });



    const [isAccVerified, setIsAccVerified] = useState('false');
    const getUserDetails = () => {
        (async () => {
            try {
                const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/userdetails/" + userdetails.unique_id);
                if (resData.data.status == 1) {
                    setAddressDetails(resData.data.data.results);
                    setUserDetailsArray(resData.data.data.results);
                    setSubrubValue(resData.data.data.results.address_suburbs);
                    setUserUploadAddressDocumentsContent(resData.data.data.results.address_document_proof);

                    setIsAccVerified(resData.data.data.results.verifications_status.statustype.address_details);
                }
            } catch (error) { console.log(error); }
        })();
    }

    const toggleHidden = () => {
        if (isHidden == true) {
            setAddressDetails({
                full_address: addressDetails.full_address,
                address_unit_no: addressDetails.address_unit_no,
                address_building_no: addressDetails.address_building_no,
                address_street_name: addressDetails.address_street_name,
                address_suburbs: addressDetails.address_suburbs,
                address_user_state: addressDetails.address_user_state,
                address_document_proof_type: addressDetails.address_document_proof_type,
            });
        }
        setIsHidden(!isHidden);
    };

    useEffect(() => {
        if (userdetails.unique_id) {
            getUserDetails();
            const sessionStorageData = JSON.parse(localStorage.getItem("sessionStorageData"));
            (async () => {

                if (sessionStorageData) {
                    setGetallstates(sessionStorageData.get_all_states);
                } else {
                    try {
                        const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/get_all_states");
                        if (resData.data.status == 1) { setGetallstates(resData.data.data.results); }
                    } catch (error) { console.log(error); }
                }

            })();
        } else { setTimeout(function () { window.location.replace("/login"); }, 100) }
    }, []);


    const [validated, setValidated] = useState(false);
    const [isDataUpdated, setIsDataUpDated] = useState(false);
    const formSubmitData = async () => {
        try {
            const formData = new FormData();
            formData.append('full_address', googleAddressValue);
            formData.append('address_unit_no', addressDetails.address_unit_no);
            formData.append('address_building_no', addressDetails.address_building_no);
            formData.append('address_street_name', addressDetails.address_street_name);
            formData.append('address_suburbs', subrubValue);
            formData.append('address_user_state', addressDetails.address_user_state);
            formData.append('is_user_loggedin', userdetails.login_token);
            if (userdetails.unique_id) {
                formData.append('userid', userdetails.unique_id);
            }
            const response = await axios.post(
                `https://squadgoo.com.au/api.squadgoo.com.au/userdetails_data_update`,
                formData,
                { headers: { 'Content-Type': 'multipart/form-data' } }
            );
            if (response.data.status === 1) {
                getUserDetails(); // Refresh user details
                toast.success(response.data.message);
                setIsModalShow(false); // Close the modal
                dispatch(documentCheckValidation(0));
                setIsDocumentsModalShow(true);

                if (userdetails.validationdata_check.basicaddress_validation == 0) {
                    /*redux validation update */
                    dispatch(basicAddressValidationCheck(1));
                    /*redux validation check end  */
                };

            } else {
                toast.warning(response.data.message);
                setIsModalShow(false); // Close the modal
            }
        } catch (error) {
            console.error('Error during form submission:', error);
            toast.error('An error occurred while submitting the form.');
        }
    };

    const onAddressDetailsSubmit = (e) => {
        e.preventDefault();
        const form = e.currentTarget;

        if (form.checkValidity() === false) {
            setValidated(true); // Show validation errors
            return; // Don't proceed if the form is invalid
        }

        if (isAccVerified == true) {
            setIsModalShow(true);
        } else {
            formSubmitData();
        }

        setValidated(false); // Reset validation state
    };




    //setSubrubValue(userdetails.address_suburbs);
    const addressDetailsUpdateFun = (event) => {
        const { name, value } = event.target;
        if (name === 'address_unit_no' && (!/^\d*$/.test(value))) {
            return; // Ignore non-numeric input
        }
        //name
        setAddressDetails((prevFormData) => ({ ...prevFormData, [name]: value }));
    };


    // console.log(addressDetails.address_suburbs);
    //  const subrubValueSubrubs =  addressDetails.address_suburbs ? addressDetails.address_suburbs :  userdetails.address_suburbs ; 
    const [subRubSggestions, setSubRubSggestions] = useState([])

    const setSubrubsIteminArray = (value) => { setSubrubValue(value); if (value.length > 2) { fetchSuggestions(value); } else { setSubRubSggestions([]) } };
    const getSubrubByli = (value) => { setSubrubValue(value); setSubRubSggestions([]) };
    const fetchSuggestions = async (value) => {
        try {
            const response = await axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/get_all_subrubs_name/${value}?responsetype=autocomplete`);
            const data = response.data;
            setSubRubSggestions(data);
        } catch (error) { console.error(error); }
    };




    const [address, setAddress] = useState({
        lat: '',
        lng: '',
        unit: '',
        houseNumber: '',
        streetName: '',
        suburb: '',
        city: '',
        state: '',
        country: 'Australia',
        placevalue: ''
    });

    const [googleAddressValue, setGoogleAddress] = useState(userdetails.full_address);
    const googleAuotoCompelte = (newAddress) => {
        setAddress(newAddress);
        setGoogleAddress(`${newAddress.placevalue}`)
    };
    const handleInputChange = (e) => { setGoogleAddress(e.target.value); };

    useEffect(() => {
        // console.log(address);
        if (address && address.houseNumber) {
            setAddressDetails((prevFormData) => ({ ...prevFormData, ['address_building_no']: address.houseNumber }));
        }
        if (address && address.state) {
            const stateObject = get_all_states.find((obj) => obj.state === address.state);
            if (stateObject) {
                setAddressDetails((prevFormData) => ({ ...prevFormData, ['address_user_state']: stateObject.id }));
            }
        }

        if (address && address.streetName) {
            setAddressDetails((prevFormData) => ({ ...prevFormData, ['address_street_name']: address.streetName }));
        }

        if (address && address.city) {
            setSubrubValue(address.city);
            //setAddressDetails((prevFormData) => ({ ...prevFormData, ['address_street_name']: address.city }));
        }
        
        if (address && address.country) {
            setAddress(address.country);
        }

        //countryconsole.log(address.country);
    }, [address]);


    /*--------------------------------------------------------*/
    const [isModalShow, setIsModalShow] = useState(false);
    const actionConfirmed = () => { formSubmitData(); };


    /*--------------------------------------------------------*/
    const [userUploadAddressDocumentsContent, setUserUploadAddressDocumentsContent] = useState([]);
    const [addressDocumentType, setAddressDocumentType] = useState([]);
    const [userDetailsArray, setUserDetailsArray] = useState([]);
    const [isDocumentsModalShow, setIsDocumentsModalShow] = useState(false);
    const documentModalHide = () => {
        setIsDocumentsModalShow(false);
        return false;
    }
    const [fileLoadingContent, setFileLoadingContent] = useState(false);
    useEffect(() => {
        const sessionStorageData = JSON.parse(localStorage.getItem("sessionStorageData"));
        console.log(sessionStorageData);
        if (sessionStorageData) {
            setAddressDocumentType(sessionStorageData.get_all_address_type_options);
        } else {
            (async () => {
                try {
                    const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/get_all_address_type");
                    if (resData.data.status == 1) { setAddressDocumentType(resData.data.data.option_array); }
                } catch (error) { console.log(error); }
            })();
        }
    }, []);

    const userFileUploadToServerAddress = async (field, file) => {
        const maxSizeInBytes = 5 * 1024 * 1024; // For example, 1 MB
        if (file.size > maxSizeInBytes) {
            console.log("File size is greater than the specified limit.");
            toast.warning('File size is greater than the specified limit.');
        } else {
            console.log("File size is within the specified limit.");
            try {
                const formData = new FormData();
                formData.append('file', file);
                formData.append('uploaded_for', field);
                formData.append('userid', userdetails.unique_id);
                formData.append('maxSizeInBytes', maxSizeInBytes);
                const response = await axios.post(
                    `https://squadgoo.com.au/api.squadgoo.com.au/userdetails_file_uploads`,
                    formData, { headers: { 'Content-Type': 'multipart/form-data' } });
                //console.log('File uploaded successfully:', response.data);
                if (response.data.status == 1) {
                    setUserUploadAddressDocumentsContent(response.data.data.upload_array)
                    //toast.success('Uploaded Successfully!');
                } else { /*toast.warning(response.data.message);*/ }
            } catch (error) {  /*console.error('Error uploading file:', error);*/ }
        }
    };

    const onAddressDuocumentSubmit = async (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
            return;
        }
        try {
            const formData = new FormData();
            formData.append('address_document_proof_type', e.target.address_document_proof_type.value);
            if (userdetails.unique_id) {
                formData.append('userid', userdetails.unique_id);
            }
            const response = await axios.post(
                `https://squadgoo.com.au/api.squadgoo.com.au/userdetails_data_update`,
                formData,
                { headers: { 'Content-Type': 'multipart/form-data' } }
            );
            if (response.data.status === 1) {
                toast.success(response.data.message);
                setIsDocumentsModalShow(false);
                setIsHidden(false);
            }
        } catch (error) {
            console.error('Error during form submission:', error);
            toast.error('An error occurred while submitting the form.');
        }
    };


    /*--------------------------*/
    const addressProofChange = (select) => {
        //setAddressProofType(select.value);
        setUserDetailsArray((prevData) => ({ ...prevData, 'address_document_type': select.value }))

    }

    return (
        <>
            {/* <ToastContainer />*/}
            <div className="profilerightwraper stepscont commonform basicdetail">

                <div className="card-box work-experiencesec commonform">
                    <div className="d-flex align-items-center1 justify-content-between">
                        <h3 className="mb-3">
                            Address
                            {
                                isAccVerified == true ?
                                    <><img className='veri_unveri_icon' src="/front/images/vfy.png" data-toggle="tooltip" data-placement="top" title="verified"></img></> :
                                    isAccVerified == false ?
                                        <><img className='veri_unveri_icon' src="/front/images/uvfy.png" data-toggle="tooltip" data-placement="top" title="Your Profile is in Review"></img></> :
                                        <></>
                            }
                        </h3>
                        {<div className="editbtn" onClick={toggleHidden}  ><img src="/front/images/edit.png" /></div>}
                    </div>
                    <div className="editblock">
                        <div className="editblockinner d-flex align-items-start">
                            <h4>
                                <div style={{ width: '100%' }}>
                                    <span className="mr-2 firstcoldtl">Full Address :</span>
                                    <span className='text-capitalize' >{googleAddressValue ? googleAddressValue : addressDetails.full_address ? addressDetails.full_address : '.......'}</span>
                                </div>
                            </h4>
                        </div>
                        <div className="editblockinner d-flex align-items-start">
                            <h4>
                            <div><span className="mr-2 firstcoldtl">Country:</span><span className='text-capitalize'>{userdetails.country_id == 1 ? "Australia" : '.......'}</span></div>
                                <div><span className="mr-2 firstcoldtl">State:</span><span className='text-capitalize'>{addressDetails.address_user_state_name ? addressDetails.address_user_state_name : '.......'}</span></div>
                                <div><span className="mr-2 firstcoldtl">Suburb :</span><span className='text-capitalize' >{addressDetails.address_suburbs ? addressDetails.address_suburbs : '.......'}</span></div>
                                <div><span className="mr-2 firstcoldtl">Unit no :</span><span className='text-capitalize' >{addressDetails.address_unit_no ? addressDetails.address_unit_no : '.......'}</span></div>
                                <div><span className="mr-2 firstcoldtl">House number :</span><span className='text-capitalize' >{addressDetails.address_building_no ? addressDetails.address_building_no : '.......'}</span></div>
                                <div><span className="mr-2 firstcoldtl">Street name :</span><span className='text-capitalize' >{addressDetails.address_street_name ? addressDetails.address_street_name : '.......'}</span></div>
                            </h4>
                        </div>

                        {!isHidden &&
                            <div className="editblockinnerbtm">
                                <Form noValidate validated={validated} onSubmit={onAddressDetailsSubmit}>
                                    <div className="row">

                                        <div className="col-md-12">
                                            <div className="d-flex ">
                                                <div className="form-group mr-2 w-100">
                                                    <label className="form-label">Full address <span className="starr">*</span></label>
                                                    {/* <input type="text" className="form-control" required name='full_address' value={addressDetails.full_address} maxLength={20} onChange={addressDetailsUpdateFun} placeholder="Full address" /> */}
                                                    <GoogleAutocomplete
                                                        onSelect={googleAuotoCompelte} required
                                                        name='full_address'
                                                        value={googleAddressValue}
                                                        // value={googleAddressValue}
                                                        placeHolder='Enter full address'
                                                        className="form-control"
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-6">


                                            <div className="d-flex ">

                                                <div className="form-group mr-2 w-100">
                                                    <label className="form-label">Country<span className="starr">*</span> </label>
                                                    <input type="text" readonly={true} className="form-control" required name='country' value={address.country ? address.country : 'Australia'}  />
                                                </div>

                                                <div className="form-group  w-100">
                                                    <label className="form-label">State<span className="starr">*</span></label>
                                                    <select className="form-control mr-2" required name="address_user_state" onChange={addressDetailsUpdateFun}>
                                                        <option value="">Select state</option>
                                                        {get_all_states.map((item, index) => (<option selected={addressDetails.address_user_state == item.id ? true : false} value={item.id} >{item.state}</option>))}
                                                    </select>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Suburb <span className="starr">*</span></label>
                                                {/* <input type="text" className="form-control" required  placeholder="Enter Suburb"   onChange={addressDetailsUpdateFun} value={addressDetails.address_suburbs} name='address_suburbs' /> */}
                                                <input type="text" className="form-control" required name='address_suburbs' value={subrubValue} placeholder="Enter your suburb" onChange={(e) => setSubrubsIteminArray(e.target.value)} />
                                                {
                                                    subRubSggestions.length ?
                                                        <>
                                                            <ul className='auto_search_ul'>
                                                                {
                                                                    subRubSggestions.map((suggestion) => (<li onClick={(e) => getSubrubByli(suggestion.name)} key={suggestion.id}>{suggestion.name}</li>))
                                                                }
                                                            </ul>
                                                        </>
                                                        :
                                                        ''
                                                }
                                            </div>
                                        </div>


                                        <div className="col-md-6">
                                            <div className="d-flex ">
                                                <div className="form-group mr-2 w-100">
                                                    <label className="form-label">Unit</label>
                                                    <input type="text" className="form-control" name='address_unit_no' value={addressDetails.address_unit_no} maxLength={20} onChange={addressDetailsUpdateFun} placeholder="Unit" />
                                                </div>
                                                <div className="form-group w-100">
                                                    <label className="form-label">House number <span className="starr">*</span> </label>
                                                    <input type="text" className="form-control" required name='address_building_no' value={addressDetails.address_building_no} maxLength={20} onChange={addressDetailsUpdateFun} placeholder="House number" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Street name <span className="starr">*</span></label>
                                                <input type="text" className="form-control" required name='address_street_name' value={addressDetails.address_street_name} maxLength={20} onChange={addressDetailsUpdateFun} placeholder="Enter Street name" />
                                            </div>
                                        </div>




                                        <div className="col-md-12 mt-3">
                                            <button className="batn batn-rounded rounded-orange mr-2">Save</button>
                                            <button onClick={(e) => setIsHidden(true)} className="batn batn-rounded rounded-orange mr-2">Cancel</button>
                                        </div>
                                    </div>
                                </Form>

                                {/* ---------------file upload data---------------- */}
                                <Modal show={isDocumentsModalShow} onHide={() => setIsDocumentsModalShow(false)} className="documentnewmodal">
                                    <div className="modal-dialog1 findastaffmodle">
                                        <div className="modal-content">
                                            <div className="modal-body p-3">
                                                <button type="button" className="btn-close" onClick={() => setIsDocumentsModalShow(false)} disabled={fileLoadingContent} ></button>
                                                <h4 className="">Upload document</h4>
                                                <div className="row stepscont">
                                                    <div className="col-md-12 stepscontright">
                                                        <div className="row">
                                                            <Form noValidate validated={validated} onSubmit={onAddressDuocumentSubmit}>
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div className="form-group mb-2">
                                                                            <label className="form-label">Document for address proof <span className="starr">*</span></label>
                                                                            <Select className='react_select' name="address_document_proof_type" required aria-label="4"
                                                                                options={addressDocumentType}
                                                                                isSearchable
                                                                                placeholder="Search..."
                                                                                styles={customStyles}
                                                                                onChange={addressProofChange}
                                                                                value={addressDocumentType.find(item => item.value == userDetailsArray.address_document_proof_type)}
                                                                            />
                                                                            {
                                                                                (userUploadAddressDocumentsContent.length > 0) && <input type="hidden" name="address_document_proof_type" value={userDetailsArray.address_document_proof_type} />
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <div className="form-group mb-2">
                                                                            <label className="form-label">Upload Document </label>
                                                                            <div className="addressproofdoc">

                                                                                {
                                                                                    (userUploadAddressDocumentsContent.length >= 2) ?
                                                                                        <><h5 className='cantupload'>You can't upload more document</h5></>
                                                                                        :
                                                                                        <>
                                                                                            <div className="customuploadsec1 form-group identityproof newfiletype">
                                                                                                <input className='form-control' type="file" accept="image/x-png,image/gif,image/jpeg ,application/pdf" onChange={(e) => userFileUploadToServerAddress('address_document_proof', e.target.files[0])} />
                                                                                            </div>
                                                                                            <p className="mt-2 purpleclr font12">jpg, pdf (Max file size 5 MB)</p>
                                                                                        </>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    {(userUploadAddressDocumentsContent.length < 2) && <div className="col-md-12">
                                                                        <button type="submit" className="batn batn-rounded rounded-orange mr-2">Save</button>
                                                                        <button type="button" onClick={(e) => documentModalHide()} className="batn batn-rounded rounded-orange mr-2">Cancel</button>
                                                                    </div>}

                                                                </div>
                                                            </Form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Modal>

                                {/* ---------------warning modal---------------- */}
                                <ModalDialog
                                    show={isModalShow}
                                    onClose={() => setIsModalShow(false)} // Close the modal without taking action
                                    onConfirm={actionConfirmed} // Action to take when confirming
                                    title="Existing details are already verified, Are you sure to change it?"
                                    message="This may cause you to wait for re-verification from Squad Goo."
                                />

                            </div>
                        }

                    </div>
                </div>
            </div>
        </>
    );
}
export default UserAddressComp;