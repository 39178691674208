import React, { useState, useEffect, Component } from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink, useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';

//import { Form, Button, Col, InputGroup, Modal, Row } from 'react-bootstrap';
//import axios from 'axios';


//import { ToastContainer, toast } from 'react-toastify';
////import 'react-toastify/dist/ReactToastify.css';

const ChatwidgetList = () => {

    const useriddata = JSON.parse(localStorage.getItem("squadGologinSession"));
    const [chatUserListArray, setChatUserListArray] = useState([]);
    const getChatUserList  = () => {
        (async () => {
            try {
                const resData = await axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/getchatlist_user/${useriddata.login_token}/${useriddata.user_type}`);
                if (resData.data.status == 1) { setChatUserListArray(resData.data.data.chatuserid); }
            } catch (error) { console.log(error); }
         })();
    }
   useEffect( () => { getChatUserList(); }, []);
   
   return (
      <>
      {/* <ToastContainer />*/} 
      <div className="recuritersidbar">
            <div className="recuritersidbarinner">
            <div className="recuritersidbarttl">
                <h4 className="d-flex mb-0">
                    {/* Inbox (3 Unread)  */}
                    <NavLink to={`/chat`}>View All</NavLink>
                </h4>
            </div>
            <div className="chatpanel">
                {
                    chatUserListArray.length  ? 
                    chatUserListArray.map((item,index) => (
                        
                        <div key={item.unique_id} className="chatsinglepnl d-flex">
                            <div className={`chatimg ${item.online == 1 ? 'online' : 'offline'}`}> 
                                <img src={item.profilepic ? 'https://squadgoo.com.au/api.squadgoo.com.au/'+item.profilepic  : '/front/images/user.svg'}></img>
                            </div>
                            <div className="chatmidleinfo">
                                    <Link className="chatlist_title" to={`/chat?contactto=${item.unique_id}`}>
                                        <h4 className='text-capitalize'>
                                            {item.first_name+' '+item.last_name }
                                        </h4>
                                    </Link>
                                <p>{item.last_message ? item.last_message : <><Link to={`/chat?contactto=${item.unique_id}`}>Start chat now</Link></>}</p>
                            </div>
                            {
                                item.totalcount ? 
                                <>
                                    <div className="chatendinfo">
                                    {/* <p>{item.time_many_ago}</p> */}
                                    <div><span className="msgcount">{item.totalcount}</span></div>
                                    </div>
                                </>
                                :
                                <></>
                            }
                        </div>
                    ))
                    :
                    <>
                        <div className="chatsinglepnl d-flex">
                        {/* start chat now */}
                        <p>There are no messages to show</p>
                        </div>
                    </>
                }
                 
            </div>
            </div>
        </div>
      </>
   );
}
export default ChatwidgetList;