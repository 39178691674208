import React, { useState ,useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route ,  NavLink , useParams , useNavigate ,Link }  from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';

const Varified = () => {
  const [userUniqueId, setUserUniqueId] = useState('');
  const [userType, setUserType] = useState(3);
  const [userTypeRole, setUserTypeRole] = useState('jobseeker');
  const [nextStep, setNextStep] = useState('signup');
  const navigate = useNavigate();

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search)
    const candidate = queryParameters.get("id");
    const status = queryParameters.get("status");
    setNextStep(status);
    window.scrollTo(0, 0);
    (async () => {
        try {
          const responceData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/userdetails/"+candidate);
            if(responceData.data.status == 1){ 
              setUserUniqueId(candidate); 
              setUserType(responceData.data.data.results.user_type) ;
              setUserTypeRole(responceData.data.data.results.user_type_role) ;
            }
            else {navigate('/login') }
        } catch (error) { console.log(error);}
    })();
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    if(userTypeRole == 'rec_indivisual'){ navigate('/indivisual-accountsetup?id='+userUniqueId);} 
    else if(userTypeRole == 'rec_reprasentative') { navigate('/recruiter-accountsetup?id='+userUniqueId);} 
    else { navigate('/accountsetup?id='+userUniqueId); }
  };
    
    return (
     <>
    
       <div className="loginsignupwrap">
            <div className="container">
               <div className="row">
                   <div className="col-md-12">
                      <div className="loginsignupwrapformwrap">
                          <form  onSubmit={handleSubmit} className="commonform lofinsignupform loginform">
                            <div className="text-center my-5"><img src="/front/images/varified.png"/></div>
                            <h2 className="text-center mb-2">Congratulations!</h2>
                            <div className="text-center formbtmtxt mb-4">
                              <p>Your email address has been verified successfully.</p>
                            </div>
                            <div className="form-group mb-2">
                               {
                                (nextStep == 'emailverification') ?
                                <>
                                  <Link className="batn batn-orange text-white" to={`/login`} >
                                  Continue to Login
                                  </Link>
                                </>
                                :
                                <><input type="Submit" className="batn batn-orange" value="Complete your profile"/> </>
                               }
                            </div>
                          </form>
                      </div>
                   </div>
               </div>
            </div>
       </div>
     </>
    );
  }
  
  export default Varified;
  

 