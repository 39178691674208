import React from 'react';
import { jsPDF } from 'jspdf';
//import { applyPlugin } from 'jspdf-autotable'

const PdfExportButton = ({ jsonData , export_for}) => {
   
      const exportToPdf3 = () => {
        const doc = new jsPDF();
    
        const totalWidth = 200; // Total width of the PDF
        var columnWidths = {
          Name: totalWidth * 0.2,
          Email: totalWidth * 0.3,
          // Divide the remaining width equally among other columns
          Contact: (totalWidth * 0.5) / 3,
          Unit_No: (totalWidth * 0.5) / 3,
          Status: (totalWidth * 0.5) / 3,
        };

        if(export_for ==  'walletHistory'){
            columnWidths = {
              'Transection Type': totalWidth * 0.2,
              Amount: (totalWidth * 0.5) / 3,
              Coins: (totalWidth * 0.5) / 3,
              Date: (totalWidth * 0.5) / 3,
            };
        }
    
        const cellHeight = 10;
        const lineHeight = 12;
    
        // Styling for the headers
        doc.setFont('helvetica', 'bold');
        doc.setFontSize(12);
    
        let currentX = 10;
        Object.entries(columnWidths).forEach(([columnName, width]) => {
          doc.text(columnName, currentX, 20);
          currentX += width;
        });
    
        // Styling for the data rows
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(10);
    
        jsonData.forEach((row, rowIndex) => {
          const yPos = 30 + rowIndex * lineHeight;
    
          let currentX = 10;
          Object.entries(columnWidths).forEach(([columnName, width]) => {
            const value = row[columnName] || ''; // Handle cases where a value might be undefined
            doc.text(value.toString(), currentX, yPos);
            currentX += width;
          });
        });
    
        doc.save('exported_data.pdf');
      };
    
  return (
    // <button onClick={exportToPdf}>Export to PDF</button>
    <span className='filtericon' onClick={exportToPdf3}  data-toggle="tooltip" title="Export in pdf">
                                    <img className="downloadicon" src="/front/images/pdficon.png" />
                                 </span>
  );
};

export default PdfExportButton;
