import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';
// import WorkExpComp from '../../WorkExpComp';


import WalletHist from '../../../admin/WalletHistoryCom';

const AdminWallet = (prop) => {

    const [getUserID, setGetUserID] = useState(0);
    const [getDataFromChild, setgetDataFromChild] = useState([]);
    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        const userid = queryParameters.get("id");
        setGetUserID(userid);
        //console.log("userid==="+userid);
    });

    const setgetDataFromChildFun = (data) => {
        setgetDataFromChild(data)
    }


    const [coinsData, setRecCoinsData] = useState({
        total_performance_coins: 0,
        total_compensation_coins: 0,
        total_reffered_coins: 0,
        total_withdraw_coins: 0,
        current_squad_go_coins: 0,
        total_available_coins: 0,
        total_coins_purchased: 0,
        total_coins_used: 0,
        total_coins_withdraw: 0,
        total_coins_withdraw_pending: 0,
        transection_history: []
    });
    const handleChildDataChange = useCallback((newData) => { setRecCoinsData(newData); }, []);

    return (
        <>

        
            <div className="row walletblocks ">


                <div className="col-md-12 mb-4">
                    <div className="box ststicsbox heiglightbox d-flex justify-content-between">
                        <div className="ststicsboxleft">
                            <h2>{coinsData.total_available_coins} <i className="fa fa-arrow-down" aria-hidden="true"></i></h2>
                            <p>Current Squad Goo wallet balance</p>
                        </div>
                    </div>
                </div>


                {(prop.user_type == 2) && <div className="row">
                    <div className="col-md-20 col-sm-6 mb-4">
                        <div className="box ststicsbox d-flex justify-content-between greenbg">
                            <div className="ststicsboxleft">
                                <p>Coins purchases</p>
                                <h2>{coinsData.total_coins_purchased} <i className="fa fa-arrow-down" aria-hidden="true"></i></h2>
                                {/* <span onClick={()=> {backTotheTransection(false) ; setTransectionType('coins_purchases')}  } className="batn batn-orange">Purchased</span> */}
                            </div>

                            <div className="ststicsboxright"><img src="/front/images/ion.png" /></div>
                        </div>
                    </div>
                    <div className="col-md-20 col-sm-6 mb-4">
                        <div className="box ststicsbox d-flex justify-content-between greenbg">
                            <div className="ststicsboxleft">
                                <p>Refferred coins</p>
                                <h2 className="dashboard_tile"><a href="#">{coinsData.total_reffered_coins}  <i className="fa fa-arrow-down" aria-hidden="true"></i></a></h2>
                            </div>
                            <div className="ststicsboxright"><img src="/front/images/loss.png" /></div>
                        </div>
                    </div>
                    <div className="col-md-20 col-sm-6 mb-4">
                        <div className="box ststicsbox d-flex justify-content-between redbg">
                            <div className="ststicsboxleft">
                                <p>Total coins used</p>
                                <h2 className="dashboard_tile">
                                    <a href="#" >{coinsData.total_coins_used}  <i className="fa fa-arrow-up" aria-hidden="true"></i></a></h2>

                            </div>
                            <div className="ststicsboxright"><img src="/front/images/deposit.png" /></div>
                        </div>
                    </div>
                    <div className="col-md-20 col-sm-6 mb-4">
                        <div className="box ststicsbox d-flex justify-content-between redbg">
                            <div className="ststicsboxleft">
                                <p>Pending withdrawn</p>
                                <h2 className="dashboard_tile">
                                    <a href="#">{coinsData.total_coins_withdraw_pending}  <i className="fa fa-arrow-up" aria-hidden="true"></i></a></h2>

                            </div>
                            <div className="ststicsboxright"><img src="/front/images/jobcin.png" /></div>
                        </div>
                    </div>
                    <div className="col-md-20 col-sm-6 mb-4">
                        <div className="box ststicsbox d-flex justify-content-between redbg">
                            <div className="ststicsboxleft">
                                <p>Total withdrawn</p>
                                <h2 className="dashboard_tile">
                                    <a href="#">{coinsData.total_coins_withdraw}  <i className="fa fa-arrow-up" aria-hidden="true"></i></a></h2>

                            </div>
                            <div className="ststicsboxright"><img src="/front/images/jobcin.png" /></div>
                        </div>
                    </div>
                </div>}

                {(prop.user_type == 3) && <div className="row">
                    <div className="col-md-20 col-sm-6 mb-4">
                        <div className="box ststicsbox d-flex justify-content-between greenbg">
                            <div className="ststicsboxleft">
                                <p>Performance coins</p>
                                <h2>{coinsData.total_performance_coins} <i className="fa fa-arrow-down" aria-hidden="true"></i></h2>
                            </div>
                            <div className="ststicsboxright"><img src="/front/images/ion.png" /></div>
                        </div>
                    </div>
                    <div className="col-md-20 col-sm-6 mb-4">
                        <div className="box ststicsbox d-flex justify-content-between greenbg">
                            <div className="ststicsboxleft">
                                <p>Compensation coins</p>
                                <h2>{coinsData.total_compensation_coins} <i className="fa fa-arrow-down" aria-hidden="true"></i></h2>
                            </div>
                            <div className="ststicsboxright"><img src="/front/images/ion.png" /></div>
                        </div>
                    </div>
                    <div className="col-md-20 col-sm-6 mb-4">
                        <div className="box ststicsbox d-flex justify-content-between greenbg">
                            <div className="ststicsboxleft">
                                <p>Referral coins</p>
                                <h2 className="dashboard_tile"><a href="/recruiters?backto=dashboard">{coinsData.total_reffered_coins} <i className="fa fa-arrow-down" aria-hidden="true"></i></a></h2>

                            </div>
                            <div className="ststicsboxright"><img src="/front/images/deposit.png" /></div>
                        </div>
                    </div>
                    <div className="col-md-20 col-sm-6 mb-4">
                        <div className="box ststicsbox d-flex justify-content-between redbg">
                            <div className="ststicsboxleft">
                                <p>Pending withdrawn</p>
                                <h2 className="dashboard_tile">
                                    {coinsData.total_withdraw_coins}
                                    <i className="fa fa-arrow-up" aria-hidden="true"></i>

                                </h2>
                            </div>
                            <div className="ststicsboxright"><img src="/front/images/loss.png" /></div>
                        </div>
                    </div>

                    <div className="col-md-20 col-sm-6 mb-4">
                        <div className="box ststicsbox d-flex justify-content-between redbg">
                            <div className="ststicsboxleft">
                                <p>Total withdrawn</p>
                                <h2 className="dashboard_tile">
                                    {coinsData.total_withdraw_coins} <i className="fa fa-arrow-up" aria-hidden="true"></i>
                                </h2>
                            </div>
                            <div className="ststicsboxright"><img src="/front/images/loss.png" /></div>
                        </div>
                    </div>
                </div>}

                {/* <div className="col-md-20 col-sm-6 mb-4">
                    <div className="box ststicsbox d-flex justify-content-between greenbg">
                        <div className="ststicsboxleft">
                            <p>Performance coins</p>
                            <h2>{recCoinsData.total_performance_coins} <i className="fa fa-arrow-down" aria-hidden="true"></i></h2>
                        </div>
                        <div className="ststicsboxright"><img src="/front/images/ion.png" /></div>
                    </div>
                </div>
                <div className="col-md-20 col-sm-6 mb-4">
                    <div className="box ststicsbox d-flex justify-content-between greenbg">
                        <div className="ststicsboxleft">
                            <p>Compensation coins</p>
                            <h2>{recCoinsData.total_coins_compensation_paid} <i className="fa fa-arrow-down" aria-hidden="true"></i></h2>
                        </div>
                        <div className="ststicsboxright"><img src="/front/images/ion.png" /></div>
                    </div>
                </div>
                <div className="col-md-20 col-sm-6 mb-4">
                    <div className="box ststicsbox d-flex justify-content-between greenbg">
                        <div className="ststicsboxleft">
                            <p>Referral coins</p>
                            <h2 className="dashboard_tile"><a href="/recruiters?backto=dashboard">{recCoinsData.total_coins_refundpaid} <i className="fa fa-arrow-down" aria-hidden="true"></i></a></h2>

                        </div>
                        <div className="ststicsboxright"><img src="/front/images/deposit.png" /></div>
                    </div>
                </div>
                <div className="col-md-20 col-sm-6 mb-4">
                    <div className="box ststicsbox d-flex justify-content-between redbg">
                        <div className="ststicsboxleft">
                            <p>Withdrawn pending</p>
                            <h2 className="dashboard_tile"><a href="/jobseekers?backto=dashboard">{recCoinsData.total_coins_withdraw_pending} <i className="fa fa-arrow-up" aria-hidden="true"></i></a></h2>
                        </div>
                        <div className="ststicsboxright"><img src="/front/images/loss.png" /></div>
                    </div>
                </div>
                <div className="col-md-20 col-sm-6 mb-4">
                    <div className="box ststicsbox d-flex justify-content-between redbg">
                        <div className="ststicsboxleft">
                            <p>Total withdrawn</p>
                            <h2 className="dashboard_tile"><a href="/jobseekers?backto=dashboard">{recCoinsData.total_coins_withdraw_pending} <i className="fa fa-arrow-up" aria-hidden="true"></i></a></h2>
                        </div>
                        <div className="ststicsboxright"><img src="/front/images/loss.png" /></div>
                    </div>
                </div> */}


            </div>
            <div className="row">
                <div className="col-md-12">
                    <WalletHist onChildDataChange={handleChildDataChange} />
                </div>
            </div>

        </>
    );
}
export default AdminWallet;
