import React, { useState, useEffect, Component } from 'react';
import  PrefferdJobCom from '../PrefferdJobCom';
import CheckValidationComp  from '../CheckValidationComp';

const Jobwanttoapply = () => {
   const userdetails = JSON.parse(localStorage.getItem("squadGologin"));
   useEffect(() => {
      if (!userdetails) {
         setTimeout(function () { window.location.replace("/login"); }, 100)
      } 
   }, []);

   return (
      <>
          
         <div className="dashboarwrap">
            <div className="pagebanner">
               <div className="container">
                  <div className="row">
                     <div className="col-md-12">
                        <h2>Preferred Jobs</h2>
                     </div>
                  </div>
               </div>
            </div>
            <div className="jobbtmsec py-5">
               <div className="container">
                  <div className="row">
                     <PrefferdJobCom />
                  </div>
               </div>
            </div>
         </div>
      </>
   );
}
export default Jobwanttoapply;