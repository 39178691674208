// import React, { useRef, useEffect } from 'react';

// const AutocompleteInput = () => {
//     const autocompleteInputRef = useRef(null);

//     useEffect(() => {
//         if (window.google && window.google.maps && autocompleteInputRef.current) {
//             const autocompleteService = new window.google.maps.places.AutocompleteService();
//             const autocomplete = new window.google.maps.places.Autocomplete(autocompleteInputRef.current, {
//                 types: ['geocode'],
//                 componentRestrictions: { country: 'AU' } // Restrict to Australia
//             });

//             // Optional: Add event listeners or handle selected place
//         }
//     }, []);

//     return <input ref={autocompleteInputRef} placeholder="Enter Australian suburb" />;
// };

// export default AutocompleteInput;
import React, { useState } from 'react';
import LiveChatWindow from './admin/LiveChatWindow';

// Chat window component
// Chat window component
const ChatWindow = ({ user, onClose, onMinimize }) => {
    const [minimized, setMinimized] = useState(false);
    const [hidden, setHidden] = useState(false);
    const windowId = 1234;
    const handleMinimize = () => {
        setMinimized(!minimized);
        onMinimize();
    };
    return (
        <>
            <div className="livechatbox">
                <div className="card dddddd" id="chat2">
                    <div className="card-header d-flex justify-content-between align-items-center p-3">
                        <h5 className="mb-0">Live Chat {user.id} </h5>
                        <span  >
                            <i onClick={handleMinimize} class={`cursor_pointer fa fa-angle-${minimized ? 'up' : 'down'}`} aria-hidden="true"></i>

                            <i onClick={onClose} class={`fa fa-close ml-2 cursor_pointer`} aria-hidden="true"></i>
                        </span>
                    </div>
                    {!minimized && (
                        <div className="chat-content">
                            <div class="livechat_message_body card-body perfect-scrollbar ps ps--active-y" data-mdb-perfect-scrollbar="true"></div>
                            <div class="card-footer text-muted d-flex justify-content-start align-items-center p-3"><img src="/front/images/user.svg" alt="avatar 3" class="chatavatar" />
                                <input type="text" class="form-control form-control-lg" id="exampleFormControlInput1" placeholder="Type message" fdprocessedid="jq0r4b" value="" />
                                <span class="cursor_pointer ms-3 text-muted" ><i class="fa fa-smile-o" aria-hidden="true"></i></span>
                                <span class="ms-3 cursor_pointer"><i class="fa fa-paper-plane" aria-hidden="true"></i></span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};




// Table component
const Table = ({ users }) => {
    const [openChats, setOpenChats] = useState([]);

    const handleOpenChat = (user) => {
        const existingChatIndex = openChats.findIndex(chat => chat.id === user.id);
        if (existingChatIndex !== -1) {
            // Chat window already open, close it
            const updatedChats = [...openChats];
            updatedChats.splice(existingChatIndex, 1);
            setOpenChats(updatedChats);
        } else {
            // Open chat window
            setOpenChats([...openChats, user]);
        }
    };

    const handleCloseChat = (userId) => {
        const updatedChats = openChats.filter(chat => chat.id !== userId);
        setOpenChats(updatedChats);
    };

    const handleMinimizeChat = (userId) => {
        // Minimize chat window
        // You can implement this functionality as per your requirements
    };

    return (
        <div>
            <table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map(user => (
                        <tr key={user.id}>
                            <td>{user.name}</td>
                            <td>{user.email}</td>
                            <td>
                                <button onClick={() => handleOpenChat(user)} >Open Chat</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="chat-windows">

                <div className='main-chatbox' >
                    {openChats.map(chat => (
                        <ChatWindow
                            key={chat.id}
                            user={chat}
                            onClose={() => handleCloseChat(chat.id)}
                            onMinimize={() => handleMinimizeChat(chat.id)}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

// Main app component
const App = () => {
    const users = [
        { id: 1, name: 'User 1', email: 'user1@example.com' },
        { id: 2, name: 'User 2', email: 'user2@example.com' },
        { id: 3, name: 'User 3', email: 'user3@example.com' },
        { id: 4, name: 'User 4', email: 'user4@example.com' },
        { id: 5, name: 'User 5', email: 'user5@example.com' },
        { id: 6, name: 'User 6', email: 'user6@example.com' },
        { id: 7, name: 'User 7', email: 'user7@example.com' },
        { id: 8, name: 'User 8', email: 'user8@example.com' },
        { id: 9, name: 'User 9', email: 'user9@example.com' },
        // Add more users as needed
    ];

    return (
        <div className="app">
            <h2>User Table</h2>
            <Table users={users} />
        </div>
    );
};

export default App;