import { useState, useEffect } from 'react';
import axios from 'axios';
import payment_status from '../../json/payment_status.json';

const PaymentRequest = () => {
    const useriddata = JSON.parse(localStorage.getItem("squadGologinSession"));
    const [withdrawRequests, setWithdrawRequests] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [requestsPerPage] = useState(10);
    const [loadingData, setLoadingData] = useState(false);
    const [getPaymentStatus, setGetPaymentStatus] = useState('');
    const [searchText, setsearchText] = useState('');



    useEffect(() => {
        fetchWithdrawRequests();
    }, [getPaymentStatus, searchText]);



    const fetchWithdrawRequests = async () => {
        try {
            const response = await axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/api/stripe/withdrawrequest_by_admin/${useriddata.login_token}?paymentstatus=${getPaymentStatus}&search=${searchText}`);
            if (response.status === 200 && response.data.status == 1) {
                setWithdrawRequests(response.data.data)
            } else { console.error('Error:', response.status); setLoadingData(false); }
        } catch (error) { console.error('Error:', error); }
    };

    // Pagination
    const indexOfLastRequest = currentPage * requestsPerPage;
    const indexOfFirstRequest = indexOfLastRequest - requestsPerPage;
    const currentRequests = withdrawRequests.slice(indexOfFirstRequest, indexOfLastRequest);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);


    return (
        <>
            <div className="box mb-4">

                <div className="table_filter mb-2 d-flex align-items-center justify-content-between commonform">
                    <h3>Withdrawn Request</h3>
                </div>

                <div className="table_filter mb-2 d-flex align-items-center justify-content-between commonform">
                    <div className="tblsearch">
                        {/*  getPaymentStatus , searchText */}
                        <input type="text" className="form-control" placeholder="Search" value={searchText} onChange={(e) => setsearchText(e.target.value)} />
                    </div>
                    <div className="tblrightfilters d-flex align-items-center">

                        <select className="form-control ml-3" onChange={(e) => setGetPaymentStatus(e.target.value)} >
                            {
                                payment_status.map((item, index) => (
                                    <option selected={item.value == getPaymentStatus} value={item.value}>{item.name}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
                <div className="table-responsive">
                    <table className="table admintbl">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>User name</th>
                                <th>Email</th>
                                <th>User type</th>
                                <th>Coins</th>
                                <th>Amount(USD)</th>
                                <th>Status</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentRequests.map((request, index) => (
                                <tr key={index}>
                                    <td>{request.id}</td>
                                    <td><a className='text-capitalize'>{request.first_name + ' ' + request.last_name}</a></td>
                                    <td>{request.email}</td>
                                    <td>{request.user_type == 2 ? 'Recruiter' : request.user_type == 3 ? 'Jobseeker' : 'Other'}</td>
                                    <td>{request.coins_value}</td>
                                    <td>${request.amount_in_usd}</td>
                                    <td>{request.status}</td>
                                    <td>{request.created_date}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="card-box">
                        <nav aria-label="Page navigation">
                            <ul className="pagination mb-0 justify-content-center">
                                <li className="page-item"><a className="page-link" href="#"><i className="fa fa-angle-left" aria-hidden="true"></i></a></li>
                                <li className="page-item active"><a className="page-link" href="#">1</a></li>
                                {/* <li className="page-item"><a className="page-link" href="#">2</a></li> */}
                                <li className="page-item"><a className="page-link" href="#"><i className="fa fa-angle-right" aria-hidden="true"></i></a></li>
                            </ul>
                        </nav>
                    </div>
                    
                </div>
            </div>
        </>
    );
}
export default PaymentRequest;