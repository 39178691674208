import React, { useState, useEffect, Component } from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink, useParams, useNavigate } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';
import ProfileBanner from '../profile_header';
import ProfileSidebar from '../profile_sidebar';

import { ToastContainer, toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';

const RecruiterTaxInformation = () => {

   const [selectedTaxOption, setSelectedTaxOption] = useState('abn');
   console.log(selectedTaxOption);
   const [selectedTaxPlaceholder, setSelectedTaxPlaceholder] = useState('Enter ABN number');
   const [selectedTax, setSelectedTax] = useState('ABN');
   const [taxDefaultValue, setTaxDefaultValue] = useState('.........');
   const [taxValueLength, setTaxValueLength] = useState(11);

   const setTaxTypeValueandParam = (value) => {
      console.log(value)
      setSelectedTaxOption(value);
      setTaxValueLength(11)
      setSelectedTax('ABN')
      setSelectedTaxPlaceholder('Enter ABN number')
      if (value == 'acn') {
         setTaxValueLength(9);
         setSelectedTax('ACN');
         setSelectedTaxPlaceholder('Enter ACN number')
      }
   }

   const setTaxTypeFun = (event) => { setTaxTypeValueandParam(event.target.value); };

   const [isHidden, setIsHidden] = useState(true);
   const toggleHidden = () => { setIsHidden(!isHidden); };
   const hideFormDetails = () => { setIsHidden(true); }

   const userdetails = JSON.parse(localStorage.getItem("squadGologin"));
   const [userDetailsArray, setUserDetailsArray] = useState([]);

   const [isAccVerified, setIsAccVerified] = useState('false');
   const getUserDetails = () => {
      (async () => {
         try {
            const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/userdetails/" + userdetails.unique_id);
            if (resData.data.status == 1) {
               setUserDetailsArray(resData.data.data.results);
               resData.data.data.results.tax_type && setTaxTypeValueandParam(resData.data.data.results.tax_type);
               setTaxDefaultValue(resData.data.data.results.tax_type_value);
               setIsAccVerified(resData.data.data.results.verifications_status.statustype.tax_information);
            }
         } catch (error) { console.log(error); }
      })();
   }

   useEffect(() => {
      if (userdetails.unique_id) { getUserDetails(); } else { setTimeout(function () { window.location.replace("/login"); }, 100) }
      
   }, []);


   const [validated, setValidated] = useState(false);
   const [taxTypeClass, setTaxTypeClass] = useState(false);
   const onvisaDetailsSubmit = async (event) => {
      event.preventDefault();
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
         event.preventDefault();
         event.stopPropagation();
      } else if (event.target.tax_type_value.value.length != 11) {
         setTaxTypeClass(true);
      } else {
         try {
            const formData = new FormData(event.target);
            const referenceDataValue = {};
            formData.forEach((value, name) => {
               if (name.endsWith('[]')) {
                  const inputName = name.slice(0, -2);
                  if (!referenceDataValue[inputName]) {
                     referenceDataValue[inputName] = [value];
                  } else { referenceDataValue[inputName].push(value); }
               } else { referenceDataValue[name] = value; }
            });
            if (userdetails.unique_id) {
               formData.append('userid', userdetails.unique_id);
            }
            const response = await axios.post(`https://squadgoo.com.au/api.squadgoo.com.au/userdetails_data_update`,
               formData, { headers: { 'Content-Type': 'multipart/form-data' } });
            if (response.data.status == 1) {
               setIsHidden(true); getUserDetails(); toast.success(response.data.message);
            } else { toast.warning(response.data.message); }
         } catch (error) { console.error('Error uploading file:', error); }
      }
      setValidated(true);
   };


   return (
      <>
         {/* <ToastContainer />*/}
         <div className="dashboarwrap">
            <ProfileBanner />
            <div className="jobbtmsec py-5">
               <div className="container">

                  <div className="row">
                     <div className="col-md-3 profilesidebarwrap">
                        <ProfileSidebar />
                     </div>
                     <div className="col-md-9">
                        <div className="profilerightwraper stepscont commonform basicdetail">

                           <div className="card-box license-sec tfn-sec">
                              <div className="">
                                 <div className="d-flex justify-content-between">
                                    <h3 className="mb-3">
                                       Tax information
                                       {
                                          isAccVerified == true ?
                                             <><img className='veri_unveri_icon' src="/front/images/vfy.png" data-toggle="tooltip" data-placement="top" title="verified"></img></> :
                                             isAccVerified == false ?
                                                <><img className='veri_unveri_icon' src="/front/images/uvfy.png" data-toggle="tooltip" data-placement="top" title="Your Profile is in Review"></img></> :
                                                <></>
                                       }
                                    </h3>
                                    {isAccVerified == false && <div className="editbtn" onClick={toggleHidden}  ><img src="/front/images/edit.png" /></div>}
                                 </div>

                                 <div className="d-flex align-items-center justify-content-between mb-3">
                                    <p className="mb-0 largepera">{(selectedTaxOption === 'acn' || selectedTaxOption === 'ACN') ? 'ACN' : 'ABN'} No.</p>
                                    <div className="d-flex flex-wrap">
                                       <p className="mb-0">{userDetailsArray.tax_type_value ? userDetailsArray.tax_type_value : '..........'}</p>
                                    </div>
                                 </div>
                                 {!isHidden &&
                                    <div className="">
                                       <Form noValidate validated={validated} onSubmit={onvisaDetailsSubmit}>
                                          <div className="row mb-2">
                                             {/* <input type="hidden" value="abn" name='tax_type' ></input> */}
                                             
                                             
                                             <div className="col-md-6">
                                                <div className="form-group taxtype mb-2">
                                                   <label className="form-label">Tax type  </label>

                                                   <div className="switch-field square-switch-field mr-0 ">

                                                      <div className="switchfieldinner">
                                                         <input type="radio" id="recruiter" name="tax_type" checked={(selectedTaxOption === 'acn' || selectedTaxOption === 'ACN') ? true : false} placeholder={selectedTaxPlaceholder} value="acn" onChange={setTaxTypeFun} />
                                                         <label htmlFor="recruiter">ACN</label>
                                                      </div>

                                                      <div className="switchfieldinner">
                                                         <input type="radio" id="jobseeker" name="tax_type" checked={(selectedTaxOption === 'abn' || selectedTaxOption === 'ABN') ? true : false} placeholder={selectedTaxPlaceholder} value="abn" onChange={setTaxTypeFun} />
                                                         <label htmlFor="jobseeker">ABN </label>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>

                                             <div className="col-md-6">
                                                <div className="form-group mb-2">
                                                   <label className="form-label">{selectedTax}<span className="starr">*</span></label>
                                                   <input required={true} type="text" name='tax_type_value' className={`form-control ${taxTypeClass ? 'bordercss' : ''}`} maxLength={taxValueLength} onChange={(e) => setTaxDefaultValue(e.target.value)} value={taxDefaultValue} placeholder={selectedTaxPlaceholder} />
                                                </div>
                                             </div>
                                          </div>
                                          <div className="col-md-12 mt-3">
                                             <button type='submit' className="batn batn-rounded rounded-orange mr-2">Save</button>
                                             <button type='button' onClick={hideFormDetails} className="batn batn-rounded rounded-orange mr-2">Cancel</button>
                                          </div>
                                       </Form>
                                    </div>
                                 }
                              </div>
                           </div>

                        </div>
                     </div>
                  </div>
               </div>
            </div>

         </div>
      </>
   );
}
export default RecruiterTaxInformation;