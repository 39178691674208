import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const UserContext = createContext();

export const ApiDataContextProvider = ({ children }) => {
  const [profilePicUrlCA, setProfilePicUrl] = useState('/front/images/user.svg');
  const [profileEditUrlCA, setProfileEditUrl] = useState('/basic-detail');
  const [loginUserData, setloginUserData] = useState(null);
  const useriddata = JSON.parse(localStorage.getItem("squadGologinSession"));


  const fetchData = async (unique_id) => {
    if(unique_id){
        try {
          const resData = await axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/userdetails/${unique_id}`);
          if (resData.data.status === 1) {
            const profilepic = resData.data.data.results.profilepic;
            setloginUserData(resData.data.data.results);
            setProfilePicUrl(profilepic ? profilepic : '/front/images/user.svg');
            const userType = resData.data.data.results.user_type;
            setProfileEditUrl(userType == 3 ? 'jobseeker/basic-detail' : '/recruiter/basic-detail');
          }
        } catch (error) {
          console.log(error);
        }
    }
  };

  useEffect(() => {
    if (useriddata) {
      fetchData(useriddata.unique_id);
    }
  }, []); // Ensure it runs only once when component mounts



  const updateLoginUserData = (newData) => {
    //setloginUserData(newData);
  };


  const resetContaxtData = () => {
    setProfilePicUrl('/front/images/user.svg');
    setProfileEditUrl('/basic-detail');
    setloginUserData(null);
  };

  const getDataDuringLogin = (unique_id) => { fetchData(unique_id); };


  return (
    <UserContext.Provider value={{ profilePicUrlCA, profileEditUrlCA, loginUserData, updateLoginUserData, resetContaxtData, getDataDuringLogin }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);