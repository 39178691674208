import React, { useState, useCallback, useEffect } from 'react';
import ProfileSidebar from '../profile_sidebar';
import RecWalletHist from '../WalletHistoryCom';
import RecWalletCheckout from './RecWalletCheckout';
import RecWalletWithdraw from './recWalletWithdraw';
import ReferEarnComponent from '../ReferEarnComponent';
import { Form, Modal } from 'react-bootstrap';

import LiveChatWindow from '../LiveChatWindow';
import axios from 'axios';

//admin/PayemntProcess.js

const RecruiterWallet = (prop) => {


   const [callingBy, setCallingBy] = useState(prop.callingby);
   const [isCheckOutPageShow, setIsCheckOutPageShow] = useState((prop.callingby == 'coinpurchase') ?  true :  false);
   const receiveMessageFromChild = (toggle) => { setIsCheckOutPageShow(toggle); };
   const [withDrawformShow, setWithDrawformShow] = useState(false);
   const backTotheTransection = (toggle) => {
      setWithDrawformShow(toggle)
   }

   

   const [recCoinsData, setRecCoinsData] = useState({
      current_squad_go_coins: 0,
      total_available_coins: 0,
      total_coins_purchased: 0,
      total_coins_used: 0,
      total_coins_withdraw: 0,
      total_coins_withdraw_pending: 0,
      total_reffered_coins: 0,
      transection_history: []
   });


   //const [parentData, setParentTotalCoinsData] = useState('....');
   const handleChildDataChange = useCallback((newData) => { setRecCoinsData(newData); }, []);
   const [liveChatModule, setLiveChatModule] = useState(false);



   const [walletSettingData, setWalletSettingData] = useState([]);
   const get_porduct_details = async () => {
      try {
         const response = await axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/api/stripe/get_porduct_details`);
         if (response.status === 200 && response.data.status == 1) {
            //setWalletSettingData
            setWalletSettingData(response.data.data)
         } else { console.error('Error:', response.status); }
      } catch (error) { console.error('Error:', error); }
   }


   useEffect(() => {
      get_porduct_details();
   }, []);

   return (

      <>
         {/* <ToastContainer />*/}
         <div className="dashboarwrap">
            <div className="pagebanner">
               <div className="container">
                  <div className="row">
                     <div className="col-md-12">
                        <h2>Wallet</h2>
                     </div>
                  </div>
               </div>
            </div>
            <div className="jobbtmsec py-5 admininfo">
               <div className="container">
                  <div className="row">
                     <div className={'col-md-12'}>
                        <div className="profilerightwraper stepscont walletblocks">
                           {
                              // isCheckOutPageShow 
                              <>
                                 {prop.callingby != 'coinpurchase' && <div className="col-md-12 mb-4">
                                    <div className="box ststicsbox heiglightbox d-flex justify-content-between">
                                       <div className="ststicsboxleft">
                                          <h2>{recCoinsData.total_available_coins} <i className="fa fa-arrow-down" aria-hidden="true"></i></h2>
                                          <p>Current Squad Goo wallet balance</p>
                                       </div>
                                       <div className="ststicsboxright">
                                          <span onClick={() => { setIsCheckOutPageShow(!isCheckOutPageShow); setWithDrawformShow(false) }} className="batn batn-orange">Purchase Coin</span>
                                          <span onClick={() => { setWithDrawformShow(!withDrawformShow); setIsCheckOutPageShow(false) }} className="batn btnrev ml-2">Withdraw Coins</span>
                                          <span onClick={() => setLiveChatModule(!liveChatModule)} className=" ml-2">
                                             <i class='fa fa-comment livechat_icon'></i>
                                          </span>
                                       </div>
                                    </div>
                                 </div>} 
                                 
                                 {
                                    isCheckOutPageShow &&
                                    <>
                                       <RecWalletCheckout callingby={callingBy} sendMessageToParent={receiveMessageFromChild} />
                                    </>
                                 }
                                 {
                                    !isCheckOutPageShow &&
                                    <>
                                       <RecWalletHist onChildDataChange={handleChildDataChange} />
                                    </>
                                 }

                                 <ReferEarnComponent />
                              </>

                           }

                        </div>
                     </div>
                  </div>
               </div>
            </div>

         </div>

         {liveChatModule && <LiveChatWindow livechataction={liveChatModule} />}

         <Modal size="md" id="withdraw_amount_coins" className="modal fade " show={withDrawformShow} onHide={() => setWithDrawformShow(!withDrawformShow)}>
            {
               <>
                  <div className="modal-dialog1 ">
                     <div className="modal-content">
                        <div className="modal-body">
                           <button onClick={() => setWithDrawformShow(!withDrawformShow)} type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                           <RecWalletWithdraw backToParent={backTotheTransection} productdata={walletSettingData} total_available_coins={recCoinsData.total_available_coins} />
                        </div>
                     </div>
                  </div>
               </>
            }
         </Modal>
      </>

   );
}
export default RecruiterWallet;