import React, { useEffect } from 'react';

const MapWithDirections = () => {
  useEffect(() => {

    console.log(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
    
    const loadGoogleMapScript = () => {
      const googleMapScript = document.createElement('script');
      googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&callback=initMap`;
      window.document.body.appendChild(googleMapScript);
      googleMapScript.addEventListener('load', initMap);
    };

    const initMap = () => {
      if (window.google && window.google.maps) {
        
        const directionsService = new window.google.maps.DirectionsService();
        const directionsRenderer = new window.google.maps.DirectionsRenderer();
        const map = new window.google.maps.Map(document.getElementById('map'), {
          zoom: 7,
            fullscreenControl: false, // Disable fullscreen control
          zoomControl: false // Disable zoom control
        });
        directionsRenderer.setMap(map);
    
        const request = {
          origin: 'Indore',
          destination: 'jabalpur',
          travelMode: window.google.maps.TravelMode.DRIVING
        };
    
        directionsService.route(request, function(result, status) {
          if (status == window.google.maps.DirectionsStatus.OK) {
            directionsRenderer.setDirections(result);
          } else {
            window.alert('Directions request failed due to ' + status);
          }
        });


      } else {
        console.error('Google Maps API not loaded.');
      }
    };

    loadGoogleMapScript();
  }, []);

  return <div id="map" style={{ height: '400px', width: '100%' }} />;
};

export default MapWithDirections;



// import React from 'react';
// import GoogleMapReact from 'google-map-react';

// const MapWithPath = ({ center, zoom, path }) => {
//   const renderPath = () => {
//     if (!path || path.length < 2) return null;

//     const pathCoordinates = path.map(point => ({
//       lat: point.lat,
//       lng: point.lng
//     }));

//     return <google-map-react.Polyline path={pathCoordinates} options={{ strokeColor: '#FF0000' }} />;
//   };

//   return (
//     <div style={{ height: '400px', width: '100%' }}>
//       <GoogleMapReact
//         bootstrapURLKeys={{ key: 'YOUR_GOOGLE_MAPS_API_KEY' }}
//         defaultCenter={center}
//         defaultZoom={zoom}
//       >
//         {renderPath()}
//       </GoogleMapReact>
//     </div>
//   );
// };

// MapWithPath.defaultProps = {
//   center: { lat: 0, lng: 0 }, // Default center if not provided
//   zoom: 10 // Default zoom level if not provided
// };

// const App = () => {
//   const path = [
//     { lat: 40.7128, lng: -74.006 },
//     { lat: 34.0522, lng: -118.243 }
//   ];

//   return (
//     <div>
//       <h1>Path Between Two Places</h1>
//       <MapWithPaths center={{ lat: 37.7749, lng: -122.4194 }} zoom={5} path={path} />
//     </div>
//   );
// };

// export default App;





// import React, { useState } from 'react';
// // Child component
// const ChildComponent = ({ sendDataToParent }) => {
//   const [inputValue, setInputValue] = useState('');
//   const handleInputChange = (e) => { setInputValue(e.target.value); };
//   const sendData = () => {sendDataToParent(inputValue); };
//   return (
//     <div>
//       <input type="text" value={inputValue} onChange={handleInputChange} />
//       <button onClick={sendData}>Send Data</button>
//     </div>
//   );
// };
// // Parent component
// const ParentComponent = () => {
//   const [receivedData, setReceivedData] = useState('');
//   // Update state with data received from child
//   const receiveDataFromChild = (data) => { setReceivedData(data); };
//   return (
//     <div>
//       <h2>Data received from child: {receivedData}</h2>
//       <ChildComponent sendDataToParent={receiveDataFromChild} />
//     </div>
//   );
// };
// export default ParentComponent;






// import React from 'react';
// import { BrowserRouter as Router, Route } from 'react-router-dom';
// import Home from './Home';
// import About from './About';
// import Contact from './Contact';

// function App() {
//   return (
//     <Router>
//       <div>
//         <Route exact path="/" component={Home} />
//         <Route path="/about" component={About} />
//         <Route path="/contact" component={Contact} />
//       </div>
//     </Router>
//   );
// }

// export default App;



/*in this example when we click on setNumber then GetHtml is also calling  */

// import React, { useState } from 'react';
// const SquaredNumbersWithoutMemo = () => {
//   const [numbers, setNumbers] = useState(0);
//   const GetHtml = () => {
//     console.log('Calling function....');
//     return <h1>Hello</h1>
//   };
//   return (
//     <div>
//       <button onClick={() => setNumbers(numbers+1)}>Reset Numbers</button>
//       <GetHtml />
//       <h3>{numbers}</h3>
//     </div>
//   );
// };
// export default SquaredNumbersWithoutMemo;




/* After use memo 'GetHtml' is now rendor one time when we click on setNumbers its not affecting */

// import React, { useMemo, useState ,useCallback  } from 'react';
// const SquaredNumbersWithoutMemo = () => {
//   const [numbers, setNumbers] = useState(0);
//   const GetHtml = () => {
//     console.log('Calculating squared numbers...');
//     return <h1>Hello</h1>
//   };
//   const memoizedHtml = useCallback ( () => <GetHtml />, [] )
//   return (
//     <div>
//       <button onClick={() => setNumbers(numbers+1)}>Reset Numbers</button>
//       <memoizedHtml />
//       <h3>{numbers}</h3>
//     </div>
//   );
// };
// export default SquaredNumbersWithoutMemo;


// import React, { useState, useMemo } from 'react';

// const MyComponent = () => {
//   const [count, setCount] = useState(0);

//   // Memoize the doubledCount value using useMemo
//   // const doubledCount = useMemo(() => {
//   //   return count * 2;
//   // }, [count]); // Dependency array includes 'count'

//   return (
//     <div>
//       <p>Count: {count}</p>
//       {/* <p>Doubled Count: {doubledCount}</p> */}
//       <button onClick={() => setCount(count + 1)}>Increment</button>
//     </div>
//   );
// };

// export default MyComponent;
// // VideoCall.js
// import React, { useRef, useEffect, useState } from 'react';
// import firebase from './FirebaseConfig';
// import Peer from 'simple-peer';

// const VideoCall = () => {
//   const localVideoRef = useRef();
//   const remoteVideoRefs = useRef([]);
//   const peerConnections = useRef([]);
//   const [connectedUsers, setConnectedUsers] = useState(0);

//   useEffect(() => {
//     const initializePeerConnection = async () => {
//       try {
//         // Initialize local video stream
//         const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
//         localVideoRef.current.srcObject = stream;

//         // Initialize WebRTC peer connection
//         const configuration = { iceServers: [{ urls: 'stun:stun.l.google.com:19302' }] };
//         const peerConnection = new RTCPeerConnection(configuration);

//         // Add local stream to the peer connection
//         stream.getTracks().forEach((track) => {
//           peerConnection.addTrack(track, stream);
//         });

//         // Event handlers for WebRTC
//         peerConnection.onicecandidate = (event) => handleICECandidate(event, peerConnection);
//         peerConnection.ontrack = (event) => handleTrack(event, peerConnection);

//         // Store the peer connection in the refs
//         peerConnections.current.push(peerConnection);

//         // Increment the connected users count
//         setConnectedUsers((prevUsers) => prevUsers + 1);

//         // Create an offer and send it to the other user (you can use a signaling mechanism, like Firebase)
//         const offer = await peerConnection.createOffer();
//         await peerConnection.setLocalDescription(offer);
//         console.log('Sending offer:', peerConnection.localDescription);
//         // Send the offer to the other user (you can use a signaling mechanism, like Firebase)

//       } catch (error) {
//         console.error('Error initializing peer connection:', error);
//       }
//     };

//     initializePeerConnection();
//   }, []);

//   const handleICECandidate = (event, peerConnection) => {
//     // Handle ICE candidate events and send them to the other user
//     const candidate = event.candidate;
//     if (candidate) {
//       console.log('Sending ICE candidate:', candidate);
//       // Send the candidate to the other user (you can use a signaling mechanism, like Firebase)
//     }
//   };

//   const handleTrack = (event, peerConnection) => {
//     // Handle incoming video/audio tracks and display them
//     const remoteStream = new MediaStream();
//     remoteStream.addTrack(event.track);
//     const index = peerConnections.current.indexOf(peerConnection);
//     remoteVideoRefs.current[index].srcObject = remoteStream;
//   };

//   const startVideoCall = () => {
//     // Start the video call by creating an offer
//     // (Note: In a real-world scenario, you'd need a signaling mechanism to exchange session description)
//     peerConnections.current.forEach(async (peerConnection) => {
//       const offer = await peerConnection.createOffer();
//       await peerConnection.setLocalDescription(offer);
//       console.log('Sending offer:', peerConnection.localDescription);
//       // Send the offer to the other user (you can use a signaling mechanism, like Firebase)
//     });
//   };

//   return (
//     <div>
//       <div>
//         <p>Connected Users: {connectedUsers}</p>
//       </div>
//       <div>
//         <video ref={localVideoRef} autoPlay muted />
//         {peerConnections.current.map((_, index) => (
//           <video key={index} ref={(el) => remoteVideoRefs.current[index] = el} autoPlay />
//         ))}
//       </div>
//       <button onClick={startVideoCall}>Start Video Call</button>
//     </div>
//   );
// };

// export default VideoCall;