import React, { useState ,useEffect , Component} from 'react';
import axios from 'axios';
import { isVisible } from '@testing-library/user-event/dist/utils';

const Subscription = () => {
  
   return (
   <>
   <div className="dashboarwrap">
   <div className="pagebanner">
         <div className="container">
            <div className="row">
               <div className="col-md-12">
                  <h2>Subscription</h2>
               </div>
            </div>
         </div>
      </div>
      <div className="subscriptionwrap py-5">
         <div className="container">
         
         <div className="row">
               <div className="col-md-12">
                   <div className="card-box mb-3">
                     <div className="splantop d-flex align-items-center justify-content-between mb-3">
                        <h4>Your subscription details.</h4>
                        <span className="subdate d-flex align-items-center"><img src="/front/images/clock.png"/>Next payment due on <b>20 Nov 2024</b></span>
                     </div>
                     <div className="splan1 d-flex align-items-start justify-content-between">
                        <div>
                           <h3>Basic Plan <span className="smalltag greentag">Active</span></h3>
                           <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi </p>
                        </div>
                        <a href="#" className="batn batn-rounded rounded-orange">Update plan</a>
                     </div>

                   </div>

                   <div className="card-box mb-3">
                        <div className="splan1 d-flex align-items-start justify-content-between">
                           <div>
                              <h3>Payment Method</h3>
                           </div>
                           <a href="#" className="batn batn-rounded rounded-orange">Add a method</a>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mt-3 cardsec">
                           <div className="d-flex align-items-center">
                              <i className="fa fa-credit-card mr-3" aria-hidden="true"></i>
                              <p className="mb-0"><strong>Card ending with 6749</strong></p>
                              <span className="smalltag greentag ml-3">Preferred</span>
                           </div>
                           <div className="paymentlinks d-flex"><a href="#" className="editpayment"><i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                              Edit</a> <a href="#" className="removepayment"><i className="fa fa-trash-o" aria-hidden="true"></i> Remove</a>
                           </div>
                        </div>
                     </div>

                   {/* <div className="card-box mb-3">
                     <div className="splantop">
                        <h4>Payment Method</h4>
                        <p>Update your credit card details for automatic subscription.</p>
                        <div className="commonform mt-2">
                        <div className="card">
                              <div className="card-header d-flex align-items-end">
                              <h4 className="mr-2 mb-0">Credit Card</h4><p className="mb-0">Enter your card details</p>
                              </div>
                              <div className="card-body">


                              <div className="row">
                              <div className="col-sm-12">
                              <div className="form-group">
                              <label  className="form-label">Credit Card Number</label>


                              <div className="input-group">
                              <input className="form-control" type="text" placeholder="0000 0000 0000 0000" autocomplete="email"/>
                              <div className="input-group-append">
                              <span className="input-group-text">
                              <i className="fa fa-credit-card-alt" aria-hidden="true"></i>

                              </span>
                              </div>
                              </div> 
                              </div>
                              </div>
                              </div>

                              <div className="row">
                              <div className="form-group col-sm-4">
                              <label className="form-label">Month</label>
                              <select className="form-control" id="ccmonth">
                              <option>1</option>
                              <option>2</option>
                              <option>3</option>
                              <option>4</option>
                              <option>5</option>
                              <option>6</option>
                              <option>7</option>
                              <option>8</option>
                              <option>9</option>
                              <option>10</option>
                              <option>11</option>
                              <option>12</option>
                              </select>
                              </div>
                              <div className="form-group col-sm-4">
                              <label className="form-label">Year</label>
                              <select className="form-control" id="ccyear">
                              <option>2014</option>
                              <option>2015</option>
                              <option>2016</option>
                              <option>2017</option>
                              <option>2018</option>
                              <option>2019</option>
                              <option>2020</option>
                              <option>2021</option>
                              <option>2022</option>
                              <option>2023</option>
                              <option>2024</option>
                              <option>2025</option>
                              </select>
                              </div>
                              <div className="col-sm-4">
                              <div className="form-group">
                              <label className="form-label">CVV/CVC</label>
                              <input className="form-control" id="cvv" type="text" placeholder="123"/>
                              </div>
                              </div>
                              <div className="col-sm-12 mt-3">
                              <a href="#" className="batn batn-rounded rounded-orange">Update</a>

                              </div>
                              </div>

                              </div>
                             
                              </div>
                        </div>
                     </div>                

                   </div> */}


                   <div className="card-box">
               
               <div className="splan1 d-flex align-items-start justify-content-between">
                  <div>
                     <h4>End subscription</h4>
                     <p>Upon cancelling you will lose accesss of advance features</p>
                  </div>
                  <a href="#" className="batn batn-rounded rounded-orange">cancel your subscription</a>
               </div>

             </div>


               </div>
            </div>
         </div>
      </div>
   

   
    

   </div>
   </>
   );
   }
   export default Subscription;