import React from 'react';

// Define the YearSelector functional component
const YearSelector = ({ startYear = 2000, selectedYear }) => {
  // Get the current year dynamically
  const currentYear = new Date().getFullYear();
  
  // Define an array to hold the list of option elements
  const yearOptions = [];

  // Add a default option for "YYYY"
  yearOptions.push(
    <option key="default" value="">
      YYYY
    </option>
  );

  // Generate the years from the specified start year to the current year
  for (let i = startYear; i <= currentYear; i++) {
    yearOptions.push(
      <option key={i} value={i} selected={selectedYear == i ?  true : false}>
        {i}
      </option>
    );
  }

  // Return the select element with the generated options
  return yearOptions;
};

export default YearSelector;
