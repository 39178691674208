import React, { useState, useEffect, Component } from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink, useParams, useNavigate } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';
import ProfileBanner from './profile_header';
import ProfileSidebar from './profile_sidebar';

import { toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';

import { useDispatch } from 'react-redux'
import { userSocialMedia } from '../redux_state/reducres/checkDocumentstatus';
//redux_state/reducres/checkDocumentstatus

const SocialMediaSettings = () => {
   const dispatch = useDispatch();
   const [isHidden, setIsHidden] = useState(true);
   const toggleHidden = () => { setIsHidden(!isHidden); };
   const userdetails = JSON.parse(localStorage.getItem("squadGologin"));
   const [userDetailsArray, setUserDetailsArray] = useState([]);
   const getUserDetails = () => {
      (async () => {
         try {
            const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/userdetails/" + userdetails.unique_id);
            if (resData.data.status == 1) {
               setUserDetailsArray(resData.data.data.results);
            }
         } catch (error) { console.log(error); }
      })();
   }

   useEffect(() => {
      if (userdetails.unique_id) { getUserDetails(); } else { setTimeout(function () { window.location.replace("/login"); }, 100) }
   }, []);

   const [validated, setValidated] = useState(false);
   const onAddressDetailsSubmit = async (event) => {
      event.preventDefault();
      const form = event.currentTarget;
      if (form.checkValidity() === false) { event.preventDefault(); event.stopPropagation(); }
      else {
         try {
            const formData = new FormData(event.target);
            const referenceDataValue = {};
            formData.forEach((value, name) => {
               if (name.endsWith('[]')) {
                  const inputName = name.slice(0, -2);
                  if (!referenceDataValue[inputName]) {
                     referenceDataValue[inputName] = [value];
                  } else { referenceDataValue[inputName].push(value); }
               } else { referenceDataValue[name] = value; }
            });
            if (userdetails.unique_id) { formData.append('userid', userdetails.unique_id); }
            const response = await axios.post(`https://squadgoo.com.au/api.squadgoo.com.au/userdetails_data_update`,
               formData, { headers: { 'Content-Type': 'multipart/form-data' } });
            if (response.data.status == 1) {
               setIsHidden(true); getUserDetails();
               toast.success(response.data.message);
               if (userdetails.validationdata_check.socialmedia_validation == 0) {
                  /*redux validation update */
                  dispatch(userSocialMedia(1));
                  /*redux validation check end  */
               };
            }
            else { toast.warning(response.data.message); }
         } catch (error) { console.error('Error uploading file:', error); }
      }
      setValidated(true);
   };

   const socialMediaCahnage = (event) => {
      const { name, value } = event.target;
      setUserDetailsArray((prevFormData) => ({ ...prevFormData, [name]: value }));
   }


   return (
      <>
         <div className="dashboarwrap">
            <ProfileBanner />
            <div className="jobbtmsec py-5 admininfo">
               <div className="container">
                  <div className="row">
                     <div className="col-md-3 profilesidebarwrap">
                        <ProfileSidebar />
                     </div>
                     <div className="col-md-9">
                        <div className="profilerightwraper stepscont commonform basicdetail ">
                           <div className="card-box work-experiencesec commonform ">

                              <div className="d-flex align-items-center justify-content-between">
                                 <h3 className="mb-3">Social Media</h3>
                                 <div className="editbtn" onClick={toggleHidden} ><img src="/front/images/edit.png" /></div>
                              </div>
                              <div className="editblock">
                                 <div className="socialmediasec">
                                    <div className="d-flex align-items-center justify-content-between mb-3"><p className="mb-0"><i className="fa fa-facebook-square mr-2" aria-hidden="true"></i> Facebook</p><a target="_blank" href={userDetailsArray.facebook} aria-current="page" className="anc_class">{userDetailsArray.facebook ?? '......'}</a></div>
                                    <div className="d-flex align-items-center justify-content-between mb-3"><p className="mb-0"><i className="fa fa-twitter-square mr-2" aria-hidden="true"></i> Twitter</p><a target="_blank" href={userDetailsArray.twitter} aria-current="page" className="anc_class">{userDetailsArray.twitter ?? '......'}</a></div>
                                    <div className="d-flex align-items-center justify-content-between mb-3"><p className="mb-0"><i className="fa fa-linkedin-square mr-2" aria-hidden="true"></i> Linkedin</p><a target="_blank" href={userDetailsArray.linkedin} aria-current="page" className="anc_class">{userDetailsArray.linkedin ?? '......'}</a></div>
                                    <div className="d-flex align-items-center justify-content-between mb-3"><p className="mb-0"><i className="fa fa-instagram mr-2" aria-hidden="true"></i> Instagram</p><a target="_blank" href={userDetailsArray.instagram} aria-current="page" className="anc_class">{userDetailsArray.instagram ?? '......'}</a></div>
                                 </div>

                                 {/* <div className="editblockinner d-flex ">
                                    <h4>
                                    <div><span className="mr-2">Facebook :</span><span className=''>   {userDetailsArray.facebook ?? '......'} </span></div>
                                    <div><span className="mr-2">Twitter :</span><span className=''>   {userDetailsArray.twitter ?? '......'} </span></div>
                                    <div><span className="mr-2">Linkedin :</span><span className=''>   {userDetailsArray.linkedin ?? '......'} </span></div>
                                    <div><span className="mr-2">Instagram :</span><span className=''>   {userDetailsArray.instagram ?? '......'} </span></div>
                                    </h4>
                                 </div> */}
                                 {!isHidden &&
                                    <div className="editblockinnerbtm">
                                       <Form noValidate validated={validated} onSubmit={onAddressDetailsSubmit}>
                                          <div className="row">
                                             <div className="col-md-6">
                                                <div className="form-group">
                                                   <label className="form-label">Facebook</label>
                                                   <input type="text" onChange={socialMediaCahnage} value={userDetailsArray.facebook} className="form-control" name={'facebook'} placeholder="Enter Facebook account" />
                                                </div>
                                             </div>
                                             <div className="col-md-6">
                                                <div className="form-group">
                                                   <label className="form-label">Twitter</label>
                                                   <input type="text" onChange={socialMediaCahnage} value={userDetailsArray.twitter} className="form-control" name={'twitter'} placeholder="Enter Twitter account" />
                                                </div>
                                             </div>
                                             <div className="col-md-6">
                                                <div className="form-group">
                                                   <label className="form-label">Linkedin</label>
                                                   <input type="text" onChange={socialMediaCahnage} value={userDetailsArray.linkedin} className="form-control" name={'linkedin'} placeholder="Enter Linkedin account" />
                                                </div>
                                             </div>
                                             <div className="col-md-6">
                                                <div className="form-group">
                                                   <label className="form-label">Instagram</label>
                                                   <input type="text" onChange={socialMediaCahnage} value={userDetailsArray.instagram} className="form-control" name={'instagram'} placeholder="Enter Instagram account" />
                                                </div>
                                             </div>

                                             <div className="col-md-12 mt-3">
                                                <button className="batn batn-rounded rounded-orange mr-2">Save</button>
                                                <button onClick={(e) => setIsHidden(true)} className="batn batn-rounded rounded-orange mr-2">Cancel</button>
                                             </div>

                                          </div>
                                       </Form>
                                    </div>
                                 }

                              </div>
                           </div>

                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
}
export default SocialMediaSettings;