import React, { useState, useEffect, Component } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import SuperAdminSidebar from './superadmin_sidebar'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';
const CmsOptionsSettings = () => {

    const [seletOptionType, setseletOptionType] = useState([
        { key: 'please_select_option', title: 'Please Select' },
        { key: 'get_all_jobtype', title: 'Job Types' },
        { key: 'get_all_jobtitle', title: 'Job Titles List' },
        { key: 'get_all_role', title: 'Job Role' },
        { key: 'get_all_industry', title: 'Job Industry' },
        { key: 'get_all_address_type', title: 'Address Type' },
        { key: 'get_all_visatype', title: 'Visa subclass' },
        { key: 'get_all_eduqualification', title: 'Educations and Qualifications' },
        { key: 'get_all_language', title: 'Language' },
        { key: 'reprasentative_position_list', title: 'Representative positions' },
        { key: 'get_all_country', title: 'Country List' }
        // More example job titles...
    ]);

    const [selectStatus, setselectStatus] = useState([
        { key: 'all', title: 'Status' },
        { key: '1', title: 'Active' },
        { key: '0', title: 'deactive' },
        // More example job titles...
    ]);
    
    const [jobTitles, setJobTitles] = useState([]);
    const [currentActiveSetting, onSettingChangeChange] = useState('please_select_option');
    const [newSettingLoad, setnewSettingLoad] = useState(true);
    const [dataisLoading, setDataLoading] = useState(true);
    const [isEditing, setIsEditing] = useState(false);
    const [editJob, setEditJob] = useState({ id: null, title: '', status: 'Active' });
    const [newJobTitle, setNewJobTitle] = useState('');
    const [searchkey, setSearchKeyValue] = useState('');
    const [statusValue, setStatusValue] = useState('all');
    const [newJobStatus, setNewJobStatus] = useState('Active');

    const getSettingOptionsData = () => {
        (async () => {
            if (currentActiveSetting != 'please_select_option') {
                setnewSettingLoad(true);
                try {
                    const formData = new FormData();
                    axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/get_all_options_settings/${currentActiveSetting}?search=${searchkey}&status=${statusValue}`, formData)
                        .then((response) => {
                            setDataLoading(false);
                            if (response.status == 200 && response.data.status == 1) {
                                setJobTitles(response.data.data.results)
                            }
                            setnewSettingLoad(false);
                        })
                        .catch((error) => { console.error(error); });
                } catch (error) { console.log(error); }
            } else {
                setJobTitles([])
            }

        })();
    }
    useEffect(() => { getSettingOptionsData(); }, [currentActiveSetting, searchkey, statusValue]);
    const currentActiveSettingFun = (getvalue) => {
        setnewSettingLoad(true);
        onSettingChangeChange(getvalue);
    }


   

    const handleNewJobSubmit = e => {
        e.preventDefault();
       // alert()
        if (newJobTitle) {
            (async () => {
                try {
                    const formData = new FormData();
                    axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/get_all_options_settings_action/${userdetails.login_token}/${currentActiveSetting}/${currentEditId}?actiontype=newjob&status=${newJobStatus}&title=${newJobTitle}`, formData)
                        .then((response) => {
                            if (response.status == 200 && response.data.status == 1) {
                                toast.success(response.data.message); 
                                getSettingOptionsData(); 
                            }  else {
                                toast.warning(response.data.message);
                            }
                        })
                        .catch((error) => { console.error(error); });
                } catch (error) { console.log(error); }
            })();
        }
    };
    const handleEditJobSubmit = e => {
        e.preventDefault();
        // setJobTitles(jobTitles.map(job => (job.id === editJob.id ? { ...job, title: editJob.title, status: editJob.status } : job)));
        // setEditJob({ id: null, title: '', status: 'Active' });
        // setIsEditing(false);
        //if (newJobTitle) {
            (async () => {
                try {
                    const formData = new FormData();
                    axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/get_all_options_settings_action/${userdetails.login_token}/${currentActiveSetting}/${editJob.id}?actiontype=update&status=${editJob.status == 1 ? 0 : 1}&title=${editJob.title}`, formData)
                        .then((response) => {
                            if (response.status == 200 && response.data.status == 1) {
                                toast.success(response.data.message); 
                                getSettingOptionsData(); 
                                setIsEditing(false);
                            }  else {
                                toast.warning(response.data.message);
                            }
                        })
                        .catch((error) => { console.error(error); });
                } catch (error) { console.log(error); }
            })();
       // }
    };
    const CurrentActiveDataTitle = (prop) => {
        console.log(prop.keyid)
        return seletOptionType.map((item, index) => (
            (item.key == prop.keyid) ? item.title + ' Options' : ''

        ));
    };
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // This creates a smooth scroll effect
        });
    };


    /*--------------------delete item---------------------*/
    const userdetails = JSON.parse(localStorage.getItem("squadGologin"));
    const [currentEditId, setcurrentEditId] = useState(0);
    const [isBlockModelShow, setIsBlockModelShow] = useState(false);
    //const closeBlockModelShow  = () => { setIsBlockModelShow(false) }
    const showUserBlockAction = (id) => { setcurrentEditId(id); setIsBlockModelShow(true) }
    const blockCurrentActiveItem = () => {
        (async () => {
            try {
                const formData = new FormData();
                axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/get_all_options_settings_action/${userdetails.login_token}/${currentActiveSetting}/${currentEditId}?actiontype=block`, formData)
                    .then((response) => {
                       // setIsModalDataLoad(false);
                        if (response.status == 200 && response.data.status == 1) {
                            toast.success(response.data.message); 
                            // setstaffViewDetailsDataArr(response.data.data); 
                            getSettingOptionsData(); 
                            setIsBlockModelShow(false);
                        } else {
                            toast.warning(response.data.message);
                        }
                    })
                    .catch((error) => { console.error(error); });
            } catch (error) { console.log(error); }
        })();
    }

     /*--------------------status item---------------------*/
     const [isStatusModelShow, setIsStatusModelShow] = useState(false);
     const [takeStatusType, settakeStatusType] = useState(0);
    // const [currentStatusChangeId, setcurrentStatusChangeId] = useState(0);
     //takeStatusType currentStatusChangeId
     const handleStatusChange = (id,statutype) => {
         settakeStatusType(statutype);
         setcurrentEditId(id);
         setIsStatusModelShow(true);
     };
     const statusChangeFun = () => {
         (async () => {
             try {
                 const formData = new FormData();
                 axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/get_all_options_settings_action/${userdetails.login_token}/${currentActiveSetting}/${currentEditId}?actiontype=statuschange&status=${takeStatusType}`, formData)
                     .then((response) => {
                        // setIsModalDataLoad(false);
                         if (response.status == 200 && response.data.status == 1) {
                             // setstaffViewDetailsDataArr(response.data.data);
                             toast.success(response.data.message); 
                             getSettingOptionsData(); 
                             setIsStatusModelShow(false);
                         }  else {
                            toast.warning(response.data.message);
                        }
                     })
                     .catch((error) => { console.error(error); });
             } catch (error) { console.log(error); }
         })();
     }
    return (
        <>
        {/* <ToastContainer />*/}
            <div className="dashboarwrap">

            <div className="pagebanner">
               <div className="container">
                  <div className="row">
                     <div className="col-md-12">
                        <h2>CMS Options</h2>
                     </div>
                  </div>
               </div>
            </div>

                <div className="adminwrap py-4">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3 adminsidebar">
                                <SuperAdminSidebar></SuperAdminSidebar>
                            </div>
                            <div className="col-md-9">
                                <div className="box mb-4">

                                    <div className="table_filter mb-2 d-flex align-items-center justify-content-between commonform">
                                        <div className="box-ttl mb-3 d-flex align-items-center justify-content-between">
                                            <h3>{<CurrentActiveDataTitle keyid={currentActiveSetting} />}</h3>
                                        </div>
                                        <div className="tblrightfilters d-flex align-items-center">
                                            <select className="form-control ml-3 text-capitalize" onChange={(e) => currentActiveSettingFun(e.target.value)} >
                                                {
                                                    seletOptionType.map((item, index) => (
                                                        <option value={item.key} key={item.key}>{item.title}</option>
                                                    ))
                                                }

                                            </select>
                                        </div>
                                    </div>


                                    {
                                        (currentActiveSetting ==  'please_select_option') ?
                                            <>
                                                <div className={`tab-pane fade show active mt45px`} >
                                                    <div className='loader_container'>
                                                        <p className='text-center'>Please select option for the different options settings</p>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <>


                                                {
                                                    <div className="table-responsive">
                                                        {<div className="">
                                                            {!isEditing ? (

                                                                <>
                                                                    <div className="table_filter mb-2 d-flex align-items-center justify-content-between commonform">
                                                                        <div className="tblsearch">
                                                                            <input onChange={(e) => setSearchKeyValue(e.target.value)} value={searchkey} type="text" className="form-control" placeholder="Search" />
                                                                        </div>
                                                                        <div className="tblrightfilters d-flex align-items-center">
                                                                            <select onChange={(e) => setStatusValue(e.target.value)} className="form-control ml-3 text-capitalize">
                                                                                {
                                                                                    selectStatus.map((item, index) => (
                                                                                        <option value={item.key} key={item.key}>{item.title}</option>
                                                                                    ))
                                                                                }
                                                                            </select>
                                                                            <div className=' ml-3 text-capitalize'>

                                                                                <button onClick={() => { setIsEditing(true); scrollToTop(); setEditJob({ id: null, title: '', status: 'Active' }) }} className="batn batn-orange">Add</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {!newSettingLoad ?
                                                                        <table className="table admintbl profiletbl">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>ID</th>
                                                                                    <th>Title</th>
                                                                                    <th>Status</th>
                                                                                    <th>Action</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {

                                                                                    jobTitles.length  ? 
                                                                                    jobTitles.map(job => (
                                                                                        <tr key={job.id}>
                                                                                            <td>{job.id}</td>
                                                                                            <td>{job.title}</td>
                                                                                            <td className='cursor_pointer' onClick={() => handleStatusChange(job.id , job.status)}>
                                                                                                {
                                                                                                    job.status == 1 ?
                                                                                                        <> <span className='btnsmall text-capitalize text-white btn-info'>Active</span>  </>
                                                                                                        :
                                                                                                        <> <span className='btnsmall text-capitalize text-white btn-danger'>Deactive</span>  </>
                                                                                                }</td>
                                                                                            <td>


                                                                                                <Link className="ml-3 faicon" onClick={() => {
                                                                                                    setIsEditing(true);
                                                                                                    scrollToTop();
                                                                                                    setEditJob(job);
                                                                                                }}> <i className="fa fa-pencil-square-o faicon" aria-hidden="true"></i> </Link>

                                                                                                <Link className="ml-3" onClick={(e) => showUserBlockAction(job.id)}  ><i className="faicon fa fa-trash" aria-hidden="true"></i></Link>


                                                                                            </td>
                                                                                        </tr>
                                                                                    ))
                                                                                    :
                                                                                    <>
                                                                                        <tr className='text-center'>
                                                                                            <td colSpan={4}>Data not Found</td>
                                                                                        </tr>
                                                                                    </>
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                        :
                                                                        <>
                                                                            <div className={`tab-pane fade show active mt45px`} >
                                                                                <div className='loader_container'>
                                                                                    <p className='text-center'><img className='width-100 loader_image' src={'/front/images/loader.gif'} /></p>
                                                                                </div>
                                                                            </div>
                                                                        </>}
                                                                </>

                                                            ) : (
                                                                <div>
                                                                    <form onSubmit={editJob.id ? handleEditJobSubmit : handleNewJobSubmit}>
                                                                        <div className="form-group">
                                                                            <label>Job Title:</label>
                                                                            <input type="text" value={editJob.title} onChange={e => {setEditJob({ ...editJob, title: e.target.value }) ; setNewJobTitle(e.target.value)}} className="form-control" required />
                                                                        </div>
                                                                        <div className="form-group mb-3">
                                                                            <label>Status:</label>
                                                                            <select value={editJob.status} onChange={e => setEditJob({ ...editJob, status: e.target.value })} className="form-control">
                                                                                <option value="1">Active</option>
                                                                                <option value="0">Deactive</option>
                                                                            </select>
                                                                        </div>
                                                                        <button type="submit" className="batn batn-orange">Save</button>
                                                                        <button onClick={() => setIsEditing(false)} className="batn batn-orange ml-2">Cancel</button>
                                                                    </form>
                                                                </div>
                                                            )}
                                                        </div>}
                                                    </div>

                                                }
                                            </>
                                    }



                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <Modal className="modal fade modalsmall jobacceptmodle" id="jobacceptmodle" show={isBlockModelShow} onHide={() => setIsBlockModelShow(false)} >
                <div className="modal-dialog1">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button type="button" className="btn-close" onClick={() => setIsBlockModelShow(false)}></button>
                            <div className="text-center mb-3">
                                {/* <img src="/front/images/greencheck.svg" /> */}
                            </div>
                            <h5 className="text-center mb-4">Do you want to delete?</h5>

                            <div className="btnsgroup text-center">
                                {/* <Link onClick={showUserBlockActionExecute} className="batn batn-orange" >Yes</Link> */}
                                <Link onClick={() => blockCurrentActiveItem()} className="batn batn-orange">Yes</Link>
                                <Link onClick={() => setIsBlockModelShow(false)} className="batn batn-orange batn-border">No</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>


            <Modal className="modal fade modalsmall jobacceptmodle" id="jobacceptmodle" show={isStatusModelShow} onHide={() => setIsStatusModelShow(false)} >
                <div className="modal-dialog1">
                    <div className="modal-content"> 
                        <div className="modal-body"> 
                            <button type="button" className="btn-close" onClick={() => setIsStatusModelShow(false)}></button>
                            <div className="text-center mb-3">
                                {/* <img src="/front/images/greencheck.svg" /> */}
                            </div>
                            <h5 className="text-center mb-4">Do you want to {takeStatusType == 1 ? 'deactivate' :'activate'}?</h5>

                            <div className="btnsgroup text-center">
                                {/* <Link onClick={showUserBlockActionExecute} className="batn batn-orange" >Yes</Link> */}
                                <Link onClick={() => statusChangeFun()} className="batn batn-orange">Yes</Link>
                                <Link onClick={() => setIsStatusModelShow(false)} className="batn batn-orange batn-border">No</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}
export default CmsOptionsSettings;