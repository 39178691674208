import React, { useState, useEffect, Component } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

import SuperAdminSidebar from './superadmin_sidebar'
import axios from 'axios';

import { ToastContainer, toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';


import PdfExportButton from './supcomp/PdfExportButton';
import ExcelExportButton from './supcomp/ExcelExportButton';

const Jobseekers = () => {
   const [dataisLoading, setDataisLoading] = useState(false);
   const [jobseekerDataArr, setjobseekerData] = useState([]);
   const [jobseekerExpData, setjobseekerExpData] = useState([]);
   const [currentActivePage, setCurrentActivePage] = useState(1);
   const [statusType, setstatusType] = useState('all');
   const [searchText, setSearchText] = useState('');
   const [profileStatusType, setProfileStatusType] = useState('all');


   const [totalPageCount, settotalPageCount] = useState(1);
   const paginationLoadContentNo = 10;
   const [jobseekerDataCount, setJobseekerDataCount] = useState(0);
   const userdetails = JSON.parse(localStorage.getItem("squadGologinSession"));

   const [moduleAccessibility] = useState(typeof (userdetails.accessibility) != undefined ? userdetails.accessibility.jobseekers : [{
      view: true,
      edit: true,
      delete: true,
   }]);
   const viewAccessibility = (moduleAccessibility != undefined && typeof (moduleAccessibility.view) != undefined) ? moduleAccessibility.view : true;
   const editAccessibility = (moduleAccessibility != undefined && typeof (moduleAccessibility.edit) != undefined) ? moduleAccessibility.edit : true;
   const deleteAccessibility = (moduleAccessibility != undefined && typeof (moduleAccessibility.delete) != undefined) ? moduleAccessibility.delete : true;


   const recTypeBlockUnBlock = [
      { key: 'all', value: 'Status' },
      { key: 0, value: 'Blocked' },
      { key: 1, value: 'Un Blocked' },
   ];

   const [tableOrderType, settableOrderType] = useState('ASC');

   const getJobseekerData = (search = '') => {

      const page = (search == '') ? currentActivePage : (1);
      (async () => {
         setDataisLoading(true);
         try {
            const formData = new FormData();
            axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/jobseekers/${userdetails.login_token}?page=${page}&search=${search}&profilestatus=${profileStatusType}&status=${statusType}&fromDate=${fromDate}&toDate=${toDate}&oderby=${tableOrderType}`, formData)
               .then((response) => {
                  setDataisLoading(false);
                  if (response.status == 200 && response.data.status == 1) {
                     setjobseekerData(response.data.data.userdata);
                     setjobseekerExpData(response.data.data.export_userdata);
                     setJobseekerDataCount(response.data.data.total_length);
                     const calculatedTotalPages = Math.ceil(response.data.data.total_length / paginationLoadContentNo);
                     settotalPageCount(calculatedTotalPages);
                  }
               })
               .catch((error) => { console.error(error); });
         } catch (error) { console.log(error); }

      })();
   }


   const [userDtailsDataArr, setUserDtailsDataArr] = useState([]);

   const getUserDetails = (user_id) => {
      (async () => {
         setDataLoading(true)
         try {
            const formData = new FormData();
            axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/jobseekers/${userdetails.login_token}/${user_id}`, formData)
               .then((response) => {
                  setDataLoading(false);
                  if (response.status == 200 && response.data.status == 1) {
                     setUserDtailsDataArr(response.data.userdata);
                  }
               })
               .catch((error) => { console.error(error); });
         } catch (error) { console.log(error); }

      })();
   }



   const getUserBlock = (user_id) => {
      (async () => {
         setDataLoading(true)
         try {
            const formData = new FormData();
            axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/jobseekersbloack/${userdetails.login_token}/0/${user_id}?paramtype=block`, formData)
               .then((response) => {
                  setDataLoading(false);
                  if (response.status == 200 && response.data.status == 1) {
                     setisMessageShow(true); setIsBlockModelShow(false); getJobseekerData();
                  }
               })
               .catch((error) => { console.error(error); });
         } catch (error) { console.log(error); }

      })();
   }


   // useEffect(() => { getJobseekerData(); }, []);
   useEffect(() => { getJobseekerData(); }, [currentActivePage, statusType, profileStatusType,tableOrderType]);


   const searchKeyValue = (searchkey) => { getJobseekerData(searchkey); setSearchText(searchkey) }

   const RenderPaginationNumbers = () => {
      const paginationNumbers = [];
      paginationNumbers.push(<li className="page-item" ><Link onClick={(e) => handlePageChange(currentActivePage - 1)} className="page-link" href="#"><i className="fa fa-angle-left" aria-hidden="true"></i></Link></li>)
      for (let i = 1; i <= totalPageCount; i++) { paginationNumbers.push(<li key={i} className={i === currentActivePage ? 'page-item active' : 'page-item'} ><Link onClick={() => handlePageChange(i)} className="page-link" href="#">{i}</Link></li>); }
      paginationNumbers.push(<li className="page-item" ><Link onClick={(e) => handlePageChange(currentActivePage + 1)} className="page-link" href="#"><i className="fa fa-angle-right" aria-hidden="true"></i></Link></li>)
      return paginationNumbers;
   };
   const handlePageChange = (newPage) => { if (newPage >= 1 && newPage <= totalPageCount) { setCurrentActivePage(newPage); } };

   /*--------------------------*/


   const statsOption = ['all', 'verified', 'unverified'];
   const onStatusChange = (type) => { setstatusType(type) }



   /*-------------------action button -----------------*/
   const [currentEditId, setcurrentEditId] = useState(0);
   const [dataLoading, setDataLoading] = useState(false);
   const [isMessageShow, setisMessageShow] = useState(false);

   const [isUserDetailsModelShow, setIsUserDetailsModelShow] = useState(false);
   //const closeUserModelShow  = () => {  setIsUserDetailsModelShow(false) }
   const showUserDetails = (id) => { setcurrentEditId(id); getUserDetails(id); setIsUserDetailsModelShow(true); }

   //isUserDetailsModelShow closeUserModelShow

   const [isBlockModelShow, setIsBlockModelShow] = useState(false);
   //const closeBlockModelShow  = () => { setIsBlockModelShow(false) }
   const showUserBlockAction = (id) => { setcurrentEditId(id); setIsBlockModelShow(true) }
   const showUserBlockActionExecute = () => { getUserBlock(currentEditId) }


   const showUserEmailDetails = (type) => { }


   /*------------------------date filter-------------------------*/

   const [hideSeekDateField, setHideSeekDateField] = useState(false);
   const [fromDate, setFromDate] = useState('');
   const [toDate, setToDate] = useState('');

   const handleFromDateChange = (event) => {
      const newFromDate = event.target.value;
      // Check if newFromDate is less than toDate
      if (!toDate || new Date(newFromDate) < new Date(toDate)) {
         setFromDate(newFromDate);
      } else { setFromDate(toDate); setToDate(newFromDate); }
   };

   const handleToDateChange = (event) => {
      const newToDate = event.target.value;
      if (!fromDate || new Date(newToDate) > new Date(fromDate)) {
         setToDate(newToDate);
      } else { setToDate(fromDate); setFromDate(newToDate); }
   };

   useEffect(() => {
      if (fromDate && toDate) { getJobseekerData(); }
   }, [fromDate, toDate]);



   return (
      <>
         {/* <ToastContainer />*/}
         <div className="dashboarwrap">

            <div className="pagebanner">
               <div className="container">
                  <div className="row">
                     <div className="col-md-12">
                        <h2>Jobseekers</h2>
                     </div>
                  </div>
               </div>
            </div>

            <div className="adminwrap py-4">
               <div className="container">
                  <div className="row">
                     <div className="col-md-3 adminsidebar">
                        <SuperAdminSidebar></SuperAdminSidebar>
                     </div>
                     <div className="col-md-9">
                        <div className="box mb-4">
                           {/* <div className="box-ttl mb-3 d-flex align-items-center justify-content-between">
                              <h3>Jobseekers</h3>
                           </div> */}
                           <div className="table_filter mb-2 d-flex align-items-center justify-content-between commonform">


                           <div className="tblsearch2">
                                       <input onChange={(e) => searchKeyValue(e.target.value)} value={searchText} type="text" className="form-control" placeholder="Search" fdprocessedid="imthp7" />
                                    </div>

                                 <div className="d-flex align-items-center datefilter">

                                    <PdfExportButton jsonData={jobseekerExpData} export_for={'jobseeker'} />
                                    <ExcelExportButton jsonData={jobseekerExpData} export_for={'jobseeker'} />
                                    <span onClick={() => { setSearchText(''); setstatusType('all'); setProfileStatusType('all'); getJobseekerData(); setFromDate(''); setToDate('') }} className="reseticon filtericon" data-toggle="tooltip" title="Reset filters">
                                       <i className="fa fa-refresh"></i>
                                    </span>
                                 </div>



                           </div>
                           


                           <div className="table_filter mb-2 d-flex align-items-center justify-content-between commonform">


                              <div className="tblrightfilters d-flex align-items-center">

                                 <div className="d-flex align-items-center datefilter">
                                   
                                    <input value={fromDate} onChange={handleFromDateChange} type="date" className="form-control  text-capitalize mr-2"></input>
                                    <span>To</span>
                                    <input value={toDate} onChange={handleToDateChange} type="date" className="form-control  text-capitalize ml-2"></input>

                                    <select className="form-control ml-3 text-capitalize" onChange={(e) => onStatusChange(e.target.value)} >
                                       {
                                          statsOption.map((item, index) => (
                                             <option selected={statusType == item} value={item} key={index}>{item}</option>
                                          ))
                                       }

                                    </select>

                                    <select className="form-control ml-3 text-capitalize" onChange={(e) => setProfileStatusType(e.target.value)} >
                                       {
                                          recTypeBlockUnBlock.map((item, index) => (
                                             <option selected={profileStatusType == item.key} value={item.key} key={item.key}>{item.value}</option>
                                          ))
                                       }
                                    </select>
                                 </div>



                              </div>
                           </div>

                           {hideSeekDateField && <div className="table_filter mb-2 d-flex align-items-center justify-content-between commonform">
                              {/* <DateFilter onDateChange={handleDateChange} /> */}

                           </div>
                           }
                           <div className="table-responsive">
                              {
                                 !dataisLoading ?
                                    <>
                                       <table id="asddsaasddsa" className="table admintbl profiletbl">
                                          <thead>
                                             <tr>
                                                {/* <th></th> */}
                                                <th className='cursor_pointer' onClick={() => settableOrderType((tableOrderType == 'ASC' ? 'DESC' :'ASC'))}>
                                                User Id
                                                {tableOrderType == 'ASC' ? <i className="fa fa-angle-down ml-5"></i> : <i className="fa fa-angle-up ml-5"></i> }
                                                </th>
                                                <th>Full name</th>
                                                <th>Email</th>
                                                <th>Phone Number</th>
                                                <th>Reg. Date</th>
                                                <th>Action</th>
                                             </tr>
                                          </thead>
                                          <tbody>
                                             {/* redbtn */}
                                             {/* {
                                             console.log(jobseekerDataArr)
                                          } */}


                                             {

                                                jobseekerDataArr.length ?
                                                   jobseekerDataArr.map((item, index) => (
                                                      <>
                                                         <tr key={item.unique_id} className={`${item.status == 1 ? 'active_tr' : 'deactive_tr'}  ${item.profile_enabled == 1 ? 'profile_is_enabled' : 'profile_is_disabled'}`} >
                                                            <td>{item.unique_id}</td>
                                                            <td className='text-capitalize' >
                                                            <Link to={`/jobseeker-detail?id=${item.unique_id}`} data-toggle="tooltip" title="View Jobseeker details" className="ml-3">
                                                               <span className='pl-5'>{item.first_name + ' ' + item.last_name}</span>
                                                            </Link>
                                                            </td>
                                                            <td>{item.email ? item.email : '----------'}</td>
                                                            <td>{item.contact_number ? `${item.contact_country_code ? '+'+item.contact_country_code+'-' : ''}`+item.contact_number : '----------'}</td>
                                                            <td>{item.created_date ? item.created_date : '----------'}</td>
                                                            <td>
                                                               <div className="d-flex">
                                                                  {(viewAccessibility || editAccessibility) && <Link to={`/jobseeker-detail?id=${item.unique_id}`} data-toggle="tooltip" title="View Jobseeker details" className="ml-3"><img src="/front/images/Show.svg" /></Link>}

                                                                  {deleteAccessibility && <Link onClick={(e) => showUserBlockAction(item.user_id, item.status)} className="ml-3">
                                                                     <span className="custom-tooltip" data-toggle="tooltip" title={item.status == 1 ? 'Do you want to deactivate account?' : 'Do you want to activate account?'}>
                                                                        {/* <i className={item.status == 1 ? 'fabanicon fa fa-circle-o  text-success1' : 'fabanicon fa fa-ban text-danger'} aria-hidden="true"></i> */}
                                                                        {/* <i className="fa fa-circle-o" aria-hidden="true"></i> */}
                                                                        {item.status == 1 ? <img className='block_unblock_icon' src="/front/images/blocked.png" /> : <img className='block_unblock_icon' src="/front/images/unblocked.png" />}

                                                                     </span>
                                                                  </Link>}
                                                               </div>
                                                            </td>
                                                         </tr>
                                                      </>
                                                   ))
                                                   :
                                                   <>
                                                      <tr>
                                                         <td className='text-center' colSpan={6}> No information available to display</td>
                                                      </tr>
                                                   </>
                                             }


                                          </tbody>
                                       </table>

                                    </>
                                    :
                                    <>
                                       <div className={`tab-pane fade show active mt45px`} >
                                          <div className='loader_container'>
                                             <p className='text-center'><img className='width-100 loader_image' src={'/front/images/loader.gif'} /></p>
                                          </div>
                                       </div>
                                    </>
                              }

                              {
                                 ((jobseekerDataCount > paginationLoadContentNo)) ?
                                    <>
                                       <div className="card-box">
                                          <nav aria-label="Page navigation">
                                             <ul className="pagination mb-0 justify-content-center">
                                                <RenderPaginationNumbers />
                                             </ul>
                                          </nav>
                                       </div>
                                    </>
                                    : ''
                              }
                              {/* <div className="text-center"><a href="#" className="batn batn-orange mt-2">View All profiles</a></div> */}
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>


         {/* jobaccept model chat redirect */}

         <Modal className="modal fade modalsmall jobacceptmodle" id="jobacceptmodle" show={isBlockModelShow} onHide={() => setIsBlockModelShow(false)} >
            <div className="modal-dialog1">
               <div className="modal-content">
                  <div className="modal-body">
                     <button type="button" className="btn-close" onClick={() => setIsBlockModelShow(false)}></button>
                     <div className="text-center mb-3">
                        {/* <img src="/front/images/greencheck.svg" /> */}
                     </div>
                     <h5 className="text-center mb-4">Do you want to deactivate account</h5>

                     <div className="btnsgroup text-center">
                        <Link onClick={showUserBlockActionExecute} className="batn batn-orange" >Yes</Link>
                        <Link onClick={() => setIsBlockModelShow(false)} className="batn batn-orange batn-border">No</Link>
                     </div>
                  </div>
               </div>
            </div>
         </Modal>

         <Modal className="modal fade modallarg jobacceptmodle" id="jobacceptmodle" show={isUserDetailsModelShow} onHide={() => setIsUserDetailsModelShow(false)}>
            <div className="modal-dialog1">
               <div className="modal-content">
                  <div className="modal-body">
                     <button type="button" className="btn-close" onClick={() => setIsUserDetailsModelShow(false)}></button>
                     <div className="joblistbox">

                        {
                           !dataLoading ?
                              <>
                                 <div className="d-flex flex-wrap justify-content-between">
                                    {console.log(userDtailsDataArr)}
                                    <div className="joblistbox">
                                       <div className="d-flex flex-wrap justify-content-between">
                                          <h3>{userDtailsDataArr.job_title_text}</h3>
                                          <h3 className="job_price orange_clr">
                                             <strong>{userDtailsDataArr.salary_minimum ? '$' + userDtailsDataArr.salary_minimum + '/Hour' : '--'}</strong>
                                             <strong className="subheadpera">To</strong>
                                             <strong>{userDtailsDataArr.salary_maximum ? '$' + userDtailsDataArr.salary_maximum + '/Hour' : '--'}</strong>
                                          </h3>
                                       </div>
                                       <div><span className="tags">{userDtailsDataArr.job_type_text}</span></div>
                                       {/* <p>Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Sed hendrerit. Maecenas nec odio et ante condimentum rhoncus, sem quam semper libero condimentum rhoncus, sem quam...</p> */}
                                       <div className="d-flex flex-wrap mt-2">
                                          <div className="icontxt"><img src="/front/images/calender.png"></img> Offer date: <span>{userDtailsDataArr.created_date_format ?? '.....'}</span></div>
                                          <div className="icontxt"><img src="/front/images/clock.png"></img> Offer expire date: <span>{userDtailsDataArr.expired_date_format ?? '.....'}</span></div>
                                          <div className="icontxt"><img src="/front/images/location.png"></img> Location: <span>{userDtailsDataArr.job_location_text ?? '......'}</span></div>
                                          <div className="icontxt"><img src="/front/images/bag.png"></img> Job type: <span>{userDtailsDataArr.job_type_text}</span></div>
                                          <div className="icontxt"><img src="/front/images/star.png"></img> Experience: <span>{userDtailsDataArr.total_experience_month + '.' + userDtailsDataArr.total_experience_year + ' Years'}</span></div>
                                       </div>
                                       <hr>
                                       </hr>
                                       <h5>Availability to work</h5>
                                       <p>Jobseeker should available on below time.</p>
                                       {/* <Availability  abdata={userDtailsDataArr.availibility} ></Availability> */}



                                       <hr></hr>
                                       <h5>Paying extra for</h5>
                                       <ul>
                                          <li className='text-capitalize'>Public holidays : {userDtailsDataArr.are_you_paying_extra_in_holiday}  </li>
                                          <li className='text-capitalize'>Weekend : {userDtailsDataArr.are_you_paying_extra_in_weekend} </li>
                                          <li className='text-capitalize'>Overtime : {userDtailsDataArr.are_you_paying_extra_in_overtime} </li>
                                       </ul>


                                       <hr></hr>

                                       <h5>Education qualification</h5>
                                       <p>Jobseeker should have following degree and diplomas:</p>
                                       <ul>
                                          <li>{userDtailsDataArr.education_qualification_text}</li>
                                       </ul>
                                       <hr>
                                       </hr>

                                       <h5>Language known</h5>
                                       <p>Jobseeker should be fluent in following languages:</p>
                                       <ul>
                                          <li>{userDtailsDataArr.language_text}</li>
                                       </ul>
                                       <hr>
                                       </hr>
                                       <h5>About recruiter</h5>



                                    </div>
                                 </div>
                              </>
                              :
                              <>
                                 <div className={`tab-pane fade show active mt45px`} >
                                    <div className='loader_container'>
                                       <p className='text-center'><img className='width-100 loader_image' src={'/front/images/loader.gif'} /></p>
                                    </div>
                                 </div>
                              </>
                        }

                     </div>
                     {/* <div className="btnsgroup text-center">
                        <Link to={userAccountStatusChange} className="batn batn-orange" >Yes</Link>
                        <Link onClick={closeUserModelShow} className="batn batn-orange batn-border">No</Link>
                     </div> */}
                  </div>
               </div>
            </div>
         </Modal>






         <Modal className="modal fade modalsmall jobacceptmodle" id="jobacceptmodle" show={isMessageShow} onHide={() => setisMessageShow(false)}>
            <div className="modal-dialog1">
               <div className="modal-content">
                  <div className="modal-body">
                     <button type="button" className="btn-close" onClick={() => setisMessageShow(false)}></button>
                     <div className="text-center mb-3">
                        <img src="/front/images/greencheck.svg" />
                     </div>
                     <h5 className="text-center mb-4">Successfully </h5>
                  </div>
               </div>
            </div>
         </Modal>


      </>
   );
}
export default Jobseekers;