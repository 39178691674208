import React, { useState, useEffect, Component, useCallback } from 'react';
// import { BrowserRouter as Router, Routes, Route, NavLink, useParams, useNavigate } from 'react-router-dom';
// import { Form, Button } from 'react-bootstrap';
import axios from 'axios';
// import ProfileBanner from '../profile_header';
// import ProfileSidebar from '../profile_sidebar';
// import ContactFormComp from '../ContactDetailsComp';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import { useSelector, useDispatch } from 'react-redux'
//import { basicDetailsValidationCheck } from '../../redux_state/reducres/checkDocumentstatus';
import SuperAdminSidebar from './superadmin_sidebar';
import PaymentRequest from './paymentRequest';

import RecWalletHist from '../WalletHistoryCom';

const AdminWallet = () => {


   const useriddata = JSON.parse(localStorage.getItem("squadGologinSession"));
   const [adminWalletData, setAdminWalletData] = useState(
      {
         total_coins_sold: 0,
         total_coins_amount: 0.00,
         total_coins_bonuspaid: 0,
         total_coins_refundpaid: 0,
         total_coins_rewardpaid: 0,
         total_coins_compensation_paid: 0,
         transection_history: [],
      }
   );

   const handleChildDataChange = useCallback((newData) => {  setAdminWalletData(newData); }, []);


   return (

      <>
         <ToastContainer />
         <div className="dashboarwrap">
            <div className="pagebanner">
               <div className="container">
                  <div className="row">
                     <div className="col-md-12">
                        <h2>Wallet</h2>
                     </div>
                  </div>
               </div>
            </div>
            <div className="jobbtmsec py-5 admininfo">
               <div className="container">

                  <div className="row">
                     <div className="col-md-3 profilesidebarwrap">
                        <SuperAdminSidebar></SuperAdminSidebar>
                     </div>
                     <div className="col-md-9">
                        <div className="profilerightwraper stepscont walletblocks">
                           {/* {
                              console.log(adminWalletData.transection_history)
                           } */}
                           {/* {adminWalletData.total_coins_amount} */}
                           <div className="row">
                              <div className="col-md-6 mb-4">
                                 <div className="box ststicsbox d-flex justify-content-between redbg">
                                    <div className="ststicsboxleft">
                                       <p>Total coins sold</p>
                                       <h2>{adminWalletData.total_coins_sold}

                                          (${(adminWalletData.total_coins_amount)})
                                          <i className="fa fa-arrow-up" aria-hidden="true"></i>
                                       </h2>
                                    </div>
                                    <div className="ststicsboxright"><img src="/front/images/ion.png" /></div>
                                 </div>
                              </div>
                              <div className="col-md-6 mb-4">
                                 <div className="box ststicsbox d-flex justify-content-between redbg">
                                    <div className="ststicsboxleft">
                                       <p>Bonuse Paid</p>
                                       <h2>{adminWalletData.total_coins_bonuspaid} <i className="fa fa-arrow-up" aria-hidden="true"></i></h2>
                                    </div>
                                    <div className="ststicsboxright"><img src="/front/images/deposit.png" /></div>
                                 </div>
                              </div>
                              <div className="col-md-6 mb-4">
                                 <div className="box ststicsbox d-flex justify-content-between redbg">
                                    <div className="ststicsboxleft">
                                       <p>Compensation  Paid</p>
                                       <h2>{adminWalletData.total_coins_compensation_paid} <i className="fa fa-arrow-up" aria-hidden="true"></i></h2>
                                    </div>
                                    <div className="ststicsboxright"><img src="/front/images/revenue.png" /></div>
                                 </div>
                              </div>
                              <div className="col-md-6 mb-4">
                                 <div className="box ststicsbox d-flex justify-content-between redbg">
                                    <div className="ststicsboxleft">
                                       <p>Refunds Paid</p>
                                       <h2>{adminWalletData.total_coins_refundpaid} <i className="fa fa-arrow-up" aria-hidden="true"></i></h2>
                                    </div>
                                    <div className="ststicsboxright"><img src="/front/images/loss.png" /></div>
                                 </div>
                              </div>

                              <div className="col-md-6 mb-4">
                                 <div className="box ststicsbox d-flex justify-content-between redbg">
                                    <div className="ststicsboxleft">
                                       <p>Rewards Paid</p>
                                       <h2 className="dashboard_tile"><a href="/jobseekers?backto=dashboard">{adminWalletData.total_coins_rewardpaid} <i className="fa fa-arrow-up" aria-hidden="true"></i></a></h2>

                                    </div>
                                    <div className="ststicsboxright"><img src="/front/images/reward.png" /></div>
                                 </div>
                              </div>
                           </div>
                           {/* <div className="row">
                              <div className="col-md-12">
                                 <div className="box mb-4">
                                    <div className="box-ttl mb-3 d-flex align-items-center justify-content-between">
                                       <h3> </h3>
                                       <div className="switch-field mr-0">
                                          <div className="switchfieldinner"><input type="radio" id="purchased" name="registration_statistick" checked="" /><label for="purchased">Sold</label></div>
                                          <div className="switchfieldinner"><input type="radio" id="withdrawn" name="registration_statistick" /><label for="withdrawn">Withdrawn</label></div>
                                          <div className="switchfieldinner"><input type="radio" id="rewards" name="registration_statistick" /><label for="rewards">Rewards</label></div>
                                       </div>
                                    </div>
                                    <div><img src="/front/images/graph.jpg" /></div>
                                 </div>
                              </div>
                           </div> */}
                           <div className="row">
                              <div className="col-md-12">
                                 <PaymentRequest />
                                 <RecWalletHist onChildDataChange={handleChildDataChange} />
                              </div>
                           </div>
                        </div>
                     </div>

                  </div>
               </div>
            </div>

         </div>
      </>

   );
}
export default AdminWallet;