import React, { useState, useEffect, Component , useRef} from 'react';
import SuperAdminSidebar from './superadmin_sidebar'
import ChatHistoryComp from './supcomp/ChatHistoryComp'


const ChatHistory = () => {

   const useriddata = JSON.parse(localStorage.getItem("squadGologinSession"));
  
   return (
      <>
         <div className="dashboarwrap">
            <>
            <div className="pagebanner">
               <div className="container">
                  <div className="row">
                     <div className="col-md-12">
                        <h2>Chat History</h2>
                     </div>
                  </div>
               </div>
            </div>
               <div className="adminwrap py-4">
                  <div className="container">
                     <div className="row">
                        <div className="col-md-3 adminsidebar">
                           <SuperAdminSidebar></SuperAdminSidebar>
                        </div>
                        <div className="col-md-9">
                           <div className="row">
                              <ChatHistoryComp />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </>

         </div>
      </>
   );
}
export default ChatHistory;