import React, { useState, useCallback, useEffect } from 'react';
import BANKLIST from '../json/austrlia_bank_list.json'
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';

const BankDetailsComp = ({ setActionNextChild }) => {

    const useriddata = JSON.parse(localStorage.getItem("squadGologinSession"));
    const [account_id, setAccountId] = useState(0);
    const [withDrawAccount, setwithDrawAccount] = useState(0);
    const [isDataLoading, setisDataLoading] = useState(true);
    const [isAccountListShow, setIsAccountListShow] = useState(true);
    const [validated, setValidated] = useState(false);


    const [bankName, setBankName] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [bsbCode, setBSBCode] = useState('');
    const [branch, setBranch] = useState('');
    const [city, setCity] = useState('');
    const [errors, setErrors] = useState({
        bankName: '',
        accountNumber: '',
        bsbCode: ''
    });


    const setwithDrawAccountFun = (id) => {
        console.log('childe=' + id);
        //setActionNextChild(id); 
    }

    /*-----------  on page load data -------------*/
    const [bankAccountData, setBankAccountData] = useState([]);
    const get_accountdetails = async () => {
        try {
            const response = await axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/api/wallet/get_accountdetails/${useriddata.login_token}`);
            if (response.status === 200 && response.data.status == 1) {
                //setWalletSettingData
                setBankAccountData(response.data.data);
                setwithDrawAccount(response.data.currentactive);
                setisDataLoading(false);
            } else { console.error('Error:', response.status); }
        } catch (error) { console.error('Error:', error); }
    }

    useEffect(() => { get_accountdetails(); }, []);


    /*----------- form submit and validation-------------*/

    const accountNumberRegex = /^\d{5,15}$/;
    const bsbCodeRegex = /^\d{6}$/;

    const validateBankDetailsForm = () => {
        let isValid = true;
        const newErrors = {
            bankName: '',
            accountNumber: '',
            bsbCode: ''
        };

        // Check if bank name is selected
        if (bankName == '') {
            // alert(bankName);
            newErrors.bankName = 'Please select a bank.';
            isValid = false;
        }

        // Validate account number
        if (!accountNumberRegex.test(accountNumber)) {
            newErrors.accountNumber = 'Please enter a valid account number (5-15 digits).';
            isValid = false;
        }

        // Validate BSB code
        if (!bsbCodeRegex.test(bsbCode)) {
            newErrors.bsbCode = 'Please enter a valid BSB code (6 digits).';
            isValid = false;
        }


        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            console.log('submit')
        } else {
            if (validateBankDetailsForm()) {
                try {
                    const formData = new FormData();
                    formData.append('loginToken', useriddata.login_token);
                    formData.append('bank_name', bankName);
                    formData.append('account_number', accountNumber);
                    formData.append('bsb_code', bsbCode);
                    formData.append('branch', branch);
                    formData.append('city', city);
                    formData.append('account_id', account_id);

                    const response = await axios.post('https://squadgoo.com.au/api.squadgoo.com.au/api/wallet/save_accountdetails', formData);
                    if (response.status == 200 && response.data.status == 1) {
                        toast.success(response.data.message);
                        setBankAccountData(response.data.data);
                        setIsAccountListShow(!isAccountListShow);
                    } else { toast.warning('something went wrong'); }
                } catch (err) { console.log(err); }
            }
        }
        setValidated(true);
    };

    const findBankNameById = (id) => {
        const bank = BANKLIST.find(bank => bank.id === parseInt(id));
        return bank ? bank.name : 'Bank not found';
    };


    /*-----------account active form-------------*/
    const accountActivateFun = async (id) => {
        if (window.confirm("Do you want to activate this details?")) {
            try {
                const response = await axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/api/wallet/bank_accountactivated/${useriddata.login_token}/${id}`);
                if (response.status === 200 && response.data.status == 1) {
                    setBankAccountData(response.data.data);
                    //setisDataLoading(false);
                    setwithDrawAccount(id);
                } else { console.error('Error:', response.status); }
            } catch (error) { console.error('Error:', error); }
        }
    }

    /*-----------edit bank data-------------*/
    const setBankDetailsUpdate = async (id) => {


        setisDataLoading(true);
        try {
            const response = await axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/api/wallet/get_spesificaccountdetails/${useriddata.login_token}/${id}`);
            if (response.status === 200 && response.data.status == 1) {
                let bankdata = response.data.data;
                setBankName(bankdata.bank_name);
                setAccountNumber(bankdata.account_number);
                setBSBCode(bankdata.bsb_code);
                setBranch(bankdata.branch);
                setCity(bankdata.city);
                setisDataLoading(false);
                setIsAccountListShow(!isAccountListShow);
            } else { console.error('Error:', response.status); }
        } catch (error) { console.error('Error:', error); }

    }


    /*-----------account trash form-------------*/
    const accountTrashItem = async (id) => {
        if (window.confirm("Do you want to trash this details?")) {
            try {
                const response = await axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/api/wallet/bank_accountacttrash/${useriddata.login_token}/${id}`);
                if (response.status === 200 && response.data.status == 1) {
                    setBankAccountData(response.data.data);
                    //setisDataLoading(false);
                    setwithDrawAccount(id);
                } else { console.error('Error:', response.status); }
            } catch (error) { console.error('Error:', error); }
        }
    }

    return (

        <>
            <div className='m-3'>
                {
                    isDataLoading ? <>
                        <div className={`tab-pane mt45px`} >
                            <div className='loader_container'>
                                <p className='text-center'><img className='width-100 loader_image' src={'/front/images/loader.gif'} /></p>
                            </div>
                        </div>
                    </>
                        :
                        <>
                            {
                                (isAccountListShow) ?
                                    <>
                                        {
                                            bankAccountData.map((item, index) => (
                                                <>
                                                    <div className={`card-box mt-2 joblistbox mb-1  ${(item.account_active == 1) ? 'success_div' : 'danger_div'}`} role="alert">
                                                        {/*  */}
                                                        <h3 className='mb-1'>
                                                            {findBankNameById(item.bank_name)}
                                                            {
                                                                (item.is_verified == 1)
                                                                    ?
                                                                    <img className="veri_unveri_icon" src="/front/images/vfy.png" data-toggle="tooltip" data-placement="top" title="verified"></img>
                                                                    :
                                                                    <img className="veri_unveri_icon" src="/front/images/uvfy.png" data-toggle="tooltip" data-placement="top" title="unverified"></img>

                                                            }
                                                            {(item.account_active != 1) && <div data-toggle="tooltip" data-placement="top" title="Do you want to activate edit this account ?" onClick={(e) => accountActivateFun(item.account_id)} className="editbtn float-right ml4px"><i className="fa fa-check-circle-o" aria-hidden="true"></i></div>}
                                                            {(item.account_active != 1) && <div data-toggle="tooltip" data-placement="top" title="Do you want to trash ?" onClick={() => accountTrashItem(item.account_id)} className="editbtn float-right ml4px"><img src="/front/images/trash.svg" /></div>}
                                                            <div data-toggle="tooltip" data-placement="top" title="Do you want to activate this account ?" onClick={() => setBankDetailsUpdate(item.account_id)} className="editbtn float-right "><img src="/front/images/edit.png" /></div>
                                                        </h3>
                                                        {/* <span className={`tags float-right cursor_pointer  ${(item.account_active == 1) ? 'bgdarkgreen' : 'bgdarkred'}`} onClick={(e) => accountActivateFun(item.account_id)} >{(item.account_active == 1) ? 'Active' : 'Deactive'}</span> */}
                                                        <div className="d-flex flex-wrap">
                                                            <div className="icontxt">Account Number: <span>{item.account_number}</span></div>
                                                            <div className="icontxt ml-2">BSB code: <span>{item.bsb_code ? item.bsb_code : '----'}</span></div>
                                                            <div className="icontxt ml-2">Branch: <span>{(item.branch != 0) ? item.branch : '----'}</span></div>
                                                            <div className="icontxt ml-2">City: <span>{(item.city != 0) ? item.city : '----'}</span></div>
                                                            {/* <div className="icontxt ml-2">Status: <span>{(item.status == 1) ? 'Active' : 'Deactive'}</span></div> */}
                                                        </div>
                                                    </div>
                                                </>
                                            ))
                                        }
                                        <span onClick={() => setIsAccountListShow(!isAccountListShow)} className="cursor_pointer   add_bank_details_link d-inline-flex align-items-center"><span><img className="mr-2" src="/front/images/addwork.svg" /> <span className='mt-1'> Add new account</span> </span></span>
                                    </> :
                                    <>
                                        <span onClick={() => setIsAccountListShow(!isAccountListShow)} className="cursor_pointer   add_bank_details_link d-inline-flex align-items-center"><span> <i className="fa fa-arrow-left"></i> List Show </span></span>
                                        <div className='m-3'>
                                            <Form className='row' noValidate validated={validated} onSubmit={handleSubmit}>
                                                <div className="form-group">
                                                    <label htmlFor="bankName">Bank Name</label>
                                                    <select
                                                        className="form-control"
                                                        id="bankName"
                                                        name="bank_name"
                                                        value={bankName}
                                                        onChange={(e) => { setBankName(e.target.value); setErrors(prevErrors => ({ ...prevErrors, bankName: '' })) }}
                                                        required
                                                    >
                                                        <option value={''}>Select</option>
                                                        {
                                                            BANKLIST.map((item, index) => (
                                                                <option key={item.key + '-' + index} value={item.id}>{item.name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                    {errors.bankName && <div className="error-feedback">{errors.bankName}</div>}
                                                </div>
                                                <div className={`form-group ${errors.accountNumber ? 'has-invalid' : ''}`}>
                                                    <label htmlFor="accountNumber">Account Number</label>
                                                    <input
                                                        type="text"
                                                        className={`form-control ${errors.accountNumber ? 'is-invalid' : 'is-valid'}`}
                                                        id="accountNumber"
                                                        value={accountNumber}
                                                        onChange={(e) => { setAccountNumber(e.target.value); }}
                                                        placeholder="Enter account number"
                                                        required
                                                    />
                                                    {errors.accountNumber && <div className="error-feedback">{errors.accountNumber}</div>}
                                                </div>
                                                <div className={`form-group ${errors.bsbCode ? 'has-invalid' : ''}`}>
                                                    <label htmlFor="bsbCode">BSB Code</label>
                                                    <input
                                                        type="text"
                                                        className={`form-control ${errors.bsbCode ? 'is-invalid' : 'is-valid'}`}
                                                        id="bsbCode"
                                                        value={bsbCode}
                                                        onChange={(e) => { setBSBCode(e.target.value); }}
                                                        placeholder="Enter BSB code"
                                                        required
                                                    />
                                                    {errors.bsbCode && <div className="error-feedback">{errors.bsbCode}</div>}
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="branch">Branch</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="branch"
                                                        value={branch}
                                                        onChange={(e) => setBranch(e.target.value)}
                                                        placeholder="Enter branch name"
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="city">City</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="city"
                                                        value={city}
                                                        onChange={(e) => setCity(e.target.value)}
                                                        placeholder="Enter city"
                                                        required
                                                    />
                                                </div>
                                                <div className=' col-md-6'>
                                                    <button type="submit" className="batn batn-orange backbtnchkout mt-3">Save</button>
                                                </div>
                                            </Form>

                                        </div>

                                    </>
                            }
                        </>
                }
            </div>
        </>

    );
}
export default BankDetailsComp;