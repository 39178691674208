import SuperAdminSidebar from './superadmin_sidebar'
const Expenses = () => {
   return (
      <>
         <div className="dashboarwrap">

         <div className="pagebanner">
               <div className="container">
                  <div className="row">
                     <div className="col-md-12">
                        <h2>Expenses</h2>
                     </div>
                  </div>
               </div>
            </div>


            <div className="adminwrap py-4">
               <div className="container">
                  <div className="row">
                     <div className="col-md-3 adminsidebar">
                        <SuperAdminSidebar></SuperAdminSidebar>
                     </div>
                     <div className="col-md-9">
                        
                     <div className="row">
                        <div className="col-md-4 mb-4">
                           <div className="box ststicsbox d-flex justify-content-between coinbox Totalearning">
                              <div className="ststicsboxleft">
                                 <p>Total Expenses </p>
                                 <h2>54904 <span>Coins</span></h2>
                              </div>
                              <div className="ststicsboxright"><img src="/front/images/ion.png"/></div>
                           </div>
                        </div>
                        <div className="col-md-4 mb-4">
                           <div className="box ststicsbox d-flex justify-content-between coinbox">
                              <div className="ststicsboxleft">
                                 <p>Rewards</p>
                                 <h2>30495 <span>Coins</span></h2>
                              </div>
                              <div className="ststicsboxright"><img src="/front/images/reward.png"/></div>
                           </div>
                        </div>
                        <div className="col-md-4 mb-4">
                           <div className="box ststicsbox d-flex justify-content-between coinbox">
                              <div className="ststicsboxleft">
                                 <p>Bonuses</p>
                                 <h2>24,409 <span>Coins</span></h2>
                              </div>
                              <div className="ststicsboxright"><img src="/front/images/revenue.png"/></div>
                           </div>
                        </div>
                        <div className="col-md-4 mb-4">
                           <div className="box ststicsbox d-flex justify-content-between coinbox">
                              <div className="ststicsboxleft">
                                 <p>Refunds</p>
                                 <h2>24,409 <span>Coins</span></h2>
                              </div>
                              <div className="ststicsboxright"><img src="/front/images/dollar.png"/></div>
                           </div>
                        </div>
                        <div className="col-md-4 mb-4">
                           <div className="box ststicsbox d-flex justify-content-between coinbox">
                              <div className="ststicsboxleft">
                                 <p>Withdrawals</p>
                                 <h2>24,409 <span>Coins</span></h2>
                              </div>
                              <div className="ststicsboxright"><img src="/front/images/loss.png"/></div>
                           </div>
                        </div>
                        <div className="col-md-4 mb-4">
                           <div className="box ststicsbox d-flex justify-content-between coinbox">
                              <div className="ststicsboxleft">
                                 <p>Compensation</p>
                                 <h2>24,409 <span>Coins</span></h2>
                              </div>
                              <div className="ststicsboxright"><img src="/front/images/compensation.png"/></div>
                           </div>
                        </div>
                     </div>

                     <div className="row">
                        <div className="col-md-12">
                           <div className="box mb-4">
                                 <div className="box-ttl mb-3 d-flex align-items-center justify-content-between">    
                                       <h3>Expenses</h3>
                                       <div className="switch-field mr-0">
                                       <div className="switchfieldinner">
                                       <input type="radio" id="monthly" name="yearwitch"/>
                                       <label htmlFor="monthly">Monthly</label>
                                       </div>
                                       <div className="switchfieldinner">
                                       <input type="radio" id="quaterly" name="yearwitch"/>
                                       <label htmlFor="quaterly">Quaterly</label>
                                       </div>
                                       <div className="switchfieldinner">
                                       <input type="radio" id="yearly" name="yearwitch"/>
                                       <label htmlFor="yearly">Yearly</label>
                                       </div>
                                       </div>
                                 </div>
                                 <img src="/front/images/graph.jpg" className="w-100"></img>
                           </div>
                        </div>
                     </div>


                     <div className="row">
                        <div className="col-md-12">
                           <div className="box mb-4">
                              <div className="table_filter mb-2 d-flex align-items-center justify-content-between commonform">
                                 <div className="tblsearch"><input type="text" className="form-control" placeholder="Search" fdprocessedid="61naqt"/></div>
                                 <div className="tblrightfilters d-flex align-items-center">
                                    <select className="form-control" fdprocessedid="57d4zre">
                                       <option>All</option>
                                       <option>Jobseeker</option>
                                       <option>Recruiter</option>
                                    </select>
                                    <select className="form-control ml-3" fdprocessedid="q0h1yu">
                                       <option>Date</option>
                                       <option>Monthly</option>
                                       <option>Quaterly</option>
                                       <option>Yearly</option>
                                    </select>
                                 </div>
                              </div>
                              <div className="table-responsive">
                                 <table className="table admintbl">
                                    <thead>
                                       <tr>
                                          <th>User Name</th>
                                          <th>Email</th>
                                          <th>User Type</th>
                                          <th>Date</th>
                                          <th>Mode of Expens</th>
                                          <th>Number of coins Expens</th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       <tr>
                                          <td><a>Daniel Marten</a></td>
                                          <td>daniel@yopmail.com</td>
                                          <td>Recruiter</td>
                                          <td>22 November 2022</td>
                                          <td>Reward</td>
                                          <td>3453</td>
                                       </tr>
                                       <tr>
                                          <td><a>Daniel Marten</a></td>
                                          <td>daniel@yopmail.com</td>
                                          <td>Recruiter</td>
                                          <td>22 November 2022</td>
                                          <td>Bonuses</td>
                                          <td>3453</td>
                                       </tr>
                                       <tr>
                                          <td><a>Daniel Marten</a></td>
                                          <td>daniel@yopmail.com</td>
                                          <td>Recruiter</td>
                                          <td>22 November 2022</td>
                                          <td>Compensation</td>
                                          <td>3453</td>
                                       </tr>
                                       <tr>
                                          <td><a>Daniel Marten</a></td>
                                          <td>daniel@yopmail.com</td>
                                          <td>Recruiter</td>
                                          <td>22 November 2022</td>
                                          <td>Reward</td>
                                          <td>3453</td>
                                       </tr>
                                       <tr>
                                          <td><a>Daniel Marten</a></td>
                                          <td>daniel@yopmail.com</td>
                                          <td>Recruiter</td>
                                          <td>22 November 2022</td>
                                          <td>Reward</td>
                                          <td>3453</td>
                                       </tr>
                                       <tr>
                                          <td><a>Daniel Marten</a></td>
                                          <td>daniel@yopmail.com</td>
                                          <td>Recruiter</td>
                                          <td>22 November 2022</td>
                                          <td>Compensation</td>
                                          <td>3453</td>
                                       </tr>
                                       <tr>
                                          <td><a>Daniel Marten</a></td>
                                          <td>daniel@yopmail.com</td>
                                          <td>Recruiter</td>
                                          <td>22 November 2022</td>
                                          <td>Reward</td>
                                          <td>3453</td>
                                       </tr>
                                       
                                    </tbody>
                                 </table>
                                 <div className="card-box">
                                    <nav aria-label="Page navigation">
                                       <ul className="pagination mb-0 justify-content-center">
                                          <li className="page-item"><a className="page-link" href="#"><i className="fa fa-angle-left" aria-hidden="true"></i></a></li>
                                          <li className="page-item active"><a className="page-link" href="#">1</a></li>
                                          <li className="page-item"><a className="page-link" href="#">2</a></li>
                                          <li className="page-item"><a className="page-link" href="#"><i className="fa fa-angle-right" aria-hidden="true"></i></a></li>
                                       </ul>
                                    </nav>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>


                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
}
export default Expenses;