// BarChart.js
import React from 'react';
import { Bar } from 'react-chartjs-2';
// import {CategoryScale} from 'chart.js'; 
import Chart from 'chart.js/auto';

const BarChart = ({ data, options }) => {



    

    const fixedBarWidth = 30;

  const barDatasetOptions = {
    barThickness: fixedBarWidth,
    maxBarThickness: fixedBarWidth,
    minBarLength: 2,
  };


  const datasets = data.datasets.map((dataset) => ({
    ...dataset,
    ...barDatasetOptions,
  }));

  const modifiedData = {
    ...data,
    datasets,
  };


//   const modifiedOptions = {
//     ...options,
//     scales: {
//       x: {
//         ...options.scales.x,
//         display: false,
//       },
//       y: {
//         ...options.scales.y,
//         display: false,
//       },
//     },
//   };

  return (
    <div>
        {/* {console.log(data)} */}
        {/* {console.log(options)} */}
        
        <Bar data={modifiedData} options={options} />
    </div>
  );
};

export default BarChart;
