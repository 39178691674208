import React, { useState ,useEffect , Component} from 'react';

import ProfileSidebar from './profile_sidebar';
import ProfileBanner from './profile_header';
import PasswordResetComp from './PasswordResetComp';
//import ProfileBanner from './profile_header';

const UserPasswordSetting = () => {
    
   return ( 
         <>
        {/* <ToastContainer />*/}
         <div className="dashboarwrap">
            <ProfileBanner />
            <div className="jobbtmsec py-5 admininfo">
               <div className="container">
               
                  <div className="row">
                     <div className="col-md-3 profilesidebarwrap">
                        <ProfileSidebar />
                     </div>
                     <div className="col-md-9">
                         <PasswordResetComp usertype={'admin'}  />
                     </div>

                  </div>
               </div>
            </div>

         </div>
         </>
      );
   }
   export default UserPasswordSetting;