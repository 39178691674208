import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import StarRating from '../../expocomp/StarRatingComp';
import ShowSoacialMedia from '../ShowSocialMediaComp'
// jobseeker profile model data
const JobDesModalcomp = (dataattr) => {
    const [tabSctionValue, setTabSctionValue] = useState('jobdetail')
    const staffViewDetailsDataArr = dataattr.data;
    const recruiter_data = dataattr.data.recruiterdata;
    const reccomp = dataattr.data.reccomp;
    const directersdata = dataattr.data.directersdata;
    const currentScreen = dataattr.currentScreen;

    const [selectedOptionsEductions , setselectedOptionsEductions] = React.useState(staffViewDetailsDataArr.required_education ? JSON.parse(staffViewDetailsDataArr.required_education) : []);
    const [selectedOptionsQualification , setselectedOptionsQualification] = React.useState(staffViewDetailsDataArr.extra_qualification ? JSON.parse(staffViewDetailsDataArr.extra_qualification) : []);

    console.log(selectedOptionsEductions)
    /*--------------modal const---------------*/
    const Availability = (data) => {
        let obj = (data.abdata) ? data.abdata : [];
        return (
            obj.length ?
                <>
                    {
                        obj.map((item, indxe) => (
                            <>
                                <div key={'availibility_' + indxe} className="availability-sec d-flex align-items-center  mb-2">
                                    <p>{item.name}</p>
                                    <strong>{item.from_value ? item.from_value : '--.--'}</strong>
                                    <div className="subheadpera">To</div>
                                    <strong>{item.to_value ? item.to_value : '--.--'}</strong>
                                </div>
                            </>
                        ))
                    }

                </>
                : <></>
        )
    }
    return (
        <>

            {
                (currentScreen == 'active_job' || currentScreen == 'completed_job') && <nav className="jobseekertabs mt-0">
                    <div className="nav nav-tabs " id="nav-tab" role="tablist">
                        <button onClick={() => setTabSctionValue('jobdetail')} className={`${tabSctionValue == 'jobdetail' ? 'active' : ''} nav-link`} id="jobdetail"><i className="fa fa-briefcase mr-1" aria-hidden="true"></i> Offers details</button>
                        <button onClick={() => setTabSctionValue('about_recruiter')} className={`${tabSctionValue == 'about_recruiter' ? 'active' : ''} nav-link`} id="about_recruiter"><i className="fa fa-user-o mr-1" aria-hidden="true"></i>
                            About {(recruiter_data.user_type_role == 'rec_owners') ? 'comapny' : recruiter_data.user_type_role == 'rec_indivisual' ? 'recruiter ' : 'comapny reprasentative'}
                        </button>
                    </div>
                </nav>
            }

            {
                tabSctionValue == 'jobdetail' &&
                <>
                    <div className="d-flex flex-wrap justify-content-between">
                        <h3 className='text-capitalize'>{staffViewDetailsDataArr.job_title_text}</h3>
                        <h3 className="job_price orange_clr">
                            {
                                staffViewDetailsDataArr.fixed_salary ?
                                    <>
                                        <strong>{staffViewDetailsDataArr.fixed_salary ? '$' + staffViewDetailsDataArr.fixed_salary : '----'}</strong>
                                    </>
                                    :
                                    <>
                                        <strong>{staffViewDetailsDataArr.salary_minimum ? '$' + staffViewDetailsDataArr.salary_minimum + '/Hour' : '--'}</strong>
                                        <strong className="subheadpera">To</strong>
                                        <strong>{staffViewDetailsDataArr.salary_maximum ? '$' + staffViewDetailsDataArr.salary_maximum + '/Hour' : '--'}</strong>
                                    </>
                            }

                        </h3>
                    </div>
                    <div><span className="tags">{staffViewDetailsDataArr.job_type_text}</span></div>
                    <div className="d-flex flex-wrap mt-2 joblistbox">
                        <div className="icontxt"><img src="/front/images/calender.png"></img> Offer date: <span>{staffViewDetailsDataArr.created_date_format ?? '.....'}</span></div>
                        <div className="icontxt"><img src="/front/images/clock.png"></img> Offer expire date: <span>{staffViewDetailsDataArr.expired_date_format ?? '.....'}</span></div>
                        <div className="icontxt"><img src="/front/images/location.png"></img> Work location: <span>{staffViewDetailsDataArr.job_location_text ? staffViewDetailsDataArr.job_location_text : '................'}</span></div>
                        <div className="icontxt"><img src="/front/images/bag.png"></img> Job type: <span>{staffViewDetailsDataArr.job_type_text}</span></div>
                        <div className="icontxt"><img src="/front/images/star.png"></img> Experience: <span>{staffViewDetailsDataArr.total_experience_month + '.' + staffViewDetailsDataArr.total_experience_year + ' Years'}</span></div>
                        <div className="icontxt "><img src="/front/images/fa_route.png"></img> Range from location: <span>{staffViewDetailsDataArr.range_from_location}{staffViewDetailsDataArr.range_from_location > 0 ? 'KM' : ''}</span></div>
                        {/* <div className="icontxt"><img src="/front/images/user.svg"></img> Total staff loooking: <span>{staffViewDetailsDataArr.staff_number}</span></div> */}
                        <div className="icontxt"><img src="/front/images/dollar.png"></img> Salary Type: <span>{staffViewDetailsDataArr.fixed_salary ? 'Fixed' : 'Hourly'}</span></div>

                        {/* <div className="icontxt"><img src="/front/images/user.svg"></img> Job will be: <span>{staffViewDetailsDataArr.expire_date}</span></div> */}
                    </div>
                    <hr></hr>
                    <h5>Job description</h5>
                    <p>{staffViewDetailsDataArr.job_descriptions ? staffViewDetailsDataArr.job_descriptions : '..............'}</p>
                    <hr></hr>
                    <h5>Availability to work</h5>
                    <p>Jobseeker should available on below time.</p>
                    <Availability abdata={staffViewDetailsDataArr.availibility} ></Availability>
                    <hr></hr>
                    <h5>Paying extra for</h5>
                    <ul>
                        <li className='text-capitalize'>Public holidays : {staffViewDetailsDataArr.are_you_paying_extra_in_holiday}  </li>
                        <li className='text-capitalize'>Weekend : {staffViewDetailsDataArr.are_you_paying_extra_in_weekend} </li>
                        <li className='text-capitalize'>Shift loading : {staffViewDetailsDataArr.are_you_paying_extra_in_overtime} </li>
                    </ul>
                    <hr></hr>
                    <h5>Required Education</h5>
                    <p>Jobseeker should have following degree and diplomas:</p>
                    <ul>
                        {selectedOptionsEductions.map(item => (
                            <li key={item.value}>{item.label}</li>
                        ))}
                    </ul>
                    
                    <hr></hr>
                    <h5>Extra qualification</h5>
                    <p>Jobseeker is having qualifications:</p>
                    <ul>
                    {selectedOptionsQualification.map(item => (
                        <li key={item.value}>{item.label}</li>
                    ))}
                    </ul>

                    <hr></hr>
                    <h5>Language known</h5>
                    <p>Jobseeker should be fluent in following languages:</p>
                    <ul> <li>{staffViewDetailsDataArr.language_text}</li></ul>
                </>
            }



            {/* && currentScreen == 'completed_job' */}

            {(tabSctionValue == 'about_recruiter') &&


                <>
                    {/* {recruiter_data.user_type_role} */}
                    <h5>About {(recruiter_data.user_type_role == 'rec_owners') ? 'comapny' : recruiter_data.user_type_role == 'rec_indivisual' ? 'recruiter ' : 'comapny reprasentative'}</h5>


                    <div className="aboutrecuruter d-flex">
                        <div className="recruterimg">
                            <img src={'https://squadgoo.com.au/api.squadgoo.com.au/' + staffViewDetailsDataArr.recruiterdata.profilepic}></img>
                        </div>
                        {
                            <>
                                <div className="recruterabout">
                                    <h5 className='text-capitalize'>
                                        {
                                            (recruiter_data.user_type_role == 'rec_owners') ?
                                                <>{reccomp.name ? reccomp.name : '........'}</>
                                                :
                                                <>{staffViewDetailsDataArr.recruiterdata.first_name + ' ' + staffViewDetailsDataArr.recruiterdata.last_name}</>
                                        }
                                    </h5>


                                    {currentScreen == 'completed_job' && <>
                                        <div className="d-flex recruiterdetailpop">
                                            <p>
                                                <i className="fa fa-envelope-o" aria-hidden="true"></i>
                                                {
                                                    staffViewDetailsDataArr.recruiterdata.email ?
                                                        <><a className="linktxt" href={`mailto:${staffViewDetailsDataArr.recruiterdata.email}`}>{staffViewDetailsDataArr.recruiterdata.email}</a> </>
                                                        : <></>
                                                }
                                            </p>
                                            <p>
                                                <i className="fa fa-phone" aria-hidden="true"></i>
                                                {
                                                    staffViewDetailsDataArr.recruiterdata.contact_number ?
                                                        <>
                                                            <a className="linktxt" href={`tel:${staffViewDetailsDataArr.recruiterdata.contact_number}`}>
                                                                {staffViewDetailsDataArr.recruiterdata.contact_country_code ? "+" + staffViewDetailsDataArr.recruiterdata.contact_country_code : ''} {staffViewDetailsDataArr.recruiterdata.contact_number}
                                                            </a>
                                                        </>
                                                        : <></>
                                                }
                                            </p>
                                            <p><i className="fa fa-industry" aria-hidden="true"></i>
                                                {staffViewDetailsDataArr.reccomp.industry ? staffViewDetailsDataArr.reccomp.industry_text : '-------------'}
                                            </p>
                                        </div>
                                        <div className="d-flex recruiterdetailpop">
                                            <p>
                                                <Link to={staffViewDetailsDataArr.reccomp.website} target="_blank" className="linktxt">
                                                    <i className="fa fa-globe" aria-hidden="true"></i>
                                                    {staffViewDetailsDataArr.reccomp.website ? staffViewDetailsDataArr.reccomp.website : '------------------'}
                                                </Link>
                                            </p>
                                            <p className='text-capitalize'><i className="fa fa-map-marker" aria-hidden="true"></i>
                                                {`
                                                        ${staffViewDetailsDataArr.reccomp.address_street_name ? staffViewDetailsDataArr.reccomp.address_street_name : '------'} 
                                                        ${staffViewDetailsDataArr.reccomp.address_suburbs ? ` , ${staffViewDetailsDataArr.reccomp.address_suburbs}` : '------'}
                                                    `}
                                            </p>
                                            <div>
                                                {staffViewDetailsDataArr.reccomp.profile_summary}
                                            </div>
                                        </div>
                                    </>}

                                    <ShowSoacialMedia data={staffViewDetailsDataArr.recruiterdata} />
                                </div>
                            </>
                        }
                    </div>
                    <hr></hr>
                    <p>
                        {staffViewDetailsDataArr.recruiterdata.profile_summary}
                    </p>


                    {recruiter_data.user_type_role != 'rec_owners' && <>
                        <hr></hr>
                        <h5 className=''>Address details</h5>
                        <div className="d-flex flex-wrap mt-2 joblistbox">
                            <div className="icontxt"> Building No.: <span>{recruiter_data.address_building_no}</span></div>
                            <div className="icontxt"> Street: <span>{recruiter_data.address_street_name}</span></div>
                            <div className="icontxt"> Suburbs: <span>{recruiter_data.address_suburbs}</span></div>
                            <div className="icontxt"> Unit No.: <span>{recruiter_data.address_unit_no}</span></div>
                            <div className="icontxt"> State: <span>{recruiter_data.address_user_state}</span></div>
                        </div>
                    </>}

                    {currentScreen == 'completed_job' && recruiter_data.user_type_role == 'rec_owners' && <>
                        <hr></hr>
                        <h5 className=''>Comapny details</h5>
                        <div className="d-flex flex-wrap mt-2 joblistbox">
                            <div className="icontxt"> Comapny Name: <span>{reccomp.name ? reccomp.name : '........'}</span></div>
                            <div className="icontxt"> Company website: <span>{reccomp.website ? reccomp.website : '........'}  </span></div>
                            <div className="icontxt"> Industry: <span>{reccomp.industry_text ? reccomp.industry_text : '........'} </span></div>
                        </div>

                        <hr></hr>

                        <h5 className=''>Comapny address</h5>
                        <div className="d-flex flex-wrap mt-2 joblistbox">
                            <div className="icontxt"> Building No.: <span>{reccomp.address_building_no}</span></div>
                            <div className="icontxt"> Street: <span>{reccomp.address_street_name}</span></div>
                            <div className="icontxt"> Suburbs: <span>{reccomp.address_suburbs}</span></div>
                            <div className="icontxt"> Unit No.: <span>{reccomp.address_unit_no}</span></div>
                            <div className="icontxt"> State: <span>{reccomp.address_state}</span></div>
                        </div>

                    </>}



                </>
            }
        </>
    );
}
export default JobDesModalcomp;