import React from 'react';
import ProfileBanner from '../profile_header';
import ProfileSidebar from '../profile_sidebar';
import UserAddressComp from '../UserAddressComp';

import CheckValidationComp  from '../CheckValidationComp';
const Address = () => {
 
   return ( 
         <>
          
         {/* <ToastContainer />*/}
         <div className="dashboarwrap">
            <ProfileBanner />
            <div className="jobbtmsec py-5 admininfo">
               <div className="container">
               
                  <div className="row">
                     <div className="col-md-3 profilesidebarwrap">
                        <ProfileSidebar />
                     </div>
                     <div className="col-md-9">
                        <UserAddressComp />
                     </div>

                  </div>
               </div>
            </div>

         </div>
         </>
      );
   }
   export default Address;