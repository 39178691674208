import React, { useState, useEffect, Component } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import LiveChatWindow from './LiveChatWindow';
import { Form, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import DateTimeComp from './../expocomp/DateTimeComp';
import CheckValidationComp from './CheckValidationComp';
import CustomDatePicker from '../expocomp/DatePickerComponent';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const Supportnew = () => {

   //https://squadgoo.com.au/api.squadgoo.com.au/getfaq
   const navigate = useNavigate();
   const useriddata = JSON.parse(localStorage.getItem("squadGologinSession"));
   const [screenLoader, setScreenLoader] = useState(false);
   const [searchString, setSearchString] = useState(false);
   const [isVelidate, setisVelidate] = useState(false);
   const [isModalShow, setIsModalShow] = useState(false);
   const [isMordeContactDetails, setIsMordeContactDetails] = useState(false);
   const [faqs, setFAQs] = useState([]);
   const [liveChatModule, setLiveChatModule] = useState(false);
   const [searchresultScreen, setSearchresultScreen] = useState(false);
   const [searchresultString, setSearchresultString] = useState('');
   const [validated, setValidated] = useState(false);
   const [idDataLoading, setidDataLoading] = useState(false);



   const get_faq = (apiurl, type = 'onload') => {
      setScreenLoader(true);
      (async () => {
         try {
            const resData = await axios.get(apiurl);
            if (resData.data.status == 1) {
               setScreenLoader(false);
               setFAQs(resData.data.data);
            }
         } catch (error) { console.log(error); }
      })();
   }





   useEffect(() => {
      get_faq(`https://squadgoo.com.au/api.squadgoo.com.au/getfaq?search=${searchresultString}`);
   }, [searchresultString]);

   const searchSteingData = () => {
      setisVelidate(true);
      if (searchString) {
         setisVelidate(false);
         setSearchresultScreen(true);
         setSearchresultString(searchString);
      }
   }



   const [isCallreqModalShow, setisCallreqModalShow] = useState(false)
   const requestForCall = () => {
      setisCallreqModalShow(true);
   }



   const handleSubmit = async (event) => {
      event.preventDefault();
      const form = event.currentTarget;

      if (!form.checkValidity()) {
         event.stopPropagation();
         setValidated(true);
         return;
      }

      setidDataLoading(true);
      try {
         const formData = new FormData(form);
         const response = await axios.post(`https://squadgoo.com.au/api.squadgoo.com.au/request_for_call/${useriddata.login_token}`, formData);
         console.log(response);
         if (response.data.status == 1) {
            toast.success(response.data.message);
         }
         setidDataLoading(false);
         setisCallreqModalShow(false);
         form.reset();
      } catch (error) { console.error('Error uploading file:', error); }
      setValidated(true);
   };

   //setIsModalShow


   const CallBackForm = () => {
      const [selectedDate, setSelectedDate] = useState(null);

      // useEffect(() => {
      //    const tomorrow = new Date();
      //    tomorrow.setDate(tomorrow.getDate() + 1);
      //    setSelectedDate(tomorrow);
      //  }, []);

      const isWeekday = (date) => {
         const day = date.getDay();
         return day !== 0 && day !== 6; // Saturday and Sunday are disabled
      };

      const isTimeInRange = (date) => {
         const hour = date.getHours();
         return hour >= 10 && hour < 17; // 10am to 5pm
      };

      const handleChange = (date) => {
         if (isWeekday(date) && isTimeInRange(date)) {
            setSelectedDate(date);
            handleDone();
         } else {
            setSelectedDate(null);
         }
      };

      const handleDone = () => {
         // Handle callback or any further action here
         console.log("Callback scheduled for:", selectedDate);
      };

      const filterPastAndToday = (date) => {
         const today = new Date();
         today.setHours(0, 0, 0, 0); // Set time to 00:00:00
         return date > today; // Only allow future dates
      };

      return (
         <>
            <DatePicker
               selected={selectedDate}
               onChange={handleChange}
               showTimeSelect
               className='form-control'
               timeFormat="HH:mm"
               timeIntervals={60}
               dateFormat="MMMM d, yyyy h:mm aa"
               id="schedule_time" 
               name="schedule_date_time"
               // filterDate={isWeekday}
               filterDate={(date) => isWeekday(date) && filterPastAndToday(date)}
               minTime={new Date(0, 0, 0, 10, 0)} // 10am
               maxTime={new Date(0, 0, 0, 17, 0)} // 5pm
               placeholderText="Select a weekday between 10am to 5pm"
            />

            <img src="./front/images/calendar.png" alt="calendar" className="calendar-icon" />
         </>
      );
   };

   return (
      <>

         <div className="dashboarwrap">
            <div className="adminwrap">
               <div className="helpbanner" id="SearchFormSection">
                  <div className="container">
                     <div className="row align-items-center">
                        <div className="col-md-5">
                           <h2>Find a solution fast.</h2>
                           <h4>how can we help you?</h4>
                           <div className="searchform d-flex">

                              <input type="text" className={`form-control ${isVelidate ? 'bordercss' : 'validated'}`} onKeyUp={(e) => { setSearchString(e.target.value); setisVelidate(e.target.value.length ? false : true) }} placeholder="Search by keywords" />
                              <input onClick={searchSteingData} type="submit" value="Search" />
                           </div>
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-6">
                           <img src="/front/images/suppoertbanner.png" className="img-fluid"></img>
                        </div>
                     </div>
                  </div>
               </div>

               <div className="faq">
                  <div className="container">
                     {
                        screenLoader ?
                           <>
                              <div className='loader_container'>
                                 <p className='text-center'><img className='width-100 loader_image' src={'/front/images/loader.gif'} /></p>
                              </div>
                           </>
                           :
                           <>
                              {
                                 !searchresultScreen ?
                                    <>
                                       <div className="mx-auto text-center">
                                          <h2 className="mb-5">Frequently Asked Questions</h2>
                                       </div>
                                       <div className="row">
                                          {
                                             faqs.map((item, index) => (
                                                <>
                                                   <div className="col-lg-6 mt-2" key={item.id}>
                                                      <div className="accordion" id={`accordion_faq_${item.id}`} >
                                                         <div className="accordion-item wow fadeIn" data-wow-delay={`0.${index + 1}s`}>
                                                            <h2 className="accordion-header" id={`heading_${item.id}`}>
                                                               <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapseOne${item.id}`} aria-expanded="false" aria-controls={`collapseOne${item.id}`} >
                                                                  {item.question}
                                                               </button>
                                                            </h2>
                                                            <div id={`collapseOne${item.id}`} className="accordion-collapse collapse" aria-labelledby={`heading_${item.id}`} data-bs-parent={`#accordion_faq_${item.id}`} >
                                                               <div className="accordion-body">
                                                                  {/* {item.answer} */}
                                                                  <div dangerouslySetInnerHTML={{ __html: item.answer }} />
                                                               </div>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </>
                                             ))
                                          }
                                       </div>
                                    </>
                                    :
                                    <>
                                       <div className="box mb-4">
                                          <div className="box-ttl mb-3 d-flex align-items-center justify-content-between">
                                             <h3>
                                                <span className="back_to_hist cursor_pointer" onClick={() => { setSearchresultScreen(false); setSearchresultString('') }}>
                                                   <i className="fa fa-chevron-left" aria-hidden="true"></i>
                                                </span>
                                                Search Results
                                             </h3>
                                             {
                                                faqs.length ?
                                                   <>
                                                      <div className="resultcount mr-0">
                                                         <p><i>{faqs.length} results for "{searchresultString}"</i></p>
                                                      </div>
                                                   </>
                                                   :
                                                   <></>
                                             }
                                          </div>
                                          {
                                             faqs.length ?
                                                faqs.map((item, index) => (
                                                   <>
                                                      <div className="reportblock" key={item.id} >
                                                         <h4>{item.question}</h4>
                                                         <p className="mb-0">
                                                            <div className='' dangerouslySetInnerHTML={{ __html: item.answer }} />

                                                            {/* <a href="#">Read More</a> */}
                                                         </p>
                                                      </div>
                                                   </>
                                                ))
                                                :
                                                <>
                                                   <div className="resultcount mr-0">
                                                      <p>There are no results found for "{searchresultString}"</p>
                                                   </div>
                                                </>
                                          }
                                       </div>
                                    </>
                              }
                           </>
                     }

                  </div>
               </div>
               <div className="helpcta">
                  <div className="container">
                     <div className="row">
                        <div className="col-md-12">
                           <div className="helpctainner d-flex justify-content-between align-items-center">
                              <h2>Did you find the answer to your search?</h2>
                              <div className="d-flex">
                                 {/* <a href="#" className="batn batn-orange mr-2">Yes</a> */}
                                 <Link onClick={() => { setIsModalShow(!isModalShow); setIsMordeContactDetails(false) }} className="batn batn-orange mr-2" >Yes</Link>
                                 <Link onClick={() => { setIsModalShow(false); setIsMordeContactDetails(!isMordeContactDetails) }} className="batn batn-orange" >No</Link>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               {
                  isModalShow && <>
                     <div className="connecttype">
                        <div className="container">
                           <div className="mx-auto text-center">
                              <h2 className="mb-5" style={{ maxWidth: '100%' }}>Thanks for your feedback</h2>
                              {/* <a href="#SearchFormSection" className='text-orange' style={{ fontSize: "18px"}}>Search more...</a> */}
                           </div>
                        </div>
                     </div>
                  </>
               }
               {isMordeContactDetails && <div className="connecttype">
                  <div className="container">
                     <div className="mx-auto text-center">
                        <h2 className="mb-5">How do you want to connect with us?</h2>
                     </div>

                     <div className="row">
                        <div className="col-lg-4 col-md-6 col-12 mb-4 mb-lg-0">
                           <div className="custom-block bg-white shadow-lg">
                              <Link onClick={() => setLiveChatModule(!liveChatModule)} >
                                 {/* <Link onClick={() => setLiveChatModuleFun()} > */}
                                 <div className="d-flex">
                                    <div>
                                       <img src="/front/images/live-chat.png" className="custom-block-image img-fluid" alt="" />
                                       <h4 className="mb-2">Livechat</h4>
                                       <p className="mb-0">Get instant assistance and expert guidance at your fingertips—chat with us live!</p>
                                    </div>
                                 </div>
                              </Link>
                           </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-12 mb-4 mb-lg-0">
                           <div className="custom-block bg-white shadow-lg">
                              <Link onClick={requestForCall} >
                                 <div className="d-flex">
                                    <div>
                                       <img src="/front/images/phone-call.png" className="custom-block-image img-fluid" alt="" />
                                       <h4 className="mb-2">Request a Callback</h4>
                                       <p className="mb-0">Request a callback and connect with us at your convenience!</p>
                                    </div>
                                 </div>
                              </Link>
                           </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-12 mb-4 mb-lg-0">
                           <div className="custom-block bg-white shadow-lg">
                              <a href="mailto: abc@example.com">
                                 <div className="d-flex">
                                    <div>
                                       <img src="/front/images/mail.png" className="custom-block-image img-fluid" alt="" />
                                       <h4 className="mb-2">Email</h4>
                                       <p className="mb-0">Prefer to communicate via email? Request a callback, and we’ll respond to your inquiry promptly!</p>
                                    </div>
                                 </div>

                              </a>
                           </div>
                        </div>

                     </div>
                  </div>
               </div>}

            </div>
         </div>

         {liveChatModule && <LiveChatWindow livechataction={liveChatModule} />}
         <Modal className="modal fade" show={isCallreqModalShow} onHide={() => setisCallreqModalShow(false)}>
            {
               <>
                  <div className="modal-dialog1">
                     <div className="modal-content">
                        <div className="modal-body">
                           <button type="button" className="btn-close" onClick={() => setisCallreqModalShow(false)} ></button>

                           <Form noValidate validated={validated} onSubmit={handleSubmit} className="scheduleForm">
                              <div className="row">
                                 <div className="col-md-12 form-group mb-2">
                                    <label className="form-label" for="reason_for_call">Reason for call <span className="starr">*</span></label>
                                    <textarea required={true} className="form-control" id="reason_for_call" name="reason_for_call" maxlength="500" rows="5" placeholder="Enter description"></textarea>
                                 </div>
                                 <div className="col-md-12 form-group mb-2">
                                    <label className="form-label" for="schedule_time">Schedule time <span className="starr">*</span></label>
                                    {/* <DateTimeComp startFrom={2} day={10} /> */}
                                    <CallBackForm />
                                    {/* <input type='date' /> */}
                                    {/* <CustomDatePicker /> */}
                                 </div>
                                 <div className="col-md-12 form-group mt-3">
                                    <button disabled={idDataLoading} type="submit" className="batn batn-orange w-100">Send</button>
                                 </div>
                              </div>
                           </Form>
                        </div>
                     </div>
                  </div>
               </>
            }
         </Modal>


         {/* <Modal className="modal fade ask-jobdeclinedmodle" show={isModalShow} onHide={() => setIsModalShow(false)} >
            <div className="modal-dialog1">
               <div className="modal-content">
                  <div className="modal-body">
                     <button type="button" className="btn-close" onClick={() => setIsModalShow(false)} ></button>

                     <div style={{ textAlign: "center", fontSize: '55px' }}  >
                        <i class="fa fa-thumbs-up text-success"></i>
                     </div>
                     <h5 className="text-center mb-2">thank you for your answer</h5>
                     <div className="btnsgroup text-center">
                        <Link onClick={() => setIsModalShow(false)} className="batn batn-orange batn-border">Cancel</Link>
                     </div>
                  </div>
               </div>
            </div>
         </Modal> */}

      </>
   );
}
export default Supportnew;