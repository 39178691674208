import SuperAdminSidebar from './superadmin_sidebar'
const GrossReport = () => {
   return (
      <>
         <div className="dashboarwrap">

         <div className="pagebanner">
               <div className="container">
                  <div className="row">
                     <div className="col-md-12">
                        <h2>Gross Report</h2>
                     </div>
                  </div>
               </div>
            </div>

            <div className="adminwrap py-4">
               <div className="container">
                  <div className="row">
                     <div className="col-md-3 adminsidebar">
                        <SuperAdminSidebar></SuperAdminSidebar>
                     </div>
                     <div className="col-md-9">
                        
                     <div className="row">
                        <div className="col-md-4 mb-4">
                           <div className="box ststicsbox d-flex justify-content-between coinbox Totalearning">
                              <div className="ststicsboxleft">
                                 <p>Total Earning </p>
                                 <h2>54904 <span>Coins</span></h2>
                              </div>
                              <div className="ststicsboxright"><img src="/front/images/ion.png"/></div>
                           </div>
                        </div>
                        <div className="col-md-4 mb-4">
                           <div className="box ststicsbox d-flex justify-content-between coinbox">
                              <div className="ststicsboxleft">
                                 <p>Total Expenses</p>
                                 <h2>30495 <span>Coins</span></h2>
                              </div>
                              <div className="ststicsboxright"><img src="/front/images/jobcin.png"/></div>
                           </div>
                        </div>
                        <div className="col-md-4 mb-4">
                           <div className="box ststicsbox d-flex justify-content-between coinbox">
                              <div className="ststicsboxleft">
                                 <p>Total Profit</p>
                                 <h2>24,409 <span>Coins</span></h2>
                              </div>
                              <div className="ststicsboxright"><img src="/front/images/recritercoin.png"/></div>
                           </div>
                        </div>
                     </div>

                     <div className="row">
                        <div className="col-md-12">
                           <div className="box mb-4">
                                 <div className="box-ttl mb-3 d-flex align-items-center justify-content-between">    
                                       <h3>Gross Report</h3>
                                       <div>
                                       <div className="switch-field mr-0">
                                       <div className="switchfieldinner">
                                       <input type="radio" id="Earning" name="grossreport"/>
                                       <label htmlFor="Earning">Earning</label>
                                       </div>
                                       <div className="switchfieldinner">
                                       <input type="radio" id="Expenses" name="grossreport"/>
                                       <label htmlFor="Expenses">Expenses</label>
                                       </div>
                                       <div className="switchfieldinner">
                                       <input type="radio" id="Profit" name="grossreport"/>
                                       <label htmlFor="Profit">Profit</label>
                                       </div>
                                       </div>

                                       <div className="switch-field mr-0 ml-3">
                                       <div className="switchfieldinner">
                                       <input type="radio" id="monthly" name="yearwitch"/>
                                       <label htmlFor="monthly">Monthly</label>
                                       </div>
                                       <div className="switchfieldinner">
                                       <input type="radio" id="quaterly" name="yearwitch"/>
                                       <label htmlFor="quaterly">Quaterly</label>
                                       </div>
                                       <div className="switchfieldinner">
                                       <input type="radio" id="yearly" name="yearwitch"/>
                                       <label htmlFor="yearly">Yearly</label>
                                       </div>
                                       </div>
                                       </div>
                                 </div>
                                 <img src="/front/images/graph.jpg" className="w-100"></img>
                           </div>
                        </div>
                     </div>


                   

                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
}
export default GrossReport;