import React from 'react';
import { Link ,useNavigate ,useLocation } from 'react-router-dom';

const ShowSoacialMedia = (prop) => {

    const facebook =  prop.data.facebook ?  prop.data.facebook : '';
    const twitter =  prop.data.twitter ?  prop.data.twitter : '';
    const linkedin =  prop.data.linkedin ?  prop.data.linkedin : '';
    const instagram =  prop.data.instagram ?  prop.data.instagram : '';
    return (
        <>
        
            <p className={typeof(prop.nopadding) == 'undefined' ? 'd-flex haedersocial d-flex' : 'haedersocial mb-0 mt-1'} >

                {facebook && <Link to={`${facebook}`} className="mr-2" target="_blank"><i className="fa fa-facebook-square" aria-hidden="true"></i></Link>}
                {twitter && <Link to={`${twitter}`} className="mr-2" target="_blank"><i className="fa fa-twitter-square" aria-hidden="true"></i></Link>}
                {linkedin && <Link to={`${linkedin}`} className="mr-2" target="_blank"><i className="fa fa-linkedin-square" aria-hidden="true"></i></Link>}
                {instagram && <Link to={`${instagram}`} className="mr-2" target="_blank"><i className="fa fa-instagram" aria-hidden="true"></i></Link>}
            </p>
        </>
    );
}
export default ShowSoacialMedia;