import React, { useState, useEffect, Component } from 'react';
import axios from 'axios';
import { isVisible } from '@testing-library/user-event/dist/utils';
import SuperAdminSidebar from './superadmin_sidebar'
import { Link } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';

const Cms_faq = () => {

    const useriddata = JSON.parse(localStorage.getItem("squadGologinSession"));
    const [screenLoader, setScreenLoader] = useState(false);

    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');
    const [faqFor, setFaqFor] = useState(1);


    const [addFaqAction, setAddFaqAction] = useState(false);
    const [addFaqEditID, setAddFaqEditID] = useState(0);
    const [faqs, setFAQs] = useState([]);

    const [faqType, setFaqType] = useState([
        { key: 3, name: 'Both', value: 3 },
        { key: 1, name: 'Jobseeker', value: 1 },
        { key: 2, name: 'Recruiter', value: 2 },
    ]);


    const get_faq = (apiurl, type = 'onload') => {
        (async () => {
            try {
                const resData = await axios.get(apiurl);
                if (resData.data.status == 1) {
                    setScreenLoader(false);
                    if (type == 'editdata') {
                        console.log(resData.data.data);
                        setQuestion(resData.data.data.question); setAnswer(resData.data.data.answer); setAddFaqAction(true);
                        setFaqFor(resData.data.data.faq_for)
                        // toast.success(resData.data.message);
                    } else {
                        setFAQs(resData.data.data);
                    }
                }
            } catch (error) { console.log(error); }
        })();
    }

    useEffect(() => {
        if (useriddata) {
            get_faq(`https://squadgoo.com.au/api.squadgoo.com.au/cms_faq_admin/${useriddata.login_token}`)
        }
        // Fetch the list of FAQs from your API and set it to the state
    }, []);

    const getFaqTypeOptionfilter = (faqtype) => {
        get_faq(`https://squadgoo.com.au/api.squadgoo.com.au/cms_faq_admin/${useriddata.login_token}?faqtype=${faqtype}`)
    }

    const handleEdit = (faqId) => {
        if (faqId) {
            setAddFaqEditID(faqId);
            get_faq(`https://squadgoo.com.au/api.squadgoo.com.au/cms_faq_admin/${useriddata.login_token}?editid=${faqId}`, 'editdata')
        }
    };

    const handleDelete = (faqId) => {
        // Implement delete functionality
    };


    /**-------------------**/


    const handleSubmit = (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append('question', question);
            formData.append('answer', answer);
            formData.append('faq_for', faqFor);
            formData.append('login_token', useriddata.login_token);
            formData.append('edit_id', addFaqEditID);
            //axios.defaults.headers.common['Authorization'] = `Bearer ${useriddata.login_token}`;
            axios.post('https://squadgoo.com.au/api.squadgoo.com.au/cms_faq_admin_add', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
                .then((response) => {
                    if (response.status == 200 && response.data.status == 1) {
                        setQuestion(''); setAnswer(''); setAddFaqAction(false);
                        toast.success(response.data.message);
                        setFAQs(response.data.data);
                    }
                })
                .catch((error) => { console.error(error); });
        } catch (error) { console.log(error); }
    };

    return (
        <>

            <div className="dashboarwrap">
                {
                    <>
                        <div className="pagebanner">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h2>FAQ</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                       {/* <ToastContainer />*/}
                        <div className="adminwrap py-4">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-3 adminsidebar">
                                        <SuperAdminSidebar></SuperAdminSidebar>
                                    </div>
                                    <div className="col-md-9">
                                        {/* ----------------- */}

                                        <div className="d-flex1 justify-content-end1 mb-3 border-bottom1 pb-2">
                                                {addFaqAction && <span className="back_to_hist1 cursor_pointer" onClick={() => { setAddFaqAction(false); setAddFaqEditID(0) }} ><i className="fa fa-chevron-left" aria-hidden="true"></i>Back to List</span>}
                                                {
                                                    !addFaqAction && 
                                                    <select  onChange={(e) => getFaqTypeOptionfilter(e.target.value)} className="cms_faq_type form-control1">
                                                        {
                                                            faqType.map((item, index) => (
                                                                <option  key={item.key} value={item.value}>{item.name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                }
                                                <Link className="orange_clr float-right" onClick={() => { setAddFaqAction(true); setAddFaqEditID(0); setQuestion(''); setAnswer(''); }} >
                                                    Add New FAQ
                                                </Link>
                                            </div>

                                        <div className="box mb-4">
                                            
                                            {
                                                addFaqAction ?
                                                    <>
                                                        <form onSubmit={handleSubmit}>
                                                            <div className="mb-3">
                                                                <label htmlFor="question" className="form-label">Faq Publish for</label>

                                                                <select onChange={(e) => setFaqFor(e.target.value)} required className='form-control' name='faq_for'>
                                                                    {
                                                                        faqType.map((item, index) => (
                                                                            <option selected={faqFor == item.value ? true : false} key={item.key} value={item.value}>{item.name}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                            </div>
                                                            <div className="mb-3">
                                                                <label htmlFor="question" className="form-label">Question</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="question"
                                                                    value={question}
                                                                    onChange={(e) => setQuestion(e.target.value)}
                                                                    required
                                                                />
                                                            </div>
                                                            <div className="mb-3">
                                                                <label htmlFor="answer" className="form-label">Answer</label>
                                                                <textarea
                                                                    className="form-control"
                                                                    id="answer"
                                                                    rows="4"
                                                                    value={answer}
                                                                    onChange={(e) => setAnswer(e.target.value)}
                                                                    required
                                                                ></textarea>
                                                            </div>
                                                            <button type="submit" className="batn batn-orange">{addFaqEditID ? 'Update' : 'Add'}</button>
                                                            <button className="batn batn-orange ml-2" type="button" onClick={() => { setAddFaqAction(false); setAddFaqEditID(0) }} >Cancel</button>
                                                        </form>
                                                    </>
                                                    :
                                                    <>

                                                        {
                                                            screenLoader ?
                                                                <>
                                                                    <div className='loader_container'>
                                                                        <p className='text-center'><img className='width-100 loader_image' src={'/front/images/loader.gif'} /></p>
                                                                    </div>
                                                                </>
                                                                :
                                                                <>
                                                                    {
                                                                        faqs.length ?

                                                                            faqs.map((faq) => (
                                                                                <>
                                                                                    <div className="reportblock" key={faq.id}>
                                                                                        <p className="reportdate d-flex"><img src="/front/images/calender.png" />{faq.created_date}</p>
                                                                                        <h4>
                                                                                            {faq.question}
                                                                                            <span className='ml-5'>({faq.faq_for == 1 ? 'Jobseeker' : 'Recruiter'})</span>
                                                                                        </h4>
                                                                                        <p>{faq.answer}</p>
                                                                                        {/* <button className='btn btn-info' onClick={() => handleEdit(faq.id)}>Edit</button>
                                                                                        <button className='btn btn-danger ml-2' onClick={() => handleDelete(faq.id)}>Delete</button> */}
                                                                                        <Link onClick={() => handleEdit(faq.id)}>Edit</Link>
                                                                                        <span className='ml-2 mr-2'>|</span>
                                                                                        <Link className='' onClick={() => handleDelete(faq.id)}>Delete</Link>
                                                                                    </div>
                                                                                    {faqs.length > 10 && <div className="text-center">
                                                                                        <a href="#" className="batn batn-orange mt-2">View All Requests</a>
                                                                                    </div>}
                                                                                </>
                                                                            ))

                                                                            :
                                                                            <>There is no data available</>
                                                                    }
                                                                </>
                                                        }
                                                    </>
                                            }

                                        </div>
                                        {/* ----------------- */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </>
    );
}
export default Cms_faq;