import React, { useEffect } from 'react';
import {  useNavigate } from 'react-router-dom';

const LiveChatWindow = (prop) => {
    const navigate = useNavigate();
    useEffect(() => {
        localStorage.clear(); 
        setTimeout(function () { navigate('/login'); }, 50);
     }, []);
};


export default LiveChatWindow;
