import React, { useState, useEffect, Component } from 'react';
import axios from 'axios';
import { isVisible } from '@testing-library/user-event/dist/utils';
import SuperAdminSidebar from './superadmin_sidebar'
const SubscriptionPackages = () => {
  return (
    <>
      <div className="dashboarwrap">
        {
          <>
          <div className="pagebanner">
               <div className="container">
                  <div className="row">
                     <div className="col-md-12">
                        <h2>Subscription Packages</h2>
                     </div>
                  </div>
               </div>
            </div>
            <div className="adminwrap py-4">
              <div className="container">
                <div className="row">
                  <div className="col-md-3 adminsidebar">
                    <SuperAdminSidebar></SuperAdminSidebar>
                  </div>
                  <div className="col-md-9">
                    {/* <div className="aadminhead mb-4" ><h3>Subscription Packages</h3></div> */}
                    <div className="subscriptiontbls">
                      <div className="row">
                        <div className="col-lg-4 col-md-6">
                          <div className="pricing-item">
                            <div className="pricing-header">
                              <h3 className="pricing-title">BASIC PLAN</h3>
                            </div>
                            <div className="pricing-body">
                              <div className="price-wrapper">
                                <span className="currency">$</span>
                                <span className="price">10.90</span>
                                <span className="period">/Month</span>
                              </div>
                              <ul className="list">
                                <li className="active">Lorem Ipsum</li>
                                <li className="active">Consectetur adipiscing elit</li>
                                <li className="active">Eiusmod tempor </li>
                                <li className="active">Duis aute irure dolor in </li>
                                <li>Excepteur sint occaecat </li>
                                <li>Sunt in culpa</li>
                                <li>24x7 Great Support</li>
                                <li>Monthly Reports and Analytics</li>
                              </ul>
                            </div>
                            <div className="pricing-footer">
                              <a href="#" className="btn-primary-line" data-bs-toggle="modal" data-bs-target="#subscriptoinpackage" data-bs-dismiss="modal">Edit Plan</a>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="pricing-item active">
                            <div className="pricing-header">
                              <h3 className="pricing-title">ADVANCED PLAN</h3>
                            </div>
                            <div className="pricing-body">
                              <div className="price-wrapper">
                                <span className="currency">$</span>
                                <span className="price">21.90</span>
                                <span className="period">/Month</span>
                              </div>
                              <ul className="list">
                                <li className="active">Lorem Ipsum</li>
                                <li className="active">consectetur adipiscing elit</li>
                                <li className="active">1Eiusmod tempor </li>
                                <li className="active">Duis aute irure dolor in </li>
                                <li className="active">Excepteur sint occaecat </li>
                                <li className="active">Sunt in culpa</li>
                                <li>24x7 Great Support</li>
                                <li>Monthly Reports and Analytics</li>
                              </ul>
                            </div>
                            <div className="pricing-footer">
                              <a href="#" className="btn-primary-line" data-bs-toggle="modal" data-bs-target="#subscriptoinpackage" data-bs-dismiss="modal">Edit Plan</a>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="pricing-item">
                            <div className="pricing-header">
                              <h3 className="pricing-title">EXPERT PLAN</h3>
                            </div>
                            <div className="pricing-body">
                              <div className="price-wrapper">
                                <span className="currency">$</span>
                                <span className="price">43.90</span>
                                <span className="period">/Month</span>
                              </div>
                              <ul className="list">
                                <li className="active">Lorem Ipsum</li>
                                <li className="active">consectetur adipiscing elit</li>
                                <li className="active">1Eiusmod tempor </li>
                                <li className="active">Duis aute irure dolor in </li>
                                <li className="active">Excepteur sint occaecat </li>
                                <li className="active">Sunt in culpa</li>
                                <li className="active">24x7 Great Support</li>
                                <li className="active">Monthly Reports and Analytics</li>
                              </ul>
                            </div>
                            <div className="pricing-footer">
                              <a href="#" className="btn-primary-line" data-bs-toggle="modal" data-bs-target="#subscriptoinpackage" data-bs-dismiss="modal">Edit Plan</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        }
        <div className="modal fade modalmedium jobmodle subscriptoinpackage" id="subscriptoinpackage" tabIndex="-1" aria-labelledby="subscriptoinpackageLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <h3>Basic plan</h3>
                <div className="commonform">
                  <div className="form-group mb-2">
                    <label className="form-label">Plan name</label>
                    <input type="text" className="form-control" placeholder="Enter plan name"></input>
                  </div>
                  <div className="form-group mb-2">
                    <label className="form-label">Plan Price</label>
                    <input type="text" className="form-control" placeholder="Enter plan price"></input>
                  </div>
                  <div className="form-group mb-2">
                    <label className="form-label">Plan description</label>
                    <textarea type="text" className="form-control" rows="8" placeholder="Enter plan description"></textarea>
                  </div>
                  <div className="form-group">
                    <a href="#" className="batn batn-orange w-100" >Save Plan</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default SubscriptionPackages;