import React from 'react';

const ImageDownloadButton = (attr) => {
    const handleDownloadClick = () => {
        // Replace 'your_image_url' with the actual URL of the image you want to download
        const imageUrl = attr.url;

        // Fetch the image data
        fetch(imageUrl)
            .then(response => response.blob())
            .then(blob => {
                // Create a Blob from the fetched data
                const url = window.URL.createObjectURL(blob);

                // Create a temporary link element
                const a = document.createElement('a');
                a.href = url;

                // Set the filename for the download
                a.download = 'downloaded_image.jpg';

                // Append the link to the document
                document.body.appendChild(a);

                // Simulate a click on the link to trigger the download
                a.click();

                // Remove the temporary link element
                document.body.removeChild(a);
            })
            .catch(error => {
                console.error('Error downloading image:', error);
            });
    };

    return (
        <span className='cursor_pointer' onClick={handleDownloadClick} >
            <img src="/front/images/download.png" />
        </span>
    );
};

export default ImageDownloadButton;
