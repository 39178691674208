import React, { useState, useEffect, Component } from 'react';
import PasswordResetComp from '../PasswordResetComp';
import SuperAdminSidebar from './superadmin_sidebar'
const Accountsetting = () => {


   return (
      <>

         <div className="dashboarwrap">
         <div className="pagebanner">
               <div className="container">
                  <div className="row">
                     <div className="col-md-12">
                        <h2>Account Settings</h2>
                     </div>
                  </div>
               </div>
            </div>
           {/* <ToastContainer />*/}
            <div className="adminwrap py-4">
               <div className="container">
                  <div className="row">
                     <div className="col-md-3 adminsidebar">
                        <SuperAdminSidebar></SuperAdminSidebar>
                     </div>
                     <div className="col-md-9">
                        <PasswordResetComp  usertype={'superadmin'} />
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
}
export default Accountsetting;