import React, { useState, useEffect, useRef, Component } from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink, useParams, useNavigate, Link } from 'react-router-dom';
import { Form, Button, Col, InputGroup, Modal, Row } from 'react-bootstrap';

import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';


//import ReviewSendfun from './ReviewSendfun';


import CheckValidationComp  from './CheckValidationComp';

const Chat = () => {
   

   const useriddata = JSON.parse(localStorage.getItem("squadGologinSession"));
   const [chatUserListArray, setChatUserListArray] = useState([]);
   const [currentchatMessageArray, setCurrentchatMessageArray] = useState('');
   const [candiDateData, setCandiDateData] = useState([]);
   const [candiDateDataLoading, setCandiDateDataLoading] = useState(false);
   const [chatSeactShow, setChatSeactShow] = useState(false);
   const [currentChatUserID, setCurrentChatUserID] = useState(useriddata.unique_id);
   const [attachment, setAttachment] = useState(null);
   const [chadSedningStatus, setChadSedningStatus] = useState(false);
   const [chatTokenID, setChatTokenID] = useState(false);
   const [isUserBlock, setisUserBlock] = useState('unblock');

   const handleFileUpload = (event) => {
      const file = event.target.files[0];
      let filesize = 50 * 1024 * 1024;
      if (file.size < filesize) { setAttachment(file); } else { alert("You can't upload more the 50 mb") }

   };

   //getcandidate_data
   const getChatUserList = (searchkey = '') => {
      (async () => {
         try {
            const resData = await axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/getchatlist_user/${useriddata.login_token}/${useriddata.user_type}?searchkey=${searchkey}`);
            if (resData.data.status == 1) { setChatUserListArray(resData.data.data.chatuserid); }
         } catch (error) { console.log(error); }
      })();
   }

   const getCandidateData = (getuserid) => {
      setCandiDateDataLoading(true);
      (async () => {
         try {
            const resData = await axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/getcandidate_chat_data/${useriddata.login_token}/${getuserid}`); //setisUserBlock
            if (resData.data.status == 1) {
               //console.log(resData.data.data)
               setCurrentchatMessageArray(resData.data.data.chatdata);
               setisUserBlock(resData.data.data.block_status);
               setChatTokenID(resData.data.data.chat_token_id);
               setCurrentChatUserID(getuserid);
               setCandiDateData(resData.data.data.candidate);

            }
            setCandiDateDataLoading(false);
         } catch (error) { console.log(error); }
      })();
   }

   const getCandidateDataByOfferId = (offerid) => {
      setCandiDateDataLoading(true);
      (async () => {
         try {
            const resData = await axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/getcandidate_chat_data_byofferid/${useriddata.login_token}/${offerid}`);
            if (resData.data.status == 1) { setCurrentchatMessageArray(resData.data.data.chatdata); setCurrentChatUserID(resData.data.data.candidate.unique_id); setCandiDateData(resData.data.data.candidate); setCandiDateDataLoading(false); }
         } catch (error) { console.log(error); }
      })();
   }


   //userdetails
   useEffect(() => {
      const queryParameters = new URLSearchParams(window.location.search);
      const contactid = queryParameters.get("contactto");
      const jobofferid = queryParameters.get("offerid");
      getChatUserList();
      if (contactid) { console.log('dddd'); getCandidateData(contactid); } 
      else {  console.log('sssdddd'); getCandidateData(useriddata.unique_id)}
      if (jobofferid) { getCandidateDataByOfferId(jobofferid); }
   }, []);

   const myRef = useRef();
   const onChatMessageSend = () => {

      myRef.current.focus(); // Focus the input element
      var value = (myRef.current.value) ? myRef.current.value : '';
      if (capturedImage) { value = 'New attachment by capture'; }
      if (attachment) { value = 'New attachment by sysytem'; }

      if (value) {
         (async () => {
            try {
               setChadSedningStatus(true);
               const formData = new FormData();
               formData.append('message', value);
               formData.append('to_unique_id', currentChatUserID);
               formData.append('from_unique_id', useriddata.unique_id);
               formData.append('login_token', useriddata.login_token);
               if (attachment) { formData.append('attachment', attachment); }
               if (capturedImage) { formData.append('attachment', capturedImage); }

               console.log(value)
               axios.post('https://squadgoo.com.au/api.squadgoo.com.au/chat_mesage_create', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
                  .then((response) => {
                     if (response.status == 200 && response.data.status == 1) {
                        console.log(response.data);
                        myRef.current.value = '';
                        setCurrentchatMessageArray(response.data.data.chatdata)
                        if (capturedImage) { setShowModal(false); }
                        setChadSedningStatus(false);
                     }
                  })
                  .catch((error) => { console.error(error); });
            } catch (error) { console.log(error); }

         })();
      }
   };


   {/*------------search message -------------------*/ }
   const getChatData = (searchkey = '') => {
      (async () => {
         try {
            const resData = await axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/getchatdata_user/${useriddata.login_token}/${currentChatUserID}?searchkey=${searchkey}`);
            if (resData.data.status == 1) { setCurrentchatMessageArray(resData.data.data.chatdata); }
         } catch (error) { console.log(error); }
      })();
   }
   const searchInChat = (searchtext) => { if (searchtext.length > 3) { getChatData(searchtext); } else { getChatData(searchtext); } }

   {/*-------------------------------------------------------------*/ }
   const [showModal, setShowModal] = useState(false);
   // const handleShowModal = () => { setShowModal(true); cameraOff() };
   const handleHideModal = () => { setShowModal(false); cameraOff(); };
   const videoRef = useRef(null);
   const [capturedImage, setCapturedImage] = useState('');
   const [stream, setStream] = useState(null);
   const chatModelShow = () => { setShowModal(true); startCamera() }
   const cameraOff = () => { if (stream) { const tracks = stream.getTracks(); tracks.forEach((track) => track.stop()); setStream(null); } else { setCapturedImage(''); } };

   const startCamera = async () => {
      setCapturedImage('')
      try {
         const stream = await navigator.mediaDevices.getUserMedia({ video: true });
         if (videoRef.current) { videoRef.current.srcObject = stream; setStream(stream); }
      } catch (error) { console.error('Error accessing camera:', error); }
   };

   const captureImage = () => {
      const canvas = document.createElement('canvas');
      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;
      const context = canvas.getContext('2d');
      context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
      const dataURL = canvas.toDataURL('image/png');
      setCapturedImage(dataURL);
      cameraOff();
   };


   {/*-------------------------------------------------------------*/ }
   //const [isModalHideandShowWithParam, setisModalHideandShowWithParam] = useState(false);
   const [isModalHideandShowAllChat, setisModalHideandShowAllChat] = useState(false);
   const [editItemID, seteditItemID] = useState(0);
   const [cllingDeleteType, setcllingDeleteType] = useState('deleteMessage');
   const [modalCallMessage, setModalCallMessage] = useState('Do you want to Delete all conversation?');
   const deleteChat = () => {
      (async () => {
         try {
            const resData = await axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/chatdelete_action/${useriddata.login_token}/${currentChatUserID}/${cllingDeleteType}?editItemID=${editItemID}&chat_token_id=${chatTokenID}`);
            if (resData.data.status == 1) { getCandidateData(currentChatUserID); setisModalHideandShowAllChat(false) }
         } catch (error) { console.log(error); }
      })();
   }
   const isModalHideandShowWithParam = (modelcallingtype, itemid = 0) => {
      setcllingDeleteType(modelcallingtype);
      seteditItemID(itemid)
      switch (modelcallingtype) {
         case 'deleteIndivisual':
            setModalCallMessage('Do you want to Delete message?');
            break;
         case 'blockUser':
            setModalCallMessage(`Would you like to ${isUserBlock == 'unblock' ? 'Block' : 'Unblock'}  conversation?`);
            break;
         default:
            setModalCallMessage('Are you sure you want to delete the entire conversation? ');
            break;
      }
      setisModalHideandShowAllChat(true);
   }




   /*---------------------------modal-------------------------------*/
   const [isToggleAttachmentModal, setisToggleAttachmentModal] = useState(false);
   const [loadingBtn, setloadingBtn] = useState(false);
   const [sendMessageArea, setsendMessageArea] = useState('');
   const [file, setFile] = useState(null);
   const [imageUrl, setImageUrl] = useState(null);

   const attachmentModalCloseFun = () => {
      setisToggleAttachmentModal(false);
      handleRemoveFile()
   }


   const handleFileChange = (e) => {
      const selectedFile = e.target.files[0];
      if (selectedFile) {
         setFile(selectedFile);
         setAttachment(selectedFile);

         if (selectedFile.type.startsWith('image/')) {
            const reader = new FileReader();
            reader.onload = (e) => {
               setImageUrl(e.target.result);
            };
            reader.readAsDataURL(selectedFile);
         } else if (selectedFile.type === 'application/pdf') {
            setImageUrl('/path-to-default-pdf-image.jpg');
         } else {
            // Handle non-image and non-PDF files as needed
         }
      }
   };
   const handleRemoveFile = () => {
      setFile(null);
      setImageUrl(null);
      setAttachment(null);
      setloadingBtn(false);
   };
   const sendMessageWithAttachemnt = () => {
      (async () => {
         try {
            setloadingBtn(true);
            const formData = new FormData();
            formData.append('message', sendMessageArea);
            formData.append('to_unique_id', currentChatUserID);
            formData.append('from_unique_id', useriddata.unique_id);
            formData.append('login_token', useriddata.login_token);
            if (attachment) { formData.append('attachment', attachment); }
            axios.post('https://squadgoo.com.au/api.squadgoo.com.au/chat_mesage_create', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
               .then((response) => {
                  if (response.status == 200 && response.data.status == 1) {
                     handleRemoveFile();
                     setCurrentchatMessageArray(response.data.data.chatdata)
                     setisToggleAttachmentModal(false)
                  }
               })
               .catch((error) => { console.error(error); });
         } catch (error) { console.log(error); }

      })();
   };


   /*---------------component call------------------*/
   const CheckFileType = (data) => {
      //console.log(data.data)
      return (
         <>
            {
               data.data.extenstion == 'pdf' ?
                  <>
                     <a target='_blank' href={'https://squadgoo.com.au/api.squadgoo.com.au/' + data.data.attachment_url} >
                        <img className='chat_row_attachment' key={data.data.id} src={`/front/images/pdfimage.png`} />
                     </a>
                  </>
                  :
                  <>
                     <img className='chat_row_attachment' key={data.data.id} src={'https://squadgoo.com.au/api.squadgoo.com.au/' + data.data.attachment_url}></img>
                  </>
            }
         </>
      )
   }
   const [chekRowNumber, setchekRowNumber] = useState(1);
   const checkTextAreaRow = (event) => {
      //checkTextAreaRow
      if (event.key === "Enter") {
         // Prevent the newline character from being added
         event.preventDefault();

         // Increment the row number and update the state
         setchekRowNumber(chekRowNumber + 1);
      }
   }


   /*--------------------------on validation of new review modal-------------------------------*/
   const [addreviewmodal, setaddreviewmodal] = useState(false);
   const [hideAndSeekMyRequestModel, setHideAndSeekMyRequestModel] = useState(false);
   const [validated, setValidated] = useState(false);
   const onFinalSubmit = async (event) => {
      event.preventDefault();
      const form = event.currentTarget;
      if (form.checkValidity() === false) { event.preventDefault(); event.stopPropagation(); }
      else {
         const description = handleInputChange(event.target.description.value);
         try {
            const formData = new FormData();

            formData.append('title', event.target.title.value);
            formData.append('description', description);
            formData.append('login_token', useriddata.login_token);
            formData.append('review_related_to', 'chat_module');
            formData.append('rating', rating);
            formData.append('send_for', currentChatUserID);
            // formData.append('report_form', reportFrom);
            // formData.append('edit_id', editDataID);
            axios.post('https://squadgoo.com.au/api.squadgoo.com.au/add_review_actions', formData)
               .then((response) => {
                  if (response.status == 200 && response.data.status == 1) {
                     toast.success(response.data.message);
                     setaddreviewmodal(false)
                     //get_requesteddata()
                  } else {
                     toast.warning(response.data.message);
                  }
                  //setEditDataID(0);
               })
               .catch((error) => { console.error(error); });
         } catch (error) { console.log(error); }
      }
      setValidated(true);
   };


   /*----------------------*/

   const handleInputChange = (inputText) => {
      //const inputText = event.target.value;

      // Regular expressions to match email addresses and phone numbers
      const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,6}\b/g;
      const phoneRegex = /\b\d{10}\b/g;

      // Function to replace matched text with hyperlinks
      const replaceEmailsAndPhones = (text) => {
         const textWithEmailLinks = text.replace(emailRegex, (match) => {
            return `<a href="mailto:${match}">${match}</a>`;
         });

         const textWithPhoneLinks = textWithEmailLinks.replace(phoneRegex, (match) => {
            return `<a href="tel:${match}">${match}</a>`;
         });

         return textWithPhoneLinks;
      };

      const transformedMessage = replaceEmailsAndPhones(inputText);

      return (transformedMessage);
   };


   /*----------------search content ------------------*/
   const [filterKey, setFilterKey] = useState('');
   const handleFilterChange = (e) => {
      setFilterKey(e.target.value);
   };
   const chatUserListArrayData = chatUserListArray.filter((item) => item.first_name.toLowerCase().includes(filterKey.toLowerCase()));

   /*-----------------hide conversation-----------------------*/
   const hideThisConversation = (id) => {
      (async () => {
         try {
            const resData = await axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/disable_chat_option/${useriddata.login_token}/${chatTokenID}`);
            if (resData.data.status == 1) {
               // setContactNumber(''); 
               // setContactNumberError(resData.data.message); 
            }
         } catch (error) { console.log(error); }
      })();
   }

   /*-------------------*/
   const [rating, setRating] = useState(1);
   const [review, setReview] = useState('');

   const handleRatingChange = (newRating) => {
      setRating(newRating);
   };

   const handleReviewChange = (e) => {
      setReview(e.target.value);
   };


   const StarRating = ({ rating, onRatingChange }) => {
      const stars = [1, 2, 3, 4, 5];
      return (
         <>
            <div className="star-rating">
               {
                  stars.map((star) => (
                     <span
                        key={star}
                        className={star <= rating ? 'star active' : 'star'}
                        onClick={() => onRatingChange(star)}
                     >
                        ★
                     </span>
                  ))
               }
            </div>
         </>
      )
   }
 
   return (
      <>
        {/* <ToastContainer />*/}
        
         <div className="dashboarwrap">
            <div className="pagebanner">
               <div className="container">
                  <div className="row">
                     <div className="col-md-12">
                        <h2>Message</h2>
                     </div>
                  </div>
               </div>
            </div>

            <div className="jobbtmsec py-5">
               <div className="container">


                  <div className="row chatwrap">
                     <div className="col-md-3 recurutersidbar">
                        <div className="recuritersidbar">
                           <div className="recuritersidbarinner">

                              <div className="chatsearch"><div className="chatsearchinner">
                                 <input className="form-control"
                                    type="text" placeholder="Search"
                                    value={filterKey}
                                    onChange={handleFilterChange}
                                 />
                                 <img src="/front/images/chatsearchleft.png" /></div>
                              </div>

                              <div className="chatpanel">
                                 <Link className={`chatlist_title ${currentChatUserID == useriddata.unique_id ? 'active' : ''}`} onClick={() => getCandidateData(useriddata.unique_id)}>
                                    <div className="chatsinglepnl d-flex">
                                       <div className={`chatimg ${useriddata.online == 1 ? 'online' : 'offline'}`}>
                                          <img src={useriddata.profilepic ? 'https://squadgoo.com.au/api.squadgoo.com.au/' + useriddata.profilepic : '/front/images/user.svg'}></img>
                                       </div>
                                       <div className="chatmidleinfo">
                                          <h4 className='text-capitalize'>
                                             {useriddata.first_name + ' ' + useriddata.last_name}
                                          </h4>
                                       </div>
                                    </div>
                                 </Link>
                                 {
                                    chatUserListArrayData.length ?
                                       chatUserListArrayData.map((item, index) => (
                                          <Link className={`chatlist_title ${currentChatUserID == item.unique_id ? 'active' : ''}`} onClick={() => getCandidateData(item.unique_id)}>
                                             <div className="chatsinglepnl d-flex">
                                                <div className={`chatimg ${item.online == 1 ? 'online' : 'offline'}`}>
                                                   <img src={item.profilepic ? 'https://squadgoo.com.au/api.squadgoo.com.au/' + item.profilepic : '/front/images/user.svg'}></img>
                                                </div>
                                                <div className="chatmidleinfo">
                                                   <h4 className='text-capitalize'>
                                                      {item.first_name + ' ' + item.last_name}
                                                   </h4>
                                                   <p>{item.last_message ? item.last_message : <>Start chat now</>}</p>
                                                </div>
                                                {
                                                   item.totalcount ?
                                                      <>
                                                         <div className="chatendinfo">
                                                            {/* <p>{item.time_many_ago}</p> */}
                                                            <div><span className="msgcount">{item.totalcount}</span></div>
                                                         </div>
                                                      </>
                                                      :
                                                      <></>
                                                }
                                                <span onClick={() => hideThisConversation(item.user_id)} className="hideoption">Hide</span>

                                             </div>
                                          </Link>
                                       ))
                                       :
                                       <>
                                          {/* <div className="chatsinglepnl d-flex">

                                             <p>No chats available for display</p>
                                          </div> */}
                                       </>
                                 }
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="col-md-9">
                        <div className="chatrightwrap">
                           {
                              candiDateDataLoading ?
                                 <>
                                    <div className='loader_container'>
                                       <p className='text-center'><img className='width-100 loader_image' src={'/front/images/loader.gif'} /></p>
                                    </div>
                                 </>
                                 :
                                 <>
                                    <div className="chatheader">
                                       <div className="chatsinglepnl d-flex">
                                          <div className={`chatimg ${candiDateData.online == 1 ? 'Online' : 'Offline'}`}>

                                             <img src={candiDateData.profilepic ? 'https://squadgoo.com.au/api.squadgoo.com.au/' + candiDateData.profilepic : '/front/images/user.svg'}></img>

                                             {/* <img src={`https://squadgoo.com.au/api.squadgoo.com.au/${candiDateData.profilepic}`} /> */}
                                          </div>
                                          <div className="chatmidleinfo">
                                             <h4 className='text-capitalize'>{candiDateData.first_name + ' ' + candiDateData.last_name}</h4>
                                             <p>{candiDateData.online == 1 ? 'Online' : 'Offline'}</p>
                                          </div>
                                          <div className="chatboxtool d-flex align-items-center ml-auto">
                                             {chatSeactShow && <input onChange={(e) => searchInChat(e.target.value)} type='text' placeholder='search....' className='form-control' />}
                                             <Link onClick={(e) => setChatSeactShow(!chatSeactShow)} href="#"><img src="/front/images/chatsearch.svg" /></Link>
                                             {/* {candiDateData.contact_number && <a href={`tel:${candiDateData.contact_number}`}><img src="/front/images/chatphone.svg" /></a>} */}
                                             <Link target='_blank' to={`/voicecall?tokenid=${currentChatUserID}&chatid=${chatTokenID}`}><img src="/front/images/chatphone.svg" /></Link>
                                             <Link target='_blank' to={`/livevideochat?tokenid=${currentChatUserID}&chatid=${chatTokenID}`}><img src="/front/images/chatvideo.svg" /></Link>
                                             {candiDateData.email && <a href={`tel:${candiDateData.email}`}><img src="/front/images/charmail.svg" /></a>}
                                             {/* <Link target='_blank' to="https://www.twilio.com/en-us/video"><img src="/front/images/chatvideo.svg" /></Link> */}
                                             {/* <Link target='_blank' to="https://meet.google.com"><img src="/front/images/chatvideo.svg" /></Link> */}
                                            
                                             <div className="chatbocoptions">
                                                <a href="#" className="dropdown-toggle" type="button" id="cahtoptiondrop" data-bs-toggle="dropdown" aria-expanded="false"><img src="/front/images/chardots.png" /></a>
                                                <ul className="dropdown-menu" aria-labelledby="cahtoptiondrop">
                                                   {/* <li><a className="dropdown-item" href="#">Mute notifications</a></li> */}

                                                   {currentChatUserID != useriddata.unique_id ? 
                                                   <>
                                                   <li><Link onClick={() => setaddreviewmodal(true)} className="dropdown-item" href="#">Review</Link></li>
                                                   <li><Link onClick={() => isModalHideandShowWithParam('deleteAll')} className="dropdown-item" href="#">Delete chat</Link></li>
                                                   <li><Link onClick={() => isModalHideandShowWithParam('blockUser')} className="dropdown-item" href="#">{isUserBlock == 'unblock' ? 'Block' : 'Unblock'} user</Link></li>
                                                   <li><Link target='_blank' to={`/my-requests?action=new_rquest&notification_form=chat_report&reference_id=${chatTokenID}`} className="dropdown-item" href="#">Report</Link></li>
                                                   </>
                                                      :
                                                   <>
                                                   <li><Link onClick={() => isModalHideandShowWithParam('deleteAll')} className="dropdown-item" href="#">Delete chat</Link></li>
                                                   </>   
                                                }
                                                
                                                
                                                </ul>
                                             </div>

                                          </div>
                                       </div>
                                    </div>
                                    <div className="chatbody">
                                       {
                                          currentchatMessageArray.length ?
                                             <>
                                                {
                                                   currentchatMessageArray.map((item, index) => (
                                                      <>
                                                         <div className="chatday text-center"><span className="line"></span><span>{item.daydate}</span><span className="line"></span></div>
                                                         {
                                                            item.data.map((itemdata, indexdata) => (
                                                               <>
                                                                  <div key={itemdata.id} className={`chatlinw  ${itemdata.sender_id} ${item.sender_id != itemdata.sender_id ? 'client' : ''}`}>
                                                                     {
                                                                        itemdata.delete_status == 1 ?
                                                                           <>
                                                                              <div className={`chatmsg mt-1 ${item.sender_id != itemdata.sender_id ? 'text-1' : 'text-white'}`}>
                                                                                 <i>This message deleted by you on {itemdata.delete_status_date}</i>
                                                                              </div>
                                                                           </>
                                                                           :
                                                                           <>
                                                                              <div className="chattime">{itemdata.time}</div>
                                                                              <div className="chatmsg">
                                                                                 <div className='message_contiainer'>
                                                                                    {
                                                                                       (itemdata.attachment && itemdata.attachment.length) ?
                                                                                          <>
                                                                                             {
                                                                                                itemdata.attachment.map((itemattach, indexattach) => (
                                                                                                   <>
                                                                                                      <CheckFileType data={itemattach} ></CheckFileType>
                                                                                                   </>
                                                                                                ))
                                                                                             }
                                                                                          </>
                                                                                          :
                                                                                          <></>
                                                                                    }
                                                                                    {/* <p>{itemdata.message}</p> */}
                                                                                    <div className={`${item.sender_id != itemdata.sender_id ? 'text-1' : 'text-white'}`} dangerouslySetInnerHTML={{ __html: handleInputChange(itemdata.message).replace(/\n/g, '<br>') }} />
                                                                                    <span onClick={() => { isModalHideandShowWithParam('deleteIndivisual', itemdata.id); }} className='text-ornage cursor_pointer'>
                                                                                       <i className='fa fa-trash' ></i>
                                                                                    </span>
                                                                                 </div>
                                                                              </div>
                                                                           </>
                                                                     }
                                                                  </div>
                                                               </>
                                                            ))
                                                         }

                                                      </>
                                                   ))
                                                }
                                             </>
                                             :
                                             <>
                                                <p className='no_conversation_found'>No messages available for display</p>
                                             </>
                                       }
                                    </div>
                                    <div className="cahtfooterouter">
                                       {isUserBlock == 'unblock' && <div className="cahtfooter d-flex align-items-center justify-content-between">
                                          {/* <input></input> */}
                                          <textarea ref={myRef} type="text" placeholder="Type something..." row={chekRowNumber} className='chat_textarea'></textarea>
                                          <div className="d-flex align-items-center chatboctools">
                                             {/* <span href="#">
                                                <input type="file" onChange={handleFileUpload} accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*" />
                                                <img src="/front/images/attachment.svg"></img>
                                             </span> */}
                                             <Link onClick={() => setisToggleAttachmentModal(true)} href="#"><img src="/front/images/attachment.svg"></img></Link>
                                             <Link onClick={() => chatModelShow(true)} href="#"><img src="/front/images/camera.svg"></img></Link>
                                             <Link onClick={onChatMessageSend}><img src="/front/images/send.svg"></img></Link>
                                          </div>
                                       </div>}
                                       {
                                          isUserBlock == 'block' && <div className="cahtfooter d-flex align-items-center justify-content-between">
                                             <div className="d-flex align-items-center chatboctools">
                                                <p className='text-danger m-0 text-center'>You can't message to this user.</p>
                                             </div>
                                          </div>
                                       }
                                    </div>

                                 </>
                           }

                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         {/* <Modal show={showModal} onHide={handleHideModal}> */}
         <Modal show={showModal} onHide={handleHideModal}>
            <div className="modal-dialog1 findastaffmodle">
               <div className="modal-content">
                  <div className="modal-body">
                     <button type="button" className="btn-close" onClick={handleHideModal} data-bs-dismiss="modal" aria-label="Close"></button>
                     {
                        <>
                           {capturedImage && <img src={capturedImage} alt="Captured" className='captured_image' />}
                           {!capturedImage && <video className='captured_image' ref={videoRef} width="400" height="400" autoPlay />}
                           <div className="form-group mb-2 text-center mt-2">
                              {!stream && <button className='batn batn-orange mr-2  text-capitalize' onClick={startCamera}>Start Camera</button>}
                              {stream && <button className='batn batn-orange  text-capitalize  mr-2 ' onClick={cameraOff}>Stop Camra</button>}
                              {stream && <button className='batn batn-orange mr-2  text-capitalize' onClick={captureImage}>Take a picture</button>}
                              {capturedImage && <button disabled={chadSedningStatus} className='batn batn-orange mr-2  text-capitalize' onClick={onChatMessageSend}>{chadSedningStatus ? 'Sending' : 'Send'}</button>}

                           </div>
                        </>
                     }
                  </div>
               </div>
            </div>
         </Modal>
         <Modal show={isModalHideandShowAllChat} onHide={() => setisModalHideandShowAllChat(0)}>
            <div className="modal-dialog1 findastaffmodle">
               <div className="modal-content">
                  <div className="modal-body p-5">
                     <button type="button" className="btn-close" onClick={() => setisModalHideandShowAllChat(0)}></button>
                     {/* {cllingDeleteType} */}
                     <h4 className="text-center mb-4">{modalCallMessage}</h4>
                     <div className="btnsgroup text-center">
                        <Link onClick={() => setisModalHideandShowAllChat(0)} className="batn batn-orange batn-border">No</Link>
                        <Link onClick={deleteChat} className="batn batn-orange batn-border"  >Yes</Link>
                     </div>
                  </div>
               </div>
            </div>
         </Modal>


         <Modal show={isToggleAttachmentModal} onHide={() => attachmentModalCloseFun(0)}>
            <div className="modal-dialog1 findastaffmodle">
               <div className="modal-content">
                  <div className="modal-body p-3">
                     <button type="button" className="btn-close" onClick={() => attachmentModalCloseFun(0)}></button>
                     {/* {cllingDeleteType} */}
                     <h4 className="text-center mb-4">
                        <div>
                           {(file && imageUrl) ? (
                              <div>
                                 <button onClick={handleRemoveFile} className="batn batn-orange batn-border modal_back" >
                                    <i className="fa fa-arrow-circle-left" aria-hidden="true"></i>
                                 </button>
                                 {file.type.startsWith('image/') ? (
                                    <div>
                                       <img src={imageUrl} alt="Selected file" style={{ maxWidth: '100%' }} />
                                    </div>
                                 ) : file.type === 'application/pdf' ? (
                                    <div>
                                       <img src={`/front/images/pdfimage.png`} alt="PDF File" style={{ maxWidth: '100%' }} />
                                    </div>
                                 ) : (
                                    <div>
                                       File type: {file.type}
                                    </div>
                                 )}

                                 {
                                    !loadingBtn ?
                                       <>
                                          <div className='cahtfooterouter p-0 pt-2'>
                                             <div className='cahtfooter_modal d-flex align-items-center justify-content-between'>
                                                <textarea type="text" onChange={(e) => setsendMessageArea(e.target.value)} placeholder="Type something..." className="chat_textarea chat_textarea_modal border " spellcheck="false"></textarea>
                                                <Link onClick={sendMessageWithAttachemnt}><img src="/front/images/send.svg" /></Link>
                                             </div>
                                          </div>
                                       </>
                                       :
                                       <><p className='text-center'>Please wait attachment is uploading to server.....</p></>}

                              </div>
                           ) :
                              <>
                                 <div className="form-group identityproof stepscont">
                                    <div className="customuploadsec">
                                       <input
                                          type="file"
                                          accept=".pdf, .jpg, .jpeg, .png"
                                          onChange={handleFileChange}
                                          id="fileInput"
                                       />
                                       <div className="customuploadinner text-center">
                                       <div className="my-4"><img src="/front/images/uploadicon.png" className="mt-4"/></div>
                                             <p className="mb-0">Click here to upload a file</p>
                                         
                                       </div>
                                    </div>
                                 </div>
                              </>
                           }
                        </div>
                     </h4>

                  </div>
               </div>
            </div>
         </Modal>

         {/* new feedback modal */}
         <Modal show={addreviewmodal} onHide={() => setaddreviewmodal(false)}>
            <div className="modal-dialog1">
               <div className="modal-content">
                  <div className="modal-body">
                     <button onClick={() => setaddreviewmodal(false)} type="button" className="btn-close" ></button>
                     <h3>Add Review</h3>
                     <div className="commonform">
                        {/* <ReviewSendfun data={currentChatUserID}  /> */}
                        <Form noValidate validated={validated} onSubmit={onFinalSubmit}>
                           <div className="row">
                              <div className="col-md-12 form-group mb-2">
                                 <label className="form-label" htmlFor="lastName1">Title <span className="starr">*</span></label>
                                 <input className="form-control" required={true} name='title' placeholder="Enter title" />
                              </div>
                              <div className="col-md-12 form-group mb-2">
                                 <label className="form-label" htmlFor="exampleInputEmail1">Description <span className="starr">*</span></label>
                                 <textarea className="form-control" required={true} name='description' rows="5" placeholder="Enter description"></textarea>
                              </div>

                              <div className="col-md-12 form-group mb-2">
                                 <label className="form-label" htmlFor="exampleInputEmail1">Rating <span className="starr">*</span></label>
                                 <StarRating rating={rating} onRatingChange={handleRatingChange} />
                              </div>

                              <div className="col-md-12 form-group mt-3">
                                 <button type='submit' className="batn batn-orange w-100">Send</button>
                              </div>

                           </div>
                        </Form>
                     </div>
                  </div>
               </div>
            </div>
         </Modal>



      </>
   );
}
export default Chat;