// src/utils/cropper-utils.js
export const getCroppedImg = (imageSrc, crop) => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = imageSrc;
  
      image.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
  
        // Set the canvas size to the crop area
        canvas.width = crop.width;
        canvas.height = crop.height;
  
        // Draw the cropped area on the canvas
        ctx.drawImage(
          image,
          crop.x,
          crop.y,
          crop.width,
          crop.height,
          0,
          0,
          crop.width,
          crop.height
        );
  
        // Convert the canvas to a Blob
        canvas.toBlob((blob) => {
          if (!blob) {
            reject('Could not create a blob');
            return;
          }
          // Create a URL from the Blob
          const fileUrl = URL.createObjectURL(blob);
          resolve({ blob, fileUrl });
        }, 'image/jpeg'); // Specify the desired image format
      };
  
      image.onerror = (err) => {
        reject(err);
      };
    });
  };
  