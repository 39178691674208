import React, { useState, useEffect, Component } from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink, useParams, useNavigate } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';
import ProfileBanner from '../profile_header';
import ProfileSidebar from '../profile_sidebar';

import { toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';

import { useDispatch } from 'react-redux'
import { directorsDetailsValidation } from '../../redux_state/reducres/checkDocumentstatus';
import ABBREVIATIONS from '../../json/abbreviations.json';


const DirectorsDetails = () => {
    const alphabeticRegex = /^[a-zA-Z]*$/;
    const userdetails = JSON.parse(localStorage.getItem("squadGologin"));
    const dispatch = useDispatch();
    const [userDetailsArray, setUserDetailsArray] = useState([]);
    const [directorsArray, setDirectorsArray] = useState([]);
    const [hideToggleSection3, setHideToggleSection3] = useState(true);
    const toggleHidden3 = () => { setHideToggleSection3(!hideToggleSection3); };
    const [getAllCountry, setGetallCountry] = useState([]);
    /*------------------------director detail update ------------------ */
    const [isAccVerified, setIsAccVerified] = useState('false');
    const getUserDetails = () => {
        (async () => {
            try {
                const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/userdetails/" + userdetails.unique_id);
                if (resData.data.status == 1) {
                    setUserDetailsArray(resData.data.data.results);
                    setDirectorsArray(resData.data.data.results.directers_details);

                    console.log(resData.data.data.results.verifications_status.statustype);
                    //basic_details
                    setIsAccVerified(resData.data.data.results.verifications_status.statustype.director_contact_details);
                }
            } catch (error) { console.log(error); }
        })();
    }


    useEffect(() => {
        if (userdetails.unique_id) {
            getUserDetails();
            (async () => {

                try {
                    const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/get_all_country");
                    if (resData.data.status == 1) { setGetallCountry(resData.data.data.results); }
                } catch (error) { console.log(error); }
            })();

        } else { setTimeout(function () { window.location.replace("/login"); }, 100) }
    }, []);


    const directorDetailsUpdatefun = (event) => {
        const { name, value } = event.target;
        if ((name == 'first_name' || name == 'middle_name' || name == 'last_name') && !alphabeticRegex.test(value)) {
            return null;
        }

        setDirectorsArray((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
        //formdataValue(name, value)
    };

    const [validatedCnClass, setValidatedCnClass] = useState(false);
    const [contactNumber, setContactNumber] = useState('')
    const [validated, setValidated] = useState(false);
    const onDirectorsDetailsSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        setValidatedCnClass(false);
        if (!contactNumber) { setValidatedCnClass(true) }
        if (form.checkValidity() === false) {
            event.preventDefault(); event.stopPropagation(); console.log('error');
        } else {
            console.log('not error');
            try {
                const userid = userdetails.unique_id;
                const formData = new FormData();
                formData.append('name_salute', event.target.name_salute.value);
                formData.append('first_name', event.target.first_name.value);
                formData.append('middle_name', event.target.middle_name.value);
                formData.append('last_name', event.target.last_name.value);
                formData.append('email_id', event.target.email_id.value);
                formData.append('contact_country_code', event.target.contact_country_code.value);
                formData.append('contact_number', event.target.contact_number.value);
                //formData.append('contact_number', contactNumber);
                formData.append('userid', userid);
                const response = await axios.post(
                    `https://squadgoo.com.au/api.squadgoo.com.au/directors_details_field_update`,
                    formData, { headers: { 'Content-Type': 'multipart/form-data' } });
                if (response.data.status == 1) {
                    getUserDetails(); setHideToggleSection3(true); toast.success(response.data.message);
                    if (userdetails.validationdata_check.directordetails_validation == 0) {
                        alert()
                        /*redux validation update */
                        dispatch(directorsDetailsValidation(1));
                        /*redux validation check end  */
                    };
                } else { toast.warning(response.data.message); }
            } catch (error) { console.error('Error uploading file:', error); }
            // handleNext()
        }
        setValidated(true);
    };

    return (
        <>
            <div className="dashboarwrap">
                <ProfileBanner />
                <div className="jobbtmsec py-5 admininfo">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3 profilesidebarwrap">
                                <ProfileSidebar />
                            </div>
                            <div className="col-md-9">
                                <div className="profilerightwraper stepscont commonform basicdetail">





                                    <div className="card-box work-experiencesec commonform">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <h3 className="mb-3">
                                                Director's contact details
                                                {
                                                    isAccVerified == true ?
                                                        <><img className='veri_unveri_icon' src="/front/images/vfy.png"></img></> :
                                                        isAccVerified == false ?
                                                            <><img className='veri_unveri_icon' src="/front/images/uvfy.png"></img></> :
                                                            <></>
                                                }
                                            </h3>
                                            {isAccVerified == false && <div className="editbtn" onClick={toggleHidden3} ><img src="/front/images/edit.png" /></div>}

                                        </div>
                                        <div className="editblock">
                                            <div className="editblockinner d-flex align-items-start">
                                                <h4>
                                                    <div><span className="mr-2">First name :</span><span className='text-capitalize' >{directorsArray.first_name ? directorsArray.first_name : '....'}</span></div>
                                                    <div><span className="mr-2">Middle name :</span><span className='text-capitalize' >{directorsArray.middle_name ? directorsArray.middle_name : '....'}</span></div>
                                                    <div><span className="mr-2">Last name:</span><span className='text-capitalize'>{directorsArray.last_name ? directorsArray.last_name : '....'}</span></div>
                                                    {/* <div><span className="mr-2">Date of birth</span><span>{userDetailsArray.date_of_birth ? userDetailsArray.date_of_birth : '......'}</span></div> */}

                                                    <div><span className="mr-2">Phone:</span><span className='text-capitalize' >{directorsArray.contact_number ? (directorsArray.contact_country_code ? '+' + directorsArray.contact_country_code + '-' : '') + directorsArray.contact_number : '....'}</span></div>
                                                    <div><span className="mr-2">E-mail:</span><span >{directorsArray.email_id ? directorsArray.email_id : '....'}</span></div>
                                                </h4>
                                            </div>
                                            {
                                                !hideToggleSection3 && <>
                                                    <div className="editblockinnerbtm">
                                                        <Form noValidate validated={validated} onSubmit={onDirectorsDetailsSubmit}>
                                                            <div className="row">
                                                                <div className="col-md-4">
                                                                    <div className="d-flex">
                                                                        <div className="form-group mr-2">
                                                                            <label className="form-label">Salute</label>
                                                                            <select className="form-control" name='name_salute' onChange={directorDetailsUpdatefun}>

                                                                                {/* <option value={'Mr.'}>Mr.</option>
                                                                                <option value={'Miss.'} selected={(directorsArray.name_salute == 'Miss.') ? true : false}>Miss.</option> */}
                                                                                {
                                                                                    ABBREVIATIONS.map((item) => (
                                                                                        <option selected={(directorsArray.name_salute == item.value) ? true : false} key={item.id} value={item.value}>{item.value}</option>
                                                                                    ))
                                                                                }

                                                                            </select>
                                                                        </div>
                                                                        <div className="form-group w-100">
                                                                            <label className="form-label">First name<span className="starr">*</span></label>
                                                                            <input type="text" required={true} className="form-control" maxlength="100" placeholder="Andrew" value={directorsArray.first_name} name='first_name' onChange={directorDetailsUpdatefun} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="form-group">
                                                                        <label className="form-label">Middle name</label>
                                                                        <input type="text" className="form-control" maxlength="100" onChange={directorDetailsUpdatefun} value={directorsArray.middle_name} name="middle_name" placeholder="Enter middle name" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="form-group">
                                                                        <label className="form-label">Last name<span className="starr">*</span></label>
                                                                        <input type="text" required={true} className="form-control" maxlength="100" onChange={directorDetailsUpdatefun} value={directorsArray.last_name} name="last_name" placeholder="Enderson" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="form-group mb-2">
                                                                        <label className="form-label">Phone<span className="starr">*</span></label>
                                                                        <div className="phonefield d-flex align-items-center">
                                                                            <select className="form-control" name="contact_country_code" >
                                                                                {
                                                                                    getAllCountry.length ?
                                                                                        <>
                                                                                            {
                                                                                                getAllCountry.map((item, index) => (
                                                                                                    <option selected={directorsArray.contact_country_code == item.phonecode ? true : false} value={item.phonecode}>+{item.phonecode}</option>
                                                                                                ))
                                                                                            }
                                                                                        </>
                                                                                        : ''
                                                                                }
                                                                            </select>
                                                                            <input type="tel" required={true} name="contact_number" className="form-control" placeholder="Enter mobile number" onChange={directorDetailsUpdatefun} maxlength="10" value={directorsArray.contact_number} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="form-group mb-2">
                                                                        <label className="form-label">E-mail<span className="starr">*</span></label>
                                                                        <div className="phonefield d-flex align-items-center">
                                                                            <input type="email" required={true} className="form-control" onChange={directorDetailsUpdatefun} placeholder="Enter e-mail address" maxlength="100" value={directorsArray.email_id} name='email_id' />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12 mt-3">
                                                                    <button className="batn batn-rounded rounded-orange mr-2">Save</button>
                                                                    <button onClick={(e) => toggleHidden3(true)} className="batn batn-rounded rounded-orange mr-2">Cancel</button>
                                                                </div>
                                                            </div>
                                                        </Form>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>






        </>
    );
}
export default DirectorsDetails;