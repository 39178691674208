import React from 'react';

// Define the YearSelector functional component
const MonthsNumberSelection = ({ startMonthName = '', selectedMonth = '' }) => {

    const Months = [
        { name: 'January', key: 1, abbreviation: 'Jan' },
        { name: 'February', key: 2, abbreviation: 'Feb' },
        { name: 'March', key: 3, abbreviation: 'Mar' },
        { name: 'April', key: 4, abbreviation: 'Apr' },
        { name: 'May', key: 5, abbreviation: 'May' },
        { name: 'June', key: 6, abbreviation: 'Jun' },
        { name: 'July', key: 7, abbreviation: 'Jul' },
        { name: 'August', key: 8, abbreviation: 'Aug' },
        { name: 'September', key: 9, abbreviation: 'Sep' },
        { name: 'October', key: 10, abbreviation: 'Oct' },
        { name: 'November', key: 11, abbreviation: 'Nov' },
        { name: 'December', key: 12, abbreviation: 'Dec' },
    ];
    const yearOptions = [];

    if (!startMonthName) {
        Months.map(month => (
            yearOptions.push(
                <option selected={selectedMonth == month.name ? true : false} key={month.key}>{month.name}</option>
            )
        ))
        return yearOptions;
    }

    const normalizedStartMonth = startMonthName.toLowerCase();
    const startIndex = Months.findIndex(month => month.name.toLowerCase() === normalizedStartMonth);
    // Slice the array from 'May' to the end
    const filteredMonths = Months.slice(startIndex);


    filteredMonths.map(month => (
        yearOptions.push(
            <option selected={selectedMonth == month.name ? true : false} key={month.key}>{month.name}</option>
        )
    ))
    return yearOptions;

};

export default MonthsNumberSelection;
