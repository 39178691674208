import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Header from './front/component/header.js';
import Footer from './front/component/footer';
import Home from './front/pages/home';
import ClearCache from './front/pages/ClearCache.js';
import AboutUs from './front/pages/AboutUs';
import Jobseeker from './front/pages/jobseeker';
import Recruiter from './front/pages/recruiter';
import ContactUs from './front/pages/ContactUs';
import TermsAndConditions from './front/pages/termsandconditions';
import PrivacyPolicy from './front/pages/privacypolicy';
import Disclaimers from './front/pages/disclaimers';


import Faq from './front/pages/FAQ.js';
import Login from './front/pages/login';
import Register from './front/pages/register';
import ForgotPassword from './front/pages/forgotpassword';
import ForgotConfirmPassword from './front/pages/ForgotConfirmPassword';
import ForgotEmailVerification from './front/pages/ForgotEmailVerification.js';
import Verification from './front/pages/verification';
import Varified from './front/pages/verified';
import Profile from './front/pages/profile';
import License from './front/pages/license';
import Tfn from './front/pages/tfn';
import EmailAndPhone from './front/pages/emailandphone';
import Accountsetup from './front/pages/accountsetup.js';
import Skills from './front/pages/skills';
import FindaStaff from './front/pages/findastaff';
import Notifications from './admin/notifications';
import Supportnew from './admin/supportnew.js';
import SearchResult from './front/pages/searchresult';
import ProfileNotCompleted from './front/pages/profilenotcompleted';
import PageNotFound from './front/pages/NotFound';
import GOOGLE_LOGIN from './front/pages/gLogin.js';
import ContactVerifications from './front/pages/ContactVerifications.js';
//import FindStaffSearch from './admin/findstaffsearch';
import RecruiterAccountSetting from './front/pages/recruiteraccountsetting';
//import RecruiterAccountsetup from './front/pages/recruiteraccountsetup';
//check this login or not  
import GoogleAutoSearch from './admin/AutoSearchComp.js';
// -------------------------jobseeker----------------------------------//
import Dashboard from './admin/jobseeker/dashboard';
import WorkExperience from './admin/jobseeker/workexperience';
// import Availability from './admin/jobseeker/availability';
import Locations from './admin/jobseeker/locations';
import Reviews from './admin/jobseeker/Reviews';
// import Salary from './admin/jobseeker/salary.js';
import BasicDetail from './admin/jobseeker/basicdetail';
import Address from './admin/jobseeker/address';
import ContactDeail from './admin/jobseeker/contactdetail';
import VisaDetail from './admin/jobseeker/visadetail';
import TaxInformation from './admin/jobseeker/taxinformation';
import EduandQualification from './admin/eduction_and_qualification';
import Jsrqualification from './admin/jsr_qualification.js';
import Jobwanttoapply from './admin/jobseeker/jobwanttoapply';
import Documents from './admin/jobseeker/document';
import ProfileSummary from './admin/profilesummary';
// import SocialMedia from './admin/jobseeker/socialmedia';
import JobseekerWallet from './admin/jobseeker/jobseekerwallet';
import JobseekerTransections from './admin/jobseeker/jobseekertransections';
import UserPasswordSetting from './admin/UserPasswordSetting.js';




// -------------------------recruiter----------------------------------//
// import RecruiterContactDetail from './admin/recruiter/recruitercontactdetail';
// import ChatwidgetList from './admin/recruiter/chat_widget_list';
import RecruiterBasicDetail from './admin/recruiter/recruiterbasicdetail';
import RecAddress from './admin/jobseeker/address';
import SendOfferCandidate from './admin/recruiter/sendoffer_candidate';
import CompanyDetail from './admin/recruiter/companydetail';
import CompanyAddress from './admin/recruiter/CompanyAddress';
import DirectorsDetails from './admin/recruiter/DirectorsDetails';
import RecruiterTaxInformation from './admin/recruiter/recruitertaxinformation';
import Subscription from './admin/recruiter/subscription';
import ManualSearch from './admin/recruiter/manualsearch';
import QucikSearch from './admin/recruiter/quicksearch';
import RecruiterDashboard from './admin/recruiter/recruiterdashboard';
import RecruiterWallet from './admin/recruiter/recruiterwallet';
import RecruiterTransections from './admin/recruiter/recruitertransections';
import RecWalletWithdraw from './admin/recruiter/recWalletWithdraw.js';
import Quicksearchdemo from './admin/recruiter/quicksearchdemo';




//import Subscription from './admin/subscription';


// -------------------------superadmin----------------------------------//
import Recruiters from './admin/superadmin/recruiters';
import Jobseekers from './admin/superadmin/jobseekers';
import JobseekerDetail from './admin/superadmin/jobseekerdetail';
import RecruiterDetail from './admin/superadmin/recruiterdetail';
import Support from './admin/superadmin/support';
import Accountsetting from './admin/superadmin/accountsetting';
import AdminDashboard from './admin/superadmin/admindashboard';
import SubscriptionListing from './admin/superadmin/subscriptionlisting';
import SubscriptionPackages from './admin/superadmin/subscriptionpackages';
import Offers from './admin/superadmin/offers';
import DeclinedJob from './admin/superadmin/DeclinedJob';
import ChatHistory from './admin/superadmin/chathistory';
// import ChatHistoryDetail from './admin/superadmin/chathistorydetail';
import Reports from './admin/superadmin/reports';
import Cms_faq from './admin/superadmin/cms_faq';
import SupportRequest from './admin/superadmin/support_request';
import LiveSuppot from './admin/superadmin/AdminLiveSuppot';
import Users from './admin/superadmin/users';
import Income from './admin/superadmin/income';
import Expenses from './admin/superadmin/expenses';
import PaymentHistory from './admin/superadmin/paymenthistory';
import AttendanceReport from './admin/superadmin/attendencereport';
import LiveView from './admin/superadmin/liveview';
import GrossReport from './admin/superadmin/grossreport';
import GeographicalReport from './admin/superadmin/geographicalreport';
import AdminReviewsSetting from './admin/superadmin/AdminReviewsSetting';
import CmsOptionsSettings from './admin/superadmin/CmsOptionsSettings';
import UsersChat from './admin/superadmin/UsersChat.js';
import AdminSupportCallHistory from './admin/superadmin/AdminSupportCallHistory.js';
import AdminWallet from './admin/superadmin/adminwallet';
import AdminWalletSetting from './admin/superadmin/adminwalletsetting';
import LeadCollectionComp from './admin/superadmin/LeadCollectionComp.js';
import PaymentRequest from './admin/superadmin/paymentRequest.js';

import DocumentSample from './admin/documentsample';





import TwilioVideoChat from './admin/TwilloVidiochat';
import TwilloVidiohatNew from './admin/TwilloVidiohatNew.js';



import Help from './admin/help';
import MyRequests from './admin/Myrequests.js';
import Chat from './admin/chat';
import SocialMediaSettings from './admin/SocialMediaSettings';
//import ProfileSummary1 from './admin/profilesummary';


/*-----------------------------------------*/
import PrivateComponent from './front/component/PrivateComponent';
import SuperAdmincom from './front/component/SuperadminAuth';
import RecruiterAuth from './front/component/RecruiterAuth';
import JobseekerAuth from './front/component/JobseekerAuth';
import LiveChatModulde from './admin/LiveVideoChatModule.js';
import LiveVoiceCallModule from './admin/LiveVoiceCallModule.js';
//import AdminReviewCall from './admin/superadmin/AdminSupportCallHistory.js';
/*-----------------------------------------*/
// import IndexDB from './front/component/IndexDb'; 

import TestExample from './TestExample.js';
import TestVideoApp from './admin/TestVideoApp.js';
import GoogleMapLocation from './admin/GoogleMapLocationComp.js';
import GoogleMapPathComp from './admin/GoogleMapPathComp.js';
// import PayemntProcess from './admin/PayemntProcess.js';

//import ImageUpload from './expocomp/ImageCropComp.js';


import { ApiDataContextProvider } from './admin/ApiDataContext.js';
function App() {

  const baseUrl = process.env.CURRENT_SITE_URL || 'http://localhost:3000';
  return (
    <>
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>SquadGoo</title>
          {/* Dynamic meta tag based on environment */}
          <meta name="description" content={`Your job portal website description. Include keywords related to full-time and hourly jobs for the labor class on ${baseUrl}`} />
          {/* Dynamic canonical URL */}
          <link rel="canonical" href={`${baseUrl}`} />
          {/* Dynamic Open Graph meta tags */}
          <meta property="og:title" content="Your Job Portal Website Title" />
          <meta property="og:description" content={`Your job portal website description. Include keywords related to full-time and hourly jobs for the labor class on ${baseUrl}`} />
          <meta property="og:url" content={`${baseUrl}`} />
          <meta property="og:image" content={`${baseUrl}/og-image.jpg`} />
          <meta property="og:type" content="website" />
          {/* Dynamic Twitter Card meta tags */}
          <meta name="twitter:title" content="Your Job Portal Website Title" />
          <meta name="twitter:description" content={`Your job portal website description. Include keywords related to full-time and hourly jobs for the labor class on ${baseUrl}`} />
          <meta name="twitter:image" content={`${baseUrl}/twitter-card-image.jpg`} />
          <meta name="twitter:card" content="summary_large_image" />
          {/* Favicon */}
          <link rel="icon" href={`${baseUrl}/favicon.ico`} type="image/x-icon" />
          <link rel="shortcut icon" href={`${baseUrl}/favicon.ico`} type="image/x-icon" />
        </Helmet>
      </div>
      <Router>
        {/* <IndexDB /> */}

        <ApiDataContextProvider>
          <Header />
          <div className="main" id="main_conatiner">
            <Routes >
              <Route exact path='/' element={<Home />} />
              {/* <Route exact path='/payment_process' element={<PayemntProcess />} /> */}
              <Route exact path='/testexample' element={<TestExample />} />


              {/* Login process */}
              <Route path='/gmlocation' element={<GoogleMapLocation />} />
              <Route path='/gmpath' element={<GoogleMapPathComp />} />
              <Route path='/testvideoapp' element={<TestVideoApp />} />
              <Route path='/login' element={<Login />} />
              <Route path='/clear' element={<ClearCache />} />
              <Route path='/forgot-password' element={<ForgotPassword />} />
              <Route path='/verification' element={<Verification />} />
              <Route path='/contact-verifications' element={<ContactVerifications />} />
              <Route path='/confirm-forgot-password' element={<ForgotConfirmPassword />} />
              {/* <Route path='/emailverify-forgot-password' element={<ForgotEmailVerification />} /> */}
              <Route path='/verified' element={<Varified />} />

              {/* signup form */}
              <Route path='/register' element={<Register />} />
              <Route path='/accountsetup' element={<Accountsetup />} />
              <Route path='/recruiter-accountsetup' element={<RecruiterAccountSetting usertype='recruiter'  />} />
              <Route path='/indivisual-accountsetup' element={<RecruiterAccountSetting usertype='indivisual'  />} />
              <Route path='/recruiter-accountsetting' element={<RecruiterAccountSetting usertype='recruiter' />} />

              {/* other details and cms */}
              <Route exact path='/aboutus' element={<AboutUs />} />
              <Route exact path='/jobseeker' element={<Jobseeker />} />
              <Route exact path='/recruiter' element={<Recruiter />} />
              <Route exact path='/terms-and-conditions' element={<TermsAndConditions />} />
              <Route exact path='/privacy-policy' element={<PrivacyPolicy />} />
              <Route exact path='/disclaimers' element={<Disclaimers />} />
              <Route exact path='/contactus' element={<ContactUs />} />
              <Route path='/help' element={<Supportnew />} />

              {/* testing  */}
              {/* <Route path='/imageupload' element={<ImageUpload />} /> */}

              <Route element={<PrivateComponent />}>
                <Route path='/notifications' element={<Notifications />} />
                <Route path='/chat' element={<Chat />} />
                <Route path='/profile' element={<Profile />} />
                <Route path='/license' element={<License />} />
                <Route path='/tfn-no' element={<Tfn />} />
                <Route path='/email-and-phone' element={<EmailAndPhone />} />
                <Route path='/skills' element={<Skills />} />
                <Route path='/my-requests' element={<MyRequests />} />
                <Route path='/glogin' element={<GOOGLE_LOGIN />} />

                <Route path='/google-autosearch' element={<GoogleAutoSearch />} />
                <Route exact path='/faq' element={<Faq />} />
                <Route path='/support' element={<Supportnew />} />
                <Route path='/search-results' element={<SearchResult />} />
                <Route path='/vidio_chat' element={<TwilioVideoChat />} />
                <Route path='/vidio_chat_new' element={<TwilloVidiohatNew />} />
                <Route path='/voicecall' element={<LiveVoiceCallModule />} />
                <Route path='/livevideochat' element={<LiveChatModulde />} />
                <Route path='/voicecall' element={<LiveVoiceCallModule />} />
                <Route path='/quicksearchdemo' element={<Quicksearchdemo />} />
                <Route path='/reviews' element={<Reviews />} />
                <Route path='/documents' element={<Documents />} />
                {/* --------------------jobseeker------------------------ */}
                <Route element={<JobseekerAuth />}>
                  <Route path='/jobseeker/dashboard' element={<Dashboard />} />
                  <Route path='/jobseeker/work-experience' element={<WorkExperience />} />
                  <Route path='/jobseeker/preferred-jobs' element={<Jobwanttoapply />} />
                  <Route path='/jobseeker/reviews' element={<Reviews />} />
                  <Route path='/jobseeker/address' element={<Address />} />
                  <Route path='/jobseeker/documents' element={<Documents />} />
                  <Route path='/jobseeker/wallet' element={<JobseekerWallet />} />
                  <Route path='/jobseeker/password-setting' element={<UserPasswordSetting />} />
                  <Route path='/jobseeker/social-media-settings' element={<SocialMediaSettings />} />
                  <Route path='/jobseeker/profile-summary' element={<ProfileSummary />} />
                  <Route path='/jobseeker/contact-details' element={<ContactDeail />} />
                  <Route path='/jobseeker/tax-information' element={<TaxInformation />} />
                  <Route path='/jobseeker/visa-detail' element={<VisaDetail />} />
                  <Route path='/jobseeker/basic-detail' element={<BasicDetail />} />
                  <Route path='/jobseeker/eductions' element={<EduandQualification />} />
                  <Route path='/jobseeker/qualification' element={<Jsrqualification />} />
                  <Route path='/jobseeker/transections' element={<JobseekerTransections />} />

                  <Route path='/dashboard' element={<Dashboard />} />
                  <Route path='/work-experience' element={<WorkExperience />} />
                  <Route path='/job-want-to-apply' element={<Jobwanttoapply />} />
                  {/* <Route path='/availability' element={<Availability />} /> */}
                  <Route path='/locations' element={<Locations />} />
                  {/* <Route path='/salary' element={<Salary />} /> */}
                  <Route path='/basic-detail' element={<BasicDetail />} />

                  <Route path='/contact-detail' element={<ContactDeail />} />
                  <Route path='/visa-detail' element={<VisaDetail />} />
                  <Route path='/tax-information' element={<TaxInformation />} />
                  <Route path='/eduction-and-qualification' element={<EduandQualification />} />
                  <Route path='/jsr-qualification' element={<Jsrqualification />} />


                  {/* <Route path='/social-media' element={<SocialMedia />} /> */}
                  <Route path='/jobseeker-transections' element={<JobseekerTransections />} />
                  <Route path='/profile-not-completed' element={<ProfileNotCompleted />} />
                </Route>
                {/* --------------------recruiter------------------------ */}
                <Route element={<RecruiterAuth />}>
                  <Route path='/recruiter/dashboard' element={<RecruiterDashboard />} />
                  <Route path='/recruiter/wallet' element={<RecruiterWallet callingby={'wallet'} />} />
                  <Route path='/recruiter/coinpurchase' element={<RecruiterWallet callingby={'coinpurchase'} />} />
                  <Route path='/recruiter/documents' element={<Documents />} />
                  <Route path='/recruiter/basic-detail' element={<RecruiterBasicDetail />} />
                  <Route path='/recruiter/address' element={<Address />} />
                  <Route path='/recruiter/contact-detail' element={<ContactDeail />} />
                  <Route path='/recruiter/tax-information' element={<RecruiterTaxInformation />} />
                  <Route path='/recruiter/directors-details' element={<DirectorsDetails />} />
                  <Route path='/recruiter/company-detail' element={<CompanyDetail />} />
                  <Route path='/recruiter/company-address' element={<CompanyAddress />} />
                  <Route path='/recruiter/password-setting' element={<UserPasswordSetting />} />
                  <Route path='/recruiter/social-media-settings' element={<SocialMediaSettings />} />
                  <Route path='/recruiter/profile-summary' element={<ProfileSummary />} />
                  <Route path='/recruiter/reviews' element={<Reviews />} />
                  <Route path='/recruiter/transections' element={<RecruiterTransections />} />

                  {/* <Route path='/recruiter-dashboard'  element={<RecruiterDashboard />} /> */}
                  <Route path='/sendoffer-candidate' element={<SendOfferCandidate />} />
                  <Route path='/company-detail' element={<CompanyDetail />} />
                  <Route path='/company-address' element={<CompanyAddress />} />
                  <Route path='/directors-details' element={<DirectorsDetails />} />
                  <Route path='/recruiter-basic-detail' element={<RecruiterBasicDetail />} />
                  <Route path='/recruiter-address' element={<RecAddress />} />
                  <Route path='/recruiter-contact-detail' element={<ContactDeail />} />
                  {/* <Route path='/recruiter-tax-information'  element={<RecruiterTaxInformation />} /> */}
                  <Route path='/recruiter-tax-information' element={<RecruiterTaxInformation />} />
                  <Route path='/find-a-staff' element={<FindaStaff />} />
                  <Route path='/manual-search' element={<ManualSearch />} />
                  <Route path='/quick-search' element={<QucikSearch />} />

                  {/* <Route path='/sendoffer_candidate'  element={<ChatwidgetList />} /> */}
                  {/* <Route path='/findstaffsearch'  element={<FindStaffSearch />} /> */}
                  {/* <Route path='/recruiter-contact-detail'  element={<RecruiterContactDetail />} /> */}
                </Route>
                {/* --------------------super admin------------------------ */}
                {/* UsersDetailsComp */}
                <Route element={<SuperAdmincom />}>
                  {/* <Route path='/admin/payment-request'  element={<PaymentRequest />} /> */}
                  <Route path='/subscription-packages' element={<SubscriptionPackages />} />
                  <Route path='/subscription-listing' element={<SubscriptionListing />} />
                  <Route path='/admin-dashboard' element={<AdminDashboard />} />
                  <Route path='/offers' element={<Offers />} />
                  <Route path='/declined-jobs' element={<DeclinedJob />} />
                  <Route path='/chat-history' element={<ChatHistory />} />
                  {/* <Route path='/chat-history-detail'  element={<ChatHistoryDetail />} /> */}
                  <Route path='/recruiters' element={<Recruiters />} />
                  <Route path='/jobseekers' element={<Jobseekers />} />
                  <Route path='/jobseeker-detail' element={<JobseekerDetail />} />
                  <Route path='/recruiter-detail' element={<RecruiterDetail />} />
                  <Route path='/subscription' element={<Subscription />} />
                  <Route path='/users' element={<Users />} />
                  <Route path='/account-setting' element={<Accountsetting />} />
                  <Route path='/reports' element={<Reports />} />
                  <Route path='/cms_faq' element={<Cms_faq />} />
                  <Route path='/admin-ticket-history' element={<SupportRequest />} />
                  <Route path='/admin-livechat-history' element={<LiveSuppot />} />
                  <Route path='/admin-call-history' element={<AdminSupportCallHistory />} />
                  <Route path='/income' element={<Income />} />
                  <Route path='/expenses' element={<Expenses />} />
                  <Route path='/payment-history' element={<PaymentHistory />} />
                  <Route path='/attendence-report' element={<AttendanceReport />} />
                  <Route path='/live-view' element={<LiveView />} />
                  <Route path='/gross-report' element={<GrossReport />} />
                  <Route path='/geographical-report' element={<GeographicalReport />} />
                  <Route path='/document-sample' element={<DocumentSample />} />
                  <Route path='/admin_all_reviews' element={<AdminReviewsSetting />} />
                  <Route path='/cms_options_settings' element={<CmsOptionsSettings />} />
                  <Route path='/admin_users_chat' element={<UsersChat />} />
                  <Route path='/admin-wallet' element={<AdminWallet />} />
                  <Route path='/admin-wallet-setting' element={<AdminWalletSetting />} />
                  <Route path='/lead-collection' element={<LeadCollectionComp />} />
                </Route>
                {/* not a dynamic */}

              </Route>

              <Route path="*" element={<PageNotFound />} />

            </Routes>
          </div>
          <Footer />
        </ApiDataContextProvider>
      </Router>

    </>
  );
}
export default App;