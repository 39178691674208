const Disclaimers = () => {
    return (
     <>
     <div className="mainpages">

      
        <div className="banner page-banner">
          <div className="container">
              <div className="row align-items-center">
                 <div className="col-md-6">
                    <div className="banner-left">
                        <h1>Disclaimers</h1>
                    </div>
                 </div>
                 <div className="col-md-6">
                   <img src="/front/images/privacy.png"></img>
                 </div>
              </div>
          </div>
        </div>


      

        <div className="trrmssec">
         
          <div className="container">
              <div className="row">
                 <div className="col-md-12">
                    <div className="banner-left">
                        
                    <h3 className="sectionsubhead">SQUAD GOO PTY LTD </h3> 
<p>If you require any more information or have any questions about our site's disclaimer, please feel free to contact us by email at <a href="mailto:ENQUIRY@SQUADGOO.COM.AU">ENQUIRY@SQUADGOO.COM.AU</a>.</p>

<h3 className="sectionsubhead">Disclaimers for SQUAD GOO</h3>
 
<p>All the information on this website - <a href="https://squadgoo.com.au">WWW.SQUADGOO.COM.AU</a> - is published in good faith and for general information purpose only. SQUAD GOO does not make any warranties about the completeness, reliability and accuracy of this information. Any action you take upon the information you find on this website (SQUAD GOO), is strictly at your own risk. SQUAD GOO will not be liable for any losses and/or damages in connection with the use of our website.</p> 
<p>From our website, you can visit other websites by following hyperlinks to such external sites. While we strive to provide only quality links to useful and ethical websites, we have no control over the content and nature of these sites. These links to other websites do not imply a recommendation for all the content found on these sites. Site owners and content may change without notice and may occur before we have the opportunity to remove a link which may have gone 'bad'.</p>
<p>Please be also aware that when you leave our website, other sites may have different privacy policies and terms which are beyond our control. Please be sure to check the Privacy Policies of these sites as well as their "Terms of Service" before engaging in any business or uploading any information.  </p>


<h3 className="sectionsubhead">Consent</h3> 
<p>By using our website, you hereby consent to our disclaimer and agree to its terms.</p> 
<h3 className="sectionsubhead">Update</h3>
<p>Should we update, amend or make any changes to this document, those changes will be prominently posted here. </p>

                    </div>
                 </div>
              </div>
          </div>
         
        </div>



       
        

          
        </div>

     </>
    );
  }
  export default Disclaimers;