import React, { useState, useEffect } from 'react';

const TaxInput = ({ onValuesChange, onOptionChange, defaultOption, defTaxValues, className }) => {
    const [selectedOption2, setSelectedOption2] = useState(defaultOption || 'tfn');
    const [values, setValues] = useState({
        tfn: defTaxValues.tfn,
        abn: defTaxValues.abn
    });

    const handleRadioChange = (e) => {
        const newOption = e.target.value;
        setSelectedOption2(newOption);

        if (onOptionChange) {
            onOptionChange(newOption);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (!/^\d*$/.test(value)) {
            return; // Ignore non-numeric input
        }

        if (name === 'tfn' && value.length > 9) {
            return; // Don't allow more than 9 characters for TFN
        }

        // Check for ABN length requirement
        if (name === 'abn' && value.length > 11) {
            return; // Don't allow more than 11 characters for ABN
        }


        const newValues = { ...values, [name]: value };
        setValues(newValues);

        // Call the parent callback to return updated values
        onValuesChange(newValues);
    };

    useEffect(() => {
        // Inform the parent about the current state when the selected option changes
        onValuesChange(values);
    }, [selectedOption2]); // Dependency array - call when selectedOption2 changes


    /*
    const texttype = userdetails.tax_type ? userdetails.tax_type.toLowerCase() : 'abn';
      const [tax_type_value , setTaxTypeValue] = useState(userdetails.tax_type_value ? userdetails.tax_type_value : '');
      const [tax_type_value2,setTaxTypeValue2] = useState(userdetails.tax_type_value2 ? userdetails.tax_type_value2 : '');

      const [selectedOption, setSelectedOption] = useState(texttype);
      const [taxValues, setTaxValues] = useState({
            tfn: texttype == 'both' ? tax_type_value  : texttype == 'tfn' ? tax_type_value : '',
            abn: texttype == 'both' ? tax_type_value2 : texttype == 'abn' ? tax_type_value : ''
      });

      const handleTaxValuesChange = (values) => { 
         setTaxValues(values); 
         if(selectedOption  ==  'both'){ setTaxTypeValue(values.tfn); setTaxTypeValue2(values.abn);}
         if(selectedOption  ==  'abn'){ setTaxTypeValue(values.abn); }
         if(selectedOption  ==  'tfn'){ setTaxTypeValue(values.abn);}
      };
      const handleOptionChange = (option) => { setSelectedOption(option); };
    
      <TaxInput onValuesChange={handleTaxValuesChange} onOptionChange={handleOptionChange} defaultOption={selectedOption}  defTaxValues={taxValues} className={'square-switch-field mr-0'} />

    */

    return (
        <>

            <div className="col-md-6">
                <div className="form-group mb-2 taxtype">
                    <label className="form-label">Tax type<span className="starr">*</span></label>
                    <div className={`switch-field ${className}`}>
                        <div className="switchfieldinner">
                            <input
                                type="radio"
                                id="recruiter"
                                name="tax_type"
                                value="tfn"
                                checked={selectedOption2 === 'tfn'}
                                onChange={handleRadioChange}
                            />
                            <label htmlFor="recruiter">TFN</label>
                        </div>
                        <div className="switchfieldinner">
                            <input
                                type="radio"
                                id="jobseeker"
                                name="tax_type"
                                value="abn"
                                checked={selectedOption2 === 'abn'}
                                onChange={handleRadioChange}
                            />
                            <label htmlFor="jobseeker">ABN</label>
                        </div>
                        <div className="switchfieldinner">
                            <input
                                type="radio"
                                id="bothtype"
                                name="tax_type"
                                value="both"
                                checked={selectedOption2 === 'both'}
                                onChange={handleRadioChange}
                            />
                            <label htmlFor="bothtype">BOTH</label>
                        </div>
                    </div>
                </div>
            </div>

            {selectedOption2 === 'tfn' && (
                <div className="col-md-6">
                    <div className="form-group mb-2">
                        <label className="form-label">TFN<span className="starr">*</span></label>
                        <input
                            type="text"
                            name="tfn"
                            value={values.tfn}
                            onChange={handleInputChange}
                            placeholder="Enter TFN number"
                            className={`form-control ${!values.tfn ? 'bordercss' : ''}`}
                        />
                    </div>
                </div>
            )}

            {selectedOption2 === 'abn' && (
                <div className="col-md-6">
                    <div className="form-group mb-2">
                        <label className="form-label">ABN<span className="starr">*</span></label>
                        <input
                            type="text"
                            name="abn"
                            value={values.abn}
                            onChange={handleInputChange}
                            placeholder="Enter ABN number"
                            className={`form-control ${!values.abn ? 'bordercss' : ''}`}
                        />
                    </div>
                </div>
            )}

            {selectedOption2 === 'both' && (
                <>
                    <div className="col-md-6">
                        <div className="form-group mb-2">
                            <label className="form-label">TFN<span className="starr">*</span></label>
                            <input
                                type="text"
                                name="tfn"
                                value={values.tfn}
                                onChange={handleInputChange}
                                className={`form-control ${!values.tfn ? 'bordercss' : ''}`}
                                placeholder="Enter TFN number"
                            />
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="form-group mb-2">

                            <label className="form-label">ABN<span className="starr">*</span></label>
                            <input
                                type="text"
                                name="abn"
                                value={values.abn}
                                onChange={handleInputChange}
                                className={`form-control ${!values.abn ? 'bordercss' : ''}`}
                                placeholder="Enter ABN number"
                            />
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default TaxInput;