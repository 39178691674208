import React, { useState, useEffect, Component } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

import SuperAdminSidebar from './superadmin_sidebar'
import axios from 'axios';

import { ToastContainer, toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';


import UserBasicDetails from './supcomp/UserBasicDetails';
import UserDocumentList from './supcomp/UserDocumentList';
//import UserSubscription from './supcomp/UserSubscription';
import JobSeekWorkExp from './supcomp/JobSeekWorkExp';
import JobSeekPrefferdJobs from './supcomp/JobSeekPrefferdJobs';
import UserProfileBanner from './supcomp/UserProfileBanner';
import UserChatHistory from './supcomp/UserChatHistory';
import UserChatCommunication from './supcomp/UserChatCommunication';
import JobSeekWorkHistory from './supcomp/JobSeekWorkHistory';
import AdminWallet from './supcomp/AdminWallet';


const JobseekerDetail = () => {

   const [currentActiveTab, setcurrentActiveTab] = useState('profile_tab');
   const hideAndShowTab = (tab) => {
      setcurrentActiveTab(tab)
   }

   return (
      <>
         <div className="dashboarwrap">

 

            <div className="adminwrap py-4">
               <div className="container">
                  <div className="row">
                     <div className="col-md-3 adminsidebar">
                        <SuperAdminSidebar></SuperAdminSidebar>
                     </div>
                      
                     <div className="col-md-9 admininfo ">
                        <div className="profilebanner adminjobseekerbanner box mb-3 bannerlinkabove">
                           <UserProfileBanner backcall={'jobseeker'} />
                        </div>

                        <nav className="jobseekertabs">
                           <div className="nav nav-tabs" id="nav-tab" role="tablist">
                              <button className={`${currentActiveTab == 'profile_tab' ? 'active' : ''} nav-link`} id="profile-tab" onClick={() => hideAndShowTab('profile_tab')} ><i className="fa fa-user-o mr-1" aria-hidden="true"></i> Profile</button>
                              <button className={`${currentActiveTab == 'workexp_tab' ? 'active' : ''} nav-link`} onClick={() => hideAndShowTab('workexp_tab')} id="workexp-tab"  ><i className="fa fa-briefcase mr-1" aria-hidden="true"></i> Experience</button>
                              <button className={`${currentActiveTab == 'Jobwanttoapply_tab' ? 'active' : ''} nav-link`} onClick={() => hideAndShowTab('Jobwanttoapply_tab')} id="Jobwanttoapply-tab" ><i className="fa fa-hand-pointer-o mr-1" aria-hidden="true"></i> Preferred jobs</button>
                              <button className={`${currentActiveTab == 'documentss_tab' ? 'active' : ''} nav-link`} id="documentss-tab" onClick={() => hideAndShowTab('documentss_tab')} ><i className="fa fa-file-text mr-1" aria-hidden="true"></i> Documents</button>
                              <button className={`${currentActiveTab == 'jobofferhistory_tab' ? 'active' : ''} nav-link`} onClick={() => hideAndShowTab('jobofferhistory_tab')} id="jobofferhistory-tab"><i className="fa fa-history mr-2" aria-hidden="true"></i>Work history</button>
                              <button className={`${currentActiveTab == 'chathistory_tab' ? 'active' : ''} nav-link`} onClick={() => hideAndShowTab('chathistory_tab')} id="chathistory-tab"><i className="fa fa-comment mr-2" aria-hidden="true"></i>Chats</button>
                              <button className={`${currentActiveTab == 'transecection_tab' ? 'active' : ''} nav-link`} onClick={() => hideAndShowTab('transecection_tab')} id="transecection-tab"><i className="fa fa-briefcase mr-2" aria-hidden="true"></i>Wallet</button>
                              {/* <button className={`${currentActiveTab == 'chatcommunication_tab' ? 'active' : ''} nav-link`} onClick={() => hideAndShowTab('chatcommunication_tab')} id="chathistory-tab"><i className="fa fa-comments-o" aria-hidden="true"></i></button> */}
                           </div>
                        </nav>
                        <div className="tab-content" id="nav-tabContent">
                           {currentActiveTab == 'profile_tab' && <div className="tab-pane1" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                              <UserBasicDetails />
                           </div>}
                           {currentActiveTab == 'workexp_tab' && <div className="tab-pane1" id="workexp" role="tabpanel" aria-labelledby="workexp-tab">
                              <JobSeekWorkExp />
                           </div>}
                           {currentActiveTab == 'Jobwanttoapply_tab' && <div className="tab-pane1" id="Jobwanttoapply" role="tabpanel" aria-labelledby="Jobwanttoapply-tab">
                              <JobSeekPrefferdJobs />
                           </div>}
                           {currentActiveTab == 'documentss_tab' && <div className="tab-pane1" id="documentss" role="tabpanel" aria-labelledby="documentss-tab">
                              <UserDocumentList />
                           </div>}
                           {currentActiveTab == 'jobofferhistory_tab' && <div className="tab-pane1" id="jobofferhistory" role="tabpanel" aria-labelledby="jobofferhistory-tab">
                              <JobSeekWorkHistory />
                           </div>}
                           {currentActiveTab == 'chathistory_tab' && <div className="tab-pane1" id="chathistory" role="tabpanel" aria-labelledby="chathistory-tab">
                              <UserChatHistory />
                           </div>}
 
                           {currentActiveTab == 'chatcommunication_tab' && <div className="tab-pane1" id="chatcommunication" role="tabpanel" aria-labelledby="chatcommunication-tab">
                              <UserChatCommunication />
                           </div>} 

                           {currentActiveTab == 'transecection_tab' && <div className="tab-pane1" id="transectiontab" role="tabpanel" aria-labelledby="transecection_tab">
                              <AdminWallet  user_type={3} />
                           </div>
                           } 
                        </div>

                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
}
export default JobseekerDetail;