import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink, useParams, useNavigate } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';

const ForgotPassword = () => {
  const [validated, setValidated] = useState(false);
  const [emailId, setEmailId] = useState(false);
  const [serverResMessage, setServerResMessage] = useState('');
  const [userUniqueId, setUserUniqueId] = useState('');
  const [encriptedEmail, setEncriptedEmail] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      const formData = new FormData();
      formData.append('email_id', emailId);
      axios.post('https://squadgoo.com.au/api.squadgoo.com.au/forgot_password', formData)
        .then((response) => {
          if (response.data.status == 1) {
            setUserUniqueId(response.data.data.unique_id);
            setEncriptedEmail(response.data.data.user_id);

            setSeconds(timeIntervalSecond);
            setTimeout(function () { setServerResMessageSuccess('') }, 3000);
            //unique_id
            //navigate('/confirm-forgot-password?id=' + response.data.data.user_id); 
          }
          else { setServerResMessage(response.data.message); setTimeout(function () { setServerResMessage('') }, 5000); }
        })
        .catch((error) => { console.error(error); });
    }
    setValidated(true);
  };



  /*---------------------------------------------------------------------------*/


  const [varificationCode, setVarificationCode] = useState('');
  //const [validated, setValidated] = useState(false);
  const [timeIntervalSecond, setTimeIntervalSecond] = useState(0);
  // const [serverResMessage, setServerResMessage] = useState('');
  const [serverResMessageSuccess, setServerResMessageSuccess] = useState('');

  const handleSubmit2 = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      const formData = new FormData();
      formData.append('unique_id', userUniqueId);
      formData.append('email_varification_code', varificationCode);
      axios.post('https://squadgoo.com.au/api.squadgoo.com.au/check_email_activation', formData)
        .then((response) => {
          if (response.data.status == 1) {
            const status = '';
            navigate('/confirm-forgot-password?id=' + encriptedEmail);

           
          } else { setServerResMessage(response.data.message); setTimeout(function () { setServerResMessage('') }, 3000); }
        })
        .catch((error) => { console.error(error); });
    }
    setValidated(true);
  };


  /*-------------------------------------------*/
  const resendVerificationCode = (callingtype, unique_id, intervalSecond) => {
    const formData = new FormData();
    formData.append('unique_id', unique_id ? unique_id : userUniqueId);
    formData.append('email_varification_code', varificationCode);
    axios.post('https://squadgoo.com.au/api.squadgoo.com.au/re_send_verification', formData)
      .then((response) => {
        //serverResMessageSuccess, setServerResMessageSuccess
        if (callingtype == 2) {
          setServerResMessageSuccess(response.data.message);
        }
        setSeconds(intervalSecond ? intervalSecond : timeIntervalSecond);
        setTimeout(function () { setServerResMessageSuccess('') }, 3000);
      })
      .catch((error) => { console.error(error); });
  }


  /*---------------------------------------------------------------*/
  const [seconds, setSeconds] = useState(timeIntervalSecond); // 300 seconds for 5 minutes
  const [isRunning, setIsRunning] = useState(true);
  useEffect(() => {
    let interval = null;
    if (isRunning && seconds > 0) {
      interval = setInterval(() => { setSeconds((prevSeconds) => prevSeconds - 1); }, 1000);
    } else if (!isRunning && interval !== null) { clearInterval(interval); }

    setTimeIntervalSecond(300);
    return () => clearInterval(interval); // Cleanup on component unmount
  }, [isRunning, seconds]);



  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };
  const styleComp = { fontSize: '13px', color: 'red' }

  return (
    <>
      <div className="loginsignupwrap">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="loginsignupwrapformwrap">
                {
                  !userUniqueId ?
                    <>
                      <Form noValidate validated={validated} onSubmit={handleSubmit} className="commonform lofinsignupform loginform">
                        <div className="formbranding text-center">
                          <img src="/front/images/formlogo.png"></img>
                        </div>
                        <h2 className="text-center">Reset your password</h2>
                        <div className="text-center formbtmtxt mb-4"><p>Enter the email address associated with your account and we will send you a link to reset your password.</p></div>
                        <div className="form-group mb-2">
                          <label className="form-label">Email Address</label>
                          <div className="iconfields">
                            <img className="fieldicons" src="/front/images/message.svg"></img>
                            <input
                              type="email"
                              className="form-control emailfield"
                              onChange={(event) => setEmailId(event.target.value)}
                              placeholder="Enter your Email"
                              required
                            />
                          </div>
                        </div>
                        {
                          serverResMessage ? <p className="text-danger text-center  mb-0">{serverResMessage}</p> : ''
                        }
                        <div className="form-group mb-2">
                          <input type="Submit" className="batn batn-orange" value="Continue"></input>
                        </div>

                        <div className="text-center formbtmtxt">
                          <p>Not a member yet   <NavLink to="/register" className="formlink">Join now</NavLink></p>
                        </div>
                      </Form>
                    </>
                    :
                    <>
                      <Form noValidate validated={validated} onSubmit={handleSubmit2} className="commonform lofinsignupform loginform">
                        <div className="formbranding text-center"><img src="/front/images/formlogo.png" /></div>
                        <div className="text-center my-4"><img src="/front/images/varifyicon.png" /></div>
                        <h2 className="text-center mb-2">Verify your email address</h2>
                        <div className="text-center formbtmtxt mb-4">
                          <p>We have sent a code on your email address</p>
                        </div>
                        <div className="form-group mb-2">
                          <input
                            required
                            type="text"
                            onChange={(event) => setVarificationCode(event.target.value)}
                            className="form-control verificationfield"
                            placeholder="Enter varification code"
                            style={{ paddingLeft: '8px' }}
                          />

                          {
                            (seconds > 0) ?
                              <><span style={styleComp}>{(seconds ? 'Expire will be: ' : 'Expired: ') + formatTime(seconds)}</span></> :
                              <><span className="text-orange font13px cursor_pointer" onClick={() => resendVerificationCode(2, userUniqueId, timeIntervalSecond)} >Re-send verification code</span></>
                          }
                        </div>
                        {serverResMessage ? <p className="alert alert-danger text-center  mb-0">{serverResMessage}</p> : ''}
                        {serverResMessageSuccess ? <p className="alert alert-success text-center  mb-0">{serverResMessageSuccess}</p> : ''}
                        <div className="form-group mb-2"><input type="Submit" className="batn batn-orange" value="Verify" /></div>
                        <div className="text-center formbtmtxt">
                          <p>Already have an account?  <NavLink className="" to="/login">Log In</NavLink>
                          </p>
                        </div>
                      </Form>
                    </>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ForgotPassword;