import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom'; // Ensure you have `react-router-dom` installed

/*
 const [isModalShow, setIsModalShow] = useState(false);
const actionConfirmed = () => { formSubmitData(); };
<ModalDialog
                                          show={isModalShow}
                                          onClose={() => setIsModalShow(false)} // Close the modal without taking action
                                          onConfirm={actionConfirmed} // Action to take when confirming
                                          title="Existing details are already verified, Are you sure to change it?"
                                          message="This may cause you to wait for re-verification from Squad Goo."
                                       />
*/
const ModalDialog = ({ show, onClose, onConfirm, title, message }) => (
    
  <Modal className="modal fade ask-jobdeclinedmodle" show={show} onHide={onClose}>
    <div className="modal-dialog1">
      <div className="modal-content">
        <div className="modal-body">
          <button type="button" className="btn-close" onClick={onClose}></button>
          <div style={{ textAlign: 'center', fontSize: '55px' }}>
            <i className="fa fa-warning text-danger"></i>
          </div>
          <h5 className="text-center mb-2">{title}</h5>
          <p className="text-center">{message}</p>
          <div className="btnsgroup text-center">
            <Link onClick={onConfirm} className="batn batn-orange">Yes</Link>
            <Link onClick={onClose} className="batn batn-orange batn-border">No</Link>
          </div>
        </div>
      </div>
    </div>
  </Modal>
);

export default ModalDialog;