import React, { useState, useEffect, Component } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useParams, useNavigate } from 'react-router-dom';
import { Form, Button, Col, InputGroup, Modal, Row } from 'react-bootstrap';
import axios from 'axios';
import ChatwidgetList from './chat_widget_list.js';
import { toast } from 'react-toastify';
import JsrProModalComp from './JsrProModalcom';
import StarRating from '../../expocomp/StarRatingComp';

const SendOfferCandidate = () => {
   const [staffSearchResult, setStaffSearchResult] = useState([]);
   const [searchJobtitle, setSearchJobtitle] = useState('.....');
   const [isViewDetailModalShow, isSetViewDetailModalShow] = useState(false);
   const [isModalDataLoad, setIsModalDataLoad] = useState(false);
   const [isDataLoading, setIsDataLoading] = useState(false);
   const [currentJobId, setCurrentJobId] = useState(false);
   const [staffViewDetailsDataArr, setstaffViewDetailsDataArr] = useState([]);
   const [staffViewDetailsDataLength, setstaffViewDetailsDataLength] = useState(0);
   const [totalPagesDraft, setTotalPagesDraft] = useState(1);
   const [currentActivePage, setCurrentActivePage] = useState(1);
   const [shortByCandidate, setShortByCandidate] = useState('all');
   const [screenType, setScreenType] = useState('active_job');
   const paginationLoadContentNo = 10;
   const shortByCandidateArray = ['all', 'not_sent', 'pending', 'accepted'];
   const useriddata = JSON.parse(localStorage.getItem("squadGologinSession"));
   const [isLoadingSendOffer, setIsLoadingSendOffer] = useState(false);
   const closeResumeModal = () => { setIsLoadingSendOffer(false); isSetViewDetailModalShow(false) };
   const [jobStatusType, setjobStatusType] = useState('pending');
   const [currentItemData, setCurrentItemData] = useState([]);
   const [currentFormID, setCurrentFormID] = useState(localStorage.getItem('finsStaffFormID'));

   const getCandidateData = (jobofferid, filterby, page) => {
      (async () => {
         setIsDataLoading(true);
         try {
            const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/get_candidate_by_jobid/" + useriddata.login_token + "/" + jobofferid + "/" + filterby + "?page=" + page);
            setIsDataLoading(false);
            if (resData.data.status == 1) {
               setStaffSearchResult(resData.data.data);
               setstaffViewDetailsDataLength(resData.data.length);
               const calculatedTotalPages = Math.ceil(resData.data.length / paginationLoadContentNo);
               setTotalPagesDraft(calculatedTotalPages);
            }
         } catch (error) { console.log(error); }
      })();
   }


   useEffect(() => {
      const queryParameters = new URLSearchParams(window.location.search)
      const jobofferid = queryParameters.get("offerid");
      const type = queryParameters.get("type");
      if (jobofferid) {
         setScreenType(type); setCurrentJobId(jobofferid); getCandidateData(jobofferid, shortByCandidate, 1);
      } else { setTimeout(window.location.replace("/recruiter/dashboard"), 300); }
   }, []);

   /*shortByPayData  form */
   const shortByPayData = (type) => { setShortByCandidate(type); getCandidateData(currentJobId, type, 1) }

   /*send offer details */

   const viewDetailModel = (id, stafdata, status, itemdata) => {
      setjobStatusType(status); setIsModalDataLoad(true); setIsLoadingSendOffer(false);
      const useriddata = JSON.parse(localStorage.getItem("squadGologinSession"));
      setCurrentFormID(itemdata.formid);
      //formid
      setCurrentItemData(itemdata);
      console.log(itemdata.staff_data.unique_id);
      (async () => {
         try {
            const formData = new FormData();
            formData.append('login_token', useriddata.login_token);
            formData.append('user_unique_id', useriddata.unique_id);
            formData.append('staff_id', itemdata.staff_data.unique_id);
            axios.post('https://squadgoo.com.au/api.squadgoo.com.au/findstaff_detail', formData)
               .then((response) => {
                  setIsModalDataLoad(false);
                  if (response.status == 200 && response.data.status == 1) {
                     setstaffViewDetailsDataArr(response.data.data);
                  }
               })
               .catch((error) => { console.error(error); });
         } catch (error) { console.log(error); setIsModalDataLoad(false); }

      })()
      isSetViewDetailModalShow(true);
   }


   const RenderDraftPaginationNumbers = () => {
      const paginationNumbers = [];
      paginationNumbers.push(<li className="page-item" ><Link onClick={(e) => handlePageChange(currentActivePage - 1)} className="page-link" href="#"><i className="fa fa-angle-left" aria-hidden="true"></i></Link>
      </li>)
      for (let i = 1; i <= totalPagesDraft; i++) {
         paginationNumbers.push(<li key={i} className={i === currentActivePage ? 'page-item active' : 'page-item'} ><Link onClick={() => handlePageChange(i)} className="page-link" href="#">{i}</Link></li>);
      }
      paginationNumbers.push(<li className="page-item" ><Link onClick={(e) => handlePageChange(currentActivePage + 1)} className="page-link" href="#"><i className="fa fa-angle-right" aria-hidden="true"></i></Link></li>)
      return paginationNumbers;
   };

   const handlePageChange = (newPage) => {
      if (newPage >= 1 && newPage <= totalPagesDraft) {
         setCurrentActivePage(newPage);
         getCandidateData(currentJobId, shortByCandidate, newPage);
      }
   };


   const sendOfferToStaffModel = (id, itemdata) => {
      setIsLoadingSendOffer(true);
      const useriddata = JSON.parse(localStorage.getItem("squadGologinSession"));
      try {
         const formData = new FormData();
         //const formData = new FormData();
         formData.append('user_unique_id', useriddata.unique_id);
         formData.append('filter_data', JSON.stringify([]));
         formData.append('send_offerdata', JSON.stringify(
            {
               'job_title': '',
               'candidate_first_name': currentItemData.staff_data.first_name,
               'candidate_last_name': currentItemData.staff_data.last_name
            }
         ));
         formData.append('staff_id', currentItemData.staff_data.unique_id);
         formData.append('current_form_id', currentFormID);
         formData.append('form_process_type', 'manually');
         //formData.append('job_location', (locationTags));
         axios.post('https://squadgoo.com.au/api.squadgoo.com.au/sendoffer_to_staff', formData)
            .then((response) => {
               setIsLoadingSendOffer(false);
               if (response.status == 200 && response.data.status == 1) {
                  isSetViewDetailModalShow(false);
                  toast.success(response.data.message);
                  getCandidateData(currentFormID, shortByCandidate, 1)
               } else {
                  toast.warning(response.data.message);
               }
            })
            .catch((error) => { console.error(error); });
      } catch (error) { console.log(error); }
   }

   const cancelOfferToStaffModel = (id, itemdata) => {
      setIsLoadingSendOffer(true);
      const useriddata = JSON.parse(localStorage.getItem("squadGologinSession"));
      try {
         const formData = new FormData();
         //const formData = new FormData();
         formData.append('user_unique_id', useriddata.unique_id);
         formData.append('filter_data', JSON.stringify([]));
         formData.append('send_offerdata', JSON.stringify(
            {
               'job_title': '',
               'candidate_first_name': currentItemData.staff_data.first_name,
               'candidate_last_name': currentItemData.staff_data.last_name
            }
         ));
         formData.append('staff_id', currentItemData.staff_data.unique_id);
         formData.append('current_form_id', currentFormID);
         formData.append('form_process_type', 'manually');
         //formData.append('job_location', (locationTags));
         axios.post('https://squadgoo.com.au/api.squadgoo.com.au/canceloffer_to_staff', formData)
            .then((response) => {
               setIsLoadingSendOffer(false);
               if (response.status == 200 && response.data.status == 1) {
                  isSetViewDetailModalShow(false);
                  toast.success(response.data.message);
                  getCandidateData(currentFormID, shortByCandidate, 1)
               } else {
                  toast.warning(response.data.message);
               }
            })
            .catch((error) => { console.error(error); });
      } catch (error) { console.log(error); }
   }


   const removeUnderscores = (str) => {
      return str.replace(/_/g, ' '); // Replace underscores with spaces
    };

   return (
      <>
         {/* <ToastContainer />*/}
         <div className="dashboarwrap">
            <div className="jobbanner">
               <div className="container">
                  <div className="row">
                     <div className="col-md-12">
                        <h2><span>Search result for</span> {searchJobtitle ? searchJobtitle : '.....'}</h2>
                        <form className="searchform d-flex">
                           <input type="text" className="form-control jobcategorysearch" placeholder="Search for staff here"></input>
                        </form>
                     </div>
                  </div>
               </div>
            </div>
            <div className="jobbtmsec py-4">
               <div className="container">
                  <div className="row">
                     <div className="col-md-3 sidebarwrap">
                        <ChatwidgetList />
                     </div>
                     <div className="col-md-9">
                        {
                           isDataLoading ?
                              <>
                                 <div className={`tab-pane mt45px`} >
                                    <div className='loader_container'>
                                       <p className='text-center'><img className='width-100 loader_image' src={'/front/images/loader.gif'} /></p>
                                    </div>
                                 </div>
                              </>
                              :
                              <>
                                 <div className="d-flex flex-wrap justify-content-between mb-2">

                                    <h3>
                                       <Link to={'/recruiter/dashboard?type=' + screenType} className='mr-3 batn batn-orange'><i className="fa fa-chevron-left" aria-hidden="true"></i> Back</Link>
                                       {<>{staffViewDetailsDataLength} Candidates</>} </h3>
                                    <div className="sort_option d-flex">
                                       <p>Sort by</p>
                                       <select className="form-control text-capitalize" onChange={(e) => shortByPayData(e.target.value)}>
                                          {
                                             shortByCandidateArray.map((item, index) => (
                                                <option selected={shortByCandidate == item} value={item}>{removeUnderscores(item)}</option>
                                             ))
                                          }

                                       </select>
                                    </div>
                                 </div>
                                 <div className="joblistingwrap">
                                    <div className="row">
                                       {
                                          staffSearchResult.length ?
                                             <>
                                                {
                                                   staffSearchResult.map((item, index) => (
                                                      <>

                                                         <div className="col-md-4 "  >
                                                            <div className="card-box joblistbox staflistw staflistwnew">
                                                               {
                                                                  item.status == 'not_sent' ?
                                                                     <>
                                                                        <div className="staffPprice_match ml-auto cursor_pointer" onClick={() => viewDetailModel(item.staff_data.unique_id, item.staff_data, item.offer_status, item)}>
                                                                           <h3 className="job_price">{item.expected_salary_hourly_minimum ? `$${item.expected_salary_hourly_minimum}.00` : ''}</h3>
                                                                           <div className="d-flex">
                                                                              <span className="matchbar"><span className={`matchbariner w${item.profile_match}`}></span></span>
                                                                              {item.profile_match}% matched
                                                                           </div>
                                                                        </div>
                                                                     </>
                                                                     :
                                                                     <>
                                                                        <div className="staffPprice_match ml-auto cursor_pointer" onClick={() => viewDetailModel(item.staff_data.unique_id, item.staff_data, item.offer_status, item)} >
                                                                           <h3 className={`job_price text-capitalize ${item.status == 'accepted' ? 'statusaccepted' : 'satuspending'}`}>{item.offer_status}</h3>
                                                                           {/* <div className="d-flex text-capitalize">{item.form_process_type}dd</div> */}
                                                                           <div className="d-flex">
                                                                              {
                                                                                 item.status == 'accepted' ?
                                                                                    <><Link className='candidate_chat_btn' to={`/chat?contactto=${item.staff_data.unique_id}`} ><i className="fa fa-comments-o" aria-hidden="true"></i></Link></>
                                                                                    :
                                                                                    <><span className="matchbar"><span className={`matchbariner w${item.profile_match}`}></span></span>{item.profile_match}% matched</>
                                                                              }

                                                                           </div>
                                                                        </div>
                                                                     </>
                                                               }




                                                               <div className="d-flex flex-wrap stafflisttop">
                                                                  <div className="staffimgg cursor_pointer" onClick={() => viewDetailModel(item.staff_data.unique_id, item.staff_data, item.offer_status, item)}><img src={`${item.staff_data.profilepic}`} /></div>
                                                                  <div className="staffnamerating">
                                                                     <h3 className='text-capitalize cursor_pointer' onClick={() => viewDetailModel(item.staff_data.unique_id, item.staff_data, item.offer_status, item)}>{item.staff_data.first_name + ' ' + item.staff_data.last_name}</h3>
                                                                     <div className="reting d-flex">
                                                                        <div className="stars d-flex">
                                                                           {
                                                                              console.log(item)
                                                                           }
                                                                           <StarRating rating={(typeof (item.total_rating_count) != 'undefined' && item.total_rating_count.average_rating != null) ? item.total_rating_count.average_rating : 0} />
                                                                        </div>
                                                                        <p>
                                                                           <Link className='m-0 cursor_pointer' to={`/reviews?uniqueid=${item.staff_data.unique_id}`} >
                                                                              {(typeof (item.total_rating_count) != 'undefined' && item.total_rating_count.average_rating != null) ? item.total_rating_count.average_rating : 0} |
                                                                              <span className='ml-2' >{item.total_rating_count.total_count} reviews</span>
                                                                           </Link>
                                                                        </p>
                                                                     </div>
                                                                     <div className="d-flex flex-wrap stafdata">
                                                                        <div className="icontxt">Location:
                                                                           <span className='text-capitalize'>
                                                                              {item.staff_data.prefered_location ? item.staff_data.prefered_location : '.......'}</span></div>
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                               {/* <p>Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Sed hendrerit. Maecenas nec odio et ante condimentum rhoncus, sem quam semper libero condimentum rhoncus, sem quam...<a href="#">Read More</a></p> */}


                                                            </div>
                                                         </div>
                                                      </>
                                                   ))
                                                }

                                             </>
                                             :
                                             <><div className="card-box joblistbox staflistw">{isDataLoading ? <>Loading......</> : <>Not Found</>} </div></>
                                       }
                                    </div>
                                 </div>
                                 {
                                    staffViewDetailsDataLength > paginationLoadContentNo ?
                                       <>
                                          <div className="card-box">
                                             <nav aria-label="Page navigation">
                                                <ul className="pagination mb-0 justify-content-center">
                                                   <RenderDraftPaginationNumbers />
                                                </ul>
                                             </nav>
                                          </div>
                                       </>
                                       : ''
                                 }
                              </>
                        }


                     </div>
                  </div>
               </div>
            </div>


         </div>

         {/* send offer to staff */}
         {isViewDetailModalShow && <Modal className="modal fade modallarg staffmodal jobmodle admininfo" show={isViewDetailModalShow} onHide={closeResumeModal}>
            <div className="modal-dialog1 viewdedatil_model">

               <div className="modal-content">
                  <div className="modal-body">
                     <button type="button" className="btn-close" onClick={closeResumeModal}></button>
                     <div className="joblistbox">
                        {/* jobseeker profile model */}
                        <JsrProModalComp data={staffViewDetailsDataArr} jobStatusType={jobStatusType} />
                        {/* {jobStatusType} */}
                           {
                           <div className="btnsgroup">
                              {(jobStatusType == 'not sent') ?
                              <>
                                 <button onClick={() => sendOfferToStaffModel(staffViewDetailsDataArr.unique_id, staffViewDetailsDataArr)} className="batn batn-orange" disabled={isLoadingSendOffer} >Send offer
                                    {isLoadingSendOffer ? <><img src={'/front/images/loading2.gif'} /></> : <></>}
                                 </button>
                                 <button onClick={closeResumeModal} className="batn batn-orange" >Cancel</button>
                              </>
                              :

                              (jobStatusType == 'pending') ?
                              <>
                                 <>
                                 <button onClick={() => cancelOfferToStaffModel(staffViewDetailsDataArr.unique_id, staffViewDetailsDataArr)} className="batn batn-orange" disabled={isLoadingSendOffer} >Cancel offer
                                    {isLoadingSendOffer ? <><img src={'/front/images/loading2.gif'} /></> : <></>}
                                 </button>
                                 </>
                              </>
                              :
                              <></>
                           }
                           </div>
                        }


                     </div>
                  </div>
                  <div className={`model_loader ${!isModalDataLoad ? 'display_none' : ''}`}>
                     <img src={'/front/images/loader.gif'} />
                  </div>
               </div>
            </div>
         </Modal>}
      </>
   );
}
export default SendOfferCandidate;
