import SuperAdminSidebar from './superadmin_sidebar'
const AttendanceReport = () => {
   return (
      <>
         <div className="dashboarwrap">

         <div className="pagebanner">
               <div className="container">
                  <div className="row">
                     <div className="col-md-12">
                        <h2>Attendence Report</h2>
                     </div>
                  </div>
               </div>
            </div>


            <div className="adminwrap py-4">
               <div className="container">
                  <div className="row">
                     <div className="col-md-3 adminsidebar">
                        <SuperAdminSidebar></SuperAdminSidebar>
                     </div>
                     <div className="col-md-9">
                        
                     <div className="row paymenthistory">
                        <div className="col-md-4 mb-4">
                           <div className="box ststicsbox d-flex justify-content-between coinbox Totalearning">
                              <div className="ststicsboxleft">
                                 <p>Total Jobs</p>
                                 <h2>200</h2>
                              </div>
                              <div className="ststicsboxright"><img src="/front/images/businessman.png"/></div>
                           </div>
                        </div>
                        <div className="col-md-4 mb-4">
                           <div className="box ststicsbox d-flex justify-content-between coinbox">
                              <div className="ststicsboxleft">
                                 <p>Quick Jobs</p>
                                 <h2>140</h2>
                              </div>
                              <div className="ststicsboxright"><img src="/front/images/headhunting.png"/></div>
                           </div>
                        </div>
                        <div className="col-md-4 mb-4">
                           <div className="box ststicsbox d-flex justify-content-between coinbox">
                              <div className="ststicsboxleft">
                                 <p>Mannual Jobs</p>
                                 <h2>60</h2>
                              </div>
                              <div className="ststicsboxright"><img src="/front/images/recruitmentdf.png"/></div>
                           </div>
                        </div>
                        <div className="col-md-4 mb-4">
                           <div className="box ststicsbox d-flex justify-content-between coinbox">
                              <div className="ststicsboxleft">
                                 <p>Accepted Jobs</p>
                                 <h2>70</h2>
                              </div>
                              <div className="ststicsboxright"><img src="/front/images/hire.png"/></div>
                           </div>
                        </div>
                        <div className="col-md-4 mb-4">
                           <div className="box ststicsbox d-flex justify-content-between coinbox">
                              <div className="ststicsboxleft">
                              <p>Rejected Jobs</p>
                                 <h2>56</h2>
                              </div>
                              <div className="ststicsboxright"><img src="/front/images/rejected.png"/></div>
                           </div>
                        </div>
                        <div className="col-md-4 mb-4">
                           <div className="box ststicsbox d-flex justify-content-between coinbox">
                              <div className="ststicsboxleft">
                              <p>Pending Jobs</p>
                                 <h2>73</h2>
                              </div>
                              <div className="ststicsboxright"><img src="/front/images/time-management.png"/></div>
                           </div>
                        </div>
                     </div>

                     <div className="row">
                    

                        <div className="col-md-12">
                           <div className="box mb-4">
                                 <div className="box-ttl mb-3 d-flex align-items-center justify-content-between">    
                                       <h3>Total Jobs</h3>
                                       
                                       <div className=""> 
                                       <div className="switch-field mr-0 ml-3">
                                       <div className="switchfieldinner">
                                       <input type="radio" id="monthly" name="yearwitch"/>
                                       <label htmlFor="monthly">Monthly</label>
                                       </div>
                                       <div className="switchfieldinner">
                                       <input type="radio" id="quaterly" name="yearwitch"/>
                                       <label htmlFor="quaterly">Quaterly</label>
                                       </div>
                                       <div className="switchfieldinner">
                                       <input type="radio" id="yearly" name="yearwitch"/>
                                       <label htmlFor="yearly">Yearly</label>
                                       </div>
                                       </div>
                                       </div>
                                 </div>
                                 <img src="/front/images/graph.jpg" className="w-100"></img>
                           </div>
                        </div>

                     </div>


                     <div className="row">
                        <div className="col-md-12">
                           <div className="box mb-4">
                              <div className="table_filter mb-2 d-flex align-items-center justify-content-between commonform">
                                 <div className="tblsearch"><input type="text" className="form-control" placeholder="Search" fdprocessedid="61naqt"/></div>
                                 <div className="tblrightfilters d-flex align-items-center">
                                    <select className="form-control" fdprocessedid="57d4zre">
                                    <option>All</option>
                                       <option>Active</option>
                                       <option>Rejected</option>
                                       <option>Pending</option>
                                    </select>
                                    <select className="form-control ml-3" fdprocessedid="q0h1yu">
                                       <option>Date</option>
                                       <option>Monthly</option>
                                       <option>Quaterly</option>
                                       <option>Yearly</option>
                                    </select>
                                 </div>
                              </div>
                              <div className="table-responsive">
                                 <table className="table admintbl">
                                    <thead>
                                       <tr>
                                          <th>Recruiter Name</th>
                                          <th>Recruiter Email</th>
                                          <th>JOb Title</th>
                                          <th>Mode of Jobsearch</th>
                                          <th>Date of Offer Sent</th>
                                          <th>Staus</th>
                                          
                                       </tr>
                                    </thead>
                                    <tbody>
                                       <tr>
                                         
                                          <td><a>Daniel Marten</a></td>
                                          <td>daniel@yopmail.com</td>
                                          <td><a>Required a carpenter</a></td>
                                          <td>Mannual</td>
                                          <td>22 November 2022</td>
                                          <td>Active</td>
                                       </tr>
                                        <tr>
                                         
                                          <td><a>Daniel Marten</a></td>
                                          <td>daniel@yopmail.com</td>
                                          <td><a>Required a carpenter</a></td>
                                          <td>Mannual</td>
                                          <td>22 November 2022</td>
                                          <td>Active</td>
                                       </tr>
                                        <tr>
                                         
                                          <td><a>Daniel Marten</a></td>
                                          <td>daniel@yopmail.com</td>
                                          <td><a>Required a carpenter</a></td>
                                          <td>Mannual</td>
                                          <td>22 November 2022</td>
                                          <td>Active</td>
                                       </tr>
                                        <tr>
                                         
                                          <td><a>Daniel Marten</a></td>
                                          <td>daniel@yopmail.com</td>
                                          <td><a>Required a carpenter</a></td>
                                          <td>Mannual</td>
                                          <td>22 November 2022</td>
                                          <td>Active</td>
                                       </tr>
                                        <tr>
                                         
                                          <td><a>Daniel Marten</a></td>
                                          <td>daniel@yopmail.com</td>
                                          <td><a>Required a carpenter</a></td>
                                          <td>Mannual</td>
                                          <td>22 November 2022</td>
                                          <td>Active</td>
                                       </tr>
                                        <tr>
                                         
                                          <td><a>Daniel Marten</a></td>
                                          <td>daniel@yopmail.com</td>
                                          <td><a>Required a carpenter</a></td>
                                          <td>Mannual</td>
                                          <td>22 November 2022</td>
                                          <td>Active</td>
                                       </tr>
                                        <tr>
                                         
                                          <td><a>Daniel Marten</a></td>
                                          <td>daniel@yopmail.com</td>
                                          <td><a>Required a carpenter</a></td>
                                          <td>Mannual</td>
                                          <td>22 November 2022</td>
                                          <td>Active</td>
                                       </tr>
                                       
                                       
                                    </tbody>
                                 </table>
                                 <div className="card-box">
                                    <nav aria-label="Page navigation">
                                       <ul className="pagination mb-0 justify-content-center">
                                          <li className="page-item"><a className="page-link" href="#"><i className="fa fa-angle-left" aria-hidden="true"></i></a></li>
                                          <li className="page-item active"><a className="page-link" href="#">1</a></li>
                                          <li className="page-item"><a className="page-link" href="#">2</a></li>
                                          <li className="page-item"><a className="page-link" href="#"><i className="fa fa-angle-right" aria-hidden="true"></i></a></li>
                                       </ul>
                                    </nav>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>


                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
}
export default AttendanceReport;