import React, { useState, useEffect, useRef, Component } from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink, useParams, useNavigate, Link } from 'react-router-dom';

//import { toast, ToastContainer } from 'react-toastify';
////import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

import { Howl } from 'howler';
// Import the sound file
import beepSound from '../soundlib/notification_sound.mp3';
const LiveChatWindow = (prop) => {

    const userData = JSON.parse(localStorage.getItem("squadGologinSession"));
    const sessionStorageData = JSON.parse(localStorage.getItem("sessionStorageData"));
    const liveChatTokenID = prop.livechataction;
    const [errorMessage, setErrorMessage] = useState('');
    //  console.log(prop.livechataction)
    // console.log(typeof(liveChatTokenID));

    // const [liveChatTokenID, setliveChatTokenID] = useState(prop.livechataction);
    const [smileFaceOptions, setsmileFaceOptions] = useState(false);
    const [isCahtInit, setisCahtInit] = useState(liveChatTokenID ? true : false);
    const [chatInitStatus, setchatInitStatus] = useState(liveChatTokenID ? 2 : 1);
    const [sendMessageText, setsendMessageText] = useState('');
    //    / const [isCahtInit, setisCahtInit] = useState(false);

    const [ScreenLoader, setScreenLoader] = useState(false);

    //console.log(sessionStorageData.get_livesupport_optiondd)
    const jobSupprtOption = typeof (sessionStorageData.get_livesupport_option) != undefined ? sessionStorageData.get_livesupport_option : [
        {
            "id": 1,
            "key_name": "technical",
            "label": "Technical Support",
            "description": "Assistance with technical issues related to using the job portal. Help with navigating the website or app. Troubleshooting problems with account settings or features."
        },
        {
            "id": 2,
            "key_name": "account",
            "label": "Account Support",
            "description": "Help with creating or updating a user account. Assistance with login issues or password resets. Questions about account security."
        },
        {
            "id": 3,
            "key_name": "jobApplication",
            "label": "Job Application Support",
            "description": "Guidance on how to apply for jobs on the platform. Assistance with submitting resumes or other application materials. Clarification on job listing details."
        },
        {
            "id": 4,
            "key_name": "payment",
            "label": "Payment or Subscription Support",
            "description": "Help with payment-related issues for premium services or subscriptions. Assistance with billing inquiries."
        },
        {
            "id": 5,
            "key_name": "feedback",
            "label": "Feedback and Suggestions",
            "description": "A category for users to provide feedback on the platform or suggest improvements."
        },
        {
            "id": 6,
            "key_name": "abuse",
            "label": "Abuse or Violation Reporting",
            "description": "Reporting inappropriate content, behavior, or job postings."
        },
        {
            "id": 7,
            "key_name": "general",
            "label": "General Inquiry",
            "description": "A catch-all category for questions that don't fit into the above categories."
        }
    ];

    //console.log(livesupportoption);
    const sendLiveChatNotification = (supportFor) => {
        (async () => {
            try {
                const resData = await axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/livesupport_send_notification/${userData.login_token}/${supportFor}`);
                if (resData.data.status == 1) {
                    localStorage.setItem('liveChatTokenID', JSON.stringify(resData.data.data.tokenid))
                }
            } catch (error) { console.log(error); }
        })();
    }


    const getChatSuppotyType = (supportFor) => {
        setisCahtInit(true);
        setchatInitStatus(2);
        sendLiveChatNotification(supportFor);
    }


    const [attachment, setAttachment] = useState(null);
    const [capturedImage, setcapturedImage] = useState(null);
    const [getChatMessgaesData, setgetChatMessgaesData] = useState([]);
    const [currenUserData, setcurrenUserData] = useState({
        first_name: '',
        last_name: '',
    });

    const wanToCloseConversation = (message) => {
        sendMessageToTeam('do_you_want_to_close_conversation')
    }
    const sendMessageToTeam = (message) => {
        if (isCahtInit == true) {

            setsmileFaceOptions(false);
            const formData = new FormData();
            formData.append('message', message);
            formData.append('login_token', userData.login_token);
            formData.append('live_chat_token', liveChatTokenID);
            if (attachment) { formData.append('attachment', attachment); }
            if (capturedImage) { formData.append('attachment', capturedImage); }
            axios.post('https://squadgoo.com.au/api.squadgoo.com.au/livesupport_message_send',
                formData,
                { headers: { 'Content-Type': 'multipart/form-data' } }
            )
                .then((response) => {


                    if (response.status == 200 && response.data.status == 1) {
                        setchatInitStatus(3);
                        setsendMessageText('');
                        setgetChatMessgaesData(response.data.data.chatdata);
                    }

                    if (response.status == 200 && response.data.status == 2) {
                        localStorage.removeItem('liveChatTokenID');
                        setisCahtInit(false);
                        setchatInitStatus(1);
                        setErrorMessage('Please select support type!');
                        setTimeout(setErrorMessage(''), 3000);
                    }
                })
                .catch((error) => { console.error(error); });
        } else {
            alert('Please select following select options')
        }
    }

    const playBeep = () => {
        const sound = new Howl({
            src: [beepSound],
        });
        sound.play();
    };


    const get_faq = (apiurl, type = 'onload') => {
        //setScreenLoader(true);
        (async () => {
            try {
                const resData = await axios.get(apiurl);
                if (resData.data.status == 1)
                    setchatInitStatus(3); {

                    if(resData.data.data.total_unread != 0){
                        playBeep();
                    }
                    // console.log(resData.data.data.userdata)
                    setgetChatMessgaesData(resData.data.data.chatdata);
                    setcurrenUserData(resData.data.data.userdata);
                }
            } catch (error) { console.log(error); }
        })();
    }


    

    useEffect(() => {
        if (liveChatTokenID) {
            get_faq(`https://squadgoo.com.au/api.squadgoo.com.au/livesupport_get_messages/${userData.login_token}/${liveChatTokenID}`);

            const intervalId = setInterval(() => {
                get_faq(`https://squadgoo.com.au/api.squadgoo.com.au/livesupport_get_messages/${userData.login_token}/${liveChatTokenID}`); 
             }, 5000);
         
            return () => {
                clearInterval(intervalId);
            };
        }
    }, []);

    
    /*---------------component call------------------*/

    const handleInputChange = (inputText) => {
        //const inputText = event.target.value;

        // Regular expressions to match email addresses and phone numbers
        const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,6}\b/g;
        const phoneRegex = /\b\d{10}\b/g;

        // Function to replace matched text with hyperlinks
        const replaceEmailsAndPhones = (text) => {
            const textWithEmailLinks = text.replace(emailRegex, (match) => {
                return `<a href="mailto:${match}">${match}</a>`;
            });

            const textWithPhoneLinks = textWithEmailLinks.replace(phoneRegex, (match) => {
                return `<a href="tel:${match}">${match}</a>`;
            });

            return textWithPhoneLinks;
        };

        const transformedMessage = replaceEmailsAndPhones(inputText);

        return (transformedMessage);
    };
    const CheckFileType = (data) => {
        //console.log(data.data)
        return (
            <>
                {
                    data.data.extenstion == 'pdf' ?
                        <>
                            <a target='_blank' href={'https://squadgoo.com.au/api.squadgoo.com.au/' + data.data.attachment_url} >
                                <img className='chat_row_attachment' key={data.data.id} src={`/front/images/pdfimage.png`} />
                            </a>
                        </>
                        :
                        <>
                            <img className='chat_row_attachment' key={data.data.id} src={'https://squadgoo.com.au/api.squadgoo.com.au/' + data.data.attachment_url}></img>
                        </>
                }
            </>
        )
    }

    return (<>
        {

            (userData) != null &&

            <>
                <span className="conversation_close text-center cursor_pointer" onClick={() => wanToCloseConversation()} >
                    End Chat
                </span>
                {currenUserData.first_name && <p className='chatheadername text-capitalize'>( {currenUserData.first_name + ' ' + currenUserData.last_name} )</p>}
                <div className="livechat_message_body card-body perfect-scrollbar ps ps--active-y" data-mdb-perfect-scrollbar="true">
                    {
                        chatInitStatus == 3 && getChatMessgaesData.length ?
                            <>
                                {
                                    getChatMessgaesData.map((item, index) => (
                                        <>
                                            <div className="chatday text-center"><span className="line"></span><span>{item.daydate}</span><span className="line"></span></div>
                                            {
                                                item.data.map((itemdata, indexdata) => (
                                                    <>
                                                        <div key={itemdata.id} className={`chatlinw  ${itemdata.send_by} ${item.sender_id != itemdata.send_by ? 'client' : ''}`}>
                                                            {
                                                                itemdata.delete_status == 1 ?
                                                                    <>
                                                                        <div className={`chatmsg mt-1 ${item.sender_id != itemdata.send_by ? 'text-1' : 'text-white'}`}>
                                                                            <i>This message deleted by you on {itemdata.delete_status_date}</i>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {/* <div className="chattime">{itemdata.time}</div> */}
                                                                        <div className="chatmsg mb-1">
                                                                            <div className='message_contiainer'>
                                                                                {
                                                                                    (itemdata.attachment && itemdata.attachment.length) ?
                                                                                        <>
                                                                                            {
                                                                                                itemdata.attachment.map((itemattach, indexattach) => (
                                                                                                    <>
                                                                                                        <CheckFileType data={itemattach} ></CheckFileType>
                                                                                                    </>
                                                                                                ))
                                                                                            }
                                                                                        </>
                                                                                        :
                                                                                        <></>
                                                                                }
                                                                                <div className={`${item.sender_id != itemdata.send_by ? 'text-1' : 'text-white'}`} dangerouslySetInnerHTML={{ __html: handleInputChange(itemdata.message).replace(/\n/g, '<br>') }} />

                                                                            </div>
                                                                        </div>
                                                                    </>
                                                            }
                                                        </div>
                                                    </>
                                                ))
                                            }

                                        </>
                                    ))
                                }
                            </>
                            :
                            <>
                            </>
                    }


                    {smileFaceOptions && <div className="facecard">
                        <div className="faceratings">
                            <Link onClick={(e) => sendMessageToTeam('<img src="/front/images/face1.png" />')} href="#"><img src="/front/images/face1.png" /></Link>
                            <Link onClick={(e) => sendMessageToTeam('<img src="/front/images/face2.png" />')} href="#"><img src="/front/images/face2.png" /></Link>
                            <Link onClick={(e) => sendMessageToTeam('<img src="/front/images/face3.png" />')} href="#"><img src="/front/images/face3.png" /></Link>
                            <Link onClick={(e) => sendMessageToTeam('<img src="/front/images/face4.png" />')} href="#"><img src="/front/images/face4.png" /></Link>
                            <Link onClick={(e) => sendMessageToTeam('<img src="/front/images/face5.png" />')} href="#"><img src="/front/images/face5.png" /></Link>
                        </div>
                    </div>}

                </div>

                {/* front/images/user.svg */}
                <div className="card-footer text-muted d-flex justify-content-start align-items-center p-3">
                    
                    <img src={userData.profilepic == null ? '/front/images/user.svg' : 'https://squadgoo.com.au/api.squadgoo.com.au/' + userData.profilepic} alt="avatar 3" className="chatavatar" />
                    <input onChange={(e) => setsendMessageText(e.target.value)} type="text" value={sendMessageText} className="form-control form-control-lg" id="exampleFormControlInput1" placeholder="Type message" fdprocessedid="jq0r4b" />
                    {
                        isCahtInit &&
                        <> 
                            {/* <span onClick={() => setsmileFaceOptions(!smileFaceOptions)} className="cursor_pointer ms-3 text-muted" href="#!"><i className="fa fa-paperclip" aria-hidden="true"></i></span> */}
                            <span onClick={() => setsmileFaceOptions(!smileFaceOptions)} className="cursor_pointer ms-3 text-muted " href="#!"><i className="fa fa-smile-o" aria-hidden="true"></i></span>
                        </>
                    }
                    <span className="ms-3 cursor_pointer" onClick={() => sendMessageToTeam(sendMessageText)} ><i className="fa fa-paper-plane" aria-hidden="true"></i></span>
                </div>
            </>

        }
    </>)

};

export default LiveChatWindow;
