import React, { useState, useEffect, Component } from 'react';
import axios from 'axios';
// import { isVisible } from '@testing-library/user-event/dist/utils';
import { Form, Button, Col, InputGroup, Modal, Row } from 'react-bootstrap';

import SuperAdminSidebar from './superadmin_sidebar'
import { Link } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';
const AdminReviewSettings = () => {

    const useriddata = JSON.parse(localStorage.getItem("squadGologinSession"));
    const [myReviewDataArr, setmyReviewDataArr] = useState([]);
    const [myReviewDataArrCopy, setmyReviewDataArrCopy] = useState([]);
    const [screenLoader, setScreenLoader] = useState(true);
    const [searchText, setSearchText] = useState('');
    const [searchSendByText, setSearchSendByText] = useState('');
    const [searchSendToText, setSearchSendToText] = useState('');
    const [searchFilterStatus, setsearchFilterStatus] = useState('all');
    const [searchFilterRating, setsearchFilterRating] = useState('all');
    const [currentRatingId, setcurrentRatingId] = useState(0);



    const byRatingData = [
        { key: 'all', value: 'By Rating' },
        { key: 1, value: 1 },
        { key: 2, value: 2 },
        { key: 3, value: 3 },
        { key: 4, value: 4 },
        { key: 5, value: 5 },
    ];

    const recTypeBlockUnBlock = [
        { key: 'all', value: 'Status' },
        { key: 0, value: 'Report' },
        { key: 1, value: 'Published' },
    ];

    const userdetails = JSON.parse(localStorage.getItem("squadGologinSession"));
    const [moduleAccessibility] = useState(typeof (userdetails.accessibility) != undefined ? userdetails.accessibility.reviews : [{
        view: true,
        edit: true,
        delete: true,
    }]);
    const viewAccessibility = (moduleAccessibility != undefined && typeof (moduleAccessibility.view) != undefined) ? moduleAccessibility.view : true;
    const editAccessibility = (moduleAccessibility != undefined && typeof (moduleAccessibility.edit) != undefined) ? moduleAccessibility.edit : true;
    const deleteAccessibility = (moduleAccessibility != undefined && typeof (moduleAccessibility.delete) != undefined) ? moduleAccessibility.delete : true;



    const [ratingModelShow, setRatingModelShow] = useState(false);
    const [backto, setbackto] = useState(false);
    const [getActiveUser, setgetActiveUser] = useState(false);
    const [tableOrderType, settableOrderType] = useState('ASC');

    const get_Revieweddata = (userid) => {
        (async () => {
            try {
                const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/get_allreview_admin/" + useriddata.login_token + '?sendfor_userid=' + userid + '&search=' + searchText + '&is_publised=' + searchFilterStatus + '&rating=' + searchFilterRating + '&send_by_search=' + searchSendByText + '&send_to_search=' + searchSendToText+'&orderby='+tableOrderType);
                if (resData.data.status == 1) {
                    setmyReviewDataArr(resData.data.data);
                    setmyReviewDataArrCopy(resData.data.data);
                    setScreenLoader(false);
                    console.log('caling....')
                }
            } catch (error) { console.log(error); }
        })();
    }

    useEffect(() => {
        if (useriddata) {

            const queryParameters = new URLSearchParams(window.location.search);
            const review_id = queryParameters.get("review_id");
            const userid = queryParameters.get("userid");
            setbackto(queryParameters.get("backto"));
            if (review_id) {
                setcurrentRatingId(review_id);
            }

            if (userid) {
                setgetActiveUser(userid);
                get_Revieweddata(userid);
            } else {
                get_Revieweddata(0);
            }

            console.log('1');
        }
    }, [searchText, searchFilterStatus, searchFilterRating, searchSendByText, searchSendToText , tableOrderType]);

    //showRatingReviwDetails

    const StarRating = ({ rating }) => {
        const stars = [1, 2, 3, 4, 5];
        return (
            <>
                {
                    stars.map((star) => (
                        <span
                            key={star}
                            className={star <= rating ? 'star font-14px active' : 'star font-14px'}
                        >
                            ★
                        </span>

                    ))
                }
            </>
        )
    }

    /*----------delete--------*/
    const [isBlockModelShow, setIsBlockModelShow] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const [deletItemID, setdeletItemID] = useState(0);
    const deleteConvesation = (data) => {
        setdeletItemID(data.id);
        setIsBlockModelShow(data.id);
    }
    const wantToBlockData = () => {
        (async () => {
            setBtnLoading(true)
            try {
                const formData = new FormData();
                axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/remove_support_request_converation/${useriddata.login_token}/${deletItemID}`, formData)
                    .then((response) => {
                        setBtnLoading(false);
                        if (response.status == 200 && response.data.status == 1) {
                            toast.success(response.data.message);
                            //get_converationdata(myRequestDataFormArr.id)
                            setIsBlockModelShow(false);
                        }
                    })
                    .catch((error) => { console.error(error); });
            } catch (error) { console.log(error); }

        })();
    }
    /*------------showRatingReviwDetails----------------*/


    const [currentRatingData, setcurrentRatingData] = useState([]);
    const [reviewFeedBack, setreviewFeedBack] = useState('');

    const showRatingReviwDetails = (data) => {
        setcurrentRatingData(data);
        setRatingModelShow(true);
    }

    const [isContentEmpty , setIsContentEmpty] = useState(false);
    //bordercss
    const reviewWillPublish = (data) => {
        setIsContentEmpty(false);
        if(reviewFeedBack){
            try {
                const formData = new FormData();
                
            formData.append('reviw_reply', reviewFeedBack);
            formData.append('reviw_id', currentRatingData.id);
            formData.append('login_token', useriddata.login_token);
            formData.append('action_type', 'published');
            axios.post('https://squadgoo.com.au/api.squadgoo.com.au/action_review', formData)
            .then((response) => {
                    if (response.status == 200 && response.data.status == 1) {
                        toast.success(response.data.message);
                        setRatingModelShow(false);
                        const queryParameters = new URLSearchParams(window.location.search);
                        const userid = queryParameters.get("userid");
                        get_Revieweddata(0);
                    }
                })
                .catch((error) => { console.error(error); });
            } catch (error) { console.log(error); }
        } else {
            setIsContentEmpty(true);
        }
    }

    const reviewWillDelete = (data) => {
        setIsContentEmpty(false);
        if(reviewFeedBack){
        try {
            const formData = new FormData();

            formData.append('reviw_reply', reviewFeedBack);
            formData.append('reviw_id', currentRatingData.id);
            formData.append('login_token', useriddata.login_token);
            formData.append('action_type', 'delete');
            axios.post('https://squadgoo.com.au/api.squadgoo.com.au/action_review', formData)
                .then((response) => {
                    if (response.status == 200 && response.data.status == 1) {
                        toast.success(response.data.message);
                        get_Revieweddata()
                        setRatingModelShow(false)
                    }
                })
                .catch((error) => { console.error(error); });
        } catch (error) { console.log(error); }
        } else {
            setIsContentEmpty(true);
        }
    }



    return (
        <>
            {/* <ToastContainer />*/}
            <div className="dashboarwrap">
                {
                    <>
                        <div className="pagebanner">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h2>Reviews</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="adminwrap py-4">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-3 adminsidebar">
                                        <SuperAdminSidebar></SuperAdminSidebar>
                                    </div>
                                    <div className="col-md-9">
                                        {
                                            screenLoader ?
                                                <>
                                                    <div className='loader_container'>
                                                        <p className='text-center'><img className='width-100 loader_image' src={'/front/images/loader.gif'} /></p>
                                                    </div>
                                                </>
                                                :



                                                <>

                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="box mb-4">
                                                                <div className="box-ttl mb-3 d-flex align-items-center justify-content-between">
                                                                    <h3>
                                                                        {
                                                                            backto ?
                                                                                <>
                                                                                    <Link className='text-safron' to={`/jobseeker-detail?id=${getActiveUser}`}><i className="fa fa-chevron-left" aria-hidden="true"></i> </Link>
                                                                                </>
                                                                                :
                                                                                <></>
                                                                        }
                                                                    </h3>
                                                                </div>

                                                                <div className="table_filter reviewfilter mb-2 d-flex align-items-center justify-content-between commonform">
                                                                   <div className="tblleftfilters d-flex align-items-center">
                                                                    <div className="tblsearch mr-2">
                                                                        <input onChange={(e) => setSearchText(e.target.value)} value={searchText} type="text" className="form-control" placeholder="Search" />
                                                                    </div>
                                                                    <div className="tblsearch mr-2">
                                                                        <input onChange={(e) => setSearchSendByText(e.target.value)} value={searchSendByText} type="text" className="form-control" placeholder="Send by" />
                                                                    </div>
                                                                    <div className="tblsearch mr-2">
                                                                        <input onChange={(e) => setSearchSendToText(e.target.value)} value={searchSendToText} type="text" className="form-control" placeholder="Send to" />
                                                                    </div>
                                                                    </div>

                                                                    <div className="tblrightfilters d-flex align-items-center">
                                                                        {/* searchFilterStatus searchFilterRating */}
                                                                        <select onChange={(e) => setsearchFilterStatus(e.target.value)} className="form-control ml-3 text-capitalize">
                                                                            {
                                                                                recTypeBlockUnBlock.length ?
                                                                                    <>
                                                                                        {
                                                                                            recTypeBlockUnBlock.map((itme, index) => (
                                                                                                <>
                                                                                                    <option key={itme.key} selected={searchFilterStatus == itme.key} value={itme.key}>
                                                                                                        {
                                                                                                            itme.key == 'all' ?
                                                                                                                <>{itme.value}</>
                                                                                                                :
                                                                                                                <>{itme.value}</>
                                                                                                        }
                                                                                                    </option>
                                                                                                </>
                                                                                            ))
                                                                                        }
                                                                                    </>
                                                                                    :
                                                                                    <></>
                                                                            }
                                                                        </select>
                                                                        <select onChange={(e) => setsearchFilterRating(e.target.value)} className="form-control ml-3 text-capitalize">
                                                                            {
                                                                                byRatingData.length ?
                                                                                    <>
                                                                                        {
                                                                                            byRatingData.map((itme, index) => (
                                                                                                <>
                                                                                                    <option key={itme.key} selected={searchFilterRating == itme.value} value={itme.key}>
                                                                                                        {
                                                                                                            itme.key == 'all' ?
                                                                                                                <>{itme.value}</>
                                                                                                                :
                                                                                                                <>{itme.value} star</>
                                                                                                        }
                                                                                                    </option>
                                                                                                </>
                                                                                            ))
                                                                                        }
                                                                                    </>
                                                                                    :
                                                                                    <></>
                                                                            }
                                                                        </select>
                                                                       
                                                                        <span onClick={() => {
                                                                            setSearchText('');
                                                                            setsearchFilterRating('all');
                                                                            setsearchFilterStatus('all');
                                                                            setSearchSendByText('') ;
                                                                            setSearchSendToText('')
                                                                        }}
                                                                            className="reseticon filtericon" data-toggle="tooltip" title="Reset filters">
                                                                            <i className="fa fa-refresh"></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="table-responsive">
                                                                    <table className='table admintbl '>
                                                                        <thead>
                                                                            <tr>
                                                                                <th className='cursor_pointer' onClick={() => settableOrderType((tableOrderType == 'ASC' ? 'DESC' :'ASC'))}>
                                                                                Review id
                                                                                {tableOrderType == 'ASC' ? <i className="fa fa-angle-down ml-5"></i> : <i className="fa fa-angle-up ml-5"></i> }
                                                                                </th>
                                                                                {/* <th>Review id</th> */}
                                                                                <th>Send by</th>
                                                                                <th>Send to</th>
                                                                                <th>Status</th>
                                                                                <th>Rating</th>
                                                                                <th>Action</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {

                                                                                myReviewDataArr.length ?

                                                                                    myReviewDataArr.map((item, index) => (
                                                                                        <>
                                                                                            <tr className={currentRatingId == item.id ? 'active active_row_class' : ''}>
                                                                                                <td>{item.id}</td>
                                                                                                <td className='text-capitalize'>
                                                                                                    <Link className="text-website text-decoration-none1" to={`${item.send_from_user.user_type == 3 ? '/jobseeker-detail?backto=allreviewpage&id=' + item.send_from_user.unique_id : '/recruiter-detail?backto=allreviewpage&id=' + item.send_from_user.unique_id}`} >
                                                                                                        {item.send_from_user.first_name + ' ' + item.send_from_user.last_name}
                                                                                                    </Link>
                                                                                                </td>
                                                                                                <td className='text-capitalize'>
                                                                                                    <Link className="text-website text-decoration-none1" to={`${item.send_for_user.user_type == 3 ? '/jobseeker-detail?backto=allreviewpage&id=' + item.send_for_user.unique_id : '/recruiter-detail?backto=allreviewpage&id=' + item.send_from_user.unique_id}`} >
                                                                                                        {item.send_for_user.first_name + ' ' + item.send_for_user.last_name}
                                                                                                    </Link>
                                                                                                </td>
                                                                                                <td>{
                                                                                                    item.is_published == 1 ?
                                                                                                        <span className="badge1 budge-success text-success">Published</span> :
                                                                                                        <span className="badge1 budge-success  text-danger">Reported</span>
                                                                                                }</td>
                                                                                                <td>
                                                                                                    <StarRating rating={item.rating} />
                                                                                                </td>
                                                                                                <td>

                                                                                                    <div className="d-flex">

                                                                                                        {
                                                                                                            viewAccessibility && <Link className="ml-3" onClick={() => showRatingReviwDetails(item)} data-toggle="tooltip" title="View Review">
                                                                                                                <img src="/front/images/Show.svg" />
                                                                                                            </Link>
                                                                                                        }

                                                                                                        {/* {editAccessibility &&  <span className="ml-3 cursor_pointer"  >
                                                                                                        <img src="/front/images/trash.svg" />
                                                                                                        </span>} */}
                                                                                                    </div>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </>
                                                                                    ))

                                                                                    :
                                                                                    <>
                                                                                        <tr>
                                                                                            <td className='text-center' colSpan={6}>There are no results found.</td>
                                                                                        </tr>
                                                                                    </>
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                </>

                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>

 
            <Modal className="modal fade modalsmall jobacceptmodle" id="jobacceptmodle" show={isBlockModelShow} onHide={() => setIsBlockModelShow(false)}>
                <div className="modal-dialog1">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button type="button" className="btn-close" onClick={() => setIsBlockModelShow(false)}></button>
                            <h5 className="text-center mb-4">Do you want to delete this message ?</h5>
                            <div className="btnsgroup text-center">
                                <Link onClick={wantToBlockData} className="batn batn-orange" >Yes</Link>
                                <Link onClick={() => setIsBlockModelShow(false)} className="batn batn-orange batn-border">No</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal className="" id="jobacceptmodle" show={ratingModelShow} onHide={() => setRatingModelShow(false)}>
                <div className="modal-dialog1">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button type="button" className="btn-close" onClick={() => setRatingModelShow(false)}></button>
                            {/* ------------- */}

                            {
                                ratingModelShow &&
                                <>

                                    <div id="contentdata_show">
                                        <p className="reportdate d-flex"><img src="/front/images/calender.png" />
                                            {currentRatingData.created_date}
                                        </p>
                                        <h4 className='text-capitalize m-0'>{currentRatingData.title}</h4>
                                        <p className='p-0 m-0'>{currentRatingData.description}</p>

                                        <p className='p-0 m-0 text-capitalize'><b>Review by : </b> {currentRatingData.send_from_user.first_name + ' ' + currentRatingData.send_from_user.last_name}</p>
                                        <p className='p-0 m-0 text-capitalize'><b>Review to : </b> {currentRatingData.send_for_user.first_name + ' ' + currentRatingData.send_for_user.last_name}</p>

                                        <p><StarRating rating={currentRatingData.rating} /></p>
                                    </div>
                                    {
                                        currentRatingData.history.length ? 
                                        <>
                                            <h5>Report history</h5>
                                            <ul>
                                            {
                                                currentRatingData.history.map((it,index) => (
                                                    <li>
                                                        <b><span className=''>{it.title}</span> ({it.priority}) {it.created_date}</b>
                                                        <br></br>
                                                        {it.description}
                                                    </li>
                                                    ))
                                                }
                                            </ul>
                                        </>
                                        :
                                        <></>
                                    }
                                    {
                                        currentRatingData.conversations.length ? 
                                        <>
                                            <h5>Report Reply</h5>
                                            <ul>
                                            {
                                                currentRatingData.conversations.map((it,index) => (
                                                    <li>
                                                        <b><span className=''>{it.reply_message}</span> {it.created_date}</b>
                                                    </li>
                                                    ))
                                                }
                                            </ul>
                                        </>
                                        :
                                        <></>
                                    }
                                   
                                    {currentRatingData.is_published == 0 && <>
                                        <div className="col-md-12 form-group mt-3">
                                            <label className="form-label" htmlFor="exampleInputEmail1">Reply for user <span className="starr">*</span></label>
                                            <textarea className={`form-control ${isContentEmpty ? 'bordercss' : ''}`} onChange={(e) => {setIsContentEmpty(reviewFeedBack ?  false :  true);setreviewFeedBack(e.target.value)}} name='reply_for_user' maxLength={500} required={true} rows="5" placeholder="Enter description"></textarea>
                                        </div>
                                        <div className="btnsgroup">
                                            <Link className="batn batn-orange" onClick={(e) => reviewWillPublish(currentRatingData)} >Again Publish</Link>
                                            <Link className="batn batn-red" onClick={(e) => reviewWillDelete(currentRatingData)} >Hide for all</Link>
                                        </div>
                                    </>}

                                </>
                            }


                            {/* ------------- */}
                        </div>
                    </div>
                </div>
            </Modal>




        </>
    );
}
export default AdminReviewSettings;