import React, { useState, useEffect, Component } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useParams, useNavigate } from 'react-router-dom';
import { Form, Button, Col, InputGroup, Modal, Row } from 'react-bootstrap';
import axios from 'axios';


import { ToastContainer, toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';
import JsrProModalComp from './JsrProModalcom';
import StarRating from '../../expocomp/StarRatingComp';
import GoogleMapLocationComp from '../GoogleMapLocationComp';

import { useDispatch } from 'react-redux'
import { recdeshjobactioncheck } from '../../redux_state/reducres/findStaffBtnAction';

const QucikSearch = () => {//const navigate = useNavigate();

   const dispatch = useDispatch();
   const [staffSearchResult, setStaffSearchResult] = useState([]);
   const [staffSearchResultAsc, setStaffSearchResultAsc] = useState([]);
   const [staffSearchResultDesc, setStaffSearchResultDesc] = useState([]);
   const [searchJobtitle, setSearchJobtitle] = useState('.....');
   //const [jobTypeList, setJobTypeList] = useState([]);
   const [filterData, setFilterData] = useState([]);



   const [isViewDetailModalShow, isSetViewDetailModalShow] = useState(false);
   const [isModalDataLoad, setIsModalDataLoad] = useState(false);
   const [isDataLoading, setIsDataLoading] = useState(false);
   const [staffViewDetailsDataArr, setstaffViewDetailsDataArr] = useState([]);
   const [currentFormID, setCurrentFormID] = useState(localStorage.getItem('finsStaffFormID'));
   const [jobStatusType, setjobStatusType] = useState('pending');
   /*on compnent load take data from localstaorage*/
   const useriddata = JSON.parse(localStorage.getItem("squadGologinSession"));
   const getQuickSearchData = (formid) => {
      (async () => {
         try {
            const response = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/staff_search/" + useriddata.login_token + "/" + formid);
            if (response.data.status == 1) {
               setStaffSearchResult(response.data.data.final_array);
               setStaffSearchResultAsc(response.data.data.final_array_asc);
               setStaffSearchResultDesc(response.data.data.final_array);
               setSearchJobtitle(response.data.data.job_title_name);
               setFilterData(response.data.data.filter_data);
               setIsDataLoading(false);
            }
         } catch (error) { console.log(error); }
      })();
   }

   useEffect(() => {

      const queryParameters = new URLSearchParams(window.location.search)
      const jobofferid = queryParameters.get("formid");
      if (jobofferid) {
         getQuickSearchData(jobofferid);
         setCurrentFormID(jobofferid);
         localStorage.setItem('finsStaffFormID', jobofferid);
      } else {
         if (JSON.parse(localStorage.getItem('finsStaffForm1DataArray'))) { getQuickSearchData(currentFormID) }
         else { setTimeout(window.location.replace("/recruiter/dashboard"), 300); }
      }
   }, []);

   /*model form details */
   const viewDetailModel = (id, itemdata, status) => {
      setIsModalDataLoad(true); setIsLoadingSendOffer(false);
      const useriddata = JSON.parse(localStorage.getItem("squadGologinSession"));
      setjobStatusType(itemdata.offer_sent_status);
      (async () => {
         try {
            const formData = new FormData();
            formData.append('login_token', useriddata.login_token);
            formData.append('user_unique_id', useriddata.unique_id);
            formData.append('staff_id', id);
            axios.post('https://squadgoo.com.au/api.squadgoo.com.au/findstaff_detail', formData)
               .then((response) => {
                  setIsModalDataLoad(false);
                  if (response.status == 200 && response.data.status == 1) {
                     setstaffViewDetailsDataArr(response.data.data);
                  }
               })
               .catch((error) => { console.error(error); });
         } catch (error) { console.log(error); setIsModalDataLoad(false); }

      })()
      isSetViewDetailModalShow(true);
   }
   const closeResumeModal = () => { setIsLoadingSendOffer(false); isSetViewDetailModalShow(false) };

   /*shortByPayData  form */
   const shortByPayData = (type) => {
      if (type == 2) {
         setStaffSearchResult(staffSearchResultAsc);
      } else {
         setStaffSearchResult(staffSearchResultDesc);
      }
   }

   /*send offer details */
   const [isLoadingSendOffer, setIsLoadingSendOffer] = useState(false);
   const sendOfferToStaffModel = (id, itemdata) => {
      setIsLoadingSendOffer(true);
      const useriddata = JSON.parse(localStorage.getItem("squadGologinSession"));
      //currentFormID
      try {
         const formData = new FormData();
         //const formData = new FormData();
         formData.append('user_unique_id', useriddata.unique_id);
         formData.append('filter_data', JSON.stringify(filterData));
         formData.append('send_offerdata', JSON.stringify(
            {
               'job_title': searchJobtitle,
               'candidate_first_name': itemdata.first_name,
               'candidate_last_name': itemdata.last_name
            }
         ));
         formData.append('staff_id', id);
         formData.append('current_form_id', currentFormID);
         formData.append('form_process_type', 'manually');
         //formData.append('job_location', (locationTags));
         axios.post('https://squadgoo.com.au/api.squadgoo.com.au/sendoffer_to_staff', formData)
            .then((response) => {
               setIsLoadingSendOffer(false);
               if (response.status == 200 && response.data.status == 1) {
                  isSetViewDetailModalShow(false);
                  getQuickSearchData(currentFormID);
                  toast.success(response.data.message);
               } else {
                  toast.warning(response.data.message);
               }
            })
            .catch((error) => { console.error(error); });
      } catch (error) { console.log(error); }
   }

   const cancelOfferToStaffModel = (id, itemdata) => {
      setIsLoadingSendOffer(true);
      const useriddata = JSON.parse(localStorage.getItem("squadGologinSession"));
      //currentFormID
      try {
         const formData = new FormData();
         //const formData = new FormData();
         formData.append('user_unique_id', useriddata.unique_id);
         formData.append('filter_data', JSON.stringify(filterData));
         formData.append('send_offerdata', JSON.stringify(
            {
               'job_title': searchJobtitle,
               'candidate_first_name': itemdata.first_name,
               'candidate_last_name': itemdata.last_name
            }
         ));
         formData.append('staff_id', id);
         formData.append('current_form_id', currentFormID);
         formData.append('form_process_type', 'manually');
         //formData.append('job_location', (locationTags));
         axios.post('https://squadgoo.com.au/api.squadgoo.com.au/canceloffer_to_staff', formData)
            .then((response) => {
               setIsLoadingSendOffer(false);
               if (response.status == 200 && response.data.status == 1) {
                  isSetViewDetailModalShow(false);
                  getQuickSearchData(currentFormID);
                  toast.success(response.data.message);
               } else {
                  toast.warning(response.data.message);
               }
            })
            .catch((error) => { console.error(error); });
      } catch (error) { console.log(error); }
   }

   return (
      <>
         {/* <ToastContainer />*/}
         <div className="dashboarwrap">
            <div className="jobbanner">
               <div className="container">
                  <div className="row">
                     <div className="col-md-12">
                        <h2><span>Search result for</span> {searchJobtitle ? searchJobtitle : '.....'}</h2>
                        <form className="searchform d-flex">
                           <input type="text" className="form-control jobcategorysearch" placeholder="Search for staff here"></input>
                        </form>
                     </div>
                  </div>
               </div>
            </div>
            <div className="jobbtmsec py-4">
               <div className="container">
                  <div className="row">

                     <div className="col-md-9">
                        <div className="d-flex flex-wrap justify-content-between mb-2">
                           <h3>
                              {
                                 staffSearchResult.length ?
                                    <>{staffSearchResult.length} {searchJobtitle} available</>
                                    :
                                    <>{isDataLoading ? <></> : <>{staffSearchResult.length + ' records matched'}</>}</>
                              }
                           </h3>
                           <div className="sort_option d-flex">
                              <p>Sort by</p>
                              <select className="form-control" onChange={(e) => shortByPayData(e.target.value)}>
                                 {/* <option value="">Pay</option> */}
                                 <option value="1">High to Low</option>
                                 <option value="2">Low to High</option>
                              </select>
                           </div>
                        </div>



                        <div className="joblistingwrap">
                           <div className="row">
                              {/* <div className='col-md-12 mb-2'> */}
                              {/* { staffSearchResult.length > 0  && <GoogleMapLocationComp />} */}
                              {/* </div> */}

                              {
                                 staffSearchResult.length ?
                                    (<>
                                       {
                                          staffSearchResult.map((item, index) => (
                                             <>
                                                <div key={index + 1} className="col-md-4 ">
                                                   <div className="card-box joblistbox staflistw staflistwnew">
                                                      <div className="staffPprice_match ml-auto cursor_pointer" onClick={() => viewDetailModel(item.unique_id, item, item.offer_status)} >
                                                         <h3 className="job_price">${item.expect_salary_from_decimal}</h3>
                                                         <div className="d-flex">
                                                            {
                                                               item.offer_sent_status == 'not_sent' ?
                                                                  <><span className="matchbar"><span className={`matchbariner w${item.profile_match}`}></span></span>{item.profile_match}% matched</>
                                                                  :
                                                                  <>{item.offer_sent_status}</>
                                                            }

                                                         </div>
                                                      </div>
                                                      <div className="d-flex flex-wrap stafflisttop">
                                                         <div className="staffimgg"><img src={`https://squadgoo.com.au/api.squadgoo.com.au/${item.profilepic}`} /></div>

                                                         <div className="staffnamerating">
                                                            <h3 className='text-capitalize cursor_pointer ' onClick={() => viewDetailModel(item.unique_id, item, item.offer_status)}>{item.first_name + ' ' + item.last_name}</h3>
                                                            <div className="reting d-flex">
                                                               <div className="stars d-flex">
                                                                  <StarRating rating={(typeof (item.total_rating_count) != 'undefined' && item.total_rating_count.average_rating != null) ? item.total_rating_count.average_rating : 0} />
                                                               </div>
                                                               <p>
                                                                  <Link className='m-0 cursor_pointer' to={`/reviews?uniqueid=${item.unique_id}`} >
                                                                     {(typeof (item.total_rating_count) != 'undefined' && item.total_rating_count.average_rating != null) ? item.total_rating_count.average_rating : 0} |
                                                                     <span className='ml-2' >{item.total_rating_count.total_count} Reviews</span>
                                                                  </Link>
                                                               </p>

                                                            </div>
                                                            <div className="d-flex flex-wrap stafdata">
                                                               <div className="icontxt">Location: <span className='text-capitalize'>{item.prefered_location_string.prefered_location ? item.prefered_location_string.prefered_location : '.......'}</span></div>

                                                            </div>
                                                         </div>


                                                      </div>
                                                      {/* <p>Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Sed hendrerit. Maecenas nec odio et ante condimentum rhoncus, sem quam semper libero condimentum rhoncus, sem quam...<a href="#">Read More</a></p> */}


                                                   </div>
                                                </div>
                                             </>
                                          ))
                                       }
                                    </>)
                                    :
                                    (<><div className="card-box joblistbox staflistw">
                                       {isDataLoading ? <>Loading......</> : 
                                       <>
                                       Profile Not matched <br></br>
                                       <Link 
                                       onClick={() => dispatch(recdeshjobactioncheck({ id: currentFormID, actiontype: 'edit', screentype: 'active_job' , searchtype: "manually" }))} className="">Edit post 
                                       </Link>
                                       </>
                                       } 
                                    </div></>)
                              }

                           </div>
                        </div>
                        {
                           staffSearchResult.length > 10 ?
                              <>
                                 <div className="card-box">
                                    <nav aria-label="Page navigation">
                                       <ul className="pagination mb-0 justify-content-center">
                                          <li className="page-item "><a className="page-link" href="#"><i className="fa fa-angle-left" aria-hidden="true"></i></a></li>
                                          <li className="page-item active"><a className="page-link" href="#">1</a></li>
                                          <li className="page-item"><a className="page-link" href="#"><i className="fa fa-angle-right" aria-hidden="true"></i></a></li>
                                       </ul>
                                    </nav>
                                 </div>
                              </>
                              : ''
                        }
                     </div>
                  </div>
               </div>
            </div>


            {/* send offer to staff */}
            {isViewDetailModalShow && <Modal className="modal fade modallarg staffmodal jobmodle admininfo" show={isViewDetailModalShow} onHide={closeResumeModal}>
               <div className="modal-dialog1 viewdedatil_model">

                  <div className="modal-content">
                     <div className="modal-body">
                        <button type="button" className="btn-close" onClick={closeResumeModal}></button>
                        <div className="joblistbox">
                           {/* jobseeker profile model */}
                           <JsrProModalComp data={staffViewDetailsDataArr} formdata={filterData} jobStatusType={jobStatusType} />

                           <div className="btnsgroup">
                              {
                                 (jobStatusType == 'not_sent') ?
                                    <>
                                       <button onClick={() => sendOfferToStaffModel(staffViewDetailsDataArr.unique_id, staffViewDetailsDataArr)} className="batn batn-orange" disabled={isLoadingSendOffer} >Send offer
                                          {isLoadingSendOffer ? <><img src={'/front/images/loading2.gif'} /></> : <></>}
                                       </button>
                                       <button onClick={closeResumeModal} className="batn batn-orange" >Cancel</button>
                                    </>
                                    :
                                    (jobStatusType == 'pending') ?
                                       <>
                                          <>
                                             <Link onClick={() => cancelOfferToStaffModel(staffViewDetailsDataArr.unique_id, staffViewDetailsDataArr)} className="batn batn-orange" disabled={isLoadingSendOffer} >Cancel offer
                                                {isLoadingSendOffer ? <><img src={'/front/images/loading2.gif'} /></> : <></>}
                                             </Link>
                                          </>
                                       </>
                                       :
                                       <></>
                              }
                           </div>

                        </div>
                     </div>
                     <div className={`model_loader ${!isModalDataLoad ? 'display_none' : ''}`}>
                        <img src={'/front/images/loader.gif'} />
                     </div>
                  </div>
               </div>
            </Modal>}


         </div>
      </>
   );
}
export default QucikSearch;