import { createSlice } from '@reduxjs/toolkit'

export const findStaffBtnAction = createSlice({
  name: 'fsmodelActionButton',
  initialState: {
    value: {model_will_show :  0 , model_action  :   'close' },
    jobidarray: {id:0 ,actiontype:'preview' , screentype:'completed_job' , searchtype :'manually'}
  },
  reducers: {
    fsmodelshow: (state) => {
      state.value = 1
    },
    fsmodelhide: (state) => {
      state.value = 0
    }, 
    fsmodelhideandseek: (state,action) => {
        let payload  = action.payload ? action.payload : 'close' ;
        state.value = state.value.model_will_show  ?   {model_will_show :  0 , model_action  :   payload } :  {model_will_show :  1 , model_action  :   payload }  ;
    },
    recdeshjobactioncheck: (state, action) => {
      console.log('dddddddd');
        state.jobidarray = {
                randome_number : Math.random() , 
                id : action.payload.id , 
                actiontype : action.payload.actiontype ,
                screentype : action.payload.screentype ,
                searchtype : action.payload.searchtype ,
        }
    },

  },
})
export const { fsmodelshow, fsmodelhide , fsmodelhideandseek , recdeshjobactioncheck } = findStaffBtnAction.actions

export default findStaffBtnAction.reducer