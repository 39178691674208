import React, { useState, useEffect } from 'react';
import Video from 'twilio-video';

const TwilioVideoChat = () => {
  const [room, setRoom] = useState(null);
  const [participants, setParticipants] = useState([]);

  useEffect(() => {
    const initializeRoom = async () => {
      // Your Twilio credentials
      const token = 'RM065788ad656c0d3c22669451b0f1089b';
      const roomName = 'my-room';

      try {
        const videoRoom = await Video.connect(token, {
          name: roomName
        });

        setRoom(videoRoom);

        videoRoom.on('participantConnected', participant => {
          setParticipants(prevParticipants => [...prevParticipants, participant]);
        });

        videoRoom.on('participantDisconnected', participant => {
          setParticipants(prevParticipants =>
            prevParticipants.filter(p => p !== participant)
          );
        });

        videoRoom.once('disconnected', () => {
          setRoom(null);
        });
      } catch (error) {
        console.error('Error connecting to the room:', error);
      }
    };

    initializeRoom();

    return () => {
      if (room) {
        room.disconnect();
      }
    };
  }, []); // This will run once on component mount

  return (
    <div>
      <h1>Twilio Video Chat</h1>
      <div>
        {room && (
          <div>
            <h2>Participants:</h2>
            <ul>
              {participants.map(participant => (
                <li key={participant.sid}>{participant.identity}</li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default TwilioVideoChat;
