import React from 'react';
import ReviewComp from '../ReviewComp';
import CheckValidationComp  from '../CheckValidationComp';

const Reviews = () => {

    const useriddata = JSON.parse(localStorage.getItem("squadGologinSession"));
    // console.log(useriddata.user_type_role);
    // console.log(useriddata.user_id);
    return (
        <>
            
            <div className="dashboarwrap">
                <div className="pagebanner">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2>Reviews</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className=" py-4">
                    <div className="container">

                        <div className="row">
                            <div className="col-md-12">
                               {/* <ReviewComp  attr={{role:useriddata.user_type_role , user_id : useriddata.user_id} } /> */}
                               <ReviewComp  attr={{ calling_role : 'jobseeker_or_recruiter'  , role:useriddata.user_type_role , user_id : useriddata.user_id} } />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}
export default Reviews;