import React, { useState, useEffect, Component } from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import ProfileBanner from '../profile_header';
import ProfileSidebar from '../profile_sidebar';

import { Form } from 'react-bootstrap';

import { toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';

import { useDispatch } from 'react-redux'
import { basicDetailsValidationCheck } from '../../redux_state/reducres/checkDocumentstatus';
import { userdataarray_status } from '../../redux_state/reducres/userDataRegister';

import CheckValidationComp from '../CheckValidationComp';

//import { useUser } from '../ApiDataContext';
import ABBREVIATIONS from '../../json/abbreviations.json';

const BasiDetail = () => {

   const dispatch = useDispatch();
   const userdetails = JSON.parse(localStorage.getItem("squadGologinSession"));
   const DayNumberLoop = (attr = '') => { const itemElements = []; itemElements.push(<option value="">DD</option>); for (let i = 1; i < 32; i++) { itemElements.push(<option selected={(attr != '' && attr.select_attr == i) ? true : false} value={i}>{i}</option>); } return itemElements; }
   const MonthsHtml = (attr = '') => { const itemElements = []; itemElements.push(<option value="">Month</option>); Months.map((item, index) => (itemElements.push(<option selected={(attr != '' && attr.select_attr == item.name) ? true : false} value={item.name}>{item.name}</option>))); return itemElements }
   const Months = [{ name: 'January', key: 1, abbreviation: 'Jan' }, { name: 'February', key: 2, abbreviation: 'Feb' }, { name: 'March', key: 3, abbreviation: 'Mar' }, { name: 'April', key: 4, abbreviation: 'Apr' }, { name: 'May', key: 5, abbreviation: 'May' }, { name: 'June', key: 6, abbreviation: 'Jun' }, { name: 'July', key: 7, abbreviation: 'Jul' }, { name: 'August', key: 8, abbreviation: 'Aug' }, { name: 'September', key: 9, abbreviation: 'Sep' }, { name: 'October', key: 10, abbreviation: 'Oct' }, { name: 'November', key: 11, abbreviation: 'Nov' }, { name: 'December', key: 12, abbreviation: 'Dec' },];
   const YearNumberLoop = (attr = '') => { const itemElements = []; itemElements.push(<option value="">YYYY</option>); for (let i = 1950; i < 2012; i++) { itemElements.push(<option selected={(attr != '' && attr.select_attr == i) ? true : false} value={i}>{i}</option>); } return itemElements }
   //const [userDetailsArray, setUserDetailsArray] = useState([]);
   const [isAccVerified, setIsAccVerified] = useState('false');
   const alphabeticRegex = /^[a-zA-Z]*$/;
   const [isHidden, setIsHidden] = useState(true);
   const toggleHidden = () => { setIsHidden(!isHidden); };

   const [besicDetails, setBasicDetailsData] = useState({
      name_salute: userdetails.name_salute,
      first_name: userdetails.first_name,
      middle_name: userdetails.middle_name,
      last_name: userdetails.last_name,
      dob_day: userdetails.dob_day,
      dob_month: userdetails.dob_month,
      dob_year: userdetails.dob_year,
      identity_proof_type: userdetails.identity_proof_type,
   });


   const getUserDetails = () => {
      (async () => {
         try {
            const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/userdetails/" + userdetails.unique_id);
            if (resData.data.status == 1) {
               //setUserDetailsArray(resData.data.data.results);  
               setBasicDetailsData(resData.data.data.results);
               dispatch(userdataarray_status(resData.data.data.results));
               //localStorage.setItem('squadGologinSession',JSON.stringify(resData.data.data.results))
               //setUserUploadIdentityDocuments(resData.data.data.results.identity_document_proof); 
               setIsAccVerified(resData.data.data.results.verifications_status.statustype.basic_details);
            }
         } catch (error) { console.log(error); }
      })();
   }

   useEffect(() => {
      if (userdetails.unique_id) {
         getUserDetails();
      } else { setTimeout(function () { window.location.replace("/login"); }, 100) }
   }, []);





   const [validated, setValidated] = useState(false);
   const onBasicDetailsSubmit = async (event) => {
      event.preventDefault();
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
         event.preventDefault();
         event.stopPropagation();
      } else {
         try {
            const formData = new FormData(event.target);
            const referenceDataValue = {};
            formData.forEach((value, name) => {
               if (name.endsWith('[]')) {
                  const inputName = name.slice(0, -2);
                  if (!referenceDataValue[inputName]) {
                     referenceDataValue[inputName] = [value];
                  } else { referenceDataValue[inputName].push(value); }
               } else { referenceDataValue[name] = value; }
            });
            if (userdetails.unique_id) {
               formData.append('userid', userdetails.unique_id);
            }
            const response = await axios.post(`https://squadgoo.com.au/api.squadgoo.com.au/userdetails_data_update`,
               formData, { headers: { 'Content-Type': 'multipart/form-data' } });
            if (response.data.status == 1) {
               setIsHidden(true); getUserDetails();
               if (userdetails.validationdata_check.basicdob_validation == 0) {
                  /*redux validation update */
                  dispatch(basicDetailsValidationCheck(1));

 
                  /*redux validation check end  */
               };
               toast.dismiss();
               toast.success(response.data.message);
            } else { toast.warning(response.data.message); }
         } catch (error) { console.error('Error uploading file:', error); }
      }
      setValidated(true);
   };

   //dob_day dob_month dob_year


   const basiDetailsUpdatefun = (event) => {
      const { name, value } = event.target;

      if ((name == 'first_name' || name == 'middle_name' || name == 'last_name') && !alphabeticRegex.test(value)) {
         return null;
      }

      setBasicDetailsData((prevFormData) => ({
         ...prevFormData,
         [name]: value
      }));
   };




   return (
      <>

         {/* <ToastContainer />*/}
         <div className="dashboarwrap">
            <ProfileBanner />
            <div className="jobbtmsec py-5 admininfo">
               <div className="container">

                  <div className="row">
                     <div className="col-md-3 profilesidebarwrap">
                        <ProfileSidebar />
                     </div>
                     <div className="col-md-9">
                        <div className="profilerightwraper stepscont commonform basicdetail">
                           <div className="card-box work-experiencesec commonform">

                              <div className="d-flex align-items-center justify-content-between">
                                 <h3 className="mb-3">
                                    Basic details
                                    {
                                       isAccVerified == true ?
                                          <><img className='veri_unveri_icon' src="/front/images/vfy.png" data-toggle="tooltip" data-placement="top" title="verified"></img></> :
                                          isAccVerified == false ?
                                             <><img className='veri_unveri_icon' src="/front/images/uvfy.png" data-toggle="tooltip" data-placement="top" title="Your Profile is in Review"></img></> :
                                             <></>
                                    }
                                 </h3>
                                 {isAccVerified == false && <div className="editbtn" onClick={toggleHidden} ><img src="/front/images/edit.png" /></div>}
                              </div>
                              <div className="editblock">
                                 <div className="editblockinner d-flex ">
                                    <h4>
                                       <div><span className="mr-2">First name :</span><span className='text-capitalize'>{besicDetails.first_name ?? '.......'}</span></div>
                                       <div><span className="mr-2">Middle name :</span><span className='text-capitalize'>{besicDetails.middle_name ? besicDetails.middle_name : '......'}</span></div>
                                       <div><span className="mr-2">Last name:</span><span className='text-capitalize'>{besicDetails.last_name}</span></div>
                                       <div><span className="mr-2 firstcoldtl">Date of birth</span><span className='text-capitalize'>{besicDetails.dob_day ? besicDetails.dob_day + '-' + besicDetails.dob_month + '-' + besicDetails.dob_year : '......'}</span></div>
                                    </h4>
                                 </div>
                                 {!isHidden &&
                                    <div className="editblockinnerbtm">
                                       <Form noValidate validated={validated} onSubmit={onBasicDetailsSubmit}>
                                          <div className="row">
                                             <div className="col-md-6">
                                                <div className="d-flex">
                                                   <div className="form-group mr-2 salutewrap">
                                                      <label className="form-label">Salute <span className="starr">*</span></label>
                                                      <select className="form-control" onChange={basiDetailsUpdatefun} name='name_salute'>
                                                         {/* <option>Mr.</option>
                                                         <option selected={(besicDetails.name_salute == 'Miss.') ? true : false}>Miss.</option> */}
                                                         {
                                                            ABBREVIATIONS.map((item) => (
                                                               <option selected={(besicDetails.name_salute == item.value) ? true : false} key={item.id} value={item.value}>{item.value}</option>
                                                            ))
                                                         }

                                                      </select>


                                                   </div>
                                                   <div className="form-group w-100">
                                                      <label className="form-label">First name <span className="starr">*</span></label>
                                                      <input required={true} type="text" onChange={basiDetailsUpdatefun} value={besicDetails.first_name} className="form-control" placeholder="Andrew" name='first_name' />
                                                   </div>
                                                </div>
                                             </div>
                                             <div className="col-md-6">
                                                <div className="form-group">
                                                   <label className="form-label">Middle name </label>
                                                   <input type="text" onChange={basiDetailsUpdatefun} value={besicDetails.middle_name} className="form-control" placeholder="Enter middle name" name='middle_name' />
                                                </div>
                                             </div>
                                             <div className="col-md-6">
                                                <div className="form-group">
                                                   <label className="form-label">Last name <span className="starr">*</span></label>
                                                   <input required={true} type="text" onChange={basiDetailsUpdatefun} value={besicDetails.last_name} className="form-control" placeholder="Enderson" name='last_name' />
                                                </div>
                                             </div>
                                             <div className="col-md-6">
                                                <div className="form-group">
                                                   <label className="form-label">Date of birth <span className="starr">*</span></label>
                                                   <div className="d-flex">
                                                      <select className="form-control mr-2" required={true} name="dob_day" onChange={basiDetailsUpdatefun} >
                                                         {<DayNumberLoop select_attr={besicDetails.dob_day} />}
                                                      </select>
                                                      <select className="form-control mr-2" required={true} name="dob_month" onChange={basiDetailsUpdatefun} >
                                                         {<MonthsHtml select_attr={besicDetails.dob_month} />}
                                                      </select>
                                                      <select className="form-control" required={true} name="dob_year" onChange={basiDetailsUpdatefun} >
                                                         {<YearNumberLoop select_attr={besicDetails.dob_year} />}
                                                      </select>
                                                   </div>
                                                </div>
                                             </div>

                                             <div className="col-md-12 mt-3">
                                                <button className="batn batn-rounded rounded-orange mr-2">Save</button>
                                                <button onClick={(e) => setIsHidden(true)} className="batn batn-rounded rounded-orange mr-2">Cancel</button>
                                             </div>
                                          </div>
                                       </Form>
                                    </div>
                                 }

                              </div>
                           </div>

                        </div>
                     </div>

                  </div>
               </div>
            </div>

         </div>
      </>
   );
}
export default BasiDetail;