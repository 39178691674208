import { Link   }  from 'react-router-dom';
const AboutUs = () => {
    return (
     <>
     <div className="mainpages">
        <div className="banner page-banner">
          <div className="container">
              <div className="row align-items-center">
                 <div className="col-md-6">
                    <div className="banner-left">
                        <h1>Jobseeker</h1>
                    </div>
                 </div>
                 <div className="col-md-6">
                   <img src="/front/images/aboutbannerimgg.png"></img>
                 </div>
              </div>
          </div>
        </div>




        <div className="whoweare">
          <img src="/front/images/sleft.png" className="sectionleftimg sectionabs"></img>
          <div className="container">
              <div className="row videorow">
              <div className="col-md-6">
                 <video  controls poster="front/images/videoimg.jpg">
                    <source src="/front/images/Office Work Free Stock Video _ One1.mp4" type="video/mp4"></source>
                    </video>
                    </div>
                 <div className="col-md-6">
                    <div className="banner-left">
                        <p>Jobseekers have been facing hardship applying jobs through the traditional way of applying. They must wait for days not if weeks to get response from the recruiters for the job they want. </p>
                        <p>Sometimes they get a job but the availability, location and pay does not match. They keep on applying jobs creating profiles in different platforms making them wait a lot even for a simple response. Whether you are from overseas looking for a job in another country or a person form different state or a person looking for a job offer near your home, SQUAD GO is getting your back now.</p>
                        <p>We make sure the most reliable, honest, verified, and matched profiles will get offers from your desired recruiter. </p>
                        {/* <a href="#" className="batn batn-orange">Join Us</a> */}
                        <Link to="/register" className="batn batn-orange">Join Us</Link>
                    </div>
                 </div>
                 
              </div>
          </div>
          <img src="/front/images/sright.png" className="sectionrightimg sectionabs"></img>
        </div>



        <div className="aboutleftrightsec ">
          <img src="/front/images/dotsinmg.jpg" className="dotimgsec"></img>
          <div className="container">
              <div className="row sucess">
                 
                 <div className="col-md-6 aboutcon">
                    <h2 className="sectionhead mb-3">Why not we look at some problems most of JOBSEEKERS are facing: </h2>
                    {/*                     <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>Multiple job search is a hassle when you want to do 2 jobs same time creating problems on your availability.</span></p> */}
                    <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>Waiting time are huge.</span></p>
                    <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>Multiple job search is a hassle when you want to do 2 jobs same time creating problems on your availability.</span></p>
                    <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>Having to sign up on different traditional job portal and recruitment agencies and keeping up with the sites.</span></p>
                    <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>Having to give references, resumes, personal detail check to everyone and everywhere you apply for a job.</span></p>
                    <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>Having difficulty find a suitable job ad in unlimited platform.</span></p>
                    <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>Once you quite or lost the job, you probably must wait for weeks to get another job.</span></p>
                    <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>Scammers are stealing your data from all these profiles you made from different platforms.</span></p>
                    <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>Even you found a job ad, you know many are applying just like you, so best of luck finding that job.</span></p>
                    <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>You are adjusting your profile and preferences as per the recruiter.</span></p>
                    <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>You are paying money to be able to get highlights among applicant for recruiters.</span></p>
                 </div>

                 <div className="col-md-6 aboutcon">
                    <h2 className="sectionhead mb-3">Solutions We have for you: </h2>
                    <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>You create your profile once and upon verification, you start receiving job offers.</span></p>
                    <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>You can directly chat and take calls to job providers immediately once you accept their offer.</span></p>
                    <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>We offer both “MANNUAL “AND “QUICK “job offer features. </span></p>
                    <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>Manual job offer is for your permanent job according to you profile match.</span></p>
                    <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>Quick job offers are for instant jobs same day once received.</span></p> 
                    <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>You can read the reviews of the company or individuals you are going to work with in advance.</span></p>
                    <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>Salary and requirements information’s are provided to you in advance.</span></p>
                    <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>You get compensation when a job provider offers you QUICK offer and you were not provided with job. (Terms and conditions apply)</span></p>
                    <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>Referring a friend to us and once they get job offers, you get some bonus.</span></p>
                    <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>Your details are fully secured and share according to your preferences.</span></p>
                    <p className="d-flex align-items-start"><img src="/front/images/rightIcon.svg"/><span>You can get job or be a contractor looking for contract on our system.</span></p>
                 </div>
              </div>
             
          </div>
        </div>

   



{/* 

        <div className="whoweare">
          <img src="/front/images/sleft.png" className="sectionleftimg sectionabs"></img>
          <div className="container">
              <div className="row">
                 <div className="col-md-12">
                    <div className="banner">
                        <h2 className="sectionhead">Features</h2>
                    <p id="sec1"><strong>1. VERIFIED PROFILES</strong></p>
<p>WE make sure that all profiles on our platform are scam free and legit. We run and verify, licenses, any qualifications, history of experience, reviews, references, name, address, ABN or TFN, resumes, legal checks, availability, visas (optional), phone number and email addresses of users ( COMPANY, COMPANY REPRESENTATIVES, BUSINESSES, CONTRACTORS, SUB CONTRACTORS, EMPLOYEES AND  INDIVIDUALS). </p>
<p>SQUAD GOO makes sure we don’t have dormant accounts luring around our platform for a long time. We double check for any scam or identity thief attempts.</p> 
<p id="sec2"><strong>2. PAY PER MATCH/NON-SUBSCRIPTION MODEL</strong></p>
<p>We do not charge you on subscription model which we believe gives little room for small businesses and individuals to try any platforms. Our coin bases system will allow you to use our both MANNUAL SEARCH AND QUICK SEARCH features at the same time. You can always deposit and withdraw coins from our platform as your wish. We refund any amount you spend if the match happens to be unsuccessful even after the matching (Terms and conditions applies). </p>
<p>Once you have a match with other party/individuals then we will hold the coins and deducted upon successful communication. You will be able to withdraw the excess coin you deposited if you want to quite the platforms or want to pause the service.</p>  
<p>We charge different prices for MANNUAL SEARCH and QUICK SEARCH which makes pay per match model perfect for you as you don’t have to have subscription for both features.</p>  
<p>Please <a href="#">click here (coins and purchase section)</a> to find more about charges.</p>
<p id="sec3"><strong>DATA COLLETION SAFETY/PRIVACY</strong></p>
<p>All the details and data collected by SQUAD GOO are highly secured and only shared with your potential or already matched users with disclaimers before. You may choose not to share certain information’s to other parties through our app or website. We are aware of a lot of scammers and hackers looming around for your data’s, which we make 100% sure are not misused or targeted. Your contact, personal information’s won’t be shared to each other unless we have a match.</p>

<p id="sec4"><strong>MANNUAL SEARCH</strong></p>
<p>With an aim to provide swift hiring within few minutes, MANNUAL SEARCH provides job providers with matched profiles which can be further selected by the job providers themselves to send an offer. This feature makes sure the filtered job seekers or contractor’s acceptance rating is respected. System makes sure that low acceptance rating profile are low visible or kicked out of the platform, so we have only genuine users on our platforms.</p>


<p id="sec5"><strong>QUICK SEARCH</strong></p> 
<p>Quick search feature works completely different from Manual search with the same aim. QUICK SEARCH is here to make sure hiring processes is as easy as ordering a food through delivery drivers like. With simple information’s and activating this option, the system will automatically find match and send them directly to your workplace. It ensures that both parties can communicate, map views of each other until job seekers meets job providers.</p>

<p>Job providers can always double check to whom the system send offers to, then may edit later. The coins we charge for this service will be little higher than MANNUAL SEARCH but worth it if you need staff same day 24 hours 7 days a week.</p>

<p id="sec6"><strong>FULL REFUNDS POLICY</strong></p> 
<p>SQUAD GOO believes, “we don’t deserve your money until you are satisfied”. Returning your hard-earned money when our platform could not meet what it is advertised for then, you have your money back. HOWEVER, we are a business ourselves, so we go through a lot of checks to ensure someone is not false claiming refunds.</p>

<p id="sec7"><strong>MATCHING POLICY</strong></p> 
<p> Depending upon which search option you took, Matching policies might work differently. However, we try matching 100% of your requirements with 100% matched job seekers/contractors. If the system or you cannot find 100% matched profile, then system will let you know the matching percentage and show you other profile from where you can choose yourself. </p>

<p id="sec8"><strong>REFERRAL BONUSES</strong></p> 
<p> We want to engage more users on our platform and getting referral from already registered users is effective ways to do it. It’s not only for us but you will be earning coins as bonuses per referral you send us to our platforms. Later those bonuses coins can be withdrawal to your bank account respecting our Terms and conditions.</p>

<p id="sec9"><strong>COMPENSATION POLICY</strong></p> 
<p> We understand that system and parties might make some error and can cause some parties waste of their time. If system finds that job seekers time were wasted intentionally, or job providers carelessly did not work according to the term and conditions then job seekers/ contractors will receive compensation from job providers’ account. Same way if job providers time and effort were wasted by the job seekers, then full or partial refund might activate.</p>

<p id="sec10"><strong>ONE PLACE SOLUTION</strong></p> 
<p> We are the only platform that provides connection between job seekers, contractors, sub-contractors, individuals, businesses in a smooth way, you no longer must go back to traditional hiring platform that will drain your money and time. We are on both website and android and apple mobile application. Our platform is made so simple that you will get around soon after few times of uses. With all the advance and additional features on our platforms, we aim to remove all the possible defects of traditional hiring platforms making it trustworthy of millions of people around the globe. Once you have this website or mobile application, you no longer need to sign up somewhere else for a job or job seekers.</p>

<p id="sec11"><strong>CONTACT SUPPORT FEATURES</strong></p> 
<p> SQUAD GOO is very user friendly, and we make sure you get in touch with us as soon as possible. We have live chat, email, comments, complaints, and call options available for you to directly get in touch with us. We are making all above contact method 24/7 so you don’t have to wait for business hours to get in touch with us. You also may choose your own language while communicating with us, making sure we have smooth conversation.</p>

<p id="sec12"><strong>CONVERSATION RECORDING</strong></p> 
<p> There are conversation features on our app and website between the parties after the match. Users can do video, audio, documents and photos and text with each other. However, we aim to record every conversation happening so the scamming and false parties may be caught immediately. We believe, our platform is greatly professional rather than personal, so recording all conversation gives potential victim to ask for the conversation records if needed for legal or work related or future reference.</p>

<p id="sec13"><strong>QUICK VERIFICATION</strong></p> 
<p>24/7 available verification teams make sure as soon as you upload any documents or details, they are reviewed within 24 hours.</p>  

<p id="sec14"><strong>WORLDWIDE USE CASE</strong></p> 
<p> SQUAD GOO aims to expand world-wide. This will give flexibility to users that wants to work in same or different countries.</p>

<p id="sec15"><strong>ACCOUNT SAFETY</strong></p> 
<p> TWO factor authentication and special technology aims to reduce your account from being misused by unknown parties.</p>

<p id="sec16"><strong>LIVE MAP FUNCTIONS</strong></p> 
<p> Matched parties in QUICK SEARCH can see each other’s location and know when they are going to meet once job seekers start heading to work.</p>

<p id="sec17"><strong>MULTIPLE ROLE FUNCTION</strong></p> 
<p> A contractor can use our platform as job seekers from businesses and individuals whereas, they can also pose as job providers. These multi role function ensures you don’t have to go through traditional platforms. We are all in one.</p>

<p id="sec18"><strong>REVIEWS</strong></p> 
<p> All users have reviews function available once they have a match or already had match before. This ensures all parties can decide themselves weather a user is good enough for your match. HOWEVER, reviews won’t affect your visibility on our platform. You can always report the reviews you think are irrelevant or are misused reviews.</p>
                    
                    </div>
                 </div>
              </div>

             
          </div>
          <img src="/front/images/sright.png" className="sectionrightimg sectionabs"></img>
        </div>
             */}


      {/* <div className="teamsec">
         <div className="container">
           <h2 className="sectionhead">Meet our team</h2>
            <div className="row">
                <div className="col-lg-3 col-md-6">
                   <div className="teammenber">
                      <div className="teamtop">
                         <img src="/front/images/teamimg.jpg" className="img-fluid"></img>
                        
                        <div className="teamoverlay align-items-center d-flex justify-content-center">
                            <a href="#" className="batn batn-orange">Know More</a>
                        </div>
                      </div>
                      <div className="teabsocialmedea d-flex justify-content-center">
                             <a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a>
                             <a href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a>
                             <a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a>
                        </div>
                      <div className="teambtm text-center">
                          <h3>John Deo</h3>
                          <p>Chief Executive Officer</p> 
                      </div>
                   </div>
                </div>

                <div className="col-lg-3 col-md-6">
                   <div className="teammenber">
                      <div className="teamtop">
                         <img src="/front/images/teamimg.jpg" className="img-fluid"></img>
                        
                        <div className="teamoverlay align-items-center d-flex justify-content-center">
                            <a href="#" className="batn batn-orange">Know More</a>
                        </div>
                      </div>
                      <div className="teabsocialmedea d-flex justify-content-center">
                             <a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a>
                             <a href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a>
                             <a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a>
                        </div>
                      <div className="teambtm text-center">
                          <h3>John Deo</h3>
                          <p>Chief Executive Officer</p> 
                      </div>
                   </div>
                </div>

                <div className="col-lg-3 col-md-6">
                   <div className="teammenber">
                      <div className="teamtop">
                         <img src="/front/images/teamimg.jpg" className="img-fluid"></img>
                        
                        <div className="teamoverlay align-items-center d-flex justify-content-center">
                            <a href="#" className="batn batn-orange">Know More</a>
                        </div>
                      </div>
                      <div className="teabsocialmedea d-flex justify-content-center">
                             <a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a>
                             <a href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a>
                             <a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a>
                        </div>
                      <div className="teambtm text-center">
                          <h3>John Deo</h3>
                          <p>Chief Executive Officer</p> 
                      </div>
                   </div>
                </div>

                <div className="col-lg-3 col-md-6">
                   <div className="teammenber">
                      <div className="teamtop">
                         <img src="/front/images/teamimg.jpg" className="img-fluid"></img>
                        
                        <div className="teamoverlay align-items-center d-flex justify-content-center">
                            <a href="#" className="batn batn-orange">Know More</a>
                        </div>
                      </div>
                      <div className="teabsocialmedea d-flex justify-content-center">
                             <a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a>
                             <a href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a>
                             <a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a>
                        </div>
                      <div className="teambtm text-center">
                          <h3>John Deo</h3>
                          <p>Chief Executive Office</p>
                      </div>
                   </div>
                </div>
            </div>

            <div className="text-center"><a href="#" className="batn batn-orange">Join Our Team</a></div>
         </div>
      </div> */}

        

          
        </div>

     </>
    );
  }
  export default AboutUs;