import { BrowserRouter as Router, Routes, Route, NavLink, useParams, useNavigate, Link } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import socialMediaSettings from '../../json/socialMediaSettings.json';
import ContactFormComp from '../../expocomp/ContactFormComp';
const Footer = () => {
  const auth = localStorage.getItem('squadGologin');
  const currentYear = new Date().getFullYear();
  return (
    <>
      <ToastContainer />
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-md-3 footercol footercol1">
              <div className="footerlogo">
                <img src="/front/images/logo.svg" />
              </div>
              <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur </p>
              <p><a className="footerlink" href={`/aboutus`} >Know More About Us</a></p>
            </div>

            <div className="col-md-2 footercol footercol2">
              <h4>Jobseeker</h4>
              <ul>
                <li className="nav-item"><Link className="nav-link" to="/login">Register / Login</Link></li>
                <li className="nav-item"><NavLink className="nav-link" to="/">Browse job</NavLink></li>
                <li className="nav-item"><NavLink className="nav-link" to="/aboutus">How it Works</NavLink></li>
                <li className="nav-item"><NavLink className="nav-link" to="/">Pricing</NavLink></li>
              </ul>
            </div>
            <div className="col-md-2 footercol footercol3">
              <h4>Employer</h4>
              <ul>
              <li className="nav-item"><Link className="nav-link" to="/login">Register / Login</Link></li>
                <li className="nav-item"><NavLink className="nav-link" to="/">Browse job</NavLink></li>
                <li className="nav-item"><NavLink className="nav-link" to="/aboutus">How it Works</NavLink></li>
                <li className="nav-item"><NavLink className="nav-link" to="/">Pricing</NavLink></li>
              </ul>
            </div>
            <div className="col-md-2 footercol footercol3">
              <h4>Individual</h4>
              <ul>
              <li className="nav-item"><Link className="nav-link" to="/login">Register / Login</Link></li>
                <li className="nav-item"><NavLink className="nav-link" to="/">Browse job</NavLink></li>
                <li className="nav-item"><NavLink className="nav-link" to="/aboutus">How it Works</NavLink></li>
                <li className="nav-item"><NavLink className="nav-link" to="/">Pricing</NavLink></li>
              </ul>
            </div>


            <div className="col-md-3 footercol footercol4">
              <h4>Contact Us</h4>
              <div className="footerform commonform">
                <ContactFormComp screen="footer" />
                {/* <div className="row">
                  <div className="col-md-6 mb-3">
                    <input type="text" className="form-control" placeholder="Your name"></input>
                  </div>
                  <div className="col-md-6 mb-3">
                    <input type="email" className="form-control" placeholder="Your email"></input>
                  </div>
                  <div className="col-md-12 mb-3">
                    <textarea className="form-control" placeholder="Write your message here"></textarea>
                  </div>
                  <div className="col-md-12">
                    <input type="submit" value="Submit" className="batn batn-orange"></input>
                  </div>
                </div> */}
              </div>
            </div>

          </div>
          <hr></hr>
          <div className="row fbottm">

            <div className="col-md-8 footercol">
              <p>&copy; Copyright {currentYear} by Squad Goo. All Right Reserved.</p>
            </div>
            <div className="col-md-4 footercol">
              <div className="socialmedia">
                
                <div>
                  {Object.keys(socialMediaSettings.socialMedia).map((platform, index) => (
                    <a key={index} target='_blank' href={socialMediaSettings.socialMedia[platform].link}>
                      <i className={`fa ${socialMediaSettings.socialMedia[platform].icon}`}></i>
                    </a>
                  ))}
                </div>

              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;